import BillingFiguresStatusType from "domain/model/tax/billing/billing_figures_status_type";
import SVG from "presentation/components/common/svg";
import BillingStatusBoardFiguresItem
    from "presentation/pages/billing/components/status_board/billing_status_board_figures_item";
import billingStatusBoardSelector from "presentation/states/billing/selector/billing_status_board_selector";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
`;

const BillingStatusBoardFiguresView = () => {
    const {data} = useRecoilValue(billingStatusBoardSelector);

    const {billingFigures, pendingFigures, taxesFigures, processedFigures} =
        useMemo(() => {
            if (!data.length) {
                return {
                    billingFigures: undefined,
                    pendingFigures: undefined,
                    taxesFigures: undefined,
                    processedFigures: undefined,
                };
            }

            let billingFigures = 0;
            let pendingFigures = 0;
            let taxesFigures = 0;
            let processedFigures = 0;

            data.forEach((d) => {
                billingFigures += d.billingAmount;
                pendingFigures += d.pendingAmount ?? 0;
                taxesFigures += d.taxes ?? 0;
                processedFigures +=
                    d.status === BillingFiguresStatusType.Processed
                        ? d.billingAmount
                        : 0;
            });

            return {
                billingFigures,
                pendingFigures,
                taxesFigures,
                processedFigures,
            };
        }, [data]);

    return (
        <LayoutContainer>
            <BillingStatusBoardFiguresItem
                title={S.billingPage.statusBoard.figures.billingTitle}
                value={billingFigures}
            />
            <BillingStatusBoardFiguresItem
                title={S.billingPage.statusBoard.figures.pendingTitle}
                valueColor={Palette.red500}
                value={pendingFigures}
                icon={
                    <SVG
                        asset={SVGAssets.MinusCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
            />
            <BillingStatusBoardFiguresItem
                title={S.billingPage.statusBoard.figures.taxesTitle}
                value={taxesFigures}
                icon={
                    <SVG
                        asset={SVGAssets.MinusCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
            />
            <BillingStatusBoardFiguresItem
                title={S.billingPage.statusBoard.figures.processedTitle}
                value={processedFigures}
                icon={
                    <SVG
                        asset={SVGAssets.EqualCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
            />
        </LayoutContainer>
    );
};

export default BillingStatusBoardFiguresView;
