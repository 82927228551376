import {forwardRef, PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const ContentLayout = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{ className?: string }>
>(({className, children}, ref) => {
    return (
        <LayoutContainer className={className} ref={ref}>
            {children}
        </LayoutContainer>
    );
});

export default ContentLayout;
