import UserRepository from "data/repository/user_repository";
import User from "domain/model/user/user";
import IconButton, {IconButtonHoverColorType,} from "presentation/components/button/icon_button";
import TNBTitle from "presentation/components/navigation/tnb/tnb_title";
import TNBUserTile from "presentation/components/navigation/tnb/tnb_user_tile";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useRepository from "presentation/utils/hooks/use_repository";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {optional} from "presentation/utils/types/optional";
import {useEffect, useState} from "react";
import styled from "styled-components";

export const TNBContainer = styled.div<{
    $lnbMinWidth: string;
    $lnbMaxWidth: string;
    $height: string;
}>`
    min-width: ${(props) => `calc(100% - ${props.$lnbMaxWidth})`};
    height: ${(props) => props.$height};
    background-color: ${Palette.gray100};
    transition: min-width 0.3s ease-in-out;

    @media (max-width: 768px) {
        min-width: ${(props) => `calc(100% - ${props.$lnbMinWidth})`};
    }
`;

const LayoutContainer = styled(TNBContainer)`
    padding: 0 36px;
    background-color: ${Palette.gray700};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    position: fixed;
    top: 0;
    left: ${(props) => props.$lnbMaxWidth};
    right: 0;
    z-index: 100;
    transition: padding 0.3s ease-in-out, left 0.3s ease-in-out;

    @media (max-width: 768px) {
        padding: 0 16px;
        left: ${(props) => props.$lnbMinWidth};
        gap: 0px;
    }
`;

const TNB = ({title}: { title: string }) => {
    const repository = useRepository(UserRepository);
    const theme = useThemeContext();
    const setLoading = useSetLoading();
    const showNotificationDialog = useShowNotificationDialog();

    const [user, setUser] = useState<optional<User>>(undefined);

    useEffect(() => {
        repository({
            handler: async (repository) => {
                const isAuthenticated = await repository.isAuthenticated();

                if (!isAuthenticated) return;

                const user = await repository.getUser();

                setUser(user);
            },
            onDomainError: () => {
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUserInfoButtonClick = () => AppRouter.navigate(AppRoutes.Profile);

    const onLogoutButtonClick = () =>
        showNotificationDialog({
            type: "negative",
            title: S.tnb.logoutDialogTitle,
            message: S.tnb.logoutDialogMessage,
            hasNegativeButton: false,
            onPositiveButtonClick: () =>
                repository({
                    handler: async (repository) => {
                        setLoading(true);

                        await repository.logout();

                        AppRouter.navigate(AppRoutes.Login, {
                            replace: true,
                        });
                    },
                    onFinally: () => setLoading(false),
                }),
        });

    return (
        <LayoutContainer
            $lnbMinWidth={theme.componentTheme.lnbMinWidth}
            $lnbMaxWidth={theme.componentTheme.lnbMaxWidth}
            $height={theme.componentTheme.tnbHeight}
        >
            <TNBTitle title={title}/>
            <TNBUserTile name={user?.pharmacyName}/>
            <IconButton
                enabled={!!user}
                asset={SVGAssets.Setting}
                color={Palette.gray400}
                hoverColor={IconButtonHoverColorType.Dark}
                onClick={onUserInfoButtonClick}
            />
            <IconButton
                asset={SVGAssets.Logout}
                color={Palette.gray400}
                hoverColor={IconButtonHoverColorType.Dark}
                onClick={onLogoutButtonClick}
            />
        </LayoutContainer>
    );
};

export default TNB;
