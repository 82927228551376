import {animated} from "@react-spring/web";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import {optional} from "presentation/utils/types/optional";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $height: string;
    $backgroundColor: string;
}>((props) => ({
    style: {
        minHeight: props.$height,
        backgroundColor: props.$backgroundColor,
    },
}))`
    width: 100%;
    padding: 0 12px;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const TableCellLayoutContainer = styled.div.attrs<{ $grow: boolean }>(
    (props) => ({
        style: {
            minWidth: props.$grow ? "auto" : "4.375rem",
            width: props.$grow ? "100%" : "4.375rem",
        },
    })
)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DashboardOtherFiguresChartTableRow = ({
                                                height,
                                                title,
                                                labels,
                                                backgroundColor = Palette.none,
                                            }: {
    height: string;
    title: string;
    labels: optional<string>[];
    backgroundColor?: string;
}) => {
    return (
        <LayoutContainer $height={height} $backgroundColor={backgroundColor}>
            <TableCellLayoutContainer $grow={false}>
                <TableTitleCellContainer $bold={false}>
                    {title}
                </TableTitleCellContainer>
            </TableCellLayoutContainer>
            {labels.map((v, index) => (
                <TableCellLayoutContainer key={index} $grow={true}>
                    <TableCell
                        bold={index === labels.length - 1}
                        value={
                            v ?? S.dashboardOtherFiguresChartTableRow.emptyLabel
                        }
                    />
                </TableCellLayoutContainer>
            ))}
        </LayoutContainer>
    );
};

export default DashboardOtherFiguresChartTableRow;

const TableTitleCellContainer = styled(animated.p).attrs<{
    $bold: boolean;
}>((props) => ({
    style: {
        fontWeight: props.$bold ? FontWeights.bold : FontWeights.medium,
    },
}))`
    ${Fonts.detail2Medium};
    text-align: center;
    word-break: keep-all;
`;

const TableCell = ({bold, value}: { bold: boolean; value: string }) => {
    const {props} = useFadeInTransition(value);

    return (
        <TableTitleCellContainer style={props} $bold={bold}>
            {value}
        </TableTitleCellContainer>
    );
};
