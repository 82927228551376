import Dot from "presentation/components/common/dot";
import StatisticNumberLarge from "presentation/components/statistic/statistic_number_large";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $height: string }>((props) => ({
    style: {
        height: props.$height,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: height 0.3s ease-in-out;
`;

const TitleLayoutContainer = styled.div`
    min-height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

const TitleContainer = styled.h6`
    ${Fonts.detail3Medium};
    color: ${Palette.gray600};
    text-align: center;
`;

const TransactionsTableSummaryItem = ({
                                          height,
                                          color,
                                          title,
                                          value,
                                      }: {
    height: string;
    color?: string;
    title: string;
    value?: number;
}) => {
    return (
        <LayoutContainer $height={height}>
            <TitleLayoutContainer>
                {color && <Dot color={color}/>}
                <TitleContainer>{title}</TitleContainer>
            </TitleLayoutContainer>
            <StatisticNumberLarge
                value={value}
                unit={S.transactionsTableSummaryItem.wonUnit}
                color={Palette.gray800}
                unitColor={Palette.gray600}
            />
        </LayoutContainer>
    );
};

export default TransactionsTableSummaryItem;
