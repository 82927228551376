import profitReportState from "presentation/states/profit_report/profit_report_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const profitReportDispensingStatusBoardSelector = selector({
    key: "profitReportDispensingStatusBoardSelector",
    get: ({get}) => {
        const state = get(profitReportState);

        return {
            taxStartDate: DateTime.fromKey(state.taxStartDateKey),
            selectedDateFilter: state.dispensing.statusBoard.selectedDateFilter,
            startDate: DateTime.fromKey(
                state.dispensing.statusBoard.startDateKey
            ),
            endDate: DateTime.fromKey(state.dispensing.statusBoard.endDateKey),
            accumulated: state.dispensing.statusBoard.accumulated,
            data: state.dispensing.statusBoard.data,
        };
    },
});

export default profitReportDispensingStatusBoardSelector;
