import {ProfitReportFiguresDetailTypeMap} from "domain/model/tax/profit_report/profit_report_figures_detail_type";
import ProfitReportFiguresType, {
    ProfitReportFiguresTypeMap,
} from "domain/model/tax/profit_report/profit_report_figures_type";
import HorizontalStackedBarChart
    from "presentation/components/charts/horizontal_stacked_bar_chart/horizontal_stacked_bar_chart";
import SizedBox from "presentation/components/common/sized_box";
import Spacer from "presentation/components/common/spacer";
import StatisticNumberLarge from "presentation/components/statistic/statistic_number_large";
import StatisticTitleLarge from "presentation/components/statistic/statistic_title_large";
import DashboardFiguresComparison from "presentation/pages/dashboard/components/dashboard_card_figures_comparison";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import DashboardOtherFiguresChartLayout
    from "presentation/pages/dashboard/view/other_figures/chart/components/dashboard_other_figures_chart_layout";
import DashboardOtherFiguresChartLegends
    from "presentation/pages/dashboard/view/other_figures/chart/components/dashboard_other_figures_chart_legends";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import dashboardProfitSelector from "presentation/states/dashboard/selector/dashboard_profit_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useMemo} from "react";
import {useRecoilValue} from "recoil";

const DashboardOtherFiguresChartPurchasesView = () => {
    const {contentHeight} = useContext(DashboardRowContext);

    const {previousYearMonth, selectedYearMonth} = useRecoilValue(
        dashboardDateSelector
    );
    const {data} = useRecoilValue(dashboardProfitSelector);

    const {total, previousTotal, purchasesData, legendData} = useMemo(() => {
        const currentData = data.find((d) =>
            d.date.isSameMonth(selectedYearMonth)
        );
        const previousData = data.find((d) =>
            d.date.isSameMonth(previousYearMonth)
        );

        const purchasesFiguresTypes =
            ProfitReportFiguresTypeMap.profitReportFiguresDetailTypes(
                ProfitReportFiguresType.Purchases
            );
        const purchasesData = purchasesFiguresTypes.map((t) => ({
            label: ProfitReportFiguresDetailTypeMap.shortLabel(t),
            value: 0,
            color: ProfitReportFiguresDetailTypeMap.color(t),
        }));
        purchasesFiguresTypes.forEach((_, i) => {
            purchasesData[i].value += currentData?.purchases[i + 1] ?? 0;
        });

        const total = currentData?.purchases[0];
        const previousTotal = previousData?.purchases[0];

        const legendData = currentData
            ? purchasesData
            : [
                {
                    label: S.dashboardPage.otherFigures.cardTitle,
                    color: Palette.orange600,
                },
                {
                    label: S.dashboardPage.otherFigures.cashTitle,
                    color: Palette.orange500,
                },
                {
                    label: S.dashboardPage.otherFigures.taxInvoiceTitle,
                    color: Palette.orange300,
                },
            ];

        return {
            total,
            previousTotal,
            purchasesData,
            legendData,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <DashboardOtherFiguresChartLayout height={contentHeight!}>
            <StatisticTitleLarge
                title={S.dashboardPage.otherFigures.purchasesTitle}
                color={Palette.gray800}
            />
            <StatisticNumberLarge
                value={total}
                unit={S.dashboardPage.wonUnit}
                color={Palette.gray800}
                unitColor={Palette.gray600}
            />
            <SizedBox height={"8px"}/>
            <DashboardFiguresComparison
                titleColor={Palette.gray800}
                previousValue={previousTotal}
                value={total}
            />
            <Spacer/>
            <HorizontalStackedBarChart
                data={purchasesData}
                hoverEnabled={false}
                showLegends={false}
            />
            <SizedBox height={"17.5px"}/>
            <DashboardOtherFiguresChartLegends data={legendData}/>
        </DashboardOtherFiguresChartLayout>
    );
};

export default DashboardOtherFiguresChartPurchasesView;
