import NumberHelper from "config/helper/number_helper";
import BillingMonthlyFigures from "domain/model/tax/billing/billing_monthly_figures";
import TableCellLayout from "presentation/components/table/table_cell_layout";
import TableRowLayout from "presentation/components/table/table_row_layout";
import {
    BillingYearlyTableContext
} from "presentation/pages/billing/view/yearly_table/components/billing_yearly_table/billing_yearly_table";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LabelContainer = styled.p.attrs<{ $bold: boolean }>((props) => ({
    style: {
        fontWeight: props.$bold ? FontWeights.bold : FontWeights.medium,
    },
}))`
    min-width: max-content;
    ${Fonts.detail2Medium};
    text-align: center;
`;

const BillingYearlyTableRow = ({data}: { data: BillingMonthlyFigures }) => {
    const context = useContext(BillingYearlyTableContext);

    const _data = useMemo(
        () =>
            [
                data.billingAmount,
                data.billingCount,
                data.pendingAmount,
                data.pendingCount,
                data.taxes,
                data.receiveAmount,
                data.receiveCount,
            ].map((d, index) => ({
                label: NumberHelper.toStringWithDividers(d),
                flex: index === 4 ? 2 : 1,
                leftMarginInPx:
                    index === 2 || index === 4 || index === 5
                        ? context.constants.horizontalGapInPx
                        : 0,
                rightDivider: index === 1 || index === 3 || index === 4,
                bold: index === 5,
            })),
        [data, context.constants]
    );

    return (
        <TableRowLayout>
            {_data.map((d, index) => (
                <TableCellLayout
                    key={index}
                    flex={d.flex}
                    minWidthInPx={context.constants.cellMinWidthInPx}
                    heightInPx={context.constants.cellHeightInPx}
                    leftMarginInPx={d.leftMarginInPx}
                    rightDivider={d.rightDivider}
                    roundLeft={false}
                    roundRight={false}
                    backgroundColor={Palette.none}
                >
                    <LabelContainer $bold={d.bold}>{d.label}</LabelContainer>
                </TableCellLayout>
            ))}
        </TableRowLayout>
    );
};

export default BillingYearlyTableRow;
