import NumberHelper from "config/helper/number_helper";
import CardSalesFigures from "domain/model/tax/card_sales/card_sales_figures";
import TableCellLayout from "presentation/components/table/table_cell_layout";
import TableRowLayout from "presentation/components/table/table_row_layout";
import {
    CardSalesReceiveTableContext
} from "presentation/pages/card_sales/view/status_board/components/card_sales_receive_table/card_sales_receive_table";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LabelContainer = styled.p.attrs<{
    $bold: boolean;
}>((props) => ({
    style: {
        fontWeight: props.$bold ? FontWeights.bold : FontWeights.medium,
    },
}))`
    flex: 1;
    min-width: max-content;
    ${Fonts.detail2Medium};
    text-align: center;
`;

const CardSalesReceiveTableRow = ({data}: { data: CardSalesFigures }) => {
    const context = useContext(CardSalesReceiveTableContext);

    const _data = useMemo(() => {
        const values = [
            [data.date.format(S.cardSalesReceiveTable.dateFormat)],
            [data.receiveAmount],
            [data.purchasesAmount, data.purchasesCount],
            [data.transactionsFee],
        ];

        return values.map((v, index) => ({
            flex: index === 0 ? undefined : 1,
            maxWidthInPx:
                index === 0 ? context.constants.dateColumnWidthInPx : undefined,
            leftMarginInPx:
                index !== 0 ? context.constants.horizontalGapInPx : 0,
            rightDivider: index !== 0 && index !== values.length - 1,
            labels: v.map((value) => ({
                value:
                    index === 0
                        ? value
                        : NumberHelper.toStringWithDividers(value as number),
                bold: index === 1,
            })),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, context.constants]);

    return (
        <TableRowLayout>
            {_data.map((d, index) => (
                <TableCellLayout
                    key={index}
                    flex={d.flex}
                    maxWidthInPx={d.maxWidthInPx}
                    minWidthInPx={context.constants.cellMinWidthInPx}
                    heightInPx={context.constants.cellHeightInPx}
                    leftMarginInPx={d.leftMarginInPx}
                    rightDivider={d.rightDivider}
                    roundLeft={false}
                    roundRight={false}
                    backgroundColor={Palette.none}
                >
                    {d.labels.map((l, labelIndex) => (
                        <LabelContainer key={labelIndex} $bold={l.bold}>
                            {l.value}
                        </LabelContainer>
                    ))}
                </TableCellLayout>
            ))}
        </TableRowLayout>
    );
};

export default CardSalesReceiveTableRow;
