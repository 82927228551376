import {animated, useSprings} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import {ProfitChartContext} from "presentation/components/charts/profit_chart/profit_chart";
import Dot from "presentation/components/common/dot";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {Fragment, useContext, useEffect, useMemo} from "react";
import styled from "styled-components";

const BarContainer = styled(animated.div).attrs<{
    $visible: boolean;
    $widthInPx: number;
    $heightInPx: number;
    $borderRadiusInPx: number;
    $roundTop: boolean;
    $roundBottom: boolean;
    $topOffsetInPx?: number;
    $bottomOffsetInPx?: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
        borderTopLeftRadius: props.$roundTop
            ? `${props.$borderRadiusInPx}px`
            : 0,
        borderTopRightRadius: props.$roundTop
            ? `${props.$borderRadiusInPx}px`
            : 0,
        borderBottomLeftRadius: props.$roundBottom
            ? `${props.$borderRadiusInPx}px`
            : 0,
        borderBottomRightRadius: props.$roundBottom
            ? `${props.$borderRadiusInPx}px`
            : 0,
        top:
            props.$topOffsetInPx !== undefined
                ? `${props.$topOffsetInPx}px`
                : "auto",
        bottom:
            props.$bottomOffsetInPx !== undefined
                ? `${props.$bottomOffsetInPx}px`
                : "auto",
        left: `${props.$leftOffsetInPx}px`,
        opacity: props.$visible ? 1 : 0,
    },
}))`
    background-color: ${Palette.black5};
    position: absolute;
    z-index: 1;
    transform: translateX(-50%);
    transition: height 0.3s ease-in-out, border-radius 0.3s ease-in-out,
    left 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

const TooltipContainer = styled(animated.div).attrs<{
    $heightInPx: number;
    $horizontalPaddingInPx: number;
    $topOffsetInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        height: `${props.$heightInPx}px`,
        padding: `0 ${props.$horizontalPaddingInPx}px`,
        top: `${props.$topOffsetInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
    },
}))`
    min-width: max-content;
    border-radius: 12px;
    background-color: ${Palette.black75};
    box-shadow: 0 0 0 1px ${Palette.white20} inset;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translateX(-50%);
    z-index: 15;
    transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
`;

const TooltipColorContainer = styled(Dot)<{ $visible: boolean }>`
    width: ${(props) => (props.$visible ? "10px" : "0px")};
    margin-right: ${(props) => (props.$visible ? "4px" : "0px")};
    opacity: ${(props) => (props.$visible ? 1 : 0)};
    transition: width 0.3s ease-in-out, margin-right 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
`;

const TooltipLabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail1};
    color: ${Palette.white100};
    text-align: center;
`;

const ProfitChartCollectingBars = () => {
    const context = useContext(ProfitChartContext);

    const data = useMemo(() => {
        if (!context.highlightedData) return [];

        return Array.from(context.highlightedData!).map((d) => {
            const value =
                d.value !== undefined
                    ? NumberHelper.toStringWithDividers(d.value)
                    : undefined;
            const xPos = context.functions.getXPos(d.x);

            const upperYPosEnd = context.functions.getYPosFromTop(d.yTop);
            const upperYPosStart = context.functions.getYPosFromTop(0);
            const lowerYPosStart = context.functions.getYPosFromTop(0);
            const lowerYPosEnd = context.functions.getYPosFromTop(d.yBottom);

            const upperBarBottomOffset = context.functions.getYPosFromBottom(0);
            const lowerBarTopOffset = context.functions.getYPosFromTop(0);

            const upperHeight = Math.abs(upperYPosEnd - upperYPosStart);
            const lowerHeight = Math.abs(lowerYPosStart - lowerYPosEnd);
            const upperBorderRadius =
                upperHeight >= context.constants.barBorderRadiusInPx
                    ? context.constants.barBorderRadiusInPx
                    : upperHeight;
            const lowerBorderRadius =
                lowerHeight >= context.constants.barBorderRadiusInPx
                    ? context.constants.barBorderRadiusInPx
                    : lowerHeight;

            let tooltipYPos =
                upperYPosEnd -
                context.constants.collectingTooltipMarginInPx -
                context.constants.collectingTooltipHeightInPx;

            if (tooltipYPos < 0) tooltipYPos = 0;

            return {
                key: d.x,
                barVisible: value === undefined,
                value,
                xPos,
                upperBarBottomOffset,
                lowerBarTopOffset,
                upperHeight,
                lowerHeight,
                upperBorderRadius,
                lowerBorderRadius,
                tooltipYPos,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.highlightedData, context.functions, context.constantsKey]);

    const [upperSprings, upperAPI] = useSprings(data.length, () => ({
        height: "0px",
    }));
    const [lowerSprings, lowerAPI] = useSprings(data.length, () => ({
        height: "0px",
    }));

    useEffect(() => {
        upperAPI.start((index) => ({
            height: `${data[index].upperHeight}px`,
        }));
        lowerAPI.start((index) => ({
            height: `${data[index].lowerHeight}px`,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.data, context.constantsKey]);

    return (
        <>
            {upperSprings.map((props, xIndex) => {
                const d = data[xIndex];

                return (
                    <Fragment key={d.key}>
                        <BarContainer
                            style={props}
                            $visible={d.barVisible}
                            $widthInPx={context.constants.binSizeInPx}
                            $heightInPx={d.upperHeight}
                            $borderRadiusInPx={d.upperBorderRadius}
                            $roundTop={true}
                            $roundBottom={false}
                            $bottomOffsetInPx={d.upperBarBottomOffset}
                            $leftOffsetInPx={d.xPos}
                        />
                        <TooltipContainer
                            $horizontalPaddingInPx={
                                context.constants
                                    .collectingTooltipHorizontalPaddingInPx
                            }
                            $heightInPx={
                                context.constants.collectingTooltipHeightInPx
                            }
                            $topOffsetInPx={d.tooltipYPos}
                            $leftOffsetInPx={d.xPos}
                        >
                            <TooltipColorContainer
                                color={Palette.lightGreen}
                                $visible={d.value !== undefined}
                            />
                            <TooltipLabelContainer>
                                {d.value !== undefined
                                    ? d.value
                                    : S.profitChart.highlighted
                                        .tooltipEmptyLabel}
                            </TooltipLabelContainer>
                        </TooltipContainer>
                    </Fragment>
                );
            })}
            {lowerSprings.map((props, xIndex) => {
                const d = data[xIndex];

                return (
                    <BarContainer
                        key={d.key}
                        style={props}
                        $visible={d.barVisible}
                        $widthInPx={context.constants.binSizeInPx}
                        $heightInPx={d.lowerHeight}
                        $borderRadiusInPx={d.lowerBorderRadius}
                        $roundTop={false}
                        $roundBottom={true}
                        $topOffsetInPx={d.lowerBarTopOffset}
                        $leftOffsetInPx={d.xPos}
                    />
                );
            })}
        </>
    );
};

export default ProfitChartCollectingBars;
