import StatisticTitleLarge from "presentation/components/statistic/statistic_title_large";
import DashboardDetailButton from "presentation/pages/dashboard/components/dashboard_details_button";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import styled from "styled-components";

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const DashboardProfitChartHeaderView = () => {
    const isMobile = useMobileQuery();

    const title = isMobile
        ? S.dashboardPage.profit.headerTitleShort
        : S.dashboardPage.profit.headerTitle;

    const onClick = () => {
        const route = `${AppRoutes.ProfitReport}?type=profit&scrollTo=statusBoard`;
        AppRouter.navigate(route);
    };

    return (
        <LayoutContainer>
            <StatisticTitleLarge title={title} color={Palette.gray800}/>
            <DashboardDetailButton onClick={onClick}/>
        </LayoutContainer>
    );
};

export default DashboardProfitChartHeaderView;
