import {animated, useSpring} from "@react-spring/web";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useEffect, useRef} from "react";
import styled from "styled-components";

const visibleCount = 5;
const heightInPx = 52;
const gapInPx = 4;
const noticeHeightInPx = 17;

const HeaderLayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const HeaderTitleContainer = styled.h6.attrs<{
    $roundLeft: boolean;
    $roundRight: boolean;
}>((props) => ({
    style: {
        borderTopLeftRadius: props.$roundLeft ? "16px" : "0",
        borderBottomLeftRadius: props.$roundLeft ? "16px" : "0",
        borderTopRightRadius: props.$roundRight ? "16px" : "0",
        borderBottomRightRadius: props.$roundRight ? "16px" : "0",
    },
}))`
    width: 100%;
    flex-grow: 1;
    padding: 12px;
    background-color: ${Palette.gray100};
    ${Fonts.detail2};
    text-align: center;
`;

const ListLayoutContainer = styled.ul.attrs<{
    $heightInPx: number;
    $gapInPx: number;
}>((props) => ({
    style: {
        height: `${props.$heightInPx}px`,
        gap: `${gapInPx}px`,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    overflow-y: scroll;
    position: relative;
    transition: height 0.3s ease-in-out;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const RowLayoutContainer = styled.li.attrs<{
    $heightInPx: number;
    $highlight: boolean;
}>((props) => ({
    style: {
        minHeight: `${props.$heightInPx}px`,
    },
}))`
    width: 100%;
    border-radius: 16px;
    background-color: ${(props) =>
    props.$highlight ? Palette.gray50 : Palette.none};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: min-height 0.3s ease-in-out;
`;

const LabelContainer = styled.p`
    width: 100%;
    flex-grow: 1;
    ${Fonts.detail2Medium};
    text-align: center;
`;

const NoticeLayoutContainer = styled.div.attrs<{
    $visible: boolean;
    $heightInPx: number;
}>((props) => ({
    style: {
        height: props.$visible ? `${props.$heightInPx}px` : "0px",
        opacity: props.$visible ? 1 : 0,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

const NoticeContainer = styled.p.attrs<{ $heightInPx: number }>((props) => ({
    style: {
        height: `${props.$heightInPx}px`,
    },
}))`
    width: 100%;
    ${Fonts.detail1};
    color: ${Palette.gray500};
    text-align: end;
`;

const EmptyLabelPositionContainer = styled(animated.div)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
`;

const EmptyLabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail3Medium};
    color: ${Palette.gray500};
    text-align: center;
`;

const DialogList = ({
                        autoSize,
                        titles,
                        data,
                    }: {
    autoSize: boolean;
    titles: string[];
    data: string[][];
}) => {
    const listRef = useRef<HTMLUListElement>(null);

    const [props, api] = useSpring(() => ({
        opacity: 0,
    }));

    useEffect(() => {
        if (!listRef.current) return;

        listRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [listRef, data]);

    useEffect(() => {
        if (!!data.length) {
            api.set({
                opacity: 0,
            });
            return;
        }

        api.start({
            opacity: 1,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!data.length]);

    const _visibleCount = autoSize
        ? Math.min(data.length, visibleCount)
        : visibleCount;
    const listHeight = Math.max(
        0,
        heightInPx * _visibleCount + gapInPx * (_visibleCount - 1)
    );
    const noticeVisible = data.length > _visibleCount;
    const noticeLayoutHeight = noticeHeightInPx + gapInPx;

    return (
        <>
            <HeaderLayoutContainer>
                {titles.map((title, index) => (
                    <HeaderTitleContainer
                        key={title}
                        $roundLeft={index === 0}
                        $roundRight={index === titles.length - 1}
                    >
                        {title}
                    </HeaderTitleContainer>
                ))}
            </HeaderLayoutContainer>
            <ListLayoutContainer
                ref={listRef}
                $heightInPx={listHeight}
                $gapInPx={gapInPx}
            >
                <EmptyLabelPositionContainer style={props}>
                    <EmptyLabelContainer>
                        {S.dialogList.emptyLabel}
                    </EmptyLabelContainer>
                </EmptyLabelPositionContainer>
                {data.map((row, rowIndex) => (
                    <RowLayoutContainer
                        key={rowIndex}
                        $heightInPx={heightInPx}
                        $highlight={rowIndex % 2 === 1}
                    >
                        {row.map((label, labelIndex) => (
                            <LabelContainer key={labelIndex}>
                                {label}
                            </LabelContainer>
                        ))}
                    </RowLayoutContainer>
                ))}
            </ListLayoutContainer>
            <NoticeLayoutContainer
                $visible={noticeVisible}
                $heightInPx={noticeLayoutHeight}
            >
                <NoticeContainer $heightInPx={noticeHeightInPx}>
                    {S.dialogList.noticeLabel}
                </NoticeContainer>
            </NoticeLayoutContainer>
        </>
    );
};

export default DialogList;
