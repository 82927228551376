import {animated} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $rightMarginInPx: number }>(
    (props) => ({
        style: {
            marginRight: `${props.$rightMarginInPx}px`,
        },
    })
)`
    width: max-content;
    height: 1.8125rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const LabelContainer = styled(animated.p).attrs<{
    $autoScaleUp: boolean;
    $color: string;
}>((props) => ({
    style: {
        color: props.$color,
    },
}))`
    ${Fonts.body3};
    transition: font-size 0.3s ease-in-out, font-weight 0.3s ease-in-out,
        line-height 0.3s ease-in-out, color 0.3s ease-in-out;

    @media (max-width: 768px) {
        ${(props) => (props.$autoScaleUp ? Fonts.emphasis1 : Fonts.body3)};
        color: ${Palette.white100};
    }

    @media (min-width: 1280px) {
        ${(props) => (props.$autoScaleUp ? Fonts.emphasis1 : Fonts.body3)};
        color: ${Palette.white100};
    }
`;

const UnitContainer = styled.p.attrs<{ $color: string }>((props) => ({
    style: {
        color: props.$color,
    },
}))`
    ${Fonts.detail2Medium};
    transition: color 0.3s ease-in-out;
`;

const StatisticNumberSmall = ({
                                  autoScaleUp = true,
                                  value,
                                  unit,
                                  color,
                                  unitColor,
                                  rightMarginInPx = 0,
                              }: {
    autoScaleUp?: boolean;
    value?: number;
    unit: string;
    color: string;
    unitColor?: string;
    rightMarginInPx?: number;
}) => {
    const _value =
        value !== undefined
            ? NumberHelper.toStringWithDividers(value!)
            : S.statisticItem.emptyFigures;

    const {props} = useFadeInTransition(_value);

    return (
        <LayoutContainer $rightMarginInPx={rightMarginInPx}>
            <LabelContainer
                style={props}
                $autoScaleUp={autoScaleUp}
                $color={color}
            >
                {_value}
            </LabelContainer>
            <UnitContainer $color={unitColor ?? color}>{unit}</UnitContainer>
        </LayoutContainer>
    );
};

export default StatisticNumberSmall;
