import TransactionsDailyFigures from "domain/model/tax/transactions/transactions_daily_figures";
import TransactionsFiguresType, {
    TransactionsFiguresTypeMap,
} from "domain/model/tax/transactions/transactions_figures_type";
import {TransactionsTypeMap} from "domain/model/tax/transactions/transactions_type";
import CalendarListBox from "presentation/components/calendar/common/calendar_list_box";
import SizedBox from "presentation/components/common/sized_box";
import TransactionsDetailDialog
    from "presentation/components/dialog/transactions_detail_dialog/transactions_detail_dialog";
import TransactionsList from "presentation/components/list/transactions_list/transactions_list";
import TransactionsCalendarListButtons
    from "presentation/pages/transactions/components/transactions_calendar_list_buttons";
import {
    TransactionsCalendarListViewContext,
    TransactionsPageContext,
} from "presentation/pages/transactions/transactions_page";
import transactionsCalendarListSelector
    from "presentation/states/transactions/selector/transactions_calendar_list_selector";
import {TransactionsListScrollToTop} from "presentation/states/transactions/transactions_listenable_state";
import {transactionsListenableState} from "presentation/states/transactions/transactions_state";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";
import {useListenableState} from "presentation/utils/hooks/use_listenable_state";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import {useContext, useEffect, useMemo, useRef} from "react";
import {useRecoilValue} from "recoil";

const all = "all";

const TransactionsListView = () => {
    const showDialog = useShowDialog();
    const {calendarConstants, transactionsType} = useContext(
        TransactionsPageContext
    );
    const {
        transactionsFiguresType,
        contentWidth,
        contentHeight,
        calendarYearMonth,
        setTransactionsFiguresType,
    } = useContext(TransactionsCalendarListViewContext);

    const {taxStartDate, data} = useRecoilValue(
        transactionsCalendarListSelector
    );

    const ref = useRef<HTMLUListElement>(null);

    useListenableState(transactionsListenableState, (state) => {
        if (state instanceof TransactionsListScrollToTop) {
            ref.current?.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    });

    const startDate = taxStartDate;
    const endDate = DateTime.minNow();

    const {allFiguresTypes, figuresTypes, labels} = useMemo(
        () => {
            const allFiguresTypes = [
                all,
                ...TransactionsTypeMap.transactionsFiguresTypes(
                    transactionsType!
                ),
            ];

            const figuresTypes = TransactionsTypeMap.transactionsFiguresTypes(
                transactionsType!
            );

            const labels = [
                S.transactionsPage.calendarList.allLabel,
                ...TransactionsTypeMap.transactionsFiguresTypes(
                    transactionsType!
                ).map((t) =>
                    TransactionsFiguresTypeMap.calendarListButtonsLabel(t)
                ),
            ];
            return {
                allFiguresTypes,
                figuresTypes,
                labels,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const {dataMap} = useMemo(() => {
        const monthStartDate = DateTime.max(startDate, taxStartDate);
        const monthEndDate = DateTime.min(
            endDate,
            calendarYearMonth.getLastDayInMonth()
        );

        const rangedData = data.filter((d) =>
            d.date.isInRange(monthStartDate, monthEndDate)
        );

        const dataMap: Record<string, TransactionsDailyFigures[]> = {};
        allFiguresTypes.forEach((ft, figuresTypeIndex) => {
            dataMap[ft] = rangedData
                .map((d) => {
                    if (figuresTypeIndex === 0) {
                        return d.values.at(0) ?? 0
                            ? {
                                date: d.date,
                                figures: figuresTypes
                                    .map((ft, index) => ({
                                        type: ft,
                                        value: d.values.at(index + 1) ?? 0,
                                    }))
                                    .filter((f) => f.value !== 0),
                            }
                            : undefined;
                    }

                    return d.values.at(figuresTypeIndex) ?? 0
                        ? {
                            date: d.date,
                            figures: [
                                {
                                    type: ft as TransactionsFiguresType,
                                    value: d.values.at(figuresTypeIndex) ?? 0,
                                },
                            ].filter((f) => f.value !== 0),
                        }
                        : undefined;
                })
                .filter((d) => d !== undefined)
                .map((d) => d!);
        });

        return {
            dataMap,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, allFiguresTypes, figuresTypes]);

    useEffect(() => {
        addPostFrameCallback(() =>
            ref?.current?.scrollTo({
                top: 0,
                behavior: "smooth",
            })
        );
    }, [dataMap, transactionsFiguresType]);

    const onTileClick = (date: DateTime, type: TransactionsFiguresType) =>
        showDialog({
            className: "transactions-detail-dialog",
            component: <TransactionsDetailDialog date={date} type={type}/>,
        });

    const selectedIndex = allFiguresTypes.findIndex(
        (t) => t === (transactionsFiguresType ?? all)
    );

    const onSelectedIndexChange = (index: number) => {
        if (index === 0) {
            setTransactionsFiguresType(undefined);
            return;
        }

        setTransactionsFiguresType(figuresTypes[index - 1]);
    };

    return (
        <CalendarListBox
            width={contentWidth}
            height={contentHeight}
            paddingInPx={calendarConstants.marginInPx}
        >
            <TransactionsCalendarListButtons
                labels={labels}
                selectedIndex={selectedIndex}
                onSelectedIndexChange={onSelectedIndexChange}
            />
            <SizedBox height={"24px"}/>
            <TransactionsList
                ref={ref}
                data={dataMap[transactionsFiguresType ?? all]}
                onTileClick={onTileClick}
            />
        </CalendarListBox>
    );
};

export default TransactionsListView;
