import TableCellLayout from "presentation/components/table/table_cell_layout";
import TableRowLayout from "presentation/components/table/table_row_layout";
import TableSecondaryHeaderLabel from "presentation/components/table/table_secondary_header_label";
import {
    TaxTimelineTableContext
} from "presentation/pages/tax_timeline/components/tax_timeline_table/tax_timeline_table";
import Palette from "presentation/theme/palette";
import {useContext} from "react";

const TaxTimelineTableHeader = () => {
    const context = useContext(TaxTimelineTableContext);

    const heightInPx = context.constants.headerCellHeightInPx;
    const leftMarginInPx = context.constants.horizontalGapInPx;
    const backgroundColor = Palette.gray700;
    const color = Palette.white100;

    return (
        <TableRowLayout>
            {context.headerLabels.map((l, index) => (
                <TableCellLayout
                    key={index}
                    flex={l.flex}
                    minWidthInPx={l.minWidthInPx}
                    maxWidthInPx={
                        l.flex !== undefined ? undefined : l.minWidthInPx
                    }
                    heightInPx={heightInPx}
                    leftMarginInPx={index !== 0 ? leftMarginInPx : 0}
                    roundLeft={true}
                    roundRight={true}
                    backgroundColor={
                        l.label !== undefined ? backgroundColor : Palette.none
                    }
                >
                    <TableSecondaryHeaderLabel
                        label={l.label ?? ""}
                        color={color}
                    />
                </TableCellLayout>
            ))}
        </TableRowLayout>
    );
};

export default TaxTimelineTableHeader;
