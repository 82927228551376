import NumberHelper from "config/helper/number_helper";
import {TransactionsFiguresTypeMap} from "domain/model/tax/transactions/transactions_figures_type";
import {TransactionsTypeMap} from "domain/model/tax/transactions/transactions_type";
import CalendarBox from "presentation/components/calendar/common/calendar_box";
import TransactionsCalendar from "presentation/components/calendar/transactions_calendar/transactions_calendar";
import {
    TransactionsCalendarListViewContext,
    TransactionsPageContext,
} from "presentation/pages/transactions/transactions_page";
import transactionsCalendarListSelector
    from "presentation/states/transactions/selector/transactions_calendar_list_selector";
import {TransactionsListScrollToTop} from "presentation/states/transactions/transactions_listenable_state";
import {transactionsListenableState} from "presentation/states/transactions/transactions_state";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {useSetListenableState} from "presentation/utils/hooks/use_set_listenable_state";
import {optional} from "presentation/utils/types/optional";
import {useContext, useMemo} from "react";
import {useRecoilValue} from "recoil";

const all = "all";

const TransactionsCalendarView = () => {
    const {calendarConstants, transactionsType} = useContext(
        TransactionsPageContext
    );
    const {
        transactionsFiguresType,
        contentWidth,
        contentHeight,
        calendarYearMonth,
        setCalendarYearMonth,
    } = useContext(TransactionsCalendarListViewContext);
    const setListenableState = useSetListenableState(
        transactionsListenableState
    );

    const {taxStartDate, data} = useRecoilValue(
        transactionsCalendarListSelector
    );

    const startDate = taxStartDate;
    const endDate = DateTime.minNow();

    const {totalMap, suffixMap} = useMemo(() => {
        const figuresTypes = [
            all,
            ...TransactionsTypeMap.transactionsFiguresTypes(transactionsType!),
        ];
        const monthStartDate = DateTime.max(startDate, taxStartDate);
        const monthEndDate = DateTime.min(
            endDate,
            calendarYearMonth.getLastDayInMonth()
        );

        const rangedData = data.filter((d) =>
            d.date.isInRange(monthStartDate, monthEndDate)
        );

        const totalMap: Record<string, optional<number>> = {};
        figuresTypes.forEach((t, index) => {
            const total = rangedData
                .map((d) => d.values.at(index) ?? 0)
                .reduce((acc, cur) => acc + cur, 0);

            totalMap[t] = total === 0 ? undefined : total;
        });

        const suffixMap: Record<string, Record<string, optional<string>>> = {};
        figuresTypes.forEach((t, index) => {
            suffixMap[t] = {};
            rangedData.forEach((d) => {
                suffixMap[t][d.date.key] =
                    d.values[index] !== 0
                        ? NumberHelper.toCalendarAbbreviatedString(
                            d.values[index]
                        )
                        : undefined;
            });
        });

        return {
            totalMap,
            suffixMap,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, startDate.key, endDate.key]);

    const figuresHeaderTitle =
        transactionsFiguresType !== undefined
            ? S.transactionsPage.calendarList.totalLabel(
                TransactionsFiguresTypeMap.shortLabel(
                    transactionsFiguresType!
                )
            )
            : S.transactionsPage.calendarList.totalLabel(
                TransactionsTypeMap.transactionsPageCalendarLabel(
                    transactionsType!
                )
            );

    const onFiguresTodayButtonClick = () =>
        setListenableState(new TransactionsListScrollToTop());

    return (
        <CalendarBox
            width={contentWidth}
            height={contentHeight}
            paddingInPx={calendarConstants.marginInPx}
        >
            <TransactionsCalendar
                constants={calendarConstants}
                figuresHeaderTitle={figuresHeaderTitle}
                figuresHeaderValue={totalMap[transactionsFiguresType ?? all]}
                onFiguresTodayButtonClick={onFiguresTodayButtonClick}
                startDate={startDate}
                endDate={endDate}
                initialDate={calendarYearMonth}
                suffixMap={suffixMap[transactionsFiguresType ?? all]}
                onYearMonthChange={setCalendarYearMonth}
            />
        </CalendarBox>
    );
};

export default TransactionsCalendarView;
