import {WeekdayType} from "domain/model/common/weekday_type";

export enum DateDifferenceUnitType {
    Year,
    Month,
    Day,
    Hour,
    Minute,
    Second,
}

export default class DateTime {
    private static dateLimit: number = 8640000000000000;
    static daysInWeek: number = 7;
    static monthsInYear: number = 12;
    static maxWeekdayIndex: number = 6;
    static maxWeeksInMonth: number = 6;

    private dateObject: Date;

    constructor(
        year: number,
        month: number,
        day?: number,
        hour?: number,
        minute?: number,
        second?: number,
        millisecond?: number
    ) {
        this.dateObject = new Date(
            year,
            month - 1,
            day ?? 1,
            hour ?? 0,
            minute ?? 0,
            second ?? 0,
            millisecond ?? 0
        );
    }

    static get orthodoxWeekdays(): WeekdayType[] {
        return [
            WeekdayType.Sun,
            WeekdayType.Mon,
            WeekdayType.Tue,
            WeekdayType.Wed,
            WeekdayType.Thu,
            WeekdayType.Fri,
            WeekdayType.Sat,
        ];
    }

    static fromDate(date: Date): DateTime {
        return new DateTime(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        );
    }

    static fromMillisecondsSinceEpoch(
        millisecondsSinceEpoch: number
    ): DateTime {
        return this.fromDate(new Date(millisecondsSinceEpoch));
    }

    static fromKey(key: string): DateTime {
        try {
            const millisecondsSinceEpoch = parseInt(key);
            if (isNaN(millisecondsSinceEpoch)) {
                throw new Error("Invalid DateTime key");
            }

            return this.fromMillisecondsSinceEpoch(millisecondsSinceEpoch);
        } catch (_) {
            throw new Error("Invalid DateTime key");
        }
    }

    static minNow(): DateTime {
        return DateTime.now().min;
    }

    static maxNow(): DateTime {
        return DateTime.now().max;
    }

    static minDate(): DateTime {
        return this.fromDate(new Date(-this.dateLimit));
    }

    static maxDate(): DateTime {
        return this.fromDate(new Date(this.dateLimit));
    }

    static now(): DateTime {
        return this.fromDate(new Date());
    }

    static min(a: DateTime, b: DateTime): DateTime {
        if (a.millisecondsSinceEpoch <= b.millisecondsSinceEpoch) {
            return a;
        }

        return b;
    }

    static max(a: DateTime, b: DateTime): DateTime {
        if (a.millisecondsSinceEpoch >= b.millisecondsSinceEpoch) {
            return a;
        }

        return b;
    }

    get year(): number {
        return this.dateObject.getFullYear();
    }

    get month(): number {
        return this.dateObject.getMonth() + 1;
    }

    get day(): number {
        return this.dateObject.getDate();
    }

    get hour(): number {
        return this.dateObject.getHours();
    }

    get minute(): number {
        return this.dateObject.getMinutes();
    }

    get second(): number {
        return this.dateObject.getSeconds();
    }

    get millisecond(): number {
        return this.dateObject.getMilliseconds();
    }

    get orthodoxWeekdayIndex(): number {
        return this.dateObject.getDay();
    }

    get weekdayIndex(): number {
        const currentWeekDay = this.dateObject.getDay();

        return currentWeekDay === 0 ? 6 : currentWeekDay - 1;
    }

    get weekday(): WeekdayType {
        return Object.values(WeekdayType)[this.weekdayIndex];
    }

    get min(): DateTime {
        return this.copyWith({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        });
    }

    get max(): DateTime {
        return this.copyWith({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
        });
    }

    get millisecondsSinceEpoch(): number {
        return this.dateObject.getTime();
    }

    get key(): string {
        return this.millisecondsSinceEpoch.toString();
    }

    get isSunday(): boolean {
        return this.weekdayIndex === 6;
    }

    get isFirstDayInMonth(): boolean {
        return this.day === 1;
    }

    get isLastDayInMonth(): boolean {
        return this.day === this.getLastDayInMonth().day;
    }

    get orthodoxWeekCountInMonth(): number {
        const firstDayInMonth = this.getFirstDayInMonth();
        const lastDayInMonth = this.getLastDayInMonth();
        let weekCount = 0;
        let pointer = firstDayInMonth.copyWith();
        while (pointer.isSameMonth(this)) {
            pointer = pointer.copyWith({
                day: pointer.day + DateTime.daysInWeek,
            });
            weekCount++;
        }

        pointer = pointer.copyWith({day: pointer.day - DateTime.daysInWeek});
        if (
            lastDayInMonth.orthodoxWeekdayIndex < pointer.orthodoxWeekdayIndex
        ) {
            weekCount++;
        }

        return weekCount;
    }

    copyWith({
                 year,
                 month,
                 day,
                 hour,
                 minute,
                 second,
                 millisecond,
             }: {
        year?: number;
        month?: number;
        day?: number;
        hour?: number;
        minute?: number;
        second?: number;
        millisecond?: number;
    } = {}): DateTime {
        return new DateTime(
            year ?? this.year,
            month ?? this.month,
            day ?? this.day,
            hour ?? this.hour,
            minute ?? this.minute,
            second ?? this.second,
            millisecond ?? this.millisecond
        );
    }

    toString() {
        return this.format("yyyy-MM-dd HH:mm:ss");
    }

    subtract(target: DateTime, unitType: DateDifferenceUnitType): number {
        const diffMs =
            this.millisecondsSinceEpoch - target.millisecondsSinceEpoch;
        const numberOfMonths = this.year * 12 + this.month;
        const numberOfMonthsForTarget = target.year * 12 + target.month;
        const diffMonths = numberOfMonths - numberOfMonthsForTarget;

        let result;
        switch (unitType) {
            case DateDifferenceUnitType.Year:
                result = diffMonths / 12;
                break;
            case DateDifferenceUnitType.Month:
                result = diffMonths;
                break;
            case DateDifferenceUnitType.Day:
                result = diffMs / (1000 * 60 * 60 * 24);
                break;
            case DateDifferenceUnitType.Hour:
                result = diffMs / (1000 * 60 * 60);
                break;
            case DateDifferenceUnitType.Minute:
                result = diffMs / (1000 * 60);
                break;
            case DateDifferenceUnitType.Second:
                result = diffMs / 1000;
                break;
            default:
                throw Error("Invalid date difference unit type");
        }

        return Math.floor(result);
    }

    format(format: string): string {
        const padLeft = (value: number) => {
            return value.toString().length === 1
                ? `0${value}`
                : value.toString();
        };

        const padLeftString = (value: string) => {
            return value.length === 1 ? `0${value}` : value;
        };

        return format.replace(
            /(yyyy|yy|MM|M|DD|dd|D|d|HH|H|mm|m|i|ss|s)/g,
            (t: string): any => {
                switch (t) {
                    case "yyyy":
                        return this.year;
                    case "yy":
                        return padLeftString(this.year.toString().slice(-2));
                    case "MM":
                        return padLeft(this.month);
                    case "M":
                        return this.month;
                    case "DD":
                    case "dd":
                        return padLeft(this.day);
                    case "D":
                    case "d":
                        return this.day;
                    case "HH":
                        return padLeft(this.hour);
                    case "H":
                        return this.hour;
                    case "mm":
                        return padLeft(this.minute);
                    case "m":
                        return this.minute;
                    case "i":
                        return padLeft(this.minute);
                    case "ss":
                        return padLeft(this.second);
                    case "s":
                        return this.second;
                    default:
                        return "";
                }
            }
        );
    }

    matchesWith(dateString: string): boolean {
        return this.format(dateString) === dateString;
    }

    isSameYear(dateTime: DateTime): boolean {
        return this.year === dateTime.year;
    }

    isSameMonth(dateTime: DateTime): boolean {
        return this.isSameYear(dateTime) && this.month === dateTime.month;
    }

    isSameDay(dateTime?: DateTime): boolean {
        if (!dateTime) return false;

        return (
            this.isSameYear(dateTime) &&
            this.isSameMonth(dateTime) &&
            this.day === dateTime.day
        );
    }

    isEqual(dateTime?: DateTime): boolean {
        if (!dateTime) return false;

        return this.millisecondsSinceEpoch === dateTime.millisecondsSinceEpoch;
    }

    isBefore(dateTime: DateTime): boolean {
        return this.millisecondsSinceEpoch < dateTime.millisecondsSinceEpoch;
    }

    isAfter(dateTime: DateTime): boolean {
        return this.millisecondsSinceEpoch > dateTime.millisecondsSinceEpoch;
    }

    isSameDayOrBefore(dateTime: DateTime): boolean {
        return this.isSameDay(dateTime) || this.isBefore(dateTime);
    }

    isSameDayOrAfter(dateTime: DateTime): boolean {
        return this.isSameDay(dateTime) || this.isAfter(dateTime);
    }

    isInRange(start?: DateTime, end?: DateTime): boolean {
        if (!start || !end) return false;

        return this.isSameDayOrAfter(start) && this.isSameDayOrBefore(end);
    }

    getFirstDayInMonth(): DateTime {
        return new DateTime(this.year, this.month, 1);
    }

    getLastDayInMonth(): DateTime {
        return new DateTime(this.year, this.month + 1, 0);
    }

    getFirstDayInYear(): DateTime {
        return new DateTime(this.year, 1, 1);
    }

    getLastDayInYear(): DateTime {
        return new DateTime(this.year, 12, 31);
    }

    getOrthodoxWeek(): DateTime[] {
        const currentDate = this.min;
        const firstDayOfWeek = currentDate.copyWith({
            day: currentDate.day - currentDate.orthodoxWeekdayIndex,
        });

        return Array.from(
            {
                length: DateTime.daysInWeek,
            },
            (_, index) => {
                return firstDayOfWeek.copyWith({
                    day: firstDayOfWeek.day + index,
                });
            }
        );
    }

    getWeek(): DateTime[] {
        const currentDate = this.min;
        const firstDayOfWeek = currentDate.copyWith({
            day: currentDate.day - currentDate.weekdayIndex,
        });

        return Array.from(
            {
                length: DateTime.daysInWeek,
            },
            (_, index) => {
                return firstDayOfWeek.copyWith({
                    day: firstDayOfWeek.day + index,
                });
            }
        );
    }

    getOrthodoxWeeksInMonth(): DateTime[][] {
        const date = this.min;
        const firstDayInMonth = date.getFirstDayInMonth();
        const lastDayInMonth = date.getLastDayInMonth();

        const firstDayOfFirstWeek = firstDayInMonth.getOrthodoxWeek()[0]!;
        const firstDayOfLastWeek = lastDayInMonth.getOrthodoxWeek()[0]!;

        const totalWeekCount =
            Math.floor(
                firstDayOfLastWeek.subtract(
                    firstDayOfFirstWeek,
                    DateDifferenceUnitType.Day
                )
            ) /
            7 +
            1;

        return Array.from(
            {
                length: totalWeekCount,
            },
            (_, weekNumber) => Array.from(
                {
                    length: DateTime.daysInWeek,
                },
                (_, dayNumber) => {
                    return firstDayOfFirstWeek.copyWith({
                        day:
                            firstDayOfFirstWeek.day +
                            weekNumber * DateTime.daysInWeek +
                            dayNumber,
                    });
                }
            )
        );
    }

    getWeeksInMonth(): DateTime[][] {
        const date = this.min;
        const firstDayInMonth = date.getFirstDayInMonth();
        const lastDayInMonth = date.getLastDayInMonth();

        const firstDayOfFirstWeek = firstDayInMonth.getWeek()[0]!;
        const firstDayOfLastWeek = lastDayInMonth.getWeek()[0]!;

        const totalWeekCount =
            Math.floor(
                firstDayOfLastWeek.subtract(
                    firstDayOfFirstWeek,
                    DateDifferenceUnitType.Day
                )
            ) /
            7 +
            1;

        return Array.from(
            {
                length: totalWeekCount,
            },
            (_, weekNumber) => Array.from(
                {
                    length: DateTime.daysInWeek,
                },
                (_, dayNumber) => {
                    return firstDayOfFirstWeek.copyWith({
                        day:
                            firstDayOfFirstWeek.day +
                            weekNumber * DateTime.daysInWeek +
                            dayNumber,
                    });
                }
            )
        );
    }
}
