import CalendarCellSuffix from "presentation/components/calendar/common/calendar_cell_suffix";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $visible: boolean;
    $widthInPx: number;
    $heightInPx: number;
}>((props) => ({
    style: {
        opacity: props.$visible ? 1 : 0,
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: opacity 0.3s ease-in-out;
`;

const ValueLayoutContainer = styled.div.attrs<{
    $widthInPx: number;
    $heightInPx: number;
    $backgroundColor: string;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
        backgroundColor: props.$backgroundColor,
    },
}))`
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
        color 0.3s ease-in-out;
`;

const ValueContainer = styled.p.attrs<{ $color: string }>((props) => ({
    style: {
        color: props.$color,
    },
}))`
    ${Fonts.body1};
    text-align: center;
    transition: color 0.3s ease-in-out;
`;

const CalendarCell = ({
                          visible,
                          enabled,
                          selected,
                          holiday,
                          widthInPx,
                          heightInPx,
                          valueWidthInPx,
                          valueHeightInPx,
                          value,
                          suffix,
                          suffixHighlighted = false,
                      }: {
    visible: boolean;
    enabled: boolean;
    holiday: boolean;
    selected: boolean;
    widthInPx: number;
    heightInPx: number;
    valueWidthInPx: number;
    valueHeightInPx: number;
    value: string;
    suffix?: string;
    suffixHighlighted?: boolean;
}) => {
    const color = selected
        ? Palette.gray100
        : !enabled
            ? Palette.gray300
            : holiday
                ? Palette.red500
                : Palette.gray800;

    const valueBackgroundColor = selected ? Palette.gray600 : Palette.none;

    return (
        <LayoutContainer
            $visible={visible}
            $widthInPx={widthInPx}
            $heightInPx={heightInPx}
        >
            <ValueLayoutContainer
                $widthInPx={valueWidthInPx}
                $heightInPx={valueHeightInPx}
                $backgroundColor={valueBackgroundColor}
            >
                <ValueContainer $color={color}>{value}</ValueContainer>
            </ValueLayoutContainer>
            <CalendarCellSuffix
                highlighted={suffixHighlighted}
                value={suffix}
            />
        </LayoutContainer>
    );
};

export default CalendarCell;
