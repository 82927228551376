import ContentBox from "presentation/components/content/content_box";
import SizedBox from "presentation/components/common/sized_box";
import ProfitReportProfitStatusBoardAccumulatedValuesButtonView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_accumulated_values_button_view";
import ProfitReportProfitStatusBoardDateButtonsView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_date_buttons_view";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {useRef} from "react";
import styled from "styled-components";
import ProfitReportProfitStatusBoardSalesDonutChartView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_sales_donut_chart_view";
import ProfitReportProfitStatusBoardProfitDonutChartView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_profit_donut_chart_view";
import ProfitReportProfitStatusBoardSummaryView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_summary_view";
import ProfitReportProfitStatusBoardProfitChartTitleView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_profit_chart_title_view";
import ProfitReportProfitStatusBoardDonutChartTitleView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_donut_chart_title_view";
import ContentLayout from "presentation/components/content/content_layout";
import {useReplayState} from "presentation/utils/hooks/use_replay_state";
import {profitReportReplayState} from "presentation/states/profit_report/profit_report_state";
import {ProfitReportScrollToElement} from "presentation/states/profit_report/profit_report_replay_state";
import useScrollIntoView from "presentation/utils/hooks/use_scroll_into_view";
import ViewStatusType from "domain/model/common/view_status_type";
import ProfitReportProfitStatusBoardDataChartView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_data_chart_view";
import ProfitReportProfitStatusBoardPurchasesChartView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_purchases_chart_view";
import ProfitReportProfitStatusBoardOtherExpenseChartView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_other_expenses_chart_view";

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 36px;
    transition: gap 0.3s ease-in-out;

    @media (max-width: 768px) {
        gap: 48px;
    }
`;

const ColumnContainer = styled.div`
    width: 249px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;

    @media (max-width: 400px) {
        transform: scale(0.8);
    }
`;

const ProfitReportProfitStatusBoardView = ({
                                               status,
                                           }: {
    status: ViewStatusType;
}) => {
    const scrollIntoView = useScrollIntoView();

    const boxRef = useRef<HTMLDivElement>(null);
    const layoutRef = useRef<HTMLDivElement>(null);

    const {height: contentHeight} = useResizeObserver(layoutRef, []);

    useReplayState(
        profitReportReplayState,
        (state, handled) => {
            if (state instanceof ProfitReportScrollToElement) {
                if (state.target !== "statusBoard") return;

                scrollIntoView(boxRef);
                handled();
            }
        },
        status
    );

    return (
        <ContentBox ref={boxRef} contentHeight={contentHeight}>
            <ContentLayout ref={layoutRef}>
                <ProfitReportProfitStatusBoardDateButtonsView/>
                <SizedBox height={"36px"}/>
                <ProfitReportProfitStatusBoardProfitChartTitleView/>
                <SizedBox height={"24px"}/>
                <ProfitReportProfitStatusBoardSummaryView/>
                <SizedBox height={"36px"}/>
                <ProfitReportProfitStatusBoardAccumulatedValuesButtonView/>
                <SizedBox height={"24px"}/>
                <ProfitReportProfitStatusBoardDataChartView/>
                <SizedBox height={"36px"}/>
                <ProfitReportProfitStatusBoardDonutChartTitleView/>
                <SizedBox height={"24px"}/>
                <RowContainer>
                    <ProfitReportProfitStatusBoardSalesDonutChartView/>
                    <ProfitReportProfitStatusBoardProfitDonutChartView/>
                    <ColumnContainer>
                        <ProfitReportProfitStatusBoardPurchasesChartView/>
                        <ProfitReportProfitStatusBoardOtherExpenseChartView/>
                    </ColumnContainer>
                </RowContainer>
            </ContentLayout>
        </ContentBox>
    );
};

export default ProfitReportProfitStatusBoardView;
