import BaseRepository from "data/repository/base_repository";
import JointCert from "domain/model/joint_cert/joint_cert";
import JointCertKey from "domain/model/joint_cert/joint_cert_key";
import ExternalSync from "domain/model/tax/external_syncs/external_sync";
import {optional} from "presentation/utils/types/optional";
import DateTime from "presentation/utils/extension/date_extension";

let homeTaxAccount = undefined as optional<string>;
let crefiaAccount = undefined as optional<string>;
let hiraSynced = false;
let insuranceSynced = false;

export default class ExternalSyncsRepository extends BaseRepository {
    getExternalSync = async (): Promise<ExternalSync> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                return {
                    homeTax: homeTaxAccount
                        ? {
                            label: homeTaxAccount,
                            registeredDateTime: DateTime.now(),
                        }
                        : undefined,
                    crefia: crefiaAccount
                        ? {
                            label: crefiaAccount,
                            registeredDateTime: DateTime.now(),
                        }
                        : undefined,
                    hira: hiraSynced
                        ? {
                            label: "인증서",
                            registeredDateTime: DateTime.now(),
                        }
                        : undefined,
                    insurance: insuranceSynced
                        ? {
                            label: "인증서",
                            registeredDateTime: DateTime.now(),
                        }
                        : undefined,
                };
            },
        });

    syncHomeTax = async ({
                             userID,
                             password,
                         }: {
        userID: string;
        password: string;
    }): Promise<void> =>
        await this.handler({
            request: async () => {
                await this.delayed(3000);

                if (Math.random() < 0.2) {
                    this.throwServerSentError(
                        "홈택스 연동 실패",
                        "홈택스 연동 중 오류가 발생했습니다."
                    );
                }

                homeTaxAccount = userID;
            },
        });

    syncCrefia = async ({
                            userID,
                            password,
                        }: {
        userID: string;
        password: string;
    }): Promise<void> =>
        await this.handler({
            request: async () => {
                await this.delayed(3000);

                if (Math.random() < 0.2) {
                    this.throwServerSentError(
                        "여신금융협회 연동 실패",
                        "여신금융협회 연동 중 오류가 발생했습니다."
                    );
                }

                crefiaAccount = userID;
            },
        });

    syncHira = async ({
                          jointCert,
                          jointCertKey,
                      }: {
        jointCert: JointCert;
        jointCertKey: JointCertKey;
    }): Promise<void> =>
        await this.handler({
            request: async () => {
                await this.delayed(3000);

                if (Math.random() < 0.2) {
                    this.throwServerSentError(
                        "심사평가원 연동 실패",
                        "심사평가원 연동 중 오류가 발생했습니다."
                    );
                }

                hiraSynced = true;
            },
        });

    syncInsurance = async ({
                               jointCert,
                               jointCertKey,
                           }: {
        jointCert: JointCert;
        jointCertKey: JointCertKey;
    }): Promise<void> =>
        await this.handler({
            request: async () => {
                await this.delayed(3000);

                if (Math.random() < 0.2) {
                    this.throwServerSentError(
                        "4대보험 공단 연동 실패",
                        "4대보험 공단 연동 중 오류가 발생했습니다."
                    );
                }

                insuranceSynced = true;
            },
        });

    getRecentData = async (): Promise<void> =>
        await this.handler({
            request: async () => {
                await this.delayed(50000);

                if (Math.random() < 0.2) {
                    this.throwServerSentError(
                        "연동 실패",
                        "최근 자료 불러오기에 실패했어요."
                    );
                }
            },
        });
}
