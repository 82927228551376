import {useSetRecoilState} from "recoil";
import uiState from "presentation/states/ui/ui_state";

const useDismissDialog = () => {
    const setState = useSetRecoilState(uiState);

    return (className?: string) => {
        if (!className) {
            setState((prev) => {
                const newDialogState = Array.from(prev.dialogState);
                const popped = newDialogState.pop();

                setTimeout(() => {
                    popped?.onDismissed?.();
                }, 300);

                return {
                    ...prev,
                    dialogState: newDialogState,
                };
            });
            return;
        }

        setState((prev) => {
            const match = prev.dialogState.find(
                (state) => state.className === className
            );

            if (!match) {
                return prev;
            }

            setTimeout(() => {
                match.onDismissed?.();
            }, 300);

            const newDialogState = Array.from(prev.dialogState).filter(
                (state) => state.className !== match!.className
            );
            return {
                ...prev,
                dialogState: newDialogState,
            };
        });
    };
};

export default useDismissDialog;
