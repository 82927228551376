import SizedBox from "presentation/components/common/sized_box";
import ContentBox from "presentation/components/content/content_box";
import ContentLayout from "presentation/components/content/content_layout";
import BillingYearlyTableHeaderView
    from "presentation/pages/billing/view/yearly_table/billing_yearly_table_header_view";
import BillingYearlyTable
    from "presentation/pages/billing/view/yearly_table/components/billing_yearly_table/billing_yearly_table";
import billingYearlyTableSelector from "presentation/states/billing/selector/billing_yearly_table_selector";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {useRef} from "react";
import {useRecoilValue} from "recoil";

const BillingYearlyTableView = () => {
    const {data} = useRecoilValue(billingYearlyTableSelector);

    const layoutRef = useRef<HTMLDivElement>(null);

    const {height: contentHeight} = useResizeObserver(layoutRef, []);

    return (
        <ContentBox contentHeight={contentHeight}>
            <ContentLayout ref={layoutRef}>
                <BillingYearlyTableHeaderView/>
                <SizedBox height={"12px"}/>
                <BillingYearlyTable data={data}/>
            </ContentLayout>
        </ContentBox>
    );
};

export default BillingYearlyTableView;
