import CardSalesRepository from "data/repository/card_sales_repository";
import CardSalesDateFilterType, {
    CardSalesDateFilterTypeMap,
} from "domain/model/tax/card_sales/card_sales_date_filter_type";
import DateFilterPeriodButton from "presentation/components/button/date_filter_period_button/date_filter_period_button";
import OptionButton from "presentation/components/button/option_button";
import DateButtonsRow from "presentation/components/common/date_buttons_row";
import cardSalesState from "presentation/states/card_sales/card_sales_state";
import cardSalesStatusBoardSelector from "presentation/states/card_sales/selector/card_sales_status_board_selector";
import DateTime from "presentation/utils/extension/date_extension";
import useRepository from "presentation/utils/hooks/use_repository";
import {useMemo} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";

const CardSalesStatusBoardDateButtonsView = () => {
    const repository = useRepository(CardSalesRepository);

    const setState = useSetRecoilState(cardSalesState);
    const {selectedDateFilter, startDate, endDate} = useRecoilValue(
        cardSalesStatusBoardSelector
    );

    const dateFilters = useMemo(
        () => Object.values(CardSalesDateFilterType),
        []
    );

    const onClick = (type: CardSalesDateFilterType) => () =>
        repository({
            handler: async (repository) => {
                const {startDate, endDate} =
                    CardSalesDateFilterTypeMap.datePeriod(type);

                setState((prev) => ({
                    ...prev,
                    statusBoard: {
                        ...prev.statusBoard,
                        selectedDateFilter: type,
                        startDateKey: startDate.key,
                        endDateKey: endDate.key,
                    },
                }));

                const data = await repository.getDailyFiguresInRange({
                    startDate:
                    CardSalesDateFilterTypeMap.datePeriod(type).startDate,
                    endDate:
                    CardSalesDateFilterTypeMap.datePeriod(type).endDate,
                });

                setState((prev) => ({
                    ...prev,
                    statusBoard: {
                        ...prev.statusBoard,
                        selectedDateFilter: type,
                        startDateKey: startDate.key,
                        endDateKey: endDate.key,
                        data,
                    },
                }));
            },
        });

    const onDateSelected = (startDate: DateTime, endDate: DateTime) =>
        repository({
            handler: async (repository) => {
                const match = dateFilters.find(
                    (d) =>
                        CardSalesDateFilterTypeMap.datePeriod(
                            d
                        ).startDate.isEqual(startDate) &&
                        CardSalesDateFilterTypeMap.datePeriod(
                            d
                        ).endDate.isEqual(endDate)
                );

                setState((prev) => ({
                    ...prev,
                    statusBoard: {
                        ...prev.statusBoard,
                        selectedDateFilter: match,
                        startDateKey: startDate.key,
                        endDateKey: endDate.key,
                    },
                }));

                const data = await repository.getDailyFiguresInRange({
                    startDate,
                    endDate,
                });

                setState((prev) => ({
                    ...prev,
                    statusBoard: {
                        ...prev.statusBoard,
                        selectedDateFilter: undefined,
                        startDateKey: startDate.key,
                        endDateKey: endDate.key,
                        data,
                    },
                }));
            },
        });

    return (
        <DateButtonsRow>
            {dateFilters.map((d) => (
                <OptionButton
                    key={d}
                    selected={d === selectedDateFilter}
                    label={CardSalesDateFilterTypeMap.label(d)}
                    onClick={onClick(d)}
                />
            ))}
            <DateFilterPeriodButton
                startDate={startDate}
                endDate={endDate}
                onDateSelected={onDateSelected}
            />
        </DateButtonsRow>
    );
};

export default CardSalesStatusBoardDateButtonsView;
