import {animated, useChain, useSpring, useSpringRef} from "@react-spring/web";
import ViewStatusType from "domain/model/common/view_status_type";
import Loading from "presentation/components/common/loading";
import Palette from "presentation/theme/palette";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import useTNBHeight from "presentation/utils/hooks/use_tnb_height";
import useViewStatus from "presentation/utils/hooks/use_view_status";
import {PropsWithChildren} from "react";
import styled from "styled-components";
import GeneralErrorView from "presentation/components/error/general_error_view";

const alphabetRegex = /[a-zA-Z]/g;

const PageContainer = styled.div<{ $tnbHeight: string; $minWidth: string }>`
    min-width: ${(props) => props.$minWidth};
    min-height: ${(props) => `calc(100vh - ${props.$tnbHeight})`};
    padding: 24px 36px;
    background-color: ${Palette.gray100};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;
    transition: min-width 0.3s ease-in-out, padding 0.3s ease-in-out;

    @media (max-width: 768px) {
        padding: 24px 16px;
    }
`;

const ContentContainer = styled.div.attrs<{ $loading: boolean }>((props) => ({
    style: {
        pointerEvents: props.$loading ? "none" : "auto",
        opacity: props.$loading ? 0 : 1,
    },
}))`
    width: 100%;
    height: fit-content;
    transition: opacity 0.3s ease-in-out;
`;

const LoadingContainer = styled(animated.div)<{
    $lnbMinWidthInRem: number;
    $lnbMaxWidthInRem: number;
}>`
    position: fixed;
    top: max(50vh, 300px);
    left: ${(props) => `calc(50% + ${props.$lnbMaxWidthInRem}px)`};
    z-index: 200000;
    transform: ${(props) =>
            `translate(calc(-50% - ${props.$lnbMaxWidthInRem / 2}px), -50%)`};
    transition: left 0.3s ease-in-out, transform 0.3s ease-in-out;

    @media (max-width: 768px) {
        left: ${(props) => `calc(50% + ${props.$lnbMinWidthInRem}px)`};
        transform: ${(props) =>
                `translate(calc(-50% - ${props.$lnbMinWidthInRem / 2}px), -50%)`};
    }
`;

const ErrorContainer = styled(animated.div)``;

const Page = ({
                  className,
                  status = ViewStatusType.Loaded,
                  minWidth = "100%",
                  children,
              }: PropsWithChildren<{
    className?: string;
    status?: ViewStatusType;
    minWidth?: string;
}>) => {
    const theme = useThemeContext();
    const tnbHeight = useTNBHeight();

    const {loading, error} = useViewStatus(status);

    const lnbMinWidthInRem = Number(
        theme.componentTheme.lnbMinWidth.replace(alphabetRegex, "")
    );
    const lnbMaxWidthInRem = Number(
        theme.componentTheme.lnbMaxWidth.replace(alphabetRegex, "")
    );

    const errorDisplayPropsRef = useSpringRef();
    const errorOpacityPropsRef = useSpringRef();

    const errorDisplayProps = useSpring({
        ref: errorDisplayPropsRef,
        display: error ? "block" : "none",
    });
    const errorOpacityProps = useSpring({
        ref: errorOpacityPropsRef,
        visibility: (error ? "visible" : "hidden") as "hidden" | "visible",
        opacity: error ? 1 : 0,
    });

    useChain([errorOpacityPropsRef, errorDisplayPropsRef], [0, 0.5]);

    const loadingDisplayPropsRef = useSpringRef();
    const loadingOpacityPropsRef = useSpringRef();

    const loadingDisplayProps = useSpring({
        ref: loadingDisplayPropsRef,
        display: loading ? "block" : "none",
    });
    const loadingOpacityProps = useSpring({
        ref: loadingOpacityPropsRef,
        visibility: (loading ? "visible" : "hidden") as "hidden" | "visible",
        opacity: loading ? 1 : 0,
    });

    useChain([loadingOpacityPropsRef, loadingDisplayPropsRef], [0, 0.5]);

    return (
        <PageContainer
            className={className}
            $tnbHeight={tnbHeight}
            $minWidth={minWidth}
        >
            <ContentContainer $loading={loading}>{children}</ContentContainer>
            <ErrorContainer style={{
                ...errorDisplayProps,
                ...errorOpacityProps,
            }}>
                <GeneralErrorView/>
            </ErrorContainer>
            <LoadingContainer
                style={{
                    ...loadingDisplayProps,
                    ...loadingOpacityProps,
                }}
                $lnbMinWidthInRem={lnbMinWidthInRem}
                $lnbMaxWidthInRem={lnbMaxWidthInRem}
            >
                <Loading visible={true}/>
            </LoadingContainer>
        </PageContainer>
    );
};

export default Page;
