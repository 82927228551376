import TransactionsFiguresType, {
    TransactionsFiguresTypeMap,
} from "domain/model/tax/transactions/transactions_figures_type";
import S from "presentation/theme/s";

enum TransactionsType {
    Sales = "sales",
    ExpectedReceipts = "expectedReceipts",
    Purchases = "purchases",
}

export const TransactionsTypeMap = {
    label: (type: TransactionsType): string => {
        switch (type) {
            case TransactionsType.Sales:
                return S.transactionsType.salesLabel;
            case TransactionsType.ExpectedReceipts:
                return S.transactionsType.expectedReceiptsLabel;
            case TransactionsType.Purchases:
                return S.transactionsType.purchasesLabel;
            default:
                throw new Error("Invalid TransactionsType");
        }
    },
    shortLabel: (type: TransactionsType): string => {
        switch (type) {
            case TransactionsType.Sales:
                return S.transactionsType.salesShortLabel;
            case TransactionsType.ExpectedReceipts:
                return S.transactionsType.expectedReceiptsShortLabel;
            case TransactionsType.Purchases:
                return S.transactionsType.purchasesShortLabel;
            default:
                throw new Error("Invalid TransactionsType");
        }
    },
    transactionsFiguresTypes: (
        type: TransactionsType
    ): TransactionsFiguresType[] => {
        return Object.values(TransactionsFiguresType).filter(
            (t) => TransactionsFiguresTypeMap.transactionsType(t) === type
        );
    },
    hasTable: (type: TransactionsType): boolean => {
        switch (type) {
            case TransactionsType.Sales:
            case TransactionsType.Purchases:
                return true;
            case TransactionsType.ExpectedReceipts:
                return false;
            default:
                throw new Error("Invalid TransactionsType");
        }
    },
    transactionsPageCalendarLabel: (type: TransactionsType): string => {
        switch (type) {
            case TransactionsType.Sales:
                return S.transactionsType.salesTransactionsPageCalendarTitle;
            case TransactionsType.ExpectedReceipts:
                return S.transactionsType
                    .expectedReceiptsTransactionsPageCalendarTitle;
            case TransactionsType.Purchases:
                return S.transactionsType
                    .purchasesTransactionsPageCalendarTitle;
            default:
                throw new Error("Invalid TransactionsType");
        }
    },
};

export default TransactionsType;
