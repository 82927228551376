import ObjectConvertable from "presentation/routes/model/object_convertable";
import RouteMap from "presentation/routes/model/route_map";
import {ReactElement} from "react";

export default class NestedRouteMap extends RouteMap {
    children: Array<ObjectConvertable>;

    constructor({
                    route,
                    page,
                    redirectToMainOnAuthenticated,
                    redirectToLoginOnUnauthenticated,
                    children,
                }: {
        route: string;
        page: ReactElement;
        redirectToMainOnAuthenticated: boolean;
        redirectToLoginOnUnauthenticated: boolean;
        children: Array<ObjectConvertable>;
    }) {
        super({
            route,
            page,
            redirectToMainOnAuthenticated,
            redirectToLoginOnUnauthenticated,
        });
        this.children = children;
    }

    toObject(): Object {
        return {
            ...super.toObject(),
            children: this.children.map((child) => child.toObject()),
        };
    }
}
