import {animated} from "@react-spring/web";
import SizedBox from "presentation/components/common/sized_box";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useAnimatedMount from "presentation/utils/hooks/use_animated_mount";
import styled from "styled-components";

const LayoutContainer = styled(animated.div).attrs<{
    $minWidth: string;
}>((props) => ({
    style: {
        width: "100%",
        minWidth: props.$minWidth,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
`;

const LabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail3Medium};
    color: ${Palette.gray500};
    text-align: center;
`;

const TableEmptyLabel = ({minWidthInPx}: { minWidthInPx?: number }) => {
    const minWidth = minWidthInPx !== undefined ? `${minWidthInPx}px` : "100%";

    const {props} = useAnimatedMount();

    return (
        <LayoutContainer style={props} $minWidth={minWidth}>
            <SizedBox height={"60px"}/>
            <LabelContainer>{S.tableEmptyLabel.emptyLabel}</LabelContainer>
        </LayoutContainer>
    );
};

export default TableEmptyLabel;
