import ViewStatusType from "domain/model/common/view_status_type";
import SizedBox from "presentation/components/common/sized_box";
import ContentBox from "presentation/components/content/content_box";
import ContentLayout from "presentation/components/content/content_layout";
import ProfitReportDispensingStatusBoardChartView
    from "presentation/pages/profit_report/view/dispensing/status_board/profit_report_dispensing_status_board_chart_view";
import ProfitReportDispensingStatusBoardAccumulatedButtonView
    from "presentation/pages/profit_report/view/dispensing/status_board/profit_report_dispensing_status_board_accumulated_button_view";
import ProfitReportDispensingStatusBoardDateButtonsView
    from "presentation/pages/profit_report/view/dispensing/status_board/profit_report_dispensing_status_board_date_buttons_view";
import ProfitReportDispensingStatusBoardSummaryView
    from "presentation/pages/profit_report/view/dispensing/status_board/profit_report_dispensing_status_board_summary_view";
import ProfitReportDispensingStatusBoardTitleView
    from "presentation/pages/profit_report/view/dispensing/status_board/profit_report_dispensing_status_board_title_view";
import {ProfitReportScrollToElement} from "presentation/states/profit_report/profit_report_replay_state";
import {profitReportReplayState} from "presentation/states/profit_report/profit_report_state";
import {useReplayState} from "presentation/utils/hooks/use_replay_state";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import useScrollIntoView from "presentation/utils/hooks/use_scroll_into_view";
import {useRef} from "react";

const ProfitReportDispensingStatusBoardView = ({
                                                   status,
                                               }: {
    status: ViewStatusType;
}) => {
    const scrollIntoView = useScrollIntoView();

    const boxRef = useRef<HTMLDivElement>(null);
    const layoutRef = useRef<HTMLDivElement>(null);

    const {height: contentHeight} = useResizeObserver(layoutRef, []);

    useReplayState(
        profitReportReplayState,
        (state, handled) => {
            if (state instanceof ProfitReportScrollToElement) {
                if (state.target !== "statusBoard") return;

                scrollIntoView(boxRef);
                handled();
            }
        },
        status
    );

    return (
        <ContentBox ref={boxRef} contentHeight={contentHeight}>
            <ContentLayout ref={layoutRef}>
                <ProfitReportDispensingStatusBoardDateButtonsView/>
                <SizedBox height={"36px"}/>
                <ProfitReportDispensingStatusBoardTitleView/>
                <ProfitReportDispensingStatusBoardSummaryView/>
                <SizedBox height={"36px"}/>
                <ProfitReportDispensingStatusBoardAccumulatedButtonView/>
                <SizedBox height={"24px"}/>
                <ProfitReportDispensingStatusBoardChartView/>
            </ContentLayout>
        </ContentBox>
    );
};

export default ProfitReportDispensingStatusBoardView;
