import {animated} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import {
    ProfitReportYearlyTableCellData
} from "presentation/pages/profit_report/view/profit/yearly_table/components/profit_report_yearly_table/profit_report_yearly_table_model";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import {memo} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $backgroundColor: string;
    $roundRight: boolean;
    $hasRightBorder: boolean;
    $topMarginInPx: number;
    $bottomMarginInPx: number;
}>((props) => ({
    style: {
        backgroundColor: props.$backgroundColor,
        borderTopRightRadius: props.$roundRight ? "16px" : "0",
        borderBottomRightRadius: props.$roundRight ? "16px" : "0",
        marginTop: `${props.$topMarginInPx}px`,
        marginBottom: `${props.$bottomMarginInPx}px`,
        borderRight: `1px solid ${
            props.$hasRightBorder ? Palette.gray100 : Palette.white100
        }`,
    },
}))`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelContainer = styled(animated.p).attrs<{ $bold: boolean }>(
    (props) => ({
        style: {
            fontWeight: props.$bold ? FontWeights.bold : FontWeights.medium,
        },
    })
)`
    ${Fonts.detail2Medium};
    color: ${Palette.gray900};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ProfitReportYearlyTableCell = ({
                                         value,
                                         backgroundColor,
                                         roundRight,
                                         hasRightBorder,
                                         topMarginInPx,
                                         bottomMarginInPx,
                                         bold,
                                     }: ProfitReportYearlyTableCellData) => {
    const label =
        value !== undefined ? NumberHelper.toTableAbbreviatedString(value) : "";

    const {props} = useFadeInTransition(label);

    return (
        <LayoutContainer
            $backgroundColor={backgroundColor}
            $roundRight={roundRight}
            $hasRightBorder={hasRightBorder}
            $topMarginInPx={topMarginInPx}
            $bottomMarginInPx={bottomMarginInPx}
        >
            <LabelContainer style={props} $bold={bold}>
                {label}
            </LabelContainer>
        </LayoutContainer>
    );
};

export default memo(
    ProfitReportYearlyTableCell,
    (prev, next) => prev.value === next.value
);
