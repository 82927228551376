import TableCellLayout from "presentation/components/table/table_cell_layout";
import TablePrimaryHeaderLabel from "presentation/components/table/table_primary_header_label";
import TableRowLayout from "presentation/components/table/table_row_layout";
import TableSecondaryHeaderLabel from "presentation/components/table/table_secondary_header_label";
import {
    BillingYearlyTableContext
} from "presentation/pages/billing/view/yearly_table/components/billing_yearly_table/billing_yearly_table";
import Palette from "presentation/theme/palette";
import {useContext} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $gapInPx: number }>((props) => ({
    style: {
        gap: `${props.$gapInPx}px`,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const BillingYearlyTableHeader = () => {
    const context = useContext(BillingYearlyTableContext);

    const minWidthInPx = context.constants.cellMinWidthInPx;
    const secondaryHeaderHeightInPx =
        context.constants.secondaryHeaderCellHeightInPx;
    const primaryHeaderHeightInPx =
        context.constants.primaryHeaderCellHeightInPx;
    const primaryHeaderBackgroundColor = Palette.gray100;

    return (
        <LayoutContainer $gapInPx={context.constants.verticalGapInPx}>
            <TableRowLayout>
                {context.secondaryHeaderLabels.map((l, index) => (
                    <TableCellLayout
                        key={index}
                        flex={l.flex}
                        minWidthInPx={minWidthInPx}
                        heightInPx={secondaryHeaderHeightInPx}
                        leftMarginInPx={l.leftMarginInPx}
                        roundLeft={true}
                        roundRight={true}
                        backgroundColor={l.backgroundColor}
                    >
                        <TableSecondaryHeaderLabel
                            label={l.label}
                            color={l.color}
                        />
                    </TableCellLayout>
                ))}
            </TableRowLayout>
            <TableRowLayout
                borderRadiusInPx={
                    context.constants.primaryHeaderBorderRadiusInPx
                }
                roundLeft={true}
                roundRight={true}
                backgroundColor={primaryHeaderBackgroundColor}
            >
                {context.primaryHeaderLabels.map((l, index) => (
                    <TableCellLayout
                        key={index}
                        flex={l.flex}
                        minWidthInPx={minWidthInPx}
                        heightInPx={primaryHeaderHeightInPx}
                        leftMarginInPx={l.leftMarginInPx}
                        roundLeft={index === 0}
                        roundRight={
                            index === context.primaryHeaderLabels.length - 1
                        }
                        backgroundColor={primaryHeaderBackgroundColor}
                    >
                        <TablePrimaryHeaderLabel label={l.label}/>
                    </TableCellLayout>
                ))}
            </TableRowLayout>
        </LayoutContainer>
    );
};

export default BillingYearlyTableHeader;
