import SortType from "domain/model/common/sort_type";
import CardSalesCreditCardDropdownType from "domain/model/tax/card_sales/card_sales_credit_card_dropdown_type";
import CardSalesDateFilterType from "domain/model/tax/card_sales/card_sales_date_filter_type";
import CardSalesFigures from "domain/model/tax/card_sales/card_sales_figures";
import {CardSalesReplayState} from "presentation/states/card_sales/card_sales_replay_state";
import DateTime from "presentation/utils/extension/date_extension";
import {optional} from "presentation/utils/types/optional";
import {atom} from "recoil";
import {BehaviorSubject} from "rxjs";

type CardSalesStatusBoard = {
    selectedDateFilter?: CardSalesDateFilterType;
    startDateKey: string;
    endDateKey: string;
    selectedDropdownType: CardSalesCreditCardDropdownType;
    cancelFilterOn: boolean;
    pendingFilterOn: boolean;
    cardSalesDateSortType: SortType;
    cardSalesTotalSortType: SortType;
    receiveDateSortType: SortType;
    receiveTotalSortType: SortType;
    data: CardSalesFigures[];
};

type CardSalesState = {
    statusBoard: CardSalesStatusBoard;
};

export const cardSalesReplayState = new BehaviorSubject<
    optional<CardSalesReplayState>
>(undefined);

const cardSalesState = atom<CardSalesState>({
    key: "cardSalesState",
    default: {
        statusBoard: {
            selectedDateFilter: CardSalesDateFilterType.ThisMonth,
            startDateKey: DateTime.minNow().getFirstDayInMonth().key,
            endDateKey: DateTime.minNow().key,
            selectedDropdownType: CardSalesCreditCardDropdownType.All,
            cancelFilterOn: false,
            pendingFilterOn: false,
            cardSalesDateSortType: SortType.None,
            cardSalesTotalSortType: SortType.None,
            receiveDateSortType: SortType.None,
            receiveTotalSortType: SortType.None,
            data: [],
        },
    },
});

export default cardSalesState;
