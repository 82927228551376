import Palette from "presentation/theme/palette";
import {forwardRef, PropsWithChildren, ReactElement} from "react";
import styled from "styled-components";

const RowContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

const RelativeContainer = styled.div.attrs<{
    $scrollable: boolean;
    $heightInPx: number;
}>((props) => ({
    style: {
        overflowX: props.$scrollable ? "scroll" : "hidden",
        height: `${props.$heightInPx}px`,
    },
}))`
    flex-grow: 1;
    position: relative;
    overflow-y: hidden;
    scrollbar-color: ${Palette.none} ${Palette.none};
    transition: max-width 0.3s ease-in-out, scrollbar-color 0.3s ease-in-out;

    &:hover {
        scrollbar-color: ${Palette.gray300} ${Palette.none};
    }
`;

const ChartLayout = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{
        scrollable?: boolean;
        heightInPx: number;
        yAxis: ReactElement;
    }>
>(({scrollable = true, heightInPx, yAxis, children}, ref) => {
    return (
        <RowContainer>
            {yAxis}
            <RelativeContainer
                ref={ref}
                $scrollable={scrollable}
                $heightInPx={heightInPx}
            >
                {children}
            </RelativeContainer>
        </RowContainer>
    );
});

export default ChartLayout;
