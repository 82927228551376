import profitReportState from "presentation/states/profit_report/profit_report_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const profitReportProfitYearlyTableSelector = selector({
    key: "profitReportProfitYearlyTableSelector",
    get: ({get}) => {
        const state = get(profitReportState);

        return {
            taxStartDate: DateTime.fromKey(state.taxStartDateKey),
            selectedYear: DateTime.fromKey(
                state.profit.yearlyTable.selectedYearKey
            ),
            data: state.profit.yearlyTable.data,
        };
    },
});

export default profitReportProfitYearlyTableSelector;
