import {animated, useChain, useSpring, useSpringRef} from "@react-spring/web";
import ProfileIcon from "presentation/components/common/profile_icon";
import SizedBox from "presentation/components/common/sized_box";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import styled from "styled-components";

const LayoutContainer = styled.button`
    width: fit-content;
    padding: 4px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    background-color: ${Palette.none};
    border: none;
    position: relative;
`;

const ImageContainer = styled(animated.img)`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
`;

const LabelContainer = styled(animated.p)`
    ${Fonts.detail2};
    color: ${Palette.white100};
    white-space: nowrap;
`;

const TNBUserTile = ({
                         profileImageURL,
                         name,
                     }: {
    profileImageURL?: string;
    name?: string;
}) => {
    const isMobile = useMobileQuery();
    const imageProps = useSpring({
        opacity: profileImageURL ? 1 : 0,
    });

    const nameCollapsed = !name || isMobile;

    const namePropsRef = useSpringRef();
    const overflowPropsRef = useSpringRef();

    const nameProps = useSpring({
        ref: namePropsRef,
        marginLeft: nameCollapsed ? "0px" : "8px",
        marginRight: nameCollapsed ? "0px" : "8px",
        maxWidth: nameCollapsed ? "0rem" : "7rem",
        opacity: nameCollapsed ? 0 : 1,
        overflow: nameCollapsed ? "clip" : "hidden",
    });
    const overflowProps = useSpring({
        ref: overflowPropsRef,
        textOverflow: nameCollapsed ? "clip" : "ellipsis",
    });

    useChain([namePropsRef, overflowPropsRef], [0, 0.5]);

    return (
        <LayoutContainer>
            {!profileImageURL && <ProfileIcon width={"28px"} height={"28px"}/>}
            <ImageContainer
                style={imageProps}
                src={profileImageURL}
                alt={"profile"}
            />
            <SizedBox width={"8px"}/>
            <LabelContainer
                style={{
                    ...nameProps,
                    ...overflowProps,
                }}
            >
                {name}
            </LabelContainer>
        </LayoutContainer>
    );
};

export default TNBUserTile;
