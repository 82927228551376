import {animated} from "@react-spring/web";
import Fonts from "presentation/theme/fonts";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import {ReactElement} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const TitleContainer = styled(animated.p)`
    flex-grow: 1;
    min-width: max-content;
    ${Fonts.detail3};
`;

const ContentSectionTitle = ({
                                 title,
                                 suffix,
                             }: {
    title: string;
    suffix?: ReactElement;
}) => {
    const {props} = useFadeInTransition(title);

    return (
        <LayoutContainer>
            <TitleContainer style={props}>{title}</TitleContainer>
            {suffix}
        </LayoutContainer>
    );
};

export default ContentSectionTitle;
