import {WeekdayTypeMap} from "domain/model/common/weekday_type";
import SizedBox from "presentation/components/common/sized_box";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    background-color: ${Palette.white100};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    z-index: 2;
`;

const DateContainer = styled.h6`
    ${Fonts.detail1};
    color: ${Palette.gray500};
`;

const LineContainer = styled.div`
    flex-grow: 1;
    height: 0;
    border-top: 1px solid ${Palette.gray200};
`;

const TransactionsListTileDateHeader = ({date}: { date: DateTime }) => {
    return (
        <LayoutContainer>
            <DateContainer>
                {S.transactionsList.dateHeaderLabel(
                    date.format(S.transactionsList.dateFormat),
                    WeekdayTypeMap.label(date.weekday)
                )}
            </DateContainer>
            <SizedBox width={"12px"}/>
            <LineContainer/>
        </LayoutContainer>
    );
};

export default TransactionsListTileDateHeader;
