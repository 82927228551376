import localClientInstance from "data/local/local_client/local_client";
import secureStorageInstance from "data/local/secure_storage/secure_storage";
import httpClientInstance from "data/network/http_client/http_client";
import BaseRepository from "data/repository/base_repository";
import CommonError from "domain/model/common/common_error";
import DevLogType from "domain/model/common/dev_log_type";
import DomainError from "domain/model/common/domain_error";
import ErrorCode from "domain/model/common/error_code";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import devLog from "presentation/utils/functions/dev_log";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import useShowErrorDialog from "presentation/utils/hooks/use_show_error_dialog";
import {optional} from "presentation/utils/types/optional";

const useRepository = <Repository extends BaseRepository>(
    repository: new (...args: any[]) => Repository
) => {
    const showErrorDialog = useShowErrorDialog();
    const showNotificationDialog = useShowNotificationDialog();

    return async <T>({
                         handler,
                         onDomainError,
                         onFinally,
                     }: {
        handler: (repository: Repository) => Promise<T>;
        onDomainError?: (
            errorCode: ErrorCode,
            showUnknownError: () => void
        ) => void;
        onFinally?: () => void;
    }): Promise<optional<T>> => {
        const instance = new repository(
            httpClientInstance,
            localClientInstance,
            secureStorageInstance
        );

        try {
            return await handler(instance);
        } catch (e) {
            if (e instanceof CommonError) {
                switch (e.errorCode) {
                    case ErrorCode.ServerSentError:
                        showNotificationDialog({
                            type: "negative",
                            title: e.title,
                            message: e.message,
                            hasNegativeButton: false,
                        });
                        break;

                    case ErrorCode.TokenExpired:
                        AppRouter.navigate(AppRoutes.Login, {
                            replace: true,
                        });
                        break;

                    default:
                        break;
                }
                return;
            }

            if (!(e instanceof DomainError)) {
                devLog({
                    logType: DevLogType.Error,
                    message: e,
                    format: false,
                });
                return;
            }

            if (e.errorCode === ErrorCode.Cancelled) return;

            if (!onDomainError) {
                switch (e.errorCode) {
                    default:
                        showErrorDialog();
                        return;
                }
            }

            onDomainError?.(e.errorCode, showErrorDialog);
        } finally {
            onFinally?.();
        }
    };
};

export default useRepository;
