import {animated, useSpring} from "@react-spring/web";
import DashboardFiguresCardPendingListTile
    from "presentation/pages/dashboard/view/figures/card_pending/components/dashboard_figures_card_pending_list_tile";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import styled from "styled-components";
import CardSalesFiguresDetail from "domain/model/tax/card_sales/card_sales_figures_detail";
import CardSalesDetailDialog from "presentation/pages/card_sales/view/status_board/components/card_sales_detail_dialog";

const heightInPx = 33;

const LayoutContainer = styled.div`
    width: 100%;
    position: relative;
`;

const ColumnContainer = styled(animated.ul)`
    width: 100%;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
`;

const EmptyContainer = styled(animated.p)`
    min-width: max-content;
    ${Fonts.detail3Medium};
    color: ${Palette.gray500};
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    pointer-events: none;
    transform: translate(-50%, -50%);
`;

const DashboardFiguresCardPendingListView = ({
                                                 collapsed,
                                                 history,
                                             }: {
    collapsed: boolean;
    history: {
        date: DateTime;
        pending: number;
        count: number;
        details: CardSalesFiguresDetail[];
    }[];
}) => {
    const showDialog = useShowDialog();

    const height = heightInPx * (history.length ? history.length : 3);

    const historyProps = useSpring({
        opacity: history ? 1 : 0,
        height: `${height}px`,
    });
    const emptyProps = useSpring({
        opacity: history?.length ?? true ? 0 : 1,
    });

    const onClick = (date: DateTime, data: CardSalesFiguresDetail[]) => () =>
        showDialog({
            className: "card-sales-detail-dialog",
            component: (
                <CardSalesDetailDialog
                    title={S.dashboardPage.figures.cardPendingDetailDialogTitle}
                    date={date}
                    data={data}
                />
            ),
        });

    return (
        <LayoutContainer>
            <ColumnContainer style={historyProps}>
                {history &&
                    history!.map((h) => (
                        <DashboardFiguresCardPendingListTile
                            key={h.date.key}
                            collapsed={collapsed}
                            heightInPx={heightInPx}
                            date={h.date}
                            count={h.count}
                            pending={h.pending}
                            onClick={onClick(h.date, h.details)}
                        />
                    ))}
            </ColumnContainer>
            <EmptyContainer style={emptyProps}>
                {S.dashboardPage.figures.cardPendingDataEmptyLabel}
            </EmptyContainer>
        </LayoutContainer>
    );
};

export default DashboardFiguresCardPendingListView;
