import {animated} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $height: string }>((props) => ({
    style: {
        height: props.$height,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: height 0.3s ease-in-out;
`;

const TitleContainer = styled.h6`
    ${Fonts.detail3Medium};
    color: ${Palette.gray600};
    text-align: center;
`;

const NeedCheckLabelContainer = styled.p.attrs<{ $needCheck: boolean }>(
    (props) => ({
        style: {
            opacity: props.$needCheck ? 1 : 0,
        },
    })
)`
    ${Fonts.detail1};
    color: ${Palette.red500};
    text-align: center;
    transition: opacity 0.3s ease-in-out;
`;

const LabelLayoutContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

const LabelContainer = styled(animated.p).attrs<{ $color: string }>(
    (props) => ({
        style: {
            color: props.$color,
        },
    })
)`
    ${Fonts.headline3};
    transition: color 0.3s ease-in-out;
`;

const UnitContainer = styled.p`
    ${Fonts.detail2Medium};
    color: ${Palette.gray600};
`;

const BillingStatusBoardSummaryItem = ({
                                           needCheck = false,
                                           height,
                                           title,
                                           value,
                                           valueColor = Palette.gray800,
                                       }: {
    needCheck?: boolean;
    height: string;
    title: string;
    value?: number;
    valueColor?: string;
}) => {
    const label =
        value !== undefined
            ? NumberHelper.toStringWithDividers(value)
            : S.billingStatusBoardSummaryItem.emptyLabel;
    const {props} = useFadeInTransition(label);

    return (
        <LayoutContainer $height={height}>
            <TitleContainer>{title}</TitleContainer>
            <NeedCheckLabelContainer $needCheck={needCheck}>
                {S.billingStatusBoardSummaryItem.needCheckLabel}
            </NeedCheckLabelContainer>
            <LabelLayoutContainer>
                <LabelContainer style={props} $color={valueColor}>
                    {label}
                </LabelContainer>
                <UnitContainer>
                    {S.billingStatusBoardSummaryItem.eaUnit}
                </UnitContainer>
            </LabelLayoutContainer>
        </LayoutContainer>
    );
};

export default BillingStatusBoardSummaryItem;
