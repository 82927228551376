import Fonts from "presentation/theme/fonts";
import styled from "styled-components";

const LayoutContainer = styled.p.attrs<{ $color: string }>((props) => ({
    style: {
        color: props.$color,
    },
}))`
    min-width: max-content;
    ${Fonts.detail2};
    text-align: center;
`;

const TableSecondaryHeaderLabel = ({
                                       label,
                                       color,
                                   }: {
    label: string;
    color: string;
}) => {
    return <LayoutContainer $color={color}>{label}</LayoutContainer>;
};

export default TableSecondaryHeaderLabel;
