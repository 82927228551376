import {BillingDateFilterTypeMap} from "domain/model/tax/billing/billing_date_filter_type";
import ContentSectionTitle from "presentation/components/content/content_section_title";
import billingStatusBoardSelector from "presentation/states/billing/selector/billing_status_board_selector";
import S from "presentation/theme/s";
import {useRecoilValue} from "recoil";

const BillingStatusBoardFiguresTitleView = () => {
    const {selectedDateFilter} = useRecoilValue(billingStatusBoardSelector);

    const title = selectedDateFilter
        ? S.billingPage.statusBoard.figures.titleWithDateFilter(
            BillingDateFilterTypeMap.label(selectedDateFilter)
        )
        : S.billingPage.statusBoard.figures.title;

    return <ContentSectionTitle title={title}/>;
};

export default BillingStatusBoardFiguresTitleView;
