import {RecoilRoot} from "recoil";
import {RouterProvider} from "react-router-dom";
import AppRouter from "presentation/routes/app_router";
import GlobalTheme from "presentation/theme/theme";
import ToastProvider from "presentation/providers/toast_provider";
import ThemeProvider from "presentation/providers/theme_provider";
import ErrorBoundary from "presentation/components/error/error_boundary";
import DialogProvider from "presentation/providers/dialog_provider";
import animationTheme from "presentation/theme/animation";
import {fontTheme} from "presentation/theme/fonts";
import componentTheme from "presentation/theme/component";
import LoadingProvider from "presentation/providers/loading_provider";

export const App = () => {
    return (
        <RecoilRoot>
            <GlobalTheme/>
            <ThemeProvider
                animationTheme={animationTheme}
                fontTheme={fontTheme}
                componentTheme={componentTheme}
            >
                <ErrorBoundary>
                    <DialogProvider>
                        <RouterProvider router={AppRouter}/>
                        <ToastProvider/>
                    </DialogProvider>
                    <LoadingProvider/>
                </ErrorBoundary>
            </ThemeProvider>
        </RecoilRoot>
    );
};
