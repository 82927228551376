const apiURL = () => {
    return process.env.REACT_APP_API_URL;
};

const domainURL = () => {
    if (process.env.REACT_APP_DOMAIN_URL) {
        return process.env.REACT_APP_DOMAIN_URL;
    }

    return "https://localhost:3000";
};

const isDevelopment = () => {
    const value = process.env.REACT_APP_IS_DEVELOPMENT;
    return value === "true";
};

const imageBannerUrl = () => {
    return process.env.REACT_APP_JEEKIM_BANNER_URL;
};

const nxAppCode = () => {
    return process.env.REACT_APP_NX_APP_CODE;
};

const EnvironmentConstants = {
    apiURL: apiURL(),
    domainURL: domainURL(),
    isDevelopment: isDevelopment(),
    imageBannerUrl: imageBannerUrl(),
    nxAppCode: nxAppCode(),
};

export default EnvironmentConstants;
