import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    min-height: 24.4375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transform: scale(1);
    transition: min-height 0.3s ease-in-out, transform 0.3s ease-in-out;

    @media (max-width: 380px) {
        transform: scale(0.6);
    }

    @media only screen and (min-width: 381px) and (max-width: 550px) {
        transform: scale(0.8);
    }

    @media (min-width: 769px) {
        min-height: 20.8125rem;
    }
`;

const ProfitReportStatusBoardDonutChartColumn = ({
                                                     children,
                                                 }: PropsWithChildren) => {
    return <LayoutContainer>{children}</LayoutContainer>;
};

export default ProfitReportStatusBoardDonutChartColumn;
