import CheckToggleButton from "presentation/components/button/check_toggle_button";
import profitReportState from "presentation/states/profit_report/profit_report_state";
import profitReportProfitStatusBoardSelector
    from "presentation/states/profit_report/selector/profit/status_board/profit_report_status_board_selector";
import S from "presentation/theme/s";
import {useRecoilValue, useSetRecoilState} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const ProfitReportProfitStatusBoardAccumulatedValuesButtonView = () => {
    const {accumulated} = useRecoilValue(
        profitReportProfitStatusBoardSelector
    );
    const setState = useSetRecoilState(profitReportState);

    const onClick = () =>
        setState((prev) => ({
            ...prev,
            profit: {
                ...prev.profit,
                statusBoard: {
                    ...prev.profit.statusBoard,
                    accumulated: !prev.profit.statusBoard.accumulated,
                },
            },
        }));

    return (
        <LayoutContainer>
            <CheckToggleButton
                selected={accumulated}
                label={
                    S.profitReportPage.profit.statusBoard.profitChart
                        .accumulatedValuesButtonLabel
                }
                onClick={onClick}
            />
        </LayoutContainer>
    );
};

export default ProfitReportProfitStatusBoardAccumulatedValuesButtonView;
