import TableCellLayout from "presentation/components/table/table_cell_layout";
import TableRowLayout from "presentation/components/table/table_row_layout";
import {
    TaxTimelineTableContext
} from "presentation/pages/tax_timeline/components/tax_timeline_table/tax_timeline_table";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LableLayoutContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LabelContainer = styled.p.attrs<{ $heightInPx: number }>((props) => ({
    style: {
        minHeight: `${props.$heightInPx}px`,
    },
}))`
    min-width: max-content;
    ${Fonts.detail2};
    text-align: center;
`;

const TaxTimelineTableFixedRow = () => {
    const context = useContext(TaxTimelineTableContext);

    const _data = useMemo(() => {
        const labels = [
            {
                labels: [S.taxTimelineTable.everyMonthLabel],
                minWidthInPx: context.constants.monthCellWidthInPx,
            },
            {
                labels: [S.taxTimelineTable.fixedRowDueLabel],
                minWidthInPx: context.constants.dueCellMinWidthInPx,
            },
            {
                labels: [
                    S.taxTimelineTable.fixedRowDescriptionOne,
                    S.taxTimelineTable.fixedRowDescriptionTwo,
                ],
                minWidthInPx: context.constants.descriptionCellMinWidthInPx,
            },
        ];
        const maxContentCount = labels.reduce((acc, cur) => {
            return Math.max(acc, cur.labels.length);
        }, 0);

        return labels.map((d, index) => ({
            labels: d.labels,
            flex: index !== labels.length - 1 ? undefined : 1,
            minWidthInPx: d.minWidthInPx,
            heightInPx:
                maxContentCount * context.constants.cellContentHeightInPx +
                context.constants.cellVerticalPaddingInPx * 2,
            leftMarginInPx:
                index === 0 ? 0 : context.constants.horizontalGapInPx,
            rightDivider: false,
        }));
    }, [context.constants]);

    return (
        <TableRowLayout
            borderRadiusInPx={context.constants.rowBorderRadiusInPx}
            roundLeft={true}
            roundRight={true}
            backgroundColor={Palette.gray100}
        >
            {_data.map((d, cellIndex) => (
                <TableCellLayout
                    key={cellIndex}
                    flex={d.flex}
                    minWidthInPx={d.minWidthInPx}
                    maxWidthInPx={
                        d.flex !== undefined ? undefined : d.minWidthInPx
                    }
                    heightInPx={d.heightInPx}
                    leftMarginInPx={d.leftMarginInPx}
                    rightDivider={d.rightDivider}
                    roundLeft={false}
                    roundRight={false}
                    backgroundColor={Palette.none}
                >
                    <LableLayoutContainer>
                        {d.labels.map((label, labelIndex) => (
                            <LabelContainer
                                key={labelIndex}
                                $heightInPx={
                                    context.constants.cellContentHeightInPx
                                }
                            >
                                {label}
                            </LabelContainer>
                        ))}
                    </LableLayoutContainer>
                </TableCellLayout>
            ))}
        </TableRowLayout>
    );
};

export default TaxTimelineTableFixedRow;
