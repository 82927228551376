import AppRoutes from "presentation/routes/model/app_routes";
import NestedRouteMap from "presentation/routes/model/nested_route_map";
import RouteMap from "presentation/routes/model/route_map";
import ErrorRouteMap from "presentation/routes/model/error_route_map";
import RedirectRouteMap from "presentation/routes/model/redirect_route_map";
import MainPage from "presentation/pages/main/main_page";
import LoginPage from "presentation/pages/login/login_page";
import DashboardPage from "presentation/pages/dashboard/dashboard_page";
import BillingPage from "presentation/pages/billing/billing_page";
import CardSalesPage from "presentation/pages/card_sales/card_sales_page";
import CustomerServicePage from "presentation/pages/customer_service/customer_service";
import DocumentBundlePage from "presentation/pages/document_bundle/document_bundle_page";
import ExternalSyncsPage from "presentation/pages/external_syncs/external_syncs_page";
import ProfitReportPage from "presentation/pages/profit_report/profit_report_page";
import TaxTimelinePage from "presentation/pages/tax_timeline/tax_timeline_page";
import TransactionsPage from "presentation/pages/transactions/transactions_page";
import HumanResourcesPage from "presentation/pages/human_resources/human_resources_page";
import ProfilePage from "presentation/pages/profile/profile_page";

const AppRouteMapping = [
    new NestedRouteMap({
        route: AppRoutes.Main,
        page: <MainPage/>,
        redirectToMainOnAuthenticated: false,
        redirectToLoginOnUnauthenticated: false,
        children: [
            new RedirectRouteMap({route: AppRoutes.Dashboard}),
            new RouteMap({
                route: AppRoutes.Billing,
                page: <BillingPage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
            new RouteMap({
                route: AppRoutes.CardSales,
                page: <CardSalesPage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
            new RouteMap({
                route: AppRoutes.CustomerService,
                page: <CustomerServicePage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
            new RouteMap({
                route: AppRoutes.Dashboard,
                page: <DashboardPage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
            new RouteMap({
                route: AppRoutes.DocumentBundle,
                page: <DocumentBundlePage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
            new RouteMap({
                route: AppRoutes.ExternalSyncs,
                page: <ExternalSyncsPage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
            new RouteMap({
                route: AppRoutes.HumanResources,
                page: <HumanResourcesPage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
            new RouteMap({
                route: AppRoutes.Profile,
                page: <ProfilePage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
            new RouteMap({
                route: AppRoutes.ProfitReport,
                page: <ProfitReportPage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
            new RouteMap({
                route: AppRoutes.TaxTimeline,
                page: <TaxTimelinePage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
            new RouteMap({
                route: AppRoutes.Transactions,
                page: <TransactionsPage/>,
                redirectToMainOnAuthenticated: false,
                redirectToLoginOnUnauthenticated: true,
            }),
        ],
    }),
    new RouteMap({
        route: AppRoutes.Login,
        page: <LoginPage/>,
        redirectToMainOnAuthenticated: true,
        redirectToLoginOnUnauthenticated: false,
    }),
    new ErrorRouteMap(),
].map((route) => route.toObject());

export default AppRouteMapping;
