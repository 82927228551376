import NumberHelper from "config/helper/number_helper";
import {DonutChartLegendData} from "presentation/components/charts/donut_chart/donut_chart_model";
import Dot from "presentation/components/common/dot";
import SizedBox from "presentation/components/common/sized_box";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const LayoutContainer = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
    gap: 12px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;
    }
`;

const RowContainer = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const LabelContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.gray600};
`;

const ValueContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.gray800};
`;

const DonutChartLegends = ({data}: { data: DonutChartLegendData[] }) => {
    const dataEmpty = data.every((d) => d.value === 0);

    const label = (value: number) =>
        dataEmpty
            ? S.donutChartLegends.emptyLabel
            : NumberHelper.toChartYAxisAbbreviatedString(value);

    return (
        <LayoutContainer>
            {data.map((d, index) => (
                <RowContainer key={index}>
                    <Dot color={d.color}/>
                    <SizedBox width={"6px"}/>
                    <LabelContainer>{d.label}</LabelContainer>
                    <SizedBox width={"8px"}/>
                    <ValueContainer>{label(d.value)}</ValueContainer>
                </RowContainer>
            ))}
        </LayoutContainer>
    );
};

export default DonutChartLegends;
