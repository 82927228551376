import ArrowIconButton from "presentation/components/button/arrow_icon_button";
import {
    TransactionsCalendarContext
} from "presentation/components/calendar/transactions_calendar/transactions_calendar";
import Spacer from "presentation/components/common/spacer";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {useContext} from "react";
import styled from "styled-components";

export enum TransactionsCalendarMonthHeaderCallerType {
    Previous = "previous",
    Next = "next",
}

export const TransactionsCalendarMonthHeaderCallerTypeMap = {
    fromTranslateX: (
        caller: TransactionsCalendarMonthHeaderCallerType
    ): string => {
        switch (caller) {
            case TransactionsCalendarMonthHeaderCallerType.Previous:
                return "-100%";
            case TransactionsCalendarMonthHeaderCallerType.Next:
                return "100%";
            default:
                throw new Error(
                    "Invalid TransactionsCalendarMonthHeaderCallerType"
                );
        }
    },
    leaveTranslateX: (
        caller: TransactionsCalendarMonthHeaderCallerType
    ): string => {
        switch (caller) {
            case TransactionsCalendarMonthHeaderCallerType.Previous:
                return "100%";
            case TransactionsCalendarMonthHeaderCallerType.Next:
                return "-100%";
            default:
                throw new Error(
                    "Invalid TransactionsCalendarMonthHeaderCallerType"
                );
        }
    },
};

const LayoutContainer = styled.div.attrs<{ $heightInPx: number }>((props) => ({
    style: {
        minHeight: `${props.$heightInPx}px`,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    transition: min-height 0.3s ease-in-out;
`;

const TitleContainer = styled.h6`
    width: 7rem;
    ${Fonts.title2};
    color: ${Palette.gray800};
    text-align: center;
`;

const TodayButtonContainer = styled.button`
    padding: 6px 12px;
    border: none;
    border-radius: 12px;
    background-color: ${Palette.gray100};
    ${Fonts.detail1};
    text-align: center;
    cursor: pointer;
`;

const TransactionsCalendarMonthHeader = () => {
    const context = useContext(TransactionsCalendarContext);

    const previousButtonEnabled = context.yearMonth
        .getFirstDayInMonth()
        .isAfter(context.startDate.getFirstDayInMonth());
    const nextButtonEnabled = context.yearMonth
        .getFirstDayInMonth()
        .isBefore(context.endDate.getFirstDayInMonth());

    const previousButtonClick = () => {
        context.onYearMonthChange?.(
            context.yearMonth.copyWith({
                month: context.yearMonth.month - 1,
            })
        );
        context.setYearMonth({
            value: context.yearMonth.copyWith({
                month: context.yearMonth.month - 1,
            }),
            caller: TransactionsCalendarMonthHeaderCallerType.Previous,
        });
    };
    const nextButtonClick = () => {
        context.onYearMonthChange?.(
            context.yearMonth.copyWith({
                month: context.yearMonth.month + 1,
            })
        );
        context.setYearMonth({
            value: context.yearMonth.copyWith({
                month: context.yearMonth.month + 1,
            }),
            caller: TransactionsCalendarMonthHeaderCallerType.Next,
        });
    };

    const onTodayButtonClick = () => {
        context.onFiguresTodayButtonClick?.();

        if (context.yearMonth.isSameMonth(DateTime.now())) return;

        const thisMonth = DateTime.now().getFirstDayInMonth();
        context.onYearMonthChange?.(thisMonth);
        context.setYearMonth({
            value: thisMonth,
            caller: TransactionsCalendarMonthHeaderCallerType.Next,
        });
    };

    return (
        <LayoutContainer $heightInPx={context.monthHeaderHeightInPx}>
            <ArrowIconButton
                enabled={previousButtonEnabled}
                asset={SVGAssets.Back}
                onClick={previousButtonClick}
            />
            <TitleContainer>
                {context.yearMonth.format(
                    S.transactionsCalendar.monthHeaderDateFormat
                )}
            </TitleContainer>
            <ArrowIconButton
                enabled={nextButtonEnabled}
                asset={SVGAssets.Next}
                onClick={nextButtonClick}
            />
            <Spacer/>
            <TodayButtonContainer onClick={onTodayButtonClick}>
                {S.transactionsCalendar.todayButtonLabel}
            </TodayButtonContainer>
        </LayoutContainer>
    );
};

export default TransactionsCalendarMonthHeader;
