import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
`;

const DocumentBundlePage = () => {
    return <LayoutContainer>document bundle page</LayoutContainer>;
};

export default DocumentBundlePage;
