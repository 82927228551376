import {CardSalesDateFilterTypeMap} from "domain/model/tax/card_sales/card_sales_date_filter_type";
import ContentSectionTitle from "presentation/components/content/content_section_title";
import cardSalesStatusBoardSelector from "presentation/states/card_sales/selector/card_sales_status_board_selector";
import S from "presentation/theme/s";
import {useRecoilValue} from "recoil";

const CardSalesStatusBoardCardSalesTitleView = () => {
    const {selectedDateFilter} = useRecoilValue(cardSalesStatusBoardSelector);

    const title = selectedDateFilter
        ? S.cardSalesPage.statusBoard.cardSales.titleWithDateFilter(
            CardSalesDateFilterTypeMap.label(selectedDateFilter)
        )
        : S.cardSalesPage.statusBoard.cardSales.title;

    return <ContentSectionTitle title={title}/>;
};

export default CardSalesStatusBoardCardSalesTitleView;
