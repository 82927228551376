import Loading from "assets/json/loading.json";
import GetRecentDataOne from "assets/json/get_recent_data/1.json";
import GetRecentDataTwo from "assets/json/get_recent_data/2.json";
import GetRecentDataThree from "assets/json/get_recent_data/3.json";
import GetRecentDataFour from "assets/json/get_recent_data/4.json";
import GetRecentDataFive from "assets/json/get_recent_data/5.json";
import GetRecentDataSix from "assets/json/get_recent_data/6.json";
import GetRecentDataSeven from "assets/json/get_recent_data/7.json";
import GetRecentDataEight from "assets/json/get_recent_data/8.json";
import {ReactComponent as ArrowRight} from "assets/svg/ic_arrow_right.svg";
import {ReactComponent as ArrowSide} from "assets/svg/ic_arrow_side.svg";
import {ReactComponent as ArrowUp} from "assets/svg/ic_arrow_up.svg";
import {ReactComponent as Ascending} from "assets/svg/ic_ascending.svg";
import {ReactComponent as Avatar} from "assets/svg/ic_avatar.svg";
import {ReactComponent as Back} from "assets/svg/ic_back.svg";
import {ReactComponent as CalendarCheck} from "assets/svg/ic_calendar_check.svg";
import {ReactComponent as Calendar} from "assets/svg/ic_calendar.svg";
import {ReactComponent as Cash} from "assets/svg/ic_cash.svg";
import {ReactComponent as CheckCircle} from "assets/svg/ic_check_circle.svg";
import {ReactComponent as CheckedCard} from "assets/svg/ic_checked_card.svg";
import {ReactComponent as CheckedDocument} from "assets/svg/ic_checked_document.svg";
import {ReactComponent as CheckedSales} from "assets/svg/ic_checked_sales.svg";
import {ReactComponent as Close} from "assets/svg/ic_close.svg";
import {ReactComponent as CreditCard} from "assets/svg/ic_credit_card.svg";
import {ReactComponent as Descending} from "assets/svg/ic_descending.svg";
import {ReactComponent as DeleteCircle} from "assets/svg/ic_delete_circle.svg";
import {ReactComponent as DocumentSearch} from "assets/svg/ic_document_search.svg";
import {ReactComponent as Documents} from "assets/svg/ic_documents.svg";
import {ReactComponent as Down} from "assets/svg/ic_down.svg";
import {ReactComponent as EqualCircle} from "assets/svg/ic_equal_circle.svg";
import {ReactComponent as HelpCircle} from "assets/svg/ic_help_circle.svg";
import {ReactComponent as Home} from "assets/svg/ic_home.svg";
import {ReactComponent as Info} from "assets/svg/ic_info.svg";
import {ReactComponent as Link} from "assets/svg/ic_link.svg";
import {ReactComponent as Logo} from "assets/svg/logo.svg";
import {ReactComponent as LogoFull} from "assets/svg/logo_full.svg";
import {ReactComponent as Logout} from "assets/svg/ic_logout.svg";
import {ReactComponent as MinusCircle} from "assets/svg/ic_minus_circle.svg";
import {ReactComponent as Money} from "assets/svg/ic_money.svg";
import {ReactComponent as Next} from "assets/svg/ic_next.svg";
import {ReactComponent as People} from "assets/svg/ic_people.svg";
import {ReactComponent as PublicSales} from "assets/svg/ic_public_sales.svg";
import {ReactComponent as Report} from "assets/svg/ic_report.svg";
import {ReactComponent as Sales} from "assets/svg/ic_sales.svg";
import {ReactComponent as Search} from "assets/svg/ic_search.svg";
import {ReactComponent as Setting} from "assets/svg/ic_setting.svg";
import {ReactComponent as TaxCheck} from "assets/svg/ic_tax_check.svg";
import {ReactComponent as Warning} from "assets/svg/ic_warning.svg";
import {ReactComponent as ImageErrorOne} from "assets/svg/img_error_1.svg";
import {ReactComponent as ImageErrorTwo} from "assets/svg/img_error_2.svg";
import {FC, ReactElement, SVGProps} from "react";

type RawAsset = FC<SVGProps<SVGSVGElement>>;

const toReactElement = (Asset: RawAsset): ReactElement => {
    return <Asset/>;
};

export class JSONAssets {
    static readonly Loading: Object = Loading;
    static readonly GetRecentDataOne: Object = GetRecentDataOne;
    static readonly GetRecentDataTwo: Object = GetRecentDataTwo;
    static readonly GetRecentDataThree: Object = GetRecentDataThree;
    static readonly GetRecentDataFour: Object = GetRecentDataFour;
    static readonly GetRecentDataFive: Object = GetRecentDataFive;
    static readonly GetRecentDataSix: Object = GetRecentDataSix;
    static readonly GetRecentDataSeven: Object = GetRecentDataSeven;
    static readonly GetRecentDataEight: Object = GetRecentDataEight;
}

export default class SVGAssets {
    static readonly ArrowRight: ReactElement = toReactElement(ArrowRight);
    static readonly ArrowSide: ReactElement = toReactElement(ArrowSide);
    static readonly ArrowUp: ReactElement = toReactElement(ArrowUp);
    static readonly Ascending: ReactElement = toReactElement(Ascending);
    static readonly Avatar: ReactElement = toReactElement(Avatar);
    static readonly Back: ReactElement = toReactElement(Back);
    static readonly CalendarCheck: ReactElement = toReactElement(CalendarCheck);
    static readonly Calendar: ReactElement = toReactElement(Calendar);
    static readonly Cash: ReactElement = toReactElement(Cash);
    static readonly CheckCircle: ReactElement = toReactElement(CheckCircle);
    static readonly CheckedCard: ReactElement = toReactElement(CheckedCard);
    static readonly CheckedDocument: ReactElement =
        toReactElement(CheckedDocument);
    static readonly CheckedSales: ReactElement = toReactElement(CheckedSales);
    static readonly Close: ReactElement = toReactElement(Close);
    static readonly CreditCard: ReactElement = toReactElement(CreditCard);
    static readonly DeleteCircle: ReactElement = toReactElement(DeleteCircle);
    static readonly Descending: ReactElement = toReactElement(Descending);
    static readonly DocumentSearch: ReactElement =
        toReactElement(DocumentSearch);
    static readonly Documents: ReactElement = toReactElement(Documents);
    static readonly Down: ReactElement = toReactElement(Down);
    static readonly EqualCircle: ReactElement = toReactElement(EqualCircle);
    static readonly HelpCircle: ReactElement = toReactElement(HelpCircle);
    static readonly Home: ReactElement = toReactElement(Home);
    static readonly Info: ReactElement = toReactElement(Info);
    static readonly Link: ReactElement = toReactElement(Link);
    static readonly Logo: ReactElement = toReactElement(Logo);
    static readonly LogoFull: ReactElement = toReactElement(LogoFull);
    static readonly Logout: ReactElement = toReactElement(Logout);
    static readonly MinusCircle: ReactElement = toReactElement(MinusCircle);
    static readonly Money: ReactElement = toReactElement(Money);
    static readonly Next: ReactElement = toReactElement(Next);
    static readonly People: ReactElement = toReactElement(People);
    static readonly PublicSales: ReactElement = toReactElement(PublicSales);
    static readonly Report: ReactElement = toReactElement(Report);
    static readonly Sales: ReactElement = toReactElement(Sales);
    static readonly Search: ReactElement = toReactElement(Search);
    static readonly Setting: ReactElement = toReactElement(Setting);
    static readonly TaxCheck: ReactElement = toReactElement(TaxCheck);
    static readonly Warning: ReactElement = toReactElement(Warning);
}

export class StaticSVGAssets {
    static readonly ImageErrorOne: ReactElement = toReactElement(ImageErrorOne);
    static readonly ImageErrorTwo: ReactElement = toReactElement(ImageErrorTwo);
}