import NumberHelper from "config/helper/number_helper";
import BillingFigures from "domain/model/tax/billing/billing_figures";
import {BillingFiguresStatusTypeMap} from "domain/model/tax/billing/billing_figures_status_type";
import OutlinedChip from "presentation/components/chip/outlined_chip";
import {
    BillingStatusBoardTableContext
} from "presentation/pages/billing/view/status_board/components/billing_status_board_table/billing_status_board_table";
import BillingStatusBoardTableCellLayout
    from "presentation/pages/billing/view/status_board/components/billing_status_board_table/components/billing_status_board_table_cell_layout";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const CellLabelContainer = styled.p.attrs<{ $bold: boolean }>((props) => ({
    style: {
        fontWeight: props.$bold ? FontWeights.bold : FontWeights.medium,
    },
}))`
    min-width: max-content;
    ${Fonts.detail2Medium};
    text-align: center;
`;

const BillingStatusBoardTableRow = ({
                                        highlight,
                                        data,
                                    }: {
    highlight: boolean;
    data: BillingFigures;
}) => {
    const context = useContext(BillingStatusBoardTableContext);

    const rowData = useMemo(() => {
        const labels = [
            data.date.format(S.billingStatusBoardTable.dateFormat),
            data.billingOrder,
            NumberHelper.toStringWithDividers(data.billingAmount),
            data.pendingAmount
                ? NumberHelper.toStringWithDividers(data.pendingAmount)
                : "",
            NumberHelper.toStringWithDividers(data.taxes),
            NumberHelper.toStringWithDividers(data.receiveAmount),
            data.receiveDate.format(S.billingStatusBoardTable.dateFormat),
            BillingFiguresStatusTypeMap.label(data.status),
        ];

        return labels.map((l, index) => ({
            label: l,
            bold: index === 5,
            chipData:
                index === labels.length - 1
                    ? {
                        color: BillingFiguresStatusTypeMap.color(data.status),
                        backgroundColor:
                            BillingFiguresStatusTypeMap.backgroundColor(
                                data.status
                            ),
                        borderColor: BillingFiguresStatusTypeMap.borderColor(
                            data.status
                        ),
                    }
                    : undefined,
        }));
    }, [data]);

    const backgroundColor = highlight ? Palette.gray50 : Palette.none;

    return (
        <LayoutContainer>
            {rowData.map((d, index) => (
                <BillingStatusBoardTableCellLayout
                    key={index}
                    heightInPx={context.constants.cellHeightInPx}
                    roundLeft={index === 0}
                    roundRight={index === rowData.length - 1}
                    backgroundColor={backgroundColor}
                >
                    {d.chipData ? (
                        <OutlinedChip
                            label={d.label}
                            color={d.chipData.color}
                            backgroundColor={d.chipData.backgroundColor}
                            borderColor={d.chipData.borderColor}
                        />
                    ) : (
                        <CellLabelContainer $bold={d.bold}>
                            {d.label}
                        </CellLabelContainer>
                    )}
                </BillingStatusBoardTableCellLayout>
            ))}
        </LayoutContainer>
    );
};

export default BillingStatusBoardTableRow;
