import {
    ProfitReportYearlyTableContext
} from "presentation/pages/profit_report/view/profit/yearly_table/components/profit_report_yearly_table/profit_report_yearly_table";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {memo, useContext} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div<{
    $minWidthInPx: number;
    $heightInPx: number;
    $bottomMarginInPx: number;
    $leftBorderRounded: boolean;
    $rightBorderRounded: boolean;
}>`
    height: ${(props) => `${props.$heightInPx}px`};
    margin-bottom: ${(props) => `${props.$bottomMarginInPx}px`};
    border-top-left-radius: ${(props) =>
    `${props.$leftBorderRounded ? 16 : 0}px`};
    border-top-right-radius: ${(props) =>
    `${props.$rightBorderRounded ? 16 : 0}px`};
    border-bottom-left-radius: ${(props) =>
    `${props.$leftBorderRounded ? 16 : 0}px`};
    border-bottom-right-radius: ${(props) =>
    `${props.$rightBorderRounded ? 16 : 0}px`};
    background-color: ${Palette.gray100};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelContainer = styled.h6`
    ${Fonts.detail2};
    text-align: center;
`;

const ProfitReportYearlyTableMonthCell = ({month}: { month: number }) => {
    const context = useContext(ProfitReportYearlyTableContext);

    const leftBorderRounded = month === 1;
    const rightBorderRounded = month === DateTime.monthsInYear;

    return (
        <LayoutContainer
            $minWidthInPx={context.constants.cellMinWidthInPx}
            $heightInPx={context.constants.monthHeaderHeightInPx}
            $bottomMarginInPx={context.constants.monthHeaderToTableGapInPx}
            $leftBorderRounded={leftBorderRounded}
            $rightBorderRounded={rightBorderRounded}
        >
            <LabelContainer>
                {S.yearlyTable.monthCell.label(month.toString())}
            </LabelContainer>
        </LayoutContainer>
    );
};

export default memo(ProfitReportYearlyTableMonthCell);
