import Palette from "presentation/theme/palette";
import styled from "styled-components";

const DividerContainer = styled.div<{
    $height: string;
    $horizontalMargin: string;
}>`
    width: 0;
    height: ${(props) => props.$height};
    margin: ${(props) => `0 ${props.$horizontalMargin}`};
    border-left: 1px solid ${Palette.gray300};
    transition: height 0.3s ease-in-out, margin 0.3s ease-in-out,
        border 0.3s ease-in-out;

    @media (max-width: 768px) {
        height: 0;
        border-left: 0px solid ${Palette.none};
        margin: 0;
    }
`;

const SpacedDivider = ({
                           height,
                           horizontalMargin,
                       }: {
    height: string;
    horizontalMargin: string;
}) => {
    return (
        <DividerContainer
            $height={height}
            $horizontalMargin={horizontalMargin}
        />
    );
};

export default SpacedDivider;
