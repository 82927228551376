import {animated, useSpring} from "@react-spring/web";
import SVG from "presentation/components/common/svg";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import {ReactElement, useEffect} from "react";
import styled from "styled-components";

const LayoutContainer = styled(animated.li)`
    width: 100%;
    min-width: 32px;
    padding: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    cursor: pointer;
`;

const TitleContainer = styled(animated.p)`
    ${Fonts.detail2};
    min-width: 100%;
    padding-left: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    transition: width 0.3s ease-in-out, padding-left 0.15s ease-in-out;

    @media (max-width: 768px) {
        min-width: 0%;
        padding-left: 0px;
    }
`;

const LNBItem = ({
                     selected,
                     icon,
                     label,
                     onClick,
                 }: {
    selected: boolean;
    icon: ReactElement;
    label: string;
    onClick: () => void;
}) => {
    const isMobile = useMobileQuery();

    const [props, api] = useSpring(() => ({
        border: selected
            ? `1.5px solid ${Palette.primary500}`
            : `1.5px solid ${Palette.none}`,
        backgroundColor: selected ? Palette.primary10 : Palette.none,
    }));
    const titleProps = useSpring({
        opacity: isMobile ? 0 : 1,
        color: selected ? Palette.primary500 : Palette.gray800,
        fontWeight: selected ? FontWeights.bold : FontWeights.medium,
    });

    useEffect(() => {
        api.start({
            border: selected
                ? `1.5px solid ${Palette.primary500}`
                : `1.5px solid ${Palette.none}`,
            backgroundColor: selected ? Palette.primary10 : Palette.none,
        });
    }, [selected, api]);

    const onMouseEnter = () =>
        api.start({backgroundColor: Palette.primary10});
    const onMouseLeave = () =>
        api.start({
            backgroundColor: selected ? Palette.primary10 : Palette.none,
        });

    return (
        <LayoutContainer
            style={{...props}}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <SVG
                asset={icon}
                width={"20px"}
                height={"20px"}
                color={selected ? Palette.primary500 : Palette.gray400}
            />
            <TitleContainer style={titleProps}>{label}</TitleContainer>
        </LayoutContainer>
    );
};

export default LNBItem;
