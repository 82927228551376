import {LoginListenableState} from "presentation/states/login/login_listenable_state";
import {atom} from "recoil";
import {Subject} from "rxjs";

type LoginState = {
    certCodeRequested: boolean;
    phoneNumber: string;
};

export const loginListenableState = new Subject<LoginListenableState>();

const loginState = atom<LoginState>({
    key: "loginState",
    default: {
        certCodeRequested: false,
        phoneNumber: "",
    },
});

export default loginState;
