import {ChartAxisData} from "presentation/components/charts/common/chart_model";
import ChartEmptyOverlay from "presentation/components/charts/common/chart_empty_overlay";
import ChartHorizontalGrid from "presentation/components/charts/common/chart_horizontal_grid";
import ChartLayout from "presentation/components/charts/common/chart_layout";
import ChartXAxis from "presentation/components/charts/common/chart_x_axis";
import ChartYAxis from "presentation/components/charts/common/chart_y_axis";
import LineChartDots from "presentation/components/charts/line_chart/components/line_chart_dots";
import LineChartLines from "presentation/components/charts/line_chart/components/line_chart_lines";
import LineChartTooltip from "presentation/components/charts/line_chart/components/line_chart_tooltip";
import LineChartData from "presentation/components/charts/line_chart/line_chart_model";
import {optional} from "presentation/utils/types/optional";
import {
    Dispatch,
    SetStateAction,
    createContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

const topMarginInPx = 10;
const binMarginInPx = 7;
const binSizeInPx = 24;
const xAxisHeightInPx = 25;
const yAxisWidthInPx = 60;
const xAxisHorizontalMarginInPx = 12;

export const LineChartContext = createContext<{
    constants: {
        innerWidthInPx: number;
        widthInPx: number;
        innerHeightInPx: number;
        heightInPx: number;
        topMarginInPx: number;
        binSizeInPx: number;
        xAxisHeightInPx: number;
        xAxisHorizontalMarginInPx: number;
        xRangeCount: number;
    };
    constantsKey: string;
    functions: {
        getXPos: (x: number) => number;
        getYPos: (y: number) => number;
    };
    data: LineChartData[];
    hoveredXIndex?: number;
    setHoveredXIndex: Dispatch<SetStateAction<optional<number>>>;
}>({
    constants: {
        innerWidthInPx: 500,
        widthInPx: 500,
        innerHeightInPx: 265,
        heightInPx: 265,
        topMarginInPx,
        binSizeInPx,
        xAxisHeightInPx,
        xAxisHorizontalMarginInPx,
        xRangeCount: 0,
    },
    constantsKey: "",
    functions: {
        getXPos: (x: number) => x,
        getYPos: (y: number) => y,
    },
    data: [] as LineChartData[],
    hoveredXIndex: undefined,
    setHoveredXIndex: () => {
    },
});

const LineChart = ({
                       heightInPx,
                       data,
                       xAxis,
                       yAxis,
                   }: {
    heightInPx: number;
    data: LineChartData[];
    xAxis: ChartAxisData[];
    yAxis: ChartAxisData[];
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [hoveredXIndex, setHoveredXIndex] =
        useState<optional<number>>(undefined);

    const dataEmpty = data.every((d) => !d.dots.length);

    const {xRange, yRange} = useMemo(
        () => {
            const xValues = xAxis.map((x) => x.value);
            const yValues = yAxis.map((y) => y.value);

            const xRange = {
                min: Math.min(...xValues),
                max: Math.max(...xValues),
            };
            const yRange = {
                min: Math.min(...yValues),
                max: Math.max(...yValues),
            };

            return {
                xRange,
                yRange,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const {constants, constantsKey, functions, gridWidthInPx} =
        useMemo(() => {
            const count = xRange.max - xRange.min;
            const binWidth = binSizeInPx + binMarginInPx * 2;
            const totalBinWidth = binWidth * count;
            const width = totalBinWidth + xAxisHorizontalMarginInPx * 2;

            const scrollbarHeight = Math.abs(
                (ref.current?.offsetHeight ?? 0) -
                (ref.current?.clientHeight ?? 0)
            );

            const constants = {
                widthInPx: width - yAxisWidthInPx,
                innerWidthInPx:
                    width - yAxisWidthInPx - xAxisHorizontalMarginInPx * 2,
                heightInPx,
                innerHeightInPx:
                    heightInPx -
                    (topMarginInPx + xAxisHeightInPx + scrollbarHeight),
                topMarginInPx,
                binSizeInPx,
                xAxisHeightInPx,
                xAxisHorizontalMarginInPx,
                xRangeCount: xRange.max - xRange.min + 1,
            };

            const functions = {
                getXPos: (x: number) => {
                    const minX = xRange.min;
                    const maxX = xRange.max;

                    const magnitudeX = Math.abs(maxX - minX);
                    const scaleX = constants.innerWidthInPx / magnitudeX;

                    return constants.xAxisHorizontalMarginInPx +
                        (x - minX) * scaleX;
                },
                getYPos: (y: number) => {
                    const minY = yRange.min;
                    const maxY = yRange.max;

                    const magnitudeY = Math.abs(maxY - minY);
                    const scaleY = constants.innerHeightInPx / magnitudeY;
                    const yPos = (maxY - y) * scaleY;

                    return yPos + topMarginInPx;
                },
            };

            const constantsKey = Object.entries(constants)
                .map(([key, value]) => `${key}-${value}`)
                .join();

            const gridWidthInPx =
                constants.innerWidthInPx +
                constants.xAxisHorizontalMarginInPx * 2;

            return {
                constants,
                constantsKey,
                functions,
                gridWidthInPx,
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            data,
            ref.current?.offsetWidth,
            ref.current?.offsetHeight,
            ref.current?.clientHeight,
        ]);

    const {gridData, yAxisData} = useMemo(() => {
        const yAxisData = yAxis.map((y) => ({
            yPosFromTop: functions.getYPos(y.value),
            label: y.label,
        }));
        const gridData = yAxisData.map((y) => y.yPosFromTop);

        return {
            yAxisData,
            gridData,
        };
    }, [yAxis, functions]);

    const xAxisData = useMemo(() => {
        return xAxis.map((x) => ({
            binWidthInPx: binSizeInPx,
            xPosFromLeft: functions.getXPos(x.value),
            label: x.label,
        }));
    }, [xAxis, functions]);

    useEffect(() => {
        setHoveredXIndex(undefined);
    }, [data]);

    const emptyOverlayBottomOffsetInPx = `${xAxisHeightInPx}px`;

    return (
        <LineChartContext.Provider
            value={{
                constants,
                constantsKey,
                functions,
                data,
                hoveredXIndex,
                setHoveredXIndex,
            }}
        >
            <ChartLayout
                ref={ref}
                scrollable={!dataEmpty}
                heightInPx={heightInPx}
                yAxis={
                    <ChartYAxis
                        widthInPx={yAxisWidthInPx}
                        heightInPx={constants.innerHeightInPx}
                        data={yAxisData}
                    />
                }
            >
                <ChartHorizontalGrid
                    widthInPx={gridWidthInPx}
                    yPositions={gridData}
                />
                <ChartXAxis data={xAxisData} highlightedIndex={hoveredXIndex}/>
                <LineChartLines/>
                <LineChartDots/>
                <LineChartTooltip/>
                <ChartEmptyOverlay
                    visible={dataEmpty}
                    bottomOffset={emptyOverlayBottomOffsetInPx}
                />
            </ChartLayout>
        </LineChartContext.Provider>
    );
};

export default LineChart;
