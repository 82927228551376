import profitReportState from "presentation/states/profit_report/profit_report_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const profitReportProfitStatusBoardSelector = selector({
    key: "profitReportProfitStatusBoardSelector",
    get: ({get}) => {
        const state = get(profitReportState);

        return {
            taxStartDate: DateTime.fromKey(state.taxStartDateKey),
            selectedDateFilter: state.profit.statusBoard.selectedDateFilter,
            startDate: DateTime.fromKey(state.profit.statusBoard.startDateKey),
            endDate: DateTime.fromKey(state.profit.statusBoard.endDateKey),
            accumulated: state.profit.statusBoard.accumulated,
            data: state.profit.statusBoard.data,
        };
    },
});

export default profitReportProfitStatusBoardSelector;
