import {
    TransactionsCalendarConstants
} from "presentation/components/calendar/transactions_calendar/transactions_calendar";
import DashboardRow from "presentation/pages/dashboard/components/dashboard_row";
import DashboardTransactionsCalendarListView
    from "presentation/pages/dashboard/view/transactions/calendar_list/dashboard_transactions_calendar_list_view";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import DateTime from "presentation/utils/extension/date_extension";
import {createContext, Dispatch, SetStateAction, useMemo, useState,} from "react";
import {useRecoilValue} from "recoil";

const calendarConstants = {
    marginInPx: 24,
    monthHeaderHeightInPx: 36,
    monthHeaderToFiguresHeaderGapInPx: 24,
    figuresHeaderHeightInPx: 34,
    figuresHeaderToCalendarGapInPx: 24,
    weekdayHeaderHeightInPx: 20,
    calendarRowVerticalGapInPx: 5,
    calendarCellWidthInPx: 68,
    calendarCellHeightInPx: 68,
    calendarCellValueWidthInPx: 40,
    calendarCellValueHeightInPx: 40,
};

export const DashboardTransactionsViewContext = createContext<{
    contentHeight: string;
    queryYearMonth: DateTime;
    setQueryYearMonth: Dispatch<SetStateAction<DateTime>>;
    calendarConstants: TransactionsCalendarConstants;
}>({
    contentHeight: "auto",
    queryYearMonth: DateTime.now(),
    setQueryYearMonth: () => {
    },
    calendarConstants,
});

const DashboardTransactionsView = () => {
    const {selectedYearMonth} = useRecoilValue(dashboardDateSelector);

    const [queryYearMonth, setQueryYearMonth] = useState(selectedYearMonth);

    const contentHeight = useMemo(() => {
        const weekCount = queryYearMonth.getOrthodoxWeeksInMonth().length;
        const height =
            calendarConstants.marginInPx * 2 +
            calendarConstants.monthHeaderHeightInPx +
            calendarConstants.monthHeaderToFiguresHeaderGapInPx +
            calendarConstants.figuresHeaderHeightInPx +
            calendarConstants.figuresHeaderToCalendarGapInPx +
            calendarConstants.weekdayHeaderHeightInPx +
            (calendarConstants.calendarRowVerticalGapInPx +
                calendarConstants.calendarCellHeightInPx) *
            weekCount;

        return `${height}px`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryYearMonth.key]);

    return (
        <DashboardTransactionsViewContext.Provider
            value={{
                contentHeight,
                queryYearMonth,
                setQueryYearMonth,
                calendarConstants,
            }}
        >
            <DashboardRow contentWidth={"572px"} contentHeight={contentHeight}>
                <DashboardTransactionsCalendarListView/>
            </DashboardRow>
        </DashboardTransactionsViewContext.Provider>
    );
};

export default DashboardTransactionsView;
