import {animated, useSpring} from "@react-spring/web";
import {DispensingChartContext} from "presentation/components/charts/dispensing_chart/dispensing_chart";
import Palette from "presentation/theme/palette";
import {useContext, useEffect, useMemo, useRef} from "react";
import styled from "styled-components";

const CanvasContainer = styled(animated.canvas)`
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.3s ease-in-out;
    z-index: 4;
`;

const DispensingChartDottedLines = () => {
    const context = useContext(DispensingChartContext);
    const ref = useRef<HTMLCanvasElement>(null);

    const data = useMemo(
        () =>
            context.data.map((d) => ({
                xPos: context.functions.getXPos(d.x),
                yPos: context.functions.getYPosFromTop(d.y),
            })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [context.data, context.highlightedXIndices, context.constantsKey]
    );

    const [props, api] = useSpring(() => ({
        opacity: 0,
    }));

    useEffect(() => {
        const canvas = ref.current;
        const c = ref.current?.getContext("2d");
        if (!c) return;

        const drawCircle = (data: { xPos: number; yPos: number }) => {
            c.fillStyle = Palette.deepBlue;
            c.beginPath();
            c.arc(data.xPos, data.yPos, 6, 0, 2 * Math.PI);
            c.fill();
            c.beginPath();
            c.fillStyle = Palette.white100;
            c.arc(data.xPos, data.yPos, 3, 0, 2 * Math.PI);
            c.fill();
        };

        const drawLine = (
            data: { xPos: number; yPos: number },
            prev?: { xPos: number; yPos: number }
        ) => {
            if (!prev) return;

            c.strokeStyle = Palette.deepBlue;
            c.lineWidth = 3;
            c.beginPath();
            c.moveTo(prev.xPos, prev.yPos);
            c.lineTo(data.xPos, data.yPos);
            c.stroke();
        };

        data.forEach((d, index) => drawLine(d, data[index - 1]));
        data.forEach((d) => drawCircle(d));

        api.start({
            opacity: 1,
        });

        return () => {
            c.clearRect(
                0,
                0,
                canvas?.width ?? context.constants.widthInPx,
                canvas?.height ?? context.constants.heightInPx
            );
            api.set({
                opacity: 0,
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, ref]);

    return (
        <CanvasContainer
            ref={ref}
            style={props}
            width={context.constants.widthInPx}
            height={context.constants.heightInPx}
        />
    );
};

export default DispensingChartDottedLines;
