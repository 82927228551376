import TaxTimelineRepository from "data/repository/tax_timeline_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import Page from "presentation/components/common/page";
import TaxTimelineHeaderView from "presentation/pages/tax_timeline/view/tax_timeline_header_view";
import TaxTimelineSummaryView from "presentation/pages/tax_timeline/view/tax_timeline_summary_view";
import TaxTimelineTableView from "presentation/pages/tax_timeline/view/tax_timeline_table_view";
import taxTimelineDateSelector from "presentation/states/tax_timeline/selector/tax_timeline_date_selector";
import taxTimelineState from "presentation/states/tax_timeline/tax_timeline_state";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import {useEffect, useState} from "react";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";

const TaxTimelinePage = () => {
    const repository = useRepository(TaxTimelineRepository);

    const {selectedYear} = useRecoilValue(taxTimelineDateSelector);
    const setState = useSetRecoilState(taxTimelineState);
    const resetState = useResetRecoilState(taxTimelineState);

    const [status, setStatus] = useState(ViewStatusType.Loading);

    useLifeCycle({
        onInit: () =>
            repository({
                handler: async (repository) => {
                    const data = await repository.getTaxTimeline({
                        year: selectedYear,
                    });

                    setState((prev) => ({
                        ...prev,
                        data,
                    }));

                    setTimeout(() => setStatus(ViewStatusType.Loaded), 1000);
                },
            }),
        onDispose: () => resetState(),
    });

    useEffect(() => {
        if (status === ViewStatusType.Loading) return;

        repository({
            handler: async (repository) => {
                const data = await repository.getTaxTimeline({
                    year: selectedYear,
                });

                setState((prev) => ({
                    ...prev,
                    data,
                }));
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear.key]);

    return (
        <>
            <TaxTimelineHeaderView status={status}/>
            <Page status={status}>
                <TaxTimelineSummaryView/>
                <TaxTimelineTableView/>
            </Page>
        </>
    );
};

export default TaxTimelinePage;
