import DashboardRow from "presentation/pages/dashboard/components/dashboard_row";
import DashboardOtherFiguresChartView
    from "presentation/pages/dashboard/view/other_figures/chart/dashboard_other_figures_chart_view";

const DashboardOtherFiguresView = () => {
    return (
        <DashboardRow contentHeight={"208px"}>
            <DashboardOtherFiguresChartView/>
        </DashboardRow>
    );
};

export default DashboardOtherFiguresView;
