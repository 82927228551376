import ContentSectionTitle from "presentation/components/content/content_section_title";
import S from "presentation/theme/s";

const CardSalesStatusBoardReceiveTableHeaderView = () => {
    return (
        <ContentSectionTitle
            title={S.cardSalesPage.statusBoard.cardSales.tableHeaderTitle}
        />
    );
};

export default CardSalesStatusBoardReceiveTableHeaderView;
