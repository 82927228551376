import BillingRepository from "data/repository/billing_repository";
import YearDropdownButton from "presentation/components/button/year_dropdown_button";
import ContentSectionTitle from "presentation/components/content/content_section_title";
import billingState from "presentation/states/billing/billing_state";
import billingYearlyTableSelector from "presentation/states/billing/selector/billing_yearly_table_selector";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import useRepository from "presentation/utils/hooks/use_repository";
import {useRecoilValue, useSetRecoilState} from "recoil";

const BillingYearlyTableHeaderView = () => {
    const repository = useRepository(BillingRepository);

    const {taxStartDate, selectedYear} = useRecoilValue(
        billingYearlyTableSelector
    );
    const setState = useSetRecoilState(billingState);

    const onSelectedDateChange = (year: DateTime) => {
        setState((prev) => ({
            ...prev,
            yearlyTable: {
                ...prev.yearlyTable,
                selectedYearKey: year.key,
            },
        }));

        repository({
            handler: async (repository) => {
                const yearlyTableData =
                    await repository.getMonthlyFiguresInRange({
                        startDate: year.getFirstDayInYear(),
                        endDate: year.getLastDayInYear(),
                    });

                setState((prev) => ({
                    ...prev,
                    yearlyTable: {
                        ...prev.yearlyTable,
                        selectedYearKey: year.key,
                        data: yearlyTableData,
                    },
                }));
            },
        });
    };

    return (
        <ContentSectionTitle
            title={S.billingPage.yearlyTable.title}
            suffix={
                <YearDropdownButton
                    startDate={taxStartDate}
                    selectedDate={selectedYear}
                    onSelectedDateChange={onSelectedDateChange}
                />
            }
        />
    );
};

export default BillingYearlyTableHeaderView;
