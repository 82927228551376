import {transactionsState} from "presentation/states/transactions/transactions_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const transactionsCalendarListSelector = selector({
    key: "transactionsCalendarListSelector",
    get: ({get}) => {
        const state = get(transactionsState);

        return {
            taxStartDate: DateTime.fromKey(state.taxStartDateKey),
            yearMonth: DateTime.fromKey(state.calendarList.yearMonthKey),
            data: state.calendarList.data,
        };
    },
});

export default transactionsCalendarListSelector;
