import NumberHelper from "config/helper/number_helper";
import CardSalesFigures from "domain/model/tax/card_sales/card_sales_figures";
import IconButton, {IconButtonHoverColorType,} from "presentation/components/button/icon_button";
import TableCellLayout from "presentation/components/table/table_cell_layout";
import TableRowLayout from "presentation/components/table/table_row_layout";
import {
    CardSalesTableContext
} from "presentation/pages/card_sales/view/status_board/components/card_sales_table/card_sales_table";
import SVGAssets from "presentation/theme/assets";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LabelContainer = styled.p.attrs<{
    $bold: boolean;
    $maxWidthInPx?: number;
}>((props) => ({
    style: {
        flex: props.$maxWidthInPx !== undefined ? "auto" : "1",
        maxWidth:
            props.$maxWidthInPx !== undefined
                ? `${props.$maxWidthInPx}px`
                : "auto",
        fontWeight: props.$bold ? FontWeights.bold : FontWeights.medium,
    },
}))`
    min-width: max-content;
    ${Fonts.detail2Medium};
    text-align: center;
`;

const ButtonLayoutContainer = styled.div.attrs<{ $widthInPx: number }>(
    (props) => ({
        style: {
            minWidth: `${props.$widthInPx}px`,
        },
    })
)`
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

const ButtonLabelContainer = styled.p`
    flex-grow: 1;
    min-width: max-content;
    ${Fonts.detail2Medium};
    text-align: end;
`;

const CardSalesTableRow = ({data}: { data: CardSalesFigures }) => {
    const context = useContext(CardSalesTableContext);

    const _data = useMemo(() => {
        const values = [
            [data.date.format(S.cardSalesTable.dateFormat)],
            [data.total],
            [data.processedAmount, data.processedCount],
            data.cancelledDetails.length
                ? [data.cancelledAmount, data.cancelledCount]
                : [undefined, undefined],
            data.pendingDetails.length
                ? [data.pendingAmount, data.pendingCount]
                : [undefined, undefined],
        ];

        return values.map((v, index) => ({
            flex: index === 0 ? undefined : 1,
            maxWidthInPx:
                index === 0 ? context.constants.dateColumnWidthInPx : undefined,
            leftMarginInPx:
                index !== 0 ? context.constants.horizontalGapInPx : 0,
            rightDivider: index !== 0 && index !== values.length - 1,
            labels: v.map((value, valueIndex) => ({
                value:
                    index === 0 || value === undefined
                        ? value
                        : NumberHelper.toStringWithDividers(value as number),
                maxWidthInPx:
                    valueIndex === 1
                        ? context.constants.countCellWidthInPx
                        : undefined,
                bold: index === 1,
                hasButton:
                    value !== undefined &&
                    (index === 3 || index === 4) &&
                    valueIndex === 1,
                onClick:
                    value === undefined || valueIndex !== 1
                        ? undefined
                        : index === 3
                            ? () =>
                                context.onDetailButtonClick(
                                    "cancel",
                                    data.date,
                                    data.cancelledDetails
                                )
                            : () =>
                                context.onDetailButtonClick(
                                    "pending",
                                    data.date,
                                    data.pendingDetails
                                ),
            })),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, context.constants, context.onDetailButtonClick]);

    return (
        <TableRowLayout>
            {_data.map((d, index) => (
                <TableCellLayout
                    key={index}
                    flex={d.flex}
                    maxWidthInPx={d.maxWidthInPx}
                    minWidthInPx={context.constants.cellMinWidthInPx}
                    heightInPx={context.constants.cellHeightInPx}
                    leftMarginInPx={d.leftMarginInPx}
                    rightDivider={d.rightDivider}
                    roundLeft={false}
                    roundRight={false}
                    backgroundColor={Palette.none}
                >
                    {d.labels.map((l, labelIndex) =>
                        l.hasButton && l.maxWidthInPx ? (
                            <ButtonLayoutContainer
                                key={labelIndex}
                                $widthInPx={l.maxWidthInPx}
                            >
                                <ButtonLabelContainer key={labelIndex}>
                                    {l.value ?? ""}
                                </ButtonLabelContainer>
                                <IconButton
                                    asset={SVGAssets.Search}
                                    width={"24px"}
                                    height={"24px"}
                                    color={Palette.primary500}
                                    onClick={l.onClick}
                                    hoverColor={IconButtonHoverColorType.Light}
                                />
                            </ButtonLayoutContainer>
                        ) : (
                            <LabelContainer
                                key={labelIndex}
                                $bold={l.bold}
                                $maxWidthInPx={l.maxWidthInPx}
                            >
                                {l.value ?? ""}
                            </LabelContainer>
                        )
                    )}
                </TableCellLayout>
            ))}
        </TableRowLayout>
    );
};

export default CardSalesTableRow;
