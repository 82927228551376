import DashboardRow from "presentation/pages/dashboard/components/dashboard_row";
import DashboardProfitChartView from "presentation/pages/dashboard/view/profit/chart/dashboard_profit_chart_view";
import DashboardProfitDonutChartView
    from "presentation/pages/dashboard/view/profit/donut_chart/dashboard_profit_donut_chart_view";

const DashboardProfitView = () => {
    return (
        <DashboardRow height={"417px"}>
            <DashboardProfitChartView/>
            <DashboardProfitDonutChartView/>
        </DashboardRow>
    );
};

export default DashboardProfitView;
