import {animated, useTransition} from "@react-spring/web";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    margin-top: 9.5px;
    position: relative;
`;

const LabelContainer = styled(animated.p)`
    ${Fonts.detail3};
    color: ${Palette.white100};
    word-break: keep-all;
    position: absolute;
    top: 0;
    left: 0;
`;

const DashboardFiguresBillingLabelView = ({
                                              needCheck,
                                          }: {
    needCheck: boolean;
}) => {
    const transitions = useTransition(needCheck, {
        from: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
        },
        leave: {
            opacity: 0,
        },
    });

    return (
        <LayoutContainer>
            {transitions((props, needCheck) =>
                needCheck ? (
                    <LabelContainer style={props}>
                        {S.dashboardPage.figures.billingNeedCheckLabel}
                    </LabelContainer>
                ) : (
                    <LabelContainer style={props}>
                        {S.dashboardPage.figures.billingNoCheckNeededLabel}
                    </LabelContainer>
                )
            )}
        </LayoutContainer>
    );
};

export default DashboardFiguresBillingLabelView;
