import SVG from "presentation/components/common/svg";
import OtherExpensesInfoButton
    from "presentation/components/button/other_expenses_info_button/other_expenses_info_button";
import ProfitReportStatusBoardSummaryItem
    from "presentation/pages/profit_report/components/profit/status_board/profit_report_profit_status_board_summary_item";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";
import profitReportProfitStatusBoardSelector
    from "presentation/states/profit_report/selector/profit/status_board/profit_report_status_board_selector";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
`;

const ProfitReportProfitStatusBoardSummaryView = () => {
    const {data} = useRecoilValue(profitReportProfitStatusBoardSelector);

    const _data = useMemo(() => {
        if (data.length === 0) return undefined;

        const salesTotal = data.reduce((acc, cur) => acc + cur.sales[0], 0);
        const profitTotal = data.reduce((acc, cur) => acc + cur.profit, 0);
        const purchasesTotal = data.reduce(
            (acc, cur) => acc + cur.purchases[0],
            0
        );
        const otherExpensesTotal = data.reduce(
            (acc, cur) => acc + cur.otherExpenses[0],
            0
        );

        return {
            salesTotal: salesTotal,
            profitTotal: profitTotal,
            purchasesTotal: purchasesTotal,
            otherExpensesTotal: otherExpensesTotal,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <LayoutContainer>
            <ProfitReportStatusBoardSummaryItem
                color={Palette.lightGreen}
                title={
                    S.profitReportPage.profit.statusBoard.summary.profitLabel
                }
                value={_data?.profitTotal}
            />
            <ProfitReportStatusBoardSummaryItem
                color={Palette.deepBlue}
                title={S.profitReportPage.profit.statusBoard.summary.salesLabel}
                value={_data?.salesTotal}
                icon={
                    <SVG
                        asset={SVGAssets.EqualCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
            />
            <ProfitReportStatusBoardSummaryItem
                color={Palette.orange500}
                title={
                    S.profitReportPage.profit.statusBoard.summary.purchasesLabel
                }
                value={_data?.purchasesTotal}
                icon={
                    <SVG
                        asset={SVGAssets.MinusCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
            />
            <ProfitReportStatusBoardSummaryItem
                color={Palette.gray500}
                title={
                    S.profitReportPage.profit.statusBoard.summary
                        .otherExpensesLabel
                }
                value={_data?.otherExpensesTotal}
                icon={
                    <SVG
                        asset={SVGAssets.MinusCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
                infoButton={<OtherExpensesInfoButton width={"240px"}/>}
            />
        </LayoutContainer>
    );
};

export default ProfitReportProfitStatusBoardSummaryView;
