import billingState from "presentation/states/billing/billing_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const billingYearlyTableSelector = selector({
    key: "billingYearlyTableSelector",
    get: ({get}) => {
        const state = get(billingState);

        return {
            taxStartDate: DateTime.fromKey(state.taxStartDateKey),
            selectedYear: DateTime.fromKey(state.yearlyTable.selectedYearKey),
            data: state.yearlyTable.data,
        };
    },
});

export default billingYearlyTableSelector;
