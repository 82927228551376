import Palette from "presentation/theme/palette";
import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $width: string;
    $height: string;
    $paddingInPx: number;
}>((props) => ({
    style: {
        maxWidth: props.$width,
        height: props.$height,
        padding: props.$paddingInPx,
    },
}))`
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 0 0 1px ${Palette.gray200} inset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    transition: min-width 0.3s ease-in-out, max-width 0.3s ease-in-out,
        height 0.3s ease-in-out, padding 0.3s ease-in-out;

    @media (max-width: 1280px) {
        min-width: ${(props) => props.$width};
    }
`;

const CalendarListBox = ({
                             width,
                             height,
                             paddingInPx,
                             children,
                         }: PropsWithChildren<{
    width: string;
    height: string;
    paddingInPx: number;
}>) => {
    return (
        <LayoutContainer
            $width={width}
            $height={height}
            $paddingInPx={paddingInPx}
        >
            {children}
        </LayoutContainer>
    );
};

export default CalendarListBox;
