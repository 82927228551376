import {ChartXAxisData} from "presentation/components/charts/common/chart_model";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LabelContainer = styled.p.attrs<{
    $highlighted: boolean;
    $widthInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
        color: props.$highlighted ? Palette.gray800 : Palette.gray400,
    },
}))`
    height: 17px;
    ${Fonts.detail1};
    text-align: center;
    position: absolute;
    bottom: 0px;
    transform: translateX(-50%);
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out,
        color 0.3s ease-in-out;
`;

const ChartXAxis = ({
                        data,
                        highlightedIndex,
                        highlightedIndices,
                    }: {
    data: ChartXAxisData[];
    highlightedIndex?: number;
    highlightedIndices?: Set<number>;
}) => {
    return (
        <>
            {data.map((d, index) => {
                const highlighted =
                    highlightedIndex === index ||
                    (highlightedIndices?.has(index) ?? false);

                return (
                    <LabelContainer
                        key={index}
                        $highlighted={highlighted}
                        $widthInPx={d.binWidthInPx}
                        $leftOffsetInPx={d.xPosFromLeft}
                    >
                        {d.label}
                    </LabelContainer>
                );
            })}
        </>
    );
};

export default ChartXAxis;
