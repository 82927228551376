import externalSyncsState from "presentation/states/external_syncs/external_syncs_state";
import {selector} from "recoil";

const externalSyncsHomeTaxSelector = selector({
    key: "externalSyncsHomeTaxSelector",
    get: ({get}) => {
        const state = get(externalSyncsState);

        return state.homeTax;
    },
});

export default externalSyncsHomeTaxSelector;
