import Palette from "presentation/theme/palette";
import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $clickable: boolean;
    $flex?: number;
    $minWidthInPx: number;
    $maxWidthInPx?: number;
    $heightInPx: number;
    $leftMarginInPx: number;
    $rightDivider: boolean;
    $roundLeft: boolean;
    $roundRight: boolean;
    $backgroundColor: string;
}>((props) => ({
    style: {
        cursor: props.$clickable ? "pointer" : "default",
        flex: props.$flex !== undefined ? props.$flex : "auto",
        minWidth:
            props.$flex !== undefined
                ? `${props.$minWidthInPx * props.$flex}px`
                : "auto",
        maxWidth:
            props.$maxWidthInPx !== undefined
                ? `${props.$maxWidthInPx}px`
                : "auto",
        height: `${props.$heightInPx}px`,
        marginLeft: `${props.$leftMarginInPx}px`,
        borderRight: props.$rightDivider
            ? `1px solid ${Palette.gray100}`
            : "none",
        borderTopLeftRadius: props.$roundLeft ? "16px" : "0",
        borderBottomLeftRadius: props.$roundLeft ? "16px" : "0",
        borderTopRightRadius: props.$roundRight ? "16px" : "0",
        borderBottomRightRadius: props.$roundRight ? "16px" : "0",
        backgroundColor: props.$backgroundColor,
    },
}))`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: min-width 0.3s ease-in-out, height 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
`;

const TableCellLayout = ({
                             flex,
                             minWidthInPx,
                             maxWidthInPx,
                             heightInPx,
                             leftMarginInPx,
                             rightDivider = false,
                             roundLeft,
                             roundRight,
                             backgroundColor,
                             children,
                             onClick,
                         }: PropsWithChildren<{
    flex?: number;
    minWidthInPx: number;
    maxWidthInPx?: number;
    heightInPx: number;
    leftMarginInPx: number;
    rightDivider?: boolean;
    roundLeft: boolean;
    roundRight: boolean;
    backgroundColor: string;
    onClick?: () => void;
}>) => {
    return (
        <LayoutContainer
            onClick={onClick}
            $clickable={!!onClick}
            $flex={flex}
            $minWidthInPx={minWidthInPx}
            $maxWidthInPx={maxWidthInPx}
            $heightInPx={heightInPx}
            $leftMarginInPx={leftMarginInPx}
            $rightDivider={rightDivider}
            $roundLeft={roundLeft}
            $roundRight={roundRight}
            $backgroundColor={backgroundColor}
        >
            {children}
        </LayoutContainer>
    );
};

export default TableCellLayout;
