import NumberHelper from "config/helper/number_helper";
import {DispensingDateFilterTypeMap} from "domain/model/tax/profit_report/dispensing_date_filter_type";
import {ChartAxisData} from "presentation/components/charts/common/chart_model";
import DispensingChart from "presentation/components/charts/dispensing_chart/dispensing_chart";
import profitReportDispensingStatusBoardSelector
    from "presentation/states/profit_report/selector/dispensing/status_board/profit_report_dispensing_status_board_date_filter_selector";
import S from "presentation/theme/s";
import {DateDifferenceUnitType} from "presentation/utils/extension/date_extension";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";

const ProfitReportDispensingStatusBoardChartView = () => {
    const {selectedDateFilter, startDate, endDate, data, accumulated} =
        useRecoilValue(profitReportDispensingStatusBoardSelector);

    const initialScrollPosition =
        DispensingDateFilterTypeMap.initialScrollPosition(selectedDateFilter);

    const {chartData, xAxis, yAxis} = useMemo(() => {
        const chartData = data.map((d) => ({
            x: d.date.subtract(startDate, DispensingDateFilterTypeMap.dateDifferenceUnitType(selectedDateFilter)),
            y: d.dispensingAmount,
            payload1: d.billingCount,
            payload2: Math.floor(d.dispensingAmount / d.billingCount),
        }));

        if (accumulated) {
            const maxIndex = chartData.length - 1;
            for (let i = 0; i <= maxIndex; i++) {
                const cur = chartData[i];
                const prev = i > 0 ? chartData.at(i - 1) : undefined;

                chartData[i] = {
                    x: cur.x,
                    y: cur.y + (prev?.y ?? 0),
                    payload1: cur.payload1 + (prev?.payload1 ?? 0),
                    payload2: Math.floor(
                        (cur.y + (prev?.y ?? 0)) /
                        (cur.payload1 + (prev?.payload1 ?? 0))
                    ),
                };
            }
        }

        const minY = 0;
        const maxY = Math.max(...chartData.map((d) => d.y));

        const yLabelCount = 8;
        const unit = NumberHelper.getUnitFromMinMaxCount(
            minY,
            maxY,
            yLabelCount
        );
        const [, upperBoundY] = NumberHelper.getBoundsForCount(
            minY,
            maxY,
            yLabelCount
        );

        const xAxisDateDifferenceUnitType =
            DispensingDateFilterTypeMap.dateDifferenceUnitType(
                selectedDateFilter
            );
        const xAxisLength =
            endDate.subtract(startDate, xAxisDateDifferenceUnitType) + 1;

        const xAxis = data.length
            ? Array.from(
                {
                    length: xAxisLength,
                },
                (_, index) => ({
                    value: index,
                    label: startDate
                        .copyWith({
                            month:
                                xAxisDateDifferenceUnitType ===
                                DateDifferenceUnitType.Month
                                    ? startDate.month + index
                                    : undefined,
                            day:
                                xAxisDateDifferenceUnitType ===
                                DateDifferenceUnitType.Day
                                    ? startDate.day + index
                                    : undefined,
                        })
                        .format(
                            S.profitReportPage.dispensing.statusBoard
                                .xAxisDateFormat
                        ),
                })
            )
            : [];
        const yAxis: ChartAxisData[] = [];

        let pointer = 0;
        while (pointer <= upperBoundY) {
            yAxis.push({
                value: pointer,
                label: NumberHelper.toChartYAxisAbbreviatedString(pointer),
            });
            pointer += unit;
        }

        return {
            chartData,
            xAxis,
            yAxis,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, accumulated]);

    return (
        <DispensingChart
            heightInPx={470}
            data={chartData}
            xAxis={xAxis}
            yAxis={yAxis}
            initialScrollPosition={initialScrollPosition}
        />
    );
};

export default ProfitReportDispensingStatusBoardChartView;
