import ExternalSyncsRepository from "data/repository/external_syncs_repository";
import JointCertRepository from "data/repository/joint_cert_repository";
import ErrorCode from "domain/model/common/error_code";
import ExternalSyncsContent from "presentation/pages/external_syncs/components/external_syncs_content";
import {ExternalSyncsRefresh} from "presentation/states/external_syncs/external_syncs_listenable_state";
import {externalSyncsListenableState} from "presentation/states/external_syncs/external_syncs_state";
import externalSyncsHiraSelector from "presentation/states/external_syncs/selector/external_syncs_hira_selector";
import S from "presentation/theme/s";
import downloadExternalFile from "presentation/utils/functions/download_external_file";
import getPlatformType from "presentation/utils/functions/get_platform_type";
import useRepository from "presentation/utils/hooks/use_repository";
import {useSetListenableState} from "presentation/utils/hooks/use_set_listenable_state";
import useShowErrorDialog from "presentation/utils/hooks/use_show_error_dialog";
import {useRecoilValue} from "recoil";

const ExternalSyncsHiraView = () => {
    const jointCertRepository = useRepository(JointCertRepository);
    const externalSyncsRepository = useRepository(ExternalSyncsRepository);
    const showErrorDialog = useShowErrorDialog();
    const setListenableState = useSetListenableState(
        externalSyncsListenableState
    );

    const hira = useRecoilValue(externalSyncsHiraSelector);

    const synced = !!hira;
    const syncedDescription = hira?.label ?? "";

    const onClick = async () => {
        const result = await jointCertRepository({
            handler: async (repository) => {
                const platform = getPlatformType();

                if (platform !== "windows") {
                    showErrorDialog({
                        title: S.externalSyncsPage.invalidPlatformErrorTitle,
                        message:
                        S.externalSyncsPage.invalidPlatformErrorMessage,
                    });
                    return;
                }

                const isInstalled = await repository.isInstalled();

                if (!isInstalled) {
                    const installURL = await repository.getInstallURL();

                    if (!installURL) return;

                    downloadExternalFile(installURL);
                    return;
                }

                const jointCert = await repository.getJointCert();
                const jointCertKey = await repository.getJointCertKey(
                    jointCert
                );

                return {
                    jointCert,
                    jointCertKey,
                };
            },
            onDomainError: (errorCode, showUnknownError) => {
                switch (errorCode) {
                    case ErrorCode.JointCertWindowClosed:
                        break;

                    default:
                        showUnknownError();
                        break;
                }
            },
        });

        if (!result) return;

        const {jointCert, jointCertKey} = result;

        externalSyncsRepository({
            handler: async (repository) => {
                await repository.syncHira({
                    jointCert,
                    jointCertKey,
                });
            },
        });

        setListenableState(new ExternalSyncsRefresh());
    };

    return (
        <ExternalSyncsContent
            title={S.externalSyncsPage.hira.title}
            description={S.externalSyncsPage.hira.description}
            synced={synced}
            unsyncedDescription={
                S.externalSyncsPage.jointCertBasedUnsyncedLabel
            }
            syncedDescription={syncedDescription}
            onClick={onClick}
        />
    );
};

export default ExternalSyncsHiraView;
