import ViewStatusType from "domain/model/common/view_status_type";
import SizedBox from "presentation/components/common/sized_box";
import ContentBox from "presentation/components/content/content_box";
import ContentLayout from "presentation/components/content/content_layout";
import ProfitReportProfitYearlyDataTableView
    from "presentation/pages/profit_report/view/profit/yearly_table/profit_report_profit_yearly_data_table_view";
import ProfitReportProfitYearlyTableHeaderView
    from "presentation/pages/profit_report/view/profit/yearly_table/profit_report_profit_yearly_table_header_view";
import {ProfitReportScrollToElement} from "presentation/states/profit_report/profit_report_replay_state";
import {profitReportReplayState} from "presentation/states/profit_report/profit_report_state";
import {useReplayState} from "presentation/utils/hooks/use_replay_state";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import useScrollIntoView from "presentation/utils/hooks/use_scroll_into_view";
import {useRef} from "react";

const ProfitReportProfitYearlyTableView = ({
                                               status,
                                           }: {
    status: ViewStatusType;
}) => {
    const scrollIntoView = useScrollIntoView();

    const boxRef = useRef<HTMLDivElement>(null);
    const layoutRef = useRef<HTMLDivElement>(null);

    const {height: contentHeight} = useResizeObserver(layoutRef, []);

    useReplayState(
        profitReportReplayState,
        (state, handled) => {
            if (state instanceof ProfitReportScrollToElement) {
                if (state.target !== "yearlyTable") return;

                scrollIntoView(boxRef);
                handled();
            }
        },
        status
    );

    return (
        <ContentBox ref={boxRef} contentHeight={contentHeight}>
            <ContentLayout ref={layoutRef}>
                <ProfitReportProfitYearlyTableHeaderView/>
                <SizedBox height={"12px"}/>
                <ProfitReportProfitYearlyDataTableView/>
            </ContentLayout>
        </ContentBox>
    );
};

export default ProfitReportProfitYearlyTableView;
