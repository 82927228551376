import {ProfitReportFiguresDetailTypeMap} from "domain/model/tax/profit_report/profit_report_figures_detail_type";
import ProfitReportFiguresType, {
    ProfitReportFiguresTypeMap,
} from "domain/model/tax/profit_report/profit_report_figures_type";
import OtherExpensesInfoButton
    from "presentation/components/button/other_expenses_info_button/other_expenses_info_button";
import HorizontalStackedBarChart
    from "presentation/components/charts/horizontal_stacked_bar_chart/horizontal_stacked_bar_chart";
import SizedBox from "presentation/components/common/sized_box";
import Spacer from "presentation/components/common/spacer";
import StatisticNumberLarge from "presentation/components/statistic/statistic_number_large";
import StatisticTitleLarge from "presentation/components/statistic/statistic_title_large";
import DashboardFiguresComparison from "presentation/pages/dashboard/components/dashboard_card_figures_comparison";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import DashboardOtherFiguresChartLayout
    from "presentation/pages/dashboard/view/other_figures/chart/components/dashboard_other_figures_chart_layout";
import DashboardOtherFiguresChartLegends
    from "presentation/pages/dashboard/view/other_figures/chart/components/dashboard_other_figures_chart_legends";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import dashboardProfitSelector from "presentation/states/dashboard/selector/dashboard_profit_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useDesktopQuery from "presentation/utils/hooks/use_desktop_query";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import {useContext, useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const DashboardOtherFiguresChartOtherExpensesView = () => {
    const {contentHeight} = useContext(DashboardRowContext);
    const isMobile = useMobileQuery();
    const isDesktop = useDesktopQuery();

    const {previousYearMonth, selectedYearMonth} = useRecoilValue(
        dashboardDateSelector
    );
    const {data} = useRecoilValue(dashboardProfitSelector);

    const {total, previousTotal, otherExpensesData, legendData} =
        useMemo(() => {
            const currentData = data.find((d) =>
                d.date.isSameMonth(selectedYearMonth)
            );
            const previousData = data.find((d) =>
                d.date.isSameMonth(previousYearMonth)
            );

            const otherExpensesFiguresTypes =
                ProfitReportFiguresTypeMap.profitReportFiguresDetailTypes(
                    ProfitReportFiguresType.OtherExpenses
                );
            const otherExpensesData = otherExpensesFiguresTypes.map((t) => ({
                label: ProfitReportFiguresDetailTypeMap.shortLabel(t),
                value: 0,
                color: ProfitReportFiguresDetailTypeMap.color(t),
            }));
            otherExpensesFiguresTypes.forEach((_, i) => {
                otherExpensesData[i].value +=
                    currentData?.otherExpenses[i + 1] ?? 0;
            });

            const total = currentData?.otherExpenses[0];
            const previousTotal = previousData?.otherExpenses[0];

            const legendData = currentData
                ? otherExpensesData
                : [
                    {
                        label: S.dashboardPage.otherFigures
                            .personnelCostsTitle,
                        color: Palette.gray500,
                    },
                    {
                        label: S.dashboardPage.otherFigures.welfareCostsTitle,
                        color: Palette.gray400,
                    },
                    {
                        label: S.dashboardPage.otherFigures.otherCostsTitle,
                        color: Palette.gray300,
                    },
                ];

            return {
                total,
                previousTotal,
                otherExpensesData,
                legendData,
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data]);

    const tooltipAlignment = isMobile || isDesktop ? "center" : "right";
    const tooltipWidth = isDesktop ? "540px" : "240px";

    return (
        <DashboardOtherFiguresChartLayout height={contentHeight!}>
            <RowContainer>
                <StatisticTitleLarge
                    title={S.dashboardPage.otherFigures.otherExpensesTitle}
                    color={Palette.gray800}
                />
                <OtherExpensesInfoButton
                    width={tooltipWidth}
                    alignment={tooltipAlignment}
                />
            </RowContainer>
            <StatisticNumberLarge
                value={total}
                unit={S.dashboardPage.wonUnit}
                color={Palette.gray800}
                unitColor={Palette.gray600}
            />
            <SizedBox height={"8px"}/>
            <DashboardFiguresComparison
                titleColor={Palette.gray800}
                previousValue={previousTotal}
                value={total}
            />
            <Spacer/>
            <HorizontalStackedBarChart
                data={otherExpensesData}
                hoverEnabled={false}
                showLegends={false}
            />
            <SizedBox height={"17.5px"}/>
            <DashboardOtherFiguresChartLegends data={legendData}/>
        </DashboardOtherFiguresChartLayout>
    );
};

export default DashboardOtherFiguresChartOtherExpensesView;
