import {AppRoutesMap} from "presentation/routes/model/app_routes";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {RefObject} from "react";
import {useLocation} from "react-router-dom";

const useScrollIntoView = () => {
    const theme = useThemeContext();
    const {pathname} = useLocation();

    return (ref: RefObject<HTMLElement>) => {
        const bounds = ref.current?.getBoundingClientRect();

        if (!bounds) return;

        const top = bounds.top;
        const route = AppRoutesMap.fromPathname(pathname);
        if (!route) return;

        const tnbHeightInPx =
            Number(theme.componentTheme.tnbHeight.replaceAll("rem", "")) * 16;
        const tabBarHeightInPx =
            Number(theme.componentTheme.tabBarHeight.replaceAll("rem", "")) *
            16;

        const headerHeight =
            tnbHeightInPx +
            (AppRoutesMap.hasTabBar(route) ? tabBarHeightInPx : 0);

        addPostFrameCallback(() =>
            window.scrollTo({
                behavior: "smooth",
                top: top - window.scrollY - headerHeight,
            })
        );
    };
};

export default useScrollIntoView;
