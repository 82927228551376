import AppRoutes, {AppRoutesMap,} from "presentation/routes/model/app_routes";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {useLayoutEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const useTNBHeight = () => {
    const theme = useThemeContext();
    const {pathname} = useLocation();
    const [pageContentHeight, setPageContentHeight] = useState("0rem");

    useLayoutEffect(() => {
        const route = Object.values(AppRoutes).find((route) =>
            route.includes(pathname)
        );

        if (!route) return;

        const hasTabBar = AppRoutesMap.hasTabBar(route);

        setPageContentHeight(
            `calc(${theme.componentTheme.tnbHeight} + ${
                hasTabBar ? theme.componentTheme.tabBarHeight : "0rem"
            })`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return pageContentHeight;
};

export default useTNBHeight;
