import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
`;

const TitleContainer = styled.h6`
    min-width: 32px;
    ${Fonts.detail1}
    color: ${Palette.gray400};
`;

const LNBSection = ({
                        title,
                        children,
                    }: PropsWithChildren<{ title: string }>) => {
    return (
        <LayoutContainer>
            <TitleContainer>{title}</TitleContainer>
            {children}
        </LayoutContainer>
    );
};

export default LNBSection;
