import Fonts from "presentation/theme/fonts";
import styled from "styled-components";

const LayoutContainer = styled.div`
    min-width: max-content;
    ${Fonts.detail2Medium};
    text-align: center;
`;

const TablePrimaryHeaderLabel = ({
                                     className,
                                     label,
                                 }: {
    className?: string;
    label: string;
}) => {
    return <LayoutContainer className={className}>{label}</LayoutContainer>;
};

export default TablePrimaryHeaderLabel;
