import SyncCrefiaDialog from "presentation/components/dialog/sync_crefia_dialog/sync_crefia_dialog";
import ExternalSyncsContent from "presentation/pages/external_syncs/components/external_syncs_content";
import {ExternalSyncsRefresh} from "presentation/states/external_syncs/external_syncs_listenable_state";
import {externalSyncsListenableState} from "presentation/states/external_syncs/external_syncs_state";
import externalSyncsCrefiaSelector from "presentation/states/external_syncs/selector/external_syncs_crefia_selector";
import S from "presentation/theme/s";
import {useSetListenableState} from "presentation/utils/hooks/use_set_listenable_state";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import {useRecoilValue} from "recoil";

const ExternalSyncsCrefiaView = () => {
    const showDialog = useShowDialog();
    const setListenableState = useSetListenableState(
        externalSyncsListenableState
    );

    const crefia = useRecoilValue(externalSyncsCrefiaSelector);

    const synced = !!crefia;
    const syncedDescription = crefia?.label ?? "";

    const onClick = () =>
        showDialog({
            className: "crefia-sync-dialog",
            component: <SyncCrefiaDialog/>,
            onDismissed: () => setListenableState(new ExternalSyncsRefresh()),
            dismissible: false,
        });

    return (
        <ExternalSyncsContent
            title={S.externalSyncsPage.crefia.title}
            description={S.externalSyncsPage.crefia.description}
            synced={synced}
            unsyncedDescription={S.externalSyncsPage.passwordBasedUnsyncedLabel}
            syncedDescription={syncedDescription}
            onClick={onClick}
        />
    );
};

export default ExternalSyncsCrefiaView;
