import DropdownButtonItem from "presentation/components/button/dropdown_button/components/dropdown_button_item";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";

const itemHeightInPx = 36;
const itemGapInPx = 8;
const dropdownPaddingInPx = 8;

const LayoutContainer = styled.div`
    position: relative;
`;

const ButtonContainer = styled.button.attrs<{
    $hovered: boolean;
    $selecting: boolean;
    $widthInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        boxShadow: `0 0 0 1.5px ${
            props.$hovered || props.$selecting
                ? Palette.primary500
                : Palette.gray200
        } inset`,
    },
}))`
    padding: 8px 16px;
    border-radius: 12px;
    background-color: ${Palette.white100};
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 30;
    transition: width 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
`;

const DropdownContainer = styled.ul.attrs<{
    $selecting: boolean;
    $widthInPx: number;
    $heightInPx: number;
    $paddingInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        opacity: props.$selecting ? 1 : 0,
        top: props.$selecting ? "calc(100% + 8px)" : "calc(90%)",
        pointerEvents: props.$selecting ? "auto" : "none",
    },
}))`
    height: ${(props) => `${props.$heightInPx}px`};
    padding: ${(props) => `${props.$paddingInPx}px`};
    background-color: ${Palette.white100};
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 8px;
    position: absolute;
    z-index: 29;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
    transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out,
        top 0.3s ease-in-out;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const ButtonLabelContainer = styled.p`
    ${Fonts.detail3Medium};
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
`;

const DropdownButton = ({
                            minWidthInPx,
                            maxWidthInPx,
                            selectedIndex,
                            onSelectedIndexChange,
                            maxIndexVisible = 5,
                            labels,
                        }: {
    minWidthInPx: number;
    maxWidthInPx: number;
    selectedIndex: number;
    onSelectedIndexChange: (index: number) => void;
    maxIndexVisible?: number;
    labels: string[];
}) => {
    const isMobile = useMobileQuery();

    const buttonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null);
    const [hovered, setHovered] = useState(false);
    const [selecting, setSelecting] = useState(false);

    const {dropdownMaxHeightInPx} = useMemo(() => {
        const dropdownMaxHeightInPx =
            (itemHeightInPx + itemGapInPx) *
            Math.min(labels.length, maxIndexVisible) -
            itemGapInPx +
            2 * dropdownPaddingInPx;
        return {dropdownMaxHeightInPx};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxIndexVisible, labels.length]);

    const onClick = (e: MouseEvent) => {
        if (buttonRef.current && buttonRef.current.contains(e.target as Node)) {
            setSelecting(true);
            return;
        }

        if (
            dropdownRef.current &&
            dropdownRef.current.contains(e.target as Node)
        ) {
            return;
        }

        setSelecting(false);
    };

    useLayoutEffect(() => {
        window.addEventListener("mousedown", onClick);

        return () => window.removeEventListener("mousedown", onClick);
    }, []);

    useEffect(() => {
        const button = buttonRef.current;
        if (!button) return;

        const onMouseEnter = () => setHovered(true);

        const onMouseLeave = () => setHovered(false);

        button.addEventListener("mouseenter", onMouseEnter);
        button.addEventListener("mouseleave", onMouseLeave);

        return () => {
            button.removeEventListener("mouseenter", onMouseEnter);
            button.removeEventListener("mouseleave", onMouseLeave);
        };
    }, [buttonRef]);

    useEffect(() => {
        if (!selecting) return;

        dropdownRef.current?.scrollTo({
            top:
                selectedIndex * (itemHeightInPx + itemGapInPx) +
                dropdownPaddingInPx -
                itemGapInPx,
            behavior: "smooth",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selecting]);

    const selectedLabel = labels.at(selectedIndex) ?? labels.at(0);

    const onItemClick = (index: number) => () => {
        onSelectedIndexChange(index);
        setSelecting(false);
    };

    const widthInPx = isMobile ? minWidthInPx : maxWidthInPx;

    return (
        <LayoutContainer>
            <ButtonContainer
                ref={buttonRef}
                $hovered={hovered}
                $selecting={selecting}
                $widthInPx={widthInPx}
            >
                <ButtonLabelContainer>{selectedLabel}</ButtonLabelContainer>
                <SVG
                    asset={SVGAssets.Down}
                    width={"24px"}
                    height={"24px"}
                    color={Palette.gray800}
                />
            </ButtonContainer>
            <DropdownContainer
                ref={dropdownRef}
                $selecting={selecting}
                $widthInPx={widthInPx}
                $heightInPx={dropdownMaxHeightInPx}
                $paddingInPx={dropdownPaddingInPx}
            >
                {labels.map((label, index) => (
                    <DropdownButtonItem
                        key={label}
                        selected={index === selectedIndex}
                        heightInPx={itemHeightInPx}
                        label={label}
                        onClick={onItemClick(index)}
                    />
                ))}
            </DropdownContainer>
        </LayoutContainer>
    );
};

export default DropdownButton;
