import ChartHorizontalGrid from "presentation/components/charts/common/chart_horizontal_grid";
import ChartXAxis from "presentation/components/charts/common/chart_x_axis";
import ChartYAxis from "presentation/components/charts/common/chart_y_axis";
import {createContext, Dispatch, SetStateAction, useEffect, useMemo, useRef, useState,} from "react";
import {optional} from "presentation/utils/types/optional";
import ChartLayout from "presentation/components/charts/common/chart_layout";
import {ChartAxisData, ChartInitialScrollPositionType,} from "presentation/components/charts/common/chart_model";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";
import DispensingChartData from "presentation/components/charts/dispensing_chart/dispensing_chart_model";
import DispensingChartBars from "presentation/components/charts/dispensing_chart/components/dispensing_chart_bars";
import DispensingChartDottedLines
    from "presentation/components/charts/dispensing_chart/components/dispensing_dotted_lines";
import DispensingChartTooltip
    from "presentation/components/charts/dispensing_chart/components/dispensing_chart_tooltip";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import DispensingChartCollectingBars
    from "presentation/components/charts/dispensing_chart/components/dispensing_chart_collecting_bars";
import ChartEmptyOverlay from "presentation/components/charts/common/chart_empty_overlay";

const topMarginInPx = 10;
const _binMarginInPx = 35;
const binSizeInPx = 52;
const xAxisHeightInPx = 30;
const yAxisWidthInPx = 60;
const _xAxisHorizontalMarginInPx = 52;
const barBorderRadiusInPx = 12;
const barRimPaddingInPx = 8;
const collectingTooltipHeightInPx = 33;
const collectingTooltipHorizontalPaddingInPx = 8;
const collectingTooltipMarginInPx = 16;

export const DispensingChartContext = createContext<{
    constants: {
        topMarginInPx: number;
        innerWidthInPx: number;
        widthInPx: number;
        innerHeightInPx: number;
        heightInPx: number;
        binSizeInPx: number;
        xAxisHorizontalMarginInPx: number;
        barBorderRadiusInPx: number;
        barRimPaddingInPx: number;
        collectingTooltipHeightInPx: number;
        collectingTooltipHorizontalPaddingInPx: number;
        collectingTooltipMarginInPx: number;
    };
    constantsKey: string;
    functions: {
        getXPos: (x: number) => number;
        getYPosFromTop: (y: number) => number;
        getYPosFromBottom: (y: number) => number;
    };
    data: DispensingChartData[];
    highlightedXIndices?: Set<number>;
    highlightedData?: {
        value?: number;
        x: number;
        y: number;
    }[];
    hoveredXIndex?: number;
    setHoveredXIndex?: Dispatch<SetStateAction<optional<number>>>;
}>({
    constants: {
        topMarginInPx,
        innerWidthInPx: 500,
        widthInPx: 500,
        innerHeightInPx: 470,
        heightInPx: 470,
        binSizeInPx,
        xAxisHorizontalMarginInPx: _xAxisHorizontalMarginInPx,
        barBorderRadiusInPx,
        barRimPaddingInPx,
        collectingTooltipHeightInPx,
        collectingTooltipHorizontalPaddingInPx,
        collectingTooltipMarginInPx,
    },
    constantsKey: "",
    functions: {
        getXPos: (x: number) => x,
        getYPosFromTop: (y: number) => y,
        getYPosFromBottom: (y: number) => y,
    },
    data: [] as DispensingChartData[],
    highlightedData: undefined,
    highlightedXIndices: undefined,
    hoveredXIndex: undefined,
    setHoveredXIndex: undefined,
});

const DispensingChart = ({
                             heightInPx,
                             data,
                             highlightedXIndices,
                             highlightedXAxisIndices,
                             xAxis,
                             yAxis,
                             initialScrollPosition = ChartInitialScrollPositionType.Left,
                             overrideConstants,
                             hoverEnabled = true,
                             showEmptyOverlay = true,
                         }: {
    heightInPx: number;
    data: DispensingChartData[];
    highlightedXIndices?: Set<number>;
    highlightedXAxisIndices?: Set<number>;
    xAxis: ChartAxisData[];
    yAxis: ChartAxisData[];
    initialScrollPosition?: ChartInitialScrollPositionType;
    overrideConstants?: {
        binMarginInPx: number;
        xAxisHorizontalMarginInPx: number;
    };
    hoverEnabled?: boolean;
    showEmptyOverlay?: boolean;
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [hoveredXIndex, setHoveredXIndex] =
        useState<optional<number>>(undefined);
    useResizeObserver(ref, [data]);

    const dataEmpty = !data.length;

    const {xRange, yRange} = useMemo(
        () => {
            const xValues = xAxis.map((x) => x.value);
            const yValues = yAxis.map((y) => y.value);

            const xRange = {
                min: Math.min(...xValues),
                max: Math.max(...xValues),
            };
            const yRange = {
                min: 0,
                max: Math.max(...yValues),
            };

            return {
                xRange,
                yRange,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const collectingData = useMemo(() => {
        if (!highlightedXIndices) return undefined;

        return Array.from(highlightedXIndices!).map((x) => {
            const match = data.find((d) => d.x === x);
            const amplitude = Math.abs(yRange.max - yRange.min);
            const minY = yRange.min + amplitude / 3;
            const maxY = yRange.max - amplitude / 3;
            const y = !!match ? match.y : minY + Math.random() * (maxY - minY);
            const value = match?.y;

            return {
                value: value,
                x,
                y,
            };
        });
    }, [data, highlightedXIndices, yRange]);

    const {constants, constantsKey, functions, gridWidthInPx} =
        useMemo(() => {
            const binMarginInPx =
                overrideConstants?.binMarginInPx ?? _binMarginInPx;
            const xAxisHorizontalMarginInPx =
                overrideConstants?.xAxisHorizontalMarginInPx ??
                _xAxisHorizontalMarginInPx;

            const count = xRange.max - xRange.min;
            const binWidth = binSizeInPx + binMarginInPx * 2;
            const totalBinWidth = binWidth * count;
            const calculatedWidth =
                totalBinWidth + xAxisHorizontalMarginInPx * 2;

            const scrollbarHeight = Math.abs(
                (ref.current?.offsetHeight ?? 0) -
                (ref.current?.clientHeight ?? 0)
            );
            const availableWidthInPx = ref.current?.offsetWidth ?? 0;

            const constants = {
                topMarginInPx,
                widthInPx: calculatedWidth - yAxisWidthInPx,
                innerWidthInPx:
                    calculatedWidth -
                    yAxisWidthInPx -
                    xAxisHorizontalMarginInPx * 2,
                heightInPx,
                innerHeightInPx:
                    heightInPx -
                    (topMarginInPx + xAxisHeightInPx + scrollbarHeight),
                binSizeInPx,
                xAxisHorizontalMarginInPx,
                barBorderRadiusInPx,
                barRimPaddingInPx,
                collectingTooltipHeightInPx,
                collectingTooltipHorizontalPaddingInPx,
                collectingTooltipMarginInPx,
            };

            if (calculatedWidth < availableWidthInPx) {
                constants.widthInPx = availableWidthInPx - yAxisWidthInPx;
                constants.innerWidthInPx =
                    availableWidthInPx -
                    yAxisWidthInPx -
                    xAxisHorizontalMarginInPx * 2;
            }

            const getYPosFromTop = (y: number) => {
                const minY = yRange.min;
                const maxY = yRange.max;

                const magnitudeY = Math.abs(maxY - minY);
                const scaleY = constants.innerHeightInPx / magnitudeY;
                const yPos = (maxY - y) * scaleY;

                return yPos + topMarginInPx;
            };

            const getYPosFromBottom = (y: number) =>
                constants.heightInPx - getYPosFromTop(y) - scrollbarHeight;

            const functions = {
                getXPos: (x: number) => {
                    const minX = xRange.min;
                    const maxX = xRange.max;

                    const magnitudeX = Math.abs(maxX - minX);
                    const scaleX = constants.innerWidthInPx / magnitudeX;

                    return constants.xAxisHorizontalMarginInPx +
                        (x - minX) * scaleX;
                },
                getYPosFromTop,
                getYPosFromBottom,
            };

            const constantsKey = Object.entries(constants)
                .map(([key, value]) => `${key}-${value}`)
                .join();

            const gridWidthInPx =
                constants.innerWidthInPx +
                constants.xAxisHorizontalMarginInPx * 2;

            return {
                constants,
                constantsKey,
                functions,
                gridWidthInPx,
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            data,
            overrideConstants,
            ref.current?.offsetWidth,
            ref.current?.offsetHeight,
            ref.current?.clientHeight,
        ]);

    const {gridData, yAxisData} = useMemo(() => {
        const yAxisData = yAxis.map((y) => ({
            yPosFromTop: functions.getYPosFromTop(y.value),
            label: y.label,
        }));
        const gridData = yAxisData.map((y) => y.yPosFromTop);

        return {
            yAxisData,
            gridData,
        };
    }, [yAxis, functions]);

    const {xAxisData} = useMemo(() => {
        const xAxisData = xAxis.map((x) => ({
            binWidthInPx: binSizeInPx,
            xPosFromLeft: functions.getXPos(x.value),
            label: x.label,
        }));

        return {
            xAxisData,
        };
    }, [xAxis, functions]);

    useEffect(() => {
        if (!ref.current) return;

        let offset = 0;
        switch (initialScrollPosition) {
            case ChartInitialScrollPositionType.Left:
                offset = 0;
                break;
            case ChartInitialScrollPositionType.Middle:
                offset = constants.widthInPx / 2;
                break;
            case ChartInitialScrollPositionType.Right:
                offset = constants.widthInPx;
                break;
            default:
                throw new Error("Invalid ChartInitialScrollPositionType");
        }

        addPostFrameCallback(() =>
            ref.current?.scrollTo({
                left: offset,
                behavior: "smooth",
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, data.length]);

    useEffect(() => {
        setHoveredXIndex(undefined);
    }, [data]);

    return (
        <DispensingChartContext.Provider
            value={{
                constants,
                constantsKey,
                functions,
                data,
                highlightedData: collectingData,
                highlightedXIndices: highlightedXIndices,
                hoveredXIndex,
                setHoveredXIndex: hoverEnabled ? setHoveredXIndex : undefined,
            }}
        >
            <ChartLayout
                ref={ref}
                scrollable={!dataEmpty}
                heightInPx={constants.heightInPx}
                yAxis={
                    <ChartYAxis
                        widthInPx={yAxisWidthInPx}
                        heightInPx={constants.innerHeightInPx}
                        data={yAxisData}
                    />
                }
            >
                <ChartHorizontalGrid
                    widthInPx={gridWidthInPx}
                    yPositions={gridData}
                />
                <ChartXAxis
                    data={xAxisData}
                    highlightedIndices={highlightedXAxisIndices}
                />
                <DispensingChartBars/>
                <DispensingChartDottedLines/>
                {hoverEnabled && <DispensingChartTooltip/>}
                {highlightedXIndices && <DispensingChartCollectingBars/>}
                <ChartEmptyOverlay visible={showEmptyOverlay && dataEmpty}/>
            </ChartLayout>
        </DispensingChartContext.Provider>
    );
};

export default DispensingChart;
