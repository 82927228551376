import ViewStatusType from "domain/model/common/view_status_type";
import SizedBox from "presentation/components/common/sized_box";
import ContentBox from "presentation/components/content/content_box";
import ContentLayout from "presentation/components/content/content_layout";
import CardSalesStatusBoardCardSalesSummaryView
    from "presentation/pages/card_sales/view/status_board/card_sales_status_board_card_sales_summary_view";
import CardSalesStatusBoardCardSalesTitleView
    from "presentation/pages/card_sales/view/status_board/card_sales_status_board_card_sales_title_view";
import CardSalesStatusBoardDateButtonsView
    from "presentation/pages/card_sales/view/status_board/card_sales_status_board_date_buttons_view";
import CardSalesStatusBoardReceiveSummaryView
    from "presentation/pages/card_sales/view/status_board/card_sales_status_board_receive_summary_view";
import CardSalesStatusBoardReceiveTableHeaderView
    from "presentation/pages/card_sales/view/status_board/card_sales_status_board_receive_table_header_view";
import CardSalesStatusBoardReceiveTableView
    from "presentation/pages/card_sales/view/status_board/card_sales_status_board_receive_table_view";
import CardSalesStatusBoardTableHeaderView
    from "presentation/pages/card_sales/view/status_board/card_sales_status_board_table_header_view";
import CardSalesStatusBoardTableView
    from "presentation/pages/card_sales/view/status_board/card_sales_status_board_table_view";
import {CardSalesScrollToElement} from "presentation/states/card_sales/card_sales_replay_state";
import {cardSalesReplayState} from "presentation/states/card_sales/card_sales_state";
import {useReplayState} from "presentation/utils/hooks/use_replay_state";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import useScrollIntoView from "presentation/utils/hooks/use_scroll_into_view";
import {useRef} from "react";

const CardSalesStatusBoardView = ({status}: { status: ViewStatusType }) => {
    const scrollIntoView = useScrollIntoView();

    const boxRef = useRef<HTMLDivElement>(null);
    const layoutRef = useRef<HTMLDivElement>(null);

    useReplayState(
        cardSalesReplayState,
        (state, handled) => {
            if (state instanceof CardSalesScrollToElement) {
                if (state.target !== "statusBoard") return;

                scrollIntoView(boxRef);
                handled();
            }
        },
        status
    );

    const {height: contentHeight} = useResizeObserver(layoutRef, []);

    return (
        <ContentBox ref={boxRef} contentHeight={contentHeight}>
            <ContentLayout ref={layoutRef}>
                <CardSalesStatusBoardDateButtonsView/>
                <SizedBox height={"36px"}/>
                <CardSalesStatusBoardCardSalesTitleView/>
                <SizedBox height={"24px"}/>
                <CardSalesStatusBoardCardSalesSummaryView/>
                <SizedBox height={"36px"}/>
                <CardSalesStatusBoardTableHeaderView/>
                <SizedBox height={"12px"}/>
                <CardSalesStatusBoardTableView/>
                <SizedBox height={"60px"}/>
                <CardSalesStatusBoardReceiveSummaryView/>
                <SizedBox height={"36px"}/>
                <CardSalesStatusBoardReceiveTableHeaderView/>
                <SizedBox height={"12px"}/>
                <CardSalesStatusBoardReceiveTableView/>
            </ContentLayout>
        </ContentBox>
    );
};

export default CardSalesStatusBoardView;
