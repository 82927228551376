import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

const LabelContainer = styled.h4`
    ${Fonts.detail2};
    color: ${Palette.gray600};
    text-align: center;
`;

const LoginLogoView = () => {
    return (
        <LayoutContainer>
            <SVG asset={SVGAssets.LogoFull} width={"149px"}/>
            <LabelContainer>{S.loginPage.welcomeLabel}</LabelContainer>
        </LayoutContainer>
    );
};

export default LoginLogoView;
