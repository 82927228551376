import BaseRepository from "data/repository/base_repository";
import TaxTimeline from "domain/model/tax/tax_timeline/tax_timeline";
import DateTime from "presentation/utils/extension/date_extension";

export default class TaxTimelineRepository extends BaseRepository {
    getTaxTimeline = async ({
                                year,
                            }: {
        year: DateTime;
    }): Promise<TaxTimeline[]> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                return Array.from(
                    {
                        length: DateTime.monthsInYear,
                    },
                    (_, index) => {
                        const yearMonth = year.copyWith({
                            month: year.month + index,
                        });

                        if (
                            !yearMonth.isSameMonth(DateTime.now()) &&
                            Math.random() < 0.5
                        ) {
                            return undefined;
                        }
                        const due = yearMonth.copyWith({
                            day: Math.floor(1 + Math.random() * 27),
                        });
                        let secondDue = due.copyWith();
                        while (due.isEqual(secondDue)) {
                            secondDue = secondDue.copyWith({
                                day: Math.floor(1 + Math.random() * 27),
                            });
                        }

                        if (Math.random() < 0.3) {
                            return [
                                {
                                    due,
                                    declarationStartDate:
                                        due.getFirstDayInMonth(),
                                    declarationEndDate: due.copyWith(),
                                    description: "세금",
                                    lastPaidAmount:
                                        Math.random() > 0.5
                                            ? Math.floor(
                                                Math.random() * 50_000_000
                                            )
                                            : undefined,
                                },
                                {
                                    due: secondDue,
                                    declarationStartDate:
                                        secondDue.getFirstDayInMonth(),
                                    declarationEndDate: secondDue.copyWith(),
                                    description: "세금",
                                    lastPaidAmount:
                                        Math.random() > 0.5
                                            ? Math.floor(
                                                Math.random() * 50_000_000
                                            )
                                            : undefined,
                                },
                            ];
                        }

                        return {
                            due,
                            declarationStartDate: due.getFirstDayInMonth(),
                            declarationEndDate: due.copyWith(),
                            description: "세금",
                            lastPaidAmount:
                                Math.random() > 0.5 ? 1_000_000 : undefined,
                        };
                    }
                )
                    .flat()
                    .filter((f) => f !== undefined)
                    .map((d) => d!);
            },
        });
}
