import CalendarCell from "presentation/components/calendar/common/calendar_cell";
import CalendarWeekdayCell from "presentation/components/calendar/common/calendar_weekday_cell";
import {
    TransactionsCalendarContext
} from "presentation/components/calendar/transactions_calendar/transactions_calendar";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $verticalGapInPx: number }>(
    (props) => ({
        style: {
            gap: `${props.$verticalGapInPx}px`,
        },
    })
)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: gap 0.3s ease-in-out;
`;

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TransactionsCalendarMonth = ({month}: { month: DateTime }) => {
    const context = useContext(TransactionsCalendarContext);

    const {weekdayCellWidth, weekdayCellHeight, weekdays, weeks} =
        useMemo(() => {
            const isBeforeTwelve = DateTime.now().hour < 12;
            const now = DateTime.now().min;
            const yesterday = now.copyWith({day: now.day - 1});

            const suffixMapMonthKey = context.suffixMap
                ? Object.keys(context.suffixMap).at(0)
                : undefined;
            const suffixMapMonth = suffixMapMonthKey
                ? DateTime.fromKey(suffixMapMonthKey)
                : undefined;
            const weekdayCellWidth = `${context.calendarCellWidthInPx}px`;
            const weekdayCellHeight = `${context.weekdayHeaderHeightInPx}px`;

            const weekdays = DateTime.orthodoxWeekdays;
            const weeks = month.getOrthodoxWeeksInMonth().map((week) =>
                week.map((day) => {
                    const suffixHighlighted =
                        suffixMapMonth?.isSameMonth(month) &&
                        day.isSameDay(yesterday) &&
                        isBeforeTwelve &&
                        context.suffixMap?.[day.key] === undefined;
                    const suffix = suffixHighlighted
                        ? S.transactionsCalendar.collectingLabel
                        : context.suffixMap?.[day.key];

                    return {
                        key: day.key,
                        visible: day.isSameMonth(month),
                        enabled: day.isInRange(
                            context.startDate,
                            context.endDate
                        ),
                        holiday: day.isSunday,
                        selected: day.isSameDay(now),
                        value: day.format(S.calendarCell.dayFormat),
                        suffix,
                        suffixHighlighted,
                    };
                })
            );

            return {
                weekdayCellWidth,
                weekdayCellHeight,
                weekdays,
                weeks,
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            context.calendarCellWidthInPx,
            context.weekdayHeaderHeightInPx,
            month.key,
            context.startDate.key,
            context.endDate.key,
            context.suffixMap,
        ]);

    return (
        <LayoutContainer $verticalGapInPx={context.calendarRowVerticalGapInPx}>
            <RowContainer>
                {weekdays.map((weekday) => (
                    <CalendarWeekdayCell
                        key={weekday}
                        width={weekdayCellWidth}
                        height={weekdayCellHeight}
                        weekday={weekday}
                    />
                ))}
            </RowContainer>
            {weeks.map((week, weekIndex) => (
                <RowContainer key={weekIndex}>
                    {week.map((day) => (
                        <CalendarCell
                            key={day.key}
                            visible={day.visible}
                            enabled={day.enabled}
                            selected={day.selected}
                            holiday={day.holiday}
                            heightInPx={context.calendarCellHeightInPx}
                            widthInPx={context.calendarCellWidthInPx}
                            valueWidthInPx={context.calendarCellValueWidthInPx}
                            valueHeightInPx={
                                context.calendarCellValueHeightInPx
                            }
                            value={day.value}
                            suffix={day.suffix}
                            suffixHighlighted={day.suffixHighlighted}
                        />
                    ))}
                </RowContainer>
            ))}
        </LayoutContainer>
    );
};

export default TransactionsCalendarMonth;
