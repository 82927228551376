import {
    DateFilterPeriodCalendarContext
} from "presentation/components/button/date_filter_period_button/components/date_filter_period_calendar";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import DateTime from "presentation/utils/extension/date_extension";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
`;

const BackgroundContainer = styled.div.attrs<{
    $visible: boolean;
    $backgroundColor: string;
    $borderRadiusInPx: number;
    $leftCircle: boolean;
    $rightCircle: boolean;
}>((props) => ({
    style: {
        backgroundColor: props.$backgroundColor,
        clipPath:
            !props.$leftCircle && !props.$rightCircle
                ? "none"
                : props.$rightCircle
                    ? `circle(${props.$borderRadiusInPx}px at 0 50%)`
                    : props.$leftCircle
                        ? `circle(${props.$borderRadiusInPx}px at 100% 50%)`
                        : `circle(${props.$borderRadiusInPx}px at 50% 50%)`,
    },
}))`
    flex-grow: 1;
    height: 100%;
`;

const CellContainer = styled.button.attrs<{
    $dropdownVisible: boolean;
    $visible: boolean;
    $enabled: boolean;
    $selected: boolean;
    $cellSizeInPx: number;
    $borderRadiusInPx: number;
}>((props) => ({
    style: {
        backgroundColor: props.$selected ? Palette.primary500 : Palette.none,
        cursor: !props.$visible
            ? "default"
            : props.$enabled
                ? "pointer"
                : "not-allowed",
        pointerEvents:
            props.$dropdownVisible && props.$visible ? "auto" : "none",
    },
}))`
    width: ${(props) => `${props.$cellSizeInPx}px`};
    height: ${(props) => `${props.$cellSizeInPx}px`};
    border: none;
    border-radius: ${(props) => `${props.$borderRadiusInPx}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: ${(props) => (props.$visible ? 1 : 0)};
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
`;

const CellLabelContainer = styled.p.attrs<{
    $enabled: boolean;
    $selected: boolean;
    $holiday: boolean;
}>((props) => ({
    style: {
        color: !props.$enabled
            ? props.$holiday
                ? Palette.red200
                : Palette.gray300
            : props.$selected
                ? Palette.primary100
                : props.$holiday
                    ? Palette.red500
                    : Palette.gray800,
    },
}))`
    ${Fonts.detail3};
`;

const DateFilterPeriodCalendarCell = ({
                                          month,
                                          date,
                                      }: {
    month: DateTime;
    date: DateTime;
}) => {
    const context = useContext(DateFilterPeriodCalendarContext);

    const isLeftEnd = date.orthodoxWeekdayIndex === 0;
    const isRightEnd = date.orthodoxWeekdayIndex === DateTime.maxWeekdayIndex;

    const {holiday, cellSizeInPx, borderRadiusInPx} = useMemo(() => {
        const holiday = date.isSunday;
        const cellSizeInPx = context.constants.cellSizeInPx;
        const borderRadiusInPx = context.constants.cellSizeInPx / 2;

        return {
            holiday,
            cellSizeInPx,
            borderRadiusInPx,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        visible,
        enabled,
        selected,
        backgroundColor,
        backgroundLeftCircle,
        backgroundRightCircle,
    } = useMemo(() => {
        const enabled = date.isInRange(
            context.enabledStartDate,
            context.enabledEndDate
        );
        const visible = date.isSameMonth(month);
        const selectedStartDate = context.selectedStartDate;
        const selectedEndDate = context.selectedEndDate;
        const selected =
            date.isSameDay(selectedStartDate) ||
            date.isSameDay(selectedEndDate);

        const isSelectedStartDate = date.isSameDay(selectedStartDate);
        const isSelectedEndDate = date.isSameDay(selectedEndDate);
        const isInRange = date.isInRange(selectedStartDate, selectedEndDate);

        const backgroundColor =
            visible && enabled && isInRange ? Palette.primary10 : Palette.none;
        const backgroundLeftCircle =
            isSelectedStartDate ||
            isLeftEnd ||
            date.isFirstDayInMonth ||
            !isInRange;
        const backgroundRightCircle =
            isSelectedEndDate ||
            isRightEnd ||
            date.isLastDayInMonth ||
            !isInRange;

        return {
            visible,
            enabled,
            selected,
            backgroundColor,
            backgroundLeftCircle,
            backgroundRightCircle,
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        date.key,
        context.enabledStartDate?.key,
        context.enabledEndDate?.key,
        context.selectedStartDate?.key,
        context.selectedEndDate?.key,
    ]);

    const onDateClick = () => {
        if (!(visible && enabled)) return;

        context.onDateClick(date);
    };

    return (
        <LayoutContainer>
            <BackgroundContainer
                $visible={visible}
                $backgroundColor={backgroundColor}
                $borderRadiusInPx={borderRadiusInPx}
                $leftCircle={backgroundLeftCircle}
                $rightCircle={false}
            />
            <BackgroundContainer
                $visible={visible}
                $backgroundColor={backgroundColor}
                $borderRadiusInPx={borderRadiusInPx}
                $leftCircle={false}
                $rightCircle={backgroundRightCircle}
            />
            <CellContainer
                onClick={onDateClick}
                $dropdownVisible={context.dropdownVisible}
                $visible={visible}
                $enabled={enabled}
                $selected={selected}
                $cellSizeInPx={cellSizeInPx}
                $borderRadiusInPx={borderRadiusInPx}
            >
                <CellLabelContainer
                    $enabled={enabled}
                    $selected={selected}
                    $holiday={holiday}
                >
                    {date.day}
                </CellLabelContainer>
            </CellContainer>
        </LayoutContainer>
    );
};

export default DateFilterPeriodCalendarCell;
