import {forwardRef, PropsWithChildren, ReactElement} from "react";
import styled from "styled-components";
import ViewStatusType from "domain/model/common/view_status_type";
import Loading from "presentation/components/common/loading";
import useViewStatus from "presentation/utils/hooks/use_view_status";

const LayoutContainer = styled.div.attrs<{
    $loading: boolean;
    $blockPointer: boolean;
    $height?: string;
    $loadingColor: string;
    $backgroundColor: string;
    $hoverColor: string;
    $grow: boolean;
    $maxWidthOnGrow: string;
    $isButton: boolean;
}>((props) => ({
    style: {
        height: props.$height !== undefined ? props.$height : "auto",
        cursor: props.$isButton && !props.$loading ? "pointer" : "default",
    },
}))`
    min-width: 216px;
    max-width: max(100%, 216px);
    flex-grow: ${(props) => (props.$grow ? 1 : 0)};
    padding: 24px 12px;
    border-radius: 20px;
    box-shadow: 0px 3px 15px 0px rgba(199, 199, 199, 0.25);
    background-color: ${(props) =>
    props.$loading ? props.$loadingColor : props.$backgroundColor};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    pointer-events: ${(props) => (props.$blockPointer ? "none" : "auto")};
    transition: min-width 0.3s ease-in-out, max-width 0.3s ease-in-out,
        height 0.3s ease-in-out, border 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

    &:hover {
        background-color: ${(props) =>
    props.$loading ? props.$loadingColor : props.$hoverColor};
    }

    @media (max-width: 768px) {
        min-width: 264px;
        max-width: 264px;
    }

    @media (min-width: 1280px) {
        min-width: 297px;
        max-width: ${(props) => props.$maxWidthOnGrow};
    }
`;

const ContentContainer = styled.div.attrs<{
    $loading: boolean;
    $height?: string;
}>((props) => ({
    style: {
        opacity: props.$loading ? 0 : 1,
        height: props.$height !== undefined ? props.$height : "100%",
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
`;

const DashboardCard = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{
        status: ViewStatusType;
        height: string;
        isHeightContentHeight?: boolean;
        onClick?: () => void;
        loadingColor: string;
        backgroundColor: string;
        hoverColor: string;
        grow?: boolean;
        maxWidthOnGrow?: string;
        isButton?: boolean;
        upper?: ReactElement;
        lower?: ReactElement;
    }>
>(
    (
        {
            status,
            height,
            isHeightContentHeight = false,
            onClick,
            loadingColor,
            backgroundColor,
            hoverColor,
            grow = false,
            maxWidthOnGrow = "100%",
            isButton = false,
            upper,
            lower,
        },
        ref
    ) => {
        const {loading, error} = useViewStatus(status);

        const _onClick = () => {
            if (!isButton || loading || error) return;

            onClick?.();
        };

        return (
            <LayoutContainer
                ref={ref}
                onClick={_onClick}
                $loading={loading}
                $blockPointer={loading}
                $height={isHeightContentHeight ? undefined : height}
                $loadingColor={loadingColor}
                $backgroundColor={backgroundColor}
                $hoverColor={hoverColor}
                $grow={grow}
                $maxWidthOnGrow={maxWidthOnGrow}
                $isButton={isButton}
            >
                <ContentContainer
                    $loading={loading}
                    $height={isHeightContentHeight ? height : undefined}
                >
                    {upper}
                    {lower}
                </ContentContainer>
                <Loading visible={loading}/>
            </LayoutContainer>
        );
    }
);

export default DashboardCard;
