import TaxTimeline from "domain/model/tax/tax_timeline/tax_timeline";
import TableCellLayout from "presentation/components/table/table_cell_layout";
import TableRowLayout from "presentation/components/table/table_row_layout";
import {
    TaxTimelineTableContext
} from "presentation/pages/tax_timeline/components/tax_timeline_table/tax_timeline_table";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LabelLayoutContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LabelContainer = styled.p.attrs<{ $heightInPx: number }>((props) => ({
    style: {
        minHeight: `${props.$heightInPx}px`,
    },
}))`
    min-width: max-content;
    ${Fonts.detail2};
    text-align: center;
`;

const TaxTimelineTableRow = ({
                                 yearMonth,
                                 data,
                             }: {
    yearMonth: DateTime;
    data: TaxTimeline[];
}) => {
    const context = useContext(TaxTimelineTableContext);

    const _data = useMemo(() => {
        const labels = [
            {
                labels: [
                    yearMonth.format(S.taxTimelineTable.monthColumnDateFormat),
                ],
                minWidthInPx: context.constants.monthCellWidthInPx,
            },
            {
                labels: data.map((d) =>
                    d.declarationEndDate.format(S.taxTimelineTable.rowDueDateFormat)
                ),
                minWidthInPx: context.constants.dueCellMinWidthInPx,
            },
            {
                labels: data.map((d) => d.description),
                minWidthInPx: context.constants.descriptionCellMinWidthInPx,
            },
        ];

        const maxLabelCount = labels.reduce(
            (acc, cur) => Math.max(acc, cur.labels.length),
            0
        );

        return labels.map((d, index) => ({
            labels: d.labels,
            flex: index !== labels.length - 1 ? undefined : 1,
            minWidthInPx: d.minWidthInPx,
            heightInPx:
                maxLabelCount * context.constants.cellContentHeightInPx +
                context.constants.cellVerticalPaddingInPx * 2,
            leftMarginInPx:
                index === 0 ? 0 : context.constants.horizontalGapInPx,
            rightDivider: index === 1,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yearMonth.key, data, context.constants]);

    const selected = DateTime.now().isSameMonth(yearMonth);
    const backgroundColor = selected ? Palette.primary10 : Palette.none;
    const boxShadow = selected
        ? `0 0 0 2px ${Palette.primary500} inset`
        : undefined;

    return (
        <TableRowLayout
            borderRadiusInPx={context.constants.rowBorderRadiusInPx}
            roundRight={true}
            roundLeft={true}
            backgroundColor={backgroundColor}
            boxShadow={boxShadow}
        >
            {_data.map((d, cellIndex) => (
                <TableCellLayout
                    key={cellIndex}
                    flex={d.flex}
                    minWidthInPx={d.minWidthInPx}
                    maxWidthInPx={
                        d.flex !== undefined ? undefined : d.minWidthInPx
                    }
                    heightInPx={d.heightInPx}
                    leftMarginInPx={d.leftMarginInPx}
                    rightDivider={!selected && d.rightDivider}
                    roundLeft={false}
                    roundRight={false}
                    backgroundColor={Palette.none}
                >
                    <LabelLayoutContainer>
                        {d.labels.map((label, index) => (
                            <LabelContainer
                                key={index}
                                $heightInPx={
                                    context.constants.cellContentHeightInPx
                                }
                            >
                                {label}
                            </LabelContainer>
                        ))}
                    </LabelLayoutContainer>
                </TableCellLayout>
            ))}
        </TableRowLayout>
    );
};

export default TaxTimelineTableRow;
