import ReplayState from "presentation/states/base/replay_state";

export class TransactionsReplayState extends ReplayState {
}

export class TransactionsScrollToElement extends TransactionsReplayState {
    target: string;

    constructor({element}: { element: string }) {
        super();
        this.target = element;
    }
}
