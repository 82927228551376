import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";

enum TransactionsDateFilterType {
    ThisMonth = "thisMonth",
    LastMonth = "lastMonth",
    RecentThreeMonths = "recentThreeMonths",
}

export const TransactionsDateFilterTypeMap = {
    label: (type: TransactionsDateFilterType): string => {
        switch (type) {
            case TransactionsDateFilterType.ThisMonth:
                return S.transactionsDateFilterType.thisMonthLabel;
            case TransactionsDateFilterType.LastMonth:
                return S.transactionsDateFilterType.lastMonthLabel;
            case TransactionsDateFilterType.RecentThreeMonths:
                return S.transactionsDateFilterType.recentThreeMonthsLabel;
            default:
                throw new Error("Invalid TransactionsDateFilterType");
        }
    },
    datePeriod: (
        type: TransactionsDateFilterType
    ): { startDate: DateTime; endDate: DateTime } => {
        const now = DateTime.now().min;
        switch (type) {
            case TransactionsDateFilterType.ThisMonth:
                return {
                    startDate: now.getFirstDayInMonth(),
                    endDate: now,
                };
            case TransactionsDateFilterType.LastMonth:
                return {
                    startDate: now
                        .copyWith({
                            month: now.month - 1,
                        })
                        .getFirstDayInMonth(),
                    endDate: now
                        .copyWith({
                            month: now.month - 1,
                        })
                        .getLastDayInMonth(),
                };
            case TransactionsDateFilterType.RecentThreeMonths:
                const threeMonthsBefore = now.copyWith({
                    month: now.month - 3,
                    day: 1,
                });
                const lastDayInMonthThreeMonthsBefore =
                    threeMonthsBefore.getLastDayInMonth();

                const startDate =
                    now.day > lastDayInMonthThreeMonthsBefore.day
                        ? lastDayInMonthThreeMonthsBefore.copyWith({
                            day: lastDayInMonthThreeMonthsBefore.day + 1,
                        })
                        : threeMonthsBefore.copyWith({
                            day: now.day + 1,
                        });

                return {
                    startDate,
                    endDate: now,
                };
            default:
                throw new Error("Invalid TransactionsDateFilterType");
        }
    },
};

export default TransactionsDateFilterType;
