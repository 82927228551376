import UserRepository from "data/repository/user_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import User from "domain/model/user/user";
import Page from "presentation/components/common/page";
import SizedBox from "presentation/components/common/sized_box";
import ContentBox from "presentation/components/content/content_box";
import ContentLayout from "presentation/components/content/content_layout";
import {TextField} from "presentation/components/text_field/text_field";
import ProfileTitledSection from "presentation/pages/profile/components/profile_titled_section";
import ProfileContentTitleView from "presentation/pages/profile/view/profile_content_title_view";
import S from "presentation/theme/s";
import businessRegistrationNumberFormatter from "presentation/utils/formatter/business_registration_number_formatter";
import phoneNumberFormatter from "presentation/utils/formatter/phone_number_formatter";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {optional} from "presentation/utils/types/optional";
import {useRef, useState} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 36px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const ProfilePage = () => {
    const repository = useRepository(UserRepository);

    const layoutRef = useRef<HTMLDivElement>(null);
    const [status, setStatus] = useState(ViewStatusType.Loading);
    const [user, setUser] = useState<optional<User>>(undefined);

    const {height} = useResizeObserver(layoutRef, []);

    useLifeCycle({
        onInit: () =>
            repository({
                handler: async (repository) => {
                    const userInfo = await repository.getUser();

                    setUser(userInfo);

                    setStatus(ViewStatusType.Loaded);
                },
            }),
    });

    return (
        <Page status={status}>
            <LayoutContainer>
                <ContentBox maxWidth={"1018px"} contentHeight={height}>
                    <ContentLayout ref={layoutRef}>
                        <ProfileContentTitleView/>
                        <SizedBox height={"36px"}/>
                        <ProfileTitledSection
                            title={S.profilePage.pharmacyNameTitle}
                        >
                            <TextField
                                enabled={false}
                                value={user?.pharmacyName ?? ""}
                            />
                        </ProfileTitledSection>
                        <SizedBox height={"36px"}/>
                        <ProfileTitledSection
                            title={S.profilePage.pharmacistNameTitle}
                        >
                            <TextField
                                enabled={false}
                                value={user?.name ?? ""}
                            />
                        </ProfileTitledSection>
                        <SizedBox height={"36px"}/>
                        <ProfileTitledSection
                            title={S.profilePage.pharmacyAddressTitle}
                        >
                            <TextField
                                enabled={false}
                                value={user?.address ?? ""}
                            />
                            <TextField
                                enabled={false}
                                value={user?.addressDetails ?? ""}
                            />
                        </ProfileTitledSection>
                        <SizedBox height={"36px"}/>
                        <RowContainer>
                            <ProfileTitledSection
                                title={S.profilePage.phoneNumberTitle}
                            >
                                <TextField
                                    enabled={false}
                                    value={phoneNumberFormatter(
                                        user?.phoneNumber
                                    )}
                                />
                            </ProfileTitledSection>
                            <ProfileTitledSection
                                title={
                                    S.profilePage
                                        .businessRegistrationNumberTitle
                                }
                            >
                                <TextField
                                    enabled={false}
                                    value={businessRegistrationNumberFormatter(
                                        user?.businessRegistrationNumber
                                    )}
                                />
                            </ProfileTitledSection>
                        </RowContainer>
                        <SizedBox height={"36px"}/>
                        <RowContainer>
                            <ProfileTitledSection
                                title={S.profilePage.nursingAgencyNumberTitle}
                            >
                                <TextField
                                    enabled={false}
                                    value={user?.nursingAgencyNumber ?? ""}
                                />
                            </ProfileTitledSection>
                            <ProfileTitledSection
                                title={S.profilePage.licenseNumberTitle}
                            >
                                <TextField
                                    enabled={false}
                                    value={user?.licenseNumber ?? ""}
                                />
                            </ProfileTitledSection>
                        </RowContainer>
                    </ContentLayout>
                </ContentBox>
            </LayoutContainer>
        </Page>
    );
};

export default ProfilePage;
