import {animated, useSpring} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import ChartTooltipLabel from "presentation/components/charts/common/chart_tooltip_label";
import DonutChartData from "presentation/components/charts/donut_chart/donut_chart_model";
import StatisticNumberSmall from "presentation/components/statistic/statistic_number_small";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {MouseEvent, useEffect, useMemo, useRef} from "react";
import styled from "styled-components";

const donutThicknessInPx = 40;

const LayoutContainer = styled(animated.div)<{
    $widthInPx: number;
    $heightInPx: number;
}>`
    width: ${(props) => `${props.$widthInPx}px`};
    height: ${(props) => `${props.$heightInPx}px`};
    position: relative;
    overflow: visible;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const TitleContainer = styled.p`
    ${Fonts.detail2Medium};
    color: ${Palette.gray600};
`;

const TooltipContainer = styled(animated.ul)`
    width: max-content;
    padding: 8px;
    border-radius: 12px;
    background-color: ${Palette.black75};
    box-shadow: 0 0 0 1px ${Palette.white20} inset;
    position: absolute;
`;

const DonutChart = ({
                        className,
                        donutSizeInPx,
                        data,
                        title,
                        value,
                        hoverEnabled = true,
                    }: {
    className?: string;
    donutSizeInPx: number;
    data: DonutChartData[];
    title?: string;
    value?: number;
    hoverEnabled?: boolean;
}) => {
    const layoutRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const tooltipRef = useRef<HTMLUListElement>(null);

    const dataEmpty = !data.length || data.every((d) => d.value === 0);

    const _data = useMemo(
        () => {
            const result: {
                percentageLabel: string;
                startDegree: number;
                endDegree: number;
                color: string;
            }[] = [];
            const total = data.reduce((acc, cur) => acc + cur.value, 0);

            let accumulatedPercentage = 0;
            data.forEach((d, i) => {
                const percentage =
                    i !== data.length - 1
                        ? d.value / total
                        : 1 - accumulatedPercentage;
                accumulatedPercentage += percentage;

                result.push({
                    percentageLabel: S.donutChart.percentageLabel(
                        d.label,
                        Math.round(percentage * 100).toString()
                    ),
                    startDegree: (accumulatedPercentage - percentage) * 360,
                    endDegree: accumulatedPercentage * 360,
                    color: d.color,
                });
            });

            return result;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const [canvasProps, canvasAPI] = useSpring(() => ({
        opacity: 0,
    }));

    const [tooltipProps, tooltipAPI] = useSpring(() => ({
        top: 0,
        left: 0,
        opacity: 0,
    }));

    useEffect(() => {
        const canvas = canvasRef.current;
        const c = canvasRef.current?.getContext("2d");
        if (!c) return;

        const drawSlice = ({
                               startDegree,
                               endDegree,
                               color,
                           }: {
            startDegree: number;
            endDegree: number;
            color: string;
        }) => {
            const adjustedStartDegree = startDegree - 90;
            const adjustedEndDegree = endDegree - 90;
            c.fillStyle = color;
            c.beginPath();
            c.moveTo(donutSizeInPx / 2, donutSizeInPx / 2);
            c.arc(
                donutSizeInPx / 2,
                donutSizeInPx / 2,
                donutSizeInPx / 2,
                NumberHelper.degreeToRadian(adjustedStartDegree),
                NumberHelper.degreeToRadian(adjustedEndDegree)
            );
            c.closePath();
            c.fill();
        };

        const drawInnerCircle = () => {
            c.fillStyle = Palette.white100;
            c.beginPath();
            c.moveTo(donutSizeInPx / 2, donutSizeInPx / 2);
            c.arc(
                donutSizeInPx / 2,
                donutSizeInPx / 2,
                donutSizeInPx / 2 - donutThicknessInPx,
                0,
                2 * Math.PI
            );
            c.closePath();
            c.fill();
        };

        drawSlice({
            startDegree: 0,
            endDegree: 360,
            color: Palette.gray100,
        });
        _data.forEach((d) =>
            drawSlice({
                startDegree: d.startDegree,
                endDegree: d.endDegree,
                color: d.color,
            })
        );
        drawInnerCircle();

        canvasAPI.start({
            opacity: 1,
        });

        return () => {
            c.clearRect(
                0,
                0,
                canvas?.width ?? donutSizeInPx,
                canvas?.height ?? donutSizeInPx
            );
            canvasAPI.set({
                opacity: 0,
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasRef, _data, donutSizeInPx]);

    const onMouseEnter = () => {
        if (!hoverEnabled || dataEmpty) return;

        tooltipAPI.start({
            opacity: 1,
        });
    };
    const onMouseLeave = () => {
        if (!hoverEnabled || dataEmpty) return;

        tooltipAPI.start({
            opacity: 0,
        });
    };
    const onMouseMove = (e: MouseEvent) => {
        if (!hoverEnabled) return;

        const layout = layoutRef.current;
        const tooltip = tooltipRef.current;
        if (!layout || !tooltip) return;

        const rect = layout.getBoundingClientRect();

        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;
        const width = tooltip.offsetWidth;
        const height = tooltip.offsetHeight;

        if (x < 0 || y < 0 || x > donutSizeInPx || y > donutSizeInPx) return;

        const overflowX = width - donutSizeInPx + x;
        const overflowY = height - donutSizeInPx + y;

        if (overflowX > 0) x -= overflowX;
        if (overflowY > 0) y -= overflowY;

        tooltipAPI.start({
            top: y,
            left: x,
        });
    };

    const _value = dataEmpty ? undefined : value;

    return (
        <LayoutContainer
            className={className}
            ref={layoutRef}
            style={canvasProps}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
            $widthInPx={donutSizeInPx}
            $heightInPx={donutSizeInPx}
        >
            <canvas
                ref={canvasRef}
                width={donutSizeInPx}
                height={donutSizeInPx}
            />
            {title !== undefined && (
                <ColumnContainer>
                    <TitleContainer>{title}</TitleContainer>
                    <StatisticNumberSmall
                        autoScaleUp={false}
                        value={_value}
                        unit={S.donutChart.wonUnit}
                        color={Palette.gray800}
                        unitColor={Palette.gray600}
                    />
                </ColumnContainer>
            )}
            <TooltipContainer ref={tooltipRef} style={tooltipProps}>
                {_data.map((d) => (
                    <ChartTooltipLabel
                        key={d.percentageLabel}
                        color={d.color}
                        label={d.percentageLabel}
                    />
                ))}
            </TooltipContainer>
        </LayoutContainer>
    );
};

export default DonutChart;
