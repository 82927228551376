import TransactionsFiguresType from "domain/model/tax/transactions/transactions_figures_type";
import TransactionsType, {
    TransactionsTypeMap,
} from "domain/model/tax/transactions/transactions_type";
import {
    TransactionsCalendarConstants
} from "presentation/components/calendar/transactions_calendar/transactions_calendar";
import TabBar from "presentation/components/tab_bar/tab_bar";
import TabBarItem from "presentation/components/tab_bar/tab_bar_item";
import TransactionsView from "presentation/pages/transactions/view/transactions_view";
import AppRoutes from "presentation/routes/model/app_routes";
import {TransactionsScrollToElement} from "presentation/states/transactions/transactions_replay_state";
import {transactionsReplayState} from "presentation/states/transactions/transactions_state";
import DateTime from "presentation/utils/extension/date_extension";
import {changePathWithoutRedirect} from "presentation/utils/functions/change_path_without_redirect";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import {useSearchParams} from "presentation/utils/hooks/use_search_params";
import {useSetReplayState} from "presentation/utils/hooks/use_set_replay_state";
import {optional} from "presentation/utils/types/optional";
import {
    Dispatch,
    SetStateAction,
    createContext,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import {useLocation} from "react-router-dom";

const calendarConstants = {
    marginInPx: 24,
    monthHeaderHeightInPx: 36,
    monthHeaderToFiguresHeaderGapInPx: 24,
    figuresHeaderHeightInPx: 34,
    figuresHeaderToCalendarGapInPx: 24,
    weekdayHeaderHeightInPx: 20,
    calendarRowVerticalGapInPx: 5,
    calendarCellWidthInPx: 68,
    calendarCellHeightInPx: 68,
    calendarCellValueWidthInPx: 40,
    calendarCellValueHeightInPx: 40,
};

export const TransactionsPageContext = createContext<{
    calendarConstants: TransactionsCalendarConstants;
    pageMinWidth: string;
    transactionsType?: TransactionsType;
}>({
    calendarConstants,
    pageMinWidth: "100%",
    transactionsType: undefined,
});

export const TransactionsCalendarListViewContext = createContext<{
    transactionsFiguresType?: TransactionsFiguresType;
    contentWidth: string;
    contentHeight: string;
    calendarYearMonth: DateTime;
    setTransactionsFiguresType: Dispatch<
        SetStateAction<optional<TransactionsFiguresType>>
    >;
    setCalendarYearMonth: Dispatch<SetStateAction<DateTime>>;
}>({
    transactionsFiguresType: undefined,
    contentWidth: "auto",
    contentHeight: "auto",
    calendarYearMonth: DateTime.now(),
    setTransactionsFiguresType: () => {
    },
    setCalendarYearMonth: () => {
    },
});

const TransactionsPage = () => {
    const {pathname, search} = useLocation();
    const searchParams = useSearchParams();
    const isMobile = useMobileQuery();

    const setReplayState = useSetReplayState(transactionsReplayState);

    const [selectedType, setSelectedType] =
        useState<optional<TransactionsType>>(undefined);
    const types = useMemo(() => Object.values(TransactionsType), []);

    const onClick = (type: TransactionsType) => () => {
        setSelectedType(type);
        changePathWithoutRedirect(AppRoutes.Transactions + "?type=" + type);
    };

    useLayoutEffect(() => {
        const searchSelectedType = searchParams("type");
        const type =
            types.find((t) => t === searchSelectedType) ??
            TransactionsType.Sales;
        setSelectedType(type);

        const element = searchParams("scrollTo");
        if (element) {
            setReplayState(new TransactionsScrollToElement({element}));
        }

        const path = `${AppRoutes.Transactions}?type=${type}`;
        changePathWithoutRedirect(path, {
            replace: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, search]);

    const view = useMemo(() => {
        if (!selectedType) return null;

        return <TransactionsView key={selectedType}/>;
    }, [selectedType]);

    const label = (type: TransactionsType) => TransactionsTypeMap.label(type);

    const pageMinWidth = isMobile ? "100%" : "744px";

    return (
        <TransactionsPageContext.Provider
            value={{
                calendarConstants,
                pageMinWidth,
                transactionsType: selectedType,
            }}
        >
            <TabBar>
                {types.map((type) => (
                    <TabBarItem
                        key={type}
                        selected={type === selectedType}
                        label={label(type)}
                        onClick={onClick(type)}
                    />
                ))}
            </TabBar>
            {view}
        </TransactionsPageContext.Provider>
    );
};

export default TransactionsPage;
