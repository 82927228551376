import BaseRepository from "data/repository/base_repository";
import CardSalesFigures from "domain/model/tax/card_sales/card_sales_figures";
import DateTime, {DateDifferenceUnitType,} from "presentation/utils/extension/date_extension";

export default class CardSalesRepository extends BaseRepository {
    getDailyFiguresInRange = async ({
                                        startDate,
                                        endDate,
                                    }: {
        startDate: DateTime;
        endDate: DateTime;
    }): Promise<CardSalesFigures[]> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                if (Math.random() < 0.1) return [];

                const _endDate = DateTime.min(DateTime.minNow(), endDate);
                const difference =
                    _endDate.subtract(startDate, DateDifferenceUnitType.Day) +
                    1;

                return Array.from(
                    {
                        length: difference,
                    },
                    (_, index) => {
                        const date = _endDate.copyWith({
                            day: _endDate.day - index,
                        });
                        const total = Math.floor(Math.random() * 100_000_000);
                        const processedAmount = Math.floor(
                            Math.random() * total
                        );
                        const processedCount = processedAmount
                            ? Math.floor(Math.random() * 500)
                            : 0;
                        const cancelledAmount =
                            Math.random() < 0.5
                                ? Math.floor(
                                    Math.random() *
                                    Math.abs(total - processedAmount)
                                )
                                : 0;
                        const cancelledCount = cancelledAmount
                            ? Math.floor(Math.random() * 100)
                            : 0;
                        const cancelledDetails = Array.from(
                            {
                                length: cancelledCount,
                            },
                            () => ({
                                label:
                                    Math.random() < 0.5
                                        ? "하나카드"
                                        : "비씨카드",
                                date: date.copyWith({
                                    hour: Math.floor(Math.random() * 24),
                                    minute: Math.floor(Math.random() * 60),
                                    second: Math.floor(Math.random() * 60),
                                }),
                                amount: Math.floor(
                                    Math.random() * cancelledAmount
                                ),
                            })
                        ).toSorted(
                            (a, b) =>
                                a.date.millisecondsSinceEpoch -
                                b.date.millisecondsSinceEpoch
                        );
                        const pendingAmount =
                            Math.random() < 0.5
                                ? Math.floor(
                                    Math.random() *
                                    Math.abs(
                                        total -
                                        processedAmount -
                                        cancelledAmount
                                    )
                                )
                                : 0;
                        const pendingCount = pendingAmount
                            ? Math.floor(Math.random() * 10)
                            : 0;
                        const pendingDetails = Array.from(
                            {
                                length: pendingCount,
                            },
                            () => ({
                                label:
                                    Math.random() < 0.5
                                        ? "하나카드"
                                        : "비씨카드",
                                date: date.copyWith({
                                    hour: Math.floor(Math.random() * 24),
                                    minute: Math.floor(Math.random() * 60),
                                    second: Math.floor(Math.random() * 60),
                                }),
                                amount: Math.floor(
                                    Math.random() *
                                    (pendingAmount / pendingCount)
                                ),
                            })
                        ).toSorted(
                            (a, b) =>
                                a.date.millisecondsSinceEpoch -
                                b.date.millisecondsSinceEpoch
                        );

                        const receiveAmount = Math.floor(
                            Math.random() * 5_000_000
                        );
                        const purchasesAmount = Math.floor(
                            Math.random() * receiveAmount
                        );
                        const purchasesCount = Math.floor(Math.random() * 50);
                        const transactionsFee = receiveAmount * 0.11;

                        return {
                            date,
                            total,
                            processedAmount,
                            processedCount,
                            cancelledAmount,
                            cancelledCount,
                            cancelledDetails,
                            pendingAmount,
                            pendingCount,
                            pendingDetails,
                            receiveAmount,
                            purchasesAmount,
                            purchasesCount,
                            transactionsFee,
                        };
                    }
                ).toSorted(
                    (a, b) =>
                        a.date.millisecondsSinceEpoch -
                        b.date.millisecondsSinceEpoch
                );
            },
        });
}
