import TaxTimeline from "domain/model/tax/tax_timeline/tax_timeline";
import DateTime from "presentation/utils/extension/date_extension";
import {atom} from "recoil";

type TaxTimelineState = {
    startDateKey: string;
    selectedYearKey: string;
    data: TaxTimeline[];
};

const taxTimelineState = atom<TaxTimelineState>({
    key: "taxTimelineState",
    default: {
        startDateKey: DateTime.minNow()
            .copyWith({
                year: DateTime.minNow().year - 4,
            })
            .getFirstDayInYear().key,
        selectedYearKey: DateTime.minNow().getFirstDayInYear().key,
        data: [],
    },
});

export default taxTimelineState;
