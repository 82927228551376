import {
    DateFilterPeriodCalendarContext
} from "presentation/components/button/date_filter_period_button/components/date_filter_period_calendar";
import {
    DateFilterPeriodCalendarMonthCallerType
} from "presentation/components/button/date_filter_period_button/components/date_filter_period_calendar_month";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div<{
    $widthInPx: number;
    $heightInPx: number;
    $monthGapInPx: number;
}>`
    width: ${(props) => `${props.$widthInPx}px`};
    height: ${(props) => `${props.$heightInPx}px`};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: ${(props) => `${props.$monthGapInPx}px`};
    background-color: ${Palette.white100};
`;

const RowContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: width 0.3s ease-in-out;
`;

const IconContainer = styled.div.attrs<{ $enabled: boolean }>((props) => ({
    style: {
        cursor: props.$enabled ? "pointer" : "not-allowed",
    },
}))`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelContainer = styled.p`
    ${Fonts.detail3};
    color: ${Palette.black100};
    text-align: center;
    flex-grow: 1;
`;

const DateFilterPeriodCalendarMonthHeader = ({
                                                 collapsed,
                                             }: {
    collapsed: boolean;
}) => {
    const context = useContext(DateFilterPeriodCalendarContext);

    const {
        leftMonthLabel,
        rightMonthLabel,
        hasPreviousMonth,
        hasNextMonth,
        leftArrowColor,
        rightArrowColor,
    } = useMemo(
        () => {
            const leftMonthLabel = context.month
                .copyWith({
                    month: context.month.month - 1,
                })
                .format(S.dateFilterPeriodButton.monthHeaderDateFormat);
            const rightMonthLabel = context.month.format(
                S.dateFilterPeriodButton.monthHeaderDateFormat
            );

            const enabledStartMonth =
                context.enabledStartDate.getFirstDayInMonth();
            const enabledEndMonth = context.enabledEndDate.getFirstDayInMonth();

            const hasPreviousMonth = context.month.isAfter(enabledStartMonth);
            const hasNextMonth = context.month.isBefore(enabledEndMonth);

            const leftArrowColor = hasPreviousMonth
                ? Palette.gray800
                : Palette.gray300;
            const rightArrowColor = hasNextMonth
                ? Palette.gray800
                : Palette.gray300;

            return {
                leftMonthLabel,
                rightMonthLabel,
                hasPreviousMonth,
                hasNextMonth,
                leftArrowColor,
                rightArrowColor,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            context.month.key,
            context.enabledStartDate.key,
            context.enabledEndDate.key,
        ]
    );

    const onLeftArrowClick = () => {
        if (!hasPreviousMonth) return;
        context.onMonthChange(
            DateFilterPeriodCalendarMonthCallerType.Previous,
            context.month.copyWith({month: context.month.month - 1})
        );
    };
    const onRightArrowClick = () => {
        if (!hasNextMonth) return;
        context.onMonthChange(
            DateFilterPeriodCalendarMonthCallerType.Next,
            context.month.copyWith({month: context.month.month + 1})
        );
    };

    return (
        <LayoutContainer
            $widthInPx={context.constants.innerWidthInPx}
            $heightInPx={context.constants.monthHeaderHeightInPx}
            $monthGapInPx={context.constants.monthGapInPx}
        >
            {!collapsed && (
                <RowContainer>
                    <IconContainer
                        onClick={onLeftArrowClick}
                        $enabled={hasPreviousMonth}
                    >
                        <SVG asset={SVGAssets.Back} color={leftArrowColor}/>
                    </IconContainer>
                    <LabelContainer>{leftMonthLabel}</LabelContainer>
                </RowContainer>
            )}
            <RowContainer>
                {collapsed && (
                    <IconContainer
                        onClick={onLeftArrowClick}
                        $enabled={hasPreviousMonth}
                    >
                        <SVG asset={SVGAssets.Back} color={leftArrowColor}/>
                    </IconContainer>
                )}
                <LabelContainer>{rightMonthLabel}</LabelContainer>
                <IconContainer
                    onClick={onRightArrowClick}
                    $enabled={hasNextMonth}
                >
                    <SVG asset={SVGAssets.Next} color={rightArrowColor}/>
                </IconContainer>
            </RowContainer>
        </LayoutContainer>
    );
};

export default DateFilterPeriodCalendarMonthHeader;
