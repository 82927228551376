import TransactionsDailyFigures from "domain/model/tax/transactions/transactions_daily_figures";
import TransactionsFiguresType from "domain/model/tax/transactions/transactions_figures_type";
import TransactionsListTileGroup
    from "presentation/components/list/transactions_list/components/transactions_list_tile_group";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {forwardRef, useMemo} from "react";
import styled from "styled-components";

const LayoutContainer = styled.ul`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    position: relative;
    scrollbar-color: ${Palette.none} ${Palette.none};
    transition: scrollbar-color 0.3s ease-in-out;

    &:hover {
        scrollbar-color: ${Palette.gray300} ${Palette.none};
    }
`;

const EmptyContainer = styled.p.attrs<{ $visible: boolean }>((props) => ({
    style: {
        opacity: props.$visible ? 1 : 0,
    },
}))`
    min-width: max-content;
    border-radius: 16px;
    background-color: ${Palette.white100};
    ${Fonts.detail3Medium};
    text-align: center;
    color: ${Palette.gray500};
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    transition: opacity 0.3s ease-in-out;
`;

const TransactionsList = forwardRef<
    HTMLUListElement,
    {
        data: TransactionsDailyFigures[];
        onTileClick: (date: DateTime, type: TransactionsFiguresType) => void;
    }
>(({data, onTileClick}, ref) => {
    const _data = useMemo(() => {
        const isBeforeTwelve = DateTime.now().hour < 12;
        const now = DateTime.now().min;
        const yesterday = now.copyWith({day: now.day - 1});
        const isCurrentMonth = data.at(0)?.date.isSameMonth(DateTime.now());

        const mapped = data.map((d) => ({
            date: d.date,
            figures: d.figures,
            collecting: false,
        }));

        const hasYesterday = mapped.some((d) => d.date.isSameDay(yesterday));

        if (isCurrentMonth && isBeforeTwelve && !hasYesterday) {
            mapped.push({
                date: yesterday,
                figures: [],
                collecting: true,
            });
        }

        return mapped.toSorted(
            (a, b) =>
                b.date.millisecondsSinceEpoch - a.date.millisecondsSinceEpoch
        );
    }, [data]);

    return (
        <LayoutContainer ref={ref}>
            {_data.map((d, index) => (
                <TransactionsListTileGroup
                    key={d.date.key}
                    topMarginInPx={index === 0 ? 0 : 32}
                    date={d.date}
                    figures={d.figures}
                    collecting={d.collecting}
                    onTileClick={onTileClick}
                />
            ))}
            <EmptyContainer $visible={!data.length}>
                {S.transactionsList.emptyLabel}
            </EmptyContainer>
        </LayoutContainer>
    );
});

export default TransactionsList;
