import SizedBox from "presentation/components/common/sized_box";
import {
    LNBItemType,
    LNBItemTypeMap,
    LNBSectionType,
    LNBSectionTypeMap,
} from "presentation/components/navigation/lnb/lnb_model";
import LNBDivider from "presentation/components/navigation/lnb/lnb_divider";
import LNBItem from "presentation/components/navigation/lnb/lnb_item";
import LNBLogo from "presentation/components/navigation/lnb/lnb_logo";
import LNBSection from "presentation/components/navigation/lnb/lnb_section";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import Palette from "presentation/theme/palette";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {Fragment, useMemo} from "react";
import styled from "styled-components";

export const LNBContainer = styled.div<{
    $lnbMinWidth: string;
    $lnbMaxWidth: string;
}>`
    height: 100%;
    width: ${(props) => props.$lnbMaxWidth};
    min-width: ${(props) => props.$lnbMaxWidth};
    background-color: ${Palette.gray100};
    transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out;

    @media (max-width: 768px) {
        width: ${(props) => props.$lnbMinWidth};
        min-width: ${(props) => props.$lnbMinWidth};
    }
`;

const LayoutContainer = styled(LNBContainer)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 24px 16px;
    background-color: ${Palette.white100};
    border-right: 1px solid ${Palette.gray100};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 5;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const LNB = ({selectedItem}: { selectedItem?: LNBItemType }) => {
    const theme = useThemeContext();

    const sections = useMemo(() => Object.values(LNBSectionType), []);
    const items = useMemo(() => Object.values(LNBItemType), []);
    const groupedItems = useMemo(() => {
        const group: Record<string, LNBItemType[]> = {};
        sections.forEach((section) => {
            group[section] = items.filter(
                (item) => LNBItemTypeMap.sectionType(item) === section
            );
        });
        return group;
    }, [items, sections]);

    const onLogoClick = () =>
        AppRouter.navigate(AppRoutes.Dashboard, {
            replace: true,
        });
    const onClick = (item: LNBItemType) => () => {
        const route = LNBItemTypeMap.route(item);
        const onClick = LNBItemTypeMap.onClick(item);

        if (!!route) {
            AppRouter.navigate(route, {
                replace: true,
            });
            return;
        }

        onClick?.();
    }


    return (
        <LayoutContainer
            $lnbMinWidth={theme.componentTheme.lnbMinWidth}
            $lnbMaxWidth={theme.componentTheme.lnbMaxWidth}
        >
            <LNBLogo onClick={onLogoClick}/>
            <SizedBox height={"32px"}/>
            <LNBItem
                selected={selectedItem === LNBItemType.Dashboard}
                icon={LNBItemTypeMap.icon(LNBItemType.Dashboard)}
                label={LNBItemTypeMap.label(LNBItemType.Dashboard)}
                onClick={onClick(LNBItemType.Dashboard)}
            />
            <SizedBox height={"16px"}/>
            {sections.map((section, index) => {
                const isNotFirst = index !== 0;
                const title = LNBSectionTypeMap.label(section);
                const items = groupedItems[section] ?? [];

                return (
                    <Fragment key={section}>
                        {isNotFirst && <LNBDivider/>}
                        <LNBSection title={title}>
                            {items.map((item) => {
                                const icon = LNBItemTypeMap.icon(item);
                                const label = LNBItemTypeMap.label(item);

                                return (
                                    <LNBItem
                                        key={item}
                                        selected={selectedItem === item}
                                        icon={icon}
                                        label={label}
                                        onClick={onClick(item)}
                                    />
                                );
                            })}
                        </LNBSection>
                    </Fragment>
                );
            })}
        </LayoutContainer>
    );
};

export default LNB;
