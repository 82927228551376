import ProfitReportYearlyTableHeaderType, {
    ProfitReportYearlyTableHeaderTypeMap,
} from "domain/model/tax/profit_report/profit_report_yearly_table_header_type";
import ProfitReportYearlyTable
    from "presentation/pages/profit_report/view/profit/yearly_table/components/profit_report_yearly_table/profit_report_yearly_table";
import profitReportProfitYearlyTableSelector
    from "presentation/states/profit_report/selector/profit/yearly_table/profit_report_yearly_table_selector";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";

const ProfitReportProfitYearlyDataTableView = () => {
    const {data} = useRecoilValue(profitReportProfitYearlyTableSelector);

    const headerData = useMemo(
        () =>
            Object.values(ProfitReportYearlyTableHeaderType).map((type) => ({
                heightInPx:
                    ProfitReportYearlyTableHeaderTypeMap.heightInPx(type),
                topMarginInPx:
                    ProfitReportYearlyTableHeaderTypeMap.topMarginInPx(type),
                bottomMarginInPx:
                    ProfitReportYearlyTableHeaderTypeMap.bottomMarginInPx(type),
                topRounded:
                    ProfitReportYearlyTableHeaderTypeMap.topRounded(type),
                bottomRounded:
                    ProfitReportYearlyTableHeaderTypeMap.bottomRounded(type),
                label: ProfitReportYearlyTableHeaderTypeMap.label(type),
                color: ProfitReportYearlyTableHeaderTypeMap.color(type),
                dataBold: ProfitReportYearlyTableHeaderTypeMap.dataBold(type),
            })),
        []
    );

    const _data = useMemo(() => {
        return data.map((d) => ({
            yearMonth: d.date,
            values: [
                ...d.sales.slice(1),
                d.purchases[0],
                d.sales[0] - d.purchases[0],
                ...d.otherExpenses.slice(1),
                d.profit,
            ].map((v) => (!v ? undefined : v)),
        }));
    }, [data]);

    return <ProfitReportYearlyTable headerData={headerData} data={_data}/>;
};

export default ProfitReportProfitYearlyDataTableView;
