import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const ButtonContainer = styled.button`
    min-width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    border: none;
    background-color: ${Palette.none};
    cursor: pointer;
`;

const LabelContainer = styled.p`
    ${Fonts.detail2Medium};
`;

const CheckToggleButton = ({
                               selected,
                               label,
                               onClick,
                           }: {
    selected: boolean;
    label: string;
    onClick: () => void;
}) => {
    return (
        <ButtonContainer onClick={onClick}>
            <SVG
                asset={SVGAssets.CheckCircle}
                width={"24px"}
                height={"24px"}
                color={selected ? Palette.primary500 : Palette.gray200}
            />
            <LabelContainer>{label}</LabelContainer>
        </ButtonContainer>
    );
};

export default CheckToggleButton;
