import {animated} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import SizedBox from "presentation/components/common/sized_box";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useAnimatedMount from "presentation/utils/hooks/use_animated_mount";
import styled from "styled-components";

const LayoutContainer = styled.div`
    min-width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ValueContainer = styled(animated.p)`
    ${Fonts.body1};
`;

const UnitContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.gray600};
`;

const TransactionsListTileNumber = ({value}: { value: number }) => {
    const label = NumberHelper.toStringWithDividers(value);
    const {props} = useAnimatedMount(label);

    return (
        <LayoutContainer>
            <ValueContainer style={props}>{label}</ValueContainer>
            <SizedBox width={"4px"}/>
            <UnitContainer>{S.transactionsList.wonUnit}</UnitContainer>
        </LayoutContainer>
    );
};

export default TransactionsListTileNumber;
