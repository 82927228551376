import IconButton, {IconButtonHoverColorType,} from "presentation/components/button/icon_button";
import SizedBox from "presentation/components/common/sized_box";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {forwardRef, memo, useLayoutEffect, useMemo, useRef, useState,} from "react";
import styled from "styled-components";

type Alignment = "right" | "center";

const TooltipContainer = styled.div.attrs<{ $width: string }>((props) => ({
    style: {
        width: props.$width,
    },
}))`
    padding: 8px;
    background-color: ${Palette.white100};
    border-radius: 8px;
    box-shadow: 0px 3px 15px 0px rgba(199, 199, 199, 0.25);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    transform: scale(1);
    transform-origin: top right;
    transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;

    @media (max-width: 500px) {
        transform: scale(0.8);
    }

    @media only screen and (min-width: 501px) and (max-width: 768px) {
        transform: scale(0.9);
    }
`;

const TooltipTitleLayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const TooltipTitleContainer = styled.h6`
    ${Fonts.detail1};
    flex-grow: 1;
`;

const TooltipContentLayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 16px;
`;

const TooltipContentTitleContainer = styled.b`
    color: ${Palette.gray800};
`;

const TooltipContentContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.gray500};
`;

const Tooltip = memo(
    forwardRef<
        HTMLDivElement,
        { width: string; onCloseButtonClick: () => void }
    >(({width, onCloseButtonClick}, ref) => {
        const data = useMemo(() => {
            return [
                {
                    title: S.profitReportStatusBoardOtherExpensesInfoButton
                        .contentOneTitle,
                    content:
                    S.profitReportStatusBoardOtherExpensesInfoButton
                        .contentOne,
                },
                {
                    title: S.profitReportStatusBoardOtherExpensesInfoButton
                        .contentTwoTitle,
                    content:
                    S.profitReportStatusBoardOtherExpensesInfoButton
                        .contentTwo,
                },
                {
                    title: S.profitReportStatusBoardOtherExpensesInfoButton
                        .contentThreeTitle,
                    content:
                    S.profitReportStatusBoardOtherExpensesInfoButton
                        .contentThree,
                },
            ];
        }, []);

        return (
            <TooltipContainer ref={ref} $width={width}>
                <TooltipTitleLayoutContainer>
                    <TooltipTitleContainer>
                        {S.profitReportStatusBoardOtherExpensesInfoButton.title}
                    </TooltipTitleContainer>
                    <IconButton
                        onClick={onCloseButtonClick}
                        asset={SVGAssets.Close}
                        width={"20px"}
                        height={"20px"}
                        color={Palette.gray800}
                        hoverColor={IconButtonHoverColorType.Light}
                    />
                </TooltipTitleLayoutContainer>
                <SizedBox height={"4px"}/>
                <TooltipContentLayoutContainer>
                    {data.map((d) => (
                        <TooltipContentContainer key={d.title}>
                            <TooltipContentTitleContainer>
                                {d.title}
                            </TooltipContentTitleContainer>
                            {d.content}
                        </TooltipContentContainer>
                    ))}
                </TooltipContentLayoutContainer>
            </TooltipContainer>
        );
    })
);

const LayoutContainer = styled.div`
    position: relative;
`;

const PositionContainer = styled.div.attrs<{
    $visible: boolean;
    $alignment: Alignment;
}>((props) => ({
    style: {
        opacity: props.$visible ? 1 : 0,
        pointerEvents: props.$visible ? "auto" : "none",
        transform:
            props.$alignment === "right" ? "translateX(0)" : "translateX(50%)",
    },
}))`
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    z-index: 50;
    transition: opacity 0.3s ease-in-out, left 0.3s ease-in-out,
        transform 0.3s ease-in-out;
`;

const OtherExpensesInfoButton = ({
                                     alignment = "right",
                                     width,
                                 }: {
    alignment?: Alignment;
    width: string;
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);

    const onClick = (e: MouseEvent) => {
        if (buttonRef.current && buttonRef.current.contains(e.target as Node)) {
            setVisible(true);
            return;
        }

        if (
            tooltipRef.current &&
            tooltipRef.current.contains(e.target as Node)
        ) {
            return;
        }

        setVisible(false);
    };

    useLayoutEffect(() => {
        window.addEventListener("mousedown", onClick);

        return () => window.removeEventListener("mousedown", onClick);
    }, []);

    const onClickButtonClick = () => setVisible(false);

    return (
        <LayoutContainer>
            <IconButton
                ref={buttonRef}
                asset={SVGAssets.HelpCircle}
                width={"20px"}
                height={"20px"}
                color={Palette.gray400}
                hoverColor={IconButtonHoverColorType.Light}
            />
            <PositionContainer
                ref={tooltipRef}
                $visible={visible}
                $alignment={alignment}
            >
                <Tooltip
                    width={width}
                    onCloseButtonClick={onClickButtonClick}
                />
            </PositionContainer>
        </LayoutContainer>
    );
};

export default OtherExpensesInfoButton;
