import StatisticNumberSmall from "presentation/components/statistic/statistic_number_small";
import StatisticTitleLarge from "presentation/components/statistic/statistic_title_large";
import DashboardCard from "presentation/pages/dashboard/components/dashboard_card";
import DashboardCardColumn from "presentation/pages/dashboard/components/dashboard_card_column";
import DashboardCardFigures from "presentation/pages/dashboard/components/dashboard_card_figures";
import DashboardFiguresComparison from "presentation/pages/dashboard/components/dashboard_card_figures_comparison";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import dashboardCardSalesSelector from "presentation/states/dashboard/selector/dashboard_card_sales_selector";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useMemo} from "react";
import {useRecoilValue} from "recoil";

const DashboardFiguresCardView = () => {
    const {height} = useContext(DashboardRowContext);

    const {previousYearMonth, selectedYearMonth} = useRecoilValue(
        dashboardDateSelector
    );
    const {status, data} = useRecoilValue(dashboardCardSalesSelector);

    const {previousData, currentData} = useMemo(() => {
        const previousFiltered = data.filter((d) =>
            d.date.isSameMonth(previousYearMonth)
        );
        const previousData = previousFiltered.length
            ? previousFiltered.reduce(
                (acc, cur) => ({
                    date: previousYearMonth,
                    total: acc.total + cur.total,
                    processedCount: acc.processedCount + cur.processedCount,
                    pendingCount: acc.pendingCount + cur.pendingCount,
                }),
                {
                    date: previousYearMonth,
                    total: 0,
                    processedCount: 0,
                    pendingCount: 0,
                }
            )
            : undefined;

        const currentFiltered = data.filter((d) =>
            d.date.isSameMonth(selectedYearMonth)
        );
        const currentData = currentFiltered.length
            ? currentFiltered
                .filter((d) => d.date.isSameMonth(selectedYearMonth))
                .reduce(
                    (acc, cur) => ({
                        date: selectedYearMonth,
                        total: acc.total + cur.total,
                        processedCount:
                            acc.processedCount + cur.processedCount,
                        pendingCount: acc.pendingCount + cur.pendingCount,
                    }),
                    {
                        date: selectedYearMonth,
                        total: 0,
                        processedCount: 0,
                        pendingCount: 0,
                    }
                )
            : undefined;

        return {
            previousData,
            currentData,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onClick = () => {
        const route = `${AppRoutes.CardSales}?scrollTo=statusBoard`;
        AppRouter.navigate(route);
    };

    return (
        <DashboardCard
            status={status}
            height={height!}
            onClick={onClick}
            loadingColor={Palette.primary300}
            backgroundColor={Palette.deepBlue}
            hoverColor={Palette.primary600}
            isButton={true}
            upper={
                <DashboardCardColumn>
                    <StatisticTitleLarge
                        title={S.dashboardPage.figures.cardTitle}
                        color={Palette.white100}
                    />
                    <StatisticNumberSmall
                        value={currentData?.total}
                        unit={S.dashboardPage.wonUnit}
                        color={Palette.white100}
                    />
                    <DashboardFiguresComparison
                        positiveColor={Palette.primary300}
                        titleColor={Palette.white100}
                        previousValue={previousData?.total}
                        value={currentData?.total}
                    />
                </DashboardCardColumn>
            }
            lower={
                <DashboardCardColumn>
                    <DashboardCardFigures
                        title={S.dashboardPage.figures.cardApprovedShortTitle}
                        value={currentData?.processedCount}
                        unit={S.dashboardPage.eaUnit}
                    />
                    <DashboardCardFigures
                        title={S.dashboardPage.figures.cardPendingShortTitle}
                        value={currentData?.pendingCount}
                        unit={S.dashboardPage.eaUnit}
                    />
                </DashboardCardColumn>
            }
        />
    );
};

export default DashboardFiguresCardView;
