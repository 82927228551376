import Palette from "presentation/theme/palette";
import styled from "styled-components";

const HorizontalLineContainer = styled.div.attrs<{
    $widthInPx: number;
    $topOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        top: `${props.$topOffsetInPx}px`,
    },
}))`
    height: 0px;
    border-top: 1px solid ${Palette.black5};
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    transition: width 0.3s ease-in-out, top 0.3s ease-in-out;
`;

const ChartHorizontalGrid = ({
                                 widthInPx,
                                 yPositions,
                             }: {
    widthInPx: number;
    yPositions: number[];
}) => {
    return (
        <>
            {yPositions.map((y, index) => (
                <HorizontalLineContainer
                    key={index}
                    $widthInPx={widthInPx}
                    $topOffsetInPx={y}
                />
            ))}
        </>
    );
};

export default ChartHorizontalGrid;
