import {
    ProfitReportYearlyTableRowHeaderData
} from "presentation/pages/profit_report/view/profit/yearly_table/components/profit_report_yearly_table/profit_report_yearly_table_model";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import {memo} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div<{
    $heightInPx: number;
    $topPaddingInPx: number;
    $bottomPaddingInPx: number;
}>`
    width: 100%;
    height: ${(props) => `${props.$heightInPx}px`};
    padding-top: ${(props) => `${props.$topPaddingInPx}px`};
    padding-bottom: ${(props) => `${props.$bottomPaddingInPx}px`};
    position: relative;
`;

const BackgroundContainer = styled.div<{
    $widthInPx: number;
    $heightInPx: number;
    $backgroundColor?: string;
    $topOffsetInPx: number;
}>`
    width: ${(props) => `${props.$widthInPx}px`};
    height: ${(props) => `${props.$heightInPx}px`};
    background-color: ${(props) => props.$backgroundColor ?? Palette.white100};
    position: absolute;
    top: ${(props) => `${props.$topOffsetInPx}px`};
    left: 0;
    clip-path: xywh(50% 0 50% 100%);
`;

const HeaderContainer = styled.div<{
    $widthInPx: number;
    $heightInPx: number;
    $topRounded: boolean;
    $bottomRounded: boolean;
    $color: string;
    $topOffsetInPx: number;
}>`
    width: ${(props) => `${props.$widthInPx}px`};
    height: ${(props) => `${props.$heightInPx}px`};
    border-top-left-radius: ${(props) => `${props.$topRounded ? 16 : 0}px`};
    border-top-right-radius: ${(props) => `${props.$topRounded ? 16 : 0}px`};
    border-bottom-left-radius: ${(props) =>
    `${props.$bottomRounded ? 16 : 0}px`};
    border-bottom-right-radius: ${(props) =>
    `${props.$bottomRounded ? 16 : 0}px`};
    background-color: ${(props) => props.$color};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: ${(props) => `${props.$topOffsetInPx}px`};
    left: 0;
    z-index: 1;
`;

const LabelContainer = styled.h6`
    ${Fonts.detail2Medium};
    color: ${Palette.white100};
    text-align: center;
    white-space: pre-wrap;
`;

const ProfitReportYearlyTableRowHeaderCell = ({
                                                  widthInPx,
                                                  heightInPx,
                                                  topMarginInPx,
                                                  bottomMarginInPx,
                                                  topRounded,
                                                  bottomRounded,
                                                  label,
                                                  color,
                                                  backgroundColor,
                                              }: {
    widthInPx: number;
    backgroundColor?: string;
} & ProfitReportYearlyTableRowHeaderData) => {
    const totalHeightInPx = heightInPx + topMarginInPx + bottomMarginInPx;

    return (
        <LayoutContainer
            $heightInPx={totalHeightInPx}
            $topPaddingInPx={topMarginInPx}
            $bottomPaddingInPx={bottomMarginInPx}
        >
            <BackgroundContainer
                $widthInPx={widthInPx}
                $heightInPx={heightInPx}
                $backgroundColor={backgroundColor}
                $topOffsetInPx={topMarginInPx}
            />
            <HeaderContainer
                $widthInPx={widthInPx}
                $heightInPx={heightInPx}
                $topRounded={topRounded}
                $bottomRounded={bottomRounded}
                $color={color}
                $topOffsetInPx={topMarginInPx}
            >
                <LabelContainer>{label}</LabelContainer>
            </HeaderContainer>
        </LayoutContainer>
    );
};

export default memo(ProfitReportYearlyTableRowHeaderCell);
