import DispensingDateFilterType from "domain/model/tax/profit_report/dispensing_date_filter_type";
import DispensingFigures from "domain/model/tax/profit_report/dispensing_figures";
import ProfitReportDateFilterType from "domain/model/tax/profit_report/profit_report_date_filter_type";
import ProfitReportFilteredFigures from "domain/model/tax/profit_report/profit_report_filtered_figures";
import {ProfitReportReplayState} from "presentation/states/profit_report/profit_report_replay_state";
import DateTime from "presentation/utils/extension/date_extension";
import {optional} from "presentation/utils/types/optional";
import {atom} from "recoil";
import {BehaviorSubject} from "rxjs";

type ProfitReportProfitStatusBoardState = {
    selectedDateFilter?: ProfitReportDateFilterType;
    startDateKey: string;
    endDateKey: string;
    accumulated: boolean;
    data: ProfitReportFilteredFigures[];
};

type ProfitReportProfitYearlyTableState = {
    selectedYearKey: string;
    data: ProfitReportFilteredFigures[];
};

type ProfitReportProfitYearlyChartState = {
    selectedYearKey: string;
    data: ProfitReportFilteredFigures[];
};

type ProfitReportDispensingStatusBoardState = {
    selectedDateFilter?: DispensingDateFilterType;
    startDateKey: string;
    endDateKey: string;
    accumulated: boolean;
    data: DispensingFigures[];
};

type ProfitReportState = {
    taxStartDateKey: string;
    profit: {
        statusBoard: ProfitReportProfitStatusBoardState;
        yearlyTable: ProfitReportProfitYearlyTableState;
        yearlyChart: ProfitReportProfitYearlyChartState;
    };
    dispensing: {
        statusBoard: ProfitReportDispensingStatusBoardState;
    };
};

export const profitReportReplayState = new BehaviorSubject<
    optional<ProfitReportReplayState>
>(undefined);

const profitReportState = atom<ProfitReportState>({
    key: "profitReportState",
    default: {
        taxStartDateKey: DateTime.now().min.key,
        profit: {
            statusBoard: {
                selectedDateFilter: ProfitReportDateFilterType.RecentSixMonths,
                startDateKey: DateTime.now().copyWith({day: 1}).min.key,
                endDateKey: DateTime.now().min.key,
                accumulated: false,
                data: [],
            },
            yearlyTable: {
                selectedYearKey: DateTime.now().copyWith({month: 1, day: 1})
                    .min.key,
                data: [],
            },
            yearlyChart: {
                selectedYearKey: DateTime.now().copyWith({month: 1, day: 1})
                    .min.key,
                data: [],
            },
        },
        dispensing: {
            statusBoard: {
                selectedDateFilter: DispensingDateFilterType.ThisMonth,
                startDateKey: DateTime.now().copyWith({day: 1}).min.key,
                endDateKey: DateTime.now().min.key,
                accumulated: false,
                data: [],
            },
        },
    },
});

export default profitReportState;
