import BaseRepository from "data/repository/base_repository";
import User from "domain/model/user/user";
import DateTime from "presentation/utils/extension/date_extension";
import {optional} from "presentation/utils/types/optional";

export default class UserRepository extends BaseRepository {
    isAuthenticated = () =>
        this.handler({
            request: async (_, __, storage) => {
                return storage.isAuthenticated();
            },
        });

    getTaxStartDate = async (): Promise<optional<DateTime>> =>
        await this.handler({
            request: async () => {
                this.delayed(300);

                return DateTime.now().getFirstDayInMonth().copyWith({
                    year: 2015,
                });
            },
        });

    requestCertCode = async ({
                                 phoneNumber,
                             }: {
        phoneNumber: string;
    }): Promise<void> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);
            },
        });

    login = async ({
                       phoneNumber,
                       code,
                   }: {
        phoneNumber: string;
        code: string;
    }): Promise<void> =>
        await this.handler({
            request: async (_, __, storage) => {
                await this.delayed(300);

                storage.setAuthToken({
                    accessToken: "access",
                    refreshToken: "refresh",
                });
            },
        });

    logout = async (): Promise<void> =>
        await this.handler({
            request: async (_, __, storage) => {
                await this.delayed(300);

                storage.clearStorage();
            },
        });

    getUser = async (): Promise<User> =>
        await this.handler({
            concurrency: 2,
            request: async () => {
                await this.delayed(300);

                return {
                    name: "신희망",
                    email: "nnnnnnnnuuuuuuu@naver.com",
                    pharmacyName: "지킴 약국",
                    phoneNumber: "01066805082",
                    licenseNumber: "000000",
                    businessRegistrationNumber: "0000000000",
                    nursingAgencyNumber: "00000000",
                    address: "서울시 성동구 광나루로 204",
                    addressDetails: "지킴컴퍼니",
                };
            },
        });
}
