import {FontsConfig} from "presentation/theme/fonts";
import {createGlobalStyle} from "styled-components";

export type AnimationTheme = {
    defaultSpeedMS: number;
    loadingDelayMS: number;
};

export type FontTheme = {
    fonts: FontsConfig;
};

export type ComponentTheme = {
    lnbMinWidth: string;
    lnbMaxWidth: string;
    tnbHeight: string;
    tabBarHeight: string;
    dashboardHeaderHeight: string;
    paginationHeight: string;
    taxTimelineHeaderHeight: string;
};

const GlobalThemeContainer = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
    }

    * {
        margin: 0;
        padding: 0;
        font-family: 'Pretendard', serif;
    }

    body {
        overflow-x: clip;
        overflow-y: scroll;
    }
`;

const GlobalTheme = () => {
    return <GlobalThemeContainer/>;
};

export default GlobalTheme;
