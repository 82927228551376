import BaseRepository from "data/repository/base_repository";
import BillingFigures from "domain/model/tax/billing/billing_figures";
import BillingFiguresStatusType from "domain/model/tax/billing/billing_figures_status_type";
import BillingMonthlyFigures from "domain/model/tax/billing/billing_monthly_figures";
import DateTime, {DateDifferenceUnitType,} from "presentation/utils/extension/date_extension";

export default class BillingRepository extends BaseRepository {
    getDailyFiguresInRange = async ({
                                        startDate,
                                        endDate,
                                    }: {
        startDate: DateTime;
        endDate: DateTime;
    }): Promise<BillingFigures[]> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                if (Math.random() < 0.1) return [];

                const difference = endDate.subtract(
                    startDate,
                    DateDifferenceUnitType.Day
                );

                return Array.from(
                    {
                        length: Math.floor(5 + Math.random() * 500),
                    },
                    () => {
                        const date = endDate.copyWith({
                            day:
                                endDate.day -
                                Math.floor(Math.random() * difference),
                        });
                        const billingOrder = date.format("yyyyMMdd");
                        const billingAmount = Math.floor(
                            Math.random() * 100_000_000
                        );
                        const pendingAmount =
                            Math.random() > 0.9
                                ? Math.floor(Math.random() * 5_000_000)
                                : 0;
                        const receiveDate = date.copyWith({
                            day: date.day + Math.floor(Math.random() * 10),
                        });
                        const status =
                            pendingAmount !== 0
                                ? BillingFiguresStatusType.Pending
                                : Math.random() < 0.5
                                    ? BillingFiguresStatusType.Processing
                                    : BillingFiguresStatusType.Processed;

                        return {
                            date,
                            billingOrder,
                            billingAmount,
                            pendingAmount,
                            taxes: Math.floor(billingAmount * 0.11),
                            receiveAmount: Math.floor(
                                billingAmount * Math.random()
                            ),
                            receiveDate,
                            status,
                        };
                    }
                ).toSorted(
                    (a, b) =>
                        a.date.millisecondsSinceEpoch -
                        b.date.millisecondsSinceEpoch
                );
            },
        });

    getMonthlyFiguresInRange = async ({
                                          startDate,
                                          endDate,
                                      }: {
        startDate: DateTime;
        endDate: DateTime;
    }): Promise<BillingMonthlyFigures[]> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                if (Math.random() < 0.1) return [];

                let pointer = startDate.getFirstDayInMonth();
                const _endDate = DateTime.min(DateTime.minNow(), endDate);
                const dates = [];
                while (pointer.isSameDayOrBefore(_endDate)) {
                    dates.push(pointer);
                    pointer = pointer.copyWith({
                        month: pointer.month + 1,
                    });
                }

                return dates
                    .map((date) => {
                        const billingCount = Math.floor(Math.random() * 500);
                        const billingAmount = !billingCount
                            ? 0
                            : Math.floor(Math.random() * 100_000_000);
                        const pendingCount = Math.floor(
                            Math.random() * billingCount
                        );
                        const pendingAmount = !pendingCount
                            ? 0
                            : Math.floor(Math.random() * 5_000_000);
                        const taxes = Math.floor(billingAmount * 0.11);
                        const receiveCount = billingCount - pendingCount;
                        const receiveAmount = !receiveCount
                            ? 0
                            : Math.floor(billingAmount * Math.random());

                        return {
                            date,
                            billingAmount,
                            billingCount,
                            pendingAmount,
                            pendingCount,
                            taxes,
                            receiveAmount,
                            receiveCount,
                        };
                    })
                    .filter((d) => d !== undefined)
                    .map((d) => d!);
            },
        });
}
