import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import useSecureStorage from "presentation/utils/hooks/use_secure_storage";
import {PropsWithChildren, useEffect} from "react";
import {useLocation} from "react-router-dom";

const AppRouterPage = ({
                           redirectToMainOnAuthenticated,
                           redirectToLoginOnUnauthenticated,
                           children,
                       }: {
    redirectToMainOnAuthenticated: boolean;
    redirectToLoginOnUnauthenticated: boolean;
} & PropsWithChildren) => {
    const {pathname} = useLocation();
    const localStorage = useSecureStorage();

    const isAuthenticated = localStorage.isAuthenticated();

    useEffect(() => {
        if (redirectToMainOnAuthenticated && redirectToLoginOnUnauthenticated) return;

        if (isAuthenticated && redirectToMainOnAuthenticated) {
            AppRouter.navigate(AppRoutes.Main, {
                replace: true,
            });
        }
        if (!isAuthenticated && redirectToLoginOnUnauthenticated) {
            AppRouter.navigate(AppRoutes.Login, {
                replace: true,
            });
        }
    }, [
        pathname,
        redirectToMainOnAuthenticated,
        redirectToLoginOnUnauthenticated,
        isAuthenticated,
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>{children}</>;
};

export default AppRouterPage;
