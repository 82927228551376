import NumberHelper from "config/helper/number_helper";
import TransactionsDailyFilteredFigures from "domain/model/tax/transactions/transactions_daily_filtered_figures";
import TableCellLayout from "presentation/components/table/table_cell_layout";
import TableRowLayout from "presentation/components/table/table_row_layout";
import {
    TransactionsTableContext
} from "presentation/pages/transactions/components/transactions_table/transactions_table";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LabelContainer = styled.p.attrs<{
    $bold: boolean;
}>((props) => ({
    style: {
        fontWeight: props.$bold ? FontWeights.bold : FontWeights.medium,
    },
}))`
    min-width: max-content;
    ${Fonts.detail2Medium};
    text-align: center;
`;

const CollectingLabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail2};
    text-align: center;
    white-space: pre-wrap;
`;

const TransactionsTableRow = ({
                                  highlight,
                                  data,
                              }: {
    highlight: boolean;
    data: TransactionsDailyFilteredFigures;
}) => {
    const context = useContext(TransactionsTableContext);

    const {clickable, dateLabel, labels} = useMemo(() => {
        const dateLabel = data.date.format(S.transactionsTable.dateFormat);
        const labels = [
            ...data.values
                .slice(0, context.headerLabels.length - 1)
                .map((v) => (v ? NumberHelper.toStringWithDividers(v) : "")),
        ];

        const clickable = !!data.values[0];

        return {
            clickable,
            dateLabel,
            labels: labels.map((l, index) => ({
                value: l,
                leftMarginInPx:
                    index === 0 ? 0 : context.constants.horizontalGapInPx,
                roundRight: index === labels.length - 1,
            })),
        };
    }, [data, context.constants, context.headerLabels.length]);

    const now = DateTime.minNow();
    const yesterday = now.copyWith({day: now.day - 1});
    const collecting =
        context.hasCollectingData && data.date.isSameDay(yesterday);

    const heightInPx = collecting
        ? context.constants.collectingCellHeightInPx
        : context.constants.cellHeightInPx;
    const backgroundColor = highlight ? Palette.gray50 : Palette.none;

    const onClick = () => context.onClick(data);

    return (
        <TableRowLayout
            borderRadiusInPx={context.constants.rowBorderRadiusInPx}
            roundLeft={true}
            roundRight={true}
            backgroundColor={backgroundColor}
            hoverColor={Palette.primary10}
            onClick={clickable ? onClick : undefined}
        >
            <TableCellLayout
                flex={1}
                minWidthInPx={context.constants.cellMinWidthInPx}
                heightInPx={heightInPx}
                leftMarginInPx={0}
                rightDivider={false}
                roundLeft={true}
                roundRight={false}
                backgroundColor={Palette.none}
            >
                <LabelContainer $bold={false}>{dateLabel}</LabelContainer>
            </TableCellLayout>
            {collecting ? (
                <TableCellLayout
                    flex={4}
                    minWidthInPx={context.constants.cellMinWidthInPx}
                    heightInPx={heightInPx}
                    leftMarginInPx={context.constants.horizontalGapInPx}
                    rightDivider={false}
                    roundLeft={false}
                    roundRight={false}
                    backgroundColor={Palette.none}
                >
                    <CollectingLabelContainer>
                        {S.transactionsTable.collectingLabel}
                    </CollectingLabelContainer>
                </TableCellLayout>
            ) : (
                labels.map((l, index) => (
                    <TableCellLayout
                        key={index}
                        flex={1}
                        minWidthInPx={context.constants.cellMinWidthInPx}
                        heightInPx={heightInPx}
                        leftMarginInPx={l.leftMarginInPx}
                        rightDivider={false}
                        roundLeft={false}
                        roundRight={l.roundRight}
                        backgroundColor={Palette.none}
                    >
                        <LabelContainer $bold={index === 0}>
                            {l.value}
                        </LabelContainer>
                    </TableCellLayout>
                ))
            )}
        </TableRowLayout>
    );
};

export default TransactionsTableRow;
