import ProfitReportRepository from "data/repository/profit_report_repository";
import UserRepository from "data/repository/user_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import DispensingDateFilterType, {
    DispensingDateFilterTypeMap,
} from "domain/model/tax/profit_report/dispensing_date_filter_type";
import Page from "presentation/components/common/page";
import ProfitReportDispensingStatusBoardView
    from "presentation/pages/profit_report/view/dispensing/status_board/profit_report_dispensing_status_board_view";
import profitReportState from "presentation/states/profit_report/profit_report_state";
import DateTime from "presentation/utils/extension/date_extension";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import {useState} from "react";
import {useSetRecoilState} from "recoil";

const ProfitReportDispensingView = () => {
    const userRepository = useRepository(UserRepository);
    const profitReportRepository = useRepository(ProfitReportRepository);

    const setState = useSetRecoilState(profitReportState);

    const [status, setStatus] = useState(ViewStatusType.Loading);

    useLifeCycle({
        onInit: async () => {
            const taxStartDate =
                (await userRepository({
                    handler: async (repository) => {
                        return await repository.getTaxStartDate();
                    },
                })) ?? DateTime.now();

            await profitReportRepository({
                handler: async (repository) => {
                    const statusBoardDateFilter =
                        DispensingDateFilterType.ThisMonth;
                    const statusBoardStartDate =
                        DispensingDateFilterTypeMap.datePeriod(
                            statusBoardDateFilter
                        ).startDate;
                    const statusBoardEndDate =
                        DispensingDateFilterTypeMap.datePeriod(
                            statusBoardDateFilter
                        ).endDate;

                    const statusBoardData =
                        await repository.getDispensingDailyFiguresInRange({
                            startDate: statusBoardStartDate,
                            endDate: statusBoardEndDate,
                        });

                    setState((prev) => ({
                        ...prev,
                        taxStartDateKey: taxStartDate.key,
                        dispensing: {
                            ...prev.dispensing.statusBoard,
                            statusBoard: {
                                ...prev.dispensing.statusBoard,
                                selectedDateFilter: statusBoardDateFilter,
                                startDateKey: statusBoardStartDate.key,
                                endDateKey: statusBoardEndDate.key,
                                data: statusBoardData,
                            },
                        },
                    }));

                    setStatus(ViewStatusType.Loaded);
                },
            });
        },
    });

    return (
        <Page status={status}>
            <ProfitReportDispensingStatusBoardView status={status}/>
        </Page>
    );
};

export default ProfitReportDispensingView;
