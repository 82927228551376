import SizedBox from "presentation/components/common/sized_box";
import ContentBox from "presentation/components/content/content_box";
import ContentLayout from "presentation/components/content/content_layout";
import TaxTimelineTable from "presentation/pages/tax_timeline/components/tax_timeline_table/tax_timeline_table";
import TaxTimelineTableHeaderView from "presentation/pages/tax_timeline/view/tax_timeline_table_header_view";
import taxTimelineSelector from "presentation/states/tax_timeline/selector/tax_timeline_selector";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {useRef} from "react";
import {useRecoilValue} from "recoil";

const TaxTimelineTableView = () => {
    const {selectedYear, data} = useRecoilValue(taxTimelineSelector);

    const ref = useRef<HTMLDivElement>(null);

    const {height} = useResizeObserver(ref, []);

    return (
        <ContentBox contentHeight={height}>
            <ContentLayout ref={ref}>
                <TaxTimelineTableHeaderView/>
                <SizedBox height={"12px"}/>
                <TaxTimelineTable year={selectedYear} data={data}/>
            </ContentLayout>
        </ContentBox>
    );
};

export default TaxTimelineTableView;
