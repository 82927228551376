import CheckToggleButton from "presentation/components/button/check_toggle_button";
import profitReportState from "presentation/states/profit_report/profit_report_state";
import profitReportDispensingStatusBoardSelector
    from "presentation/states/profit_report/selector/dispensing/status_board/profit_report_dispensing_status_board_date_filter_selector";
import S from "presentation/theme/s";
import {useRecoilValue, useSetRecoilState} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const ProfitReportDispensingStatusBoardAccumulatedButtonView = () => {
    const {accumulated} = useRecoilValue(
        profitReportDispensingStatusBoardSelector
    );
    const setState = useSetRecoilState(profitReportState);

    const onClick = () =>
        setState((prev) => ({
            ...prev,
            dispensing: {
                ...prev.dispensing,
                statusBoard: {
                    ...prev.dispensing.statusBoard,
                    accumulated: !prev.dispensing.statusBoard.accumulated,
                },
            },
        }));

    return (
        <LayoutContainer>
            <CheckToggleButton
                selected={accumulated}
                label={
                    S.profitReportPage.dispensing.statusBoard.accumulatedLabel
                }
                onClick={onClick}
            />
        </LayoutContainer>
    );
};

export default ProfitReportDispensingStatusBoardAccumulatedButtonView;
