import styled from "styled-components";
import {ToastContainer as ToastifyContainer} from "react-toastify";
import Palette from "presentation/theme/palette";
import "react-toastify/dist/ReactToastify.css";
import FontsConfig from "presentation/theme/fonts";

const ToastContainer = styled(ToastifyContainer)`
    --toastify-toast-width: auto;
    --toastify-toast-min-height: 56px;
    --toastify-z-index: 2147483647;

    .Toastify__toast {
        max-width: 500px;
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 168px;
        background-color: ${Palette.gray500};
        bottom: 50px;
    }
    .Toastify__toast-body {
        ${FontsConfig.body1};
        font-size: 1rem;
        color: ${Palette.white100};
        text-align: center;
    }
    .Toastify__close-button {
        display: none;
    }
`;

const ToastProvider = () => {
    return (
        <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={true}
        />
    );
};

export default ToastProvider;
