import StatisticNumberMedium from "presentation/components/statistic/statistic_number_medium";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const DialogStatisticRow = ({
                                count,
                                total,
                            }: {
    count?: number;
    total?: number;
}) => {
    return (
        <LayoutContainer>
            <StatisticNumberMedium
                value={count}
                unit={S.dialogStatisticRow.eaUnit}
                color={Palette.gray800}
                unitColor={Palette.gray600}
                rightMarginInPx={24}
            />
            <StatisticNumberMedium
                value={total}
                unit={S.dialogStatisticRow.wonUnit}
                color={Palette.gray800}
                unitColor={Palette.gray600}
            />
        </LayoutContainer>
    );
};

export default DialogStatisticRow;
