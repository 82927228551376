import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";

enum BillingDateFilterType {
    ThisMonth = "thisMonth",
    LastMonth = "lastMonth",
    RecentThreeMonths = "recentThreeMonths",
}

export const BillingDateFilterTypeMap = {
    label: (type: BillingDateFilterType): string => {
        switch (type) {
            case BillingDateFilterType.ThisMonth:
                return S.billingDateFilterType.thisMonthLabel;
            case BillingDateFilterType.LastMonth:
                return S.billingDateFilterType.lastMonthLabel;
            case BillingDateFilterType.RecentThreeMonths:
                return S.billingDateFilterType.recentThreeMonthsLabel;
            default:
                throw new Error("Invalid BillingDateFilterType");
        }
    },
    datePeriod: (
        type: BillingDateFilterType
    ): { startDate: DateTime; endDate: DateTime } => {
        const now = DateTime.minNow();
        const currentYearMonth = now.getFirstDayInMonth();
        switch (type) {
            case BillingDateFilterType.ThisMonth:
                return {
                    startDate: now.getFirstDayInMonth(),
                    endDate: now,
                };
            case BillingDateFilterType.LastMonth:
                return {
                    startDate: now.copyWith({
                        month: now.month - 1,
                        day: now.day + 1,
                    }),
                    endDate: now,
                };
            case BillingDateFilterType.RecentThreeMonths:
                const threeMonthsBefore = now.copyWith({
                    month: now.month - 3,
                    day: 1,
                });
                const lastDayInMonthThreeMonthsBefore =
                    threeMonthsBefore.getLastDayInMonth();

                const startDate =
                    now.day > lastDayInMonthThreeMonthsBefore.day
                        ? lastDayInMonthThreeMonthsBefore.copyWith({
                            day: lastDayInMonthThreeMonthsBefore.day + 1,
                        })
                        : threeMonthsBefore.copyWith({
                            day: now.day + 1,
                        });

                return {
                    startDate,
                    endDate: currentYearMonth,
                };
            default:
                throw new Error("Invalid BillingDateFilterType");
        }
    },
};

export default BillingDateFilterType;
