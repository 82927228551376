import TransactionsRepository from "data/repository/transactions_repository";
import TransactionsDateFilterType, {
    TransactionsDateFilterTypeMap,
} from "domain/model/tax/transactions/transactions_date_filter_type";
import TransactionsType from "domain/model/tax/transactions/transactions_type";
import DateFilterPeriodButton from "presentation/components/button/date_filter_period_button/date_filter_period_button";
import OptionButton from "presentation/components/button/option_button";
import DateButtonsRow from "presentation/components/common/date_buttons_row";
import {TransactionsPageContext} from "presentation/pages/transactions/transactions_page";
import transactionsTableSelector from "presentation/states/transactions/selector/transactions_table_selector";
import {transactionsState} from "presentation/states/transactions/transactions_state";
import DateTime from "presentation/utils/extension/date_extension";
import useRepository from "presentation/utils/hooks/use_repository";
import {useContext, useMemo} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";

const TransactionsTableDateButtonsView = () => {
    const {transactionsType} = useContext(TransactionsPageContext);
    const repository = useRepository(TransactionsRepository);

    const setState = useSetRecoilState(transactionsState);
    const {selectedDateFilter, startDate, endDate} = useRecoilValue(
        transactionsTableSelector
    );

    const dateFilters = useMemo(
        () => Object.values(TransactionsDateFilterType),
        []
    );

    const onClick = (type: TransactionsDateFilterType) => async () => {
        const {startDate, endDate} =
            TransactionsDateFilterTypeMap.datePeriod(type);

        setState((prev) => ({
            ...prev,
            table: {
                ...prev.table,
                selectedDateFilter: type,
                startDateKey: startDate.key,
                endDateKey: endDate.key,
            },
        }));

        repository({
            handler: async (repository) => {
                const data = await repository.getFilteredFiguresInRange({
                    transactionsType: transactionsType!,
                    startDate,
                    endDate,
                });

                setState((prev) => ({
                    ...prev,
                    table: {
                        ...prev.table,
                        selectedDateFilter: type,
                        startDateKey: startDate.key,
                        endDateKey: endDate.key,
                        data,
                    },
                }));
            },
        });
    };

    const onDateSelected = async (startDate: DateTime, endDate: DateTime) => {
        const match = dateFilters.find(
            (d) =>
                TransactionsDateFilterTypeMap.datePeriod(d).startDate.isEqual(
                    startDate
                ) &&
                TransactionsDateFilterTypeMap.datePeriod(d).endDate.isEqual(
                    endDate
                )
        );

        setState((prev) => ({
            ...prev,
            table: {
                ...prev.table,
                selectedDateFilter: match,
                startDateKey: startDate.key,
                endDateKey: endDate.key,
            },
        }));

        repository({
            handler: async (repository) => {
                const data = await repository.getFilteredFiguresInRange({
                    transactionsType: TransactionsType.Sales,
                    startDate,
                    endDate,
                });

                setState((prev) => ({
                    ...prev,
                    table: {
                        ...prev.table,
                        selectedDateFilter: match,
                        startDateKey: startDate.key,
                        endDateKey: endDate.key,
                        data,
                    },
                }));
            },
        });
    };

    return (
        <DateButtonsRow>
            {dateFilters.map((d) => (
                <OptionButton
                    key={d}
                    selected={d === selectedDateFilter}
                    label={TransactionsDateFilterTypeMap.label(d)}
                    onClick={onClick(d)}
                />
            ))}
            <DateFilterPeriodButton
                startDate={startDate}
                endDate={endDate}
                onDateSelected={onDateSelected}
            />
        </DateButtonsRow>
    );
};

export default TransactionsTableDateButtonsView;
