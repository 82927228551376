import Palette from "presentation/theme/palette";
import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $width: string;
    $height: string;
    $paddingInPx: number;
}>((props) => ({
    style: {
        maxWidth: props.$width,
        height: props.$height,
    },
}))`
    min-width: ${(props) => props.$width};
    border-radius: 16px;
    box-shadow: 0 0 0 1px ${Palette.gray200} inset;
    overflow: hidden;
    position: relative;
    transition: min-width 0.3s ease-in-out, max-width 0.3s ease-in-out,
        height 0.3s ease-in-out, padding 0.3s ease-in-out;

    @media (max-width: 768px) {
        min-width: ${(props) => props.$width};
    }
`;

const PaddingContainer = styled.div.attrs<{
    $width: string;
    $height: string;
    $paddingInPx: number;
}>((props) => ({
    style: {
        width: props.$width,
        height: props.$height,
        top: props.$paddingInPx,
        left: props.$paddingInPx,
    },
}))`
    position: absolute;
`;

const CalendarBox = ({
                         width,
                         height,
                         paddingInPx,
                         children,
                     }: PropsWithChildren<{
    width: string;
    height: string;
    paddingInPx: number;
}>) => {
    const innerWidth = `calc(${width} - ${paddingInPx * 2}px)`;
    const innerHeight = `calc(${height} - ${paddingInPx * 2}px)`;

    return (
        <LayoutContainer
            $width={width}
            $height={height}
            $paddingInPx={paddingInPx}
        >
            <PaddingContainer
                $width={innerWidth}
                $height={innerHeight}
                $paddingInPx={paddingInPx}
            >
                {children}
            </PaddingContainer>
        </LayoutContainer>
    );
};

export default CalendarBox;
