import {animated, useSpring} from "@react-spring/web";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.button.attrs<{
    $selected: boolean;
    $sizeInPx: number;
    $horizontalMarginInPx: number;
}>((props) => ({
    style: {
        width: `${props.$sizeInPx}px`,
        height: `${props.$sizeInPx}px`,
        margin: `0 ${props.$horizontalMarginInPx}px`,
        backgroundColor: props.$selected
            ? Palette.primary500
            : Palette.white100,
    },
}))`
    border-radius: 12px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelContainer = styled(animated.p)`
    ${Fonts.detail2Medium};
    text-align: center;
`;

const PaginationSelectionButton = ({
                                       selected,
                                       sizeInPx,
                                       horizontalMarginInPx,
                                       page,
                                       onClick,
                                   }: {
    selected: boolean;
    sizeInPx: number;
    horizontalMarginInPx: number;
    page: number;
    onClick: () => void;
}) => {
    const props = useSpring({
        color: selected ? Palette.white100 : Palette.gray600,
        fontWeight: selected ? FontWeights.bold : FontWeights.medium,
    });

    return (
        <LayoutContainer
            onClick={onClick}
            $selected={selected}
            $sizeInPx={sizeInPx}
            $horizontalMarginInPx={horizontalMarginInPx}
        >
            <LabelContainer style={props}>{page}</LabelContainer>
        </LayoutContainer>
    );
};

export default PaginationSelectionButton;
