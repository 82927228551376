import {animated, useSpring} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {useState} from "react";
import styled from "styled-components";

type TextAlign = "left" | "center" | "right";

const maxWidthInPx = 57;

const LayoutContainer = styled(animated.li).attrs<{
    $collapsed: boolean;
    $heightInPx: number;
}>((props) => ({
    style: {
        padding: props.$collapsed ? "0 2px" : "0 12px",
    },
}))`
    width: 100%;
    height: ${(props) => `${props.$heightInPx}px`};
    background-color: ${Palette.white100};
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    list-style: none;
    cursor: pointer;
    position: relative;
    transition: padding 0.3s ease-in-out, background-color 0.3s ease-in-out,
        gap 0.3s ease-in-out;

    &:hover {
        background-color: ${Palette.primary10};
    }
`;

const LabelContainer = styled.p.attrs<{
    $collapsed: boolean;
    $textAlign: TextAlign;
}>((props) => ({
    style: {
        maxWidth: props.$collapsed ? `${maxWidthInPx}px` : "100%",
        width: props.$collapsed ? `${maxWidthInPx}px` : "100%",
        textAlign: props.$textAlign,
    },
}))`
    min-width: 40px;
    flex-grow: 1;
    ${Fonts.detail2Medium};
    transition: width 0.1s ease-in-out, min-width 0.1s ease-in-out,
        max-width 0.1s ease-in-out;
`;

const ValueContainer = styled(animated.p)`
    min-width: 40px;
    max-width: 100%;
    width: 100%;
    flex-grow: 1;
    ${Fonts.detail2};
    text-align: right;
`;

const IconContainer = styled(animated.div)`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 0;
    transform: translate(-50%, -50%);
`;

const DashboardFiguresCardPendingListTile = ({
                                                 collapsed,
                                                 heightInPx,
                                                 date,
                                                 count,
                                                 pending,
                                                 onClick,
                                             }: {
    collapsed: boolean;
    heightInPx: number;
    date: DateTime;
    count: number;
    pending: number;
    onClick: () => void;
}) => {
    const [hovered, setHovered] = useState(false);

    const valueProps = useSpring({
        transform:
            hovered && !collapsed ? "translateX(-28px)" : "translateX(0px)",
        backgroundColor: Palette.none,
    });
    const iconProps = useSpring({
        opacity: hovered && !collapsed ? 1 : 0,
        config: {
            mass: 0.3,
        },
    });

    const dateFormat = collapsed
        ? S.dashboardPage.figures.cardPendingDateShortFormat
        : S.dashboardPage.figures.cardPendingDateFormat;

    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    return (
        <LayoutContainer
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            $collapsed={collapsed}
            $heightInPx={heightInPx}
        >
            <LabelContainer $collapsed={collapsed} $textAlign={"left"}>
                {date.format(dateFormat)}
            </LabelContainer>
            <LabelContainer $collapsed={collapsed} $textAlign={"center"}>
                {S.dashboardPage.figures.cardPendingCountLabel(
                    NumberHelper.toStringWithDividers(count)
                )}
            </LabelContainer>
            <ValueContainer style={valueProps}>
                {S.dashboardPage.figures.cardPendingLabel(
                    NumberHelper.toStringWithDividers(pending)
                )}
            </ValueContainer>
            <IconContainer style={iconProps}>
                <SVG
                    asset={SVGAssets.Search}
                    width={"20px"}
                    height={"20px"}
                    color={Palette.primary500}
                />
            </IconContainer>
        </LayoutContainer>
    );
};

export default DashboardFiguresCardPendingListTile;
