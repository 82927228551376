import AppRouteMapping from "presentation/routes/app_route_mapping";
import {createBrowserRouter} from "react-router-dom";

const router = createBrowserRouter(AppRouteMapping);

const AppRouter = {
    ...router,
    popIfAvailable() {
        if (router.state.location.key === "default") return;

        router.navigate(-1);
    },
};

export default AppRouter;
