import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const LayoutContainer = styled.button`
    min-width: max-content;
    height: 2.5rem;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    background-color: ${Palette.none};
    border: none;
    border-radius: 12px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: ${Palette.gray100};
    }
`;

const LabelContainer = styled.p`
    ${Fonts.detail2Medium};
`;

const DashboardDetailButton = ({
                                   className,
                                   onClick,
                               }: {
    className?: string;
    onClick: () => void;
}) => {
    return (
        <LayoutContainer className={className} onClick={onClick}>
            <LabelContainer>{S.dashboardDetailsButton.label}</LabelContainer>
            <SVG
                asset={SVGAssets.Search}
                width={"20px"}
                height={"20px"}
                color={Palette.primary500}
            />
        </LayoutContainer>
    );
};

export default DashboardDetailButton;
