import NumberHelper from "config/helper/number_helper";
import {ChartAxisData, ChartInitialScrollPositionType,} from "presentation/components/charts/common/chart_model";
import ChartLegendLabel from "presentation/components/charts/common/chart_legend_label";
import ProfitChart from "presentation/components/charts/profit_chart/profit_chart";
import SizedBox from "presentation/components/common/sized_box";
import DashboardCard from "presentation/pages/dashboard/components/dashboard_card";
import DashboardCardColumn from "presentation/pages/dashboard/components/dashboard_card_column";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import DashboardProfitChartHeaderView
    from "presentation/pages/dashboard/view/profit/chart/dashboard_profit_chart_header_view";
import dashboardProfitSelector from "presentation/states/dashboard/selector/dashboard_profit_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {DateDifferenceUnitType} from "presentation/utils/extension/date_extension";
import {useContext, useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
`;

const DashboardProfitChartView = () => {
    const {height} = useContext(DashboardRowContext);

    const {status, startDate, endDate, data} = useRecoilValue(
        dashboardProfitSelector
    );

    const {
        chartData,
        highlightedXIndices,
        highlightedXAxisIndices,
        xAxis,
        yAxis,
        overrideConstants,
        legendsData,
    } = useMemo(() => {
        const chartData = data.map((d) => ({
            x: d.date.subtract(startDate, DateDifferenceUnitType.Month),
            y1: {
                value: d.sales[0],
                color: Palette.deepBlue,
                borderRounded: true,
            },
            y2: {
                value: -d.purchases[0],
                color: Palette.orange500,
                borderRounded: false,
            },
            y3: {
                value: -d.otherExpenses[0] - d.purchases[0],
                color: Palette.gray500,
                borderRounded: true,
            },
        }));

        const yValues = chartData
            .map((d) => [d.y1.value, d.y2.value, d.y3.value])
            .flat();
        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);

        const yLabelCount = 4;
        const unit = NumberHelper.getUnitFromMinMaxCount(
            minY,
            maxY,
            yLabelCount
        );
        const [lowerBoundY, upperBoundY] = NumberHelper.getBoundsForCount(
            minY,
            maxY,
            yLabelCount
        );

        const xAxisLength =
            endDate.subtract(startDate, DateDifferenceUnitType.Month) + 1;
        const xAxis = Array.from(
            {
                length: xAxisLength,
            },
            (_, index) => ({
                value: index,
                label: startDate
                    .copyWith({
                        month: startDate.month + index,
                    })
                    .format(S.dashboardPage.chartXAxisDateFormat),
            })
        );

        let yAxis: ChartAxisData[] = [];

        let pointer = lowerBoundY;
        while (pointer <= upperBoundY) {
            yAxis.push({
                value: pointer,
                label: NumberHelper.toChartYAxisAbbreviatedString(pointer),
            });
            pointer += unit;
        }

        const overrideConstants = {
            binMarginInPx: 22,
            xAxisHorizontalMarginInPx: 64,
        };

        const highlightedXIndices = new Set<number>([xAxisLength - 1]);
        const highlightedXAxisIndices = new Set<number>([xAxisLength - 1]);

        if (!data.length) {
            const unit = 2_000_000;
            yAxis = Array.from({length: yLabelCount}, (_, index) => ({
                value: index,
                label: NumberHelper.toChartYAxisAbbreviatedString(unit * index),
            }));
        }

        const legendsData = [
            {
                color: Palette.lightGreen,
                label: S.dashboardPage.profit.legends.profitLabel,
            },
            {
                color: Palette.deepBlue,
                label: S.dashboardPage.profit.legends.salesLabel,
            },
            {
                color: Palette.orange500,
                label: S.dashboardPage.profit.legends.purchasesLabel,
            },
            {
                color: Palette.gray500,
                label: S.dashboardPage.profit.legends.otherExpensesLabel,
            },
        ];

        return {
            chartData,
            highlightedXIndices,
            highlightedXAxisIndices,
            xAxis,
            yAxis,
            overrideConstants,
            legendsData,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <DashboardCard
            status={status}
            height={height!}
            loadingColor={Palette.white100}
            backgroundColor={Palette.white100}
            hoverColor={Palette.white100}
            grow={true}
            maxWidthOnGrow={"709px"}
            upper={<DashboardProfitChartHeaderView/>}
            lower={
                <DashboardCardColumn alignment={"center"}>
                    <ProfitChart
                        heightInPx={296}
                        data={chartData}
                        highlightedXIndices={highlightedXIndices}
                        highlightedXAxisIndices={highlightedXAxisIndices}
                        xAxis={xAxis}
                        yAxis={yAxis}
                        initialScrollPosition={
                            ChartInitialScrollPositionType.Right
                        }
                        overrideConstants={overrideConstants}
                        hoverEnabled={false}
                        showEmptyOverlay={false}
                    />
                    <SizedBox height={"8px"}/>
                    <RowContainer>
                        {legendsData.map((d) => (
                            <ChartLegendLabel
                                key={d.label}
                                color={d.color}
                                label={d.label}
                            />
                        ))}
                    </RowContainer>
                </DashboardCardColumn>
            }
        />
    );
};

export default DashboardProfitChartView;
