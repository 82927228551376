import {animated, useSpring} from "@react-spring/web";
import TransactionsFiguresType, {
    TransactionsFiguresTypeMap,
} from "domain/model/tax/transactions/transactions_figures_type";
import IconRim from "presentation/components/common/icon_rim";
import SizedBox from "presentation/components/common/sized_box";
import SVG from "presentation/components/common/svg";
import TransactionsListTileNumber
    from "presentation/components/list/transactions_list/components/transactions_list_tile_number";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import {useState} from "react";
import styled from "styled-components";

const LayoutContainer = styled.li.attrs<{ $hovered: boolean }>((props) => ({
    style: {
        backgroundColor: props.$hovered ? Palette.primary10 : Palette.white100,
    },
}))`
    width: 100%;
    padding: 4px;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
`;

const TitleContainer = styled.p`
    min-width: max-content;
    flex-grow: 1;
    ${Fonts.detail3Medium};
`;

const NumberContainer = styled(animated.div)`
    position: relative;
    background-color: ${Palette.white100};
    z-index: 1;
`;

const IconContainer = styled(animated.div)`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 0;
    transform: translate(-50%, -50%);
`;

const TransactionsListTile = ({
                                  type,
                                  value,
                                  onClick,
                              }: {
    type: TransactionsFiguresType;
    value: number;
    onClick: () => void;
}) => {
    const [hovered, setHovered] = useState(false);

    const numberProps = useSpring({
        transform: hovered ? "translateX(-28px)" : "translateX(0px)",
        backgroundColor: hovered ? Palette.none : Palette.white100,
    });
    const iconProps = useSpring({
        opacity: hovered ? 1 : 0,
        config: {
            mass: 0.3,
        },
    });

    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    return (
        <LayoutContainer
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            $hovered={hovered}
        >
            <IconRim iconWidth={"20px"} iconHeight={"20px"}>
                <SVG
                    asset={TransactionsFiguresTypeMap.asset(type)}
                    width={"20px"}
                    height={"20px"}
                    color={Palette.gray800}
                />
            </IconRim>
            <SizedBox width={"8px"}/>
            <TitleContainer>
                {TransactionsFiguresTypeMap.shortLabel(type)}
            </TitleContainer>
            <NumberContainer style={numberProps}>
                <TransactionsListTileNumber value={value}/>
            </NumberContainer>
            <IconContainer style={iconProps}>
                <SVG
                    asset={SVGAssets.Search}
                    width={"20px"}
                    height={"20px"}
                    color={Palette.primary500}
                />
            </IconContainer>
        </LayoutContainer>
    );
};

export default TransactionsListTile;
