import {createContext, PropsWithChildren} from "react";
import styled from "styled-components";

export const DashboardRowContext = createContext<{
    height?: string;
    contentWidth?: string;
    contentHeight?: string;
}>({
    height: undefined,
    contentWidth: undefined,
    contentHeight: undefined,
});

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    transition: gap 0.3s ease-in-out;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
    }
`;

const DashboardRow = ({
                          height,
                          children,
                          contentWidth,
                          contentHeight,
                      }: PropsWithChildren<{
    height?: string;
    contentWidth?: string;
    contentHeight?: string;
}>) => {
    return (
        <DashboardRowContext.Provider
            value={{height, contentWidth, contentHeight}}
        >
            <LayoutContainer>{children}</LayoutContainer>
        </DashboardRowContext.Provider>
    );
};

export default DashboardRow;
