import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";

enum BillingFiguresStatusType {
    Processing = "processing",
    Pending = "pending",
    Processed = "processed",
}

export const BillingFiguresStatusTypeMap = {
    label: (type: BillingFiguresStatusType): string => {
        switch (type) {
            case BillingFiguresStatusType.Processing:
                return S.billingFiguresStatusType.processingLabel;
            case BillingFiguresStatusType.Pending:
                return S.billingFiguresStatusType.pendingLabel;
            case BillingFiguresStatusType.Processed:
                return S.billingFiguresStatusType.processedLabel;
            default:
                throw new Error("Invalid BillingFiguresStatusType");
        }
    },
    color: (type: BillingFiguresStatusType): string => {
        switch (type) {
            case BillingFiguresStatusType.Processing:
                return Palette.gray500;
            case BillingFiguresStatusType.Pending:
                return Palette.red500;
            case BillingFiguresStatusType.Processed:
                return Palette.primary500;
            default:
                throw new Error("Invalid BillingFiguresStatusType");
        }
    },
    backgroundColor: (type: BillingFiguresStatusType): string => {
        switch (type) {
            case BillingFiguresStatusType.Processing:
                return Palette.gray100;
            case BillingFiguresStatusType.Pending:
                return Palette.red100;
            case BillingFiguresStatusType.Processed:
                return Palette.primary100;
            default:
                throw new Error("Invalid BillingFiguresStatusType");
        }
    },
    borderColor: (type: BillingFiguresStatusType): string => {
        switch (type) {
            case BillingFiguresStatusType.Processing:
                return Palette.gray300;
            case BillingFiguresStatusType.Pending:
                return Palette.red200;
            case BillingFiguresStatusType.Processed:
                return Palette.primary300;
            default:
                throw new Error("Invalid BillingFiguresStatusType");
        }
    },
};

export default BillingFiguresStatusType;
