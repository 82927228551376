import axios, {AxiosError} from "axios";
import DomainError from "domain/model/common/domain_error";
import ErrorCode from "domain/model/common/error_code";
import EnvironmentConstants from "config/environment_constants";
import {Subject} from "rxjs";
import secureStorageInstance from "data/local/secure_storage/secure_storage";
import devLog from "presentation/utils/functions/dev_log";
import DevLogType from "domain/model/common/dev_log_type";
import CommonError from "domain/model/common/common_error";

declare module "axios" {
    export interface AxiosRequestConfig {
        retried?: boolean;
    }
}

export type HTTPClient = ReturnType<typeof createHTTPClient>;

export const httpClientCancelSubject = new Subject<void>();

export function createHTTPClient() {
    const httpClient = axios.create({
        baseURL: EnvironmentConstants.apiURL,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Device: "Web",
        },
        withCredentials: true,
        timeout: 15 * 1000,
    });

    httpClient.interceptors.request.use((request) => {
        if (request.retried) {
            devLog({
                logType: DevLogType.Request,
                message: {
                    url: request.url ?? "null",
                    headers: request.headers ?? "null",
                    params: request.params ?? "null",
                    data: request.data ?? "null",
                },
            });
            return request;
        }

        const accessToken = secureStorageInstance.getAuthToken();
        if (accessToken) {
            request.headers.Authorization = `Bearer ${accessToken.accessToken}`;
        }

        devLog({
            logType: DevLogType.Request,
            message: {
                url: request.url ?? "null",
                headers: request.headers ?? "null",
                params: request.params ?? "null",
                data: request.data ?? "null",
            },
        });

        return request;
    });

    httpClient.interceptors.response.use(
        (response) => {
            devLog({
                logType: DevLogType.Response,
                message: {
                    status: response.status,
                    headers: response.headers ?? "null",
                    data: response.data ?? "null",
                },
            });
            return response;
        },
        async (error) => {
            devLog({
                logType: DevLogType.Error,
                message:
                    error.response && (error.response.data ?? error.response),
            });

            const errorData = error.response.data;
            if (errorData && errorData.code && errorData.message) {
                if (errorData.code === ErrorCode.TokenExpired) {
                    const authToken = secureStorageInstance.getAuthToken();
                    if (!authToken || error.config?.retried) {
                        secureStorageInstance.deleteAuthToken();
                        return Promise.reject(
                            new CommonError(
                                ErrorCode.TokenExpired,
                                "Refresh token expired"
                            )
                        );
                    }

                    const response = await httpClient.get(
                        "/crew/tokens/refresh",
                        {
                            headers: {
                                Authorization: `Bearer ${authToken.refreshToken}`,
                            },
                            retried: true,
                        }
                    );

                    const {accessToken, refreshToken} = response.data;
                    secureStorageInstance.setAuthToken({
                        accessToken,
                        refreshToken,
                    });

                    return httpClient.request({
                        ...error.config,
                        headers: {
                            ...error.config.headers,
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                }

                if (errorData.Code === ErrorCode.ServerSentError) {
                    const json = JSON.parse(errorData.message);
                    const title = json.title;
                    const message = json.message;

                    return Promise.reject(
                        new CommonError(
                            ErrorCode.ServerSentError,
                            title,
                            message
                        )
                    );
                }

                return Promise.reject(
                    new DomainError(errorData.code, errorData.message)
                );
            }

            if (
                error instanceof AxiosError &&
                error.code === AxiosError.ERR_CANCELED
            ) {
                return Promise.reject(
                    new DomainError(
                        ErrorCode.Cancelled,
                        "Error cancelled by user"
                    )
                );
            }

            if (error instanceof AxiosError) {
                return Promise.reject(
                    new DomainError(ErrorCode.NetworkError, "Network error")
                );
            }

            return Promise.reject(DomainError.unknown());
        }
    );

    return httpClient;
}

const httpClientInstance = createHTTPClient();

export default httpClientInstance;
