import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.li`
    min-width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    list-style: none;
`;

const IndicatorContainer = styled.div<{ $color: string }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.$color};
`;

const LabelContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.gray600};
`;

const ChartLegendLabel = ({
                              as,
                              color,
                              label,
                          }: {
    as?: string;
    color: string;
    label: string;
}) => {
    return (
        <LayoutContainer as={as}>
            <IndicatorContainer $color={color}/>
            <LabelContainer>{label}</LabelContainer>
        </LayoutContainer>
    );
};

export default ChartLegendLabel;
