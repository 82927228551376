export enum ChartInitialScrollPositionType {
    Left,
    Middle,
    Right,
}

export type ChartAxisData = {
    value: number;
    label: string;
};

export type ChartYAxisData = {
    yPosFromTop: number;
    label: string;
};

export type ChartXAxisData = {
    binWidthInPx: number;
    xPosFromLeft: number;
    label: string;
};
