import billingState from "presentation/states/billing/billing_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const billingStatusBoardSelector = selector({
    key: "billingStatusBoardSelector",
    get: ({get}) => {
        const state = get(billingState);

        return {
            taxStartDate: DateTime.fromKey(state.taxStartDateKey),
            selectedDateFilter: state.statusBoard.selectedDateFilter,
            startDate: DateTime.fromKey(state.statusBoard.startDateKey),
            endDate: DateTime.fromKey(state.statusBoard.endDateKey),
            selectedDropdownType: state.statusBoard.selectedDropdownType,
            dateSortType: state.statusBoard.dateSortType,
            billingAmountSortType: state.statusBoard.billingAmountSortType,
            data: state.statusBoard.data,
        };
    },
});

export default billingStatusBoardSelector;
