const downloadExternalFile = (url: string) => {
    let iframe = document.getElementById("ifrFile") as HTMLIFrameElement;
    if (!iframe) {
        iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.id = "ifrFile";
        document.body.appendChild(iframe);
    }
    iframe.src = url;
};

export default downloadExternalFile;
