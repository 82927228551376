import {animated, useTransition} from "@react-spring/web";
import ExternalSyncsRepository from "data/repository/external_syncs_repository";
import SizedBox from "presentation/components/common/sized_box";
import Dialog from "presentation/components/dialog/dialog";
import SVGAssets, {JSONAssets} from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import {optional} from "presentation/utils/types/optional";
import {useMemo, useRef, useState} from "react";
import Lottie from "react-lottie";
import styled from "styled-components";

const assetChangeIntervalInMS = 10_000;

const LayoutContainer = styled.div`
    width: 200px;
    height: 200px;
    position: relative;
`;

const LottieContainer = styled(animated.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
`;

const MessageContainer = styled.p`
    width: 100%;
    ${Fonts.detail3Medium};
    color: ${Palette.gray500};
    text-align: center;
    white-space: pre-wrap;
`;

const GetRecentDataDialog = () => {
    const repository = useRepository(ExternalSyncsRepository);
    const showNotificationDialog = useShowNotificationDialog();
    const dismissDialog = useDismissDialog();

    const assets = useMemo(
        () => [
            JSONAssets.GetRecentDataOne,
            JSONAssets.GetRecentDataTwo,
            JSONAssets.GetRecentDataThree,
            JSONAssets.GetRecentDataFour,
            JSONAssets.GetRecentDataFive,
            JSONAssets.GetRecentDataSix,
            JSONAssets.GetRecentDataSeven,
            JSONAssets.GetRecentDataEight,
        ],
        []
    );
    const timeoutRef = useRef<optional<NodeJS.Timeout>>(undefined);
    const [index, setIndex] = useState(0);

    useLifeCycle({
        onPreInit: () => {
            const onChange = () => {
                timeoutRef.current = setTimeout(() => {
                    setIndex((prev) => (prev + 1) % assets.length);
                    onChange();
                }, assetChangeIntervalInMS);
            };

            onChange();
        },
        onInit: () =>
            repository({
                handler: async (repository) => {
                    await repository.getRecentData();

                    showNotificationDialog({
                        title: S.getRecentDataDialog.successDialogTitle,
                        message: S.getRecentDataDialog.successDialogMessage,
                        hasNegativeButton: false,
                    });
                },
                onFinally: () => dismissDialog("get-recent-data-dialog"),
            }),
        onUnload: () => clearInterval(timeoutRef.current),
    });

    const transition = useTransition(index, {
        from: {opacity: 0, zIndex: 2},
        enter: {opacity: 1, zIndex: 2},
        leave: {opacity: 0, zIndex: 1},
    });

    return (
        <Dialog
            iconAsset={SVGAssets.CheckedDocument}
            title={S.getRecentDataDialog.title}
        >
            <LayoutContainer>
                {transition((props, index) => (
                    <LottieContainer style={props}>
                        <Lottie
                            width={200}
                            height={200}
                            options={{
                                animationData: assets.at(index),
                                loop: true,
                            }}
                            isStopped={false}
                            isClickToPauseDisabled={true}
                        />
                    </LottieContainer>
                ))}
            </LayoutContainer>
            <SizedBox height={"8px"}/>
            <MessageContainer>{S.getRecentDataDialog.message}</MessageContainer>
            <SizedBox height={"36px"}/>
        </Dialog>
    );
};

export default GetRecentDataDialog;
