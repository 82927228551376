import externalSyncsState from "presentation/states/external_syncs/external_syncs_state";
import {selector} from "recoil";

const externalSyncsHiraSelector = selector({
    key: "externalSyncsHiraSelector",
    get: ({get}) => {
        const state = get(externalSyncsState);

        return state.hira;
    },
});

export default externalSyncsHiraSelector;
