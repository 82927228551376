import StatisticNumberSmall from "presentation/components/statistic/statistic_number_small";
import StatisticTitleLarge from "presentation/components/statistic/statistic_title_large";
import DashboardCard from "presentation/pages/dashboard/components/dashboard_card";
import DashboardCardColumn from "presentation/pages/dashboard/components/dashboard_card_column";
import DashboardCardMoreButton from "presentation/pages/dashboard/components/dashboard_card_more_button";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import DashboardFiguresCardPendingListView
    from "presentation/pages/dashboard/view/figures/card_pending/dashboard_figures_card_pending_list_view";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import dashboardCardSalesSelector from "presentation/states/dashboard/selector/dashboard_card_sales_selector";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;
`;

const RightAlignContainer = styled.div`
    width: 100%;
    height: 2rem;
    position: relative;
`;

const DashboardFiguresCardPendingView = () => {
    const {height} = useContext(DashboardRowContext);

    const {selectedYearMonth} = useRecoilValue(dashboardDateSelector);
    const {status, data} = useRecoilValue(dashboardCardSalesSelector);

    const ref = useRef<HTMLDivElement>(null);
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        const element = ref.current;
        if (!element) return;

        const resizeObserver = new ResizeObserver(() => {
            const width = element.offsetWidth;

            setCollapsed(width < 320);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.unobserve(element);
    }, [ref]);

    const {total, count, list} = useMemo(() => {
        const filtered = data.filter((d) =>
            d.date.isSameMonth(selectedYearMonth)
        );
        const total = filtered.reduce((acc, cur) => acc + cur.pendingAmount, 0);
        const count = filtered.reduce((acc, cur) => acc + cur.pendingCount, 0);
        const list = filtered
            .filter((d) => !!d.pendingCount)
            .slice(-3)
            .map((d) => ({
                date: d.date,
                pending: d.pendingAmount,
                count: d.pendingCount,
                details: d.pendingDetails,
            }))
            .toReversed();

        return {
            total: data.length ? total : undefined,
            count: data.length ? count : undefined,
            list,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onClick = () => {
        const route = `${AppRoutes.CardSales}?scrollTo=statusBoard`;
        AppRouter.navigate(route);
    };

    return (
        <DashboardCard
            ref={ref}
            status={status}
            height={height!}
            loadingColor={Palette.white100}
            backgroundColor={Palette.white100}
            hoverColor={Palette.white100}
            grow={true}
            maxWidthOnGrow={"400px"}
            upper={
                <DashboardCardColumn>
                    <StatisticTitleLarge
                        title={S.dashboardPage.figures.cardPendingTitle}
                        color={Palette.gray800}
                    />
                    <RowContainer>
                        <StatisticNumberSmall
                            value={total}
                            unit={S.dashboardPage.wonUnit}
                            color={Palette.gray800}
                            unitColor={Palette.gray600}
                            rightMarginInPx={16}
                        />
                        <StatisticNumberSmall
                            value={count}
                            unit={S.dashboardPage.eaUnit}
                            color={Palette.gray800}
                            unitColor={Palette.gray600}
                        />
                    </RowContainer>
                </DashboardCardColumn>
            }
            lower={
                <DashboardCardColumn>
                    <RightAlignContainer>
                        <DashboardCardMoreButton onClick={onClick}/>
                    </RightAlignContainer>
                    <DashboardFiguresCardPendingListView
                        collapsed={collapsed}
                        history={list}
                    />
                </DashboardCardColumn>
            }
        />
    );
};

export default DashboardFiguresCardPendingView;
