import Palette from "presentation/theme/palette";
import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $clickable: boolean;
    $borderRadiusInPx: number;
    $roundLeft: boolean;
    $roundRight: boolean;
    $backgroundColor: string;
    $hoverColor: string;
    $boxShadow?: string;
}>((props) => ({
    style: {
        borderTopLeftRadius: props.$roundLeft
            ? `${props.$borderRadiusInPx}px`
            : 0,
        borderBottomLeftRadius: props.$roundLeft
            ? `${props.$borderRadiusInPx}px`
            : 0,
        borderTopRightRadius: props.$roundRight
            ? `${props.$borderRadiusInPx}px`
            : 0,
        borderBottomRightRadius: props.$roundRight
            ? `${props.$borderRadiusInPx}px`
            : 0,
        boxShadow: props.$boxShadow,
    },
}))`
    width: 100%;
    background-color: ${(props) => props.$backgroundColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: border-radius 0.3s ease-in-out,
        background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
        background-color: ${(props) =>
    props.$clickable ? props.$hoverColor : props.$backgroundColor};
    }
`;

const TableRowLayout = ({
                            className,
                            borderRadiusInPx = 0,
                            roundLeft = false,
                            roundRight = false,
                            backgroundColor = Palette.none,
                            hoverColor = Palette.none,
                            boxShadow,
                            onClick,
                            children,
                        }: PropsWithChildren<{
    className?: string;
    borderRadiusInPx?: number;
    roundLeft?: boolean;
    roundRight?: boolean;
    backgroundColor?: string;
    hoverColor?: string;
    boxShadow?: string;
    onClick?: () => void;
}>) => {
    return (
        <LayoutContainer
            className={className}
            onClick={onClick}
            $clickable={!!onClick}
            $borderRadiusInPx={borderRadiusInPx}
            $roundLeft={roundLeft}
            $roundRight={roundRight}
            $backgroundColor={backgroundColor}
            $hoverColor={hoverColor}
            $boxShadow={boxShadow}
        >
            {children}
        </LayoutContainer>
    );
};

export default TableRowLayout;
