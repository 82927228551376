import Fonts from "presentation/theme/fonts";
import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
`;

const TitleContainer = styled.p`
    ${Fonts.detail2};
`;

const ProfileTitledSection = ({
                                  title,
                                  children,
                              }: PropsWithChildren<{
    title: string;
}>) => {
    return (
        <LayoutContainer>
            <TitleContainer>{title}</TitleContainer>
            {children}
        </LayoutContainer>
    );
};

export default ProfileTitledSection;
