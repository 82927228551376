import {
    DateFilterPeriodCalendarContext
} from "presentation/components/button/date_filter_period_button/components/date_filter_period_calendar";
import DateFilterPeriodCalendarCell
    from "presentation/components/button/date_filter_period_button/components/date_filter_period_calendar_cell";
import CalendarWeekdayCell from "presentation/components/calendar/common/calendar_weekday_cell";
import Palette from "presentation/theme/palette";
import DateTime from "presentation/utils/extension/date_extension";
import {useContext, useMemo} from "react";
import styled from "styled-components";

export enum DateFilterPeriodCalendarMonthCallerType {
    Previous = "previous",
    Next = "next",
}

export const DateFilterPeriodCalendarMonthTypeCallerMap = {
    fromTranslateX: (type: DateFilterPeriodCalendarMonthCallerType): string => {
        switch (type) {
            case DateFilterPeriodCalendarMonthCallerType.Previous:
                return "-50%";
            case DateFilterPeriodCalendarMonthCallerType.Next:
                return "50%";
            default:
                throw new Error(
                    "Invalid DateFilterPeriodCalendarMonthCallerType"
                );
        }
    },
    leaveTranslateX: (
        type: DateFilterPeriodCalendarMonthCallerType
    ): string => {
        switch (type) {
            case DateFilterPeriodCalendarMonthCallerType.Previous:
                return "50%";
            case DateFilterPeriodCalendarMonthCallerType.Next:
                return "-50%";
            default:
                throw new Error(
                    "Invalid DateFilterPeriodCalendarMonthCallerType"
                );
        }
    },
};

const CalendarContainer = styled.div.attrs<{
    $widthInPx: number;
    $heightInPx: number;
    $weekCount: number;
    $weekdayHeaderHeightInPx: number;
    $cellSizeInPx: number;
    $cellHorizontalPaddingInPx: number;
    $rowGapInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
    },
}))`
    display: grid;
    grid-template-rows: ${(props) =>
    `${props.$weekdayHeaderHeightInPx}px repeat(${props.$weekCount}, ${props.$cellSizeInPx}px)`};
    grid-template-columns: ${(props) =>
    `${
        props.$cellSizeInPx + props.$cellHorizontalPaddingInPx
    }px repeat(5, ${
        props.$cellSizeInPx + props.$cellHorizontalPaddingInPx * 2
    }px) ${props.$cellSizeInPx + props.$cellHorizontalPaddingInPx}px`};
    row-gap: ${(props) => `${props.$rowGapInPx}px`};
    column-gap: 0px;
    background-color: ${Palette.white100};
    transition: height 0.3s ease-in-out;
`;

const DateFilterPeriodCalendarMonth = ({month}: { month: DateTime }) => {
    const context = useContext(DateFilterPeriodCalendarContext);
    const {heightInPx, weekdays, weeks} = useMemo(() => {
        const weekdays = DateTime.orthodoxWeekdays;
        const weeks = month.getOrthodoxWeeksInMonth();
        const heightInPx =
            context.constants.weekdayHeaderHeightInPx +
            weeks.length *
            (context.constants.cellSizeInPx + context.constants.rowGapInPx);

        return {
            heightInPx,
            weekdays,
            weeks,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month.key, context.constantsKey]);

    const weekdayCellWidth = "100%";
    const weekdayCellHeight = `${context.constants.weekdayHeaderHeightInPx}px`;

    return (
        <CalendarContainer
            $widthInPx={context.constants.monthWidthInPx}
            $heightInPx={heightInPx}
            $weekCount={weeks.length}
            $weekdayHeaderHeightInPx={context.constants.weekdayHeaderHeightInPx}
            $cellSizeInPx={context.constants.cellSizeInPx}
            $cellHorizontalPaddingInPx={
                context.constants.cellHorizontalPaddingInPx
            }
            $rowGapInPx={context.constants.rowGapInPx}
        >
            {weekdays.map((weekday) => (
                <CalendarWeekdayCell
                    key={weekday}
                    width={weekdayCellWidth}
                    height={weekdayCellHeight}
                    weekday={weekday}
                />
            ))}
            {weeks.map((week) =>
                week.map((date) => (
                    <DateFilterPeriodCalendarCell
                        key={date.key}
                        month={month}
                        date={date}
                    />
                ))
            )}
        </CalendarContainer>
    );
};

export default DateFilterPeriodCalendarMonth;
