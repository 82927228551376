import {ProfitReportDateFilterTypeMap} from "domain/model/tax/profit_report/profit_report_date_filter_type";
import ContentSectionTitle from "presentation/components/content/content_section_title";
import profitReportProfitStatusBoardSelector
    from "presentation/states/profit_report/selector/profit/status_board/profit_report_status_board_selector";
import S from "presentation/theme/s";
import {useRecoilValue} from "recoil";

const ProfitReportProfitStatusBoardDonutChartTitleView = () => {
    const {selectedDateFilter} = useRecoilValue(
        profitReportProfitStatusBoardSelector
    );

    const title = selectedDateFilter
        ? S.profitReportPage.profit.statusBoard.donutChart.titleWithDateFilter(
            ProfitReportDateFilterTypeMap.label(selectedDateFilter)
        )
        : S.profitReportPage.profit.statusBoard.donutChart.title;

    return <ContentSectionTitle title={title}/>;
};

export default ProfitReportProfitStatusBoardDonutChartTitleView;
