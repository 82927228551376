import {transactionsState} from "presentation/states/transactions/transactions_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const transactionsTableSelector = selector({
    key: "transactionsTableSelector",
    get: ({get}) => {
        const state = get(transactionsState);

        return {
            taxStartDate: DateTime.fromKey(state.taxStartDateKey),
            selectedDateFilter: state.table.selectedDateFilter,
            startDate: DateTime.fromKey(state.table.startDateKey),
            endDate: DateTime.fromKey(state.table.endDateKey),
            dateSortType: state.table.dateSortType,
            totalSortType: state.table.totalSortType,
            data: state.table.data,
        };
    },
});

export default transactionsTableSelector;
