import ExternalSyncsRepository from "data/repository/external_syncs_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import Page from "presentation/components/common/page";
import ExternalSyncsRow from "presentation/pages/external_syncs/components/external_syncs_row";
import ExternalSyncsRowItem from "presentation/pages/external_syncs/components/external_syncs_row_item";
import ExternalSyncsCrefiaView from "presentation/pages/external_syncs/view/external_syncs_crefia_view";
import ExternalSyncsInsuranceView from "presentation/pages/external_syncs/view/external_syncs_insurance_view";
import ExternalSyncsFetchButtonView from "presentation/pages/external_syncs/view/external_syncs_fetch_button_view";
import ExternalSyncsHiraView from "presentation/pages/external_syncs/view/external_syncs_hira_view";
import ExternalSyncsHomeTaxView from "presentation/pages/external_syncs/view/external_syncs_home_tax_view";
import {ExternalSyncsRefresh} from "presentation/states/external_syncs/external_syncs_listenable_state";
import externalSyncsState, {
    externalSyncsListenableState,
} from "presentation/states/external_syncs/external_syncs_state";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import {useListenableState} from "presentation/utils/hooks/use_listenable_state";
import useRepository from "presentation/utils/hooks/use_repository";
import {useState} from "react";
import {useSetRecoilState} from "recoil";
import styled from "styled-components";

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
`;

const ExternalSyncsPage = () => {
    const repository = useRepository(ExternalSyncsRepository);

    const setState = useSetRecoilState(externalSyncsState);

    const [status, setStatus] = useState(ViewStatusType.Loading);

    useListenableState(externalSyncsListenableState, (state) => {
        if (state instanceof ExternalSyncsRefresh) {
            repository({
                handler: async (repository) => {
                    const externalSync = await repository.getExternalSync();

                    setState((prev) => ({
                        ...prev,
                        homeTax: externalSync.homeTax,
                        crefia: externalSync.crefia,
                        hira: externalSync.hira,
                        insurance: externalSync.insurance,
                    }));
                },
            });
        }
    });

    useLifeCycle({
        onInit: () =>
            repository({
                handler: async (repository) => {
                    const externalSync = await repository.getExternalSync();

                    setState((prev) => ({
                        ...prev,
                        homeTax: externalSync.homeTax,
                        crefia: externalSync.crefia,
                        hira: externalSync.hira,
                        insurance: externalSync.insurance,
                    }));

                    setStatus(ViewStatusType.Loaded);
                },
            }),
    });

    return (
        <Page status={status}>
            <ColumnContainer>
                <ExternalSyncsRow>
                    <ExternalSyncsFetchButtonView/>
                </ExternalSyncsRow>
                <ExternalSyncsRow>
                    <ExternalSyncsRowItem>
                        <ExternalSyncsHomeTaxView/>
                    </ExternalSyncsRowItem>
                    <ExternalSyncsRowItem>
                        <ExternalSyncsCrefiaView/>
                    </ExternalSyncsRowItem>
                </ExternalSyncsRow>
                <ExternalSyncsRow>
                    <ExternalSyncsRowItem>
                        <ExternalSyncsHiraView/>
                    </ExternalSyncsRowItem>
                    <ExternalSyncsRowItem>
                        <ExternalSyncsInsuranceView/>
                    </ExternalSyncsRowItem>
                </ExternalSyncsRow>
            </ColumnContainer>
        </Page>
    );
};

export default ExternalSyncsPage;
