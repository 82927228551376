import {animated, useSprings} from "@react-spring/web";
import {ProfitChartContext} from "presentation/components/charts/profit_chart/profit_chart";
import Palette from "presentation/theme/palette";
import {Fragment, useContext, useEffect, useMemo} from "react";
import styled from "styled-components";

const BarBackgroundContainer = styled(animated.div).attrs<{
    $widthInPx: number;
    $heightInPx: number;
    $topOffsetInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
        top: `${props.$topOffsetInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
    },
}))`
    position: absolute;
    z-index: 0;
    transform: translateX(-50%);
    transition: height 0.3s ease-in-out;
`;

const BarRimContainer = styled.div.attrs<{
    $hovered: boolean;
    $widthInPx: number;
    $heightInPx: number;
    $upperBorderRadiusInPx: number;
    $lowerBorderRadiusInPx: number;
    $topOffsetInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
        backgroundColor: props.$hovered ? Palette.primary15 : Palette.none,
        borderTopLeftRadius: props.$upperBorderRadiusInPx,
        borderTopRightRadius: props.$upperBorderRadiusInPx,
        borderBottomLeftRadius: props.$lowerBorderRadiusInPx,
        borderBottomRightRadius: props.$lowerBorderRadiusInPx,
        top: `${props.$topOffsetInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
        zIndex: 0,
    },
}))`
    position: absolute;
    pointer-events: none;
    transform: translateX(-50%);
    transition: height 0.3s ease-in-out, background-color 0.3s ease-in-out,
        border-radius 0.3s ease-in-out, top 0.3s ease-in-out,
        left 0.3s ease-in-out;
`;

const BarContainer = styled(animated.div).attrs<{
    $barReversed: boolean;
    $widthInPx: number;
    $borderRadiusInPx: number;
    $color: string;
    $topOffsetInPx: number;
    $leftOffsetInPx: number;
    $bottomOffsetInPx: number;
    $zIndex: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        backgroundColor: props.$color,
        borderTopLeftRadius: `${
            props.$barReversed ? 0 : props.$borderRadiusInPx
        }px`,
        borderTopRightRadius: `${
            props.$barReversed ? 0 : props.$borderRadiusInPx
        }px`,
        borderBottomLeftRadius: `${
            props.$barReversed ? props.$borderRadiusInPx : 0
        }px`,
        borderBottomRightRadius: `${
            props.$barReversed ? props.$borderRadiusInPx : 0
        }px`,
        top: props.$barReversed ? `${props.$topOffsetInPx}px` : "auto",
        left: `${props.$leftOffsetInPx}px`,
        bottom: props.$barReversed ? "auto" : `${props.$bottomOffsetInPx}px`,
        zIndex: props.$zIndex,
    },
}))`
    position: absolute;
    pointer-events: none;
    transform: translateX(-50%);
    transition: max-height 0.3s ease-in-out, background-color 0.3s ease-in-out,
        border-radius 0.3s ease-in-out, top 0.3s ease-in-out,
        left 0.3s ease-in-out;
`;

const ProfitChartBars = () => {
    const context = useContext(ProfitChartContext);
    const data = useMemo(
        () =>
            context.data.map((d) => {
                const xPos = context.functions.getXPos(d.x);
                const yValues = [d.y1, d.y2, d.y3];
                const yPositions = yValues.map((y) =>
                    context.functions.getBarYPosFromTop(y.value)
                );
                const yPositionsFromBottom = yValues.map((y) =>
                    context.functions.getBarYPosFromBottom(y.value)
                );
                const heights = yPositions.map((pos) =>
                    Math.abs(pos.start - pos.end)
                );
                const visibleHeights = yValues.map((_, index) =>
                    index === yValues.length - 1
                        ? Math.abs(heights[index] - heights[index - 1])
                        : heights[index]
                );
                const borderRadii = yValues.map((y, index) =>
                    y.borderRounded
                        ? visibleHeights[index] >=
                        context.constants.barBorderRadiusInPx
                            ? context.constants.barBorderRadiusInPx
                            : visibleHeights[index]
                        : 0
                );
                const yPos =
                    yPositions.at(0)!.end - context.constants.barRimPaddingInPx;
                const height =
                    heights.at(0)! +
                    heights.at(-1)! +
                    context.constants.barRimPaddingInPx * 2;
                const upperBorderRadius =
                    borderRadii.at(0)! >= context.constants.barBorderRadiusInPx
                        ? borderRadii.at(0)! +
                        context.constants.barRimPaddingInPx
                        : borderRadii.at(0)!;
                const lowerBorderRadius =
                    borderRadii.at(-1)! >= context.constants.barBorderRadiusInPx
                        ? borderRadii.at(-1)! +
                        context.constants.barRimPaddingInPx
                        : borderRadii.at(-1)!;

                return {
                    key: d.x,
                    x: d.x,
                    pos: xPos,
                    yPos,
                    height: height,
                    upperBorderRadius,
                    lowerBorderRadius,
                    yData: yValues.map((y, index) => {
                        return {
                            key: `${d.x}-${y.value}`,
                            barReversed: context.functions.barReversed(y.value),
                            startPos: yPositions[index].start,
                            endPos: yPositions[index].end,
                            endPosFromBottom: yPositionsFromBottom[index].end,
                            xPos: xPos,
                            pos: y.value,
                            color: y.color,
                            height: heights[index],
                            visibleHeight: visibleHeights[index],
                            zIndex: Math.abs(yValues.length - index),
                            borderRadiusInPx: borderRadii[index],
                        };
                    }),
                };
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [context.data, context.constantsKey]
    );

    const [springs, api] = useSprings(data.length * 3, () => ({
        height: "0px",
    }));

    useEffect(() => {
        api.start((index) => ({
            height: `${data[Math.floor(index / 3)].yData[index % 3].height}px`,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.data, context.constantsKey]);

    const onMouseEnter = (index: number) => () =>
        context.setHoveredXIndex?.(index);
    const onMouseLeave = () => context.setHoveredXIndex?.(undefined);

    return (
        <>
            {data.map((x, index) => {
                const hovered = context.hoveredXIndex === index;

                return (
                    <Fragment key={x.key}>
                        <BarBackgroundContainer
                            onMouseEnter={onMouseEnter(index)}
                            onMouseLeave={onMouseLeave}
                            $widthInPx={context.constants.binSizeInPx}
                            $topOffsetInPx={context.constants.topMarginInPx}
                            $heightInPx={context.constants.innerHeightInPx}
                            $leftOffsetInPx={x.pos}
                        />
                        <BarRimContainer
                            $hovered={hovered}
                            $widthInPx={
                                context.constants.binSizeInPx +
                                context.constants.barRimPaddingInPx * 2
                            }
                            $heightInPx={x.height}
                            $upperBorderRadiusInPx={x.upperBorderRadius}
                            $lowerBorderRadiusInPx={x.lowerBorderRadius}
                            $topOffsetInPx={x.yPos}
                            $leftOffsetInPx={x.pos}
                        />
                    </Fragment>
                );
            })}
            {springs.map((props, index) => {
                const xIndex = Math.floor(index / 3);
                const x = data[xIndex];
                const y = x.yData[index % 3];

                return (
                    <BarContainer
                        key={y.key}
                        style={props}
                        $barReversed={y.barReversed}
                        $widthInPx={context.constants.binSizeInPx}
                        $borderRadiusInPx={y.borderRadiusInPx}
                        $color={y.color}
                        $topOffsetInPx={y.endPos}
                        $leftOffsetInPx={y.xPos}
                        $bottomOffsetInPx={y.endPosFromBottom}
                        $zIndex={y.zIndex}
                    />
                );
            })}
        </>
    );
};

export default ProfitChartBars;
