import {TransactionsFiguresTypeMap} from "domain/model/tax/transactions/transactions_figures_type";
import {TransactionsTypeMap} from "domain/model/tax/transactions/transactions_type";
import SpacedDivider from "presentation/components/common/spaced_divider";
import TransactionsTableSummaryItem from "presentation/pages/transactions/components/transactions_table_summary_item";
import {TransactionsPageContext} from "presentation/pages/transactions/transactions_page";
import transactionsTableSelector from "presentation/states/transactions/selector/transactions_table_selector";
import S from "presentation/theme/s";
import {Fragment, useContext, useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const height = "4.9375rem";
const horizontalMargin = "0px";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: gap 0.3s ease-in-out;
    gap: 32px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
    }
`;

const TransactionsTableSummaryView = () => {
    const {transactionsType} = useContext(TransactionsPageContext);
    const {data} = useRecoilValue(transactionsTableSelector);

    const _data = useMemo(() => {
        const types = [
            undefined,
            ...TransactionsTypeMap.transactionsFiguresTypes(transactionsType!),
        ].map((t) => ({
            type: t,
            value: 0,
            color:
                t !== undefined
                    ? TransactionsFiguresTypeMap.tableColor(t!)
                    : undefined,
        }));

        data.forEach((d) => {
            for (let i = 0; i < types.length; i++) {
                types[i].value += d.values[i];
            }
        });

        return types.flatMap((t) => [undefined, t]).slice(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <LayoutContainer>
            {_data.map((d, index) => (
                <Fragment key={index}>
                    {d !== undefined ? (
                        <TransactionsTableSummaryItem
                            height={height}
                            color={d.color}
                            title={
                                d.type !== undefined
                                    ? S.transactionsPage.table.summaryItemTitle(
                                        TransactionsFiguresTypeMap.shortLabel(
                                            d.type
                                        ),
                                        TransactionsTypeMap.shortLabel(
                                            transactionsType!
                                        )
                                    )
                                    : S.transactionsPage.table.summaryTotalTitle(
                                        TransactionsTypeMap.shortLabel(
                                            transactionsType!
                                        )
                                    )
                            }
                            value={d.value ? d.value : undefined}
                        />
                    ) : (
                        <SpacedDivider
                            height={height}
                            horizontalMargin={horizontalMargin}
                        />
                    )}
                </Fragment>
            ))}
        </LayoutContainer>
    );
};

export default TransactionsTableSummaryView;
