import CardSalesRepository from "data/repository/card_sales_repository";
import ProfitReportRepository from "data/repository/profit_report_repository";
import UserRepository from "data/repository/user_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import Page from "presentation/components/common/page";
import SizedBox from "presentation/components/common/sized_box";
import DashboardHeaderView from "presentation/pages/dashboard/view/dashboard_header_view";
import DashboardDispensingView from "presentation/pages/dashboard/view/dispensing/dashboard_dispensing_view";
import DashboardFiguresView from "presentation/pages/dashboard/view/figures/dashboard_figures_view";
import DashboardOtherFiguresView from "presentation/pages/dashboard/view/other_figures/dashboard_other_figures_view";
import DashboardProfitView from "presentation/pages/dashboard/view/profit/dashboard_profit_view";
import DashboardTransactionsView from "presentation/pages/dashboard/view/transactions/dashboard_transactions_view";
import dashboardState from "presentation/states/dashboard/dashboard_state";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import useRepository from "presentation/utils/hooks/use_repository";
import {useEffect} from "react";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";

const DashboardPage = () => {
    const userRepository = useRepository(UserRepository);
    const cardSalesRepository = useRepository(CardSalesRepository);
    const profitReportRepository = useRepository(ProfitReportRepository);
    const isMobile = useMobileQuery();

    const {previousYearMonth, selectedYearMonth} = useRecoilValue(
        dashboardDateSelector
    );
    const setState = useSetRecoilState(dashboardState);
    const resetState = useResetRecoilState(dashboardState);

    useLifeCycle({
        onDispose: () => resetState(),
    });

    useEffect(() => {
        (async () => {
            const startDate = selectedYearMonth
                .copyWith({
                    month: selectedYearMonth.month - 5,
                })
                .getFirstDayInMonth();
            const endDate = selectedYearMonth.getLastDayInMonth();

            const isAuthenticated = await userRepository({
                handler: async (repository) => {
                    return await repository.isAuthenticated();
                },
            });

            if (!isAuthenticated) return;

            await profitReportRepository({
                handler: async (repository) => {
                    const dispensingData =
                        await repository.getDispensingMonthlyFiguresInRange({
                            startDate,
                            endDate,
                        });

                    setState((prev) => ({
                        ...prev,
                        dispensing: {
                            ...prev.dispensing,
                            status: ViewStatusType.Loaded,
                            startDateKey: startDate.key,
                            endDateKey: endDate.key,
                            data: dispensingData,
                        },
                    }));

                    const profitData =
                        await repository.getProfitReportMonthlyFilteredFiguresInRange(
                            {
                                startDate,
                                endDate,
                            }
                        );

                    setState((prev) => ({
                        ...prev,
                        profit: {
                            ...prev.profit,
                            status: ViewStatusType.Loaded,
                            startDateKey: startDate.key,
                            endDateKey: endDate.key,
                            data: profitData,
                        },
                    }));
                },
            });

            await cardSalesRepository({
                handler: async (repository) => {
                    const startDate = previousYearMonth.getFirstDayInMonth();
                    const endDate = selectedYearMonth.getLastDayInMonth();

                    const results = await repository.getDailyFiguresInRange({
                        startDate,
                        endDate,
                    });

                    const cardSalesData = results ?? [];

                    setState((prev) => ({
                        ...prev,
                        cardSales: {
                            ...prev.cardSales,
                            status: ViewStatusType.Loaded,
                            startDateKey: startDate.key,
                            endDateKey: endDate.key,
                            data: cardSalesData,
                        },
                    }));
                },
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYearMonth.key]);

    const minWidth = isMobile ? "100%" : "744px";

    return (
        <>
            <DashboardHeaderView/>
            <Page minWidth={minWidth}>
                <DashboardDispensingView/>
                <SizedBox height={"16px"}/>
                <DashboardFiguresView/>
                <SizedBox height={"16px"}/>
                <DashboardProfitView/>
                <SizedBox height={"16px"}/>
                <DashboardOtherFiguresView/>
                <SizedBox height={"16px"}/>
                <DashboardTransactionsView/>
            </Page>
        </>
    );
};

export default DashboardPage;
