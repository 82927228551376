import Dot from "presentation/components/common/dot";
import SizedBox from "presentation/components/common/sized_box";
import StatisticNumberLarge from "presentation/components/statistic/statistic_number_large";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {ReactElement} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

const TitleLayoutContainer = styled.div`
    min-height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const TitleContainer = styled.p`
    ${Fonts.detail3Medium};
    color: ${Palette.gray600};
`;

const ProfitReportStatusBoardSummaryItem = ({
                                                color,
                                                title,
                                                value,
                                                icon,
                                                infoButton,
                                            }: {
    color: string;
    title: string;
    value?: number;
    icon?: ReactElement;
    infoButton?: ReactElement;
}) => {
    return (
        <LayoutContainer>
            {icon}
            {icon && <SizedBox width={"16px"}/>}
            <ItemContainer>
                <TitleLayoutContainer>
                    <Dot color={color}/>
                    <TitleContainer>{title}</TitleContainer>
                    {infoButton}
                </TitleLayoutContainer>
                <StatisticNumberLarge
                    value={value}
                    unit={S.profitReportProfitStatusBoardSummaryItem.wonUnit}
                    color={Palette.gray800}
                    unitColor={Palette.gray600}
                />
            </ItemContainer>
        </LayoutContainer>
    );
};

export default ProfitReportStatusBoardSummaryItem;
