import BillingFiguresStatusDropdownType, {
    BillingFiguresStatusDropdownTypeMap,
} from "domain/model/tax/billing/billing_figures_status_dropdown_type";
import DropdownButton from "presentation/components/button/dropdown_button/dropdown_button";
import ContentSectionTitle from "presentation/components/content/content_section_title";
import billingState from "presentation/states/billing/billing_state";
import billingStatusBoardSelector from "presentation/states/billing/selector/billing_status_board_selector";
import S from "presentation/theme/s";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import {useMemo} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";

const BillingStatusBoardTableHeaderView = () => {
    const isMobile = useMobileQuery();

    const {selectedDropdownType} = useRecoilValue(billingStatusBoardSelector);
    const setState = useSetRecoilState(billingState);

    const types = useMemo(
        () => Object.values(BillingFiguresStatusDropdownType),
        []
    );

    const labels = useMemo(() => {
        if (isMobile) {
            return types.map((t) =>
                BillingFiguresStatusDropdownTypeMap.shortLabel(t)
            );
        }

        return types.map((t) => BillingFiguresStatusDropdownTypeMap.label(t));
    }, [types, isMobile]);

    const selectedIndex = types.findIndex((t) => t === selectedDropdownType);

    const onSelectedIndexChange = (index: number) =>
        setState((prev) => ({
            ...prev,
            statusBoard: {
                ...prev.statusBoard,
                selectedDropdownType: types[index],
            },
        }));

    return (
        <ContentSectionTitle
            title={S.billingPage.statusBoard.table.title}
            suffix={
                <DropdownButton
                    minWidthInPx={100}
                    maxWidthInPx={164}
                    selectedIndex={selectedIndex}
                    onSelectedIndexChange={onSelectedIndexChange}
                    labels={labels}
                />
            }
        />
    );
};

export default BillingStatusBoardTableHeaderView;
