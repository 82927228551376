import {useSpring} from "@react-spring/web";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";
import {useEffect, useLayoutEffect, useRef} from "react";

const useFadeInTransition = (key: string) => {
    const ref = useRef(false);

    const [props, api] = useSpring(() => ({
        opacity: 1,
    }));

    useLayoutEffect(() => {
        if (!ref.current) return;

        api.set({opacity: 0});
        api.start({opacity: 1});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    useEffect(() => {
        addPostFrameCallback(() => (ref.current = true));
    }, []);

    return {props: ref.current ? props : undefined, api};
};

export default useFadeInTransition;
