import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import Debouncer from "presentation/utils/debouncer/debouncer";
import {useRef} from "react";
import styled from "styled-components";

type FilledButtonType = "primary" | "black";
type FilledButtonSizeType = "large" | "medium" | "small";

const colorPresets: Record<
    FilledButtonType,
    {
        backgroundColor: string;
        disabledBackgroundColor: string;
        hoverColor: string;
        color: string;
        disabledColor: string;
    }
> = {
    primary: {
        backgroundColor: Palette.none,
        disabledBackgroundColor: Palette.none,
        hoverColor: Palette.gray100,
        color: Palette.primary500,
        disabledColor: Palette.gray400,
    },
    black: {
        backgroundColor: Palette.none,
        disabledBackgroundColor: Palette.none,
        hoverColor: Palette.gray100,
        color: Palette.gray800,
        disabledColor: Palette.gray400,
    },
};

const sizePresets: Record<
    FilledButtonSizeType,
    {
        font: string;
        padding: string;
    }
> = {
    large: {
        font: Fonts.body1,
        padding: "16px",
    },
    medium: {
        font: Fonts.detail2,
        padding: "10px 16px",
    },
    small: {
        font: Fonts.detail1,
        padding: "6px 12px",
    },
};

const LayoutContainer = styled.button.attrs<{
    $enabled: boolean;
    $width: string;
    $padding: string;
    $backgroundColor: string;
    $disabledBackgroundColor: string;
    $hoverColor: string;
}>((props) => ({
    style: {
        width: props.$width,
        padding: props.$padding,
    },
}))`
    border: none;
    border-radius: 12px;
    background-color: ${(props) =>
    props.$enabled
        ? props.$backgroundColor
        : props.$disabledBackgroundColor};
    cursor: ${(props) => (props.$enabled ? "pointer" : "not-allowed")};
    transition: width 0.3s ease-in-out, padding 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

    &:hover {
        background-color: ${(props) =>
    props.$enabled
        ? props.$hoverColor
        : props.$disabledBackgroundColor};
    }
`;

const LabelContainer = styled.p.attrs<{
    $enabled: boolean;
    $font: string;
    $color: string;
    $disabledColor: string;
}>((props) => ({
    style: {
        color: props.$enabled ? props.$color : props.$disabledColor,
    },
}))`
    ${(props) => props.$font};
    text-align: center;
    transition: color 0.3s ease-in-out;
`;

const TextButton = ({
                        type = "primary",
                        sizeType = "large",
                        enabled = true,
                        width = "auto",
                        label,
                        color,
                        disabledColor,
                        debouncerDelayInMs = 50,
                        onClick,
                    }: {
    type?: FilledButtonType;
    sizeType?: FilledButtonSizeType;
    enabled?: boolean;
    width?: string;
    label: string;
    color?: string;
    disabledColor?: string;
    debouncerDelayInMs?: number;
    onClick?: () => void;
}) => {
    const debouncerRef = useRef(new Debouncer());

    const _onClick = () =>
        debouncerRef.current.run(() => onClick?.(), debouncerDelayInMs);

    const backgroundColor = colorPresets[type].backgroundColor;
    const disabledBackgroundColor = colorPresets[type].disabledBackgroundColor;
    const hoverColor = colorPresets[type].hoverColor;
    const _color = color ?? colorPresets[type].color;
    const _disabledColor = disabledColor ?? colorPresets[type].disabledColor;
    const font = sizePresets[sizeType].font;
    const padding = sizePresets[sizeType].padding;

    return (
        <LayoutContainer
            onClick={enabled ? _onClick : undefined}
            $enabled={enabled}
            $width={width}
            $padding={padding}
            $backgroundColor={backgroundColor}
            $disabledBackgroundColor={disabledBackgroundColor}
            $hoverColor={hoverColor}
        >
            <LabelContainer
                $enabled={enabled}
                $font={font}
                $color={_color}
                $disabledColor={_disabledColor}
            >
                {label}
            </LabelContainer>
        </LayoutContainer>
    );
};

export default TextButton;
