import BaseRepository from "data/repository/base_repository";
import DispensingFigures from "domain/model/tax/profit_report/dispensing_figures";
import ProfitReportFigures from "domain/model/tax/profit_report/profit_report_figures";
import ProfitReportFiguresDetailType from "domain/model/tax/profit_report/profit_report_figures_detail_type";
import ProfitReportFiguresType, {
    ProfitReportFiguresTypeMap,
} from "domain/model/tax/profit_report/profit_report_figures_type";
import ProfitReportFilteredFigures from "domain/model/tax/profit_report/profit_report_filtered_figures";
import DateTime, {DateDifferenceUnitType,} from "presentation/utils/extension/date_extension";

export default class ProfitReportRepository extends BaseRepository {
    getProfitReportMonthlyFiguresInRange = async ({
                                                      startDate,
                                                      endDate,
                                                  }: {
        startDate: DateTime;
        endDate: DateTime;
    }): Promise<ProfitReportFigures[]> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                if (Math.random() < 0.1) return [];

                let pointer = startDate.getFirstDayInMonth();
                const dates = [];
                while (pointer.isSameDayOrBefore(endDate)) {
                    dates.push(pointer);
                    pointer = pointer.copyWith({
                        month: pointer.month + 1,
                    });
                }

                return dates
                    .map((date) => {
                        if (Math.random() < 0.15) return undefined;

                        const cardSales = Math.floor(
                            Math.random() * 500_000_000
                        );
                        const cashSales = Math.floor(
                            Math.random() * 100_000_000
                        );
                        const dispensingSales = Math.floor(
                            Math.random() * 300_000_000
                        );
                        const cardPurchases = Math.floor(
                            Math.random() * cardSales
                        );
                        const cashPurchases = Math.floor(
                            Math.random() * cashSales
                        );
                        const electronicTaxInvoicePurchases = Math.floor(
                            Math.random() * dispensingSales
                        );
                        const personnelCosts = Math.floor(
                            Math.random() * 70_000_000
                        );
                        const welfareCosts = Math.floor(
                            Math.random() * 10_000_000
                        );
                        const otherCosts = Math.floor(
                            Math.random() * 30_000_000
                        );

                        const values = [
                            cardSales,
                            cashSales,
                            dispensingSales,
                            cardPurchases,
                            cashPurchases,
                            electronicTaxInvoicePurchases,
                            personnelCosts,
                            welfareCosts,
                            otherCosts,
                        ];

                        const figures = Object.values(
                            ProfitReportFiguresDetailType
                        ).map((t, index) => ({
                            type: t,
                            value: values[index],
                        }));

                        return {
                            date,
                            figures,
                        };
                    })
                    .filter((d) => d !== undefined)
                    .map((d) => d!);
            },
        });

    getProfitReportMonthlyFilteredFiguresInRange = async ({
                                                              startDate,
                                                              endDate,
                                                          }: {
        startDate: DateTime;
        endDate: DateTime;
    }): Promise<ProfitReportFilteredFigures[]> => {
        const figures = await this.getProfitReportMonthlyFiguresInRange({
            startDate,
            endDate,
        });

        return figures.map((f) => {
            const salesFigures =
                ProfitReportFiguresTypeMap.profitReportFiguresDetailTypes(
                    ProfitReportFiguresType.Sales
                ).map((t) => f.figures.find((d) => d.type === t)?.value ?? 0);
            const purchasesFigures =
                ProfitReportFiguresTypeMap.profitReportFiguresDetailTypes(
                    ProfitReportFiguresType.Purchases
                ).map((t) => f.figures.find((d) => d.type === t)?.value ?? 0);
            const otherExpensesFigures =
                ProfitReportFiguresTypeMap.profitReportFiguresDetailTypes(
                    ProfitReportFiguresType.OtherExpenses
                ).map((t) => f.figures.find((d) => d.type === t)?.value ?? 0);

            const sales = [
                salesFigures.reduce((acc, cur) => acc + cur, 0),
                ...salesFigures,
            ];
            const purchases = [
                purchasesFigures.reduce((acc, cur) => acc + cur, 0),
                ...purchasesFigures,
            ];
            const otherExpenses = [
                otherExpensesFigures.reduce((acc, cur) => acc + cur, 0),
                ...otherExpensesFigures,
            ];

            const profit = sales[0] - purchases[0] - otherExpenses[0];

            return {
                date: f.date,
                sales,
                purchases,
                otherExpenses,
                profit,
            };
        });
    };

    getDispensingDailyFiguresInRange = async ({
                                                  startDate,
                                                  endDate,
                                              }: {
        startDate: DateTime;
        endDate: DateTime;
    }): Promise<DispensingFigures[]> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                if (Math.random() < 0.1) return [];

                const difference =
                    endDate.subtract(startDate, DateDifferenceUnitType.Day) + 1;

                return Array.from(
                    {
                        length: difference,
                    },
                    (_, index) => {
                        const date = startDate.copyWith({
                            day: startDate.day + index,
                        });

                        if (Math.random() < 0.15) return undefined;

                        const dispensingAmount = Math.floor(
                            Math.random() * 100_000_000
                        );
                        const billingCount = Math.floor(
                            1 + Math.random() * 100
                        );

                        return {
                            date,
                            dispensingAmount,
                            billingCount,
                        };
                    }
                )
                    .filter((d) => d !== undefined)
                    .map((d) => d!);
            },
        });

    getDispensingMonthlyFiguresInRange = async ({
                                                    startDate,
                                                    endDate,
                                                }: {
        startDate: DateTime;
        endDate: DateTime;
    }): Promise<DispensingFigures[]> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                if (Math.random() < 0.1) return [];

                let pointer = startDate.getFirstDayInMonth();
                const dates = [];
                while (pointer.isSameDayOrBefore(endDate)) {
                    dates.push(pointer);
                    pointer = pointer.copyWith({
                        month: pointer.month + 1,
                    });
                }

                return dates
                    .map((date) => {
                        if (Math.random() < 0.15) return undefined;

                        const dispensingAmount = Math.floor(
                            Math.random() * 100_000_000
                        );
                        const billingCount = Math.floor(
                            1 + Math.random() * 10_000
                        );

                        return {
                            date,
                            dispensingAmount,
                            billingCount,
                        };
                    })
                    .filter((d) => d !== undefined)
                    .map((d) => d!);
            },
        });
}
