enum ErrorCode {
    JointCertWindowClosed = "CLIENT_1",
    Unknown = "000000",
    ServerSentError = "0000000",
    Cancelled = "000001",
    NetworkError = "000002",
    TokenExpired = "10002",
    CertCodeExpired = "20004",
    WrongCertCode = "20006",
}

export default ErrorCode;
