import ListenableState from "presentation/states/base/listenable_state";

export class LoginListenableState extends ListenableState {
}

export class LoginRequestCertCode extends LoginListenableState {
}

export class LoginCertCodeFocusRequest extends LoginListenableState {
}
