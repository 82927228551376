import {animated, useTransition} from "@react-spring/web";
import DonutChartType, {DonutChartTypeMap,} from "domain/model/tax/chart/donut_chart_type";
import {ProfitReportFiguresDetailTypeMap} from "domain/model/tax/profit_report/profit_report_figures_detail_type";
import ProfitReportFiguresType, {
    ProfitReportFiguresTypeMap,
} from "domain/model/tax/profit_report/profit_report_figures_type";
import ChartLegendLabel from "presentation/components/charts/common/chart_legend_label";
import DonutChart from "presentation/components/charts/donut_chart/donut_chart";
import SizedBox from "presentation/components/common/sized_box";
import StatisticNumberSmall from "presentation/components/statistic/statistic_number_small";
import DashboardCard from "presentation/pages/dashboard/components/dashboard_card";
import DashboardCardColumn from "presentation/pages/dashboard/components/dashboard_card_column";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import DashboardProfitDonutChartHeaderView, {
    DashboardProfitDonutChartHeaderButtonCallerType,
    DashboardProfitDonutChartHeaderButtonCallerTypeMap,
} from "presentation/pages/dashboard/view/profit/donut_chart/dashboard_profit_donut_chart_header_view";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import dashboardProfitSelector from "presentation/states/dashboard/selector/dashboard_profit_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {useCallback, useContext, useMemo, useRef, useState} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

type State = {
    type: DonutChartType;
    caller: DashboardProfitDonutChartHeaderButtonCallerType;
};

const ChartLayoutContainer = styled.div`
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;

const ChartContainer = styled(animated.div).attrs<{
    $widthInPx: number;
    $heightInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
`;

const RowContainer = styled.div.attrs<{ $collapsed: boolean }>((props) => ({
    style: {
        gap: props.$collapsed ? "8px" : "24px",
    },
}))`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: gap 0.3s ease-in-out;
`;

const DashboardProfitDonutChartView = () => {
    const {height} = useContext(DashboardRowContext);

    const {selectedYearMonth} = useRecoilValue(dashboardDateSelector);
    const {status, data} = useRecoilValue(dashboardProfitSelector);

    const cardRef = useRef<HTMLDivElement>(null);
    const layoutRef = useRef<HTMLDivElement>(null);
    const [chart, setChart] = useState<State>({
        type: DonutChartType.Sales,
        caller: DashboardProfitDonutChartHeaderButtonCallerType.Previous,
    });

    const {widthInPx} = useResizeObserver(cardRef, []);

    const chartLayoutHeight = layoutRef.current?.offsetHeight ?? 0;
    const collapsed = widthInPx < 297;

    const {salesData, salesTotal, profitData, profitTotal} = useMemo(() => {
        const match = data.find((d) => d.date.isSameMonth(selectedYearMonth));
        const dataEmpty = !match;

        const salesFiguresTypes =
            ProfitReportFiguresTypeMap.profitReportFiguresDetailTypes(
                ProfitReportFiguresType.Sales
            );
        const salesData = salesFiguresTypes.map((t) => ({
            label: ProfitReportFiguresDetailTypeMap.shortLabel(t),
            value: 0,
            color: ProfitReportFiguresDetailTypeMap.color(t),
        }));
        salesFiguresTypes.forEach((_, i) => {
            salesData[i].value += match?.sales[i + 1] ?? 0;
        });
        const salesTotal = match?.sales[0] ?? 0;

        const profitData = [
            {
                label: S.dashboardPage.profit.profitDonutChart.profitLabel,
                value: 0,
                color: Palette.lightGreen,
            },
            {
                label: S.dashboardPage.profit.profitDonutChart.purchasesLabel,
                value: 0,
                color: Palette.orange500,
            },
            {
                label: S.dashboardPage.profit.profitDonutChart
                    .otherExpensesLabel,
                value: 0,
                color: Palette.gray500,
            },
        ];
        profitData[0].value += match?.profit ?? 0;
        profitData[1].value += match?.otherExpenses[0] ?? 0;
        profitData[2].value += match?.purchases[0] ?? 0;
        const profitTotal = match?.profit ?? 0;
        profitData.forEach((d) => (d.value = Math.abs(d.value)));

        return {
            salesData,
            salesTotal: dataEmpty ? undefined : salesTotal,
            profitData,
            profitTotal: dataEmpty ? undefined : profitTotal,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const transitions = useTransition(chart, {
        from: {
            transform: `translateX(${DashboardProfitDonutChartHeaderButtonCallerTypeMap.fromTranslateX(
                chart.caller
            )})`,
            zIndex: 10,
            opacity: 1,
        },
        enter: {
            transform: "translateX(0%)",
            zIndex: 10,
            opacity: 1,
        },
        leave: {
            transform: `translateX(${DashboardProfitDonutChartHeaderButtonCallerTypeMap.leaveTranslateX(
                chart.caller
            )})`,
            zIndex: 9,
            opacity: 1,
        },
    });

    const buildChartView = useCallback(
        (chartType: DonutChartType) => {
            const donutSizeInPx = collapsed ? 165 : 220;

            switch (chartType) {
                case DonutChartType.Sales:
                    return (
                        <>
                            <DonutChart
                                donutSizeInPx={donutSizeInPx}
                                data={salesData}
                                hoverEnabled={false}
                            />
                            <RowContainer $collapsed={collapsed}>
                                {salesData.map((d) => (
                                    <ChartLegendLabel
                                        key={d.label}
                                        color={d.color}
                                        label={d.label}
                                    />
                                ))}
                            </RowContainer>
                        </>
                    );

                case DonutChartType.Profit:
                    return (
                        <>
                            <DonutChart
                                donutSizeInPx={donutSizeInPx}
                                data={profitData}
                                hoverEnabled={false}
                            />
                            <RowContainer $collapsed={collapsed}>
                                {profitData.map((d) => (
                                    <ChartLegendLabel
                                        key={d.label}
                                        color={d.color}
                                        label={d.label}
                                    />
                                ))}
                            </RowContainer>
                        </>
                    );
                default:
                    return null;
            }
        },
        [collapsed, salesData, profitData]
    );

    const onClick = (caller: DashboardProfitDonutChartHeaderButtonCallerType) =>
        setChart((prev) => ({
            caller,
            type: DashboardProfitDonutChartHeaderButtonCallerTypeMap.chartType(
                caller,
                prev.type
            ),
        }));

    const total = useMemo(() => {
        switch (chart.type) {
            case DonutChartType.Sales:
                return salesTotal;
            case DonutChartType.Profit:
                return profitTotal;
            default:
                throw new Error("Invalid DonutChartType");
        }
    }, [chart, salesTotal, profitTotal]);

    return (
        <DashboardCard
            ref={cardRef}
            status={status}
            height={height!}
            loadingColor={Palette.white100}
            backgroundColor={Palette.white100}
            hoverColor={Palette.white100}
            isButton={false}
            upper={
                <DashboardCardColumn alignment={"center"} grow={true}>
                    <DashboardProfitDonutChartHeaderView
                        title={DonutChartTypeMap.label(chart.type)}
                        onClick={onClick}
                    />
                    <SizedBox height={"8px"}/>
                    <StatisticNumberSmall
                        value={total}
                        unit={S.dashboardPage.wonUnit}
                        color={Palette.gray800}
                        unitColor={Palette.gray600}
                    />
                    <SizedBox height={"20px"}/>
                    <ChartLayoutContainer ref={layoutRef}>
                        {transitions((props, chart) => (
                            <ChartContainer
                                style={props}
                                $widthInPx={widthInPx}
                                $heightInPx={chartLayoutHeight}
                            >
                                {buildChartView(chart.type)}
                            </ChartContainer>
                        ))}
                    </ChartLayoutContainer>
                </DashboardCardColumn>
            }
        />
    );
};

export default DashboardProfitDonutChartView;
