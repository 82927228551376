import OptionButton from "presentation/components/button/option_button";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 8px;
`;

const TransactionsCalendarListButtons = ({
                                             labels,
                                             selectedIndex,
                                             onSelectedIndexChange,
                                         }: {
    labels: string[];
    selectedIndex: number;
    onSelectedIndexChange: (index: number) => void;
}) => {
    const onClick = (index: number) => () => onSelectedIndexChange(index);

    return (
        <LayoutContainer>
            {labels.map((l, index) => (
                <OptionButton
                    key={index}
                    selected={index === selectedIndex}
                    label={l}
                    onClick={onClick(index)}
                />
            ))}
        </LayoutContainer>
    );
};

export default TransactionsCalendarListButtons;
