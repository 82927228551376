import OptionButton from "presentation/components/button/option_button";
import DateFilterPeriodButton from "presentation/components/button/date_filter_period_button/date_filter_period_button";
import profitReportState from "presentation/states/profit_report/profit_report_state";
import profitReportDispensingStatusBoardSelector
    from "presentation/states/profit_report/selector/dispensing/status_board/profit_report_dispensing_status_board_date_filter_selector";
import DateTime from "presentation/utils/extension/date_extension";
import {useMemo} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import DateButtonsRow from "presentation/components/common/date_buttons_row";
import useRepository from "presentation/utils/hooks/use_repository";
import ProfitReportRepository from "data/repository/profit_report_repository";
import DispensingFigures from "domain/model/tax/profit_report/dispensing_figures";
import DispensingDateFilterType, {
    DispensingDateFilterTypeMap
} from "domain/model/tax/profit_report/dispensing_date_filter_type";

const ProfitReportDispensingStatusBoardDateButtonsView = () => {
    const repository = useRepository(ProfitReportRepository);

    const setState = useSetRecoilState(profitReportState);
    const {selectedDateFilter, startDate, endDate} = useRecoilValue(
        profitReportDispensingStatusBoardSelector
    );

    const dateFilters = useMemo(
        () => Object.values(DispensingDateFilterType),
        []
    );

    const onClick = (type: DispensingDateFilterType) => () =>
        repository({
            handler: async (repository) => {
                const {startDate, endDate} =
                    DispensingDateFilterTypeMap.datePeriod(type);

                setState((prev) => ({
                    ...prev,
                    dispensing: {
                        ...prev.dispensing,
                        statusBoard: {
                            ...prev.dispensing.statusBoard,
                            selectedDateFilter: type,
                            startDateKey: startDate.key,
                            endDateKey: endDate.key,
                        },
                    },
                }));

                let data: DispensingFigures[] = [];
                if (DispensingDateFilterTypeMap.queryDaily(type)) {
                    data = await repository.getDispensingDailyFiguresInRange({
                        startDate,
                        endDate,
                    });
                } else if (DispensingDateFilterTypeMap.queryMonthly(type)) {
                    data = await repository.getDispensingMonthlyFiguresInRange({
                        startDate,
                        endDate,
                    });
                }

                setState((prev) => ({
                    ...prev,
                    dispensing: {
                        ...prev.dispensing,
                        statusBoard: {
                            ...prev.dispensing.statusBoard,
                            selectedDateFilter: type,
                            startDateKey: startDate.key,
                            endDateKey: endDate.key,
                            data,
                        },
                    },
                }));
            },
        });

    const onDateSelected = async (startDate: DateTime, endDate: DateTime) =>
        repository({
            handler: async (repository) => {
                const match = dateFilters.find(
                    (d) =>
                        DispensingDateFilterTypeMap.datePeriod(
                            d
                        ).startDate.isEqual(startDate) &&
                        DispensingDateFilterTypeMap.datePeriod(
                            d
                        ).endDate.isEqual(endDate)
                );

                setState((prev) => ({
                    ...prev,
                    dispensing: {
                        ...prev.dispensing,
                        statusBoard: {
                            ...prev.dispensing.statusBoard,
                            selectedDateFilter: match,
                            startDateKey: startDate.key,
                            endDateKey: endDate.key,
                        },
                    },
                }));

                const data = await repository.getDispensingDailyFiguresInRange({
                    startDate,
                    endDate,
                });

                setState((prev) => ({
                    ...prev,
                    dispensing: {
                        ...prev.dispensing,
                        statusBoard: {
                            ...prev.dispensing.statusBoard,
                            selectedDateFilter: undefined,
                            startDateKey: startDate.key,
                            endDateKey: endDate.key,
                            data,
                        },
                    },
                }));
            },
        });

    return (
        <DateButtonsRow>
            {dateFilters.map((d) => {
                return (
                    <OptionButton
                        key={d}
                        selected={d === selectedDateFilter}
                        label={DispensingDateFilterTypeMap.label(d)}
                        onClick={onClick(d)}
                    />
                );
            })}
            <DateFilterPeriodButton
                startDate={startDate}
                endDate={endDate}
                onDateSelected={onDateSelected}
            />
        </DateButtonsRow>
    );
};

export default ProfitReportDispensingStatusBoardDateButtonsView;
