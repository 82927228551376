import BillingRepository from "data/repository/billing_repository";
import BillingDateFilterType, {BillingDateFilterTypeMap,} from "domain/model/tax/billing/billing_date_filter_type";
import DateFilterPeriodButton from "presentation/components/button/date_filter_period_button/date_filter_period_button";
import OptionButton from "presentation/components/button/option_button";
import DateButtonsRow from "presentation/components/common/date_buttons_row";
import billingState from "presentation/states/billing/billing_state";
import billingStatusBoardSelector from "presentation/states/billing/selector/billing_status_board_selector";
import DateTime from "presentation/utils/extension/date_extension";
import useRepository from "presentation/utils/hooks/use_repository";
import {useMemo} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";

const BillingStatusBoardDateButtonsView = () => {
    const repository = useRepository(BillingRepository);

    const setState = useSetRecoilState(billingState);
    const {selectedDateFilter, startDate, endDate} = useRecoilValue(
        billingStatusBoardSelector
    );

    const dateFilters = useMemo(() => Object.values(BillingDateFilterType), []);

    const onClick = (type: BillingDateFilterType) => () =>
        repository({
            handler: async (repository) => {
                const {startDate, endDate} =
                    BillingDateFilterTypeMap.datePeriod(type);

                setState((prev) => ({
                    ...prev,
                    statusBoard: {
                        ...prev.statusBoard,
                        selectedDateFilter: type,
                        startDateKey: startDate.key,
                        endDateKey: endDate.key,
                    },
                }));

                const data = await repository.getDailyFiguresInRange({
                    startDate:
                    BillingDateFilterTypeMap.datePeriod(type).startDate,
                    endDate: BillingDateFilterTypeMap.datePeriod(type).endDate,
                });

                setState((prev) => ({
                    ...prev,
                    statusBoard: {
                        ...prev.statusBoard,
                        selectedDateFilter: type,
                        startDateKey: startDate.key,
                        endDateKey: endDate.key,
                        data,
                    },
                }));
            },
        });

    const onDateSelected = (startDate: DateTime, endDate: DateTime) =>
        repository({
            handler: async (repository) => {
                const match = dateFilters.find(
                    (d) =>
                        BillingDateFilterTypeMap.datePeriod(
                            d
                        ).startDate.isEqual(startDate) &&
                        BillingDateFilterTypeMap.datePeriod(d).endDate.isEqual(
                            endDate
                        )
                );

                setState((prev) => ({
                    ...prev,
                    statusBoard: {
                        ...prev.statusBoard,
                        selectedDateFilter: match,
                        startDateKey: startDate.key,
                        endDateKey: endDate.key,
                    },
                }));

                const data = await repository.getDailyFiguresInRange({
                    startDate,
                    endDate,
                });

                setState((prev) => ({
                    ...prev,
                    statusBoard: {
                        ...prev.statusBoard,
                        selectedDateFilter: undefined,
                        startDateKey: startDate.key,
                        endDateKey: endDate.key,
                        data,
                    },
                }));
            },
        });

    return (
        <DateButtonsRow>
            {dateFilters.map((d) => (
                <OptionButton
                    key={d}
                    selected={d === selectedDateFilter}
                    label={BillingDateFilterTypeMap.label(d)}
                    onClick={onClick(d)}
                />
            ))}
            <DateFilterPeriodButton
                startDate={startDate}
                endDate={endDate}
                onDateSelected={onDateSelected}
            />
        </DateButtonsRow>
    );
};

export default BillingStatusBoardDateButtonsView;
