import ViewStatusType from "domain/model/common/view_status_type";
import IconButton, {IconButtonHoverColorType,} from "presentation/components/button/icon_button";
import IconRim from "presentation/components/common/icon_rim";
import Loading from "presentation/components/common/loading";
import SizedBox from "presentation/components/common/sized_box";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";
import useViewStatus from "presentation/utils/hooks/use_view_status";
import useWindowResizeObserver from "presentation/utils/hooks/use_window_resize_observer";
import {PropsWithChildren, ReactElement} from "react";
import styled from "styled-components";

const minHorizontalMarginInPx = 40;

const LayoutContainer = styled.div.attrs<{
    $widthInPx: number;
    $scaleFactor: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        transform: `scale(${props.$scaleFactor})`,
    },
}))`
    background-color: ${Palette.white100};
    border: 24px solid ${Palette.white100};
    border-radius: 16px;
    box-shadow: 0px 3px 15px 0px rgba(199, 199, 199, 0.25);
    transition: min-width 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

const ContentLayoutContainer = styled.div`
    width: 100%;
    height: max-content;
    position: relative;
`;

const ContentContainer = styled.div.attrs<{
    $visible: boolean;
    $blockPointer: boolean;
}>((props) => ({
    style: {
        opacity: props.$visible ? 1 : 0,
        pointerEvents: props.$blockPointer ? "none" : "auto",
    },
}))`
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease-in-out;
`;

const TitleLayoutContainer = styled.div<{ $bottomMargin: string }>`
    width: 100%;
    margin-bottom: ${(props) => props.$bottomMargin};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const TitleContainer = styled.h4<{ $font: string; $color: string }>`
    flex-grow: 1;
    ${(props) => props.$font};
    color: ${(props) => props.$color};
`;

const LoadingOverlayContainer = styled.div.attrs<{ $visible: boolean }>(
    (props) => ({
        style: {
            opacity: props.$visible ? 1 : 0,
            zIndex: props.$visible ? 1 : -1,
        },
    })
)`
    inset: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease-in-out;
`;

const Dialog = ({
                    widthInPx = 562,
                    status = ViewStatusType.Loaded,
                    contentLoading = false,
                    iconAsset,
                    iconColor = Palette.gray600,
                    iconRimColor = Palette.black8,
                    title,
                    titleFont = Fonts.body3,
                    titleColor = Palette.black100,
                    titleToContentSpacing = "24px",
                    children,
                }: PropsWithChildren<{
    widthInPx?: number;
    status?: ViewStatusType;
    contentLoading?: boolean;
    iconAsset: ReactElement;
    iconColor?: string;
    iconRimColor?: string;
    title?: string;
    titleFont?: string;
    titleColor?: string;
    titleToContentSpacing?: string;
}>) => {
    const dismissDialog = useDismissDialog();

    const {width} = useWindowResizeObserver([]);

    const {loading, loaded, error} = useViewStatus(status);

    const onClick = () => dismissDialog();

    const scaleFactor =
        width - widthInPx < minHorizontalMarginInPx
            ? (Math.min(width, widthInPx) - minHorizontalMarginInPx) /
            Math.max(width, widthInPx)
            : 1;

    const iconWidth = "24px";
    const iconHeight = "24px";

    return (
        <LayoutContainer $widthInPx={widthInPx} $scaleFactor={scaleFactor}>
            <TitleLayoutContainer $bottomMargin={titleToContentSpacing}>
                <IconRim
                    iconWidth={iconWidth}
                    iconHeight={iconHeight}
                    color={iconRimColor}
                >
                    <SVG
                        asset={iconAsset}
                        width={iconWidth}
                        height={iconHeight}
                        color={iconColor}
                    />
                </IconRim>
                <SizedBox width={"8px"}/>
                <TitleContainer $font={titleFont} $color={titleColor}>
                    {title ?? ""}
                </TitleContainer>
                <IconButton
                    asset={SVGAssets.Close}
                    width={"24px"}
                    height={"24px"}
                    color={Palette.gray800}
                    hoverColor={IconButtonHoverColorType.Light}
                    onClick={onClick}
                />
            </TitleLayoutContainer>
            <ContentLayoutContainer>
                <ContentContainer
                    $visible={loaded}
                    $blockPointer={loading || error}
                >
                    {children}
                </ContentContainer>
                <LoadingOverlayContainer $visible={contentLoading}>
                    <Loading visible={contentLoading} showLabel={false}/>
                </LoadingOverlayContainer>
                <Loading visible={loading}/>
            </ContentLayoutContainer>
        </LayoutContainer>
    );
};

export default Dialog;
