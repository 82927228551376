import {PropsWithChildren} from "react";
import styled from "styled-components";

type Alignment = "flex-start" | "center";

const LayoutContainer = styled.div.attrs<{
    $alignment: Alignment;
    $grow: boolean;
}>((props) => ({
    style: {
        alignItems: props.$alignment,
        flexGrow: props.$grow ? 1 : 0,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const DashboardCardColumn = ({
                                 alignment = "flex-start",
                                 grow = false,
                                 children,
                             }: PropsWithChildren<{
    alignment?: Alignment;
    grow?: boolean;
}>) => {
    return (
        <LayoutContainer $alignment={alignment} $grow={grow}>
            {children}
        </LayoutContainer>
    );
};

export default DashboardCardColumn;
