import BillingFiguresStatusType from "domain/model/tax/billing/billing_figures_status_type";
import S from "presentation/theme/s";
import {optional} from "presentation/utils/types/optional";

enum BillingFiguresStatusDropdownType {
    All = "all",
    Processing = "processing",
    Pending = "pending",
    Processed = "processed",
}

export const BillingFiguresStatusDropdownTypeMap = {
    billingFiguresStatusType: (
        type: BillingFiguresStatusDropdownType
    ): optional<BillingFiguresStatusType> => {
        switch (type) {
            case BillingFiguresStatusDropdownType.All:
                return undefined;
            case BillingFiguresStatusDropdownType.Processing:
                return BillingFiguresStatusType.Processing;
            case BillingFiguresStatusDropdownType.Pending:
                return BillingFiguresStatusType.Pending;
            case BillingFiguresStatusDropdownType.Processed:
                return BillingFiguresStatusType.Processed;
            default:
                throw new Error("Invalid BillingFiguresStatusDropdownType");
        }
    },
    label: (type: BillingFiguresStatusDropdownType): string => {
        switch (type) {
            case BillingFiguresStatusDropdownType.All:
                return S.billingFiguresStatusDropdownType.allLabel;
            case BillingFiguresStatusDropdownType.Processing:
                return S.billingFiguresStatusDropdownType.processingLabel;
            case BillingFiguresStatusDropdownType.Pending:
                return S.billingFiguresStatusDropdownType.pendingLabel;
            case BillingFiguresStatusDropdownType.Processed:
                return S.billingFiguresStatusDropdownType.processedLabel;
            default:
                throw new Error("Invalid BillingFiguresStatusDropdownType");
        }
    },
    shortLabel: (type: BillingFiguresStatusDropdownType): string => {
        switch (type) {
            case BillingFiguresStatusDropdownType.All:
                return S.billingFiguresStatusDropdownType.allShortLabel;
            case BillingFiguresStatusDropdownType.Processing:
                return S.billingFiguresStatusDropdownType.processingShortLabel;
            case BillingFiguresStatusDropdownType.Pending:
                return S.billingFiguresStatusDropdownType.pendingShortLabel;
            case BillingFiguresStatusDropdownType.Processed:
                return S.billingFiguresStatusDropdownType.processedShortLabel;
            default:
                throw new Error("Invalid BillingFiguresStatusDropdownType");
        }
    },
};

export default BillingFiguresStatusDropdownType;
