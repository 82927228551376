import {animated} from "@react-spring/web";
import {LineChartContext} from "presentation/components/charts/line_chart/line_chart";
import Palette from "presentation/theme/palette";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const DotContainer = styled(animated.div).attrs<{
    $color: string;
    $topOffsetInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        backgroundColor: props.$color,
        top: `${props.$topOffsetInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
    },
}))`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: background-color 0.3s ease-in-out, top 0.3s ease-in-out,
        left 0.3s ease-in-out;
`;

const BinContainer = styled(animated.div).attrs<{
    $widthInPx: number;
    $topOffsetInPx: number;
    $bottomOffsetInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
    },
}))`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: ${(props) => `${props.$topOffsetInPx}px`};
    bottom: ${(props) => `${props.$bottomOffsetInPx}px`};
    transform: translateX(-50%);
    transition: left 0.3s ease-in-out;
`;

const LineChartLineIndicator = styled.div.attrs<{
    $highlighted: boolean;
}>((props) => ({
    style: {
        borderLeft: `1px solid ${
            props.$highlighted ? Palette.gray800 : Palette.none
        }`,
    },
}))`
    width: 0;
    height: 100%;
    transition: border 0.3s ease-in-out;
`;

const LineChartDots = () => {
    const context = useContext(LineChartContext);
    const {binData, data} = useMemo(
        () => {
            const data = context.data.map((l) => ({
                dots: l.dots.map((d) => ({
                    key: `${d.x}-${d.y}-${l.color}`,
                    xy: {x: d.x, y: d.y},
                    xPos: context.functions.getXPos(d.x),
                    yPos: context.functions.getYPos(d.y),
                    color: l.color,
                })),
            }));
            const set = new Set<number>();
            context.data.forEach((d) =>
                d.dots.forEach((dot) => set.add(dot.x))
            );
            const binData = Array.from(set)
                .sort((a, b) => a - b)
                .map((x) => context.functions.getXPos(x));

            return {
                binData,
                data,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [context.data, context.constantsKey]
    );

    const onMouseEnter = (x: number) => () => context.setHoveredXIndex(x);
    const onMouseLeave = () => context.setHoveredXIndex(undefined);

    return (
        <>
            {binData.map((pos, index) => (
                <BinContainer
                    key={pos}
                    onMouseEnter={onMouseEnter(index)}
                    onMouseLeave={onMouseLeave}
                    $widthInPx={context.constants.binSizeInPx}
                    $topOffsetInPx={context.constants.topMarginInPx}
                    $bottomOffsetInPx={context.constants.xAxisHeightInPx}
                    $leftOffsetInPx={pos}
                >
                    <LineChartLineIndicator
                        $highlighted={context.hoveredXIndex === index}
                    />
                </BinContainer>
            ))}
            {data.map((d) =>
                d.dots.map((dot) => (
                    <DotContainer
                        key={dot.key}
                        $color={dot.color}
                        $topOffsetInPx={dot.yPos}
                        $leftOffsetInPx={dot.xPos}
                    />
                ))
            )}
        </>
    );
};

export default LineChartDots;
