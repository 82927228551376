import {ReactElement} from "react";
import {atom} from "recoil";

export type DialogState = {
    className: string;
    dismissible: boolean;
    onDismissed?: () => void;
    component?: ReactElement;
};

type UIState = {
    loading: boolean;
    dialogState: DialogState[];
};

const uiState = atom<UIState>({
    key: "uiState",
    default: {
        loading: false,
        dialogState: [],
    },
});

export default uiState;
