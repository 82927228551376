import SizedBox from "presentation/components/common/sized_box";
import ContentBox from "presentation/components/content/content_box";
import ContentLayout from "presentation/components/content/content_layout";
import TransactionsDataTableView from "presentation/pages/transactions/view/table/transactions_data_table_view";
import TransactionsTableDateButtonsView
    from "presentation/pages/transactions/view/table/transactions_table_date_buttons_view";
import TransactionsTableDateTitleView
    from "presentation/pages/transactions/view/table/transactions_table_date_title_view";
import TransactionsTableHeaderView from "presentation/pages/transactions/view/table/transactions_table_header_view";
import TransactionsTableSummaryView from "presentation/pages/transactions/view/table/transactions_table_summary_view";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {useRef} from "react";

const TransactionsTableView = () => {
    const layoutRef = useRef<HTMLDivElement>(null);

    const {height: contentHeight} = useResizeObserver(layoutRef, []);

    return (
        <ContentBox contentHeight={contentHeight}>
            <ContentLayout ref={layoutRef}>
                <TransactionsTableDateButtonsView/>
                <SizedBox height={"36px"}/>
                <TransactionsTableDateTitleView/>
                <SizedBox height={"24px"}/>
                <TransactionsTableSummaryView/>
                <SizedBox height={"36px"}/>
                <TransactionsTableHeaderView/>
                <SizedBox height={"12px"}/>
                <TransactionsDataTableView/>
            </ContentLayout>
        </ContentBox>
    );
};

export default TransactionsTableView;
