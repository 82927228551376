import TransactionsRepository from "data/repository/transactions_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import DashboardCard from "presentation/pages/dashboard/components/dashboard_card";
import DashboardTransactionsCalendarListHeaderView
    from "presentation/pages/dashboard/view/transactions/calendar_list/dashboard_transactions_calendar_list_header_view";
import DashboardTransactionsCalendarView
    from "presentation/pages/dashboard/view/transactions/calendar_list/dashboard_transactions_calendar_view";
import DashboardTransactionsListView
    from "presentation/pages/dashboard/view/transactions/calendar_list/dashboard_transactions_list_view";
import {
    DashboardTransactionsViewContext
} from "presentation/pages/dashboard/view/transactions/dashboard_transactions_view";
import dashboardState from "presentation/states/dashboard/dashboard_state";
import Palette from "presentation/theme/palette";
import DateTime from "presentation/utils/extension/date_extension";
import useRepository from "presentation/utils/hooks/use_repository";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import Debouncer from "presentation/utils/debouncer/debouncer";
import {useContext, useEffect, useRef, useState} from "react";
import {useSetRecoilState} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    transition: scrollbar-color 0.3s ease-in-out;

    @media (max-width: 768px) {
        align-items: flex-start;
        overflow-x: scroll;
        scrollbar-color: ${Palette.none} ${Palette.none};

        &:hover {
            scrollbar-color: ${Palette.gray300} ${Palette.none};
        }
    }

    @media (min-width: 1281px) {
        flex-direction: row;
        justify-content: center;
    }
`;

const DashboardTransactionsCalendarListView = () => {
    const {queryYearMonth} = useContext(DashboardTransactionsViewContext);
    const repository = useRepository(TransactionsRepository);

    const setState = useSetRecoilState(dashboardState);

    const layoutRef = useRef<HTMLDivElement>(null);
    const debouncerRef = useRef(new Debouncer());
    const [status, setStatus] = useState(ViewStatusType.Loading);

    const layoutSize = useResizeObserver(layoutRef, []);
    const layoutHeight = `max(auto, ${layoutSize.height})`;

    const startDate = queryYearMonth.getFirstDayInMonth();
    const endDate = DateTime.minNow().copyWith({
        day: DateTime.minNow().day + 14,
    });

    const fetch = () =>
        repository({
            handler: async (repository) => {
                const data = await repository.getFiguresInRange({
                    startDate,
                    endDate,
                });

                setState((prev) => ({
                    ...prev,
                    transactions: {
                        ...prev.transactions,
                        data,
                    },
                }));

                setStatus(ViewStatusType.Loaded);
            },
        });

    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status === ViewStatusType.Loading) return;

        debouncerRef.current.run(fetch, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryYearMonth.key]);

    return (
        <DashboardCard
            status={status}
            height={layoutHeight}
            isHeightContentHeight={true}
            loadingColor={Palette.white100}
            backgroundColor={Palette.white100}
            hoverColor={Palette.white100}
            grow={true}
            maxWidthOnGrow={"1018px"}
            upper={
                <LayoutContainer>
                    <DashboardTransactionsCalendarListHeaderView/>
                    <RowContainer ref={layoutRef}>
                        <DashboardTransactionsCalendarView/>
                        <DashboardTransactionsListView/>
                    </RowContainer>
                </LayoutContainer>
            }
        />
    );
};

export default DashboardTransactionsCalendarListView;
