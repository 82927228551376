import {animated} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import SizedBox from "presentation/components/common/sized_box";
import Spacer from "presentation/components/common/spacer";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const TitleContainer = styled.p`
    ${Fonts.detail3Medium};
    color: ${Palette.white100};
    flex-grow: 1;
`;

const ValueContainer = styled(animated.p)`
    ${Fonts.body3};
    color: ${Palette.white100};
`;

const UnitContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.white100};
`;

const DashboardCardFigures = ({
                                  title,
                                  value,
                                  unit,
                              }: {
    title: string;
    value?: number;
    unit: string;
}) => {
    const _value =
        value !== undefined
            ? NumberHelper.toStringWithDividers(value)
            : S.dashboardCardFigures.emptyFigures;

    const {props} = useFadeInTransition(_value);

    return (
        <LayoutContainer>
            <TitleContainer>{title}</TitleContainer>
            <Spacer/>
            <ValueContainer style={props}>{_value}</ValueContainer>
            <SizedBox width={"4px"}/>
            <UnitContainer>{unit}</UnitContainer>
        </LayoutContainer>
    );
};

export default DashboardCardFigures;
