import S from "presentation/theme/s";

enum ProfitReportType {
    Profit = "profit",
    Dispensing = "dispensing",
}

export const ProfitReportTypeMap = {
    label: (type: ProfitReportType): string => {
        switch (type) {
            case ProfitReportType.Profit:
                return S.profitReportType.profitLabel;
            case ProfitReportType.Dispensing:
                return S.profitReportType.dispensingLabel;
            default:
                throw new Error("Invalid ProfitReportType");
        }
    },
};

export default ProfitReportType;
