import {animated, useSpring, useTransition} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import ChartTooltipLabel from "presentation/components/charts/common/chart_tooltip_label";
import HorizontalStackedBarChartData
    from "presentation/components/charts/horizontal_stacked_bar_chart/horizontal_stacked_bar_chart_model";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {MouseEvent, useMemo, useRef} from "react";
import styled from "styled-components";
import ChartLegendLabel from "presentation/components/charts/common/chart_legend_label";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";

const barHeightInPx = 20;

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 8px;
`;

const TitleContainer = styled.h6`
    ${Fonts.detail2Medium};
    color: ${Palette.gray600};
`;

const BarsPositionContainer = styled.div.attrs<{ $widthInPx: number }>(
    (props) => ({
        style: {
            width: `${props.$widthInPx}px`,
        },
    })
)`
    height: ${`${barHeightInPx}px`};
    position: relative;
`;

const BarsContainer = styled(animated.div).attrs<{ $widthInPx: number }>(
    (props) => ({
        style: {
            width: `${props.$widthInPx}px`,
        },
    })
)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
`;

const EmptyBarContainer = styled(animated.div).attrs<{ $widthInPx: number }>(
    (props) => ({
        style: {
            width: `${props.$widthInPx}px`,
        },
    })
)`
    position: absolute;
    top: 0;
    left: 0;
`;

const BarContainer = styled.div.attrs<{
    $widthInPx: number;
    $hasLeftBorderRadius: boolean;
    $hasRightBorderRadius: boolean;
    $color: string;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
    },
}))`
    height: ${`${barHeightInPx}px`};
    border-top-left-radius: ${(props) =>
    `${props.$hasLeftBorderRadius ? barHeightInPx / 2 : 0}px`};
    border-bottom-left-radius: ${(props) =>
    `${props.$hasLeftBorderRadius ? barHeightInPx / 2 : 0}px`};
    border-top-right-radius: ${(props) =>
    `${props.$hasRightBorderRadius ? barHeightInPx / 2 : 0}px`};
    border-bottom-right-radius: ${(props) =>
    `${props.$hasRightBorderRadius ? barHeightInPx / 2 : 0}px`};
    background-color: ${(props) => props.$color};
    flex-grow: ${(props) => (props.$widthInPx !== undefined ? 0 : 1)};
    transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
`;

const LegendsContainer = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 36px;
`;

const LegendContainer = styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
`;

const LegendTextContainer = styled.div`
    ${Fonts.detail1};
    color: ${Palette.gray600};
    word-break: keep-all;
`;

const TooltipContainer = styled(animated.ul)`
    width: max-content;
    padding: 8px;
    background-color: ${Palette.black75};
    border-radius: 12px;
    box-shadow: 0 0 0 1px ${Palette.white20} inset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
`;

const HorizontalStackedBarChart = ({
                                       title,
                                       data,
                                       hoverEnabled = true,
                                       showLegends = true,
                                   }: {
    title?: string;
    data: HorizontalStackedBarChartData[];
    hoverEnabled?: boolean;
    showLegends?: boolean;
}) => {
    const layoutRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLUListElement>(null);

    const dataEmpty = !data.length || data.every((d) => d.value === 0);

    const [tooltipProps, tooltipAPI] = useSpring(() => ({
        opacity: 0,
        top: 0,
        left: 0,
    }));

    const size = useResizeObserver(layoutRef, [data]);

    const widthInPx = size.widthInPx;

    const _data = useMemo(() => {
        const total = data.reduce((acc, cur) => acc + cur.value, 0);

        const xScale = widthInPx / total;

        return data.map((d) => ({
            key: `${d.label}-${d.value}-${d.color}`,
            widthInPx: d.value * xScale,
            percentageLabel: S.horizontalStackedBarChart.percentageLabel(
                d.label,
                Math.floor((d.value / total) * 100).toString()
            ),
            ...d,
        }));
    }, [data, widthInPx]);

    const onMouseEnter = () => {
        if (!hoverEnabled || dataEmpty) return;

        tooltipAPI.start({
            opacity: 1,
        });
    };
    const onMouseLeave = () => {
        if (!hoverEnabled || dataEmpty) return;

        tooltipAPI.start({
            opacity: 0,
        });
    };
    const onMouseMove = (e: MouseEvent) => {
        if (!hoverEnabled) return;

        const layout = layoutRef.current;
        const tooltip = tooltipRef.current;
        if (!layout || !tooltip) return;

        const rect = layout.getBoundingClientRect();

        const containerWidth = layout.offsetWidth;
        const containerHeight = layout.offsetHeight;
        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;
        const tooltipWidth = tooltip.offsetWidth;
        const tooltipHeight = tooltip.offsetHeight;

        if (x < 0 || y < 0 || x > containerWidth || y > containerHeight) return;

        const overflowX = tooltipWidth - containerWidth + x;
        const overflowY = tooltipHeight - containerHeight + y;

        if (overflowX > 0) x -= overflowX;
        if (overflowY > 0) y -= overflowY;

        tooltipAPI.start({
            top: y,
            left: x,
        });
    };

    const transitions = useTransition(!dataEmpty, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
    });

    const legendLabel = (value: number) =>
        dataEmpty
            ? S.horizontalStackedBarChart.emptyLabel
            : NumberHelper.toChartYAxisAbbreviatedString(value);

    return (
        <LayoutContainer
            ref={layoutRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
        >
            {title && <TitleContainer>{title}</TitleContainer>}
            <BarsPositionContainer $widthInPx={widthInPx}>
                {transitions((props, notEmpty) =>
                    notEmpty ? (
                        <BarsContainer style={props} $widthInPx={widthInPx}>
                            {_data.map((d, index) => (
                                <BarContainer
                                    key={index}
                                    $widthInPx={d.widthInPx}
                                    $hasLeftBorderRadius={index === 0}
                                    $hasRightBorderRadius={
                                        index === _data.length - 1
                                    }
                                    $color={d.color}
                                />
                            ))}
                        </BarsContainer>
                    ) : (
                        <EmptyBarContainer style={props} $widthInPx={widthInPx}>
                            <BarContainer
                                $widthInPx={widthInPx}
                                $hasLeftBorderRadius={true}
                                $hasRightBorderRadius={true}
                                $color={Palette.gray100}
                            />
                        </EmptyBarContainer>
                    )
                )}
            </BarsPositionContainer>
            {showLegends && (
                <LegendsContainer>
                    {_data.map((d) => (
                        <LegendContainer key={d.key}>
                            <ChartLegendLabel
                                as={"div"}
                                color={d.color}
                                label={d.label}
                            />
                            {
                                <LegendTextContainer>
                                    {legendLabel(d.value)}
                                </LegendTextContainer>
                            }
                        </LegendContainer>
                    ))}
                </LegendsContainer>
            )}
            <TooltipContainer ref={tooltipRef} style={tooltipProps}>
                {_data.map((d) => (
                    <ChartTooltipLabel
                        key={d.percentageLabel}
                        color={d.color}
                        label={d.percentageLabel}
                    />
                ))}
            </TooltipContainer>
        </LayoutContainer>
    );
};

export default HorizontalStackedBarChart;
