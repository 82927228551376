import {animated} from "@react-spring/web";
import SVG from "presentation/components/common/svg";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import Debouncer from "presentation/utils/debouncer/debouncer";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import {ReactElement, useRef} from "react";
import styled from "styled-components";

type FilledButtonType = "primary" | "gray" | "red" | "black";
type FilledButtonSizeType = "large" | "medium" | "small";

const colorPresets: Record<
    FilledButtonType,
    {
        backgroundColor: string;
        disabledBackgroundColor: string;
        hoverColor: string;
        color: string;
        disabledColor: string;
    }
> = {
    primary: {
        backgroundColor: Palette.primary500,
        disabledBackgroundColor: Palette.primary100,
        hoverColor: Palette.primary400,
        color: Palette.white100,
        disabledColor: Palette.primary30,
    },
    gray: {
        backgroundColor: Palette.gray100,
        disabledBackgroundColor: Palette.gray100,
        hoverColor: Palette.gray200,
        color: Palette.gray800,
        disabledColor: Palette.gray300,
    },
    red: {
        backgroundColor: Palette.red100,
        disabledBackgroundColor: Palette.red100,
        hoverColor: Palette.red200,
        color: Palette.red500,
        disabledColor: Palette.red200,
    },
    black: {
        backgroundColor: Palette.black100,
        disabledBackgroundColor: Palette.black100,
        hoverColor: Palette.gray600,
        color: Palette.white100,
        disabledColor: Palette.white100,
    },
};

const sizePresets: Record<
    FilledButtonSizeType,
    {
        font: string;
        padding: string;
        iconSize: string;
    }
> = {
    large: {
        font: Fonts.body1,
        padding: "12px 16px",
        iconSize: "24px",
    },
    medium: {
        font: Fonts.detail2,
        padding: "10px 16px",
        iconSize: "20px",
    },
    small: {
        font: Fonts.detail1,
        padding: "6px 12px",
        iconSize: "16px",
    },
};

const LayoutContainer = styled.button.attrs<{
    $enabled: boolean;
    $width: string;
    $padding: string;
    $backgroundColor: string;
    $disabledBackgroundColor: string;
    $hoverColor: string;
}>((props) => ({
    style: {
        width: props.$width,
        padding: props.$padding,
    },
}))`
    border: none;
    border-radius: 12px;
    background-color: ${(props) =>
    props.$enabled
        ? props.$backgroundColor
        : props.$disabledBackgroundColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: ${(props) => (props.$enabled ? "pointer" : "not-allowed")};
    transition: width 0.3s ease-in-out, padding 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

    &:hover {
        background-color: ${(props) =>
    props.$enabled
        ? props.$hoverColor
        : props.$disabledBackgroundColor};
    }
`;

const LabelContainer = styled(animated.p).attrs<{
    $enabled: boolean;
    $font: string;
    $color: string;
    $disabledColor: string;
}>((props) => ({
    style: {
        color: props.$enabled ? props.$color : props.$disabledColor,
    },
}))`
    ${(props) => props.$font};
    text-align: center;
    transition: color 0.3s ease-in-out;
`;

const FilledButton = ({
                          type = "primary",
                          sizeType = "large",
                          enabled = true,
                          width = "auto",
                          label,
                          leftIconAsset,
                          rightIconAsset,
                          backgroundColor,
                          disabledBackgroundColor,
                          hoverColor,
                          color,
                          disabledColor,
                          debouncerDelayInMS = 50,
                          onClick,
                      }: {
    type?: FilledButtonType;
    sizeType?: FilledButtonSizeType;
    enabled?: boolean;
    width?: string;
    label: string;
    leftIconAsset?: ReactElement;
    rightIconAsset?: ReactElement;
    backgroundColor?: string;
    disabledBackgroundColor?: string;
    hoverColor?: string;
    color?: string;
    disabledColor?: string;
    debouncerDelayInMS?: number;
    onClick?: () => void;
}) => {
    const debouncerRef = useRef(new Debouncer());

    const _onClick = () =>
        debouncerRef.current.run(() => onClick?.(), debouncerDelayInMS);

    const _backgroundColor =
        backgroundColor ?? colorPresets[type].backgroundColor;
    const _disabledBackgroundColor =
        disabledBackgroundColor ?? colorPresets[type].disabledBackgroundColor;
    const _hoverColor = hoverColor ?? colorPresets[type].hoverColor;
    const _color = color ?? colorPresets[type].color;
    const _disabledColor = disabledColor ?? colorPresets[type].disabledColor;
    const font = sizePresets[sizeType].font;
    const padding = sizePresets[sizeType].padding;
    const iconSize = sizePresets[sizeType].iconSize;

    const {props} = useFadeInTransition(label);

    return (
        <LayoutContainer
            onClick={enabled ? _onClick : undefined}
            $enabled={enabled}
            $width={width}
            $padding={padding}
            $backgroundColor={_backgroundColor}
            $disabledBackgroundColor={_disabledBackgroundColor}
            $hoverColor={_hoverColor}
        >
            {leftIconAsset && (
                <SVG
                    asset={leftIconAsset}
                    width={iconSize}
                    height={iconSize}
                    color={_color}
                />
            )}
            <LabelContainer
                style={props}
                $enabled={enabled}
                $font={font}
                $color={_color}
                $disabledColor={_disabledColor}
            >
                {label}
            </LabelContainer>
            {rightIconAsset && (
                <SVG
                    asset={rightIconAsset}
                    width={iconSize}
                    height={iconSize}
                    color={_color}
                />
            )}
        </LayoutContainer>
    );
};

export default FilledButton;
