import externalSyncsState from "presentation/states/external_syncs/external_syncs_state";
import {selector} from "recoil";

const externalSyncsInsuranceSelector = selector({
    key: "externalSyncsInsuranceSelector",
    get: ({get}) => {
        const state = get(externalSyncsState);

        return state.insurance;
    },
});

export default externalSyncsInsuranceSelector;
