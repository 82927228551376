import dashboardState from "presentation/states/dashboard/dashboard_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const dashboardProfitSelector = selector({
    key: "dashboardProfitSelector",
    get: ({get}) => {
        const state = get(dashboardState);

        return {
            status: state.profit.status,
            startDate: DateTime.fromKey(state.profit.startDateKey),
            endDate: DateTime.fromKey(state.profit.endDateKey),
            data: state.profit.data,
        };
    },
});

export default dashboardProfitSelector;
