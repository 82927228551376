import Debouncer from "presentation/utils/debouncer/debouncer";
import {useEffect, useRef, useState} from "react";

const useWindowResizeObserver = (
    deps: readonly unknown[],
    debounce: number = 200
) => {
    const debouncerRef = useRef(new Debouncer());
    const [size, setSize] = useState({
        width: window.outerWidth,
        height: window.outerHeight,
    });

    useEffect(() => {
        const debouncer = debouncerRef.current;

        const onResize = () =>
            debouncer.run(() => {
                const width = window.outerWidth;
                const height = window.outerHeight;

                setSize({width, height});
            }, debounce);

        window.addEventListener("resize", onResize);

        onResize();

        return () => window.removeEventListener("resize", onResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const width = window.outerWidth;
        const height = window.outerHeight;

        setSize({width, height});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, debounce]);

    return size;
};

export default useWindowResizeObserver;
