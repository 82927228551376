import DropdownButton from "presentation/components/button/dropdown_button/dropdown_button";
import DateTime from "presentation/utils/extension/date_extension";
import {useMemo} from "react";

const YearDropdownButton = ({
                                startDate,
                                endDate = DateTime.minNow().getFirstDayInYear(),
                                selectedDate,
                                onSelectedDateChange,
                            }: {
    startDate: DateTime;
    endDate?: DateTime;
    selectedDate: DateTime;
    onSelectedDateChange: (date: DateTime) => void;
}) => {
    const {end, labels} = useMemo(
        () => {
            const start = startDate.getFirstDayInYear();
            const end = endDate.getFirstDayInYear();

            const diff = end.year - start.year;

            const labels = Array.from({length: diff + 1}, (_, i) =>
                end.copyWith({year: end.year - i}).year.toString()
            );

            return {
                end,
                labels,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [startDate.key, endDate.key]
    );

    const onSelectedIndexChange = async (index: number) =>
        onSelectedDateChange(
            end.copyWith({
                year: end.year - index,
            })
        );

    const selectedIndex = (() => {
        const index = end.year - selectedDate.year;

        if (index < 0) return 0;
        if (index >= labels.length) return labels.length - 1;

        return index;
    })();

    return (
        <DropdownButton
            minWidthInPx={100}
            maxWidthInPx={164}
            selectedIndex={selectedIndex}
            labels={labels}
            onSelectedIndexChange={onSelectedIndexChange}
        />
    );
};

export default YearDropdownButton;
