import NumberHelper from "config/helper/number_helper";
import {ProfitReportFiguresDetailTypeMap} from "domain/model/tax/profit_report/profit_report_figures_detail_type";
import ProfitReportFiguresType, {
    ProfitReportFiguresTypeMap,
} from "domain/model/tax/profit_report/profit_report_figures_type";
import SizedBox from "presentation/components/common/sized_box";
import DashboardCardMoreButton from "presentation/pages/dashboard/components/dashboard_card_more_button";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import DashboardOtherFiguresChartTableRow
    from "presentation/pages/dashboard/view/other_figures/chart/components/dashboard_other_figures_chart_table_row";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import dashboardProfitSelector from "presentation/states/dashboard/selector/dashboard_profit_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $height: string }>((props) => ({
    style: {
        height: props.$height,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: height 0.3s ease-in-out;
    position: relative;
`;

const DashboardOtherFiguresChartTableView = () => {
    const {contentHeight} = useContext(DashboardRowContext);

    const {previousYearMonth, selectedYearMonth} = useRecoilValue(
        dashboardDateSelector
    );
    const {data} = useRecoilValue(dashboardProfitSelector);

    const {headerValues, rowValues} = useMemo(() => {
        const headerValues = [
            previousYearMonth.format(
                S.dashboardPage.otherFigures.previousMonthTitleDateFormat
            ),
            selectedYearMonth.format(
                S.dashboardPage.otherFigures.currentMonthtitleDateFormat
            ),
        ];

        const currentData = data.find((d) =>
            d.date.isSameMonth(selectedYearMonth)
        );
        const previousData = data.find((d) =>
            d.date.isSameMonth(previousYearMonth)
        );

        const titles =
            ProfitReportFiguresTypeMap.profitReportFiguresDetailTypes(
                ProfitReportFiguresType.OtherExpenses
            ).map((t) => ProfitReportFiguresDetailTypeMap.shortLabel(t));

        const rowValues = titles.map((t, index) => ({
            title: t,
            labels: [
                previousData?.otherExpenses[index + 1],
                currentData?.otherExpenses[index + 1],
            ].map((v) =>
                v !== undefined
                    ? NumberHelper.toStringWithDividers(v)
                    : undefined
            ),
        }));

        return {
            headerValues,
            rowValues,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onClick = () => {
        const route = `${AppRoutes.ProfitReport}?type=profitReport&scrollTo=yearlyTable`;
        AppRouter.navigate(route);
    };

    return (
        <LayoutContainer $height={contentHeight!}>
            <DashboardCardMoreButton onClick={onClick}/>
            <SizedBox height={"4px"}/>
            <DashboardOtherFiguresChartTableRow
                height={"2.5rem"}
                title={S.dashboardPage.otherFigures.mainCostsTitle}
                labels={headerValues}
                backgroundColor={Palette.gray100}
            />
            {rowValues?.map((v, index) => (
                <DashboardOtherFiguresChartTableRow
                    key={index}
                    height={"2.75rem"}
                    title={v.title}
                    labels={v.labels}
                />
            ))}
        </LayoutContainer>
    );
};

export default DashboardOtherFiguresChartTableView;
