import StatisticTitleLarge from "presentation/components/statistic/statistic_title_large";
import DashboardDetailButton from "presentation/pages/dashboard/components/dashboard_details_button";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const DashboardDispensingChartHeaderView = () => {
    const onClick = () => {
        const route = `${AppRoutes.ProfitReport}?type=dispensing&scrollTo=statusBoard`;
        AppRouter.navigate(route);
    };

    return (
        <LayoutContainer>
            <StatisticTitleLarge
                title={S.dashboardPage.dispensing.headerTitle}
                color={Palette.gray800}
            />
            <DashboardDetailButton onClick={onClick}/>
        </LayoutContainer>
    );
};

export default DashboardDispensingChartHeaderView;
