import SVG from "presentation/components/common/svg";
import CardSalesStatusBoardSummaryItem
    from "presentation/pages/card_sales/view/status_board/components/card_sales_status_board_summary_item";
import cardSalesStatusBoardSelector from "presentation/states/card_sales/selector/card_sales_status_board_selector";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
`;

const CardSalesStatusBoardCardSalesSummaryView = () => {
    const {data} = useRecoilValue(cardSalesStatusBoardSelector);

    const {
        total,
        processed,
        processedCount,
        cancelled,
        cancelledCount,
        pending,
        pendingCount,
    } = useMemo(() => {
        let total = 0;
        let processed = 0;
        let processedCount = 0;
        let cancelled = 0;
        let cancelledCount = 0;
        let pending = 0;
        let pendingCount = 0;

        data.forEach((d) => {
            total += d.total;
            processed += d.processedAmount;
            processedCount += d.processedCount;
            cancelled += d.cancelledAmount;
            cancelledCount += d.cancelledCount;
            pending += d.pendingAmount;
            pendingCount += d.pendingCount;
        });

        return {
            total: data.length ? total : undefined,
            processed: data.length ? processed : undefined,
            processedCount: data.length ? processedCount : undefined,
            cancelled: data.length ? cancelled : undefined,
            cancelledCount: data.length ? cancelledCount : undefined,
            pending: data.length ? pending : undefined,
            pendingCount: data.length ? pendingCount : undefined,
        };
    }, [data]);

    return (
        <LayoutContainer>
            <CardSalesStatusBoardSummaryItem
                color={Palette.deepBlue}
                title={S.cardSalesPage.statusBoard.cardSales.cardSalesLabel}
                hideCount={true}
                value={total}
            />
            <CardSalesStatusBoardSummaryItem
                title={S.cardSalesPage.statusBoard.cardSales.processedLabel}
                hideCount={false}
                count={processedCount}
                value={processed}
                icon={
                    <SVG
                        asset={SVGAssets.EqualCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
            />
            <CardSalesStatusBoardSummaryItem
                title={S.cardSalesPage.statusBoard.cardSales.cancelledLabel}
                hideCount={false}
                count={cancelledCount}
                value={cancelled}
                icon={
                    <SVG
                        asset={SVGAssets.MinusCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
            />
            <CardSalesStatusBoardSummaryItem
                color={Palette.yellow500}
                title={S.cardSalesPage.statusBoard.cardSales.pendingLabel}
                hideCount={false}
                count={pendingCount}
                value={pending}
                icon={
                    <SVG
                        asset={SVGAssets.MinusCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
            />
        </LayoutContainer>
    );
};

export default CardSalesStatusBoardCardSalesSummaryView;
