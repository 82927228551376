import DashboardRow from "presentation/pages/dashboard/components/dashboard_row";
import DashboardDispensingChartView
    from "presentation/pages/dashboard/view/dispensing/chart/dashboard_dispensing_chart_view";
import DashboardDispensingSummaryView
    from "presentation/pages/dashboard/view/dispensing/summary/dashboard_dispensing_summary_view";

const DashboardDispensingView = () => {
    return (
        <DashboardRow height={"294px"}>
            <DashboardDispensingSummaryView/>
            <DashboardDispensingChartView/>
        </DashboardRow>
    );
};

export default DashboardDispensingView;
