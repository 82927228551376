import BillingFiguresStatusType from "domain/model/tax/billing/billing_figures_status_type";
import SpacedDivider from "presentation/components/common/spaced_divider";
import BillingStatusBoardSummaryItem
    from "presentation/pages/billing/components/status_board/billing_status_board_summary_item";
import billingStatusBoardSelector from "presentation/states/billing/selector/billing_status_board_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const height = "7.125rem";
const horizontalMargin = "0px";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 56px;
    transition: gap 0.3s ease-in-out;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
    }
`;

const BillingStatusBoardSummaryView = () => {
    const {data} = useRecoilValue(billingStatusBoardSelector);

    const {count, needCheckCount, processedCount} = useMemo(() => {
        if (!data.length) {
            return {
                count: undefined,
                needCheckCount: undefined,
                processedCount: undefined,
            };
        }

        let count = 0;
        let needCheckCount = 0;
        let processedCount = 0;

        data.forEach((d) => {
            count++;
            if (d.status === BillingFiguresStatusType.Pending) {
                needCheckCount++;
            }
            if (d.status === BillingFiguresStatusType.Processed) {
                processedCount++;
            }
        });

        return {
            count,
            needCheckCount,
            processedCount,
        };
    }, [data]);

    return (
        <LayoutContainer>
            <BillingStatusBoardSummaryItem
                height={height}
                title={S.billingPage.statusBoard.summary.billingTitle}
                value={count}
            />
            <SpacedDivider
                height={height}
                horizontalMargin={horizontalMargin}
            />
            <BillingStatusBoardSummaryItem
                height={height}
                needCheck={!!needCheckCount}
                title={S.billingPage.statusBoard.summary.pendingTitle}
                value={needCheckCount}
                valueColor={Palette.red500}
            />
            <SpacedDivider
                height={height}
                horizontalMargin={horizontalMargin}
            />
            <BillingStatusBoardSummaryItem
                height={height}
                title={S.billingPage.statusBoard.summary.processedTitle}
                value={processedCount}
            />
        </LayoutContainer>
    );
};

export default BillingStatusBoardSummaryView;
