const S = {
    accessErrorView: {
        message: "잘못된 접근입니다"
    },

    appRoutes: {
        dashboardTNBTitle: "대시보드",
        profitReportTNBTitle: "손익 리포트",
        billingTNBTitle: "청구 관리",
        cardSalesTNBTitle: "카드매출 관리",
        transactionsTNBTitle: "거래 조회",
        taxTimelineTNBTitle: "세금 납부 일정",
        humanResourcesTNBTitle: "인사 관리",
        documentBundleTNBTitle: "서식 모음",
        externalSyncsTNBTitle: "외부기관 연동",
        customerServiceTNBTitle: "고객 안내",
        profileTNBTitle: "기본 정보",
    },

    billingDateFilterType: {
        thisMonthLabel: "이번달",
        lastMonthLabel: "지난달",
        recentThreeMonthsLabel: "3개월",
    },

    billingFiguresStatusType: {
        processingLabel: "처리중",
        pendingLabel: "확인 필요",
        processedLabel: "완료",
    },

    billingFiguresStatusDropdownType: {
        allLabel: "청구 상태 전체",
        processingLabel: "처리중",
        pendingLabel: "확인 필요",
        processedLabel: "완료",
        allShortLabel: "전체",
        processingShortLabel: "처리중",
        pendingShortLabel: "확인 필요",
        processedShortLabel: "완료",
    },

    billingStatusBoardFiguresItem: {
        wonUnit: "원",
        emptyLabel: "-",
    },

    billingStatusBoardTable: {
        dateHeaderLabel: "청구 일자",
        orderHeaderLabel: "심사차수",
        amountHeaderLabel: "청구 금액",
        pendingHeaderLabel: "지급 불능/보류",
        taxesHeaderLabel: "원천세",
        receiveHeaderLabel: "지급 금액",
        receiveDateHeaderLabel: "지급 일자",
        statusHeaderLabel: "청구 상태",
        dateFormat: "yyyy.MM.dd",
    },

    billingPage: {
        statusBoard: {
            summary: {
                titleWithDateFilter: (dateFilterLabel: string) =>
                    `${dateFilterLabel} 청구 현황`,
                title: "청구 현황",
                billingTitle: "청구 신청",
                pendingTitle: "지급 불능/보류",
                processedTitle: "지급완료",
            },
            figures: {
                titleWithDateFilter: (dateFilterLabel: string) =>
                    `${dateFilterLabel} 청구 금액`,
                title: "청구 금액",
                billingTitle: "청구 신청",
                pendingTitle: "지급 불능/보류",
                taxesTitle: "원천세",
                processedTitle: "지급완료",
            },
            table: {
                title: "목록",
            },
        },
        yearlyTable: {
            title: "연간 지표",
        },
    },

    billingStatusBoardSummaryItem: {
        needCheckLabel: "확인이 필요해요",
        emptyLabel: "-",
        eaUnit: "건",
    },

    billingYearlyTable: {
        billingLabel: "청구 신청",
        pendingLabel: "지급 불능/보류",
        taxesLabel: "원천세",
        processedLabel: "지급 금액",
        amountLabel: "금액",
        countLabel: "건 수",
        monthHeaderDateFormat: "M월",
    },

    calendarCell: {
        dayFormat: "d",
    },

    cardSalesDateFilterType: {
        thisMonthLabel: "이번달",
        thisYearLabel: "올해",
        lastYearLabel: "작년",
    },

    cardSalesDetailDialog: {
        creditCardCompanyLabel: "카드사",
        transactionDateTimeLabel: "거래 시간",
        amountLabel: "금액",
        dateTimeFormat: "HH:mm:ss",
    },

    cardSalesPage: {
        statusBoard: {
            cardSales: {
                titleWithDateFilter: (dateFilterLabel: string) =>
                    `${dateFilterLabel} 카드 매출 현황`,
                title: "카드 매출 현황",
                cardSalesLabel: "카드 매출 합계",
                processedLabel: "승인",
                cancelledLabel: "취소",
                pendingLabel: "미매입",
                tableHeaderTitle: "목록",
                cancelFilterLabel: "취소건",
                pendingFilterLabel: "미매입건",
            },
            receive: {
                receiveLabel: "지급액 합계",
                purchasesLabel: "매입",
                transactionsFeeLabel: "수수료",
            },
        },
        detailDialog: {
            cancelledTitle: "카드 취소건",
            pendingTitle: "카드 미매입건",
        },
    },

    cardSalesReceiveTable: {
        dateLabel: "일자",
        receiveLabel: "지급액 합계",
        purchasesLabel: "매입",
        transactionsFeeLabel: "수수료",
        amountLabel: "금액",
        countLabel: "건 수",
        dateFormat: "yyyy.MM.dd",
    },

    cardSalesStatusBoardSummaryItem: {
        eaUnit: "건",
        wonUnit: "원",
    },

    cardSalesTable: {
        dateLabel: "일자",
        cardSalesLabel: "카드 매출 합계",
        processedLabel: "승인",
        cancelledLabel: "취소",
        pendingLabel: "미매입",
        amountLabel: "금액",
        countLabel: "건 수",
        dateFormat: "yyyy.MM.dd",
    },

    common: {
        companyName: "지킴",
        close: "닫기",
        confirm: "확인",
        placeholder: "자리매김",
        unknownErrorTitle: "알 수 없는 오류",
        unknownErrorMessage:
            "에러가 발생했습니다. 잠시 뒤에 다시 시도해주세요.",
    },

    chartEmptyOverlay: {
        label: "아직 집계된 데이터가 없어요",
    },

    dashboardCardFigures: {
        emptyFigures: "-",
    },

    dashboardCardMoreButton: {
        label: "더보기",
    },

    dashboardDetailsButton: {
        label: "자세히",
    },

    dashboardOtherFiguresChartTableRow: {
        emptyLabel: "-",
    },

    dashboardPage: {
        wonUnit: "원",
        eaUnit: "건",
        chartXAxisDateFormat: "yyyy.MM",
        header: {
            titleDateFormat: "yyyy.MM",
            subtitleDateFormat: "yyyy.MM.dd",
            subtitle: (startDate: string, endDate: string) =>
                `${startDate} ~ ${endDate}`,
        },
        dispensing: {
            cardTitle: "조제료",
            comparisonTitle: "지난달 대비",
            emptyComparisonLabel: "아직 집계된 데이터가 없어요",
            billingTitle: "청구",
            averageTitle: "건당 평균",
            headerTitle: "최근 6개월 조제료",
        },
        figures: {
            billingTitle: "청구 지급 불능/보류",
            billingNeedCheckLabel: "확인이 필요한 지급 불능/보류건이 있어요!",
            billingNoCheckNeededLabel: "아직 지급 불능/보류건이 없어요",
            billingShortTitle: "청구",
            pendingShortTitle: "지급 불능/보류",
            cardTitle: "카드 매출",
            cardApprovedShortTitle: "승인",
            cardPendingShortTitle: "미매입",
            cardPendingTitle: "카드 미매입",
            cardPendingDateFormat: "yyyy.MM.dd",
            cardPendingDateShortFormat: "MM.dd",
            cardPendingCountLabel: (count: string) => `${count}건`,
            cardPendingLabel: (pending: string) => `-${pending}`,
            cardPendingDataEmptyLabel: "아직 집계된 데이터가 없어요",
            cardPendingDetailDialogTitle: "카드 미매입건",
        },
        profit: {
            headerTitle: "최근 6개월 손익 추이",
            headerTitleShort: "최근 6개월 손익",
            profitDonutChart: {
                profitLabel: "손익",
                purchasesLabel: "약 매입",
                otherExpensesLabel: "판매관리비",
            },
            legends: {
                profitLabel: "손익",
                salesLabel: "매출",
                purchasesLabel: "약 매입",
                otherExpensesLabel: "판매관리비",
            },
        },
        otherFigures: {
            purchasesTitle: "약 매입",
            otherExpensesTitle: "판매관리비",
            cardTitle: "카드",
            cashTitle: "현금",
            taxInvoiceTitle: "세금계산서",
            personnelCostsTitle: "인건비",
            welfareCostsTitle: "복리후생비",
            otherCostsTitle: "기타",
            mainCostsTitle: "주요 비용",
            previousMonthTitleDateFormat: "지난 달 (M월)",
            currentMonthtitleDateFormat: "이번 달 (M월)",
        },
        transactions: {
            headerTitle: "거래 현황",
            figuresHeaderTitle: (title: string) => `전체 ${title}`,
        },
    },

    dateFilterPeriodButton: {
        datePeriodDateFormat: "yyyy.MM.dd",
        datePeriodDateFormatShort: "yy.MM.dd",
        monthHeaderDateFormat: "yyyy.MM",
        noticeFooterLabel: "*최대 3개월까지 선택 가능합니다",
    },

    dialogDateHeader: {
        dateFormat: "yyyy.MM.dd",
    },

    dialogStatisticRow: {
        eaUnit: "건",
        wonUnit: "원",
    },

    dialogList: {
        emptyLabel: "아직 집계된 데이터가 없어요",
        noticeLabel: "스크롤하여 내역을 확인하실 수 있어요",
    },

    dispensingChart: {
        tooltip: {
            dispensingLabel: (value: string) => `조제료 : ${value}`,
            chargeLabel: (value: string) => `청구 : ${value}건`,
            averageLabel: (value: string) => `건당 평균 : ${value}`,
        },
        highlighted: {
            tooltipLabel: (value: string) => `${value}원`,
            tooltipEmptyLabel: "청구 예정",
        },
    },

    dispensingDateFilterType: {
        thisMonthLabel: "이번달",
        recentSixMonthsLabel: "6개월",
        thisYearLabel: "올해",
    },

    donutChart: {
        wonUnit: "원",
        percentageLabel: (label: string, percentage: string) =>
            `${label} ${percentage}%`,
    },

    donutChartLegends: {
        emptyLabel: "-",
    },

    donutChartType: {
        salesLabel: "매출",
        profitLabel: "손익",
    },

    externalSyncsContent: {
        unsyncedButtonLabel: "연동하기",
        syncedButtonLabel: "다시 연동",
        syncedChipLabel: "연동 완료",
    },

    externalSyncsPage: {
        fetchButtonLabel: "최근 자료 불러오기",
        passwordBasedUnsyncedLabel: "아이디와 비밀번호가 필요해요",
        jointCertBasedUnsyncedLabel: "공동인증서가 필요해요",
        invalidPlatformErrorTitle: "연동 실패",
        invalidPlatformErrorMessage: "현재 지원하지 않는 환경입니다.",
        syncData: {
            conditionsNotMetErrorTitle: "최근 자료 불러오기",
            conditionsNotMetErrorMessage:
                "홈택스, 여신금융협회, 심사평가원 연동이 모두 필요해요.",
        },
        homeTax: {
            title: "홈택스",
            description: "매출/매입 세금계산서 · 현금영수증 조회",
        },
        crefia: {
            title: "여신금융협회",
            description:
                "카드 매출 매입 · 미매입 내역 조회\n카드 승인 · 취소 내역, 수수료, 지급액 조회",
        },
        hira: {
            title: "심사평가원 요양기관정보마당",
            description: "조제료 조회\n청구 현황 조회",
        },
        insurance: {
            title: "4대보험 공단",
            description:
                "국민연금, 건강보험, 고용산재 토탈서비스 연동\n급여명세서 조회",
        },
    },

    getRecentDataDialog: {
        title: "최근 자료 불러오기",
        message:
            "정보를 열심히 가져오고 있어요!\n종료하지 마시고 잠시만 기다려주세요",
        successDialogTitle: "연동 성공",
        successDialogMessage: "최근 자료 불러오기에 성공했어요.",
    },

    generalErrorView: {
        message: "일시적인 오류입니다\n잠시 후 다시 시도해 주세요"
    },

    horizontalStackedBarChart: {
        percentageLabel: (label: string, percentage: string) =>
            `${label} ${percentage}%`,
        emptyLabel: "-",
    },

    lineChart: {
        tooltipLabel: (label: string, value: string) => `${label} : ${value}`,
    },

    lnbItemType: {
        dashboardLabel: "대시보드",
        profitReportLabel: "손익 리포트",
        billingLabel: "청구 관리",
        cardSalesLabel: "카드매출 관리",
        transactionsLabel: "거래 조회",
        taxTimelineLabel: "세금 납부 일정",
        humanResourcesLabel: "인사 관리",
        aiTaxCheckLabel: "AI 세금 점검",
        documentBundleLabel: "서식 모음",
        externalSyncsLabel: "외부기관 연동",
        customerServiceLabel: "고객 안내",
    },

    lnbSectionType: {
        taxLabel: "세금",
        humanResourcesLabel: "인사",
        othersLabel: "기타",
    },

    loading: {
        label: "잠시만 기다려주세요",
    },

    loginPage: {
        welcomeLabel: "지킴에 오신 것을 환영합니다!",
        phoneNumberInputPlaceholder: "휴대폰번호를 입력해주세요",
        certCodeInputPlaceholder: "인증번호를 입력해주세요",
        loginButtonRequestCertCodeLabel: "인증번호 받기",
        loginButtonLoginLabel: "로그인",
        csButtonLabel: "메세지가 오지 않나요?",
        resendButtonLabel: "다시 보내기",
        csDialogTitle: "메세지가 오지 않나요?",
        csDialogMessage:
            "1522-5720 번호로 메시지가 발송되어요.\n인증번호가 수신되지 않을 경우 스팸함을 확인해 주세요.\n\n고객센터(1522-5720) 10:00 ~ 20:00",
        resendDialogTitle: "인증번호 재전송",
        resendDialogMessage: (phoneNumber: string) =>
            `입력하신 휴대폰 번호 (${phoneNumber})로 인증번호가 재전송 되었습니다.`,
        certCodeExpiredErrorMessage: "인증번호가 만료되었습니다.",
        wrongCertCodeErrorMessage: "인증번호가 일치하지 않습니다.",
    },

    notificationDialog: {
        positiveButtonLabel: "확인",
        negativeButtonLabel: "취소",
    },

    numberHelper: {
        thousandsLabel: "천",
        tenThousandsLabel: "만",
        hundredMillionsLabel: "억",
    },

    page: {
        loadingLabel: "잠시만 기다려주세요",
    },

    profitChart: {
        xAxisDateFormat: "MM.dd",
        yAxisUnit: "만",
        tooltip: {
            profitLabel: (value: string) => `손익 : ${value}`,
            salesLabel: (value: string) => `매출 : ${value}`,
            purchasesLabel: (value: string) => `약 매입 : ${value}`,
            otherExpensesLabel: (value: string) => `판매관리비 : ${value}`,
        },
        highlighted: {
            tooltipEmptyLabel: "집계 예정",
        },
    },

    profilePage: {
        contentTitle: "약국정보",
        pharmacyNameTitle: "약국명",
        pharmacistNameTitle: "약국장 이름",
        pharmacyAddressTitle: "약국 주소",
        phoneNumberTitle: "휴대폰 번호",
        businessRegistrationNumberTitle: "사업자등록번호",
        nursingAgencyNumberTitle: "요양기관번호",
        licenseNumberTitle: "약사 면허 번호",
    },

    profitDateFilterType: {
        recentSixMonthsLabel: "6개월",
        thisYearLabel: "올해",
    },

    profitReportFiguresDetailType: {
        cardSalesShortLabel: "카드",
        cashSalesShortLabel: "현금",
        dispensingSalesShortLabel: "공단",
        cardPurchasesShortLabel: "카드",
        cashPurchasesShortLabel: "현금",
        electronicTaxInvoicePurchasesShortLabel: "세금계산서",
        personnelCostsShortLabel: "인건비",
        welfareCostsShortLabel: "복리후생비",
        otherCostsShortLabel: "기타",
        cardSalesYearlyChartLabel: "카드 매출",
        cashSalesYearlyChartLabel: "공단 매출",
        dispensingSalesYearlyChartLabel: "현금 매출",
        cardPurchasesYearlyChartLabel: "카드 매입",
        cashPurchasesYearlyChartLabel: "현금 매입",
        electronicTaxInvoicePurchasesYearlyChartLabel: "세금계산서 매입",
        personnelCostsYearlyChartLabel: "인건비",
        welfareCostsYearlyChartLabel: "복리후생비",
        otherCostsYearlyChartLabel: "기타",
    },

    profitReportPage: {
        profit: {
            statusBoard: {
                summary: {
                    profitLabel: "손익",
                    salesLabel: "매출 합계",
                    purchasesLabel: "약 매입",
                    otherExpensesLabel: "판매관리비",
                },
                profitChart: {
                    titleWithDateFilter: (dateFilterLabel: string) =>
                        `${dateFilterLabel} 손익 추이`,
                    title: "손익 추이",
                    xAxisDateFormat: "MM.dd",
                    accumulatedValuesButtonLabel: "누적 값으로 보기",
                },
                donutChart: {
                    titleWithDateFilter: (dateFilterLabel: string) =>
                        `${dateFilterLabel} 손익 분석`,
                    title: "손익 분석",
                    profitDonutChartProfitLabel: "손익",
                    profitDonutChartPurchasesLabel: "약 매입",
                    profitDonutChartOtherExpensesLabel: "판매관리비",
                    salesChartTitle: "매출합계",
                    profitChartTitle: "손익",
                    profitChartDescriptionOne: "손익은 매출 합계 ",
                    profitChartDescriptionTwo: "에서\n약 매입+판매관리비 ",
                    profitChartDescriptionThree: "을 제외한 금액입니다",
                },
                horizontalStackedBarChart: {
                    purchasesTitle: "약 매입",
                    otherExpensesTitle: "판매관리비 (인건비+복리후생비+기타)",
                },
            },
            yearlyTable: {
                title: "연간 지표",
            },
            yearlyChart: {
                title: "항목별 비용 추이",
                xAxisDateFormat: "M월",
                salesChartTitle: "매출 및 약 매입",
                purchasesLabel: "약 매입",
                otherExpensesChartTitle: "판매관리비",
            },
        },
        dispensing: {
            statusBoard: {
                titleWithDateFilter: (dateFilterLabel: string) =>
                    `${dateFilterLabel} 조제료 현황`,
                title: "조제료 현황",
                noticeLabel: "*요양 급여 조제료 (전체 조제료의 90%) 입니다",
                dispensingTitle: "청구 조제료",
                billingTitle: "청구 건수",
                averageTitle: "청구 건당 평균 조제료",
                dispensingUnit: "원",
                billingUnit: "건",
                averageUnit: "원",
                accumulatedLabel: "누적 값으로 보기",
                xAxisDateFormat: "MM.dd",
            },
        },
    },

    profitReportStatusBoardOtherExpensesInfoButton: {
        title: "판매관리비 (인건비+복리후생비+기타)",
        contentOneTitle: "인건비",
        contentOne:
            " : 정규 인원에 대한 4대보험공단 신고 보수액에 '인사 관리'에서 유저가 수기 조정하거나 비정규 인원 추가 입력분을 더한 금액입니다.",
        contentTwoTitle: "복리후생비",
        contentTwo:
            " : 식음료대, 체력단련비, 회식비 등 임직원의 복리후생과 관련된 사업장 사용 비용 금액입니다.",
        contentThreeTitle: "기타",
        contentThree:
            " : 통신비 및 솔루션 사용료, 임차료, 소모품비 등 인건비와 복리후생비를 제외한 금액의 합산입니다.",
    },

    profitReportProfitStatusBoardSummaryItem: {
        wonUnit: "원",
    },

    profitReportType: {
        profitLabel: "손익 현황",
        dispensingLabel: "조제료 현황",
    },

    profitReportYearlyTableHeaderType: {
        cardSalesLabel: "카드 매출",
        dispensingSalesLabel: "공단 매출",
        cashSalesLabel: "현금 매출",
        purchasesLabel: "약 매입",
        salesProfitLabel: "매출 총 이익\n(매출-약 매입)",
        personnelCostsLabel: "인건비",
        welfareCostsLabel: "복리후생비",
        otherExpensesLabel: "기타",
        profitLabel: "손익",
    },

    statisticItem: {
        emptyFigures: "-",
    },

    syncCrefiaDialog: {
        title: "여신금융협회 연동",
        subtitle: "카드 연동을 위해 여신금융협회 로그인이 필요해요",
        userIDPlaceholder: "여신금융협회 아이디",
        passwordPlaceholder: "여신금융협회 비밀번호",
        findIDPasswordButtonLabel: "아이디 / 비밀번호 찾기",
        loginButtonLabel: "로그인",
        joinButtonLabel: "여신금융협회 회원가입",
        successDialogTitle: "연동 성공",
        successDialogMessage: "연동에 성공하였습니다.",
    },

    syncHomeTaxDialog: {
        title: "홈택스 연동",
        subtitle: "매출 연동을 위해 홈택스 로그인이 필요해요",
        userIDPlaceholder: "홈택스 아이디",
        passwordPlaceholder: "홈택스 비밀번호",
        findIDPasswordButtonLabel: "아이디 / 비밀번호 찾기",
        loginButtonLabel: "로그인",
        joinButtonLabel: "홈택스 회원가입",
        successDialogTitle: "연동 성공",
        successDialogMessage: "연동에 성공하였습니다.",
    },

    tableEmptyLabel: {
        emptyLabel: "아직 집계된 데이터가 없어요",
    },

    taxTimelinePage: {
        header: {
            dateFormat: "yyyy",
        },
        table: {
            title: "월별 세금신고 일정",
        },
    },

    taxTimelineSummaryItem: {
        titleDateFormat: "M월",
        titleOne: (date: string) => `${date}은 `,
        titleTwo: (description: string) => `${description} `,
        titleThree: "납부의 달입니다",
        declarationDateFormat: "M월 d일",
        declarationLabelOne: "신고 기간은 ",
        declarationLabelTwo: (start: string, end: string) =>
            `${start} ~ ${end} `,
        declarationLabelThree: "입니다",
        lastPaidLabelDateFormat: "yyyy",
        lastPaidLabelOne: (date: string) => `지난해(${date}년) `,
        lastPaidLabelTwo: (description: string) => `${description} `,
        lastPaidLabelThree: "납부액은 ",
        lastPaidLabelFour: (amount: string) => `${amount}원`,
        lastPaidLabelFive: "이었습니다",
    },

    taxTimelineTable: {
        dueHeaderLabel: "신고납부 기한",
        descriptionHeaderLabel: "납부 세금",
        everyMonthLabel: "매월",
        monthColumnDateFormat: "M월",
        fixedRowDueLabel: "익월 10일",
        fixedRowDescriptionOne: "근로소득세 (갑)",
        fixedRowDescriptionTwo: "사업소득세",
        rowDueDateFormat: "d일",
    },

    tnb: {
        logoutDialogTitle: "로그아웃 하시겠어요?",
        logoutDialogMessage: "확인 버튼을 누르면 로그아웃 됩니다.",
    },

    transactionsCalendar: {
        monthHeaderDateFormat: "yyyy.MM",
        todayButtonLabel: "오늘",
        figuresHeaderUnit: "원",
        collectingLabel: "집계중",
    },

    transactionsDateFilterType: {
        thisMonthLabel: "이번달",
        lastMonthLabel: "지난달",
        recentThreeMonthsLabel: "3개월",
    },

    transactionsDetailDialog: {
        creditCardHeaderTitle: "카드사",
        detailsHeaderTitle: "상세",
        dateTimeHeaderTitle: "거래 시간",
        amountHeaderTitle: "금액",
        transactionTimeFormat: "HH:mm:ss",
    },

    transactionsFiguresType: {
        cardSalesShortLabel: "카드",
        cashSalesShortLabel: "현금",
        dispensingSalesShortLabel: "공단",
        expectedCardReceiptShortLabel: "카드 지급액",
        expectedDispensingSalesReceiptShortLabel: "공단 지급액",
        cardPurchasesShortLabel: "카드",
        cashPurchasesShortLabel: "현금",
        electronicTaxInvoicePurchasesShortLabel: "전자세금계산서",
        cardSalesDetailDialogTitle: "카드 매출",
        cashSalesDetailDialogTitle: "현금 매출",
        dispensingSalesDetailDialogTitle: "공단 매출",
        expectedCardReceiptDetailDialogTitle: "카드 예상 지급액",
        expectedDispensingSalesReceiptDetailDialogTitle:
            "공단 매출 예상 지급액",
        cardPurchasesDetailDialogTitle: "카드 매입",
        cashPurchasesDetailDialogTitle: "현금 매입",
        electronicTaxInvoicePurchasesDetailDialogTitle: "전자세금계산서 매입",
        cardSalesCalendarListButtonsLabel: "카드",
        cashSalesCalendarListButtonsLabel: "현금",
        dispensingSalesCalendarListButtonsLabel: "공단",
        expectedCardReceiptCalendarListButtonsLabel: "카드",
        expectedDispensingSalesReceiptCalendarListButtonsLabel: "공단",
        cardPurchasesCalendarListButtonsLabel: "카드",
        cashPurchasesCalendarListButtonsLabel: "현금",
        electronicTaxInvoicePurchasesCalendarListButtonsLabel: "전자세금계산서",
    },

    transactionsList: {
        dateHeaderLabel: (date: string, weekday: string) =>
            `${date} ${weekday}`,
        dateFormat: "M월 d일",
        collectingLabel: "매출을 집계하는 중입니다",
        emptyLabel: "데이터를 집계하는 중입니다",
        wonUnit: "원",
    },

    transactionsPage: {
        table: {
            titleWithDateFilter: (
                dateFilterLabel: string,
                transactionsType: string
            ) => `${dateFilterLabel} ${transactionsType} 현황`,
            title: (transactionsType: string) => `${transactionsType} 현황`,
            summaryTotalTitle: (transactionsType: string) =>
                `전체 ${transactionsType} 합계`,
            summaryItemTitle: (
                transactionsFiguresType: string,
                transactionsType: string
            ) => `${transactionsFiguresType} ${transactionsType} 합계`,
            headerTitle: "목록",
        },
        calendarList: {
            title: (transactionsType: string) =>
                `월별 ${transactionsType} 현황`,
            totalLabel: (transactionsFiguresType: string) =>
                `전체 ${transactionsFiguresType}`,
            allLabel: "전체",
        },
    },

    transactionsTable: {
        dateLabel: "일시",
        totalLabel: (label: string) => `${label} 합계`,
        dateHeaderLabel: (
            transactionsFiguresTypeLabel: string,
            transactionTypeLabel: string
        ) => `${transactionsFiguresTypeLabel} ${transactionTypeLabel}`,
        dateFormat: "yyyy.MM.dd",
        collectingLabel:
            "매출을 집계하는 중입니다\n*0시(자정)~ 23:59분까지의 매출은 다음날 오후 12시(정오)에 표시됩니다",
    },

    transactionsTableSummaryItem: {
        wonUnit: "원",
    },

    transactionsTotalDetailDialog: {
        title: (transactionsType: string) => `${transactionsType} 상세`,
        totalLabel: (transactionsType: string) => `전체 ${transactionsType}`,
        figuresLabel: (
            transactionsFiguresType: string,
            transactionsType: string
        ) => `
            ${transactionsFiguresType} ${transactionsType} 합계`,
        wonUnit: "원",
        creditCardHeaderTitle: "카드사",
        detailsHeaderTitle: "상세",
        dateTimeHeaderTitle: "거래 시간",
        amountHeaderTitle: "금액",
        transactionTimeFormat: "HH:mm:ss",
    },

    transactionsType: {
        salesLabel: "매출 거래",
        expectedReceiptsLabel: "입금 예정",
        purchasesLabel: "매입 거래",
        salesShortLabel: "매출",
        expectedReceiptsShortLabel: "입금",
        purchasesShortLabel: "매입",
        salesTransactionsPageCalendarTitle: "매출",
        expectedReceiptsTransactionsPageCalendarTitle: "입금 예정",
        purchasesTransactionsPageCalendarTitle: "매입",
    },

    yearlyTable: {
        monthCell: {
            label: (month: string) => `${month}월`,
        },
    },

    weekdayType: {
        monShortLabel: "월",
        tueShortLabel: "화",
        wedShortLabel: "수",
        thuShortLabel: "목",
        friShortLabel: "금",
        satShortLabel: "토",
        sunShortLabel: "일",
        monLabel: "월요일",
        tueLabel: "화요일",
        wedLabel: "수요일",
        thuLabel: "목요일",
        friLabel: "금요일",
        satLabel: "토요일",
        sunLabel: "일요일",
    },
};

export default S;
