import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";

enum ProfitReportYearlyTableHeaderType {
    CardSales = "cardSales",
    CashSales = "cashSales",
    DispensingSales = "dispensingSales",
    Purchases = "purchases",
    SalesProfit = "salesProfit",
    PersonnelCosts = "personnelCosts",
    WelfareCosts = "welfareCosts",
    OtherExpenses = "otherExpenses",
    Profit = "profit",
}

export const ProfitReportYearlyTableHeaderTypeMap = {
    heightInPx: (type: ProfitReportYearlyTableHeaderType): number => {
        switch (type) {
            case ProfitReportYearlyTableHeaderType.CardSales:
            case ProfitReportYearlyTableHeaderType.CashSales:
            case ProfitReportYearlyTableHeaderType.DispensingSales:
            case ProfitReportYearlyTableHeaderType.Purchases:
                return 52;
            case ProfitReportYearlyTableHeaderType.SalesProfit:
                return 72;
            case ProfitReportYearlyTableHeaderType.PersonnelCosts:
            case ProfitReportYearlyTableHeaderType.WelfareCosts:
            case ProfitReportYearlyTableHeaderType.OtherExpenses:
            case ProfitReportYearlyTableHeaderType.Profit:
                return 52;
            default:
                throw new Error("Invalid ProfitReportYearlyTableHeaderType");
        }
    },
    topMarginInPx: (type: ProfitReportYearlyTableHeaderType): number => {
        switch (type) {
            case ProfitReportYearlyTableHeaderType.CardSales:
            case ProfitReportYearlyTableHeaderType.CashSales:
            case ProfitReportYearlyTableHeaderType.DispensingSales:
                return 0;
            case ProfitReportYearlyTableHeaderType.Purchases:
            case ProfitReportYearlyTableHeaderType.SalesProfit:
            case ProfitReportYearlyTableHeaderType.PersonnelCosts:
                return 2;
            case ProfitReportYearlyTableHeaderType.WelfareCosts:
            case ProfitReportYearlyTableHeaderType.OtherExpenses:
                return 0;
            case ProfitReportYearlyTableHeaderType.Profit:
                return 2;
            default:
                throw new Error("Invalid ProfitReportYearlyTableHeaderType");
        }
    },
    bottomMarginInPx: (type: ProfitReportYearlyTableHeaderType): number => {
        switch (type) {
            case ProfitReportYearlyTableHeaderType.CardSales:
            case ProfitReportYearlyTableHeaderType.CashSales:
                return 0;
            case ProfitReportYearlyTableHeaderType.DispensingSales:
            case ProfitReportYearlyTableHeaderType.Purchases:
            case ProfitReportYearlyTableHeaderType.SalesProfit:
                return 2;
            case ProfitReportYearlyTableHeaderType.PersonnelCosts:
            case ProfitReportYearlyTableHeaderType.WelfareCosts:
                return 0;
            case ProfitReportYearlyTableHeaderType.OtherExpenses:
                return 2;
            case ProfitReportYearlyTableHeaderType.Profit:
                return 0;
            default:
                throw new Error("Invalid ProfitReportYearlyTableHeaderType");
        }
    },
    topRounded: (type: ProfitReportYearlyTableHeaderType): boolean => {
        switch (type) {
            case ProfitReportYearlyTableHeaderType.CardSales:
                return true;
            case ProfitReportYearlyTableHeaderType.CashSales:
            case ProfitReportYearlyTableHeaderType.DispensingSales:
                return false;
            case ProfitReportYearlyTableHeaderType.Purchases:
            case ProfitReportYearlyTableHeaderType.SalesProfit:
            case ProfitReportYearlyTableHeaderType.PersonnelCosts:
                return true;
            case ProfitReportYearlyTableHeaderType.WelfareCosts:
            case ProfitReportYearlyTableHeaderType.OtherExpenses:
                return false;
            case ProfitReportYearlyTableHeaderType.Profit:
                return true;
            default:
                throw new Error("Invalid ProfitReportYearlyTableHeaderType");
        }
    },
    bottomRounded: (type: ProfitReportYearlyTableHeaderType): boolean => {
        switch (type) {
            case ProfitReportYearlyTableHeaderType.CardSales:
            case ProfitReportYearlyTableHeaderType.CashSales:
                return false;
            case ProfitReportYearlyTableHeaderType.DispensingSales:
            case ProfitReportYearlyTableHeaderType.Purchases:
            case ProfitReportYearlyTableHeaderType.SalesProfit:
                return true;
            case ProfitReportYearlyTableHeaderType.PersonnelCosts:
            case ProfitReportYearlyTableHeaderType.WelfareCosts:
                return false;
            case ProfitReportYearlyTableHeaderType.OtherExpenses:
            case ProfitReportYearlyTableHeaderType.Profit:
                return true;
            default:
                throw new Error("Invalid ProfitReportYearlyTableHeaderType");
        }
    },
    label: (type: ProfitReportYearlyTableHeaderType): string => {
        switch (type) {
            case ProfitReportYearlyTableHeaderType.CardSales:
                return S.profitReportYearlyTableHeaderType.cardSalesLabel;
            case ProfitReportYearlyTableHeaderType.CashSales:
                return S.profitReportYearlyTableHeaderType.cashSalesLabel;
            case ProfitReportYearlyTableHeaderType.DispensingSales:
                return S.profitReportYearlyTableHeaderType.dispensingSalesLabel;
            case ProfitReportYearlyTableHeaderType.Purchases:
                return S.profitReportYearlyTableHeaderType.purchasesLabel;
            case ProfitReportYearlyTableHeaderType.SalesProfit:
                return S.profitReportYearlyTableHeaderType.salesProfitLabel;
            case ProfitReportYearlyTableHeaderType.PersonnelCosts:
                return S.profitReportYearlyTableHeaderType.personnelCostsLabel;
            case ProfitReportYearlyTableHeaderType.WelfareCosts:
                return S.profitReportYearlyTableHeaderType.welfareCostsLabel;
            case ProfitReportYearlyTableHeaderType.OtherExpenses:
                return S.profitReportYearlyTableHeaderType.otherExpensesLabel;
            case ProfitReportYearlyTableHeaderType.Profit:
                return S.profitReportYearlyTableHeaderType.profitLabel;
            default:
                throw new Error("Invalid ProfitReportYearlyTableHeaderType");
        }
    },
    color: (type: ProfitReportYearlyTableHeaderType): string => {
        switch (type) {
            case ProfitReportYearlyTableHeaderType.CardSales:
            case ProfitReportYearlyTableHeaderType.CashSales:
            case ProfitReportYearlyTableHeaderType.DispensingSales:
                return Palette.deepBlue;
            case ProfitReportYearlyTableHeaderType.Purchases:
                return Palette.orange500;
            case ProfitReportYearlyTableHeaderType.SalesProfit:
                return Palette.gray800;
            case ProfitReportYearlyTableHeaderType.PersonnelCosts:
            case ProfitReportYearlyTableHeaderType.WelfareCosts:
            case ProfitReportYearlyTableHeaderType.OtherExpenses:
                return Palette.gray500;
            case ProfitReportYearlyTableHeaderType.Profit:
                return Palette.lightGreen;
            default:
                throw new Error("Invalid ProfitReportYearlyTableHeaderType");
        }
    },
    dataBold: (type: ProfitReportYearlyTableHeaderType): boolean => {
        switch (type) {
            case ProfitReportYearlyTableHeaderType.CardSales:
            case ProfitReportYearlyTableHeaderType.CashSales:
            case ProfitReportYearlyTableHeaderType.DispensingSales:
            case ProfitReportYearlyTableHeaderType.Purchases:
            case ProfitReportYearlyTableHeaderType.SalesProfit:
            case ProfitReportYearlyTableHeaderType.PersonnelCosts:
            case ProfitReportYearlyTableHeaderType.WelfareCosts:
            case ProfitReportYearlyTableHeaderType.OtherExpenses:
                return false;
            case ProfitReportYearlyTableHeaderType.Profit:
                return true;
            default:
                throw new Error("Invalid ProfitReportYearlyTableHeaderType");
        }
    },
};

export default ProfitReportYearlyTableHeaderType;
