import NumberHelper from "config/helper/number_helper";
import DonutChart from "presentation/components/charts/donut_chart/donut_chart";
import ProfitReportStatusBoardDonutChartColumn
    from "presentation/pages/profit_report/components/profit/status_board/profit_report_profit_status_board_donut_chart_column";
import profitReportProfitStatusBoardSelector
    from "presentation/states/profit_report/selector/profit/status_board/profit_report_status_board_selector";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const DescriptionContainer = styled.p.attrs<{ $visible: boolean }>((props) => ({
    style: {
        opacity: props.$visible ? 1 : 0,
    },
}))`
    min-width: max-content;
    ${Fonts.detail2Medium};
    color: ${Palette.gray600};
    white-space: pre-wrap;
    text-align: center;
    transition: opacity 0.3s;
`;

const BoldContainer = styled.b`
    ${Fonts.detail2};
    color: ${Palette.gray800};
`;

const ProfitReportProfitStatusBoardProfitDonutChartView = () => {
    const {data} = useRecoilValue(profitReportProfitStatusBoardSelector);

    const {dataEmpty, salesTotal, profitData, profitTotal} = useMemo(() => {
        const dataEmpty = !data.length;
        const salesTotal = data.reduce((acc, cur) => acc + cur.sales[0], 0);

        const profitData = [
            {
                label: S.profitReportPage.profit.statusBoard.donutChart
                    .profitDonutChartProfitLabel,
                value: 0,
                color: Palette.lightGreen,
            },
            {
                label: S.profitReportPage.profit.statusBoard.donutChart
                    .profitDonutChartPurchasesLabel,
                value: 0,
                color: Palette.orange500,
            },
            {
                label: S.profitReportPage.profit.statusBoard.donutChart
                    .profitDonutChartOtherExpensesLabel,
                value: 0,
                color: Palette.gray500,
            },
        ];
        data.forEach((d) => {
            profitData[0].value += d.profit;
            profitData[1].value += d.otherExpenses[0];
            profitData[2].value += d.purchases[0];
        });
        const profitTotal = data.reduce((acc, cur) => acc + cur.profit, 0);
        profitData.forEach((d) => (d.value = Math.abs(d.value)));

        return {
            dataEmpty,
            salesTotal: dataEmpty ? undefined : salesTotal,
            profitData,
            profitTotal: dataEmpty ? undefined : profitTotal,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <ProfitReportStatusBoardDonutChartColumn>
            <DonutChart
                donutSizeInPx={280}
                data={profitData}
                title={
                    S.profitReportPage.profit.statusBoard.donutChart
                        .profitChartTitle
                }
                value={profitTotal}
            />
            <DescriptionContainer $visible={!dataEmpty}>
                {
                    S.profitReportPage.profit.statusBoard.donutChart
                        .profitChartDescriptionOne
                }
                <BoldContainer>
                    {NumberHelper.toChartYAxisAbbreviatedString(
                        salesTotal ?? 0
                    )}
                </BoldContainer>
                {
                    S.profitReportPage.profit.statusBoard.donutChart
                        .profitChartDescriptionTwo
                }
                <BoldContainer>
                    {NumberHelper.toChartYAxisAbbreviatedString(
                        profitData[1].value + profitData[2].value
                    )}
                </BoldContainer>
                {
                    S.profitReportPage.profit.statusBoard.donutChart
                        .profitChartDescriptionThree
                }
            </DescriptionContainer>
        </ProfitReportStatusBoardDonutChartColumn>
    );
};

export default ProfitReportProfitStatusBoardProfitDonutChartView;
