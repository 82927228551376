import SortType from "domain/model/common/sort_type";
import BillingFiguresStatusDropdownType, {
    BillingFiguresStatusDropdownTypeMap,
} from "domain/model/tax/billing/billing_figures_status_dropdown_type";
import BillingStatusBoardTable
    from "presentation/pages/billing/view/status_board/components/billing_status_board_table/billing_status_board_table";
import billingState from "presentation/states/billing/billing_state";
import billingStatusBoardSelector from "presentation/states/billing/selector/billing_status_board_selector";
import {useMemo} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";

const BillingStatusBoardTableView = () => {
    const {selectedDropdownType, dateSortType, billingAmountSortType, data} =
        useRecoilValue(billingStatusBoardSelector);
    const setState = useSetRecoilState(billingState);

    const _data = useMemo(() => {
        if (selectedDropdownType === BillingFiguresStatusDropdownType.All) {
            return data;
        }

        return data.filter(
            (d) =>
                d.status ===
                BillingFiguresStatusDropdownTypeMap.billingFiguresStatusType(
                    selectedDropdownType
                )
        );
    }, [data, selectedDropdownType]);

    const onSortChange = (
        dateSortType: SortType,
        billingAmountSortType: SortType
    ) =>
        setState((prev) => ({
            ...prev,
            statusBoard: {
                ...prev.statusBoard,
                dateSortType,
                billingAmountSortType,
            },
        }));

    return (
        <BillingStatusBoardTable
            data={_data}
            dateSortType={dateSortType}
            billingAmountSortType={billingAmountSortType}
            onSortChange={onSortChange}
        />
    );
};

export default BillingStatusBoardTableView;
