import EnvironmentConstants from "config/environment_constants";
import BaseRepository from "data/repository/base_repository";
import DomainError from "domain/model/common/domain_error";
import ErrorCode from "domain/model/common/error_code";
import JointCert from "domain/model/joint_cert/joint_cert";
import JointCertKey from "domain/model/joint_cert/joint_cert_key";
import getPlatformType from "presentation/utils/functions/get_platform_type";
import {optional} from "presentation/utils/types/optional";

export default class JointCertRepository extends BaseRepository {
    isInstalled = async (): Promise<boolean> =>
        await this.localHandler({
            request: async (client) => {
                try {
                    await client.post("?op=setup");

                    return true;
                } catch (_) {
                    return false;
                }
            },
        });

    getInstallURL = (): optional<string> => {
        const platform = getPlatformType();

        switch (platform) {
            case "mobile":
                return undefined;

            case "macOS":
                return "https://www.infotech.co.kr/iftnxweb_macos_x64_1.5.pkg";

            case "windows":
                return "https://www.infotech.co.kr/ExAdapter_Web_Setup_20221130.exe";

            default:
                throw new Error("Invalid PlatformType");
        }
    };

    getJointCert = async (): Promise<JointCert> =>
        await this.localHandler({
            request: async (client) => {
                const {data} = await client.post("?op=certSelect", {
                    certImageUrl: EnvironmentConstants.imageBannerUrl,
                    nxKeypad: "",
                });

                if (!data.file1 || !data.file2) {
                    throw new DomainError(
                        ErrorCode.JointCertWindowClosed,
                        "Joint cert window closed"
                    );
                }

                return {
                    certName: data.cert_nm,
                    registeredDate: data.pub_dt,
                    expireDate: data.end_dt + " 23:59:59",
                    organization: data.org_nm,
                    oid: data.oid,
                    serialNumber: data.sn,
                    derFilePath: data.file1,
                    keyFilePath: data.file2,
                    certPassword: data.cert_pw,
                };
            },
        });

    getJointCertKey = async (jointCert: JointCert): Promise<JointCertKey> =>
        await this.localHandler({
            request: async (client) => {
                const response = await client.post("?op=execute", {
                    orgCd: "common",
                    svcCd: "getCertInfo",
                    appCd: EnvironmentConstants.nxAppCode,
                    signCert: jointCert.derFilePath,
                    signPri: jointCert.keyFilePath,
                    signPw: jointCert.certPassword,
                });

                const data = response.data;

                const certDerTokens = (data.DER2PEM as string).split("\n");
                const certKeyTokens = (data.KEY2PEM as string).split("\n");

                const tokenValid = (token: string) => {
                    if (token.length <= 0) return false;
                    if (token.includes("-----BEGIN")) return false;
                    if (token.includes("-----END")) return false;

                    return true;
                };

                const certDer = certDerTokens.filter(tokenValid).join("\n");
                const certKey = certKeyTokens.filter(tokenValid).join("\n");

                return {
                    certDer,
                    certKey,
                };
            },
        });
}
