import {MouseEvent, PropsWithChildren, useCallback, useEffect} from "react";
import styled from "styled-components";
import Palette from "presentation/theme/palette";
import {useRecoilValue} from "recoil";
import uiDialogStateSelector from "presentation/states/ui/selector/ui_dialog_state_selector";
import {animated, useTransition} from "@react-spring/web";
import FocusTrap from "focus-trap-react";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";

const FixedPositionContainer = styled(animated.div)`
    width: 100%;
    height: 100dvh;
    height: 100lvh; // fallback
    height: 100vh; // fallback
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2147483646;
`;

const DialogBackgroundContainer = styled(FixedPositionContainer)<{
    $backgroundColor: string;
}>`
    width: 100%;
    height: 100dvh;
    height: 100lvh; // fallback
    height: 100vh; // fallback
    background-color: ${(props) => props.$backgroundColor};
    display: grid;
    place-items: center;
`;

const DialogContainer = styled(FixedPositionContainer)`
    width: fit-content;
    height: fit-content;
    position: absolute;
    transform-origin: 0% 0%;
    top: 50%;
    left: 50%;
    z-index: 2147483647;
`;

const DialogFocusTrapButton = styled.button`
    width: 0;
    height: 0;
    background-color: transparent;
    border: none;
    position: absolute;
`;

const DialogProvider = ({children}: PropsWithChildren) => {
    const dismissDialog = useDismissDialog();

    const dialogState = useRecoilValue(uiDialogStateSelector);
    const anyVisible = dialogState.length > 0;
    const lastState = anyVisible ? dialogState.at(-1) : undefined;

    const transitions = useTransition(dialogState, {
        keys: (item) => item.className,
        from: {opacity: 0, transform: "scale(0.95) translate(-50%, -50%)"},
        enter: {opacity: 1, transform: "scale(1) translate(-50%, -50%)"},
        leave: {opacity: 0, transform: "scale(0.95) translate(-50%, -50%)"},
        update: (_, index) => ({
            opacity: index === dialogState.length - 1 ? 1 : 0,
            pointerEvents: index === dialogState.length - 1 ? "auto" : "none",
        }),
    });

    const onDialogForegroundClick = useCallback((event: MouseEvent) => {
        event.stopPropagation();
    }, []);

    const onBackgroundClick = useCallback(
        ({
             className,
             dismissible,
         }: {
            className: string;
            dismissible: boolean;
        }) =>
            () => {
                if (!dismissible) return;
                dismissDialog(className);
            },
        [dismissDialog]
    );

    useEffect(() => {
        const {style} = document.body;

        if (!style) return;

        if (anyVisible) {
            style.setProperty("width", "100%");
            style.setProperty("touch-action", "none");
            style.setProperty("overflow", "hidden");
        } else {
            style.removeProperty("width");
            style.removeProperty("touch-action");
            style.removeProperty("overflow");
        }

        return () => {
            style.removeProperty("width");
            style.removeProperty("touch-action");
            style.removeProperty("overflow");
        };
    }, [anyVisible]);

    useEffect(() => {
        if (!lastState) return;

        const onEscKeyUp = (event: KeyboardEvent) => {
            event.stopPropagation();
            event.preventDefault();
            if (!lastState.dismissible) return;
            if (event.key !== "Escape") return;
            dismissDialog(lastState.className);
        };

        document.addEventListener("keyup", onEscKeyUp);

        return () => document.removeEventListener("keyup", onEscKeyUp);
    }, [lastState, dismissDialog]);

    return (
        <>
            {children}
            {transitions(({transform, ...rest}, item) => (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                    }}
                >
                    <DialogBackgroundContainer
                        onClick={onBackgroundClick({
                            className: item.className,
                            dismissible: item.dismissible,
                        })}
                        style={rest}
                        $backgroundColor={Palette.black20}
                    >
                        <DialogContainer
                            style={{
                                transform,
                                ...rest,
                            }}
                            onClick={onDialogForegroundClick}
                        >
                            <DialogFocusTrapButton/>
                            {item.component}
                        </DialogContainer>
                    </DialogBackgroundContainer>
                </FocusTrap>
            ))}
        </>
    );
};

export default DialogProvider;
