import SizedBox from "presentation/components/common/sized_box";
import LoginCertCodeView from "presentation/pages/login/view/login_cert_code_view";
import LoginLogoView from "presentation/pages/login/view/login_logo_view";
import LoginPhoneNumberView from "presentation/pages/login/view/login_phone_number_view";
import loginState from "presentation/states/login/login_state";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import {useRecoilValue, useResetRecoilState} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ContentContainer = styled.div`
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const RelativeContainer = styled.div.attrs<{ $height: string }>((props) => ({
    style: {
        height: props.$height,
    },
}))`
    width: 100%;
    position: relative;
    transition: height 0.3s ease-in-out;
`;

export const LoginPage = () => {
    const {certCodeRequested} = useRecoilValue(loginState);
    const resetState = useResetRecoilState(loginState);

    useLifeCycle({
        onDispose: () => resetState(),
    });

    const height = certCodeRequested ? "11rem" : "8rem";

    return (
        <LayoutContainer>
            <ContentContainer>
                <LoginLogoView/>
                <SizedBox height={"36px"}/>
                <RelativeContainer $height={height}>
                    <LoginPhoneNumberView/>
                    <LoginCertCodeView/>
                </RelativeContainer>
            </ContentContainer>
        </LayoutContainer>
    );
};

export default LoginPage;
