enum SortType {
    None = "none",
    Ascending = "ascending",
    Descending = "descending",
}

export const SortTypeMap = {
    next: (sortType: SortType): SortType => {
        switch (sortType) {
            case SortType.None:
                return SortType.Ascending;
            case SortType.Ascending:
                return SortType.Descending;
            case SortType.Descending:
                return SortType.None;
            default:
                throw new Error("Invalid SortType");
        }
    },
};

export default SortType;
