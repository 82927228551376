import {httpClientCancelSubject} from "data/network/http_client/http_client";
import {useEffect, useLayoutEffect} from "react";

export const useLifeCycle = ({
                                 onPreInit,
                                 onInit,
                                 onUnload,
                                 onDispose,
                             }: {
    onPreInit?: () => void;
    onInit?: () => void;
    onUnload?: () => void; /// on refresh
    onDispose?: () => void; /// on route change
}) => {
    const unload = () => {
        httpClientCancelSubject.next();
        onUnload?.();
    };

    const dispose = () => {
        httpClientCancelSubject.next();
        onDispose?.();
    };

    useLayoutEffect(() => {
        onPreInit?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onInit?.();
        window.addEventListener("beforeunload", unload);

        return () => {
            window.removeEventListener("beforeunload", unload);
            dispose?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
