import TransactionsRepository from "data/repository/transactions_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import TransactionsDateFilterType, {
    TransactionsDateFilterTypeMap,
} from "domain/model/tax/transactions/transactions_date_filter_type";
import {TransactionsTypeMap} from "domain/model/tax/transactions/transactions_type";
import Page from "presentation/components/common/page";
import SizedBox from "presentation/components/common/sized_box";
import {TransactionsPageContext} from "presentation/pages/transactions/transactions_page";
import TransactionsCalendarListView
    from "presentation/pages/transactions/view/calendar_list/transactions_calendar_list_view";
import TransactionsTableView from "presentation/pages/transactions/view/table/transactions_table_view";
import {transactionsState} from "presentation/states/transactions/transactions_state";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import {useContext, useMemo, useState} from "react";
import {useResetRecoilState, useSetRecoilState} from "recoil";

const TransactionsView = () => {
    const {pageMinWidth, transactionsType} = useContext(
        TransactionsPageContext
    );
    const repository = useRepository(TransactionsRepository);

    const setState = useSetRecoilState(transactionsState);
    const resetState = useResetRecoilState(transactionsState);

    const [status, setStatus] = useState(ViewStatusType.Loading);

    useLifeCycle({
        onInit: () =>
            repository({
                handler: async (repository) => {
                    const tableDateFilter =
                        TransactionsDateFilterType.ThisMonth;
                    const {startDate, endDate} =
                        TransactionsDateFilterTypeMap.datePeriod(
                            tableDateFilter
                        );

                    const data = await repository.getFilteredFiguresInRange({
                        transactionsType: transactionsType!,
                        startDate,
                        endDate,
                    });

                    setState((prev) => ({
                        ...prev,
                        table: {
                            ...prev.table,
                            selectedDateFilter: tableDateFilter,
                            startDateKey: startDate.key,
                            endDateKey: endDate.key,
                            data,
                        },
                        calendarList: {
                            ...prev.calendarList,
                            data,
                        },
                    }));

                    setStatus(ViewStatusType.Loaded);
                },
            }),
        onDispose: () => resetState(),
    });

    const tableView = useMemo(() => {
        const hasTable = TransactionsTypeMap.hasTable(transactionsType!);

        if (!hasTable) return null;

        return (
            <>
                <TransactionsTableView/>
                <SizedBox height={"24px"}/>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page status={status} minWidth={pageMinWidth}>
            {tableView}
            <TransactionsCalendarListView/>
        </Page>
    );
};

export default TransactionsView;
