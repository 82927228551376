import {animated, useSprings} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import {DispensingChartContext} from "presentation/components/charts/dispensing_chart/dispensing_chart";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {Fragment, useContext, useEffect, useMemo} from "react";
import styled from "styled-components";

const BarContainer = styled(animated.div).attrs<{
    $visible: boolean;
    $widthInPx: number;
    $heightInPx: number;
    $borderRadiusInPx: number;
    $bottomOffsetInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
        borderTopLeftRadius: `${props.$borderRadiusInPx}px`,
        borderTopRightRadius: `${props.$borderRadiusInPx}px`,
        bottom: `${props.$bottomOffsetInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
        opacity: props.$visible ? 1 : 0,
    },
}))`
    background-color: ${Palette.black5};
    position: absolute;
    z-index: 1;
    transform: translateX(-50%);
    transition: height 0.3s ease-in-out, border-radius 0.3s ease-in-out,
    left 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

const TooltipContainer = styled(animated.div).attrs<{
    $heightInPx: number;
    $horizontalPaddingInPx: number;
    $topOffsetInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        height: `${props.$heightInPx}px`,
        padding: `0 ${props.$horizontalPaddingInPx}px`,
        top: `${props.$topOffsetInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
    },
}))`
    min-width: max-content;
    border-radius: 12px;
    background-color: ${Palette.black75};
    box-shadow: 0 0 0 1px ${Palette.white20} inset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translateX(-50%);
    z-index: 15;
    transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
`;

const TooltipLabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail1};
    color: ${Palette.white100};
    text-align: center;
`;

const DispensingChartCollectingBars = () => {
    const context = useContext(DispensingChartContext);

    const data = useMemo(() => {
        if (!context.highlightedData) return [];

        return Array.from(context.highlightedData!).map((d) => {
            const value =
                d.value !== undefined
                    ? NumberHelper.toStringWithDividers(d.value)
                    : undefined;
            const xPos = context.functions.getXPos(d.x);
            const yPosEnd = context.functions.getYPosFromTop(d.y);
            const yPosStart = context.functions.getYPosFromTop(0);
            const yPosStartFromBottom = context.functions.getYPosFromBottom(0);
            const height = Math.abs(yPosStart - yPosEnd);
            const borderRadius =
                height >= context.constants.barBorderRadiusInPx
                    ? context.constants.barBorderRadiusInPx
                    : height;

            let tooltipYPos =
                yPosEnd -
                context.constants.collectingTooltipMarginInPx -
                context.constants.collectingTooltipHeightInPx;

            if (tooltipYPos < 0) tooltipYPos = 0;

            return {
                key: d.x,
                barVisible: value === undefined,
                value,
                xPos,
                yPosStartFromBottom,
                height,
                borderRadius,
                tooltipYPos,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.highlightedData, context.functions, context.constantsKey]);

    const [springs, api] = useSprings(data.length, () => ({
        height: "0px",
    }));

    useEffect(() => {
        api.start((index) => ({
            height: `${data[index].height}px`,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.data, context.constantsKey]);

    return (
        <>
            {springs.map((props, xIndex) => {
                const d = data[xIndex];

                return (
                    <Fragment key={d.key}>
                        <BarContainer
                            style={props}
                            $visible={d.barVisible}
                            $widthInPx={context.constants.binSizeInPx}
                            $heightInPx={d.height}
                            $borderRadiusInPx={d.borderRadius}
                            $bottomOffsetInPx={d.yPosStartFromBottom}
                            $leftOffsetInPx={d.xPos}
                        />
                        <TooltipContainer
                            $horizontalPaddingInPx={
                                context.constants
                                    .collectingTooltipHorizontalPaddingInPx
                            }
                            $heightInPx={
                                context.constants.collectingTooltipHeightInPx
                            }
                            $topOffsetInPx={d.tooltipYPos}
                            $leftOffsetInPx={d.xPos}
                        >
                            <TooltipLabelContainer>
                                {d.value !== undefined
                                    ? S.dispensingChart.highlighted.tooltipLabel(
                                        d.value
                                    )
                                    : S.dispensingChart.highlighted
                                        .tooltipEmptyLabel}
                            </TooltipLabelContainer>
                        </TooltipContainer>
                    </Fragment>
                );
            })}
        </>
    );
};

export default DispensingChartCollectingBars;
