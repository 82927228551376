import LNB, {LNBContainer} from "presentation/components/navigation/lnb/lnb";
import {
    LNBItemType,
    LNBItemTypeMap,
} from "presentation/components/navigation/lnb/lnb_model";
import TNB, {TNBContainer} from "presentation/components/navigation/tnb/tnb";
import useTNBHeight from "presentation/utils/hooks/use_tnb_height";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {optional} from "presentation/utils/types/optional";
import {useLayoutEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import styled from "styled-components";
import Palette from "presentation/theme/palette";
import {AppRoutesMap} from "presentation/routes/model/app_routes";

const RowContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: ${Palette.gray100};
`;

const ColumnContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    scrollbar-color: ${Palette.gray300} ${Palette.gray100};
`;

const OutletContainer = styled(Outlet)`
    flex-grow: 1;
`;

const MainPage = () => {
    const theme = useThemeContext();
    const {pathname} = useLocation();

    const tnbHeight = useTNBHeight();
    const [selectedItem, setSelectedItem] =
        useState<optional<LNBItemType>>(undefined);

    useLayoutEffect(() => {
        const item = Object.values(LNBItemType).find((item) => pathname === LNBItemTypeMap.route(item));
        setSelectedItem(item);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const route = AppRoutesMap.fromPathname(pathname);
    const title = route !== undefined ? AppRoutesMap.tnbTitle(route) ?? "" : "";

    return (
        <RowContainer>
            <LNB selectedItem={selectedItem}/>
            <TNB title={title}/>
            <LNBContainer
                $lnbMinWidth={theme.componentTheme.lnbMinWidth}
                $lnbMaxWidth={theme.componentTheme.lnbMaxWidth}
            />
            <ColumnContainer>
                <TNBContainer
                    $lnbMinWidth={theme.componentTheme.lnbMinWidth}
                    $lnbMaxWidth={theme.componentTheme.lnbMaxWidth}
                    $height={tnbHeight}
                />
                <OutletContainer/>
            </ColumnContainer>
        </RowContainer>
    );
};

export default MainPage;
