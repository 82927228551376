import ProfitReportDateFilterType, {
    ProfitReportDateFilterTypeMap,
} from "domain/model/tax/profit_report/profit_report_date_filter_type";
import OptionButton from "presentation/components/button/option_button";
import profitReportState from "presentation/states/profit_report/profit_report_state";
import profitReportProfitStatusBoardSelector
    from "presentation/states/profit_report/selector/profit/status_board/profit_report_status_board_selector";
import {useMemo} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import DateButtonsRow from "presentation/components/common/date_buttons_row";
import useRepository from "presentation/utils/hooks/use_repository";
import ProfitReportRepository from "data/repository/profit_report_repository";
import ProfitReportFilteredFigures from "domain/model/tax/profit_report/profit_report_filtered_figures";

const ProfitReportProfitStatusBoardDateButtonsView = () => {
    const repository = useRepository(ProfitReportRepository);

    const setState = useSetRecoilState(profitReportState);
    const {selectedDateFilter} = useRecoilValue(
        profitReportProfitStatusBoardSelector
    );

    const dateFilters = useMemo(
        () => Object.values(ProfitReportDateFilterType),
        []
    );

    const onClick = (type: ProfitReportDateFilterType) => () =>
        repository({
            handler: async (repository) => {
                const {startDate, endDate} =
                    ProfitReportDateFilterTypeMap.datePeriod(type);

                setState((prev) => ({
                    ...prev,
                    profit: {
                        ...prev.profit,
                        statusBoard: {
                            ...prev.profit.statusBoard,
                            selectedDateFilter: type,
                            startDateKey: startDate.key,
                            endDateKey: endDate.key,
                        },
                    },
                }));

                let data: ProfitReportFilteredFigures[] = [];

                data =
                    await repository.getProfitReportMonthlyFilteredFiguresInRange(
                        {
                            startDate,
                            endDate,
                        }
                    );

                setState((prev) => ({
                    ...prev,
                    profit: {
                        ...prev.profit,
                        statusBoard: {
                            ...prev.profit.statusBoard,
                            selectedDateFilter: type,
                            startDateKey: startDate.key,
                            endDateKey: endDate.key,
                            data,
                        },
                    },
                }));
            },
        });

    return (
        <DateButtonsRow>
            {dateFilters.map((d) => (
                <OptionButton
                    key={d}
                    selected={d === selectedDateFilter}
                    label={ProfitReportDateFilterTypeMap.label(d)}
                    onClick={onClick(d)}
                />
            ))}
        </DateButtonsRow>
    );
};

export default ProfitReportProfitStatusBoardDateButtonsView;
