import CheckToggleButton from "presentation/components/button/check_toggle_button";
import ContentSectionTitle from "presentation/components/content/content_section_title";
import cardSalesState from "presentation/states/card_sales/card_sales_state";
import cardSalesStatusBoardSelector from "presentation/states/card_sales/selector/card_sales_status_board_selector";
import S from "presentation/theme/s";
import {useRecoilValue, useSetRecoilState} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
`;

const CardSalesStatusBoardTableHeaderView = () => {
    const {cancelFilterOn, pendingFilterOn} = useRecoilValue(
        cardSalesStatusBoardSelector
    );
    const setState = useSetRecoilState(cardSalesState);

    const onClickCancelFilter = () =>
        setState((prev) => ({
            ...prev,
            statusBoard: {
                ...prev.statusBoard,
                cancelFilterOn: !prev.statusBoard.cancelFilterOn,
            },
        }));

    const onClickPendingFilter = () =>
        setState((prev) => ({
            ...prev,
            statusBoard: {
                ...prev.statusBoard,
                pendingFilterOn: !prev.statusBoard.pendingFilterOn,
            },
        }));

    return (
        <ContentSectionTitle
            title={S.cardSalesPage.statusBoard.cardSales.tableHeaderTitle}
            suffix={
                <LayoutContainer>
                    <CheckToggleButton
                        selected={cancelFilterOn}
                        label={
                            S.cardSalesPage.statusBoard.cardSales
                                .cancelFilterLabel
                        }
                        onClick={onClickCancelFilter}
                    />
                    <CheckToggleButton
                        selected={pendingFilterOn}
                        label={
                            S.cardSalesPage.statusBoard.cardSales
                                .pendingFilterLabel
                        }
                        onClick={onClickPendingFilter}
                    />
                </LayoutContainer>
            }
        />
    );
};

export default CardSalesStatusBoardTableHeaderView;
