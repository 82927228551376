import DashboardCard from "presentation/pages/dashboard/components/dashboard_card";
import DashboardCardFigures from "presentation/pages/dashboard/components/dashboard_card_figures";
import DashboardFiguresComparison from "presentation/pages/dashboard/components/dashboard_card_figures_comparison";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useMemo} from "react";
import {useRecoilValue} from "recoil";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import DashboardCardColumn from "presentation/pages/dashboard/components/dashboard_card_column";
import StatisticNumberSmall from "presentation/components/statistic/statistic_number_small";
import StatisticTitleLarge from "presentation/components/statistic/statistic_title_large";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import dashboardDispensingSelector from "presentation/states/dashboard/selector/dashboard_dispensing_selector";

const DashboardDispensingSummaryView = () => {
    const {height} = useContext(DashboardRowContext);

    const {previousYearMonth, selectedYearMonth} = useRecoilValue(
        dashboardDateSelector
    );
    const {status, data} = useRecoilValue(dashboardDispensingSelector);

    const {previousData, currentData, currentAverage} = useMemo(
        () => ({
            previousData: data.find((d) =>
                d.date.isSameMonth(previousYearMonth)
            ),
            currentData: data.find((d) =>
                d.date.isSameMonth(selectedYearMonth)
            ),
            currentAverage:
                data.at(1) !== undefined && data.at(1)!.billingCount !== 0
                    ? Math.floor(
                        data.at(1)!.dispensingAmount /
                        data.at(1)!.billingCount
                    )
                    : undefined,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const onClick = () => {
        const route = `${AppRoutes.ProfitReport}?type=dispensing&scrollTo=statusBoard`;
        AppRouter.navigate(route);
    };

    return (
        <DashboardCard
            status={status}
            height={height!}
            onClick={onClick}
            loadingColor={Palette.gray300}
            backgroundColor={Palette.gray700}
            hoverColor={Palette.gray600}
            isButton={true}
            upper={
                <DashboardCardColumn>
                    <StatisticTitleLarge
                        title={S.dashboardPage.dispensing.cardTitle}
                        color={Palette.white100}
                    />
                    <StatisticNumberSmall
                        value={currentData?.dispensingAmount}
                        unit={S.dashboardPage.wonUnit}
                        color={Palette.white100}
                    />
                    <DashboardFiguresComparison
                        titleColor={Palette.white100}
                        previousValue={previousData?.dispensingAmount}
                        value={currentData?.dispensingAmount}
                    />
                </DashboardCardColumn>
            }
            lower={
                <DashboardCardColumn>
                    <DashboardCardFigures
                        title={S.dashboardPage.dispensing.billingTitle}
                        value={currentData?.billingCount}
                        unit={S.dashboardPage.eaUnit}
                    />
                    <DashboardCardFigures
                        title={S.dashboardPage.dispensing.averageTitle}
                        value={currentAverage}
                        unit={S.dashboardPage.wonUnit}
                    />
                </DashboardCardColumn>
            }
        />
    );
};

export default DashboardDispensingSummaryView;
