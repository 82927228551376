import SortType from "domain/model/common/sort_type";
import TransactionsDailyFilteredFigures from "domain/model/tax/transactions/transactions_daily_filtered_figures";
import TransactionsTable from "presentation/pages/transactions/components/transactions_table/transactions_table";
import TransactionsTotalDetailDialog from "presentation/pages/transactions/components/transactions_total_detail_dialog";
import {TransactionsPageContext} from "presentation/pages/transactions/transactions_page";
import transactionsTableSelector from "presentation/states/transactions/selector/transactions_table_selector";
import {transactionsState} from "presentation/states/transactions/transactions_state";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import {useContext} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";

const TransactionsDataTableView = () => {
    const {transactionsType} = useContext(TransactionsPageContext);
    const showDialog = useShowDialog();

    const {data, dateSortType, totalSortType} = useRecoilValue(
        transactionsTableSelector
    );
    const setState = useSetRecoilState(transactionsState);

    const onSortChange = (dateSortType: SortType, totalSortType: SortType) =>
        setState((prev) => ({
            ...prev,
            table: {
                ...prev.table,
                dateSortType: dateSortType,
                totalSortType: totalSortType,
            },
        }));

    const onClick = (data: TransactionsDailyFilteredFigures) =>
        showDialog({
            className: "transactions-total-detail-dialog",
            component: (
                <TransactionsTotalDetailDialog
                    transactionsType={transactionsType!}
                    data={data}
                />
            ),
        });

    return (
        <TransactionsTable
            type={transactionsType!}
            data={data}
            dateSortType={dateSortType}
            totalSortType={totalSortType}
            onSortChange={onSortChange}
            onClick={onClick}
        />
    );
};

export default TransactionsDataTableView;
