import {ChartYAxisData} from "presentation/components/charts/common/chart_model";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $widthInPx: number;
    $heightInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
    },
}))`
    position: relative;
`;

const LabelContainer = styled.p.attrs<{ $topOffsetInPx: number }>((props) => ({
    style: {
        top: `${props.$topOffsetInPx}px`,
    },
}))`
    width: 50px;
    position: absolute;
    left: 0;
    ${Fonts.detail1};
    color: ${Palette.gray400};
    text-align: right;
    transform: translateY(-50%);
    transition: top 0.3s ease-in-out;
`;

const ChartYAxis = ({
                        widthInPx,
                        heightInPx,
                        data,
                    }: {
    widthInPx: number;
    heightInPx: number;
    data: ChartYAxisData[];
}) => {
    return (
        <LayoutContainer $widthInPx={widthInPx} $heightInPx={heightInPx}>
            {data.map((d, index) => (
                <LabelContainer key={index} $topOffsetInPx={d.yPosFromTop}>
                    {d.label}
                </LabelContainer>
            ))}
        </LayoutContainer>
    );
};

export default ChartYAxis;
