import NumberHelper from "config/helper/number_helper";
import TransactionsRepository from "data/repository/transactions_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import TransactionsFiguresDetail from "domain/model/tax/transactions/transactions_figures_detail";
import TransactionsFiguresType, {
    TransactionsFiguresTypeMap,
} from "domain/model/tax/transactions/transactions_figures_type";
import SizedBox from "presentation/components/common/sized_box";
import DialogDateHeader from "presentation/components/dialog/common/dialog_date_header";
import DialogList from "presentation/components/dialog/common/dialog_list";
import DialogStatisticRow from "presentation/components/dialog/common/dialog_statistic_row";
import Dialog from "presentation/components/dialog/dialog";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import {useMemo, useState} from "react";

const TransactionsDetailDialog = ({
                                      date,
                                      type,
                                  }: {
    date: DateTime;
    type: TransactionsFiguresType;
}) => {
    const repository = useRepository(TransactionsRepository);

    const [status, setStatus] = useState(ViewStatusType.Loading);
    const [data, setData] = useState<TransactionsFiguresDetail[]>([]);

    useLifeCycle({
        onInit: () =>
            repository({
                handler: async (repository) => {
                    const details = await repository.getFiguresDetails({
                        date,
                        transactionsFiguresType: type,
                    });

                    setData(details);

                    setStatus(ViewStatusType.Loaded);
                },
            }),
    });

    const {count, total, titles, listData} = useMemo(() => {
        const count = data.length;
        const total = data.reduce((acc, cur) => acc + cur.value, 0);
        const titles = [
            TransactionsFiguresTypeMap.detailDialogHasCreditCardCompany(type)
                ? S.transactionsDetailDialog.creditCardHeaderTitle
                : undefined,
            TransactionsFiguresTypeMap.detailDialogHasDetail(type)
                ? S.transactionsDetailDialog.detailsHeaderTitle
                : undefined,
            TransactionsFiguresTypeMap.detailDialogHasDateTime(type)
                ? S.transactionsDetailDialog.dateTimeHeaderTitle
                : undefined,
            S.transactionsDetailDialog.amountHeaderTitle,
        ]
            .filter((t) => t !== undefined)
            .map((t) => t!);
        const listData = data.map((d) =>
            [
                TransactionsFiguresTypeMap.detailDialogHasCreditCardCompany(
                    type
                ) || TransactionsFiguresTypeMap.detailDialogHasDetail(type)
                    ? d.label
                    : undefined,
                TransactionsFiguresTypeMap.detailDialogHasDateTime(type)
                    ? d.dateTime.format(
                        S.transactionsDetailDialog.transactionTimeFormat
                    )
                    : undefined,
                NumberHelper.toStringWithDividers(d.value),
            ]
                .filter((ld) => ld !== undefined)
                .map((ld) => ld!)
        );

        return {
            count,
            total,
            titles,
            listData,
        };
    }, [data, type]);

    return (
        <Dialog
            status={status}
            iconAsset={TransactionsFiguresTypeMap.asset(type)}
            title={TransactionsFiguresTypeMap.detailDialogTitle(type)}
        >
            <DialogDateHeader date={date}/>
            <SizedBox height="8px"/>
            <DialogStatisticRow count={count} total={total}/>
            <SizedBox height={"24px"}/>
            <DialogList autoSize={true} titles={titles} data={listData}/>
        </Dialog>
    );
};

export default TransactionsDetailDialog;
