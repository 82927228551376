import {animated, useTransition} from "@react-spring/web";
import DateFilterPeriodCalendarMonth, {
    DateFilterPeriodCalendarMonthCallerType,
    DateFilterPeriodCalendarMonthTypeCallerMap,
} from "presentation/components/button/date_filter_period_button/components/date_filter_period_calendar_month";
import DateFilterPeriodCalendarMonthHeader
    from "presentation/components/button/date_filter_period_button/components/date_filter_period_calendar_month_header";
import DateFilterPeriodCalendarNoticeFooter
    from "presentation/components/button/date_filter_period_button/components/date_filter_period_calendar_notice_footer";
import Palette from "presentation/theme/palette";
import DateTime from "presentation/utils/extension/date_extension";
import {useMediaQuery} from "presentation/utils/hooks/use_media_query";
import {optional} from "presentation/utils/types/optional";
import {createContext, forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState,} from "react";
import styled from "styled-components";

const dropdownContainerVerticalPaddingInPx = 24;
const dropdownContainerHorizontalPaddingInPx = 16;
const monthHeaderHeightInPx = 24;
const headerGapInPx = 12;
const weekdayHeaderHeightInPx = 20;
const cellSizeInPx = 38;
const rowGapInPx = 8;
const cellHorizontalPaddingInPx = 4;
const noticeFooterHeightInPx = 21;
const monthGapInPx = 24;

export const DateFilterPeriodCalendarContext = createContext<{
    constants: {
        monthWidthInPx: number;
        innerWidthInPx: number;
        cellSizeInPx: number;
        cellHorizontalPaddingInPx: number;
        rowGapInPx: number;
        monthHeaderHeightInPx: number;
        weekdayHeaderHeightInPx: number;
        headerGapInPx: number;
        noticeFooterHeightInPx: number;
        monthGapInPx: number;
    };
    constantsKey: string;
    dropdownVisible: boolean;
    month: DateTime;
    enabledStartDate: DateTime;
    enabledEndDate: DateTime;
    selectedStartDate: optional<DateTime>;
    selectedEndDate: optional<DateTime>;
    onDateClick: (date: DateTime) => void;
    onMonthChange: (
        caller: DateFilterPeriodCalendarMonthCallerType,
        month: DateTime
    ) => void;
}>({
    constants: {
        monthWidthInPx: 314,
        innerWidthInPx: 652,
        cellSizeInPx,
        cellHorizontalPaddingInPx,
        rowGapInPx,
        monthHeaderHeightInPx,
        weekdayHeaderHeightInPx,
        headerGapInPx,
        noticeFooterHeightInPx,
        monthGapInPx,
    },
    constantsKey: "",
    dropdownVisible: false,
    month: DateTime.now(),
    enabledStartDate: DateTime.now().min,
    enabledEndDate: DateTime.now().min,
    selectedStartDate: undefined,
    selectedEndDate: undefined,
    onDateClick: () => {
    },
    onMonthChange: () => {
    },
});

const DropdownContainer = styled.div.attrs<{
    $visible: boolean;
    $widthInPx: number;
    $heightInPx: number;
    $verticalPaddingInPx: number;
    $horizontalPaddingInPx: number;
}>((props) => ({
    style: {
        opacity: props.$visible ? 1 : 0,
        pointerEvents: props.$visible ? "auto" : "none",
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
    },
}))`
    border-top: ${(props) =>
    `${props.$verticalPaddingInPx}px solid ${Palette.white100}`};
    border-right: ${(props) =>
    `${props.$horizontalPaddingInPx}px solid ${Palette.white100}`};
    border-bottom: ${(props) =>
    `${props.$verticalPaddingInPx}px solid ${Palette.white100}`};
    border-left: ${(props) =>
    `${props.$horizontalPaddingInPx}px solid ${Palette.white100}`};
    border-radius: 16px;
    background-color: ${Palette.white100};
    box-shadow: 0px 3px 15px 0px rgba(199, 199, 199, 0.25);
    position: absolute;
    cursor: auto;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%) scale(1);
    transform-origin: top center;
    z-index: 10;
    overflow: hidden;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
        opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    @media (max-width: 500px) {
        transform: translateX(-50%) scale(0.65);
    }

    @media only screen and (min-width: 501px) and (max-width: 768px) {
        transform: translateX(-50%) scale(0.75);
    }
`;

const RelativeContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const HeaderPositionContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 51;
`;

const CalendarPositionContainer = styled(animated.div)<{
    $topOffsetInPx: number;
}>`
    position: absolute;
    top: ${(props) => `${props.$topOffsetInPx}px`};
    right: 0;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const GapContainer = styled.div<{ $widthInPx: number; $heightInPx: number }>`
    min-width: ${(props) => `${props.$widthInPx}px`};
    height: ${(props) => `${props.$heightInPx}px`};
    background-color: ${Palette.white100};
`;

const FooterPositionContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 51;
`;

const DateFilterPeriodDropdown = forwardRef<
    HTMLDivElement,
    {
        visible: boolean;
        calendarStartDate: DateTime;
        startDate: DateTime;
        endDate: DateTime;
        onDateSelected: (startDate: DateTime, endDate: DateTime) => void;
    }
>(
    (
        {visible, calendarStartDate, startDate, endDate, onDateSelected},
        dropdownRef
    ) => {
        const collapsed = useMediaQuery("(max-width: 1000px)");

        const [layout, setLayout] = useState<{
            monthWidthInPx: number;
            innerWidthInPx: number;
            widthInPx: number;
            monthHeightInPx: number;
            innerHeightInPx: number;
            heightInPx: number;
            topOffsetInPx: number;
        }>({
            monthWidthInPx: 314,
            innerWidthInPx: 652,
            widthInPx: 684,
            monthHeightInPx: 250,
            innerHeightInPx: 307,
            heightInPx: 355,
            topOffsetInPx: 36,
        });
        const [month, setMonth] = useState<DateTime>(
            endDate.getFirstDayInMonth()
        );
        const [enabledStartDate, setEnabledStartDate] = useState<DateTime>(
            calendarStartDate.min
        );
        const [enabledEndDate, setEnabledEndDate] = useState<DateTime>(
            DateTime.now().min
        );
        const [selectedStartDate, setSelectedStartDate] =
            useState<optional<DateTime>>(startDate);
        const [selectedEndDate, setSelectedEndDate] =
            useState<optional<DateTime>>(endDate);

        const transitionCallerRef = useRef(
            DateFilterPeriodCalendarMonthCallerType.Previous
        );

        const {constants, constantsKey} = useMemo(() => {
            const constants = {
                cellSizeInPx,
                cellHorizontalPaddingInPx,
                rowGapInPx,
                monthHeaderHeightInPx,
                weekdayHeaderHeightInPx,
                headerGapInPx,
                noticeFooterHeightInPx,
                monthGapInPx,
            };

            const constantsKey = Object.entries(constants)
                .map(([key, value]) => `${key}-${value}`)
                .join();

            return {
                constants,
                constantsKey,
            };
        }, []);

        useLayoutEffect(() => {
            const monthWidthInPx =
                DateTime.daysInWeek *
                (cellSizeInPx + cellHorizontalPaddingInPx * 2) -
                cellHorizontalPaddingInPx * 2;
            const innerWidthInPx = collapsed
                ? monthWidthInPx
                : monthWidthInPx * 2 + monthGapInPx;
            const widthInPx =
                innerWidthInPx + dropdownContainerHorizontalPaddingInPx * 2;
            const leftMonthHeight =
                weekdayHeaderHeightInPx +
                month.copyWith({month: month.month - 1})
                    .orthodoxWeekCountInMonth *
                (cellSizeInPx + rowGapInPx);
            const rightMonthHeight =
                weekdayHeaderHeightInPx +
                month.orthodoxWeekCountInMonth * (cellSizeInPx + rowGapInPx);
            const monthHeightInPx = Math.max(leftMonthHeight, rightMonthHeight);
            const innerHeightInPx =
                monthHeightInPx + monthHeaderHeightInPx + headerGapInPx;
            const heightInPx =
                dropdownContainerVerticalPaddingInPx * 2 +
                monthHeaderHeightInPx +
                headerGapInPx +
                (collapsed ? rightMonthHeight : monthHeightInPx) +
                noticeFooterHeightInPx;

            const topOffsetInPx = monthHeaderHeightInPx + headerGapInPx;

            setLayout({
                monthWidthInPx,
                innerWidthInPx,
                widthInPx,
                monthHeightInPx,
                innerHeightInPx,
                heightInPx,
                topOffsetInPx,
            });

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [collapsed, month.key]);

        useEffect(() => {
            if (
                startDate.isEqual(selectedStartDate) &&
                endDate.isEqual(selectedEndDate)
            ) {
                return;
            }

            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
            setMonth(endDate.getFirstDayInMonth());
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [startDate.key, endDate.key]);

        useEffect(() => {
            if (!visible && (!selectedStartDate || !selectedEndDate)) {
                if (!selectedStartDate && !selectedEndDate) {
                    setSelectedStartDate(startDate);
                    setSelectedEndDate(endDate);
                    return;
                }

                setSelectedStartDate(selectedStartDate ?? selectedEndDate);
                setSelectedEndDate(selectedEndDate ?? selectedStartDate);
                return;
            }

            if (
                !selectedStartDate ||
                !selectedEndDate ||
                (startDate.isSameDay(selectedStartDate) &&
                    endDate.isSameDay(selectedEndDate))
            ) {
                return;
            }

            onDateSelected(selectedStartDate, selectedEndDate);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [visible, selectedStartDate?.key, selectedEndDate?.key]);

        const onDateClick = (date: DateTime) => {
            let newStartDate: optional<DateTime>;
            let newEndDate: optional<DateTime>;
            let newEnabledStartDate: DateTime = calendarStartDate.min;
            let newEnabledEndDate: DateTime = DateTime.minNow();

            if (selectedStartDate && selectedEndDate) {
                if (
                    date.isSameDay(selectedStartDate) &&
                    date.isSameDay(selectedEndDate)
                ) {
                    newStartDate = undefined;
                    newEndDate = undefined;
                } else if (date.isSameDay(selectedStartDate)) {
                    newStartDate = selectedEndDate;
                    newEndDate = undefined;
                    newEnabledStartDate = DateTime.max(
                        newEnabledStartDate,
                        newStartDate.copyWith({
                            month: newStartDate.month - 3,
                            day: newStartDate.day + 1,
                        })
                    );
                    newEnabledEndDate = DateTime.min(
                        newEnabledEndDate,
                        newStartDate.copyWith({
                            month: newStartDate.month + 3,
                            day: newStartDate.day - 1,
                        })
                    );
                } else if (date.isSameDay(selectedEndDate)) {
                    newStartDate = selectedStartDate;
                    newEndDate = undefined;
                    newEnabledStartDate = DateTime.max(
                        newEnabledStartDate,
                        newStartDate.copyWith({
                            month: newStartDate.month - 3,
                            day: newStartDate.day + 1,
                        })
                    );
                    newEnabledEndDate = DateTime.min(
                        newEnabledEndDate,
                        newStartDate.copyWith({
                            month: newStartDate.month + 3,
                            day: newStartDate.day - 1,
                        })
                    );
                } else {
                    newStartDate = date;
                    newEndDate = undefined;
                    newEnabledStartDate = DateTime.max(
                        newEnabledStartDate,
                        newStartDate.copyWith({
                            month: newStartDate.month - 3,
                            day: newStartDate.day + 1,
                        })
                    );
                    newEnabledEndDate = DateTime.min(
                        newEnabledEndDate,
                        newStartDate.copyWith({
                            month: newStartDate.month + 3,
                            day: newStartDate.day - 1,
                        })
                    );
                }
            } else if (!selectedStartDate && !selectedEndDate) {
                newStartDate = date;
                newEndDate = undefined;
                newEnabledStartDate = DateTime.max(
                    newEnabledStartDate,
                    newStartDate.copyWith({
                        month: newStartDate.month - 3,
                        day: newStartDate.day + 1,
                    })
                );
                newEnabledEndDate = DateTime.min(
                    newEnabledEndDate,
                    newStartDate.copyWith({
                        month: newStartDate.month + 3,
                        day: newStartDate.day - 1,
                    })
                );
            } else {
                const [startDate, endDate] = [
                    selectedStartDate ?? selectedEndDate,
                    date,
                ].toSorted(
                    (a, b) =>
                        a!.millisecondsSinceEpoch - b!.millisecondsSinceEpoch
                );

                newStartDate = startDate;
                newEndDate = endDate;
            }

            setEnabledStartDate(newEnabledStartDate);
            setEnabledEndDate(newEnabledEndDate);
            setSelectedStartDate(newStartDate);
            setSelectedEndDate(newEndDate);
        };

        const transitions = useTransition(month, {
            from: {
                transform: `translateX(${DateFilterPeriodCalendarMonthTypeCallerMap.fromTranslateX(
                    transitionCallerRef.current
                )})`,
                zIndex: 50,
                opacity: 1,
            },
            enter: {
                transform: "translateX(0%)",
                zIndex: 50,
                opacity: 1,
            },
            leave: {
                transform: `translateX(${DateFilterPeriodCalendarMonthTypeCallerMap.leaveTranslateX(
                    transitionCallerRef.current
                )})`,
                zIndex: 49,
                opacity: collapsed ? 1 : 0,
            },
        });

        const onMonthChange = (
            caller: DateFilterPeriodCalendarMonthCallerType,
            month: DateTime
        ) => {
            transitionCallerRef.current = caller;
            setMonth(month);
        };

        return (
            <DateFilterPeriodCalendarContext.Provider
                value={{
                    constants: {
                        monthWidthInPx: layout.monthWidthInPx,
                        innerWidthInPx: layout.innerWidthInPx,
                        ...constants,
                    },
                    constantsKey,
                    dropdownVisible: visible,
                    month,
                    enabledStartDate,
                    enabledEndDate,
                    selectedStartDate,
                    selectedEndDate,
                    onDateClick,
                    onMonthChange,
                }}
            >
                <DropdownContainer
                    ref={dropdownRef}
                    $visible={visible}
                    $widthInPx={layout.widthInPx}
                    $heightInPx={layout.heightInPx}
                    $verticalPaddingInPx={dropdownContainerVerticalPaddingInPx}
                    $horizontalPaddingInPx={
                        dropdownContainerHorizontalPaddingInPx
                    }
                >
                    <RelativeContainer>
                        <HeaderPositionContainer>
                            <DateFilterPeriodCalendarMonthHeader
                                collapsed={collapsed}
                            />
                        </HeaderPositionContainer>
                        {transitions((props, month) => (
                            <CalendarPositionContainer
                                style={props}
                                $topOffsetInPx={layout.topOffsetInPx}
                            >
                                <RowContainer>
                                    <DateFilterPeriodCalendarMonth
                                        month={month.copyWith({
                                            month: month.month - 1,
                                        })}
                                    />
                                    <GapContainer
                                        $widthInPx={constants.monthGapInPx}
                                        $heightInPx={layout.monthHeightInPx}
                                    />
                                    <DateFilterPeriodCalendarMonth
                                        month={month}
                                    />
                                </RowContainer>
                            </CalendarPositionContainer>
                        ))}
                        <FooterPositionContainer>
                            <DateFilterPeriodCalendarNoticeFooter/>
                        </FooterPositionContainer>
                    </RelativeContainer>
                </DropdownContainer>
            </DateFilterPeriodCalendarContext.Provider>
        );
    }
);

export default DateFilterPeriodDropdown;
