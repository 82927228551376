import {animated, useTransition} from "@react-spring/web";
import TransactionsCalendarFiguresHeader
    from "presentation/components/calendar/transactions_calendar/components/transactions_calendar_figures_header";
import TransactionsCalendarMonth
    from "presentation/components/calendar/transactions_calendar/components/transactions_calendar_month";
import TransactionsCalendarMonthHeader, {
    TransactionsCalendarMonthHeaderCallerType,
    TransactionsCalendarMonthHeaderCallerTypeMap,
} from "presentation/components/calendar/transactions_calendar/components/transactions_calendar_month_header";
import SizedBox from "presentation/components/common/sized_box";
import DateTime from "presentation/utils/extension/date_extension";
import {optional} from "presentation/utils/types/optional";
import {createContext, Dispatch, SetStateAction, useMemo, useRef, useState,} from "react";
import styled from "styled-components";

type YearMonthState = {
    value: DateTime;
    caller: TransactionsCalendarMonthHeaderCallerType;
};

export type TransactionsCalendarConstants = {
    marginInPx: number;
    monthHeaderHeightInPx: number;
    monthHeaderToFiguresHeaderGapInPx: number;
    figuresHeaderHeightInPx: number;
    figuresHeaderToCalendarGapInPx: number;
    weekdayHeaderHeightInPx: number;
    calendarRowVerticalGapInPx: number;
    calendarCellWidthInPx: number;
    calendarCellHeightInPx: number;
    calendarCellValueWidthInPx: number;
    calendarCellValueHeightInPx: number;
};

export const TransactionsCalendarContext = createContext<
    {
        figuresHeaderTitle: string;
        figuresHeaderValue?: number;
        onFiguresTodayButtonClick?: () => void;
        startDate: DateTime;
        endDate: DateTime;
        yearMonth: DateTime;
        suffixMap?: Record<string, optional<string>>;
        setYearMonth: Dispatch<SetStateAction<YearMonthState>>;
        onYearMonthChange?: (yearMonth: DateTime) => void;
    } & TransactionsCalendarConstants
>({
    marginInPx: 24,
    monthHeaderHeightInPx: 36,
    monthHeaderToFiguresHeaderGapInPx: 24,
    figuresHeaderHeightInPx: 34,
    figuresHeaderToCalendarGapInPx: 24,
    weekdayHeaderHeightInPx: 20,
    calendarRowVerticalGapInPx: 5,
    calendarCellWidthInPx: 68,
    calendarCellHeightInPx: 68,
    calendarCellValueWidthInPx: 40,
    calendarCellValueHeightInPx: 40,
    figuresHeaderTitle: "",
    figuresHeaderValue: undefined,
    onFiguresTodayButtonClick: undefined,
    startDate: DateTime.now(),
    endDate: DateTime.now(),
    yearMonth: DateTime.now(),
    suffixMap: undefined,
    setYearMonth: () => {
    },
    onYearMonthChange: undefined,
});

const LayoutContainer = styled.div.attrs<{
    $heightInPx: number;
}>((props) => ({
    style: {
        height: `${props.$heightInPx}px`,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: height 0.3s ease-in-out;
`;

const CalendarLayoutContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
`;

const CalendarContainer = styled(animated.div)`
    width: 100%;
    height: max-content;
    position: absolute;
    top: 0;
    left: 0;
`;

const TransactionsCalendar = ({
                                  constants,
                                  figuresHeaderTitle,
                                  figuresHeaderValue,
                                  onFiguresTodayButtonClick,
                                  startDate,
                                  endDate,
                                  initialDate,
                                  suffixMap,
                                  onYearMonthChange,
                              }: {
    constants: TransactionsCalendarConstants;
    figuresHeaderTitle: string;
    figuresHeaderValue?: number;
    onFiguresTodayButtonClick?: () => void;
    startDate: DateTime;
    endDate: DateTime;
    initialDate: DateTime;
    suffixMap?: Record<string, optional<string>>;
    onYearMonthChange?: (yearMonth: DateTime) => void;
}) => {
    const layoutRef = useRef<HTMLDivElement>(null);
    const [yearMonth, setYearMonth] = useState<YearMonthState>({
        value: initialDate.getFirstDayInMonth(),
        caller: TransactionsCalendarMonthHeaderCallerType.Previous,
    });

    const {heightInPx} = useMemo(
        () => {
            const weekdays = DateTime.orthodoxWeekdays;
            const weeks = yearMonth.value.getOrthodoxWeeksInMonth();

            const heightInPx =
                constants.monthHeaderHeightInPx +
                constants.monthHeaderToFiguresHeaderGapInPx +
                constants.figuresHeaderHeightInPx +
                constants.figuresHeaderToCalendarGapInPx +
                constants.weekdayHeaderHeightInPx +
                (constants.calendarRowVerticalGapInPx +
                    constants.calendarCellHeightInPx) *
                weeks.length;

            return {heightInPx, weekdays, weeks};
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [constants, yearMonth]
    );

    const transitions = useTransition(yearMonth, {
        from: {
            translateX:
                TransactionsCalendarMonthHeaderCallerTypeMap.fromTranslateX(
                    yearMonth.caller
                ),
            zIndex: 10,
            opacity: 1,
        },
        enter: {
            translateX: "0%",
            zIndex: 10,
            opacity: 1,
        },
        leave: {
            translateX:
                TransactionsCalendarMonthHeaderCallerTypeMap.leaveTranslateX(
                    yearMonth.caller
                ),
            zIndex: 9,
            opacity: 0,
        },
    });

    const monthHeaderToFiguresHeaderGap = `${constants.monthHeaderToFiguresHeaderGapInPx}px`;
    const figuresHeaderToCalendarGap = `${constants.figuresHeaderToCalendarGapInPx}px`;

    return (
        <TransactionsCalendarContext.Provider
            value={{
                ...constants,
                figuresHeaderTitle,
                figuresHeaderValue,
                onFiguresTodayButtonClick,
                startDate,
                endDate,
                yearMonth: yearMonth.value,
                suffixMap,
                setYearMonth,
                onYearMonthChange,
            }}
        >
            <LayoutContainer $heightInPx={heightInPx} ref={layoutRef}>
                <TransactionsCalendarMonthHeader/>
                <SizedBox height={monthHeaderToFiguresHeaderGap}/>
                <TransactionsCalendarFiguresHeader/>
                <SizedBox height={figuresHeaderToCalendarGap}/>
                <CalendarLayoutContainer>
                    {transitions((props, month) => (
                        <CalendarContainer style={props}>
                            <TransactionsCalendarMonth month={month.value}/>
                        </CalendarContainer>
                    ))}
                </CalendarLayoutContainer>
            </LayoutContainer>
        </TransactionsCalendarContext.Provider>
    );
};

export default TransactionsCalendar;
