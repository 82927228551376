import ProfitReportFiguresDetailType, {
    ProfitReportFiguresDetailTypeMap,
} from "domain/model/tax/profit_report/profit_report_figures_detail_type";

enum ProfitReportFiguresType {
    Profit = "profit",
    Sales = "sales",
    Purchases = "purchases",
    OtherExpenses = "otherExpenses",
}

export const ProfitReportFiguresTypeMap = {
    profitReportFiguresDetailTypes: (
        type: ProfitReportFiguresType
    ): ProfitReportFiguresDetailType[] => {
        const values = Object.values(ProfitReportFiguresDetailType);

        return values.filter(
            (v) =>
                ProfitReportFiguresDetailTypeMap.profitReportFiguresType(v) ===
                type
        );
    },
};

export default ProfitReportFiguresType;
