import SVG from "presentation/components/common/svg";
import Palette from "presentation/theme/palette";
import {forwardRef, MouseEvent, ReactElement} from "react";
import styled from "styled-components";

export enum IconButtonHoverColorType {
    Light = "light",
    Dark = "dark",
}

const IconButtonHoverColorTypeMap = {
    color: (type: IconButtonHoverColorType): string => {
        switch (type) {
            case IconButtonHoverColorType.Light:
                return Palette.black5;
            case IconButtonHoverColorType.Dark:
                return Palette.white8;
            default:
                throw new Error("Invalid IconButtonHoverColorType");
        }
    },
};

const LayoutContainer = styled.button<{
    $enabled: boolean;
    $padding: string;
    $borderRadius: string;
    $backgroundColor: string;
    $hoverColor: string;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(props) => props.$padding};
    border-radius: ${(props) => props.$borderRadius};
    background-color: ${(props) => props.$backgroundColor};
    border: none;
    cursor: ${(props) => (props.$enabled ? "pointer" : "not-allowed")};
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: ${(props) =>
    props.$enabled ? props.$hoverColor : props.$backgroundColor};
    }
`;

const IconButton = forwardRef<
    HTMLButtonElement,
    {
        className?: string;
        as?: string;
        enabled?: boolean;
        onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
        asset: ReactElement;
        width?: string;
        height?: string;
        padding?: string;
        borderRadius?: string;
        color: string;
        backgroundColor?: string;
        hoverColor?: IconButtonHoverColorType;
        tabIndex?: number;
    }
>(
    (
        {
            className,
            as,
            enabled = true,
            onClick,
            asset,
            width = "auto",
            height = "auto",
            padding = "6px",
            borderRadius = "12px",
            color,
            backgroundColor = Palette.none,
            hoverColor,
            tabIndex,
        },
        ref
    ) => {
        const _hoverColor = !hoverColor
            ? Palette.none
            : IconButtonHoverColorTypeMap.color(hoverColor);

        const _onClick = (e: MouseEvent<HTMLButtonElement>) => {
            if (!enabled) return;

            onClick?.(e);
        };

        return (
            <LayoutContainer
                className={className}
                as={as}
                ref={ref}
                onClick={_onClick}
                tabIndex={tabIndex}
                $enabled={enabled}
                $padding={padding}
                $borderRadius={borderRadius}
                $backgroundColor={backgroundColor}
                $hoverColor={_hoverColor}
            >
                <SVG
                    asset={asset}
                    width={width}
                    height={height}
                    color={color}
                />
            </LayoutContainer>
        );
    }
);

export default IconButton;
