import ProfitReportFiguresType from "domain/model/tax/profit_report/profit_report_figures_type";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";

enum ProfitReportFiguresDetailType {
    CardSales = "CARD_SALES",
    CashSales = "CASH_SALES",
    DispensingSales = "DISPENSING_SALES",
    CardPurchases = "CARD_PURCHASES",
    CashPurchases = "CASH_PURCHASES",
    ElectronicTaxInvoicePurchases = "ELECTRONIC_TAX_INVOICE_PURCHASES",
    PersonnelCosts = "PERSONNEL_COSTS",
    WelfareCosts = "WELFARE_COSTS",
    OtherCosts = "OTHER_COSTS",
}

export const ProfitReportFiguresDetailTypeMap = {
    profitReportFiguresType: (
        type: ProfitReportFiguresDetailType
    ): ProfitReportFiguresType => {
        switch (type) {
            case ProfitReportFiguresDetailType.CardSales:
            case ProfitReportFiguresDetailType.CashSales:
            case ProfitReportFiguresDetailType.DispensingSales:
                return ProfitReportFiguresType.Sales;
            case ProfitReportFiguresDetailType.CardPurchases:
            case ProfitReportFiguresDetailType.CashPurchases:
            case ProfitReportFiguresDetailType.ElectronicTaxInvoicePurchases:
                return ProfitReportFiguresType.Purchases;
            case ProfitReportFiguresDetailType.PersonnelCosts:
            case ProfitReportFiguresDetailType.WelfareCosts:
            case ProfitReportFiguresDetailType.OtherCosts:
                return ProfitReportFiguresType.OtherExpenses;
            default:
                throw new Error("Invalid ProfitReportFiguresDetailType");
        }
    },
    shortLabel: (type: ProfitReportFiguresDetailType): string => {
        switch (type) {
            case ProfitReportFiguresDetailType.CardSales:
                return S.profitReportFiguresDetailType.cardSalesShortLabel;
            case ProfitReportFiguresDetailType.CashSales:
                return S.profitReportFiguresDetailType.cashSalesShortLabel;
            case ProfitReportFiguresDetailType.DispensingSales:
                return S.profitReportFiguresDetailType
                    .dispensingSalesShortLabel;
            case ProfitReportFiguresDetailType.CardPurchases:
                return S.profitReportFiguresDetailType.cardPurchasesShortLabel;
            case ProfitReportFiguresDetailType.CashPurchases:
                return S.profitReportFiguresDetailType.cashPurchasesShortLabel;
            case ProfitReportFiguresDetailType.ElectronicTaxInvoicePurchases:
                return S.profitReportFiguresDetailType
                    .electronicTaxInvoicePurchasesShortLabel;
            case ProfitReportFiguresDetailType.PersonnelCosts:
                return S.profitReportFiguresDetailType.personnelCostsShortLabel;
            case ProfitReportFiguresDetailType.WelfareCosts:
                return S.profitReportFiguresDetailType.welfareCostsShortLabel;
            case ProfitReportFiguresDetailType.OtherCosts:
                return S.profitReportFiguresDetailType.otherCostsShortLabel;
            default:
                throw new Error("Invalid ProfitReportFiguresDetailType");
        }
    },
    yearlyChartLabel: (type: ProfitReportFiguresDetailType): string => {
        switch (type) {
            case ProfitReportFiguresDetailType.CardSales:
                return S.profitReportFiguresDetailType
                    .cardSalesYearlyChartLabel;
            case ProfitReportFiguresDetailType.CashSales:
                return S.profitReportFiguresDetailType
                    .cashSalesYearlyChartLabel;
            case ProfitReportFiguresDetailType.DispensingSales:
                return S.profitReportFiguresDetailType
                    .dispensingSalesYearlyChartLabel;
            case ProfitReportFiguresDetailType.CardPurchases:
                return S.profitReportFiguresDetailType
                    .cardPurchasesYearlyChartLabel;
            case ProfitReportFiguresDetailType.CashPurchases:
                return S.profitReportFiguresDetailType
                    .cashPurchasesYearlyChartLabel;
            case ProfitReportFiguresDetailType.ElectronicTaxInvoicePurchases:
                return S.profitReportFiguresDetailType
                    .electronicTaxInvoicePurchasesYearlyChartLabel;
            case ProfitReportFiguresDetailType.PersonnelCosts:
                return S.profitReportFiguresDetailType
                    .personnelCostsYearlyChartLabel;
            case ProfitReportFiguresDetailType.WelfareCosts:
                return S.profitReportFiguresDetailType
                    .welfareCostsYearlyChartLabel;
            case ProfitReportFiguresDetailType.OtherCosts:
                return S.profitReportFiguresDetailType
                    .otherCostsYearlyChartLabel;
            default:
                throw new Error("Invalid ProfitReportFiguresDetailType");
        }
    },
    color: (type: ProfitReportFiguresDetailType): string => {
        switch (type) {
            case ProfitReportFiguresDetailType.CardSales:
                return Palette.deepBlue;
            case ProfitReportFiguresDetailType.CashSales:
                return Palette.primary500;
            case ProfitReportFiguresDetailType.DispensingSales:
                return Palette.primary300;
            case ProfitReportFiguresDetailType.CardPurchases:
                return Palette.orange600;
            case ProfitReportFiguresDetailType.CashPurchases:
                return Palette.orange500;
            case ProfitReportFiguresDetailType.ElectronicTaxInvoicePurchases:
                return Palette.orange300;
            case ProfitReportFiguresDetailType.PersonnelCosts:
                return Palette.gray500;
            case ProfitReportFiguresDetailType.WelfareCosts:
                return Palette.gray400;
            case ProfitReportFiguresDetailType.OtherCosts:
                return Palette.gray300;
            default:
                throw new Error("Invalid ProfitReportFiguresDetailType");
        }
    },
};

export default ProfitReportFiguresDetailType;
