import BillingMonthlyFigures from "domain/model/tax/billing/billing_monthly_figures";
import TableEmptyLabel from "presentation/components/table/table_empty_label";
import BillingYearlyTableHeader
    from "presentation/pages/billing/view/yearly_table/components/billing_yearly_table/components/billing_yearly_table_header";
import BillingYearlyTableMonthHeader
    from "presentation/pages/billing/view/yearly_table/components/billing_yearly_table/components/billing_yearly_table_month_header";
import BillingYearlyTableRow
    from "presentation/pages/billing/view/yearly_table/components/billing_yearly_table/components/billing_yearly_table_row";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {createContext, useMemo} from "react";
import styled from "styled-components";

const constants = {
    emptyHeightInPx: 234,
    primaryHeaderCellHeightInPx: 36,
    primaryHeaderBorderRadiusInPx: 16,
    secondaryHeaderCellHeightInPx: 48,
    monthHeaderWidthInPx: 64,
    cellMinWidthInPx: 108.125,
    cellHeightInPx: 48,
    verticalGapInPx: 4,
    horizontalGapInPx: 4,
    bottomMarginInPx: 20,
};

export const BillingYearlyTableContext = createContext<{
    constants: typeof constants;
    secondaryHeaderLabels: {
        label: string;
        flex: number;
        leftMarginInPx: number;
        backgroundColor: string;
        color: string;
    }[];
    primaryHeaderLabels: {
        label: string;
        flex: number;
        leftMarginInPx: number;
    }[];
    monthHeaderLabels: string[];
}>({
    constants,
    secondaryHeaderLabels: [],
    primaryHeaderLabels: [],
    monthHeaderLabels: [],
});

const LayoutContainer = styled.div.attrs<{
    $heightInPx: number;
    $gapInPx: number;
}>((props) => ({
    style: {
        height: `${props.$heightInPx}px`,
        gap: `${props.$gapInPx}px`,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    transition: height 0.3s ease-in-out;
`;

const ScrollContainer = styled.div`
    height: 100%;
    flex-grow: 1;
    overflow-x: scroll;
    overflow-y: clip;
    scrollbar-color: ${Palette.none} ${Palette.none};
    transition: height 0.3s ease-in-out, scrollbar-color 0.3s ease-in-out;

    &:hover {
        scrollbar-color: ${Palette.gray300} ${Palette.none};
    }
`;

const ColumnContainer = styled.ul.attrs<{
    $minWidthInPx: number;
    $gapInPx: number;
}>((props) => ({
    style: {
        width: `max(100%, ${props.$minWidthInPx}px)`,
        gap: `${props.$gapInPx}px`,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
        gap 0.3s ease-in-out;
`;

const BillingYearlyTable = ({data}: { data: BillingMonthlyFigures[] }) => {
    const {secondaryHeaderLabels, primaryHeaderLabels} = useMemo(() => {
        const secondaryHeaderLabels = [
            {
                label: S.billingYearlyTable.billingLabel,
                backgroundColor: Palette.gray700,
                color: Palette.white100,
            },
            {
                label: S.billingYearlyTable.pendingLabel,
                backgroundColor: Palette.red400,
                color: Palette.white100,
            },
            {
                label: S.billingYearlyTable.taxesLabel,
                backgroundColor: Palette.gray100,
                color: Palette.gray800,
            },
            {
                label: S.billingYearlyTable.processedLabel,
                backgroundColor: Palette.gray700,
                color: Palette.white100,
            },
        ].map((l, index) => ({
            label: l.label,
            flex: 2,
            leftMarginInPx: index === 0 ? 0 : constants.horizontalGapInPx,
            backgroundColor: l.backgroundColor,
            color: l.color,
        }));
        const primaryHeaderLabels = [
            {label: S.billingYearlyTable.amountLabel, leftMarginInPx: 0},
            {label: S.billingYearlyTable.countLabel, leftMarginInPx: 0},
            {
                label: S.billingYearlyTable.amountLabel,
                leftMarginInPx: constants.horizontalGapInPx,
            },
            {label: S.billingYearlyTable.countLabel, leftMarginInPx: 0},
            {
                label: S.billingYearlyTable.amountLabel,
                leftMarginInPx: constants.horizontalGapInPx,
            },
            {
                label: S.billingYearlyTable.amountLabel,
                leftMarginInPx: constants.horizontalGapInPx,
            },
            {label: S.billingYearlyTable.countLabel, leftMarginInPx: 0},
        ].map((l, index) => ({
            label: l.label,
            flex: index === 4 ? 2 : 1,
            leftMarginInPx: l.leftMarginInPx,
        }));

        return {
            secondaryHeaderLabels,
            primaryHeaderLabels,
        };
    }, []);

    const {minWidthInPx, heightInPx, monthHeaderLabels} = useMemo(() => {
        const monthHeaderLabels = data.map((d) =>
            d.date.format(S.billingYearlyTable.monthHeaderDateFormat)
        );

        const minWidthInPx =
            constants.cellMinWidthInPx * primaryHeaderLabels.length;

        const heightInPx = data.length
            ? constants.secondaryHeaderCellHeightInPx +
            constants.primaryHeaderCellHeightInPx +
            monthHeaderLabels.length * constants.cellHeightInPx +
            (monthHeaderLabels.length + 1) * constants.verticalGapInPx +
            constants.bottomMarginInPx
            : constants.emptyHeightInPx;

        return {
            minWidthInPx,
            heightInPx,
            monthHeaderLabels,
        };
    }, [primaryHeaderLabels, data]);

    return (
        <BillingYearlyTableContext.Provider
            value={{
                constants,
                secondaryHeaderLabels,
                primaryHeaderLabels,
                monthHeaderLabels,
            }}
        >
            <LayoutContainer
                $heightInPx={heightInPx}
                $gapInPx={constants.horizontalGapInPx}
            >
                <BillingYearlyTableMonthHeader/>
                <ScrollContainer>
                    <ColumnContainer
                        $minWidthInPx={minWidthInPx}
                        $gapInPx={constants.verticalGapInPx}
                    >
                        <BillingYearlyTableHeader/>
                        {data.length ? (
                            data.map((d, index) => (
                                <BillingYearlyTableRow key={index} data={d}/>
                            ))
                        ) : (
                            <TableEmptyLabel minWidthInPx={minWidthInPx}/>
                        )}
                    </ColumnContainer>
                </ScrollContainer>
            </LayoutContainer>
        </BillingYearlyTableContext.Provider>
    );
};

export default BillingYearlyTable;
