import TransactionsDailyFigures from "domain/model/tax/transactions/transactions_daily_figures";
import TransactionsFiguresType, {
    TransactionsFiguresTypeMap,
} from "domain/model/tax/transactions/transactions_figures_type";
import TransactionsType from "domain/model/tax/transactions/transactions_type";
import TransactionsDetailDialog
    from "presentation/components/dialog/transactions_detail_dialog/transactions_detail_dialog";
import TransactionsList from "presentation/components/list/transactions_list/transactions_list";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import CalendarListBox from "presentation/components/calendar/common/calendar_list_box";
import {
    DashboardTransactionsViewContext
} from "presentation/pages/dashboard/view/transactions/dashboard_transactions_view";
import {dashboardListenableState} from "presentation/states/dashboard/dashboard_state";
import {DashboardListScrollToTop} from "presentation/states/dashboard/dashboard_listenable_state";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import dashboardTransactionsSelector from "presentation/states/dashboard/selector/dashboard_transactions_selector";
import DateTime from "presentation/utils/extension/date_extension";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";
import {useListenableState} from "presentation/utils/hooks/use_listenable_state";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import {useContext, useEffect, useMemo, useRef} from "react";
import {useRecoilValue} from "recoil";

const DashboardTransactionsListView = () => {
    const showDialog = useShowDialog();
    const {contentWidth, contentHeight} = useContext(DashboardRowContext);
    const {queryYearMonth, calendarConstants} = useContext(
        DashboardTransactionsViewContext
    );

    const {taxStartDate} = useRecoilValue(dashboardDateSelector);
    const {type, data} = useRecoilValue(dashboardTransactionsSelector);

    const ref = useRef<HTMLUListElement>(null);

    useListenableState(dashboardListenableState, (state) => {
        if (state instanceof DashboardListScrollToTop) {
            ref.current?.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    });

    const startDate = taxStartDate;
    const endDate = DateTime.minNow();

    const {dataMap} = useMemo(() => {
        const types = Object.values(TransactionsType);
        const monthStartDate = DateTime.max(startDate, taxStartDate);
        const monthEndDate = DateTime.min(
            endDate,
            queryYearMonth.getLastDayInMonth()
        );

        const rangedData = data.filter((d) =>
            d.date.isInRange(monthStartDate, monthEndDate)
        );

        const dataMap: Record<string, TransactionsDailyFigures[]> = {};
        types.forEach((t) => {
            dataMap[t] = rangedData
                .map((d) => {
                    const figures = d.figures.filter(
                        (f) =>
                            TransactionsFiguresTypeMap.transactionsType(
                                f.type
                            ) === t
                    );

                    return figures.length
                        ? {
                            date: d.date,
                            figures,
                        }
                        : undefined;
                })
                .filter((d) => d !== undefined)
                .map((d) => d!);
        });

        return {
            dataMap,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        addPostFrameCallback(() =>
            ref?.current?.scrollTo({
                top: 0,
                behavior: "smooth",
            })
        );
    }, [dataMap]);

    const onTileClick = (date: DateTime, type: TransactionsFiguresType) =>
        showDialog({
            className: "transactions-detail-dialog",
            component: <TransactionsDetailDialog date={date} type={type}/>,
        });

    return (
        <CalendarListBox
            width={contentWidth!}
            height={contentHeight!}
            paddingInPx={calendarConstants.marginInPx}
        >
            <TransactionsList
                ref={ref}
                data={dataMap[type]}
                onTileClick={onTileClick}
            />
        </CalendarListBox>
    );
};

export default DashboardTransactionsListView;
