import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
`;

const ProfitReportYearlyChartLegendsRow = ({children}: PropsWithChildren) => {
    return <LayoutContainer>{children}</LayoutContainer>;
};

export default ProfitReportYearlyChartLegendsRow;
