import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const SubtitleContainer = styled.h6`
    ${Fonts.detail3Medium};
    color: ${Palette.gray500};
    text-align: center;
`;

const SyncDialogSubtitle = ({subtitle}: { subtitle: string }) => {
    return (
        <LayoutContainer>
            <SubtitleContainer>{subtitle}</SubtitleContainer>
        </LayoutContainer>
    );
};

export default SyncDialogSubtitle;
