import {animated} from "@react-spring/web";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import styled from "styled-components";

const TitleContainer = styled(animated.h1)`
    flex: 1;
    ${Fonts.body2};
    color: ${Palette.white100};
    text-align: left;
`;

const TNBTitle = ({title}: { title: string }) => {
    const {props} = useFadeInTransition(title);

    return <TitleContainer style={props}>{title}</TitleContainer>;
};

export default TNBTitle;
