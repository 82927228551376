import dashboardState from "presentation/states/dashboard/dashboard_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const dashboardDateSelector = selector({
    key: "dashboardDateSelector",
    get: ({get}) => {
        const state = get(dashboardState);

        const selectedYearMonth = DateTime.fromKey(state.selectedYearMonthKey);

        return {
            taxStartDate: DateTime.fromKey(state.taxStartDateKey),
            previousYearMonth: selectedYearMonth
                .copyWith({
                    month: selectedYearMonth.month - 1,
                })
                .getFirstDayInMonth(),
            selectedYearMonth,
        };
    },
});

export default dashboardDateSelector;
