import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import styled from "styled-components";

const LayoutContainer = styled.h6`
    width: 100%;
    ${Fonts.body1};
`;

const DialogDateHeader = ({date}: { date: DateTime }) => {
    return (
        <LayoutContainer>
            {date.format(S.dialogDateHeader.dateFormat)}
        </LayoutContainer>
    );
};

export default DialogDateHeader;
