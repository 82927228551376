import TransactionsRepository from "data/repository/transactions_repository";
import TransactionsFiguresType from "domain/model/tax/transactions/transactions_figures_type";
import SizedBox from "presentation/components/common/sized_box";
import ContentBox from "presentation/components/content/content_box";
import ContentLayout from "presentation/components/content/content_layout";
import {
    TransactionsCalendarListViewContext,
    TransactionsPageContext,
} from "presentation/pages/transactions/transactions_page";
import TransactionsCalendarListTitleView
    from "presentation/pages/transactions/view/calendar_list/transactions_calendar_list_title_view";
import TransactionsCalendarView from "presentation/pages/transactions/view/calendar_list/transactions_calendar_view";
import TransactionsListView from "presentation/pages/transactions/view/calendar_list/transactions_list_view";
import transactionsCalendarListSelector
    from "presentation/states/transactions/selector/transactions_calendar_list_selector";
import {transactionsState} from "presentation/states/transactions/transactions_state";
import Palette from "presentation/theme/palette";
import useRepository from "presentation/utils/hooks/use_repository";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import Debouncer from "presentation/utils/debouncer/debouncer";
import {optional} from "presentation/utils/types/optional";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import styled from "styled-components";

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    transition: scrollbar-color 0.3s ease-in-out;

    @media (max-width: 768px) {
        align-items: flex-start;
        overflow-x: scroll;
        scrollbar-color: ${Palette.none} ${Palette.none};

        &:hover {
            scrollbar-color: ${Palette.gray300} ${Palette.none};
        }
    }

    @media (min-width: 1281px) {
        flex-direction: row;
        justify-content: center;
    }
`;

const TransactionsCalendarListView = () => {
    const {calendarConstants, transactionsType} = useContext(
        TransactionsPageContext
    );
    const repository = useRepository(TransactionsRepository);

    const {yearMonth} = useRecoilValue(transactionsCalendarListSelector);
    const setState = useSetRecoilState(transactionsState);

    const debouncerRef = useRef(new Debouncer());
    const layoutRef = useRef<HTMLDivElement>(null);
    const [calendarYearMonth, setCalendarYearMonth] = useState(yearMonth);
    const [transactionsFiguresType, setTransactionsFiguresType] =
        useState<optional<TransactionsFiguresType>>(undefined);

    useEffect(() => {
        debouncerRef.current.run(
            () =>
                repository({
                    handler: async (repository) => {
                        const copy = calendarYearMonth.copyWith();

                        if (copy.isEqual(yearMonth)) return;

                        const startDate = copy.getFirstDayInMonth();
                        const endDate = copy.getLastDayInMonth();

                        const data = await repository.getFilteredFiguresInRange(
                            {
                                transactionsType: transactionsType!,
                                startDate,
                                endDate,
                            }
                        );

                        setState((prev) => ({
                            ...prev,
                            calendarList: {
                                yearMonthKey: copy.key,
                                data,
                            },
                        }));
                    },
                }),
            500
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarYearMonth.key]);

    const contentWidth = "572px";
    const contentHeight = useMemo(() => {
        const weekCount = calendarYearMonth.getOrthodoxWeeksInMonth().length;
        const height =
            calendarConstants.marginInPx * 2 +
            calendarConstants.monthHeaderHeightInPx +
            calendarConstants.monthHeaderToFiguresHeaderGapInPx +
            calendarConstants.figuresHeaderHeightInPx +
            calendarConstants.figuresHeaderToCalendarGapInPx +
            calendarConstants.weekdayHeaderHeightInPx +
            (calendarConstants.calendarRowVerticalGapInPx +
                calendarConstants.calendarCellHeightInPx) *
            weekCount;

        return `${height}px`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarYearMonth]);

    const {height} = useResizeObserver(layoutRef, []);

    return (
        <TransactionsCalendarListViewContext.Provider
            value={{
                transactionsFiguresType,
                contentWidth,
                contentHeight,
                calendarYearMonth,
                setTransactionsFiguresType,
                setCalendarYearMonth,
            }}
        >
            <ContentBox contentHeight={height}>
                <ContentLayout ref={layoutRef}>
                    <TransactionsCalendarListTitleView/>
                    <SizedBox height={"24px"}/>
                    <RowContainer>
                        <TransactionsCalendarView/>
                        <TransactionsListView/>
                    </RowContainer>
                </ContentLayout>
            </ContentBox>
        </TransactionsCalendarListViewContext.Provider>
    );
};

export default TransactionsCalendarListView;
