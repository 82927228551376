import SortType from "domain/model/common/sort_type";
import CardSalesFigures from "domain/model/tax/card_sales/card_sales_figures";
import Pagination from "presentation/components/pagination/pagination";
import TableEmptyLabel from "presentation/components/table/table_empty_label";
import CardSalesReceiveTableHeader
    from "presentation/pages/card_sales/view/status_board/components/card_sales_receive_table/components/card_sales_receive_table_header";
import CardSalesReceiveTableRow
    from "presentation/pages/card_sales/view/status_board/components/card_sales_receive_table/components/card_sales_receive_table_row";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {createContext, useEffect, useMemo, useState} from "react";
import styled from "styled-components";

const constants = {
    maxVisibleCount: 10,
    emptyListHeightInPx: 138,
    primaryHeaderCellHeightInPx: 36,
    primaryHeaderBorderRadiusInPx: 16,
    secondaryHeaderCellHeightInPx: 48,
    dateColumnWidthInPx: 100,
    cellMinWidthInPx: 278,
    cellHeightInPx: 48,
    verticalGapInPx: 4,
    horizontalGapInPx: 4,
    listToPaginationGapInPx: 36,
    bottomMarginInPx: 20,
};

export const CardSalesReceiveTableContext = createContext<{
    constants: {
        maxVisibleCount: number;
        emptyListHeightInPx: number;
        primaryHeaderCellHeightInPx: number;
        primaryHeaderBorderRadiusInPx: number;
        secondaryHeaderCellHeightInPx: number;
        dateColumnWidthInPx: number;
        cellMinWidthInPx: number;
        cellHeightInPx: number;
        verticalGapInPx: number;
        horizontalGapInPx: number;
        listToPaginationGapInPx: number;
        bottomMarginInPx: number;
    };
    secondaryHeaderLabels: {
        label: string;
        maxWidthInPx?: number;
        flex?: number;
        leftMarginInPx: number;
        backgroundColor: string;
        color: string;
        isDateSortButton: boolean;
        isTotalSortButton: boolean;
    }[];
    primaryHeaderLabels: {
        labels: string[];
        widthInPx?: number;
        flex?: number;
        leftMarginInPx: number;
    }[];
    dateSortType: SortType;
    totalSortType: SortType;
    onSortChange: (dateSortType: SortType, totalSortType: SortType) => void;
}>({
    constants,
    secondaryHeaderLabels: [],
    primaryHeaderLabels: [],
    dateSortType: SortType.None,
    totalSortType: SortType.None,
    onSortChange: () => {
    },
});

const LayoutContainer = styled.div`
    width: 100%;
    position: relative;
    overflow-x: scroll;
    overflow-y: clip;
    scrollbar-color: ${Palette.none} ${Palette.none};
    transition: height 0.3s ease-in-out, scrollbar-color 0.3s ease-in-out;

    &:hover {
        scrollbar-color: ${Palette.gray300} ${Palette.none};
    }
`;

const ColumnContainer = styled.div.attrs<{
    $minWidthInPx: number;
    $height: string;
    $gapInPx: number;
}>((props) => ({
    style: {
        width: `max(100%, ${props.$minWidthInPx}px)`,
        height: props.$height,
        gap: `${props.$gapInPx}px`,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
        gap 0.3s ease-in-out;
`;

const PaginationPositionContainer = styled.div.attrs<{
    $topOffsetInPx: number;
}>((props) => ({
    style: {
        top: `${props.$topOffsetInPx}px`,
    },
}))`
    position: absolute;
    transition: top 0.3s ease-in-out;
`;

const CardSalesReceiveTable = ({
                                   data,
                                   dateSortType,
                                   totalSortType,
                                   onSortChange,
                               }: {
    data: CardSalesFigures[];
    dateSortType: SortType;
    totalSortType: SortType;
    onSortChange: (dateSortType: SortType, totalSortType: SortType) => void;
}) => {
    const theme = useThemeContext();

    const [page, setPage] = useState(1);

    const {secondaryHeaderLabels, primaryHeaderLabels} = useMemo(() => {
        const secondaryHeaderLabels = [
            {
                label: S.cardSalesReceiveTable.dateLabel,
                backgroundColor: Palette.gray100,
            },
            {
                label: S.cardSalesReceiveTable.receiveLabel,
                backgroundColor: Palette.gray700,
            },
            {
                label: S.cardSalesReceiveTable.purchasesLabel,
                backgroundColor: Palette.primary10,
            },
            {
                label: S.cardSalesReceiveTable.transactionsFeeLabel,
                backgroundColor: Palette.red200,
            },
        ].map((l, index) => ({
            label: l.label,
            maxWidthInPx:
                index === 0 ? constants.dateColumnWidthInPx : undefined,
            flex: index === 0 ? undefined : 1,
            leftMarginInPx: index === 0 ? 0 : constants.horizontalGapInPx,
            backgroundColor: l.backgroundColor,
            color: index === 1 ? Palette.white100 : Palette.gray800,
            isDateSortButton: index === 0,
            isTotalSortButton: index === 1,
        }));
        const primaryHeaderLabels = [
            [],
            [S.cardSalesReceiveTable.amountLabel],
            [S.cardSalesReceiveTable.amountLabel, S.cardSalesTable.countLabel],
            [S.cardSalesReceiveTable.amountLabel],
        ].map((l, index) => ({
            labels: l,
            widthInPx: index === 0 ? constants.dateColumnWidthInPx : undefined,
            flex: index === 0 ? undefined : 1,
            leftMarginInPx: index === 0 ? 0 : constants.horizontalGapInPx,
        }));

        return {
            secondaryHeaderLabels,
            primaryHeaderLabels,
        };
    }, []);

    const _data = useMemo(() => {
        if (dateSortType === SortType.Ascending) {
            return data.toSorted(
                (a, b) =>
                    b.date.millisecondsSinceEpoch -
                    a.date.millisecondsSinceEpoch
            );
        }

        if (dateSortType === SortType.Descending) {
            return data.toSorted(
                (a, b) =>
                    a.date.millisecondsSinceEpoch -
                    b.date.millisecondsSinceEpoch
            );
        }

        if (totalSortType === SortType.Ascending) {
            return data.toSorted((a, b) => b.receiveAmount - a.receiveAmount);
        }

        if (totalSortType === SortType.Descending) {
            return data.toSorted((a, b) => a.receiveAmount - b.receiveAmount);
        }

        return data.toSorted(
            (a, b) =>
                b.date.millisecondsSinceEpoch - a.date.millisecondsSinceEpoch
        );
    }, [data, dateSortType, totalSortType]);

    const {minWidthInPx, height, paginationTopOffsetInPx} = useMemo(() => {
        const minWidthInPx = secondaryHeaderLabels.reduce(
            (acc, cur) =>
                acc +
                (cur.maxWidthInPx ?? constants.cellMinWidthInPx) +
                cur.leftMarginInPx,
            0
        );

        const visibleCount = Math.min(_data.length, constants.maxVisibleCount);
        const listHeight = visibleCount
            ? visibleCount * constants.cellHeightInPx +
            (visibleCount - 1) * constants.verticalGapInPx
            : constants.emptyListHeightInPx;
        const heightInPx =
            constants.secondaryHeaderCellHeightInPx +
            constants.primaryHeaderCellHeightInPx +
            listHeight +
            constants.listToPaginationGapInPx +
            constants.bottomMarginInPx;
        const paginationTopOffsetInPx =
            constants.secondaryHeaderCellHeightInPx +
            constants.primaryHeaderCellHeightInPx +
            constants.verticalGapInPx * 2 +
            listHeight +
            constants.listToPaginationGapInPx;

        const height = `calc(${heightInPx}px + ${theme.componentTheme.paginationHeight})`;

        return {
            minWidthInPx,
            height,
            paginationTopOffsetInPx,
        };
    }, [theme, secondaryHeaderLabels, _data]);

    const {pageData, maxPage} = useMemo(() => {
        const pageSize = constants.maxVisibleCount;
        const pageData: Record<number, CardSalesFigures[]> = {};

        let page = 1;
        while ((page - 1) * pageSize < _data.length) {
            pageData[page] = _data.slice(
                (page - 1) * pageSize,
                page * pageSize
            );
            page++;
        }

        return {
            pageData,
            maxPage: Math.ceil(_data.length / constants.maxVisibleCount),
        };
    }, [_data]);

    useEffect(() => {
        setPage(1);
    }, [data, _data.length]);

    return (
        <CardSalesReceiveTableContext.Provider
            value={{
                constants,
                secondaryHeaderLabels,
                primaryHeaderLabels,
                dateSortType,
                totalSortType,
                onSortChange,
            }}
        >
            <LayoutContainer>
                <ColumnContainer
                    $minWidthInPx={minWidthInPx}
                    $height={height}
                    $gapInPx={constants.verticalGapInPx}
                >
                    <CardSalesReceiveTableHeader/>
                    {_data.length ? (
                        pageData[page]?.map((d, index) => (
                            <CardSalesReceiveTableRow key={index} data={d}/>
                        ))
                    ) : (
                        <TableEmptyLabel minWidthInPx={minWidthInPx}/>
                    )}

                    {!!_data.length && (
                        <PaginationPositionContainer
                            $topOffsetInPx={paginationTopOffsetInPx}
                        >
                            <Pagination
                                maxPage={maxPage}
                                page={page}
                                onPageChange={setPage}
                            />
                        </PaginationPositionContainer>
                    )}
                </ColumnContainer>
            </LayoutContainer>
        </CardSalesReceiveTableContext.Provider>
    );
};

export default CardSalesReceiveTable;
