import SortType from "domain/model/common/sort_type";
import CardSalesFiguresDetail from "domain/model/tax/card_sales/card_sales_figures_detail";
import CardSalesDetailDialog from "presentation/pages/card_sales/view/status_board/components/card_sales_detail_dialog";
import CardSalesTable, {
    CardSalesTableDetailButtonType,
} from "presentation/pages/card_sales/view/status_board/components/card_sales_table/card_sales_table";
import cardSalesState from "presentation/states/card_sales/card_sales_state";
import cardSalesStatusBoardSelector from "presentation/states/card_sales/selector/card_sales_status_board_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import {useRecoilValue, useSetRecoilState} from "recoil";

const CardSalesStatusBoardTableView = () => {
    const showDialog = useShowDialog();

    const {
        data,
        cancelFilterOn,
        pendingFilterOn,
        cardSalesDateSortType,
        cardSalesTotalSortType,
    } = useRecoilValue(cardSalesStatusBoardSelector);
    const setState = useSetRecoilState(cardSalesState);

    const dialogTitle = (type: CardSalesTableDetailButtonType) => {
        switch (type) {
            case "cancel":
                return S.cardSalesPage.detailDialog.cancelledTitle;

            case "pending":
                return S.cardSalesPage.detailDialog.pendingTitle;

            default:
                throw new Error("Invalid type");
        }
    };

    const onSortChange = (dateSortType: SortType, totalSortType: SortType) =>
        setState((prev) => ({
            ...prev,
            statusBoard: {
                ...prev.statusBoard,
                cardSalesDateSortType: dateSortType,
                cardSalesTotalSortType: totalSortType,
            },
        }));

    const onDetailButtonClick = (
        type: CardSalesTableDetailButtonType,
        date: DateTime,
        data: CardSalesFiguresDetail[]
    ) =>
        showDialog({
            className: "card-sales-detail-dialog",
            component: (
                <CardSalesDetailDialog
                    iconColor={
                        type === "pending" ? Palette.yellow500 : undefined
                    }
                    iconRimColor={
                        type === "pending" ? Palette.yellow100 : undefined
                    }
                    title={dialogTitle(type)}
                    date={date}
                    data={data}
                />
            ),
        });

    return (
        <CardSalesTable
            data={data}
            cancelFilterOn={cancelFilterOn}
            pendingFilterOn={pendingFilterOn}
            dateSortType={cardSalesDateSortType}
            totalSortType={cardSalesTotalSortType}
            onSortChange={onSortChange}
            onDetailButtonClick={onDetailButtonClick}
        />
    );
};

export default CardSalesStatusBoardTableView;
