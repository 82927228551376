import ExternalSyncsRepository from "data/repository/external_syncs_repository";
import FilledButton from "presentation/components/button/filled_button";
import TextButton from "presentation/components/button/text_button";
import SizedBox from "presentation/components/common/sized_box";
import SyncDialogSubtitle from "presentation/components/dialog/common/sync_dialog_subtitle";
import Dialog from "presentation/components/dialog/dialog";
import {TextField} from "presentation/components/text_field/text_field";
import SVGAssets from "presentation/theme/assets";
import S from "presentation/theme/s";
import {openInNewTab} from "presentation/utils/functions/open_in_new_tab";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import {userIDValidator} from "presentation/utils/input_validator/user_id_validator";
import {FormEvent, useState} from "react";
import styled from "styled-components";

const FindIDPasswordButtonLayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const FormContainer = styled.form`
    width: 100%;
`;

const SyncHomeTaxDialog = () => {
    const repository = useRepository(ExternalSyncsRepository);
    const dismissDialog = useDismissDialog();
    const showNotificationDialog = useShowNotificationDialog();

    const [loading, setLoading] = useState(false);

    const [userID, setUserID] = useState("");
    const [password, setPassword] = useState("");

    const [userIDFocused, setUserIDFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);

    useLifeCycle({
        onInit: () => setUserIDFocused(true),
    });

    const enabled = !!userID.length && !!password.length;

    const onLoginButtonClick = () =>
        repository({
            handler: async (repository) => {
                setLoading(true);

                await repository.syncHomeTax({
                    userID,
                    password,
                });

                dismissDialog();
                showNotificationDialog({
                    title: S.syncHomeTaxDialog.successDialogTitle,
                    message: S.syncHomeTaxDialog.successDialogMessage,
                    hasNegativeButton: false,
                });
            },
            onFinally: () => setLoading(false),
        });
    const onEnterPress = () => onLoginButtonClick();
    const onTextButtonClick = () => openInNewTab("https://www.hometax.go.kr/");
    const onSubmit = (e: FormEvent) => e.preventDefault();

    return (
        <Dialog
            contentLoading={loading}
            widthInPx={380}
            iconAsset={SVGAssets.CheckedSales}
            title={S.syncHomeTaxDialog.title}
        >
            <SyncDialogSubtitle subtitle={S.syncHomeTaxDialog.subtitle}/>
            <SizedBox height={"24px"}/>
            <FormContainer onSubmit={onSubmit}>
                <TextField
                    value={userID}
                    onValueChange={setUserID}
                    placeholder={S.syncHomeTaxDialog.userIDPlaceholder}
                    focused={userIDFocused}
                    onFocusChange={setUserIDFocused}
                    maxLength={30}
                    autoComplete={"username"}
                    inputValidator={userIDValidator}
                    onEnterPress={onEnterPress}
                />
                <SizedBox height={"16px"}/>
                <TextField
                    secure={true}
                    value={password}
                    onValueChange={setPassword}
                    placeholder={S.syncHomeTaxDialog.passwordPlaceholder}
                    focused={passwordFocused}
                    onFocusChange={setPasswordFocused}
                    maxLength={30}
                    autoComplete={"current-password"}
                    inputValidator={userIDValidator}
                    onEnterPress={onEnterPress}
                />
            </FormContainer>
            <SizedBox height={"8px"}/>
            <FindIDPasswordButtonLayoutContainer>
                <TextButton
                    type={"black"}
                    sizeType={"small"}
                    label={S.syncHomeTaxDialog.findIDPasswordButtonLabel}
                    onClick={onTextButtonClick}
                />
            </FindIDPasswordButtonLayoutContainer>
            <SizedBox height={"24px"}/>
            <FilledButton
                type={"primary"}
                enabled={enabled}
                width={"100%"}
                label={S.syncHomeTaxDialog.loginButtonLabel}
                onClick={onLoginButtonClick}
            />
            <SizedBox height={"8px"}/>
            <TextButton
                type={"primary"}
                sizeType={"small"}
                label={S.syncHomeTaxDialog.joinButtonLabel}
                onClick={onTextButtonClick}
            />
        </Dialog>
    );
};

export default SyncHomeTaxDialog;
