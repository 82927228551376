import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $visible: boolean;
    $bottomOffset: string;
}>((props) => ({
    style: {
        opacity: props.$visible ? 1 : 0,
        bottom: props.$bottomOffset,
    },
}))`
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 30px;
    left: 0;
    background: rgba(4, 169, 255, 0.1);
    background: -webkit-linear-gradient(
        bottom,
        rgba(70, 195, 255, 0),
        rgba(4, 169, 255, 0.1)
    );
    background: linear-gradient(
        to bottom,
        rgba(70, 195, 255, 0),
        rgba(4, 169, 255, 0.1)
    );
    transition: opacity 0.3s ease-in-out;
`;

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const EmptyLabel = styled.p`
    min-width: max-content;
    ${Fonts.detail2Medium};
    color: ${Palette.gray500};
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ChartEmptyOverlay = ({
                               visible,
                               bottomOffset = "0",
                           }: {
    visible: boolean;
    bottomOffset?: string;
}) => {
    return (
        <LayoutContainer $visible={visible} $bottomOffset={bottomOffset}>
            <ContentContainer>
                <EmptyLabel>{S.chartEmptyOverlay.label}</EmptyLabel>
            </ContentContainer>
        </LayoutContainer>
    );
};

export default ChartEmptyOverlay;
