import uiState from "presentation/states/ui/ui_state";
import {useSetRecoilState} from "recoil";

const useSetLoading = () => {
    const setState = useSetRecoilState(uiState);

    return (loading: boolean) =>
        setState((prev) => ({
            ...prev,
            loading,
        }));
};

export default useSetLoading;
