import Dot from "presentation/components/common/dot";
import SizedBox from "presentation/components/common/sized_box";
import StatisticNumberLarge from "presentation/components/statistic/statistic_number_large";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {ReactElement} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

const TitleLayoutContainer = styled.div`
    min-height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const TitleContainer = styled.p`
    ${Fonts.detail3Medium};
    color: ${Palette.gray600};
`;

const ContentLayoutContainer = styled.div`
    min-height: 5.375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const CardSalesStatusBoardSummaryItem = ({
                                             color,
                                             title,
                                             hideCount,
                                             count,
                                             value,
                                             icon,
                                         }: {
    color?: string;
    title: string;
    hideCount: boolean;
    count?: number;
    value?: number;
    icon?: ReactElement;
}) => {
    return (
        <LayoutContainer>
            {icon}
            {icon && <SizedBox width={"16px"}/>}
            <ItemContainer>
                <TitleLayoutContainer>
                    {color && <Dot color={color}/>}
                    <TitleContainer>{title}</TitleContainer>
                </TitleLayoutContainer>
                <ContentLayoutContainer>
                    {!hideCount && (
                        <StatisticNumberLarge
                            value={count}
                            unit={S.cardSalesStatusBoardSummaryItem.eaUnit}
                            color={Palette.gray800}
                            unitColor={Palette.gray600}
                        />
                    )}
                    <StatisticNumberLarge
                        value={value}
                        unit={S.cardSalesStatusBoardSummaryItem.wonUnit}
                        color={Palette.gray800}
                        unitColor={Palette.gray600}
                    />
                </ContentLayoutContainer>
            </ItemContainer>
        </LayoutContainer>
    );
};

export default CardSalesStatusBoardSummaryItem;
