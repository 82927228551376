import FilledButton from "presentation/components/button/filled_button";
import GetRecentDataDialog from "presentation/components/dialog/get_recent_data_dialog/get_recent_data_dialog";
import externalSyncsState from "presentation/states/external_syncs/external_syncs_state";
import SVGAssets from "presentation/theme/assets";
import S from "presentation/theme/s";
import useReadRecoilState from "presentation/utils/hooks/use_read_recoil_state";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import useShowErrorDialog from "presentation/utils/hooks/use_show_error_dialog";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const ExternalSyncsFetchButtonView = () => {
    const showDialog = useShowDialog();
    const showErrorDialog = useShowErrorDialog();

    const readState = useReadRecoilState(externalSyncsState);

    const onClick = async () => {
        const state = await readState();
        const homeTaxSynced = !!state.homeTax;
        const crefiaSynced = !!state.crefia;
        const hiraSynced = !!state.hira;

        if (!homeTaxSynced || !crefiaSynced || !hiraSynced) {
            showErrorDialog({
                title: S.externalSyncsPage.syncData.conditionsNotMetErrorTitle,
                message:
                S.externalSyncsPage.syncData.conditionsNotMetErrorMessage,
            });
            return;
        }

        showDialog({
            className: "get-recent-data-dialog",
            component: <GetRecentDataDialog/>,
            dismissible: false,
        });
    };

    return (
        <LayoutContainer>
            <FilledButton
                sizeType={"medium"}
                label={S.externalSyncsPage.fetchButtonLabel}
                rightIconAsset={SVGAssets.ArrowSide}
                onClick={onClick}
            />
        </LayoutContainer>
    );
};

export default ExternalSyncsFetchButtonView;
