import Fonts from "presentation/theme/fonts";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $color: string;
    $backgroundColor: string;
    $borderColor: string;
}>((props) => ({
    style: {
        color: props.$color,
        backgroundColor: props.$backgroundColor,
        boxShadow: `0 0 0 1.5px ${props.$borderColor} inset`,
    },
}))`
    min-width: max-content;
    padding: 4px 8px;
    border-radius: 8px;
    ${Fonts.detail3};
    text-align: center;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
`;

const OutlinedChip = ({
                          label,
                          color,
                          backgroundColor,
                          borderColor,
                      }: {
    label: string;
    color: string;
    backgroundColor: string;
    borderColor: string;
}) => {
    return (
        <LayoutContainer
            $color={color}
            $backgroundColor={backgroundColor}
            $borderColor={borderColor}
        >
            {label}
        </LayoutContainer>
    );
};

export default OutlinedChip;
