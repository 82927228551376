import taxTimelineState from "presentation/states/tax_timeline/tax_timeline_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const taxTimelineDateSelector = selector({
    key: "taxTimelineDateSelector",
    get: ({get}) => {
        const state = get(taxTimelineState);

        return {
            startDate: DateTime.fromKey(state.startDateKey),
            selectedYear: DateTime.fromKey(state.selectedYearKey),
        };
    },
});

export default taxTimelineDateSelector;
