import dashboardState from "presentation/states/dashboard/dashboard_state";
import {selector} from "recoil";

const dashboardTransactionsSelector = selector({
    key: "dashboardTransactionsSelector",
    get: ({get}) => {
        const state = get(dashboardState);

        return {
            type: state.transactions.type,
            data: state.transactions.data,
        };
    },
});

export default dashboardTransactionsSelector;
