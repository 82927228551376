import {TransactionsDateFilterTypeMap} from "domain/model/tax/transactions/transactions_date_filter_type";
import {TransactionsTypeMap} from "domain/model/tax/transactions/transactions_type";
import ContentSectionTitle from "presentation/components/content/content_section_title";
import {TransactionsPageContext} from "presentation/pages/transactions/transactions_page";
import transactionsTableSelector from "presentation/states/transactions/selector/transactions_table_selector";
import S from "presentation/theme/s";
import {useContext} from "react";
import {useRecoilValue} from "recoil";

const TransactionsTableDateTitleView = () => {
    const {transactionsType} = useContext(TransactionsPageContext);

    const {selectedDateFilter} = useRecoilValue(transactionsTableSelector);

    const title = selectedDateFilter
        ? S.transactionsPage.table.titleWithDateFilter(
            TransactionsDateFilterTypeMap.label(selectedDateFilter),
            TransactionsTypeMap.shortLabel(transactionsType!)
        )
        : S.transactionsPage.table.title(
            TransactionsTypeMap.shortLabel(transactionsType!)
        );

    return <ContentSectionTitle title={title}/>;
};

export default TransactionsTableDateTitleView;
