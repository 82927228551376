import NumberHelper from "config/helper/number_helper";
import {ChartAxisData, ChartInitialScrollPositionType,} from "presentation/components/charts/common/chart_model";
import DispensingChart from "presentation/components/charts/dispensing_chart/dispensing_chart";
import DashboardCard from "presentation/pages/dashboard/components/dashboard_card";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import DashboardDispensingChartHeaderView
    from "presentation/pages/dashboard/view/dispensing/chart/dashboard_dispensing_chart_header_view";
import dashboardDispensingSelector from "presentation/states/dashboard/selector/dashboard_dispensing_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {DateDifferenceUnitType} from "presentation/utils/extension/date_extension";
import {useContext, useMemo} from "react";
import {useRecoilValue} from "recoil";

const DashboardDispensingChartView = () => {
    const {height} = useContext(DashboardRowContext);

    const {status, startDate, endDate, data} = useRecoilValue(
        dashboardDispensingSelector
    );

    const {
        chartData,
        highlightedXIndices,
        highlightedXAxisIndices,
        xAxis,
        yAxis,
        overrideConstants,
    } = useMemo(() => {
        const chartData = data.map((d) => ({
            x: d.date.subtract(startDate, DateDifferenceUnitType.Month),
            y: d.dispensingAmount,
            payload1: d.billingCount,
            payload2: Math.floor(d.dispensingAmount / d.billingCount),
        }));

        const minY = 0;
        const maxY = Math.max(...chartData.map((d) => d.y));

        const yLabelCount = 4;
        const unit = NumberHelper.getUnitFromMinMaxCount(
            minY,
            maxY,
            yLabelCount
        );
        const [, upperBoundY] = NumberHelper.getBoundsForCount(
            minY,
            maxY,
            yLabelCount
        );

        const xAxisLength =
            endDate.subtract(startDate, DateDifferenceUnitType.Month) + 1;
        const xAxis = Array.from(
            {
                length: xAxisLength,
            },
            (_, index) => ({
                value: index,
                label: startDate
                    .copyWith({
                        month: DateDifferenceUnitType.Month
                            ? startDate.month + index
                            : undefined,
                    })
                    .format(S.dashboardPage.chartXAxisDateFormat),
            })
        );
        let yAxis: ChartAxisData[] = [];

        let pointer = 0;
        while (pointer <= upperBoundY) {
            yAxis.push({
                value: pointer,
                label: NumberHelper.toChartYAxisAbbreviatedString(pointer),
            });
            pointer += unit;
        }

        const overrideConstants = {
            binMarginInPx: 22,
            xAxisHorizontalMarginInPx: 64,
        };

        const highlightedXIndices = new Set<number>([xAxisLength - 1]);
        const highlightedXAxisIndices = new Set<number>([xAxisLength - 1]);

        if (!data.length) {
            const unit = 2_000_000;
            yAxis = Array.from({length: yLabelCount}, (_, index) => ({
                value: index,
                label: NumberHelper.toChartYAxisAbbreviatedString(unit * index),
            }));
        }

        return {
            chartData,
            highlightedXIndices,
            highlightedXAxisIndices,
            xAxis,
            yAxis,
            overrideConstants,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <DashboardCard
            status={status}
            height={height!}
            loadingColor={Palette.white100}
            backgroundColor={Palette.white100}
            hoverColor={Palette.white100}
            grow={true}
            maxWidthOnGrow={"709px"}
            upper={<DashboardDispensingChartHeaderView/>}
            lower={
                <DispensingChart
                    heightInPx={198}
                    data={chartData}
                    highlightedXIndices={highlightedXIndices}
                    highlightedXAxisIndices={highlightedXAxisIndices}
                    xAxis={xAxis}
                    yAxis={yAxis}
                    initialScrollPosition={ChartInitialScrollPositionType.Right}
                    overrideConstants={overrideConstants}
                    hoverEnabled={false}
                    showEmptyOverlay={false}
                />
            }
        />
    );
};

export default DashboardDispensingChartView;
