import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.div<{
    $width: string;
    $height: string;
    $backgroundColor: string;
}>`
    width: ${(props) => props.$width};
    height: ${(props) => props.$height};
    background-color: ${(props) => props.$backgroundColor};
    border-radius: 50%;
    position: relative;
`;

const IconContainer = styled(SVG)`
    position: absolute;
    inset: 0;
`;

const ProfileIcon = ({
                         width,
                         height,
                         color = Palette.gray300,
                         backgroundColor = Palette.gray100,
                     }: {
    width: string;
    height: string;
    color?: string;
    backgroundColor?: string;
}) => {
    return (
        <LayoutContainer
            $width={width}
            $height={height}
            $backgroundColor={backgroundColor}
        >
            <IconContainer
                asset={SVGAssets.Avatar}
                width={width}
                height={height}
                color={color}
            />
        </LayoutContainer>
    );
};

export default ProfileIcon;
