import SortType, {SortTypeMap} from "domain/model/common/sort_type";
import SizedBox from "presentation/components/common/sized_box";
import SortIcon from "presentation/components/common/sort_icon";
import TableCellLayout from "presentation/components/table/table_cell_layout";
import TableRowLayout from "presentation/components/table/table_row_layout";
import TableSecondaryHeaderLabel from "presentation/components/table/table_secondary_header_label";
import {
    TransactionsTableContext
} from "presentation/pages/transactions/components/transactions_table/transactions_table";
import {useContext} from "react";

const TransactionsTableHeader = () => {
    const context = useContext(TransactionsTableContext);

    const minWidthInPx = context.constants.cellMinWidthInPx;
    const heightInPx = context.constants.headerCellHeightInPx;

    const onClick =
        (isDateSortType: boolean, isTotalSortType: boolean) => () => {
            if (isDateSortType) {
                context.onSortChange(
                    SortTypeMap.next(context.dateSortType),
                    SortType.None
                );
                return;
            }

            if (isTotalSortType) {
                context.onSortChange(
                    SortType.None,
                    SortTypeMap.next(context.totalSortType)
                );
                return;
            }
        };

    return (
        <TableRowLayout>
            {context.headerLabels.map((l, index) => (
                <TableCellLayout
                    key={index}
                    flex={l.flex}
                    minWidthInPx={minWidthInPx}
                    heightInPx={heightInPx}
                    leftMarginInPx={l.leftMarginInPx}
                    roundLeft={true}
                    roundRight={true}
                    backgroundColor={l.backgroundColor}
                    onClick={
                        l.isDateSortButton || l.isTotalSortButton
                            ? onClick(l.isDateSortButton, l.isTotalSortButton)
                            : undefined
                    }
                >
                    <TableSecondaryHeaderLabel
                        label={l.label}
                        color={l.color}
                    />
                    {(l.isDateSortButton || l.isTotalSortButton) && (
                        <SizedBox width={"10px"}/>
                    )}
                    {l.isDateSortButton && (
                        <SortIcon
                            width={"20px"}
                            height={"20px"}
                            sortType={context.dateSortType}
                        />
                    )}
                    {l.isTotalSortButton && (
                        <SortIcon
                            width={"20px"}
                            height={"20px"}
                            sortType={context.totalSortType}
                        />
                    )}
                </TableCellLayout>
            ))}
        </TableRowLayout>
    );
};

export default TransactionsTableHeader;
