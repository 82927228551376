import BillingRepository from "data/repository/billing_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import BillingDateFilterType, {BillingDateFilterTypeMap,} from "domain/model/tax/billing/billing_date_filter_type";
import Page from "presentation/components/common/page";
import SizedBox from "presentation/components/common/sized_box";
import BillingStatusBoardView from "presentation/pages/billing/view/status_board/billing_status_board_view";
import BillingYearlyTableView from "presentation/pages/billing/view/yearly_table/billing_yearly_table_view";
import {BillingScrollToElement} from "presentation/states/billing/billing_replay_state";
import billingState, {billingReplayState,} from "presentation/states/billing/billing_state";
import DateTime from "presentation/utils/extension/date_extension";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import {useSearchParams} from "presentation/utils/hooks/use_search_params";
import {useSetReplayState} from "presentation/utils/hooks/use_set_replay_state";
import {useLayoutEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useSetRecoilState} from "recoil";

const BillingPage = () => {
    const {pathname, search} = useLocation();
    const searchParams = useSearchParams();
    const setReplayState = useSetReplayState(billingReplayState);
    const repository = useRepository(BillingRepository);

    const setState = useSetRecoilState(billingState);

    const [status, setStatus] = useState(ViewStatusType.Loading);

    useLayoutEffect(() => {
        const element = searchParams("scrollTo");
        if (element) {
            setReplayState(new BillingScrollToElement({element}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, search]);

    useLifeCycle({
        onInit: () =>
            repository({
                handler: async (repository) => {
                    const statusBoardDateFilter =
                        BillingDateFilterType.ThisMonth;
                    const statusBoardStartDate =
                        BillingDateFilterTypeMap.datePeriod(
                            statusBoardDateFilter
                        ).startDate;
                    const statusBoardEndDate =
                        BillingDateFilterTypeMap.datePeriod(
                            statusBoardDateFilter
                        ).endDate;
                    const year = DateTime.minNow().getFirstDayInYear();

                    const statusBoardData =
                        await repository.getDailyFiguresInRange({
                            startDate: statusBoardStartDate,
                            endDate: statusBoardEndDate,
                        });

                    const yearlyTableData =
                        await repository.getMonthlyFiguresInRange({
                            startDate: year.getFirstDayInYear(),
                            endDate: year.getLastDayInYear(),
                        });

                    setState((prev) => ({
                        ...prev,
                        taxStartDateKey: new DateTime(2010, 1, 1).key,
                        statusBoard: {
                            ...prev.statusBoard,
                            selectedDateFilter: statusBoardDateFilter,
                            startDateKey: statusBoardStartDate.key,
                            endDateKey: statusBoardEndDate.key,
                            data: statusBoardData,
                        },
                        yearlyTable: {
                            ...prev.yearlyTable,
                            selectedYearKey: year.key,
                            data: yearlyTableData,
                        },
                    }));
                },
                onFinally: () => setStatus(ViewStatusType.Loaded),
            }),
    });

    return (
        <Page status={status}>
            <BillingStatusBoardView status={status}/>
            <SizedBox height={"24px"}/>
            <BillingYearlyTableView/>
        </Page>
    );
};

export default BillingPage;
