import {animated} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import TransactionsRepository from "data/repository/transactions_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import TransactionsDailyFilteredFigures from "domain/model/tax/transactions/transactions_daily_filtered_figures";
import TransactionsFiguresDetail from "domain/model/tax/transactions/transactions_figures_detail";
import TransactionsFiguresType, {
    TransactionsFiguresTypeMap,
} from "domain/model/tax/transactions/transactions_figures_type";
import TransactionsType, {
    TransactionsTypeMap,
} from "domain/model/tax/transactions/transactions_type";
import SizedBox from "presentation/components/common/sized_box";
import DialogDateHeader from "presentation/components/dialog/common/dialog_date_header";
import DialogList from "presentation/components/dialog/common/dialog_list";
import DialogTotalsSummary from "presentation/components/dialog/common/dialog_totals_summary";
import Dialog from "presentation/components/dialog/dialog";
import TabBar from "presentation/components/tab_bar/tab_bar";
import TabBarItem from "presentation/components/tab_bar/tab_bar_item";
import SVGAssets from "presentation/theme/assets";
import S from "presentation/theme/s";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import useRepository from "presentation/utils/hooks/use_repository";
import {useEffect, useMemo, useState} from "react";
import styled from "styled-components";

const TabBarContainer = styled(TabBar)`
    position: static;
`;

const ListAnimationContainer = styled(animated.div)`
    width: 100%;
`;

const TransactionsTotalDetailDialog = ({
                                           transactionsType,
                                           data,
                                       }: {
    transactionsType: TransactionsType;
    data: TransactionsDailyFilteredFigures;
}) => {
    const repository = useRepository(TransactionsRepository);

    const [status, setStatus] = useState(ViewStatusType.Loading);
    const [tabIndex, setTabIndex] = useState(0);
    const [details, setDetails] = useState<TransactionsFiguresDetail[]>([]);

    const figuresTypes = useMemo(
        () =>
            Object.values(TransactionsFiguresType).filter(
                (t) =>
                    TransactionsFiguresTypeMap.transactionsType(t) ===
                    transactionsType
            ),
        [transactionsType]
    );

    const totalsData = useMemo(
        () =>
            data.values.map((d, index) => ({
                label:
                    index === 0
                        ? S.transactionsTotalDetailDialog.totalLabel(
                            TransactionsTypeMap.shortLabel(transactionsType)
                        )
                        : S.transactionsTotalDetailDialog.figuresLabel(
                            TransactionsFiguresTypeMap.shortLabel(
                                figuresTypes[index - 1]
                            ),
                            TransactionsTypeMap.shortLabel(transactionsType)
                        ),
                value: d,
            })),
        [figuresTypes, transactionsType, data]
    );

    useEffect(() => {
        repository({
            handler: async (repository) => {
                const transactionsFiguresType = figuresTypes[tabIndex];

                const details = await repository.getFiguresDetails({
                    date: data.date,
                    transactionsFiguresType: transactionsFiguresType,
                });

                setDetails(details);

                if (status !== ViewStatusType.Loaded) {
                    setStatus(ViewStatusType.Loaded);
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabIndex]);

    const {titles, listData, listDataKey} = useMemo(() => {
        const type = figuresTypes[tabIndex];
        const titles = [
            TransactionsFiguresTypeMap.detailDialogHasCreditCardCompany(type)
                ? S.transactionsTotalDetailDialog.creditCardHeaderTitle
                : undefined,
            TransactionsFiguresTypeMap.detailDialogHasDetail(type)
                ? S.transactionsTotalDetailDialog.detailsHeaderTitle
                : undefined,
            TransactionsFiguresTypeMap.detailDialogHasDateTime(type)
                ? S.transactionsTotalDetailDialog.dateTimeHeaderTitle
                : undefined,
            S.transactionsTotalDetailDialog.amountHeaderTitle,
        ]
            .filter((t) => t !== undefined)
            .map((t) => t!);
        const listData = details.map((d) =>
            [
                TransactionsFiguresTypeMap.detailDialogHasCreditCardCompany(
                    type
                ) || TransactionsFiguresTypeMap.detailDialogHasDetail(type)
                    ? d.label
                    : undefined,
                TransactionsFiguresTypeMap.detailDialogHasDateTime(type)
                    ? d.dateTime.format(
                        S.transactionsTotalDetailDialog.transactionTimeFormat
                    )
                    : undefined,
                NumberHelper.toStringWithDividers(d.value),
            ]
                .filter((ld) => ld !== undefined)
                .map((ld) => ld!)
        );
        const listDataKey = tabIndex.toString();

        return {
            titles,
            listData,
            listDataKey,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details]);

    const {props} = useFadeInTransition(listDataKey);

    const onClick = (index: number) => () => setTabIndex(index);

    return (
        <Dialog
            widthInPx={720}
            status={status}
            iconAsset={SVGAssets.Sales}
            title={S.transactionsTotalDetailDialog.title(
                TransactionsTypeMap.shortLabel(transactionsType)
            )}
        >
            <DialogDateHeader date={data.date}/>
            <SizedBox height={"8px"}/>
            <DialogTotalsSummary data={totalsData}/>
            <SizedBox height={"24px"}/>
            <TabBarContainer
                horizontalPaddingInPx={0}
                collapsedHorizontalPaddingInPx={0}
            >
                {figuresTypes.map((t, index) => (
                    <TabBarItem
                        key={index}
                        flex={1}
                        label={TransactionsFiguresTypeMap.shortLabel(t)}
                        selected={index === tabIndex}
                        onClick={onClick(index)}
                    />
                ))}
            </TabBarContainer>
            <SizedBox height={"24px"}/>
            <ListAnimationContainer style={props}>
                <DialogList autoSize={false} titles={titles} data={listData}/>
            </ListAnimationContainer>
        </Dialog>
    );
};

export default TransactionsTotalDetailDialog;
