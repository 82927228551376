import AppRoute from "presentation/routes/model/app_routes";
import SVGAssets from "presentation/theme/assets";
import S from "presentation/theme/s";
import {optional} from "presentation/utils/types/optional";
import {ReactElement} from "react";
import {openInNewTab} from "presentation/utils/functions/open_in_new_tab";

export enum LNBSectionType {
    Tax = "tax",
    HumanResources = "humanResources",
    Others = "others",
}

export const LNBSectionTypeMap = {
    label: (type: LNBSectionType): string => {
        switch (type) {
            case LNBSectionType.Tax:
                return S.lnbSectionType.taxLabel;
            case LNBSectionType.HumanResources:
                return S.lnbSectionType.humanResourcesLabel;
            case LNBSectionType.Others:
                return S.lnbSectionType.othersLabel;
            default:
                throw new Error("Invalid LNBSectionType");
        }
    },
};

export enum LNBItemType {
    Dashboard = "dashboard",
    ProfitReport = "profitReport",
    Billing = "billing",
    CardSales = "cardSales",
    Transactions = "transactions",
    TaxTimeline = "taxTimeline",
    HumanResources = "humanResources",
    AITaxCheck = "aiTaxCheck",
    DocumentBundle = "documentBundle",
    ExternalSyncs = "externalSyncs",
    CustomerService = "customerService",
}

export const LNBItemTypeMap = {
    label: (type: LNBItemType): string => {
        switch (type) {
            case LNBItemType.Dashboard:
                return S.lnbItemType.dashboardLabel;
            case LNBItemType.ProfitReport:
                return S.lnbItemType.profitReportLabel;
            case LNBItemType.Billing:
                return S.lnbItemType.billingLabel;
            case LNBItemType.CardSales:
                return S.lnbItemType.cardSalesLabel;
            case LNBItemType.Transactions:
                return S.lnbItemType.transactionsLabel;
            case LNBItemType.TaxTimeline:
                return S.lnbItemType.taxTimelineLabel;
            case LNBItemType.HumanResources:
                return S.lnbItemType.humanResourcesLabel;
            case LNBItemType.AITaxCheck:
                return S.lnbItemType.aiTaxCheckLabel;
            case LNBItemType.DocumentBundle:
                return S.lnbItemType.documentBundleLabel;
            case LNBItemType.ExternalSyncs:
                return S.lnbItemType.externalSyncsLabel;
            case LNBItemType.CustomerService:
                return S.lnbItemType.customerServiceLabel;
            default:
                throw new Error("Invalid LNBItemType");
        }
    },
    sectionType: (type: LNBItemType): optional<LNBSectionType> => {
        switch (type) {
            case LNBItemType.Dashboard:
                return undefined;
            case LNBItemType.ProfitReport:
            case LNBItemType.Billing:
            case LNBItemType.CardSales:
            case LNBItemType.Transactions:
            case LNBItemType.TaxTimeline:
                return LNBSectionType.Tax;
            case LNBItemType.HumanResources:
                return LNBSectionType.HumanResources;
            case LNBItemType.AITaxCheck:
            case LNBItemType.DocumentBundle:
            case LNBItemType.ExternalSyncs:
            case LNBItemType.CustomerService:
                return LNBSectionType.Others;
            default:
                throw new Error("Invalid LNBItemType");
        }
    },
    icon: (type: LNBItemType): ReactElement => {
        switch (type) {
            case LNBItemType.Dashboard:
                return SVGAssets.Home;
            case LNBItemType.ProfitReport:
                return SVGAssets.Report;
            case LNBItemType.Billing:
                return SVGAssets.Money;
            case LNBItemType.CardSales:
                return SVGAssets.CreditCard;
            case LNBItemType.Transactions:
                return SVGAssets.DocumentSearch;
            case LNBItemType.TaxTimeline:
                return SVGAssets.CalendarCheck;
            case LNBItemType.HumanResources:
                return SVGAssets.People;
            case LNBItemType.AITaxCheck:
                return SVGAssets.TaxCheck;
            case LNBItemType.DocumentBundle:
                return SVGAssets.Documents;
            case LNBItemType.ExternalSyncs:
                return SVGAssets.Link;
            case LNBItemType.CustomerService:
                return SVGAssets.Info;
            default:
                throw new Error("Invalid LNBItemType");
        }
    },
    route: (type: LNBItemType): optional<AppRoute> => {
        switch (type) {
            case LNBItemType.Dashboard:
                return AppRoute.Dashboard;
            case LNBItemType.ProfitReport:
                return AppRoute.ProfitReport;
            case LNBItemType.Billing:
                return AppRoute.Billing;
            case LNBItemType.CardSales:
                return AppRoute.CardSales;
            case LNBItemType.Transactions:
                return AppRoute.Transactions;
            case LNBItemType.TaxTimeline:
                return AppRoute.TaxTimeline;
            case LNBItemType.HumanResources:
                return AppRoute.HumanResources;
            case LNBItemType.AITaxCheck:
                return undefined;
            case LNBItemType.DocumentBundle:
                return AppRoute.DocumentBundle;
            case LNBItemType.ExternalSyncs:
                return AppRoute.ExternalSyncs;
            case LNBItemType.CustomerService:
                return undefined;
            default:
                throw new Error("Invalid LNBItemType");
        }
    },
    onClick: (type: LNBItemType): optional<Function> => {
        switch (type) {
            case LNBItemType.Dashboard:
            case LNBItemType.ProfitReport:
            case LNBItemType.Billing:
            case LNBItemType.CardSales:
            case LNBItemType.Transactions:
            case LNBItemType.TaxTimeline:
            case LNBItemType.HumanResources:
                return undefined;
            case LNBItemType.AITaxCheck:
                return () => {
                    openInNewTab("https://jeekimtax.com/40");
                };
            case LNBItemType.DocumentBundle:
            case LNBItemType.ExternalSyncs:
                return undefined;
            case LNBItemType.CustomerService:
                return () => {
                    openInNewTab("https://jeekim.co.kr/cs");
                };
            default:
                throw new Error("Invalid LNBItemType");
        }
    },
};
