import S from "presentation/theme/s";

const hundredMillionsUnit = 100_000_000;
const tenThousandsUnit = 10_000;
const thousandsUnit = 1_000;

export default class NumberHelper {

    static getUnitFromMinMaxCount = (
        min: number,
        max: number,
        count: number
    ): number => {
        const digits =
            Math.max(Math.abs(max), Math.abs(min)).toString().length - 1;
        const decimal = Math.pow(10, digits);

        return Math.ceil(Math.abs(max - min) / (count * decimal)) * decimal;
    };

    static getBoundsForCount = (
        min: number,
        max: number,
        count: number
    ): number[] => {
        const values: number[] = [];

        const unit = NumberHelper.getUnitFromMinMaxCount(min, max, count);
        let lowerBound = NumberHelper.getLowerBoundForUnit(min, unit);
        let upperBound = NumberHelper.getUpperBoundForUnit(max, unit);

        let pointer = lowerBound;
        while (pointer <= upperBound) {
            values.push(pointer);
            pointer += unit;
        }
        upperBound += unit;

        let positiveCount = values.filter((v) => v >= 0).length;
        let negativeCount = values.filter((v) => v < 0).length;
        let valueCount = values.length + 1;

        while (valueCount < count) {
            if (positiveCount < negativeCount) {
                upperBound += unit;
                positiveCount += 1;
            } else {
                lowerBound -= unit;
                negativeCount += 1;
            }

            valueCount += 1;
        }

        return [lowerBound, upperBound];
    };

    static getUpperBoundForUnit = (value: number, unit: number): number => {
        return Math.ceil(value / unit) * unit;
    };

    static getLowerBoundForUnit = (value: number, unit: number): number => {
        return Math.floor(value / unit) * unit;
    };

    static toChartAbbreviatedString = (value: number): string => {
        const sign = value < 0 ? "-" : "";
        let result = Math.abs(value);
        const hundredMillions = Math.floor(
            result / hundredMillionsUnit
        );
        result = Math.floor(result % hundredMillionsUnit);
        const tenThousands = Math.floor(result / tenThousandsUnit);
        result = Math.floor(result % tenThousandsUnit);
        const thousands = Math.floor(result / thousandsUnit);
        result = Math.floor(result % thousandsUnit);

        let values = [
            hundredMillions !== 0 &&
            NumberHelper.toStringWithDividers(hundredMillions) +
            S.numberHelper.hundredMillionsLabel,
            tenThousands !== 0 &&
            NumberHelper.toStringWithDividers(tenThousands) +
            S.numberHelper.tenThousandsLabel,
            thousands !== 0 &&
            NumberHelper.toStringWithDividers(thousands) +
            S.numberHelper.thousandsLabel,
        ];

        return (
            sign +
            (values.every((e) => !e)
                ? NumberHelper.toStringWithDividers(result)
                : values.filter((e) => !!e).join(" "))
        );
    };

    static toTableAbbreviatedString = (value: number): string => {
        const sign = value < 0 ? "-" : "";
        let result = Math.abs(value);
        const hundredMillions = Math.floor(
            result / hundredMillionsUnit
        );
        result = Math.floor(result % hundredMillionsUnit);
        const tenThousands = Math.floor(result / tenThousandsUnit);

        return (
            sign +
            [
                hundredMillions !== 0 &&
                NumberHelper.toStringWithDividers(hundredMillions) +
                S.numberHelper.hundredMillionsLabel,
                tenThousands !== 0 &&
                NumberHelper.toStringWithDividers(tenThousands) +
                S.numberHelper.tenThousandsLabel,
            ]
                .filter((e) => !!e)
                .join(" ")
        );
    };

    static toStringWithDividers = (value: number): string => {
        const digits = [];
        const sign = value < 0 ? "-" : "";
        const string = Math.abs(Math.floor(value)).toString();

        let count = 0;
        let i = string.length - 1;
        while (i >= 0) {
            if (count > 0 && count % 3 === 0) digits.push(",");
            digits.push(string[i]);
            i--;
            count++;
        }

        i = digits.length - 1;
        while (digits[i] === "0" || digits[i] === ",") {
            digits.pop();
            i--;
        }

        return (
            sign + (digits.length === 0 ? "0" : digits.toReversed().join(""))
        );
    };

    static degreeToRadian = (degree: number): number => {
        return (degree * Math.PI) / 180;
    };

    static clamp = (value: number, min: number, max: number): number => {
        return Math.min(Math.max(value, min), max);
    };

    static round = (value: number, precision: number): number => {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    };

    static toFixed = (value: number, precision: number): string => {
        return NumberHelper.round(value, precision).toFixed(precision);
    };

    static toFixedIfNotRound = (value: number, precision: number): string => {
        const string = NumberHelper.toFixed(value, precision);

        let pattern = ".";
        for (let i = 0; i < precision; i++) pattern += "0";

        if (string.endsWith(pattern)) return string.replaceAll(pattern, "");

        return string;
    };

    static toCalendarAbbreviatedString = (value: number): string => {
        if (Math.abs(value) >= hundredMillionsUnit) {
            return (
                NumberHelper.toFixedIfNotRound(
                    value / hundredMillionsUnit,
                    1
                ) + S.numberHelper.hundredMillionsLabel
            );
        }

        return (
            NumberHelper.toFixedIfNotRound(
                value / tenThousandsUnit,
                1
            ) + S.numberHelper.tenThousandsLabel
        );
    };

    static toChartYAxisAbbreviatedString = (value: number): string => {
        if (value === 0) return value.toString();

        if (Math.abs(value) >= hundredMillionsUnit) {
            return (
                NumberHelper.toFixedIfNotRound(
                    value / hundredMillionsUnit,
                    1
                ) + S.numberHelper.hundredMillionsLabel
            );
        }

        return (
            NumberHelper.toFixedIfNotRound(
                value / tenThousandsUnit,
                1
            ) + S.numberHelper.tenThousandsLabel
        );
    };
}
