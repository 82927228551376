import cardSalesState from "presentation/states/card_sales/card_sales_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const cardSalesStatusBoardSelector = selector({
    key: "cardSalesStatusBoardSelector",
    get: ({get}) => {
        const state = get(cardSalesState);

        return {
            selectedDateFilter: state.statusBoard.selectedDateFilter,
            startDate: DateTime.fromKey(state.statusBoard.startDateKey),
            endDate: DateTime.fromKey(state.statusBoard.endDateKey),
            selectedDropdownType: state.statusBoard.selectedDropdownType,
            cancelFilterOn: state.statusBoard.cancelFilterOn,
            pendingFilterOn: state.statusBoard.pendingFilterOn,
            cardSalesDateSortType: state.statusBoard.cardSalesDateSortType,
            cardSalesTotalSortType: state.statusBoard.cardSalesTotalSortType,
            receiveDateSortType: state.statusBoard.receiveDateSortType,
            receiveTotalSortType: state.statusBoard.receiveTotalSortType,
            data: state.statusBoard.data,
        };
    },
});

export default cardSalesStatusBoardSelector;
