import {optional} from "presentation/utils/types/optional";

export const changePathWithoutRedirect = (
    path: string,
    {
        replace,
    }: optional<{
        replace: boolean;
    }> = {replace: false}
) => {
    if (replace) {
        window.history.replaceState(null, "", path);
        return;
    }

    window.history.pushState(null, "", path);
};
