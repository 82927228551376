import ReplayState from "presentation/states/base/replay_state";
import {optional} from "presentation/utils/types/optional";
import {BehaviorSubject} from "rxjs";

export const useSetReplayState = <State extends ReplayState>(
    replayState: BehaviorSubject<optional<State>>
) => {
    return (state: State) => {
        replayState.next(state);
    };
};
