import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    min-height: 27px;
    border-bottom: 1px solid ${Palette.gray200};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const TitleContainer = styled.p`
    ${Fonts.detail3Medium};
    color: ${Palette.gray400};
`;

const ProfileContentTitleView = () => {
    return (
        <LayoutContainer>
            <TitleContainer>{S.profilePage.contentTitle}</TitleContainer>
        </LayoutContainer>
    );
};

export default ProfileContentTitleView;
