import {animated, useSprings} from "@react-spring/web";
import {DispensingChartContext} from "presentation/components/charts/dispensing_chart/dispensing_chart";
import Palette from "presentation/theme/palette";
import {Fragment, useContext, useEffect, useMemo} from "react";
import styled from "styled-components";

const BarBackgroundContainer = styled(animated.div).attrs<{
    $widthInPx: number;
    $heightInPx: number;
    $topOffsetInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
        top: `${props.$topOffsetInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
    },
}))`
    position: absolute;
    z-index: 0;
    transform: translateX(-50%);
    transition: height 0.3s ease-in-out;
`;

const BarRimContainer = styled.div.attrs<{
    $hovered: boolean;
    $widthInPx: number;
    $heightInPx: number;
    $borderRadiusInPx: number;
    $bottomOffsetInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
        backgroundColor: props.$hovered ? Palette.primary15 : Palette.none,
        borderTopLeftRadius: props.$borderRadiusInPx,
        borderTopRightRadius: props.$borderRadiusInPx,
        bottom: `${props.$bottomOffsetInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
    },
}))`
    position: absolute;
    pointer-events: none;
    transform: translateX(-50%);
    transition: height 0.3s ease-in-out, background-color 0.3s ease-in-out,
        border-radius 0.3s ease-in-out, top 0.3s ease-in-out,
        left 0.3s ease-in-out;
`;

const BarContainer = styled(animated.div).attrs<{
    $widthInPx: number;
    $heightInPx: number;
    $borderRadiusInPx: number;
    $bottomOffsetInPx: number;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
        borderTopLeftRadius: `${props.$borderRadiusInPx}px`,
        borderTopRightRadius: `${props.$borderRadiusInPx}px`,
        bottom: `${props.$bottomOffsetInPx}px`,
        left: `${props.$leftOffsetInPx}px`,
    },
}))`
    background: rgba(4, 169, 255, 1);
    background: -webkit-linear-gradient(
        bottom,
        rgba(4, 169, 255, 1),
        rgba(70, 195, 255, 0.1)
    );
    background: linear-gradient(
        to bottom,
        rgba(4, 169, 255, 1),
        rgba(70, 195, 255, 0.1)
    );
    position: absolute;
    z-index: 1;
    pointer-events: none;
    transform: translateX(-50%);
    transition: height 0.3s ease-in-out, border-radius 0.3s ease-in-out,
        left 0.3s ease-in-out;
`;

const DispensingChartBars = () => {
    const context = useContext(DispensingChartContext);
    const data = useMemo(
        () =>
            context.data.map((d) => {
                const xPos = context.functions.getXPos(d.x);
                const yPosEnd = context.functions.getYPosFromTop(d.y);
                const yPosStart = context.functions.getYPosFromTop(0);
                const yPosStartFromBottom =
                    context.functions.getYPosFromBottom(0);
                const height = Math.abs(yPosStart - yPosEnd);
                const borderRadius =
                    height >= context.constants.barBorderRadiusInPx
                        ? context.constants.barBorderRadiusInPx
                        : height;
                const rimWidth =
                    context.constants.binSizeInPx +
                    context.constants.barRimPaddingInPx * 2;
                const rimHeight = height + context.constants.barRimPaddingInPx;
                const rimBorderRadius =
                    borderRadius >= context.constants.barBorderRadiusInPx
                        ? borderRadius + context.constants.barRimPaddingInPx
                        : borderRadius;

                return {
                    key: d.x,
                    xPos,
                    yPosStartFromBottom,
                    height,
                    borderRadius,
                    rimWidth,
                    rimHeight,
                    rimBorderRadius,
                };
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [context.data, context.highlightedXIndices, context.constantsKey]
    );

    const [springs, api] = useSprings(data.length, () => ({
        height: "0px",
    }));

    useEffect(() => {
        api.start((index) => ({
            height: `${data[index].height}px`,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.data, context.constantsKey]);

    const onMouseEnter = (index: number) => () =>
        context.setHoveredXIndex?.(index);
    const onMouseLeave = () => context.setHoveredXIndex?.(undefined);

    return (
        <>
            {data.map((d, index) => {
                const hovered = context.hoveredXIndex === index;

                return (
                    <Fragment key={d.key}>
                        <BarBackgroundContainer
                            onMouseEnter={onMouseEnter(index)}
                            onMouseLeave={onMouseLeave}
                            $widthInPx={context.constants.binSizeInPx}
                            $topOffsetInPx={context.constants.topMarginInPx}
                            $heightInPx={context.constants.innerHeightInPx}
                            $leftOffsetInPx={d.xPos}
                        />
                        <BarRimContainer
                            $hovered={hovered}
                            $widthInPx={d.rimWidth}
                            $heightInPx={d.rimHeight}
                            $borderRadiusInPx={d.rimBorderRadius}
                            $bottomOffsetInPx={d.yPosStartFromBottom}
                            $leftOffsetInPx={d.xPos}
                        />
                    </Fragment>
                );
            })}
            {springs.map((props, xIndex) => {
                const d = data[xIndex];

                return (
                    <BarContainer
                        key={d.key}
                        style={props}
                        $widthInPx={context.constants.binSizeInPx}
                        $heightInPx={d.height}
                        $borderRadiusInPx={d.borderRadius}
                        $bottomOffsetInPx={d.yPosStartFromBottom}
                        $leftOffsetInPx={d.xPos}
                    />
                );
            })}
        </>
    );
};

export default DispensingChartBars;
