import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.button`
    width: 100%;
    min-height: 22px;
    position: relative;
    border: none;
    background-color: ${Palette.none};
    cursor: pointer;
`;

const PositionedContainer = styled.div`
    position: absolute;
    top: 0;
    left: 8px;
    pointer-events: none;
    transition: left 0.3s ease-in-out;

    @media (max-width: 768px) {
        left: 0;
    }
`;

const LogoFullContainer = styled(PositionedContainer)`
    opacity: 1;
    transition: left 0.3s ease-in-out, 0.3s ease-in-out;

    @media (max-width: 768px) {
        opacity: 0;
    }
`;

const LNBLogo = ({onClick}: { onClick: () => void }) => {
    return (
        <LayoutContainer onClick={onClick}>
            <LogoFullContainer onClick={onClick}>
                <SVG height={"22px"} asset={SVGAssets.LogoFull}/>
            </LogoFullContainer>
            <PositionedContainer onClick={onClick}>
                <SVG height={"22px"} asset={SVGAssets.Logo}/>
            </PositionedContainer>
        </LayoutContainer>
    );
};

export default LNBLogo;
