import SortType from "domain/model/common/sort_type";
import CardSalesReceiveTable
    from "presentation/pages/card_sales/view/status_board/components/card_sales_receive_table/card_sales_receive_table";
import cardSalesState from "presentation/states/card_sales/card_sales_state";
import cardSalesStatusBoardSelector from "presentation/states/card_sales/selector/card_sales_status_board_selector";
import {useRecoilValue, useSetRecoilState} from "recoil";

const CardSalesStatusBoardReceiveTableView = () => {
    const {data, receiveDateSortType, receiveTotalSortType} = useRecoilValue(
        cardSalesStatusBoardSelector
    );
    const setState = useSetRecoilState(cardSalesState);

    const onSortChange = (dateSortType: SortType, totalSortType: SortType) =>
        setState((prev) => ({
            ...prev,
            statusBoard: {
                ...prev.statusBoard,
                receiveDateSortType: dateSortType,
                receiveTotalSortType: totalSortType,
            },
        }));

    return (
        <CardSalesReceiveTable
            data={data}
            dateSortType={receiveDateSortType}
            totalSortType={receiveTotalSortType}
            onSortChange={onSortChange}
        />
    );
};

export default CardSalesStatusBoardReceiveTableView;
