import {ChartInitialScrollPositionType} from "presentation/components/charts/common/chart_model";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";

enum ProfitReportDateFilterType {
    RecentSixMonths = "recentSixMonths",
    ThisYear = "thisYear",
}

export const ProfitReportDateFilterTypeMap = {
    label: (type: ProfitReportDateFilterType): string => {
        switch (type) {
            case ProfitReportDateFilterType.RecentSixMonths:
                return S.profitDateFilterType.recentSixMonthsLabel;
            case ProfitReportDateFilterType.ThisYear:
                return S.profitDateFilterType.thisYearLabel;
            default:
                throw new Error("Invalid ProfitReportDateFilterType");
        }
    },
    datePeriod: (
        type: ProfitReportDateFilterType
    ): { startDate: DateTime; endDate: DateTime } => {
        const now = DateTime.now().min;
        const currentYearMonth = now.getFirstDayInMonth();
        switch (type) {
            case ProfitReportDateFilterType.RecentSixMonths:
                const sixMonthsBefore = currentYearMonth.copyWith({
                    month: currentYearMonth.month - 5,
                });

                return {
                    startDate: sixMonthsBefore,
                    endDate: currentYearMonth,
                };
            case ProfitReportDateFilterType.ThisYear:
                return {
                    startDate: now.copyWith({
                        month: 1,
                        day: 1,
                    }),
                    endDate: currentYearMonth,
                };
            default:
                throw new Error("Invalid ProfitReportDateFilterType");
        }
    },
    initialScrollPosition: (
        type?: ProfitReportDateFilterType
    ): ChartInitialScrollPositionType => {
        if (!type) return ChartInitialScrollPositionType.Left;

        switch (type) {
            case ProfitReportDateFilterType.RecentSixMonths:
                return ChartInitialScrollPositionType.Right;
            case ProfitReportDateFilterType.ThisYear:
                return ChartInitialScrollPositionType.Left;
            default:
                throw new Error("Invalid ProfitReportDateFilterType");
        }
    },
};

export default ProfitReportDateFilterType;
