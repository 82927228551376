import {animated, useSpring} from "@react-spring/web";
import UserRepository from "data/repository/user_repository";
import ErrorCode from "domain/model/common/error_code";
import FilledButton from "presentation/components/button/filled_button";
import TextButton from "presentation/components/button/text_button";
import SizedBox from "presentation/components/common/sized_box";
import {TextField} from "presentation/components/text_field/text_field";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import {
    LoginCertCodeFocusRequest,
    LoginRequestCertCode,
} from "presentation/states/login/login_listenable_state";
import loginState, {
    loginListenableState,
} from "presentation/states/login/login_state";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import {useListenableState} from "presentation/utils/hooks/use_listenable_state";
import useReadRecoilState from "presentation/utils/hooks/use_read_recoil_state";
import useRepository from "presentation/utils/hooks/use_repository";
import {useSetListenableState} from "presentation/utils/hooks/use_set_listenable_state";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import {decimalValidator} from "presentation/utils/input_validator/decimal_validator";
import PointerEventsType from "presentation/utils/types/pointer_events_type";
import {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const maxLength = 5;

const LayoutContainer = styled(animated.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`;

const ErrorMessageLayoutContainer = styled.div`
    width: 100%;
    min-height: 36px;
    padding: 4px 16px 0 16px;
`;

const ErrorMessageLabelContainer = styled(animated.p)`
    min-width: max-content;
    ${Fonts.detail2Medium};
    color: ${Palette.red500};
`;

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

const LoginCertCodeView = () => {
    const repository = useRepository(UserRepository);
    const showNotificationDialog = useShowNotificationDialog();
    const setListenableState = useSetListenableState(loginListenableState);
    const setLoading = useSetLoading();

    const {certCodeRequested} = useRecoilValue(loginState);
    const readState = useReadRecoilState(loginState);

    const [errorMessage, setErrorMessage] = useState("");
    const [code, setCode] = useState("");
    const [focused, setFocused] = useState(false);

    const [props, api] = useSpring(() => ({
        opacity: certCodeRequested ? 1 : 0,
        pointerEvents: (certCodeRequested
            ? "auto"
            : "none") as PointerEventsType,
    }));
    const {props: errorMessageProps} = useFadeInTransition(errorMessage);

    useListenableState(loginListenableState, (state) => {
        if (state instanceof LoginCertCodeFocusRequest) {
            setTimeout(() => setFocused(true), 500);
        }
    });

    useEffect(() => {
        if (certCodeRequested) {
            addPostFrameCallback(() => {
                setFocused(true);
                api.start({
                    opacity: 1,
                    pointerEvents: "auto" as PointerEventsType,
                });
            });
            return;
        }

        api.set({
            opacity: certCodeRequested ? 1 : 0,
            pointerEvents: (certCodeRequested
                ? "auto"
                : "none") as PointerEventsType,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certCodeRequested]);

    useEffect(() => {
        setErrorMessage("");
    }, [code]);

    const enabled = code.length === maxLength;

    const onClick = () =>
        repository({
            handler: async (repository) => {
                if (code.length !== maxLength) return;

                setLoading(true);

                const state = await readState();

                await repository.login({
                    phoneNumber: state.phoneNumber,
                    code,
                });

                setFocused(false);

                AppRouter.navigate(AppRoutes.Main, {
                    replace: true,
                });
            },
            onDomainError: (errorCode, showUnknownError) => {
                switch (errorCode) {
                    case ErrorCode.CertCodeExpired:
                        setErrorMessage(
                            S.loginPage.certCodeExpiredErrorMessage
                        );
                        setFocused(true);
                        break;

                    case ErrorCode.WrongCertCode:
                        setErrorMessage(S.loginPage.wrongCertCodeErrorMessage);
                        setFocused(true);
                        break;

                    default:
                        showUnknownError();
                        break;
                }
            },
            onFinally: () => setLoading(false),
        });

    const onCSButtonClick = () =>
        showNotificationDialog({
            type: "negative",
            title: S.loginPage.csDialogTitle,
            message: S.loginPage.csDialogMessage,
            hasNegativeButton: false,
        });

    const onResendButtonClick = () => {
        setCode("");
        setListenableState(new LoginRequestCertCode());
    };

    return (
        <LayoutContainer style={props}>
            <TextField
                error={!!errorMessage}
                value={code}
                onValueChange={setCode}
                focused={focused}
                onFocusChange={setFocused}
                placeholder={S.loginPage.certCodeInputPlaceholder}
                maxLength={maxLength}
                inputValidator={decimalValidator}
                onEnterPress={onClick}
            />
            <ErrorMessageLayoutContainer>
                <ErrorMessageLabelContainer style={errorMessageProps}>
                    {errorMessage}
                </ErrorMessageLabelContainer>
            </ErrorMessageLayoutContainer>
            <FilledButton
                enabled={enabled && !errorMessage}
                width={"100%"}
                label={S.loginPage.loginButtonLoginLabel}
                onClick={onClick}
            />
            <SizedBox height={"16px"}/>
            <RowContainer>
                <TextButton
                    sizeType={"small"}
                    label={S.loginPage.csButtonLabel}
                    onClick={onCSButtonClick}
                />
                <TextButton
                    type={"black"}
                    sizeType={"small"}
                    label={S.loginPage.resendButtonLabel}
                    color={Palette.gray600}
                    onClick={onResendButtonClick}
                />
            </RowContainer>
        </LayoutContainer>
    );
};

export default LoginCertCodeView;
