import DonutChartType, {DonutChartTypeMap,} from "domain/model/tax/chart/donut_chart_type";
import IconButton, {IconButtonHoverColorType,} from "presentation/components/button/icon_button";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

export enum DashboardProfitDonutChartHeaderButtonCallerType {
    Previous = "previous",
    Next = "next",
}

export const DashboardProfitDonutChartHeaderButtonCallerTypeMap = {
    fromTranslateX: (
        type: DashboardProfitDonutChartHeaderButtonCallerType
    ): string => {
        switch (type) {
            case DashboardProfitDonutChartHeaderButtonCallerType.Previous:
                return "-100%";
            case DashboardProfitDonutChartHeaderButtonCallerType.Next:
                return "100%";
            default:
                throw new Error(
                    "Invalid DashboardProfitDonutChartHeaderButtonCallerType"
                );
        }
    },
    leaveTranslateX: (
        type: DashboardProfitDonutChartHeaderButtonCallerType
    ): string => {
        switch (type) {
            case DashboardProfitDonutChartHeaderButtonCallerType.Previous:
                return "100%";
            case DashboardProfitDonutChartHeaderButtonCallerType.Next:
                return "-100%";
            default:
                throw new Error(
                    "Invalid DashboardProfitDonutChartHeaderButtonCallerType"
                );
        }
    },
    chartType: (
        type: DashboardProfitDonutChartHeaderButtonCallerType,
        chartType: DonutChartType
    ): DonutChartType => {
        switch (type) {
            case DashboardProfitDonutChartHeaderButtonCallerType.Previous:
                return DonutChartTypeMap.previous(chartType);
            case DashboardProfitDonutChartHeaderButtonCallerType.Next:
                return DonutChartTypeMap.next(chartType);
            default:
                throw new Error(
                    "Invalid DashboardProfitDonutChartHeaderButtonCallerType"
                );
        }
    },
};

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TitleContainer = styled.h4`
    min-width: max-content;
    ${Fonts.detail3Medium};
    text-align: center;
`;

const DashboardProfitDonutChartHeaderView = ({
                                                 title,
                                                 onClick,
                                             }: {
    title: string;
    onClick: (caller: DashboardProfitDonutChartHeaderButtonCallerType) => void;
}) => {
    const onPreviousButtonClick = () =>
        onClick(DashboardProfitDonutChartHeaderButtonCallerType.Previous);
    const onNextButtonClick = () =>
        onClick(DashboardProfitDonutChartHeaderButtonCallerType.Next);

    return (
        <LayoutContainer>
            <IconButton
                asset={SVGAssets.Back}
                width={"24px"}
                height={"24px"}
                color={Palette.gray800}
                hoverColor={IconButtonHoverColorType.Light}
                onClick={onPreviousButtonClick}
            />
            <TitleContainer>{title}</TitleContainer>
            <IconButton
                asset={SVGAssets.Next}
                width={"24px"}
                height={"24px"}
                color={Palette.gray800}
                hoverColor={IconButtonHoverColorType.Light}
                onClick={onNextButtonClick}
            />
        </LayoutContainer>
    );
};

export default DashboardProfitDonutChartHeaderView;
