import TransactionsType from "domain/model/tax/transactions/transactions_type";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {optional} from "presentation/utils/types/optional";
import {ReactElement} from "react";

enum TransactionsFiguresType {
    CardSales = "CARD_SALES",
    CashSales = "CASH_SALES",
    DispensingSales = "DISPENSING_SALES",
    ExpectedCardReceipt = "EXPECTED_INCOME_CARD",
    ExpectedDispensingSalesReceipt = "EXPECTED_INCOME_DISPENSING_SALES",
    CardPurchases = "CARD_PURCHASES",
    CashPurchases = "CASH_PURCHASES",
    ElectronicTaxInvoicePurchases = "ELECTRONIC_TAX_INVOICE_PURCHASES",
}

export const TransactionsFiguresTypeMap = {
    transactionsType: (type: TransactionsFiguresType): TransactionsType => {
        switch (type) {
            case TransactionsFiguresType.CardSales:
            case TransactionsFiguresType.CashSales:
            case TransactionsFiguresType.DispensingSales:
                return TransactionsType.Sales;
            case TransactionsFiguresType.ExpectedCardReceipt:
            case TransactionsFiguresType.ExpectedDispensingSalesReceipt:
                return TransactionsType.ExpectedReceipts;
            case TransactionsFiguresType.CardPurchases:
            case TransactionsFiguresType.CashPurchases:
            case TransactionsFiguresType.ElectronicTaxInvoicePurchases:
                return TransactionsType.Purchases;
            default:
                throw new Error("Invalid TransactionsFiguresType");
        }
    },
    shortLabel: (type: TransactionsFiguresType): string => {
        switch (type) {
            case TransactionsFiguresType.CardSales:
                return S.transactionsFiguresType.cardSalesShortLabel;
            case TransactionsFiguresType.CashSales:
                return S.transactionsFiguresType.cashSalesShortLabel;
            case TransactionsFiguresType.DispensingSales:
                return S.transactionsFiguresType.dispensingSalesShortLabel;
            case TransactionsFiguresType.ExpectedCardReceipt:
                return S.transactionsFiguresType.expectedCardReceiptShortLabel;
            case TransactionsFiguresType.ExpectedDispensingSalesReceipt:
                return S.transactionsFiguresType
                    .expectedDispensingSalesReceiptShortLabel;
            case TransactionsFiguresType.CardPurchases:
                return S.transactionsFiguresType.cardPurchasesShortLabel;
            case TransactionsFiguresType.CashPurchases:
                return S.transactionsFiguresType.cashPurchasesShortLabel;
            case TransactionsFiguresType.ElectronicTaxInvoicePurchases:
                return S.transactionsFiguresType
                    .electronicTaxInvoicePurchasesShortLabel;
            default:
                throw new Error("Invalid TransactionsFiguresType");
        }
    },
    asset: (type: TransactionsFiguresType): ReactElement => {
        switch (type) {
            case TransactionsFiguresType.CardSales:
                return SVGAssets.CreditCard;
            case TransactionsFiguresType.CashSales:
                return SVGAssets.Cash;
            case TransactionsFiguresType.DispensingSales:
                return SVGAssets.PublicSales;
            case TransactionsFiguresType.ExpectedCardReceipt:
                return SVGAssets.CreditCard;
            case TransactionsFiguresType.ExpectedDispensingSalesReceipt:
                return SVGAssets.PublicSales;
            case TransactionsFiguresType.CardPurchases:
                return SVGAssets.CreditCard;
            case TransactionsFiguresType.CashPurchases:
                return SVGAssets.Cash;
            case TransactionsFiguresType.ElectronicTaxInvoicePurchases:
                return SVGAssets.PublicSales;
            default:
                throw new Error("Invalid TransactionsFiguresType");
        }
    },
    tableColor: (type: TransactionsFiguresType): optional<string> => {
        switch (type) {
            case TransactionsFiguresType.CardSales:
                return Palette.deepBlue;
            case TransactionsFiguresType.CashSales:
                return Palette.primary500;
            case TransactionsFiguresType.DispensingSales:
                return Palette.primary300;
            case TransactionsFiguresType.ExpectedCardReceipt:
            case TransactionsFiguresType.ExpectedDispensingSalesReceipt:
                return undefined;
            case TransactionsFiguresType.CardPurchases:
                return Palette.deepBlue;
            case TransactionsFiguresType.CashPurchases:
                return Palette.primary500;
            case TransactionsFiguresType.ElectronicTaxInvoicePurchases:
                return Palette.primary300;
            default:
                throw new Error("Invalid TransactionsFiguresType");
        }
    },
    detailDialogTitle: (type: TransactionsFiguresType): string => {
        switch (type) {
            case TransactionsFiguresType.CardSales:
                return S.transactionsFiguresType.cardSalesDetailDialogTitle;
            case TransactionsFiguresType.CashSales:
                return S.transactionsFiguresType.cashSalesDetailDialogTitle;
            case TransactionsFiguresType.DispensingSales:
                return S.transactionsFiguresType
                    .dispensingSalesDetailDialogTitle;
            case TransactionsFiguresType.ExpectedCardReceipt:
                return S.transactionsFiguresType
                    .expectedCardReceiptDetailDialogTitle;
            case TransactionsFiguresType.ExpectedDispensingSalesReceipt:
                return S.transactionsFiguresType
                    .expectedDispensingSalesReceiptDetailDialogTitle;
            case TransactionsFiguresType.CardPurchases:
                return S.transactionsFiguresType.cardPurchasesDetailDialogTitle;
            case TransactionsFiguresType.CashPurchases:
                return S.transactionsFiguresType.cashPurchasesDetailDialogTitle;
            case TransactionsFiguresType.ElectronicTaxInvoicePurchases:
                return S.transactionsFiguresType
                    .electronicTaxInvoicePurchasesDetailDialogTitle;
            default:
                throw new Error("Invalid TransactionsFiguresType");
        }
    },
    detailDialogHasCreditCardCompany: (
        type: TransactionsFiguresType
    ): boolean => {
        switch (type) {
            case TransactionsFiguresType.CardSales:
                return true;
            case TransactionsFiguresType.CashSales:
            case TransactionsFiguresType.DispensingSales:
                return false;
            case TransactionsFiguresType.ExpectedCardReceipt:
                return true;
            case TransactionsFiguresType.ExpectedDispensingSalesReceipt:
            case TransactionsFiguresType.CardPurchases:
            case TransactionsFiguresType.CashPurchases:
            case TransactionsFiguresType.ElectronicTaxInvoicePurchases:
                return false;
            default:
                throw new Error("Invalid TransactionsFiguresType");
        }
    },
    detailDialogHasDetail: (type: TransactionsFiguresType): boolean => {
        switch (type) {
            case TransactionsFiguresType.CardSales:
            case TransactionsFiguresType.CashSales:
                return false;
            case TransactionsFiguresType.DispensingSales:
                return true;
            case TransactionsFiguresType.ExpectedCardReceipt:
                return false;
            case TransactionsFiguresType.ExpectedDispensingSalesReceipt:
                return true;
            case TransactionsFiguresType.CardPurchases:
                return true;
            case TransactionsFiguresType.CashPurchases:
                return false;
            case TransactionsFiguresType.ElectronicTaxInvoicePurchases:
                return true;
            default:
                throw new Error("Invalid TransactionsFiguresType");
        }
    },
    detailDialogHasDateTime: (type: TransactionsFiguresType): boolean => {
        switch (type) {
            case TransactionsFiguresType.CardSales:
            case TransactionsFiguresType.CashSales:
                return true;
            case TransactionsFiguresType.DispensingSales:
            case TransactionsFiguresType.ExpectedCardReceipt:
            case TransactionsFiguresType.ExpectedDispensingSalesReceipt:
                return false;
            case TransactionsFiguresType.CardPurchases:
            case TransactionsFiguresType.CashPurchases:
                return true;
            case TransactionsFiguresType.ElectronicTaxInvoicePurchases:
                return false;
            default:
                throw new Error("Invalid TransactionsFiguresType");
        }
    },
    calendarListButtonsLabel: (type: TransactionsFiguresType): string => {
        switch (type) {
            case TransactionsFiguresType.CardSales:
                return S.transactionsFiguresType
                    .cardSalesCalendarListButtonsLabel;
            case TransactionsFiguresType.CashSales:
                return S.transactionsFiguresType
                    .cashSalesCalendarListButtonsLabel;
            case TransactionsFiguresType.DispensingSales:
                return S.transactionsFiguresType
                    .dispensingSalesCalendarListButtonsLabel;
            case TransactionsFiguresType.ExpectedCardReceipt:
                return S.transactionsFiguresType
                    .expectedCardReceiptCalendarListButtonsLabel;
            case TransactionsFiguresType.ExpectedDispensingSalesReceipt:
                return S.transactionsFiguresType
                    .expectedDispensingSalesReceiptCalendarListButtonsLabel;
            case TransactionsFiguresType.CardPurchases:
                return S.transactionsFiguresType
                    .cardPurchasesCalendarListButtonsLabel;
            case TransactionsFiguresType.CashPurchases:
                return S.transactionsFiguresType
                    .cashPurchasesCalendarListButtonsLabel;
            case TransactionsFiguresType.ElectronicTaxInvoicePurchases:
                return S.transactionsFiguresType
                    .electronicTaxInvoicePurchasesCalendarListButtonsLabel;
            default:
                throw new Error("Invalid TransactionsFiguresType");
        }
    },
};

export default TransactionsFiguresType;
