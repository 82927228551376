import SizedBox from "presentation/components/common/sized_box";
import ProfitReportYearlyTableRowHeaderCell
    from "presentation/pages/profit_report/view/profit/yearly_table/components/profit_report_yearly_table/components/profit_report_yearly_table_row_header_cell";
import {
    ProfitReportYearlyTableContext
} from "presentation/pages/profit_report/view/profit/yearly_table/components/profit_report_yearly_table/profit_report_yearly_table";
import Palette from "presentation/theme/palette";
import {memo, useContext, useMemo} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div<{ $minWidthInPx: number }>`
    min-width: ${(props) => `${props.$minWidthInPx}px`};
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

const ProfitReportYearlyTableRowHeaders = () => {
    const context = useContext(ProfitReportYearlyTableContext);

    const data = useMemo(() => context.headerData, [context.headerData]);

    const topGapInPx = `${
        context.constants.monthHeaderHeightInPx +
        context.constants.monthHeaderToTableGapInPx
    }px`;

    const backgroundColor = (index: number) =>
        index % 2 === 1
            ? context.constants.alternatingBackgroundColor
            : Palette.white100;

    return (
        <LayoutContainer $minWidthInPx={context.constants.rowHeaderWidthInPx}>
            <SizedBox width={"100%"} height={topGapInPx}/>
            {data.map((d, index) => (
                <ProfitReportYearlyTableRowHeaderCell
                    key={d.label}
                    widthInPx={context.constants.rowHeaderWidthInPx}
                    heightInPx={d.heightInPx}
                    topMarginInPx={d.topMarginInPx}
                    bottomMarginInPx={d.bottomMarginInPx}
                    topRounded={d.topRounded}
                    bottomRounded={d.bottomRounded}
                    label={d.label}
                    color={d.color}
                    backgroundColor={backgroundColor(index)}
                    dataBold={d.dataBold}
                />
            ))}
        </LayoutContainer>
    );
};

export default memo(ProfitReportYearlyTableRowHeaders);
