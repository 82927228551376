import {animated} from "@react-spring/web";
import FilledButton from "presentation/components/button/filled_button";
import OutlinedChip from "presentation/components/chip/outlined_chip";
import SizedBox from "presentation/components/common/sized_box";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {useRef} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
`;

const TitleContainer = styled.h6`
    ${Fonts.body2};
`;

const DescriptionLayoutContainer = styled.div`
    min-height: 2.5rem;
    height: max-content;
`;

const DescriptionContainer = styled.p`
    ${Fonts.detail2Medium};
    color: ${Palette.gray500};
    white-space: pre-wrap;
`;

const PositionContainer = styled.div.attrs<{ $contentMaxHeight: string }>(
    (props) => ({
        style: {
            minHeight: `max(52px, ${props.$contentMaxHeight})`,
        },
    })
)`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    transition: min-height 0.3s ease-in-out;
`;

const SyncLayoutContainer = styled.div.attrs<{
    $visible: boolean;
    $wrap: boolean;
    $gap: string;
}>((props) => ({
    style: {
        opacity: props.$visible ? 1 : 0,
        pointerEvents: props.$visible ? "auto" : "none",
        flexDirection: props.$wrap ? "column" : "row",
        alignItems: props.$wrap ? "flex-end" : "center",
        gap: props.$gap,
    },
}))`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 0.3s ease-in-out, gap 0.3s ease-in-out;
`;

const SyncedLayoutContainer = styled.div.attrs<{
    $wrap: boolean;
    $gap: string;
}>((props) => ({
    style: {
        width: props.$wrap ? "100%" : "auto",
        gap: props.$gap,
    },
}))`
    flex: 1;
    min-width: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const SyncedDescriptionContainer = styled(animated.p)`
    min-width: 0px;
    flex: 1;
    ${Fonts.detail2Medium};
    word-break: keep-all;
`;

const UnsyncedDescriptionContainer = styled.p`
    flex: 1;
    ${Fonts.detail1};
    color: ${Palette.red500};
    word-break: keep-all;
`;

const ExternalSyncsContent = ({
                                  title,
                                  description,
                                  synced,
                                  unsyncedDescription,
                                  syncedDescription,
                                  onClick,
                              }: {
    title: string;
    description: string;
    synced: boolean;
    unsyncedDescription: string;
    syncedDescription: string;
    onClick: () => void;
}) => {
    const syncedRef = useRef<HTMLDivElement>(null);
    const unsyncedRef = useRef<HTMLDivElement>(null);

    const {props} = useFadeInTransition(syncedDescription);

    const {height: syncedHeight, widthInPx} = useResizeObserver(
        syncedRef,
        [],
        0
    );
    const {height: unsyncedHeight} = useResizeObserver(unsyncedRef, []);

    const contentMaxHeight = () =>
        synced
            ? `calc(${syncedHeight} + 12px)`
            : `calc(${unsyncedHeight} + 12px)`;
    const wrap = widthInPx < 250;

    return (
        <LayoutContainer>
            <TitleContainer>{title}</TitleContainer>
            <SizedBox height={"8px"}/>
            <DescriptionLayoutContainer>
                <DescriptionContainer>{description}</DescriptionContainer>
            </DescriptionLayoutContainer>
            <PositionContainer $contentMaxHeight={contentMaxHeight()}>
                <SyncLayoutContainer
                    ref={syncedRef}
                    $visible={synced}
                    $wrap={wrap}
                    $gap={"8px"}
                >
                    <SyncedLayoutContainer $wrap={wrap} $gap={"8px"}>
                        <OutlinedChip
                            label={S.externalSyncsContent.syncedChipLabel}
                            color={Palette.primary500}
                            backgroundColor={Palette.primary100}
                            borderColor={Palette.primary300}
                        />
                        <SyncedDescriptionContainer style={props}>
                            {syncedDescription}
                        </SyncedDescriptionContainer>
                    </SyncedLayoutContainer>
                    <FilledButton
                        type={"gray"}
                        sizeType={"medium"}
                        label={S.externalSyncsContent.syncedButtonLabel}
                        onClick={onClick}
                    />
                </SyncLayoutContainer>
                <SyncLayoutContainer
                    ref={unsyncedRef}
                    $visible={!synced}
                    $wrap={false}
                    $gap={"12px"}
                >
                    <UnsyncedDescriptionContainer>
                        {unsyncedDescription}
                    </UnsyncedDescriptionContainer>
                    <FilledButton
                        type={"black"}
                        sizeType={"medium"}
                        label={S.externalSyncsContent.unsyncedButtonLabel}
                        onClick={onClick}
                    />
                </SyncLayoutContainer>
            </PositionContainer>
        </LayoutContainer>
    );
};

export default ExternalSyncsContent;
