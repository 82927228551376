import NumberHelper from "config/helper/number_helper";
import CardSalesFiguresDetail from "domain/model/tax/card_sales/card_sales_figures_detail";
import SizedBox from "presentation/components/common/sized_box";
import DialogDateHeader from "presentation/components/dialog/common/dialog_date_header";
import DialogList from "presentation/components/dialog/common/dialog_list";
import DialogStatisticRow from "presentation/components/dialog/common/dialog_statistic_row";
import Dialog from "presentation/components/dialog/dialog";
import SVGAssets from "presentation/theme/assets";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {useMemo} from "react";

const CardSalesDetailDialog = ({
                                   iconColor,
                                   iconRimColor,
                                   title,
                                   date,
                                   data,
                               }: {
    iconColor?: string;
    iconRimColor?: string;
    title: string;
    date: DateTime;
    data: CardSalesFiguresDetail[];
}) => {
    const titles = useMemo(
        () => [
            S.cardSalesDetailDialog.creditCardCompanyLabel,
            S.cardSalesDetailDialog.transactionDateTimeLabel,
            S.cardSalesDetailDialog.amountLabel,
        ],
        []
    );

    const {count, total, listData} = useMemo(() => {
        const count = data.length;
        const total = -data.reduce((acc, cur) => acc + cur.amount, 0);
        const listData = data.map((d) => [
            d.label,
            d.date.format(S.cardSalesDetailDialog.dateTimeFormat),
            NumberHelper.toStringWithDividers(-d.amount),
        ]);

        return {count, total, listData};
    }, [data]);

    return (
        <Dialog
            iconAsset={SVGAssets.CreditCard}
            iconColor={iconColor}
            iconRimColor={iconRimColor}
            title={title}
        >
            <DialogDateHeader date={date}/>
            <SizedBox height="8px"/>
            <DialogStatisticRow count={count} total={total}/>
            <SizedBox height={"24px"}/>
            <DialogList autoSize={true} titles={titles} data={listData}/>
        </Dialog>
    );
};

export default CardSalesDetailDialog;
