import {animated, useSpring} from "@react-spring/web";
import UserRepository from "data/repository/user_repository";
import FilledButton from "presentation/components/button/filled_button";
import SizedBox from "presentation/components/common/sized_box";
import {TextField} from "presentation/components/text_field/text_field";
import {
    LoginCertCodeFocusRequest,
    LoginRequestCertCode,
} from "presentation/states/login/login_listenable_state";
import loginState, {
    loginListenableState,
} from "presentation/states/login/login_state";
import S from "presentation/theme/s";
import phoneNumberFormatter from "presentation/utils/formatter/phone_number_formatter";
import {useListenableState} from "presentation/utils/hooks/use_listenable_state";
import useRepository from "presentation/utils/hooks/use_repository";
import {useSetListenableState} from "presentation/utils/hooks/use_set_listenable_state";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import {decimalValidator} from "presentation/utils/input_validator/decimal_validator";
import PointerEventsType from "presentation/utils/types/pointer_events_type";
import {useState} from "react";
import {useSetRecoilState} from "recoil";
import styled from "styled-components";

const maxLength = 11;

const LayoutContainer = styled(animated.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`;

const LoginPhoneNumberView = () => {
    const repository = useRepository(UserRepository);
    const showNotificationDialog = useShowNotificationDialog();
    const setListenableState = useSetListenableState(loginListenableState);
    const setLoading = useSetLoading();

    const setState = useSetRecoilState(loginState);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [focused, setFocused] = useState(true);

    const [props, api] = useSpring(() => ({
        opacity: 1,
        pointerEvents: "auto" as PointerEventsType,
    }));

    const enabled = phoneNumber.length === maxLength;

    const onClick = (showSuccessDialog: boolean) => () =>
        repository({
            handler: async (repository) => {
                if (phoneNumber.length !== maxLength) return;

                setLoading(true);

                await repository.requestCertCode({
                    phoneNumber,
                });

                if (showSuccessDialog) {
                    showNotificationDialog({
                        title: S.loginPage.resendDialogTitle,
                        message: S.loginPage.resendDialogMessage(
                            phoneNumberFormatter(phoneNumber)
                        ),
                        hasNegativeButton: false,
                        onDismissed: () =>
                            setListenableState(new LoginCertCodeFocusRequest()),
                    });
                }

                setFocused(false);

                setState((prev) => ({
                    ...prev,
                    certCodeRequested: true,
                    phoneNumber,
                }));

                api.set({
                    opacity: 0,
                    pointerEvents: "none" as PointerEventsType,
                });
            },
            onFinally: () => setLoading(false),
        });

    useListenableState(loginListenableState, (state) => {
        if (state instanceof LoginRequestCertCode) {
            onClick(true)();
        }
    });

    return (
        <LayoutContainer style={props}>
            <TextField
                value={phoneNumber}
                onValueChange={setPhoneNumber}
                focused={focused}
                onFocusChange={setFocused}
                placeholder={S.loginPage.phoneNumberInputPlaceholder}
                maxLength={maxLength}
                inputValidator={decimalValidator}
                onEnterPress={onClick(false)}
            />
            <SizedBox height={"32px"}/>
            <FilledButton
                type={"gray"}
                enabled={enabled}
                width={"100%"}
                label={S.loginPage.loginButtonRequestCertCodeLabel}
                onClick={onClick(false)}
            />
        </LayoutContainer>
    );
};

export default LoginPhoneNumberView;
