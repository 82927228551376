const componentTheme = {
    lnbMinWidth: "64px",
    lnbMaxWidth: "190px",
    tnbHeight: "4.625rem",
    tabBarHeight: "2.4375rem",
    dashboardHeaderHeight: "5.3125rem",
    paginationHeight: "2rem",
    taxTimelineHeaderHeight: "3.75rem",
};

export default componentTheme;
