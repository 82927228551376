import SyncInfo from "domain/model/tax/external_syncs/sync_info";
import {ExternalSyncsListenableState} from "presentation/states/external_syncs/external_syncs_listenable_state";
import {optional} from "presentation/utils/types/optional";
import {atom} from "recoil";
import {Subject} from "rxjs";

type ExternalSyncsState = {
    homeTax: optional<SyncInfo>;
    crefia: optional<SyncInfo>;
    hira: optional<SyncInfo>;
    insurance: optional<SyncInfo>;
};

export const externalSyncsListenableState =
    new Subject<ExternalSyncsListenableState>();

const externalSyncsState = atom<ExternalSyncsState>({
    key: "externalSyncsState",
    default: {
        homeTax: undefined,
        crefia: undefined,
        hira: undefined,
        insurance: undefined,
    },
});

export default externalSyncsState;
