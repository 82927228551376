import {animated, useSpring} from "@react-spring/web";
import DateFilterPeriodDropdown
    from "presentation/components/button/date_filter_period_button/components/date_filter_period_calendar";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import {useLayoutEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    position: relative;
    overflow: visible;
`;

const ButtonContainer = styled(animated.button)`
    min-height: 2.5rem;
    padding: 8px 16px;
    background-color: ${Palette.none};
    border-radius: 12px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;
    transition: width 0.3s ease-in-out, gap 0.3s ease-in-out;

    @media (max-width: 768px) {
        gap: 8px;
    }
`;

const LabelContainer = styled(animated.p)`
    width: 86px;
    ${Fonts.detail2Medium};
    text-align: center;
    transition: width 0.3s ease-in-out;

    @media (max-width: 768px) {
        width: 66px;
    }
`;

const DateFilterPeriodButton = ({
                                    startDate,
                                    endDate,
                                    onDateSelected,
                                }: {
    startDate: DateTime;
    endDate: DateTime;
    onDateSelected: (startDate: DateTime, endDate: DateTime) => void;
}) => {
    const isMobile = useMobileQuery();

    const [hover, setHover] = useState(false);
    const [selecting, setSelecting] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const onClick = (e: MouseEvent) => {
        if (buttonRef.current && buttonRef.current.contains(e.target as Node)) {
            setSelecting(true);
            return;
        }

        if (
            dropdownRef.current &&
            dropdownRef.current.contains(e.target as Node)
        ) {
            return;
        }

        setSelecting(false);
    };

    useLayoutEffect(() => {
        window.addEventListener("mousedown", onClick);

        return () => window.removeEventListener("mousedown", onClick);
    }, []);

    const dateFormat = isMobile
        ? S.dateFilterPeriodButton.datePeriodDateFormatShort
        : S.dateFilterPeriodButton.datePeriodDateFormat;

    const {startDateLabel, endDateLabel} = useMemo(
        () => ({
            startDateLabel: `${startDate.format(dateFormat)}`,
            endDateLabel: `${endDate.format(dateFormat)}`,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [startDate.key, endDate.key, isMobile]
    );

    const buttonProps = useSpring({
        boxShadow: `0 0 0 1px ${
            hover
                ? Palette.primary500
                : selecting
                    ? Palette.primary500
                    : Palette.gray200
        } inset`,
    });
    const labelProps = useSpring({
        color: selecting ? Palette.gray400 : Palette.gray800,
    });

    const onMouseEnter = () => setHover(true);
    const onMouseLeave = () => setHover(false);

    const _onDateSelected = (startDate: DateTime, endDate: DateTime) => {
        onDateSelected(startDate, endDate);
        setSelecting(false);
    };

    return (
        <LayoutContainer>
            <ButtonContainer
                ref={buttonRef}
                style={buttonProps}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <LabelContainer style={labelProps}>
                    {startDateLabel}
                </LabelContainer>
                <SVG
                    asset={SVGAssets.ArrowRight}
                    width={"24px"}
                    height={"24px"}
                    color={Palette.gray800}
                />
                <LabelContainer style={labelProps}>
                    {endDateLabel}
                </LabelContainer>
                <SVG
                    asset={SVGAssets.Calendar}
                    width={"24px"}
                    height={"24px"}
                    color={Palette.gray800}
                />
            </ButtonContainer>
            <DateFilterPeriodDropdown
                ref={dropdownRef}
                visible={selecting}
                calendarStartDate={new DateTime(2021, 1, 1).min}
                startDate={startDate}
                endDate={endDate}
                onDateSelected={_onDateSelected}
            />
        </LayoutContainer>
    );
};

export default DateFilterPeriodButton;
