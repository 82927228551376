import {ProfitReportFiguresDetailTypeMap} from "domain/model/tax/profit_report/profit_report_figures_detail_type";
import ProfitReportFiguresType, {
    ProfitReportFiguresTypeMap,
} from "domain/model/tax/profit_report/profit_report_figures_type";
import DonutChart from "presentation/components/charts/donut_chart/donut_chart";
import DonutChartLegends from "presentation/components/charts/donut_chart/donut_chart_legends";
import ProfitReportStatusBoardDonutChartColumn
    from "presentation/pages/profit_report/components/profit/status_board/profit_report_profit_status_board_donut_chart_column";
import profitReportProfitStatusBoardSelector
    from "presentation/states/profit_report/selector/profit/status_board/profit_report_status_board_selector";
import S from "presentation/theme/s";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";

const ProfitReportProfitStatusBoardSalesDonutChartView = () => {
    const {data} = useRecoilValue(profitReportProfitStatusBoardSelector);

    const {salesData, salesTotal} = useMemo(() => {
        const salesFiguresTypes =
            ProfitReportFiguresTypeMap.profitReportFiguresDetailTypes(
                ProfitReportFiguresType.Sales
            );
        const salesData = salesFiguresTypes.map((t) => ({
            label: ProfitReportFiguresDetailTypeMap.shortLabel(t),
            value: 0,
            color: ProfitReportFiguresDetailTypeMap.color(t),
        }));
        data.forEach((d) => {
            salesFiguresTypes.forEach((_, i) => {
                salesData[i].value += d.sales[i + 1];
            });
        });
        const salesTotal = data.reduce((acc, cur) => acc + cur.sales[0], 0);

        return {
            salesData,
            salesTotal: data.length ? salesTotal : undefined,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <ProfitReportStatusBoardDonutChartColumn>
            <DonutChart
                donutSizeInPx={280}
                data={salesData}
                title={
                    S.profitReportPage.profit.statusBoard.donutChart
                        .salesChartTitle
                }
                value={salesTotal}
            />
            <DonutChartLegends data={salesData}/>
        </ProfitReportStatusBoardDonutChartColumn>
    );
};

export default ProfitReportProfitStatusBoardSalesDonutChartView;
