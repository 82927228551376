import SizedBox from "presentation/components/common/sized_box";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const height = "2rem";

const LayoutContainer = styled.button`
    width: max-content;
    height: ${height};
    padding: 0 12px;
    border: none;
    border-radius: 12px;
    background-color: ${Palette.white100};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: ${Palette.gray100};
    }
`;

const LabelContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.gray600};
`;

const DashboardCardMoreButton = ({onClick}: { onClick: () => void }) => {
    return (
        <>
            <SizedBox height={height}/>
            <LayoutContainer onClick={onClick}>
                <LabelContainer>
                    {S.dashboardCardMoreButton.label}
                </LabelContainer>
                <SVG
                    asset={SVGAssets.Next}
                    width={"20px"}
                    height={"20px"}
                    color={Palette.gray800}
                />
            </LayoutContainer>
        </>
    );
};

export default DashboardCardMoreButton;
