import {animated, useSpring, useTransition} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import {useEffect} from "react";
import styled from "styled-components";

const LayoutContainer = styled(animated.div)`
    width: 100%;
    height: 1.5625rem;
    margin-top: 8px;
    position: relative;
`;

const RowContainer = styled(animated.div)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    left: 0;
`;

const TitleContainer = styled.p.attrs<{ $titleColor: string }>((props) => ({
    style: {
        color: props.$titleColor,
    },
}))`
    ${Fonts.detail3};
    transition: color 0.3s ease-in-out;
`;

const ValueLayoutContainer = styled.div`
    min-width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const IconContainer = styled.div<{ $positive: boolean }>`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: ${(props) => `rotate(${props.$positive ? "0deg" : "180deg"})`};
    transition: transform 0.3s ease-in-out;
`;

const ValueContainer = styled(animated.p).attrs<{ $color: string }>(
    (props) => ({
        style: {
            color: props.$color,
        },
    })
)`
    ${Fonts.body1};
    transition: color 0.3s ease-in-out;
`;

const EmptyValueContainer = styled(animated.p).attrs<{ $color: string }>(
    (props) => ({
        style: {
            color: props.$color,
        },
    })
)`
    ${Fonts.detail3};
    position: absolute;
    top: 0;
    left: 0;
    transition: color 0.3s ease-in-out;
`;

const DashboardFiguresComparison = ({
                                        positiveColor = Palette.primary500,
                                        negativeColor = Palette.red400,
                                        titleColor,
                                        previousValue,
                                        value,
                                    }: {
    positiveColor?: string;
    negativeColor?: string;
    titleColor: string;
    previousValue?: number;
    value?: number;
}) => {
    const showEmpty = value === undefined;
    const hide = !showEmpty && previousValue === undefined;

    const difference =
        value !== undefined && previousValue !== undefined
            ? value - previousValue
            : undefined;
    const positive = difference !== undefined ? difference > 0 : true;
    const label =
        difference !== undefined
            ? NumberHelper.toStringWithDividers(Math.abs(difference))
            : "";
    const color = positive ? positiveColor : negativeColor;

    const {props: valueProps} = useFadeInTransition(label);

    const [layoutProps, layoutAPI] = useSpring(() => ({
        opacity: hide ? 0 : 1,
    }));
    const transitions = useTransition(showEmpty, {
        from: {
            opacity: 0,
        },
        enter: {
            opacity: hide ? 0 : 1,
        },
        leave: {
            opacity: 0,
        },
    });

    useEffect(() => {
        layoutAPI.start({
            opacity: hide ? 0 : 1,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hide]);

    return (
        <LayoutContainer style={layoutProps}>
            {transitions((props, showEmpty) =>
                showEmpty ? (
                    <EmptyValueContainer style={props} $color={titleColor}>
                        {S.dashboardPage.dispensing.emptyComparisonLabel}
                    </EmptyValueContainer>
                ) : (
                    <RowContainer style={props}>
                        <TitleContainer $titleColor={titleColor}>
                            {S.dashboardPage.dispensing.comparisonTitle}
                        </TitleContainer>
                        <ValueLayoutContainer>
                            <IconContainer $positive={positive}>
                                <SVG
                                    asset={SVGAssets.ArrowUp}
                                    width={"24px"}
                                    height={"24px"}
                                    color={color}
                                />
                            </IconContainer>
                            <ValueContainer style={valueProps} $color={color}>
                                {label}
                            </ValueContainer>
                        </ValueLayoutContainer>
                    </RowContainer>
                )
            )}
        </LayoutContainer>
    );
};

export default DashboardFiguresComparison;
