import TransactionsFigures from "domain/model/tax/transactions/transactions_figures";
import SizedBox from "presentation/components/common/sized_box";
import TransactionsListTileDateHeader
    from "presentation/components/list/transactions_list/components/transactions_list_tile_date_header";
import TransactionsListTile from "presentation/components/list/transactions_list/components/transactions_list_tile";
import DateTime from "presentation/utils/extension/date_extension";
import styled from "styled-components";
import TransactionsFiguresType from "domain/model/tax/transactions/transactions_figures_type";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";

const LayoutContainer = styled.div.attrs<{ $topMarginInPx: number }>(
    (props) => ({
        style: {
            marginTop: `${props.$topMarginInPx}px`,
        },
    })
)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
`;

const CollectingLayoutContainer = styled.div`
    width: 100%;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const CollectingLabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail3};
    color: ${Palette.gray400};
`;

const TransactionsListTileGroup = ({
                                       topMarginInPx,
                                       date,
                                       figures,
                                       collecting,
                                       onTileClick,
                                   }: {
    topMarginInPx: number;
    date: DateTime;
    figures: TransactionsFigures[];
    collecting: boolean;
    onTileClick: (date: DateTime, type: TransactionsFiguresType) => void;
}) => {
    const onClick = (date: DateTime, type: TransactionsFiguresType) => () =>
        onTileClick(date, type);

    const gap = collecting ? "2px" : "4px";

    return (
        <LayoutContainer $topMarginInPx={topMarginInPx}>
            <TransactionsListTileDateHeader date={date}/>
            <SizedBox height={gap}/>
            {collecting ? (
                <CollectingLayoutContainer>
                    <CollectingLabelContainer>
                        {S.transactionsList.collectingLabel}
                    </CollectingLabelContainer>
                </CollectingLayoutContainer>
            ) : (
                figures.map((f) => (
                    <TransactionsListTile
                        key={f.type}
                        type={f.type}
                        value={f.value}
                        onClick={onClick(date, f.type)}
                    />
                ))
            )}
        </LayoutContainer>
    );
};

export default TransactionsListTileGroup;
