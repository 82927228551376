import taxTimelineState from "presentation/states/tax_timeline/tax_timeline_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const taxTimelineSelector = selector({
    key: "taxTimelineSelector",
    get: ({get}) => {
        const state = get(taxTimelineState);

        return {
            selectedYear: DateTime.fromKey(state.selectedYearKey),
            data: state.data,
        };
    },
});

export default taxTimelineSelector;
