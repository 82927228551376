import {animated, useSpring} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import ChartTooltipLabel from "presentation/components/charts/common/chart_tooltip_label";
import {LineChartContext} from "presentation/components/charts/line_chart/line_chart";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import Debouncer from "presentation/utils/debouncer/debouncer";
import {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";

const LayoutContainer = styled(animated.ul)`
    width: max-content;
    padding: 8px;
    border-radius: 12px;
    background-color: ${Palette.black75};
    box-shadow: 0 0 0 1px ${Palette.white20} inset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    z-index: 5;
`;

const LineChartTooltip = () => {
    const context = useContext(LineChartContext);

    const debouncerRef = useRef(new Debouncer());
    const [data, setData] = useState<Array<Record<string, string>>>([]);

    const [props, api] = useSpring(() => ({
        opacity: context.hoveredXIndex !== undefined ? 1 : 0,
        top: `${context.constants.innerHeightInPx / 2}px`,
        left: "0px",
        transform: "translate(0%, -50%)",
    }));

    useEffect(() => {
        debouncerRef.current.runImmediately(() => {
            api.set({
                opacity: 0,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.data]);

    useEffect(() => {
        if (context.hoveredXIndex === undefined) {
            debouncerRef.current.run(
                () =>
                    api.start({
                        opacity: 0,
                    }),
                500
            );
            return;
        }

        debouncerRef.current.cancel();
        const isIndexLeft =
            context.hoveredXIndex + 1 < context.constants.xRangeCount / 2;
        const xPos = `${
            context.functions.getXPos(context.hoveredXIndex) +
            (isIndexLeft ? 8 : -8)
        }px`;
        const translateX = isIndexLeft ? "0%" : "-100%";

        api.start({
            opacity: 1,
            left: xPos,
            transform: `translate(${translateX}, -50%)`,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.data, context.constantsKey, context.hoveredXIndex, data]);

    useEffect(() => {
        if (context.hoveredXIndex === undefined) return;

        const data = context.data
            .map((d) => ({
                label: d.label,
                color: d.color,
                dot:
                    context.hoveredXIndex !== undefined
                        ? d.dots.at(context.hoveredXIndex)
                        : undefined,
            }))
            .filter((d) => d.dot !== undefined)
            .map((d) => ({
                label: S.lineChart.tooltipLabel(
                    d.label.replaceAll(" ", ""),
                    NumberHelper.toChartAbbreviatedString(d.dot!.y)
                ),
                color: d.color,
            }));

        setData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.data, context.constantsKey, context.hoveredXIndex]);

    return (
        <LayoutContainer style={props}>
            {data.map((d) => (
                <ChartTooltipLabel
                    key={d.label}
                    color={d.color}
                    label={d.label}
                />
            ))}
        </LayoutContainer>
    );
};

export default LineChartTooltip;
