import ProfitReportYearlyTableCell
    from "presentation/pages/profit_report/view/profit/yearly_table/components/profit_report_yearly_table/components/profit_report_yearly_table_cell";
import ProfitReportYearlyTableMonthCell
    from "presentation/pages/profit_report/view/profit/yearly_table/components/profit_report_yearly_table/components/profit_report_yearly_table_month_cell";
import ProfitReportYearlyTableRowHeaders
    from "presentation/pages/profit_report/view/profit/yearly_table/components/profit_report_yearly_table/components/profit_report_yearly_table_row_headers";
import ProfitReportYearlyTableData, {
    ProfitReportYearlyTableCellData,
    ProfitReportYearlyTableRowHeaderData,
} from "presentation/pages/profit_report/view/profit/yearly_table/components/profit_report_yearly_table/profit_report_yearly_table_model";
import Palette from "presentation/theme/palette";
import DateTime from "presentation/utils/extension/date_extension";
import useAssert from "presentation/utils/hooks/use_assert";
import {optional} from "presentation/utils/types/optional";
import {createContext, useMemo, useRef} from "react";
import styled from "styled-components";

const monthHeaderHeightInPx = 44;
const monthHeaderToTableGapInPx = 8;
const rowHeaderWidthInPx = 84;
const cellMinWidthInPx = 116;
const cellVerticalPaddingInPx = 16;
const cellHorizontalPaddingInPx = 8;
const bottomMarginInPx = 20;
const alternatingBackgroundColor = Palette.gray50;

export const ProfitReportYearlyTableContext = createContext<{
    constants: {
        heightInPx: number;
        monthHeaderHeightInPx: number;
        monthHeaderToTableGapInPx: number;
        rowHeaderWidthInPx: number;
        cellMinWidthInPx: number;
        cellVerticalPaddingInPx: number;
        cellHorizontalPaddingInPx: number;
        alternatingBackgroundColor: string;
    };
    headerData: ProfitReportYearlyTableRowHeaderData[];
}>({
    constants: {
        heightInPx: 556,
        monthHeaderHeightInPx,
        monthHeaderToTableGapInPx,
        rowHeaderWidthInPx,
        cellMinWidthInPx,
        cellVerticalPaddingInPx,
        cellHorizontalPaddingInPx,
        alternatingBackgroundColor,
    },
    headerData: [],
});

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

const RelativeContainer = styled.div<{ $heightInPx: number }>`
    height: ${(props) => `${props.$heightInPx}px`};
    flex-grow: 1;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-color: ${Palette.none} ${Palette.none};
    position: relative;
    transition: scrollbar-color 0.3s ease-in-out;

    &:hover {
        scrollbar-color: ${Palette.gray300} ${Palette.none};
    }
`;

const TableContainer = styled.div.attrs<{
    $heightInPx: number;
    $gridTemplateRows: string;
    $gridTemplateColumns: string;
}>((props) => ({
    style: {
        gridTemplateColumns: props.$gridTemplateColumns,
    },
}))`
    display: grid;
    grid-template-rows: ${(props) => props.$gridTemplateRows};
    position: absolute;
    top: 0;
    left: 0;
    transition: grid-template-columns 0.3s ease-in-out;
`;

const ProfitReportYearlyTable = ({
                                     headerData,
                                     data,
                                 }: {
    headerData: ProfitReportYearlyTableRowHeaderData[];
    data: ProfitReportYearlyTableData[];
}) => {
    useAssert(
        data.every((d) => d.values.length === headerData.length),
        "Data length is not matched with header length"
    );

    const ref = useRef<HTMLDivElement>(null);

    const {months, constants, gridTemplateRows} = useMemo(() => {
        const months = Array.from(
            {length: DateTime.monthsInYear},
            (_, index) => index + 1
        );

        const heightInPx =
            monthHeaderHeightInPx +
            monthHeaderToTableGapInPx +
            headerData.reduce(
                (acc, cur) =>
                    acc +
                    cur.heightInPx +
                    cur.topMarginInPx +
                    cur.bottomMarginInPx,
                0
            ) +
            bottomMarginInPx;

        const constants = {
            heightInPx,
            monthHeaderHeightInPx,
            monthHeaderToTableGapInPx,
            rowHeaderWidthInPx,
            cellMinWidthInPx,
            cellVerticalPaddingInPx,
            cellHorizontalPaddingInPx,
            alternatingBackgroundColor,
        };

        const gridTemplateRows =
            `${monthHeaderHeightInPx + monthHeaderToTableGapInPx}px ` +
            headerData
                .map(
                    (d) =>
                        `${
                            d.heightInPx + d.topMarginInPx + d.bottomMarginInPx
                        }px`
                )
                .join(" ");

        return {
            months,
            constants,
            gridTemplateRows,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerData]);

    const {gridTemplateColumns} = useMemo(() => {
        const availableWidthInPx = ref.current?.offsetWidth ?? 0;
        const cellWidthInPx = availableWidthInPx / DateTime.monthsInYear;

        const gridTemplateColumns = `repeat(${DateTime.monthsInYear}, max(${cellMinWidthInPx}px, ${cellWidthInPx}px))`;
        return {
            gridTemplateColumns,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current?.offsetWidth]);

    const tableData = useMemo(() => {
        const tableData: ProfitReportYearlyTableCellData[][] = [];

        headerData.forEach((h, rowIndex) => {
            const values: optional<number>[] = Array.from(
                {length: DateTime.monthsInYear},
                () => undefined
            );
            for (let month = 1; month <= DateTime.monthsInYear; month++) {
                const monthData = data.find((d) => d.yearMonth.month === month);
                if (monthData) {
                    values[month - 1] = monthData.values[rowIndex];
                }
            }

            const row = values.map((v, index) => ({
                value: v,
                backgroundColor:
                    rowIndex % 2 === 1
                        ? alternatingBackgroundColor
                        : Palette.white100,
                roundRight: index === DateTime.monthsInYear - 1,
                hasRightBorder: index < DateTime.monthsInYear - 1,
                topMarginInPx: h.topMarginInPx,
                bottomMarginInPx: h.bottomMarginInPx,
                bold: h.dataBold,
            }));

            tableData.push(row);
        });

        return tableData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <ProfitReportYearlyTableContext.Provider
            value={{
                constants,
                headerData,
            }}
        >
            <RowContainer>
                <ProfitReportYearlyTableRowHeaders/>
                <RelativeContainer ref={ref} $heightInPx={constants.heightInPx}>
                    <TableContainer
                        $heightInPx={constants.heightInPx}
                        $gridTemplateRows={gridTemplateRows}
                        $gridTemplateColumns={gridTemplateColumns}
                    >
                        {months.map((m) => (
                            <ProfitReportYearlyTableMonthCell
                                key={m}
                                month={m}
                            />
                        ))}
                        {tableData.map((row, rowIndex) =>
                            row.map((d, columnIndex) => (
                                <ProfitReportYearlyTableCell
                                    key={`${rowIndex}-${columnIndex}`}
                                    value={d.value}
                                    backgroundColor={d.backgroundColor}
                                    roundRight={d.roundRight}
                                    hasRightBorder={d.hasRightBorder}
                                    topMarginInPx={d.topMarginInPx}
                                    bottomMarginInPx={d.bottomMarginInPx}
                                    bold={d.bold}
                                />
                            ))
                        )}
                    </TableContainer>
                </RelativeContainer>
            </RowContainer>
        </ProfitReportYearlyTableContext.Provider>
    );
};

export default ProfitReportYearlyTable;
