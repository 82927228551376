import {animated, useSpring} from "@react-spring/web";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import styled from "styled-components";

const LayoutContainer = styled(animated.button)`
    min-width: max-content;
    min-height: 2.5rem;
    padding: 8px 16px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    ${Fonts.detail2};
    text-align: center;
    transition: padding 0.3s ease-in-out;
`;

const OptionButton = ({
                          className,
                          selected,
                          label,
                          onClick,
                      }: {
    className?: string;
    selected: boolean;
    label: string;
    onClick: () => void;
}) => {
    const transitionProps = useSpring({
        backgroundColor: selected ? Palette.black100 : Palette.none,
        boxShadow: `0 0 0 1px ${
            selected ? Palette.black100 : Palette.gray200
        } inset`,
        color: selected ? Palette.white100 : Palette.gray400,
        fontWeight: selected ? FontWeights.bold : FontWeights.medium,
    });

    const fadeInProps = useFadeInTransition(label);

    return (
        <LayoutContainer
            className={className}
            style={{...transitionProps, ...fadeInProps}}
            onClick={onClick}
        >
            {label}
        </LayoutContainer>
    );
};

export default OptionButton;
