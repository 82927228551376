import {animated, useSpring} from "@react-spring/web";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import styled from "styled-components";

const LayoutContainer = styled.button.attrs<{
    $flex: number;
    $height: string;
}>((props) => ({
    style: {
        flex: props.$flex,
        minWidth: "5.125rem",
        height: props.$height,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: ${Palette.none};
    border: none;
    position: relative;
    cursor: pointer;
`;

const LabelContainer = styled(animated.p)<{ $height: string }>`
    width: 100%;
    min-height: ${(props) => props.$height};
    padding: 8px 0;
    ${Fonts.detail3};
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
`;

const IndicatorContainer = styled(animated.div)`
    width: 100%;
    min-height: 2px;
    max-height: 2px;
    position: absolute;
    bottom: 1px;
`;

const TabBarItem = ({
                        flex = 0,
                        selected,
                        label,
                        onClick,
                    }: {
    flex?: number;
    selected: boolean;
    label: string;
    onClick: () => void;
}) => {
    const theme = useThemeContext();
    const {backgroundColor, ...rest} = useSpring({
        color: selected ? Palette.gray800 : Palette.gray400,
        fontWeight: selected ? FontWeights.bold : FontWeights.medium,
        backgroundColor: selected ? Palette.gray800 : Palette.none,
    });

    return (
        <LayoutContainer
            onClick={onClick}
            $flex={flex}
            $height={theme.componentTheme.tabBarHeight}
        >
            <LabelContainer
                style={rest}
                $height={theme.componentTheme.tabBarHeight}
            >
                {label}
            </LabelContainer>
            <IndicatorContainer style={{backgroundColor}}/>
        </LayoutContainer>
    );
};

export default TabBarItem;
