import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: min(100%, 63.625rem);
    height: max-content;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    gap: 24px;

    @media (max-width: 768px) {
        width: min(100%, 31.0625rem);
        flex-direction: column;
    }
`;

const ExternalSyncsRow = ({children}: PropsWithChildren) => {
    return <LayoutContainer>{children}</LayoutContainer>;
};

export default ExternalSyncsRow;
