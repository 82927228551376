import SortType from "domain/model/common/sort_type";
import BillingFigures from "domain/model/tax/billing/billing_figures";
import Pagination from "presentation/components/pagination/pagination";
import TableEmptyLabel from "presentation/components/table/table_empty_label";
import BillingStatusBoardTableHeader
    from "presentation/pages/billing/view/status_board/components/billing_status_board_table/components/billing_status_board_table_header";
import BillingStatusBoardTableRow
    from "presentation/pages/billing/view/status_board/components/billing_status_board_table/components/billing_status_board_table_row";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {createContext, useEffect, useMemo, useState} from "react";
import styled from "styled-components";

const constants = {
    maxVisibleCount: 10,
    emptyListHeightInPx: 138,
    headerCellHeightInPx: 44,
    cellMinWidthInPx: 118.25,
    cellHeightInPx: 52,
    verticalGapInPx: 4,
    listToPaginationGapInPx: 36,
    bottomMarginInPx: 20,
};

export const BillingStatusBoardTableContext = createContext<{
    constants: {
        maxVisibleCount: number;
        emptyListHeightInPx: number;
        headerCellHeightInPx: number;
        cellMinWidthInPx: number;
        cellHeightInPx: number;
        verticalGapInPx: number;
        listToPaginationGapInPx: number;
        bottomMarginInPx: number;
    };
    headerLabels: string[];
    dateSortType: SortType;
    billingAmountSortType: SortType;
    onSortChange: (
        dateSortType: SortType,
        billingAmountSortType: SortType
    ) => void;
}>({
    constants,
    headerLabels: [],
    dateSortType: SortType.None,
    billingAmountSortType: SortType.None,
    onSortChange: () => {
    },
});

const LayoutContainer = styled.div`
    width: 100%;
    position: relative;
    overflow-x: scroll;
    overflow-y: clip;
    scrollbar-color: ${Palette.none} ${Palette.none};
    transition: height 0.3s ease-in-out, scrollbar-color 0.3s ease-in-out;

    &:hover {
        scrollbar-color: ${Palette.gray300} ${Palette.none};
    }
`;

const ColumnContainer = styled.ul.attrs<{
    $minWidthInPx: number;
    $height: string;
    $gapInPx: number;
}>((props) => ({
    style: {
        width: `max(100%, ${props.$minWidthInPx}px)`,
        height: props.$height,
        gap: `${props.$gapInPx}px`,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
        gap 0.3s ease-in-out;
`;

const PaginationPositionContainer = styled.div.attrs<{
    $topOffsetInPx: number;
}>((props) => ({
    style: {
        top: `${props.$topOffsetInPx}px`,
    },
}))`
    position: absolute;
    transition: top 0.3s ease-in-out;
`;

const BillingStatusBoardTable = ({
                                     data,
                                     dateSortType,
                                     billingAmountSortType,
                                     onSortChange,
                                 }: {
    data: BillingFigures[];
    dateSortType: SortType;
    billingAmountSortType: SortType;
    onSortChange: (
        dateSortType: SortType,
        billingAmountSortType: SortType
    ) => void;
}) => {
    const theme = useThemeContext();

    const [page, setPage] = useState(1);

    const headerLabels = useMemo(
        () => [
            S.billingStatusBoardTable.dateHeaderLabel,
            S.billingStatusBoardTable.orderHeaderLabel,
            S.billingStatusBoardTable.amountHeaderLabel,
            S.billingStatusBoardTable.pendingHeaderLabel,
            S.billingStatusBoardTable.taxesHeaderLabel,
            S.billingStatusBoardTable.receiveHeaderLabel,
            S.billingStatusBoardTable.receiveDateHeaderLabel,
            S.billingStatusBoardTable.statusHeaderLabel,
        ],
        []
    );

    const _data = useMemo(() => {
        if (dateSortType === SortType.Ascending) {
            return data.toSorted(
                (a, b) =>
                    b.date.millisecondsSinceEpoch -
                    a.date.millisecondsSinceEpoch
            );
        }

        if (dateSortType === SortType.Descending) {
            return data.toSorted(
                (a, b) =>
                    a.date.millisecondsSinceEpoch -
                    b.date.millisecondsSinceEpoch
            );
        }

        if (billingAmountSortType === SortType.Ascending) {
            return data.toSorted((a, b) => b.billingAmount - a.billingAmount);
        }

        if (billingAmountSortType === SortType.Descending) {
            return data.toSorted((a, b) => a.billingAmount - b.billingAmount);
        }

        return data.toSorted(
            (a, b) =>
                b.date.millisecondsSinceEpoch - a.date.millisecondsSinceEpoch
        );
    }, [data, dateSortType, billingAmountSortType]);

    const {minWidthInPx, height, paginationTopOffsetInPx} = useMemo(() => {
        const minWidthInPx = headerLabels.length * constants.cellMinWidthInPx;

        const visibleCount = Math.min(_data.length, constants.maxVisibleCount);
        const listHeight = visibleCount
            ? visibleCount * constants.cellHeightInPx +
            (visibleCount - 1) * constants.verticalGapInPx
            : constants.emptyListHeightInPx;
        const heightInPx =
            constants.headerCellHeightInPx +
            constants.verticalGapInPx +
            listHeight +
            constants.listToPaginationGapInPx +
            constants.bottomMarginInPx;
        const paginationTopOffsetInPx =
            constants.headerCellHeightInPx +
            constants.verticalGapInPx +
            listHeight +
            constants.listToPaginationGapInPx;

        const height = `calc(${heightInPx}px + ${theme.componentTheme.paginationHeight})`;

        return {
            minWidthInPx,
            height,
            paginationTopOffsetInPx,
        };
    }, [theme, headerLabels, _data]);

    const {pageData, maxPage} = useMemo(() => {
        const pageSize = constants.maxVisibleCount;
        const pageData: Record<number, BillingFigures[]> = {};

        let page = 1;
        while ((page - 1) * pageSize < _data.length) {
            pageData[page] = _data.slice(
                (page - 1) * pageSize,
                page * pageSize
            );
            page++;
        }

        return {
            pageData,
            maxPage: Math.ceil(_data.length / constants.maxVisibleCount),
        };
    }, [_data]);

    useEffect(() => {
        setPage(1);
    }, [data, _data.length]);

    return (
        <BillingStatusBoardTableContext.Provider
            value={{
                constants,
                headerLabels,
                dateSortType,
                billingAmountSortType,
                onSortChange,
            }}
        >
            <LayoutContainer>
                <ColumnContainer
                    $minWidthInPx={minWidthInPx}
                    $height={height}
                    $gapInPx={constants.verticalGapInPx}
                >
                    <BillingStatusBoardTableHeader/>
                    {_data.length ? (
                        pageData[page]?.map((d, index) => (
                            <BillingStatusBoardTableRow
                                key={index}
                                highlight={index % 2 === 1}
                                data={d}
                            />
                        ))
                    ) : (
                        <TableEmptyLabel minWidthInPx={minWidthInPx}/>
                    )}
                    {!!_data.length && (
                        <PaginationPositionContainer
                            $topOffsetInPx={paginationTopOffsetInPx}
                        >
                            <Pagination
                                maxPage={maxPage}
                                page={page}
                                onPageChange={setPage}
                            />
                        </PaginationPositionContainer>
                    )}
                </ColumnContainer>
            </LayoutContainer>
        </BillingStatusBoardTableContext.Provider>
    );
};

export default BillingStatusBoardTable;
