import CardSalesRepository from "data/repository/card_sales_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import CardSalesCreditCardDropdownType from "domain/model/tax/card_sales/card_sales_credit_card_dropdown_type";
import CardSalesDateFilterType, {
    CardSalesDateFilterTypeMap,
} from "domain/model/tax/card_sales/card_sales_date_filter_type";
import Page from "presentation/components/common/page";
import CardSalesStatusBoardView from "presentation/pages/card_sales/view/status_board/card_sales_status_board_view";
import {CardSalesScrollToElement} from "presentation/states/card_sales/card_sales_replay_state";
import cardSalesState, {cardSalesReplayState,} from "presentation/states/card_sales/card_sales_state";
import DateTime from "presentation/utils/extension/date_extension";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import {useSearchParams} from "presentation/utils/hooks/use_search_params";
import {useSetReplayState} from "presentation/utils/hooks/use_set_replay_state";
import {useLayoutEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useSetRecoilState} from "recoil";

const CardSalesPage = () => {
    const {pathname, search} = useLocation();
    const searchParams = useSearchParams();
    const setReplayState = useSetReplayState(cardSalesReplayState);
    const repository = useRepository(CardSalesRepository);

    const setState = useSetRecoilState(cardSalesState);

    const [status, setStatus] = useState(ViewStatusType.Loading);

    useLayoutEffect(() => {
        const element = searchParams("scrollTo");
        if (element) {
            setReplayState(new CardSalesScrollToElement({element}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, search]);

    useLifeCycle({
        onInit: () =>
            repository({
                handler: async (repository) => {
                    const statusBoardDateFilter =
                        CardSalesDateFilterType.ThisMonth;
                    const statusBoardStartDate =
                        CardSalesDateFilterTypeMap.datePeriod(
                            statusBoardDateFilter
                        ).startDate;
                    const statusBoardEndDate =
                        CardSalesDateFilterTypeMap.datePeriod(
                            statusBoardDateFilter
                        ).endDate;
                    const dropdown = CardSalesCreditCardDropdownType.All;

                    const statusBoardData =
                        await repository.getDailyFiguresInRange({
                            startDate: statusBoardStartDate,
                            endDate: statusBoardEndDate,
                        });

                    setState((prev) => ({
                        ...prev,
                        taxStartDateKey: new DateTime(2010, 1, 1).key,
                        statusBoard: {
                            ...prev.statusBoard,
                            selectedDateFilter: statusBoardDateFilter,
                            startDateKey: statusBoardStartDate.key,
                            endDateKey: statusBoardEndDate.key,
                            selectedDropdownType: dropdown,
                            data: statusBoardData,
                        },
                    }));
                },
                onFinally: () => setStatus(ViewStatusType.Loaded),
            }),
    });

    return (
        <Page status={status}>
            <CardSalesStatusBoardView status={status}/>
        </Page>
    );
};

export default CardSalesPage;
