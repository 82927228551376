import {
    DateFilterPeriodCalendarContext
} from "presentation/components/button/date_filter_period_button/components/date_filter_period_calendar";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext} from "react";
import styled from "styled-components";

const LayoutContainer = styled.p<{ $widthInPx: number; $heightInPx: number }>`
    width: ${(props) => `${props.$widthInPx}px`};
    height: ${(props) => `${props.$heightInPx}px`};
    ${Fonts.detail1};
    color: ${Palette.gray500};
    text-align: right;
    background-color: ${Palette.white100};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const DateFilterPeriodCalendarNoticeFooter = () => {
    const context = useContext(DateFilterPeriodCalendarContext);

    return (
        <LayoutContainer
            $widthInPx={context.constants.innerWidthInPx}
            $heightInPx={context.constants.noticeFooterHeightInPx}
        >
            {S.dateFilterPeriodButton.noticeFooterLabel}
        </LayoutContainer>
    );
};

export default DateFilterPeriodCalendarNoticeFooter;
