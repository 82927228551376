import SpacedDivider from "presentation/components/common/spaced_divider";
import ProfitReportDispensingStatusBoardSummaryItem
    from "presentation/pages/profit_report/components/dispensing/status_board/profit_report_dispensing_status_board_summary_item";
import profitReportDispensingStatusBoardSelector
    from "presentation/states/profit_report/selector/dispensing/status_board/profit_report_dispensing_status_board_date_filter_selector";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const height = "4.9375rem";

const LayoutContainer = styled.div`
    margin: 24px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
    transition: gap 0.3s ease-in-out;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;
    }
`;

const NoticeLabelContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.gray500};
    text-align: center;
`;

const ProfitReportDispensingStatusBoardSummaryView = () => {
    const {data} = useRecoilValue(profitReportDispensingStatusBoardSelector);

    const _data = useMemo(() => {
        const dispensingTotal = data.length
            ? data.reduce((acc, cur) => acc + cur.dispensingAmount, 0)
            : undefined;
        const billingCount = data.length
            ? data.reduce((acc, cur) => acc + cur.billingCount, 0)
            : undefined;

        return [
            {
                title: S.profitReportPage.dispensing.statusBoard
                    .dispensingTitle,
                value: dispensingTotal,
                unit: S.profitReportPage.dispensing.statusBoard.dispensingUnit,
            },
            undefined,
            {
                title: S.profitReportPage.dispensing.statusBoard.billingTitle,
                value: billingCount,
                unit: S.profitReportPage.dispensing.statusBoard.billingUnit,
            },
            undefined,
            {
                title: S.profitReportPage.dispensing.statusBoard.averageTitle,
                value:
                    dispensingTotal !== undefined && billingCount !== undefined
                        ? Math.round(dispensingTotal / billingCount)
                        : undefined,
                unit: S.profitReportPage.dispensing.statusBoard.averageUnit,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <LayoutContainer>
                {_data.map((d, index) =>
                    d ? (
                        <ProfitReportDispensingStatusBoardSummaryItem
                            key={index}
                            height={height}
                            title={d.title}
                            value={d.value}
                            unit={d.unit}
                        />
                    ) : (
                        <SpacedDivider
                            key={index}
                            height={height}
                            horizontalMargin={"0px"}
                        />
                    )
                )}
            </LayoutContainer>
            <NoticeLabelContainer>
                {S.profitReportPage.dispensing.statusBoard.noticeLabel}
            </NoticeLabelContainer>
        </>
    );
};

export default ProfitReportDispensingStatusBoardSummaryView;
