import Palette from "presentation/theme/palette";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div<{
    $height: string;
    $horizontalPaddingInPx: number;
    $collapsedHorizontalPaddingInPx: number;
    $backgroundColor: string;
    $topOffset: string;
}>`
    width: 100%;
    height: ${(props) => props.$height};
    padding: ${(props) => `0 ${props.$horizontalPaddingInPx}px`};
    background-color: ${(props) => props.$backgroundColor};
    border-bottom: 1px solid ${Palette.gray100};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
    position: fixed;
    top: ${(props) => props.$topOffset};
    z-index: 100;
    transition: padding 0.3s ease-in-out;

    @media (max-width: 768px) {
        padding: ${(props) => `0 ${props.$collapsedHorizontalPaddingInPx}px`};
    }
`;

const TabBar = ({
                    className,
                    horizontalPaddingInPx = 36,
                    collapsedHorizontalPaddingInPx = 16,
                    backgroundColor = Palette.white100,
                    children,
                }: PropsWithChildren<{
    className?: string;
    horizontalPaddingInPx?: number;
    collapsedHorizontalPaddingInPx?: number;
    backgroundColor?: string;
}>) => {
    const theme = useThemeContext();

    return (
        <LayoutContainer
            className={className}
            $height={theme.componentTheme.tabBarHeight}
            $horizontalPaddingInPx={horizontalPaddingInPx}
            $collapsedHorizontalPaddingInPx={collapsedHorizontalPaddingInPx}
            $backgroundColor={backgroundColor}
            $topOffset={theme.componentTheme.tnbHeight}
        >
            {children}
        </LayoutContainer>
    );
};

export default TabBar;
