import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import {useLayoutEffect, useRef, useState} from "react";
import styled from "styled-components";

const LayoutContainer = styled.li.attrs<{
    $hovered: boolean;
    $selected: boolean;
    $heightInPx: number;
}>((props) => ({
    style: {
        backgroundColor: props.$selected
            ? Palette.primary10
            : props.$hovered
                ? Palette.gray100
                : Palette.white100,
    },
}))`
    width: 100%;
    min-height: ${(props) => `${props.$heightInPx}px`};
    padding: 0 8px;
    border-radius: 10px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    list-style: none;
    transition: background-color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
`;

const LabelContainer = styled.p.attrs<{
    $selected: boolean;
}>((props) => ({
    style: {
        fontWeight: props.$selected ? FontWeights.bold : FontWeights.medium,
    },
}))`
    ${Fonts.detail2};
    text-align: center;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DropdownButtonItem = ({
                                selected,
                                heightInPx,
                                label,
                                onClick,
                            }: {
    selected: boolean;
    heightInPx: number;
    label: string;
    onClick: () => void;
}) => {
    const ref = useRef<HTMLLIElement>(null);
    const [hovered, setHovered] = useState(false);

    useLayoutEffect(() => {
        const button = ref.current;
        if (!button) return;

        const onMouseEnter = () => setHovered(true);
        const onMouseLeave = () => setHovered(false);

        button.addEventListener("mouseenter", onMouseEnter);
        button.addEventListener("mouseleave", onMouseLeave);

        return () => {
            button.removeEventListener("mouseenter", onMouseEnter);
            button.removeEventListener("mouseleave", onMouseLeave);
        };
    }, [ref]);

    return (
        <LayoutContainer
            ref={ref}
            onClick={onClick}
            $hovered={hovered}
            $selected={selected}
            $heightInPx={heightInPx}
        >
            <LabelContainer $selected={selected}>{label}</LabelContainer>
        </LayoutContainer>
    );
};

export default DropdownButtonItem;
