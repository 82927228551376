import Dot from "presentation/components/common/dot";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.li`
    min-width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const TooltipLabelContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.white100};
`;

const ChartTooltipLabel = ({
                               color,
                               label,
                           }: {
    color?: string;
    label: string;
}) => {
    return (
        <LayoutContainer>
            {color && <Dot color={color}/>}
            <TooltipLabelContainer>{label}</TooltipLabelContainer>
        </LayoutContainer>
    );
};

export default ChartTooltipLabel;
