import {
    BillingStatusBoardTableContext
} from "presentation/pages/billing/view/status_board/components/billing_status_board_table/billing_status_board_table";
import {PropsWithChildren, useContext} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $isButton: boolean;
    $minWidthInPx: number;
    $heightInPx: number;
    $roundLeft: boolean;
    $roundRight: boolean;
    $backgroundColor: string;
}>((props) => ({
    style: {
        cursor: props.$isButton ? "pointer" : "default",
        minWidth: `${props.$minWidthInPx}px`,
        height: `${props.$heightInPx}px`,
        borderTopLeftRadius: props.$roundLeft ? "16px" : "0",
        borderBottomLeftRadius: props.$roundLeft ? "16px" : "0",
        borderTopRightRadius: props.$roundRight ? "16px" : "0",
        borderBottomRightRadius: props.$roundRight ? "16px" : "0",
        backgroundColor: props.$backgroundColor,
    },
}))`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: min-width 0.3s ease-in-out, background-color 0.3s ease-in-out;
`;

const BillingStatusBoardTableCellLayout = ({
                                               isButton = false,
                                               heightInPx,
                                               roundLeft,
                                               roundRight,
                                               backgroundColor,
                                               onClick,
                                               children,
                                           }: PropsWithChildren<{
    isButton?: boolean;
    heightInPx: number;
    roundLeft: boolean;
    roundRight: boolean;
    backgroundColor: string;
    onClick?: () => void;
}>) => {
    const context = useContext(BillingStatusBoardTableContext);

    return (
        <LayoutContainer
            onClick={onClick}
            $isButton={isButton}
            $minWidthInPx={context.constants.cellMinWidthInPx}
            $heightInPx={heightInPx}
            $roundLeft={roundLeft}
            $roundRight={roundRight}
            $backgroundColor={backgroundColor}
        >
            {children}
        </LayoutContainer>
    );
};

export default BillingStatusBoardTableCellLayout;
