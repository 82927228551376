import {JSONAssets} from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useEffect, useState} from "react";
import Lottie from "react-lottie";
import styled from "styled-components";

const LoadingContainer = styled.div.attrs<{
    $visible: boolean;
    $mount: boolean;
}>((props) => ({
    style: {
        opacity: props.$visible ? 1 : 0,
        visibility: props.$mount ? "visible" : "hidden",
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out;
`;

const LoadingLabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail3};
    color: ${Palette.black20};
    text-align: center;
    white-space: nowrap;
`;

const Loading = ({
                     className,
                     visible,
                     showLabel = true,
                 }: {
    className?: string;
    visible: boolean;
    showLabel?: boolean;
}) => {
    const [play, setPlay] = useState(visible);

    useEffect(() => {
        if (visible) {
            setPlay(true);
            return;
        }

        const id = setTimeout(() => setPlay(false), 500);

        return () => clearTimeout(id);
    }, [visible]);

    const options = {
        animationData: JSONAssets.Loading,
        loop: true,
    };

    return (
        <LoadingContainer
            className={className}
            $visible={visible}
            $mount={play}
        >
            <Lottie
                width={40}
                height={40}
                options={options}
                isStopped={!play}
                isClickToPauseDisabled={true}
            />
            {showLabel && (
                <LoadingLabelContainer>{S.loading.label}</LoadingLabelContainer>
            )}
        </LoadingContainer>
    );
};

export default Loading;
