import ProfitChartData from "presentation/components/charts/profit_chart/profit_chart_model";
import ProfitChartBars from "presentation/components/charts/profit_chart/components/profit_chart_bars";
import ChartHorizontalGrid from "presentation/components/charts/common/chart_horizontal_grid";
import ChartXAxis from "presentation/components/charts/common/chart_x_axis";
import ChartYAxis from "presentation/components/charts/common/chart_y_axis";
import {createContext, Dispatch, SetStateAction, useEffect, useMemo, useRef, useState,} from "react";
import ProfitChartDottedLines from "presentation/components/charts/profit_chart/components/profit_chart_dotted_lines";
import {optional} from "presentation/utils/types/optional";
import ProfitChartTooltip from "presentation/components/charts/profit_chart/components/profit_chart_tooltip";
import ChartLayout from "presentation/components/charts/common/chart_layout";
import {ChartAxisData, ChartInitialScrollPositionType,} from "presentation/components/charts/common/chart_model";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import ProfitChartCollectingBars
    from "presentation/components/charts/profit_chart/components/profit_chart_collecting_bars";
import NumberHelper from "config/helper/number_helper";
import ChartEmptyOverlay from "presentation/components/charts/common/chart_empty_overlay";

const topMarginInPx = 10;
const _binMarginInPx = 35;
const binSizeInPx = 52;
const xAxisHeightInPx = 30;
const yAxisWidthInPx = 60;
const _xAxisHorizontalMarginInPx = 52;
const barBorderRadiusInPx = 12;
const barRimPaddingInPx = 8;
const collectingTooltipHeightInPx = 33;
const collectingTooltipHorizontalPaddingInPx = 8;
const collectingTooltipMarginInPx = 16;

export const ProfitChartContext = createContext<{
    constants: {
        topMarginInPx: number;
        innerWidthInPx: number;
        widthInPx: number;
        innerHeightInPx: number;
        heightInPx: number;
        binSizeInPx: number;
        xAxisHorizontalMarginInPx: number;
        barBorderRadiusInPx: number;
        barRimPaddingInPx: number;
        collectingTooltipHeightInPx: number;
        collectingTooltipHorizontalPaddingInPx: number;
        collectingTooltipMarginInPx: number;
    };
    constantsKey: string;
    functions: {
        barReversed: (y: number) => boolean;
        getXPos: (x: number) => number;
        getYPosFromTop: (y: number) => number;
        getYPosFromBottom: (y: number) => number;
        getBarYPosFromTop: (x: number) => {
            start: number;
            end: number;
        };
        getBarYPosFromBottom: (x: number) => {
            start: number;
            end: number;
        };
    };
    data: ProfitChartData[];
    highlightedXIndices?: Set<number>;
    highlightedData?: {
        value?: number;
        x: number;
        yTop: number;
        yBottom: number;
    }[];
    hoveredXIndex?: number;
    setHoveredXIndex?: Dispatch<SetStateAction<optional<number>>>;
}>({
    constants: {
        topMarginInPx,
        innerWidthInPx: 500,
        widthInPx: 500,
        innerHeightInPx: 470,
        heightInPx: 470,
        binSizeInPx,
        xAxisHorizontalMarginInPx: _xAxisHorizontalMarginInPx,
        barBorderRadiusInPx,
        barRimPaddingInPx,
        collectingTooltipHeightInPx,
        collectingTooltipHorizontalPaddingInPx,
        collectingTooltipMarginInPx,
    },
    constantsKey: "",
    functions: {
        barReversed: (y: number) => false,
        getXPos: (x: number) => x,
        getYPosFromTop: (y: number) => y,
        getYPosFromBottom: (y: number) => y,
        getBarYPosFromTop: (y: number) => ({start: y, end: y}),
        getBarYPosFromBottom: (y: number) => ({start: y, end: y}),
    },
    data: [] as ProfitChartData[],
    highlightedData: undefined,
    highlightedXIndices: undefined,
    hoveredXIndex: undefined,
    setHoveredXIndex: undefined,
});

const ProfitChart = ({
                         heightInPx,
                         data,
                         highlightedXIndices,
                         highlightedXAxisIndices,
                         xAxis,
                         yAxis,
                         initialScrollPosition = ChartInitialScrollPositionType.Left,
                         overrideConstants,
                         hoverEnabled = true,
                         showEmptyOverlay = true,
                     }: {
    heightInPx: number;
    data: ProfitChartData[];
    highlightedXIndices?: Set<number>;
    highlightedXAxisIndices?: Set<number>;
    xAxis: ChartAxisData[];
    yAxis: ChartAxisData[];
    initialScrollPosition?: ChartInitialScrollPositionType;
    overrideConstants?: {
        binMarginInPx: number;
        xAxisHorizontalMarginInPx: number;
    };
    hoverEnabled?: boolean;
    showEmptyOverlay?: boolean;
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [hoveredXIndex, setHoveredXIndex] =
        useState<optional<number>>(undefined);
    useResizeObserver(ref, [data]);

    const dataEmpty = !data.length;

    const {xRange, yRange} = useMemo(
        () => {
            const xValues = xAxis.map((x) => x.value);
            const yValues = yAxis.map((y) => y.value);

            const xRange = {
                min: Math.min(...xValues),
                max: Math.max(...xValues),
            };
            const yRange = {
                min: Math.min(...yValues),
                max: Math.max(...yValues),
            };

            return {
                xRange,
                yRange,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const highlightedData = useMemo(() => {
        if (!highlightedXIndices) return undefined;

        return Array.from(highlightedXIndices!).map((x) => {
            const match = data.find((d) => d.x === x);
            const hasData = !!data.length;
            const upperAmplitude =
                yRange.max -
                ((Math.random() * yRange.max) / 3 + yRange.max / 2);
            const lowerAmplitude =
                yRange.min -
                ((Math.random() * yRange.min) / 3 + yRange.min / 2);
            const randomYValues = [upperAmplitude, lowerAmplitude].map((y) =>
                NumberHelper.clamp(y, yRange.min, yRange.max)
            );

            const yTop = !!match ? match.y1.value : Math.max(...randomYValues);
            const yBottom = !!match
                ? match.y3.value
                : hasData
                    ? Math.min(...randomYValues)
                    : 0;
            const value = !!match ? match.y1.value + match.y3.value : undefined;

            return {
                value,
                x,
                yTop,
                yBottom,
            };
        });
    }, [data, highlightedXIndices, yRange]);

    const {constants, constantsKey, functions, gridWidthInPx} =
        useMemo(() => {
            const binMarginInPx =
                overrideConstants?.binMarginInPx ?? _binMarginInPx;
            const xAxisHorizontalMarginInPx =
                overrideConstants?.xAxisHorizontalMarginInPx ??
                _xAxisHorizontalMarginInPx;

            const count = xRange.max - xRange.min;
            const binWidth = binSizeInPx + binMarginInPx * 2;
            const totalBinWidth = binWidth * count;
            const width = totalBinWidth + xAxisHorizontalMarginInPx * 2;

            const scrollbarHeight = Math.abs(
                (ref.current?.offsetHeight ?? 0) -
                (ref.current?.clientHeight ?? 0)
            );
            const availableWidthInPx = ref.current?.offsetWidth ?? 0;

            const constants = {
                topMarginInPx,
                widthInPx: width - yAxisWidthInPx,
                innerWidthInPx:
                    width - yAxisWidthInPx - xAxisHorizontalMarginInPx * 2,
                heightInPx,
                innerHeightInPx:
                    heightInPx -
                    (topMarginInPx + xAxisHeightInPx + scrollbarHeight),
                binSizeInPx,
                xAxisHorizontalMarginInPx,
                barBorderRadiusInPx,
                barRimPaddingInPx,
                collectingTooltipHeightInPx,
                collectingTooltipHorizontalPaddingInPx,
                collectingTooltipMarginInPx,
            };

            if (width < availableWidthInPx) {
                constants.widthInPx = availableWidthInPx - yAxisWidthInPx;
                constants.innerWidthInPx =
                    availableWidthInPx -
                    yAxisWidthInPx -
                    _xAxisHorizontalMarginInPx * 2;
            }

            const barReversed = (y: number) => y < 0;
            const getYPosFromTop = (y: number) => {
                const minY = yRange.min;
                const maxY = yRange.max;

                const magnitudeY = Math.abs(maxY - minY);
                const scaleY = constants.innerHeightInPx / magnitudeY;
                const yPos = (maxY - y) * scaleY;

                return yPos + topMarginInPx;
            };
            const getYPosFromBottom = (y: number) =>
                constants.heightInPx - getYPosFromTop(y) - scrollbarHeight;

            const functions = {
                barReversed: barReversed,
                getXPos: (x: number) => {
                    const minX = xRange.min;
                    const maxX = xRange.max;

                    const magnitudeX = Math.abs(maxX - minX);
                    const scaleX = constants.innerWidthInPx / magnitudeX;

                    return constants.xAxisHorizontalMarginInPx +
                        (x - minX) * scaleX;
                },
                getYPosFromTop: (y: number) => getYPosFromTop(y),
                getYPosFromBottom: (y: number) => getYPosFromBottom(y),
                getBarYPosFromTop: (y: number) => {
                    if (barReversed(y)) {
                        return {
                            start: getYPosFromTop(y),
                            end: getYPosFromTop(0),
                        };
                    }

                    return {
                        start: getYPosFromTop(0),
                        end: getYPosFromTop(y),
                    };
                },
                getBarYPosFromBottom: (y: number) => {
                    if (barReversed(y)) {
                        return {
                            start: getYPosFromBottom(0),
                            end: getYPosFromBottom(y),
                        };
                    }

                    return {
                        start: getYPosFromBottom(y),
                        end: getYPosFromBottom(0),
                    };
                },
            };

            const constantsKey = Object.entries(constants)
                .map(([key, value]) => `${key}-${value}`)
                .join();

            const gridWidthInPx =
                constants.innerWidthInPx +
                constants.xAxisHorizontalMarginInPx * 2;

            return {
                constants,
                constantsKey,
                functions,
                gridWidthInPx,
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            data,
            ref.current?.offsetWidth,
            ref.current?.offsetHeight,
            ref.current?.clientHeight,
        ]);

    const {gridData, yAxisData} = useMemo(() => {
        const yAxisData = yAxis.map((y) => ({
            yPosFromTop: functions.getYPosFromTop(y.value),
            label: y.label,
        }));
        const gridData = yAxisData.map((y) => y.yPosFromTop);

        return {
            yAxisData,
            gridData,
        };
    }, [yAxis, functions]);

    const xAxisData = useMemo(() => {
        return xAxis.map((x) => ({
            binWidthInPx: binSizeInPx,
            xPosFromLeft: functions.getXPos(x.value),
            label: x.label,
        }));
    }, [xAxis, functions]);

    useEffect(() => {
        if (!ref.current) return;

        let offset = 0;
        switch (initialScrollPosition) {
            case ChartInitialScrollPositionType.Left:
                offset = 0;
                break;
            case ChartInitialScrollPositionType.Middle:
                offset = constants.widthInPx / 2;
                break;
            case ChartInitialScrollPositionType.Right:
                offset = constants.widthInPx;
                break;
            default:
                throw new Error("Invalid ChartInitialScrollPositionType");
        }

        addPostFrameCallback(() =>
            ref.current?.scrollTo({
                left: offset,
                behavior: "smooth",
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, data.length]);

    useEffect(() => {
        setHoveredXIndex(undefined);
    }, [data]);

    return (
        <ProfitChartContext.Provider
            value={{
                constants,
                constantsKey,
                functions,
                data,
                highlightedData,
                highlightedXIndices,
                hoveredXIndex,
                setHoveredXIndex: hoverEnabled ? setHoveredXIndex : undefined,
            }}
        >
            <ChartLayout
                ref={ref}
                scrollable={!dataEmpty}
                heightInPx={constants.heightInPx}
                yAxis={
                    <ChartYAxis
                        widthInPx={yAxisWidthInPx}
                        heightInPx={constants.innerHeightInPx}
                        data={yAxisData}
                    />
                }
            >
                <ChartHorizontalGrid
                    widthInPx={gridWidthInPx}
                    yPositions={gridData}
                />
                <ChartXAxis
                    data={xAxisData}
                    highlightedIndices={highlightedXAxisIndices}
                />
                <ProfitChartBars/>
                <ProfitChartDottedLines/>
                {hoverEnabled && <ProfitChartTooltip/>}
                {highlightedXIndices && <ProfitChartCollectingBars/>}
                <ChartEmptyOverlay visible={showEmptyOverlay && dataEmpty}/>
            </ChartLayout>
        </ProfitChartContext.Provider>
    );
};

export default ProfitChart;
