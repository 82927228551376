import SpacedDivider from "presentation/components/common/spaced_divider";
import DashboardCard from "presentation/pages/dashboard/components/dashboard_card";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import DashboardOtherFiguresChartOtherExpensesView
    from "presentation/pages/dashboard/view/other_figures/chart/dashboard_other_figures_chart_other_expenses_view";
import DashboardOtherFiguresChartPurchasesView
    from "presentation/pages/dashboard/view/other_figures/chart/dashboard_other_figures_chart_purchases_view";
import DashboardOtherFiguresChartTableView
    from "presentation/pages/dashboard/view/other_figures/chart/dashboard_other_figures_chart_table_view";
import dashboardProfitSelector from "presentation/states/dashboard/selector/dashboard_profit_selector";
import Palette from "presentation/theme/palette";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {useContext, useRef} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @media (min-width: 1280px) {
        min-width: 0;
        flex-direction: row;
    }
`;

const ChartsLayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    @media (max-width: 768px) {
        min-width: 0;
        flex-direction: column;
    }
`;

const DashboardOtherFiguresChartView = () => {
    const {contentHeight: contentMaxHeight} = useContext(DashboardRowContext);

    const {status} = useRecoilValue(dashboardProfitSelector);

    const layoutRef = useRef<HTMLDivElement>(null);

    const {height} = useResizeObserver(layoutRef, []);
    const contentHeight = `max(auto, ${height})`;

    return (
        <DashboardCard
            status={status}
            height={contentHeight}
            isHeightContentHeight={true}
            loadingColor={Palette.white100}
            backgroundColor={Palette.white100}
            hoverColor={Palette.white100}
            grow={true}
            maxWidthOnGrow={"1018px"}
            upper={
                <LayoutContainer ref={layoutRef}>
                    <ChartsLayoutContainer>
                        <DashboardOtherFiguresChartPurchasesView/>
                        <SpacedDivider
                            height={contentMaxHeight!}
                            horizontalMargin={"16px"}
                        />
                        <DashboardOtherFiguresChartOtherExpensesView/>
                    </ChartsLayoutContainer>
                    <DashboardOtherFiguresChartTableView/>
                </LayoutContainer>
            }
        />
    );
};

export default DashboardOtherFiguresChartView;
