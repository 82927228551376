import Palette from "presentation/theme/palette";
import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    flex: 1;
    width: min(100%, 31.0625rem);
    padding: 24px;
    border-radius: 16px;
    background-color: ${Palette.white100};
    overflow: hidden;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ExternalSyncsRowItem = ({children}: PropsWithChildren) => {
    return <LayoutContainer>{children}</LayoutContainer>;
};

export default ExternalSyncsRowItem;
