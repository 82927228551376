import ViewStatusType from "domain/model/common/view_status_type";
import ReplayState from "presentation/states/base/replay_state";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {optional} from "presentation/utils/types/optional";
import {useEffect, useLayoutEffect, useRef} from "react";
import {BehaviorSubject} from "rxjs";

export const useReplayState = <State extends ReplayState>(
    replayState: BehaviorSubject<optional<State>>,
    listener: (state: State, handled: () => void) => void,
    status?: ViewStatusType
) => {
    const theme = useThemeContext();

    const stateRef = useRef<optional<State>>(undefined);

    const handled = () => replayState.next(undefined);

    useLayoutEffect(() => {
        const _listener = (state?: State) => {
            if (!state) return;

            if (status) {
                stateRef.current = state;
                return;
            }

            listener(state, handled);
        };

        const subscription = replayState.subscribe(_listener);

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [replayState, listener]);

    useEffect(() => {
        if (!status) return;

        const id = setTimeout(() => {
            if (!stateRef.current) return;
            if (status !== ViewStatusType.Loaded) return;

            listener(stateRef.current, handled);
            stateRef.current = undefined;
        }, theme.animationTheme.loadingDelayMS);

        return () => clearTimeout(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [replayState, status, listener]);
};
