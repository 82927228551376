import SortType from "domain/model/common/sort_type";
import TransactionsDailyFilteredFigures from "domain/model/tax/transactions/transactions_daily_filtered_figures";
import TransactionsFiguresType, {
    TransactionsFiguresTypeMap,
} from "domain/model/tax/transactions/transactions_figures_type";
import TransactionsType, {
    TransactionsTypeMap,
} from "domain/model/tax/transactions/transactions_type";
import Pagination from "presentation/components/pagination/pagination";
import TableEmptyLabel from "presentation/components/table/table_empty_label";
import TransactionsTableHeader
    from "presentation/pages/transactions/components/transactions_table/components/transactions_table_header";
import TransactionsTableRow
    from "presentation/pages/transactions/components/transactions_table/components/transactions_table_row";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {createContext, useEffect, useMemo, useState} from "react";
import styled from "styled-components";

const constants = {
    maxVisibleCount: 10,
    emptyListHeightInPx: 138,
    headerCellHeightInPx: 44,
    rowBorderRadiusInPx: 16,
    collectingCellHeightInPx: 72,
    cellMinWidthInPx: 186,
    cellHeightInPx: 52,
    verticalGapInPx: 4,
    horizontalGapInPx: 4,
    listToPaginationGapInPx: 36,
    bottomMarginInPx: 20,
};

export const TransactionsTableContext = createContext<{
    constants: {
        maxVisibleCount: number;
        emptyListHeightInPx: number;
        headerCellHeightInPx: number;
        rowBorderRadiusInPx: number;
        collectingCellHeightInPx: number;
        cellMinWidthInPx: number;
        cellHeightInPx: number;
        verticalGapInPx: number;
        horizontalGapInPx: number;
        listToPaginationGapInPx: number;
        bottomMarginInPx: number;
    };
    type: TransactionsType;
    headerLabels: {
        label: string;
        flex: number;
        leftMarginInPx: number;
        backgroundColor: string;
        color: string;
        isDateSortButton: boolean;
        isTotalSortButton: boolean;
    }[];
    dateSortType: SortType;
    totalSortType: SortType;
    hasCollectingData: boolean;
    onSortChange: (dateSortType: SortType, totalSortType: SortType) => void;
    onClick: (data: TransactionsDailyFilteredFigures) => void;
}>({
    constants,
    type: TransactionsType.Sales,
    headerLabels: [],
    dateSortType: SortType.None,
    totalSortType: SortType.None,
    hasCollectingData: false,
    onSortChange: () => {
    },
    onClick: () => {
    },
});

const LayoutContainer = styled.div`
    width: 100%;
    position: relative;
    overflow-x: scroll;
    overflow-y: clip;
    scrollbar-color: ${Palette.none} ${Palette.none};
    transition: height 0.3s ease-in-out, scrollbar-color 0.3s ease-in-out;

    &:hover {
        scrollbar-color: ${Palette.gray300} ${Palette.none};
    }
`;

const ColumnContainer = styled.div.attrs<{
    $minWidthInPx: number;
    $height: string;
    $gapInPx: number;
}>((props) => ({
    style: {
        width: `max(100%, ${props.$minWidthInPx}px)`,
        height: props.$height,
        gap: `${props.$gapInPx}px`,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    gap 0.3s ease-in-out;
`;

const PaginationPositionContainer = styled.div.attrs<{
    $topOffsetInPx: number;
}>((props) => ({
    style: {
        top: `${props.$topOffsetInPx}px`,
    },
}))`
    position: absolute;
    transition: top 0.3s ease-in-out;
`;

const TransactionsTable = ({
                               type,
                               data,
                               dateSortType,
                               totalSortType,
                               onSortChange,
                               onClick,
                           }: {
    type: TransactionsType;
    data: TransactionsDailyFilteredFigures[];
    dateSortType: SortType;
    totalSortType: SortType;
    onSortChange: (dateSortType: SortType, totalSortType: SortType) => void;
    onClick: (data: TransactionsDailyFilteredFigures) => void;
}) => {
    const theme = useThemeContext();

    const [page, setPage] = useState(1);

    const {minWidthInPx, headerLabels} = useMemo(() => {
        const labels = [
            {
                label: S.transactionsTable.dateLabel,
                backgroundColor: Palette.gray100,
            },
            {
                label: S.transactionsTable.totalLabel(
                    TransactionsTypeMap.shortLabel(type)
                ),
                backgroundColor: Palette.gray700,
            },
            ...Object.values(TransactionsFiguresType)
                .filter(
                    (t) =>
                        TransactionsFiguresTypeMap.transactionsType(t) === type
                )
                .map((t) => ({
                    label: S.transactionsTable.dateHeaderLabel(
                        TransactionsFiguresTypeMap.shortLabel(t),
                        TransactionsTypeMap.shortLabel(type)
                    ),
                    backgroundColor: TransactionsFiguresTypeMap.tableColor(t)!,
                })),
        ];

        const headerLabels = labels.map((l, index) => ({
            label: l.label,
            flex: 1,
            leftMarginInPx: index === 0 ? 0 : constants.horizontalGapInPx,
            backgroundColor: l.backgroundColor,
            color:
                index === 0 || index === labels.length - 1
                    ? Palette.gray800
                    : Palette.white100,
            isDateSortButton: index === 0,
            isTotalSortButton: index === 1,
        }));

        const minWidthInPx = headerLabels.reduce(
            (acc, cur) => acc + constants.cellMinWidthInPx + cur.leftMarginInPx,
            0
        );

        return {
            minWidthInPx,
            headerLabels,
        };
    }, [type]);

    const _data = useMemo(() => {
        if (dateSortType === SortType.Ascending) {
            return data.toSorted(
                (a, b) =>
                    b.date.millisecondsSinceEpoch -
                    a.date.millisecondsSinceEpoch
            );
        }

        if (dateSortType === SortType.Descending) {
            return data.toSorted(
                (a, b) =>
                    a.date.millisecondsSinceEpoch -
                    b.date.millisecondsSinceEpoch
            );
        }

        if (totalSortType === SortType.Ascending) {
            return data.toSorted((a, b) => b.values[0] - a.values[0]);
        }

        if (totalSortType === SortType.Descending) {
            return data.toSorted((a, b) => a.values[0] - b.values[0]);
        }

        return data.toSorted(
            (a, b) =>
                b.date.millisecondsSinceEpoch - a.date.millisecondsSinceEpoch
        );
    }, [data, dateSortType, totalSortType]);

    const {pageData, maxPage} = useMemo(() => {
        const pageSize = constants.maxVisibleCount;
        const pageData: Record<number, TransactionsDailyFilteredFigures[]> = {};

        let page = 1;
        while ((page - 1) * pageSize < _data.length) {
            pageData[page] = _data.slice(
                (page - 1) * pageSize,
                page * pageSize
            );
            page++;
        }

        return {
            pageData,
            maxPage: Math.ceil(_data.length / constants.maxVisibleCount),
        };
    }, [_data]);

    const {hasCollectingData, height, paginationTopOffsetInPx} =
        useMemo(() => {
            const now = DateTime.minNow();
            const yesterday = now.copyWith({day: now.day - 1});
            const hasCollectingData =
                DateTime.now().hour < 12 &&
                (pageData[page]?.some(
                        (d) => d.date.isSameDay(yesterday) && d.values[0] === 0
                    ) ??
                    false);

            const visibleCount = Math.min(
                _data.length,
                constants.maxVisibleCount
            );
            const listHeight = visibleCount
                ? (visibleCount - 1) *
                (constants.cellHeightInPx + constants.verticalGapInPx) +
                constants.verticalGapInPx +
                (hasCollectingData
                    ? constants.collectingCellHeightInPx
                    : constants.cellHeightInPx)
                : constants.emptyListHeightInPx;
            const heightInPx =
                constants.headerCellHeightInPx +
                listHeight +
                constants.listToPaginationGapInPx +
                constants.bottomMarginInPx;
            const paginationTopOffsetInPx =
                constants.headerCellHeightInPx +
                constants.verticalGapInPx +
                listHeight +
                constants.listToPaginationGapInPx;

            const height = `calc(${heightInPx}px + ${theme.componentTheme.paginationHeight})`;

            return {
                hasCollectingData,
                height,
                paginationTopOffsetInPx,
            };
        }, [theme, _data, page, pageData]);

    useEffect(() => {
        setPage(1);
    }, [data, _data.length]);

    return (
        <TransactionsTableContext.Provider
            value={{
                constants,
                type,
                headerLabels,
                dateSortType,
                totalSortType,
                hasCollectingData,
                onSortChange,
                onClick,
            }}
        >
            <LayoutContainer>
                <ColumnContainer
                    $minWidthInPx={minWidthInPx}
                    $height={height}
                    $gapInPx={constants.verticalGapInPx}
                >
                    <TransactionsTableHeader/>
                    {_data.length ? (
                        pageData[page]?.map((d, index) => (
                            <TransactionsTableRow
                                key={index}
                                highlight={index % 2 === 0}
                                data={d}
                            />
                        ))
                    ) : (
                        <TableEmptyLabel minWidthInPx={minWidthInPx}/>
                    )}
                    {!!_data.length && (
                        <PaginationPositionContainer
                            $topOffsetInPx={paginationTopOffsetInPx}
                        >
                            <Pagination
                                maxPage={maxPage}
                                page={page}
                                onPageChange={setPage}
                            />
                        </PaginationPositionContainer>
                    )}
                </ColumnContainer>
            </LayoutContainer>
        </TransactionsTableContext.Provider>
    );
};

export default TransactionsTable;
