import SortType, {SortTypeMap} from "domain/model/common/sort_type";
import SizedBox from "presentation/components/common/sized_box";
import SortIcon from "presentation/components/common/sort_icon";
import {
    BillingStatusBoardTableContext
} from "presentation/pages/billing/view/status_board/components/billing_status_board_table/billing_status_board_table";
import BillingStatusBoardTableCellLayout
    from "presentation/pages/billing/view/status_board/components/billing_status_board_table/components/billing_status_board_table_cell_layout";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext, useMemo} from "react";
import styled from "styled-components";

const LayoutContainer = styled.li`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const CellLabelContainer = styled.h6`
    min-width: max-content;
    ${Fonts.detail2};
    text-align: center;
`;

const BillingStatusBoardTableHeader = () => {
    const context = useContext(BillingStatusBoardTableContext);

    const data = useMemo(
        () =>
            [
                S.billingStatusBoardTable.dateHeaderLabel,
                S.billingStatusBoardTable.orderHeaderLabel,
                S.billingStatusBoardTable.amountHeaderLabel,
                S.billingStatusBoardTable.pendingHeaderLabel,
                S.billingStatusBoardTable.taxesHeaderLabel,
                S.billingStatusBoardTable.receiveHeaderLabel,
                S.billingStatusBoardTable.receiveDateHeaderLabel,
                S.billingStatusBoardTable.statusHeaderLabel,
            ].map((l, index) => ({
                label: l,
                isButton: index === 0 || index === 2,
                isDateSortButton: index === 0,
                isBillingAmountSortButton: index === 2,
            })),
        []
    );

    const dateSortType = context.dateSortType;
    const billingAmountSortType = context.billingAmountSortType;

    const onClick =
        (isDateSortType: boolean, isBillingAmountSortType: boolean) => () => {
            if (isDateSortType) {
                context.onSortChange(
                    SortTypeMap.next(dateSortType),
                    SortType.None
                );
                return;
            }

            if (isBillingAmountSortType) {
                context.onSortChange(
                    SortType.None,
                    SortTypeMap.next(billingAmountSortType)
                );
                return;
            }
        };

    return (
        <LayoutContainer>
            {data.map((d, index) => (
                <BillingStatusBoardTableCellLayout
                    key={index}
                    isButton={d.isButton}
                    heightInPx={context.constants.headerCellHeightInPx}
                    roundLeft={index === 0}
                    roundRight={index === data.length - 1}
                    backgroundColor={Palette.gray100}
                    onClick={onClick(
                        d.isDateSortButton,
                        d.isBillingAmountSortButton
                    )}
                >
                    <CellLabelContainer>{d.label}</CellLabelContainer>
                    {d.isButton && (
                        <>
                            <SizedBox width={"10px"}/>
                            <SortIcon
                                width={"20px"}
                                height={"20px"}
                                sortType={
                                    d.isDateSortButton
                                        ? context.dateSortType
                                        : d.isBillingAmountSortButton
                                            ? context.billingAmountSortType
                                            : SortType.None
                                }
                            />
                        </>
                    )}
                </BillingStatusBoardTableCellLayout>
            ))}
        </LayoutContainer>
    );
};

export default BillingStatusBoardTableHeader;
