import ChartLegendLabel from "presentation/components/charts/common/chart_legend_label";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 36px;
`;

const DashboardOtherFiguresChartLegends = ({
                                               data,
                                           }: {
    data: {
        label: string;
        color: string;
    }[];
}) => {
    return (
        <LayoutContainer>
            {data.map((d, index) => (
                <ChartLegendLabel key={index} label={d.label} color={d.color}/>
            ))}
        </LayoutContainer>
    );
};

export default DashboardOtherFiguresChartLegends;
