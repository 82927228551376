import NumberHelper from "config/helper/number_helper";
import {ProfitReportDateFilterTypeMap} from "domain/model/tax/profit_report/profit_report_date_filter_type";
import {ChartAxisData} from "presentation/components/charts/common/chart_model";
import ProfitChart from "presentation/components/charts/profit_chart/profit_chart";
import profitReportProfitStatusBoardSelector
    from "presentation/states/profit_report/selector/profit/status_board/profit_report_status_board_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {DateDifferenceUnitType} from "presentation/utils/extension/date_extension";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";

const ProfitReportProfitStatusBoardDataChartView = () => {
    const {selectedDateFilter, startDate, endDate, data, accumulated} =
        useRecoilValue(profitReportProfitStatusBoardSelector);

    const {
        profitChartData,
        xAxis,
        yAxis,
        highlightedXIndices,
        highlightedXAxisIndices,
    } = useMemo(() => {
        const profitChartData = data.map((d) => ({
            x: d.date.subtract(startDate, DateDifferenceUnitType.Month),
            y1: {
                value: d.sales[0],
                color: Palette.deepBlue,
                borderRounded: true,
            },
            y2: {
                value: -d.purchases[0],
                color: Palette.orange500,
                borderRounded: false,
            },
            y3: {
                value: -d.otherExpenses[0] - d.purchases[0],
                color: Palette.gray500,
                borderRounded: true,
            },
        }));
        if (accumulated) {
            const maxIndex = profitChartData.length - 1;
            for (let i = 0; i <= maxIndex; i++) {
                const cur = profitChartData[i];
                const prev = i > 0 ? profitChartData.at(i - 1) : undefined;

                profitChartData[i] = {
                    x: cur.x,
                    y1: {
                        ...cur.y1,
                        value: cur.y1.value + (prev?.y1.value ?? 0),
                    },
                    y2: {
                        ...cur.y2,
                        value: cur.y2.value + (prev?.y2.value ?? 0),
                    },
                    y3: {
                        ...cur.y3,
                        value: cur.y3.value + (prev?.y3.value ?? 0),
                    },
                };
            }
        }

        const mins = profitChartData.map((d) =>
            Math.min(d.y1.value, d.y2.value, d.y3.value)
        );
        const maxes = profitChartData.map((d) =>
            Math.max(d.y1.value, d.y2.value, d.y3.value)
        );
        const minY = Math.min(...mins);
        const maxY = Math.max(...maxes);

        const yLabelCount = 8;
        const unit = NumberHelper.getUnitFromMinMaxCount(
            minY,
            maxY,
            yLabelCount
        );
        const [lowerBoundY, upperBoundY] = NumberHelper.getBoundsForCount(
            minY,
            maxY,
            yLabelCount
        );

        const xAxisLength =
            endDate.subtract(startDate, DateDifferenceUnitType.Month) + 1;
        const xAxis = data.length
            ? Array.from(
                {
                    length: xAxisLength,
                },
                (_, index) => ({
                    value: index,
                    label: startDate
                        .copyWith({
                            month: startDate.month + index,
                        })
                        .format(
                            S.profitReportPage.profit.statusBoard.profitChart
                                .xAxisDateFormat
                        ),
                })
            )
            : [];
        const yAxis: ChartAxisData[] = [];

        let pointer = lowerBoundY;
        while (pointer <= upperBoundY) {
            yAxis.push({
                value: pointer,
                label: NumberHelper.toChartYAxisAbbreviatedString(pointer),
            });
            pointer += unit;
        }

        const highlightedXIndices = new Set(
            [
                profitChartData.find((d) => d.x === xAxisLength - 2)
                    ? undefined
                    : xAxisLength - 2,
                profitChartData.find((d) => d.x === xAxisLength - 1)
                    ? undefined
                    : xAxisLength - 1,
            ]
                .filter((x) => x !== undefined)
                .map((x) => x!)
        );
        const highlightedXAxisIndices = new Set([xAxisLength - 1]);

        return {
            profitChartData,
            xAxis,
            yAxis,
            highlightedXIndices: data.length ? highlightedXIndices : undefined,
            highlightedXAxisIndices: data.length
                ? highlightedXAxisIndices
                : undefined,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, accumulated]);

    const initialScrollPosition =
        ProfitReportDateFilterTypeMap.initialScrollPosition(selectedDateFilter);

    return (
        <ProfitChart
            heightInPx={470}
            data={profitChartData}
            highlightedXIndices={highlightedXIndices}
            highlightedXAxisIndices={highlightedXAxisIndices}
            xAxis={xAxis}
            yAxis={yAxis}
            initialScrollPosition={initialScrollPosition}
        />
    );
};

export default ProfitReportProfitStatusBoardDataChartView;
