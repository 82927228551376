import ViewStatusType from "domain/model/common/view_status_type";
import SizedBox from "presentation/components/common/sized_box";
import ContentBox from "presentation/components/content/content_box";
import ContentLayout from "presentation/components/content/content_layout";
import BillingStatusBoardDateButtonsView
    from "presentation/pages/billing/view/status_board/billing_status_board_date_buttons_view";
import BillingStatusBoardFiguresTitleView
    from "presentation/pages/billing/view/status_board/billing_status_board_figures_title_view";
import BillingStatusBoardFiguresView
    from "presentation/pages/billing/view/status_board/billing_status_board_figures_view";
import BillingStatusBoardTableHeaderView
    from "presentation/pages/billing/view/status_board/billing_status_board_table_header_view";
import BillingStatusBoardSummaryTitleView
    from "presentation/pages/billing/view/status_board/billing_status_board_summary_title_view";
import BillingStatusBoardSummaryView
    from "presentation/pages/billing/view/status_board/billing_status_board_summary_view";
import {BillingScrollToElement} from "presentation/states/billing/billing_replay_state";
import {billingReplayState} from "presentation/states/billing/billing_state";
import {useReplayState} from "presentation/utils/hooks/use_replay_state";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import useScrollIntoView from "presentation/utils/hooks/use_scroll_into_view";
import {useRef} from "react";
import BillingStatusBoardTableView from "presentation/pages/billing/view/status_board/billing_status_board_table_view";

const BillingStatusBoardView = ({status}: { status: ViewStatusType }) => {
    const scrollIntoView = useScrollIntoView();

    const boxRef = useRef<HTMLDivElement>(null);
    const layoutRef = useRef<HTMLDivElement>(null);

    useReplayState(
        billingReplayState,
        (state, handled) => {
            if (state instanceof BillingScrollToElement) {
                if (state.target !== "statusBoard") return;

                scrollIntoView(boxRef);
                handled();
            }
        },
        status
    );

    const {height: contentHeight} = useResizeObserver(layoutRef, []);

    return (
        <ContentBox ref={boxRef} contentHeight={contentHeight}>
            <ContentLayout ref={layoutRef}>
                <BillingStatusBoardDateButtonsView/>
                <SizedBox height={"36px"}/>
                <BillingStatusBoardSummaryTitleView/>
                <SizedBox height={"24px"}/>
                <BillingStatusBoardSummaryView/>
                <SizedBox height={"36px"}/>
                <BillingStatusBoardFiguresTitleView/>
                <SizedBox height={"24px"}/>
                <BillingStatusBoardFiguresView/>
                <SizedBox height={"36px"}/>
                <BillingStatusBoardTableHeaderView/>
                <SizedBox height={"12px"}/>
                <BillingStatusBoardTableView/>
            </ContentLayout>
        </ContentBox>
    );
};

export default BillingStatusBoardView;
