import ViewStatusType from "domain/model/common/view_status_type";
import ArrowIconButton from "presentation/components/button/arrow_icon_button";
import SizedBox from "presentation/components/common/sized_box";
import taxTimelineDateSelector from "presentation/states/tax_timeline/selector/tax_timeline_date_selector";
import taxTimelineState from "presentation/states/tax_timeline/tax_timeline_state";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import useViewStatus from "presentation/utils/hooks/use_view_status";
import Debouncer from "presentation/utils/debouncer/debouncer";
import {useRef, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import styled from "styled-components";

const debouncerDelayInMS = 500;

const LayoutContainer = styled.div.attrs<{
    $height: string;
    $topOffset: string;
    $leftOffset: string;
}>((props) => ({
    style: {
        height: props.$height,
        top: props.$topOffset,
        left: props.$leftOffset,
    },
}))`
    padding: 0 36px;
    border-bottom: 1px solid ${Palette.gray100};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 0;
    z-index: 100;
    background-color: ${Palette.white100};
    transition: padding 0.3s ease-in-out, 0.3s ease-in-out;

    @media (max-width: 768px) {
        padding: 0 16px;
    }
`;

const HeaderTitleLayoutContainer = styled.div`
    width: 208px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

const HeaderTitleContainer = styled.h4`
    ${Fonts.title2};
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

const TaxTimelineHeaderView = ({status}: { status: ViewStatusType }) => {
    const isMobile = useMobileQuery();
    const theme = useThemeContext();
    const {loaded} = useViewStatus(status);

    const {startDate, selectedYear} = useRecoilValue(taxTimelineDateSelector);
    const setState = useSetRecoilState(taxTimelineState);

    const debouncerRef = useRef(new Debouncer());
    const [year, setYear] = useState(selectedYear);

    const leftOffset = isMobile
        ? theme.componentTheme.lnbMinWidth
        : theme.componentTheme.lnbMaxWidth;

    const title = year.format(S.taxTimelinePage.header.dateFormat);

    const hasPrevious = loaded && year.isAfter(startDate.getFirstDayInYear());
    const hasNext = loaded && year.isBefore(DateTime.now().getFirstDayInYear());

    const onPreviousClick = () =>
        setYear((prevYear) => {
            debouncerRef.current.run(
                () =>
                    setState((prev) => ({
                        ...prev,
                        selectedYearKey: prevYear.copyWith({
                            year: prevYear.year - 1,
                        }).key,
                    })),
                debouncerDelayInMS
            );

            return prevYear.copyWith({year: prevYear.year - 1});
        });

    const onNextClick = () =>
        setYear((prevYear) => {
            debouncerRef.current.run(
                () =>
                    setState((prev) => ({
                        ...prev,
                        selectedYearKey: prevYear.copyWith({
                            year: prevYear.year + 1,
                        }).key,
                    })),
                debouncerDelayInMS
            );

            return prevYear.copyWith({year: prevYear.year + 1});
        });

    return (
        <>
            <SizedBox
                width={"100%"}
                height={theme.componentTheme.taxTimelineHeaderHeight}
            />
            <LayoutContainer
                $height={theme.componentTheme.taxTimelineHeaderHeight}
                $topOffset={theme.componentTheme.tnbHeight}
                $leftOffset={leftOffset}
            >
                <HeaderTitleLayoutContainer>
                    <ArrowIconButton
                        enabled={hasPrevious}
                        asset={SVGAssets.Back}
                        onClick={onPreviousClick}
                    />
                    <HeaderTitleContainer>{title}</HeaderTitleContainer>
                    <ArrowIconButton
                        enabled={hasNext}
                        asset={SVGAssets.Next}
                        onClick={onNextClick}
                    />
                </HeaderTitleLayoutContainer>
            </LayoutContainer>
        </>
    );
};

export default TaxTimelineHeaderView;
