import BillingRepository from "data/repository/billing_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import BillingMonthlyFigures from "domain/model/tax/billing/billing_monthly_figures";
import StatisticNumberSmall from "presentation/components/statistic/statistic_number_small";
import StatisticTitleLarge from "presentation/components/statistic/statistic_title_large";
import DashboardCard from "presentation/pages/dashboard/components/dashboard_card";
import DashboardCardColumn from "presentation/pages/dashboard/components/dashboard_card_column";
import DashboardCardFigures from "presentation/pages/dashboard/components/dashboard_card_figures";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import DashboardFiguresBillingLabelView
    from "presentation/pages/dashboard/view/figures/billing/dashboard_figures_billing_label_view";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useRepository from "presentation/utils/hooks/use_repository";
import {optional} from "presentation/utils/types/optional";
import {useContext, useEffect, useState} from "react";
import {useRecoilValue} from "recoil";

const DashboardFiguresBillingView = () => {
    const repository = useRepository(BillingRepository);
    const {height} = useContext(DashboardRowContext);

    const {selectedYearMonth} = useRecoilValue(dashboardDateSelector);

    const [status, setStatus] = useState(ViewStatusType.Loading);
    const [data, setData] =
        useState<optional<BillingMonthlyFigures>>(undefined);

    useEffect(() => {
        repository({
            handler: async (repository) => {
                const data = await repository.getMonthlyFiguresInRange({
                    startDate: selectedYearMonth,
                    endDate: selectedYearMonth.getLastDayInMonth(),
                });

                setData(data.at(0));

                if (status !== ViewStatusType.Loaded) {
                    setStatus(ViewStatusType.Loaded);
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYearMonth.key]);

    const onClick = () => {
        const route = `${AppRoutes.Billing}?scrollTo=statusBoard`;
        AppRouter.navigate(route);
    };

    return (
        <DashboardCard
            status={status}
            height={height!}
            onClick={onClick}
            loadingColor={Palette.red200}
            backgroundColor={Palette.red400}
            hoverColor={Palette.red300}
            isButton={true}
            upper={
                <DashboardCardColumn>
                    <StatisticTitleLarge
                        title={S.dashboardPage.figures.billingTitle}
                        color={Palette.white100}
                    />
                    <StatisticNumberSmall
                        value={data?.pendingAmount}
                        unit={S.dashboardPage.wonUnit}
                        color={Palette.white100}
                    />
                    <DashboardFiguresBillingLabelView
                        needCheck={!!data && data.pendingAmount > 0}
                    />
                </DashboardCardColumn>
            }
            lower={
                <DashboardCardColumn>
                    <DashboardCardFigures
                        title={S.dashboardPage.figures.billingShortTitle}
                        value={data?.billingCount}
                        unit={S.dashboardPage.eaUnit}
                    />
                    <DashboardCardFigures
                        title={S.dashboardPage.figures.pendingShortTitle}
                        value={data?.pendingCount}
                        unit={S.dashboardPage.eaUnit}
                    />
                </DashboardCardColumn>
            }
        />
    );
};

export default DashboardFiguresBillingView;
