import TaxTimeline from "domain/model/tax/tax_timeline/tax_timeline";
import TaxTimelineTableFixedRow
    from "presentation/pages/tax_timeline/components/tax_timeline_table/components/tax_timeline_table_fixed_row";
import TaxTimelineTableHeader
    from "presentation/pages/tax_timeline/components/tax_timeline_table/components/tax_timeline_table_header";
import TaxTimelineTableRow
    from "presentation/pages/tax_timeline/components/tax_timeline_table/components/tax_timeline_table_row";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {createContext, useMemo} from "react";
import styled from "styled-components";

const constants = {
    headerCellHeightInPx: 48,
    monthCellWidthInPx: 64,
    dueCellMinWidthInPx: 220,
    descriptionCellMinWidthInPx: 308,
    cellContentHeightInPx: 20,
    cellVerticalPaddingInPx: 16,
    rowBorderRadiusInPx: 16,
    verticalGapInPx: 4,
    horizontalGapInPx: 4,
    bottomMarginInPx: 20,
};

export const TaxTimelineTableContext = createContext<{
    constants: typeof constants;
    headerLabels: {
        label?: string;
        minWidthInPx: number;
        flex?: number;
    }[];
}>({
    constants,
    headerLabels: [],
});

const LayoutContainer = styled.div.attrs<{
    $heightInPx: number;
}>((props) => ({
    style: {
        height: `${props.$heightInPx}px`,
    },
}))`
    width: 100%;
    position: relative;
    overflow-x: scroll;
    overflow-y: clip;
    scrollbar-color: ${Palette.none} ${Palette.none};
    transition: height 0.3s ease-in-out, scrollbar-color 0.3s ease-in-out;

    &:hover {
        scrollbar-color: ${Palette.gray300} ${Palette.none};
    }
    transition: height 0.3s ease-in-out;
`;

const ColumnContainer = styled.ul.attrs<{
    $minWidthInPx: number;
    $gapInPx: number;
}>((props) => ({
    style: {
        width: `max(100%, ${props.$minWidthInPx}px)`,
        gap: `${props.$gapInPx}px`,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
        gap 0.3s ease-in-out;
`;

const TaxTimelineTable = ({
                              year,
                              data,
                          }: {
    year: DateTime;
    data: TaxTimeline[];
}) => {
    const headerLabels = useMemo(
        () => [
            {
                label: undefined,
                minWidthInPx: constants.monthCellWidthInPx,
                flex: undefined,
            },
            {
                label: S.taxTimelineTable.dueHeaderLabel,
                minWidthInPx: constants.dueCellMinWidthInPx,
                flex: undefined,
            },
            {
                label: S.taxTimelineTable.descriptionHeaderLabel,
                minWidthInPx: constants.descriptionCellMinWidthInPx,
                flex: 1,
            },
        ],
        []
    );

    const _data = useMemo(() => {
        const currentYear = year.getFirstDayInYear();

        const months = Array.from(
            {
                length: DateTime.monthsInYear,
            },
            (_, index) =>
                currentYear.copyWith({
                    month: currentYear.month + index,
                })
        );

        return months.map((m) => ({
            yearMonth: m,
            data: data.filter((d) => d.declarationEndDate.isSameMonth(m)),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const {minWidthInPx, heightInPx} = useMemo(() => {
        const minWidthInPx =
            constants.monthCellWidthInPx +
            constants.dueCellMinWidthInPx +
            constants.descriptionCellMinWidthInPx +
            constants.horizontalGapInPx * 2;

        const heightInPx =
            constants.headerCellHeightInPx +
            constants.cellContentHeightInPx * 2 +
            constants.cellVerticalPaddingInPx * 2 +
            constants.verticalGapInPx +
            _data.reduce(
                (acc, cur) =>
                    acc +
                    (cur.data.length ? cur.data.length : 1) *
                    constants.cellContentHeightInPx +
                    constants.cellVerticalPaddingInPx * 2,
                0
            ) +
            _data.length * constants.verticalGapInPx +
            constants.bottomMarginInPx;

        return {
            minWidthInPx,
            heightInPx,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_data]);

    return (
        <TaxTimelineTableContext.Provider
            value={{
                constants,
                headerLabels,
            }}
        >
            <LayoutContainer $heightInPx={heightInPx}>
                <ColumnContainer
                    $minWidthInPx={minWidthInPx}
                    $gapInPx={constants.verticalGapInPx}
                >
                    <TaxTimelineTableHeader/>
                    <TaxTimelineTableFixedRow/>
                    {_data.map((d, index) => (
                        <TaxTimelineTableRow
                            key={index}
                            yearMonth={d.yearMonth}
                            data={d.data}
                        />
                    ))}
                </ColumnContainer>
            </LayoutContainer>
        </TaxTimelineTableContext.Provider>
    );
};

export default TaxTimelineTable;
