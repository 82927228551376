import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $height: string }>((props) => ({
    style: {
        height: props.$height,
    },
}))`
    width: 249px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: min-width 0.3s ease-in-out, max-width 0.3s ease-in-out,
        height 0.3s ease-in-out;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const DashboardOtherFiguresChartLayout = ({
                                              height,
                                              children,
                                          }: PropsWithChildren<{ height: string }>) => {
    return <LayoutContainer $height={height}>{children}</LayoutContainer>;
};

export default DashboardOtherFiguresChartLayout;
