import StatisticNumberLarge from "presentation/components/statistic/statistic_number_large";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $height: string }>((props) => ({
    style: {
        height: props.$height,
    },
}))`
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const TitleContainer = styled.h6`
    height: 2rem;
    ${Fonts.detail3Medium};
    color: ${Palette.gray600};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ProfitReportDispensingStatusBoardSummaryItem = ({
                                                          height,
                                                          title,
                                                          value,
                                                          unit,
                                                      }: {
    height: string;
    title: string;
    value?: number;
    unit: string;
}) => {
    return (
        <LayoutContainer $height={height}>
            <TitleContainer>{title}</TitleContainer>
            <StatisticNumberLarge
                value={value}
                unit={unit}
                color={Palette.gray800}
                unitColor={Palette.gray600}
            />
        </LayoutContainer>
    );
};

export default ProfitReportDispensingStatusBoardSummaryItem;
