import {animated} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $rightMarginInPx: number }>(
    (props) => ({
        style: {
            marginRight: `${props.$rightMarginInPx}px`,
        },
    })
)`
    width: max-content;
    height: 1.8125rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const LabelContainer = styled(animated.p).attrs<{
    $color: string;
}>((props) => ({
    style: {
        color: props.$color,
    },
}))`
    ${Fonts.emphasis1};
    transition: color 0.3s ease-in-out;
`;

const UnitContainer = styled.p.attrs<{ $color: string }>((props) => ({
    style: {
        color: props.$color,
    },
}))`
    ${Fonts.detail2Medium};
    transition: color 0.3s ease-in-out;
`;

const StatisticNumberMedium = ({
                                   value,
                                   unit,
                                   color,
                                   unitColor,
                                   rightMarginInPx = 0,
                               }: {
    value?: number;
    unit: string;
    color: string;
    unitColor?: string;
    rightMarginInPx?: number;
}) => {
    const _value =
        value !== undefined
            ? NumberHelper.toStringWithDividers(value!)
            : S.statisticItem.emptyFigures;

    const {props} = useFadeInTransition(_value);

    return (
        <LayoutContainer $rightMarginInPx={rightMarginInPx}>
            <LabelContainer style={props} $color={color}>
                {_value}
            </LabelContainer>
            <UnitContainer $color={unitColor ?? color}>{unit}</UnitContainer>
        </LayoutContainer>
    );
};

export default StatisticNumberMedium;
