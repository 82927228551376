import ViewStatusType from "domain/model/common/view_status_type";
import CardSalesFigures from "domain/model/tax/card_sales/card_sales_figures";
import DispensingFigures from "domain/model/tax/profit_report/dispensing_figures";
import ProfitReportFilteredFigures from "domain/model/tax/profit_report/profit_report_filtered_figures";
import TransactionsDailyFigures from "domain/model/tax/transactions/transactions_daily_figures";
import TransactionsType from "domain/model/tax/transactions/transactions_type";
import {DashboardListenableState} from "presentation/states/dashboard/dashboard_listenable_state";
import DateTime from "presentation/utils/extension/date_extension";
import {atom} from "recoil";
import {Subject} from "rxjs";

type DashboardDispensingState = {
    status: ViewStatusType;
    startDateKey: string;
    endDateKey: string;
    data: DispensingFigures[];
};

type DashboardCardSalesState = {
    status: ViewStatusType;
    startDateKey: string;
    endDateKey: string;
    data: CardSalesFigures[];
};

type DashboardProfitState = {
    status: ViewStatusType;
    startDateKey: string;
    endDateKey: string;
    data: ProfitReportFilteredFigures[];
};

type DashboardTransactionsState = {
    type: TransactionsType;
    data: TransactionsDailyFigures[];
};

type DashboardState = {
    taxStartDateKey: string;
    selectedYearMonthKey: string;
    dispensing: DashboardDispensingState;
    cardSales: DashboardCardSalesState;
    profit: DashboardProfitState;
    transactions: DashboardTransactionsState;
};

export const dashboardListenableState = new Subject<DashboardListenableState>();

const dashboardState = atom<DashboardState>({
    key: "dashboardState",
    default: {
        taxStartDateKey: DateTime.now().key,
        selectedYearMonthKey: DateTime.now().getFirstDayInMonth().min.key,
        dispensing: {
            status: ViewStatusType.Loading,
            startDateKey: DateTime.now().key,
            endDateKey: DateTime.now().key,
            data: [],
        },
        cardSales: {
            status: ViewStatusType.Loading,
            startDateKey: DateTime.now().key,
            endDateKey: DateTime.now().key,
            data: [],
        },
        profit: {
            status: ViewStatusType.Loading,
            startDateKey: DateTime.now().key,
            endDateKey: DateTime.now().key,
            data: [],
        },
        transactions: {
            type: TransactionsType.Sales,
            data: [],
        },
    },
});

export default dashboardState;
