import DashboardRow from "presentation/pages/dashboard/components/dashboard_row";
import DashboardFiguresBillingView
    from "presentation/pages/dashboard/view/figures/billing/dashboard_figures_billing_view";
import DashboardFiguresCardView from "presentation/pages/dashboard/view/figures/card/dashboard_figures_card_view";
import DashboardFiguresCardPendingView
    from "presentation/pages/dashboard/view/figures/card_pending/dashboard_figures_card_pending_view";

const DashboardFiguresView = () => {
    return (
        <DashboardRow height={"294px"}>
            <DashboardFiguresBillingView/>
            <DashboardFiguresCardView/>
            <DashboardFiguresCardPendingView/>
        </DashboardRow>
    );
};

export default DashboardFiguresView;
