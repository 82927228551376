import ProfitReportRepository from "data/repository/profit_report_repository";
import YearDropdownButton from "presentation/components/button/year_dropdown_button";
import ContentSectionTitle from "presentation/components/content/content_section_title";
import profitReportState from "presentation/states/profit_report/profit_report_state";
import profitReportProfitYearlyChartSelector
    from "presentation/states/profit_report/selector/profit/yearly_chart/profit_report_yearly_chart_selector";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import useRepository from "presentation/utils/hooks/use_repository";
import {useRecoilValue, useSetRecoilState} from "recoil";

const ProfitReportProfitYearlyChartHeaderView = () => {
    const repository = useRepository(ProfitReportRepository);

    const {taxStartDate, selectedYear} = useRecoilValue(
        profitReportProfitYearlyChartSelector
    );
    const setState = useSetRecoilState(profitReportState);

    const onSelectedDateChange = (year: DateTime) =>
        repository({
            handler: async (repository) => {
                setState((prev) => ({
                    ...prev,
                    profit: {
                        ...prev.profit,
                        yearlyChart: {
                            ...prev.profit.yearlyChart,
                            selectedYearKey: year.key,
                        },
                    },
                }));

                const data =
                    await repository.getProfitReportMonthlyFilteredFiguresInRange(
                        {
                            startDate: selectedYear,
                            endDate: selectedYear.getLastDayInYear(),
                        }
                    );

                setState((prev) => ({
                    ...prev,
                    profit: {
                        ...prev.profit,
                        yearlyChart: {
                            ...prev.profit.yearlyChart,
                            selectedYearKey: year.key,
                            data,
                        },
                    },
                }));
            },
        });

    return (
        <ContentSectionTitle
            title={S.profitReportPage.profit.yearlyChart.title}
            suffix={
                <YearDropdownButton
                    startDate={taxStartDate}
                    selectedDate={selectedYear}
                    onSelectedDateChange={onSelectedDateChange}
                />
            }
        />
    );
};

export default ProfitReportProfitYearlyChartHeaderView;
