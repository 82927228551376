import SpacedDivider from "presentation/components/common/spaced_divider";
import StatisticNumberSmall from "presentation/components/statistic/statistic_number_small";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {Fragment, useMemo} from "react";
import styled from "styled-components";

const height = "3.5625rem";
const horizontalMargin = "8px";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const ItemLayoutContainer = styled.div.attrs<{ $height: string }>((props) => ({
    style: {
        height: props.$height,
    },
}))`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: height 0.3s ease-in-out;
`;

const LabelContainer = styled.h6`
    ${Fonts.detail2Medium};
    color: ${Palette.gray600};
    text-align: center;
`;

const DialogTotalsSummary = ({
                                 data,
                             }: {
    data: {
        label: string;
        value: number;
    }[];
}) => {
    const _data = useMemo(
        () => data.flatMap((d) => [undefined, d]).slice(1),
        [data]
    );

    return (
        <LayoutContainer>
            {_data.map((d, index) => (
                <Fragment key={index}>
                    {d !== undefined ? (
                        <ItemLayoutContainer $height={height}>
                            <LabelContainer>{d.label}</LabelContainer>
                            <StatisticNumberSmall
                                autoScaleUp={false}
                                value={d.value}
                                unit={S.transactionsTotalDetailDialog.wonUnit}
                                color={Palette.gray800}
                                unitColor={Palette.gray600}
                            />
                        </ItemLayoutContainer>
                    ) : (
                        <SpacedDivider
                            height={height}
                            horizontalMargin={horizontalMargin}
                        />
                    )}
                </Fragment>
            ))}
        </LayoutContainer>
    );
};

export default DialogTotalsSummary;
