import {ProfitReportFiguresDetailTypeMap} from "domain/model/tax/profit_report/profit_report_figures_detail_type";
import ProfitReportFiguresType, {
    ProfitReportFiguresTypeMap,
} from "domain/model/tax/profit_report/profit_report_figures_type";
import HorizontalStackedBarChart
    from "presentation/components/charts/horizontal_stacked_bar_chart/horizontal_stacked_bar_chart";
import profitReportProfitStatusBoardSelector
    from "presentation/states/profit_report/selector/profit/status_board/profit_report_status_board_selector";
import S from "presentation/theme/s";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";

const ProfitReportProfitStatusBoardOtherExpenseChartView = () => {
    const {data} = useRecoilValue(profitReportProfitStatusBoardSelector);

    const otherExpensesData = useMemo(() => {
        const otherExpensesFiguresTypes =
            ProfitReportFiguresTypeMap.profitReportFiguresDetailTypes(
                ProfitReportFiguresType.OtherExpenses
            );
        const otherExpensesData = otherExpensesFiguresTypes.map((t) => ({
            label: ProfitReportFiguresDetailTypeMap.shortLabel(t),
            value: 0,
            color: ProfitReportFiguresDetailTypeMap.color(t),
        }));
        data.forEach((d) => {
            otherExpensesFiguresTypes.forEach((_, i) => {
                otherExpensesData[i].value += d.otherExpenses[i + 1];
            });
        });

        return otherExpensesData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <HorizontalStackedBarChart
            title={
                S.profitReportPage.profit.statusBoard.horizontalStackedBarChart
                    .otherExpensesTitle
            }
            data={otherExpensesData}
        />
    );
};

export default ProfitReportProfitStatusBoardOtherExpenseChartView;
