import ContentSectionTitle from "presentation/components/content/content_section_title";
import profitReportDispensingStatusBoardSelector
    from "presentation/states/profit_report/selector/dispensing/status_board/profit_report_dispensing_status_board_date_filter_selector";
import S from "presentation/theme/s";
import {useRecoilValue} from "recoil";
import {DispensingDateFilterTypeMap} from "domain/model/tax/profit_report/dispensing_date_filter_type";

const ProfitReportDispensingStatusBoardTitleView = () => {
    const {selectedDateFilter} = useRecoilValue(
        profitReportDispensingStatusBoardSelector
    );

    const title = selectedDateFilter
        ? S.profitReportPage.dispensing.statusBoard.titleWithDateFilter(
            DispensingDateFilterTypeMap.label(selectedDateFilter)
        )
        : S.profitReportPage.dispensing.statusBoard.title;

    return <ContentSectionTitle title={title}/>;
};

export default ProfitReportDispensingStatusBoardTitleView;
