import TransactionsType, {TransactionsTypeMap,} from "domain/model/tax/transactions/transactions_type";
import OptionButton from "presentation/components/button/option_button";
import Spacer from "presentation/components/common/spacer";
import StatisticTitleLarge from "presentation/components/statistic/statistic_title_large";
import DashboardDetailButton from "presentation/pages/dashboard/components/dashboard_details_button";
import AppRouter from "presentation/routes/app_router";
import AppRoutes from "presentation/routes/model/app_routes";
import dashboardState from "presentation/states/dashboard/dashboard_state";
import dashboardTransactionsSelector from "presentation/states/dashboard/selector/dashboard_transactions_selector";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import {useMemo} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: 8px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-end;
    }
`;

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: gap 0.3s ease-in-out;

    @media (max-width: 768px) {
        gap: 4px;
    }
`;

const OptionButtonContainer = styled(OptionButton)`
    @media (max-width: 768px) {
        padding: 8px 12px;
    }
`;

const DashboardTransactionsCalendarListHeaderView = () => {
    const isMobile = useMobileQuery();

    const {type} = useRecoilValue(dashboardTransactionsSelector);
    const setState = useSetRecoilState(dashboardState);

    const buttonData = useMemo(() => {
        const types = Object.values(TransactionsType);

        return types.map((type) => {
            return {
                type,
                onClick: () =>
                    setState((prev) => ({
                        ...prev,
                        transactions: {
                            ...prev.transactions,
                            type,
                        },
                    })),
                label: isMobile
                    ? TransactionsTypeMap.shortLabel(type)
                    : TransactionsTypeMap.label(type),
            };
        });
    }, [setState, isMobile]);

    const onClick = () => {
        const route = `${AppRoutes.Transactions}?type=${type}`;
        AppRouter.navigate(route);
    };

    return (
        <LayoutContainer>
            <RowContainer>
                <StatisticTitleLarge
                    title={S.dashboardPage.transactions.headerTitle}
                    color={Palette.gray800}
                />
                <Spacer/>
                {buttonData.map((d, index) => (
                    <OptionButtonContainer
                        key={index}
                        selected={type === d.type}
                        label={d.label}
                        onClick={d.onClick}
                    />
                ))}
            </RowContainer>
            <DashboardDetailButton onClick={onClick}/>
        </LayoutContainer>
    );
};

export default DashboardTransactionsCalendarListHeaderView;
