import {animated, useTransition} from "@react-spring/web";
import SizedBox from "presentation/components/common/sized_box";
import TaxTimelineSummaryItem from "presentation/pages/tax_timeline/components/tax_timeline_summary_item";
import taxTimelineState from "presentation/states/tax_timeline/tax_timeline_state";
import DateTime from "presentation/utils/extension/date_extension";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {Fragment, useMemo, useRef} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $height: string }>((props) => ({
    style: {
        height: props.$height,
    },
}))`
    width: 100%;
    transition: height 0.3s ease-in-out;
`;

const MeasureContainer = styled.div`
    height: max-content;
    width: 100%;
`;

const OpacityContainer = styled(animated.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TaxTimelineSummaryView = () => {
    const {data} = useRecoilValue(taxTimelineState);

    const ref = useRef<HTMLDivElement>(null);

    const container = useMemo(() => {
        const now = DateTime.minNow();

        return {
            data: data
                .filter((d) => d.declarationEndDate.isSameMonth(now))
                .toSorted(
                    (a, b) =>
                        a.declarationEndDate.millisecondsSinceEpoch -
                        b.declarationEndDate.millisecondsSinceEpoch
                ),
        };
    }, [data]);

    const {height} = useResizeObserver(ref, [container]);

    const transition = useTransition(container, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
    });

    return (
        <LayoutContainer $height={height}>
            <MeasureContainer ref={ref}>
                {transition((props, c) => (
                    <OpacityContainer style={props}>
                        {c.data.map((d, index) => (
                            <Fragment key={index}>
                                <TaxTimelineSummaryItem data={d}/>
                                <SizedBox height={"24px"}/>
                            </Fragment>
                        ))}
                    </OpacityContainer>
                ))}
            </MeasureContainer>
        </LayoutContainer>
    );
};

export default TaxTimelineSummaryView;
