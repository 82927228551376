import dashboardState from "presentation/states/dashboard/dashboard_state";
import DateTime from "presentation/utils/extension/date_extension";
import {selector} from "recoil";

const dashboardDispensingSelector = selector({
    key: "dashboardDispensingSelector",
    get: ({get}) => {
        const state = get(dashboardState);

        return {
            status: state.dispensing.status,
            startDate: DateTime.fromKey(state.dispensing.startDateKey),
            endDate: DateTime.fromKey(state.dispensing.endDateKey),
            data: state.dispensing.data,
        };
    },
});

export default dashboardDispensingSelector;
