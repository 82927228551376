import {
    BillingYearlyTableContext
} from "presentation/pages/billing/view/yearly_table/components/billing_yearly_table/billing_yearly_table";
import Fonts from "presentation/theme/fonts";
import {useContext} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $widthInPx: number;
}>((props) => ({
    style: {
        minWidth: `${props.$widthInPx}px`,
    },
}))`
    height: 100%;
`;

const ColumnContainer = styled.div.attrs<{
    $widthInPx: number;
    $gapInPx: number;
    $topOffsetInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        gap: `${props.$gapInPx}px`,
        top: `${props.$topOffsetInPx}px`,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
`;

const MonthHeaderLabelLayoutContainer = styled.div.attrs<{
    $widthInPx: number;
    $heightInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: `${props.$heightInPx}px`,
    },
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const MonthHeaderLableContainer = styled.p`
    ${Fonts.detail2};
    text-align: center;
`;

const BillingYearlyTableMonthHeader = () => {
    const context = useContext(BillingYearlyTableContext);

    const widthInPx = context.constants.monthHeaderWidthInPx;
    const topOffsetInPx =
        context.constants.secondaryHeaderCellHeightInPx +
        context.constants.primaryHeaderCellHeightInPx +
        context.constants.verticalGapInPx * 2;

    return (
        <LayoutContainer $widthInPx={widthInPx}>
            <ColumnContainer
                $widthInPx={widthInPx}
                $gapInPx={context.constants.verticalGapInPx}
                $topOffsetInPx={topOffsetInPx}
            >
                {context.monthHeaderLabels.map((l) => (
                    <MonthHeaderLabelLayoutContainer
                        key={l}
                        $widthInPx={widthInPx}
                        $heightInPx={context.constants.cellHeightInPx}
                    >
                        <MonthHeaderLableContainer>
                            {l}
                        </MonthHeaderLableContainer>
                    </MonthHeaderLabelLayoutContainer>
                ))}
            </ColumnContainer>
        </LayoutContainer>
    );
};

export default BillingYearlyTableMonthHeader;
