import Fonts from "presentation/theme/fonts";
import {PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    max-width: 460.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
`;

const TitleContainer = styled.h6`
    width: 100%;
    ${Fonts.detail3};
    text-align: center;
`;

const ProfitReportYearlyChartColumn = ({
                                           title,
                                           children,
                                       }: PropsWithChildren<{
    title: string;
}>) => {
    return (
        <LayoutContainer>
            <TitleContainer>{title}</TitleContainer>
            {children}
        </LayoutContainer>
    );
};

export default ProfitReportYearlyChartColumn;
