import SyncHomeTaxDialog from "presentation/components/dialog/sync_home_tax_dialog/sync_home_tax_dialog";
import ExternalSyncsContent from "presentation/pages/external_syncs/components/external_syncs_content";
import {ExternalSyncsRefresh} from "presentation/states/external_syncs/external_syncs_listenable_state";
import {externalSyncsListenableState} from "presentation/states/external_syncs/external_syncs_state";
import externalSyncsHomeTaxSelector from "presentation/states/external_syncs/selector/external_syncs_home_tax_selector";
import S from "presentation/theme/s";
import {useSetListenableState} from "presentation/utils/hooks/use_set_listenable_state";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import {useRecoilValue} from "recoil";

const ExternalSyncsHomeTaxView = () => {
    const showDialog = useShowDialog();
    const setListenableState = useSetListenableState(
        externalSyncsListenableState
    );

    const homeTax = useRecoilValue(externalSyncsHomeTaxSelector);

    const synced = !!homeTax;
    const syncedDescription = homeTax?.label ?? "";

    const onClick = () =>
        showDialog({
            className: "home-tax-sync-dialog",
            component: <SyncHomeTaxDialog/>,
            onDismissed: () => setListenableState(new ExternalSyncsRefresh()),
            dismissible: false,
        });

    return (
        <ExternalSyncsContent
            title={S.externalSyncsPage.homeTax.title}
            description={S.externalSyncsPage.homeTax.description}
            synced={synced}
            unsyncedDescription={S.externalSyncsPage.passwordBasedUnsyncedLabel}
            syncedDescription={syncedDescription}
            onClick={onClick}
        />
    );
};

export default ExternalSyncsHomeTaxView;
