import NumberHelper from "config/helper/number_helper";
import TaxTimeline from "domain/model/tax/tax_timeline/tax_timeline";
import Fonts, {FontWeights} from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    height: max-content;
    padding: 24px 36px;
    border-radius: 20px;
    background-color: ${Palette.white100};
    box-shadow: 0 3px 15px 0 rgba(199, 199, 199, 0.25);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;

    @media (max-width: 768px) {
        padding: 16px;
    }
`;

const RowContainer = styled.div`
    height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const TitleContainer = styled.h4`
    ${Fonts.title3};
    color: ${Palette.black100};
    word-break: keep-all;
    white-space: pre-wrap;
`;

const TitleAccentContainer = styled(TitleContainer)`
    color: ${Palette.primary500};
`;

const LabelContainer = styled.div`
    ${Fonts.body1Medium};
    word-break: keep-all;
    white-space: pre-wrap;
`;

const LabelBoldContainer = styled(LabelContainer)`
    font-weight: ${FontWeights.bold};
`;

const LabelAccentContainer = styled(LabelBoldContainer)`
    color: ${Palette.primary500};
`;

const TaxTimelineSummaryItem = ({data}: { data: TaxTimeline }) => {
    return (
        <LayoutContainer>
            <TitleContainer>
                <RowContainer>
                    {S.taxTimelineSummaryItem.titleOne(
                        data.declarationEndDate.format(
                            S.taxTimelineSummaryItem.titleDateFormat
                        )
                    )}
                    <TitleAccentContainer>
                        {S.taxTimelineSummaryItem.titleTwo(data.description)}
                    </TitleAccentContainer>
                    {S.taxTimelineSummaryItem.titleThree}
                </RowContainer>
            </TitleContainer>
            <LabelContainer>
                <RowContainer>
                    {S.taxTimelineSummaryItem.declarationLabelOne}
                    <LabelBoldContainer>
                        {S.taxTimelineSummaryItem.declarationLabelTwo(
                            data.declarationStartDate.format(
                                S.taxTimelineSummaryItem.declarationDateFormat
                            ),
                            data.declarationEndDate.format(
                                S.taxTimelineSummaryItem.declarationDateFormat
                            )
                        )}
                    </LabelBoldContainer>
                    {S.taxTimelineSummaryItem.declarationLabelThree}
                </RowContainer>
            </LabelContainer>
            {data.lastPaidAmount !== undefined && (
                <LabelContainer>
                    <RowContainer>
                        {S.taxTimelineSummaryItem.lastPaidLabelOne(
                            data.declarationEndDate
                                .copyWith({
                                    year: data.declarationEndDate.year - 1,
                                })
                                .format(
                                    S.taxTimelineSummaryItem
                                        .lastPaidLabelDateFormat
                                )
                        )}
                        <LabelAccentContainer>
                            {S.taxTimelineSummaryItem.lastPaidLabelTwo(
                                data.description
                            )}
                        </LabelAccentContainer>
                        {S.taxTimelineSummaryItem.lastPaidLabelThree}
                        <LabelBoldContainer>
                            {S.taxTimelineSummaryItem.lastPaidLabelFour(
                                NumberHelper.toStringWithDividers(
                                    data.lastPaidAmount
                                )
                            )}
                        </LabelBoldContainer>
                        {S.taxTimelineSummaryItem.lastPaidLabelFive}
                    </RowContainer>
                </LabelContainer>
            )}
        </LayoutContainer>
    );
};

export default TaxTimelineSummaryItem;
