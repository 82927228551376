import SizedBox from "presentation/components/common/sized_box";
import ContentBox from "presentation/components/content/content_box";
import ContentLayout from "presentation/components/content/content_layout";
import ProfitReportProfitYearlyChartHeaderView
    from "presentation/pages/profit_report/view/profit/yearly_chart/profit_report_profit_yearly_chart_header_view";
import ProfitReportProfitYearlyChartOtherExpensesView
    from "presentation/pages/profit_report/view/profit/yearly_chart/profit_report_profit_yearly_chart_other_expenses_view";
import ProfitReportProfitYearlyChartSalesView
    from "presentation/pages/profit_report/view/profit/yearly_chart/profit_report_profit_yearly_chart_sales_view";
import profitReportProfitYearlyChartSelector
    from "presentation/states/profit_report/selector/profit/yearly_chart/profit_report_yearly_chart_selector";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {useRef} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @media (max-width: 1279px) {
        flex-direction: column;
        gap: 48px;
    }
`;

const ProfitReportProfitYearlyChartView = () => {
    const {data} = useRecoilValue(profitReportProfitYearlyChartSelector);

    const layoutRef = useRef<HTMLDivElement>(null);

    const {height: contentHeight} = useResizeObserver(layoutRef, [data]);

    return (
        <ContentBox contentHeight={contentHeight}>
            <ContentLayout ref={layoutRef}>
                <ProfitReportProfitYearlyChartHeaderView/>
                <SizedBox height={"24px"}/>
                <RowContainer>
                    <ProfitReportProfitYearlyChartSalesView/>
                    <ProfitReportProfitYearlyChartOtherExpensesView/>
                </RowContainer>
            </ContentLayout>
        </ContentBox>
    );
};

export default ProfitReportProfitYearlyChartView;
