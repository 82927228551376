import {
    TransactionsCalendarContext
} from "presentation/components/calendar/transactions_calendar/transactions_calendar";
import Spacer from "presentation/components/common/spacer";
import StatisticNumberMedium from "presentation/components/statistic/statistic_number_medium";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useContext} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $heightInPx: number }>((props) => ({
    style: {
        minHeight: `${props.$heightInPx}px`,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: min-height 0.3s ease-in-out;
`;

const TitleContainer = styled.h6`
    ${Fonts.detail3Medium};
    color: ${Palette.gray600};
`;

const TransactionsCalendarFiguresHeader = () => {
    const context = useContext(TransactionsCalendarContext);

    return (
        <LayoutContainer $heightInPx={context.figuresHeaderHeightInPx}>
            <TitleContainer>{context.figuresHeaderTitle}</TitleContainer>
            <Spacer/>
            <StatisticNumberMedium
                value={context.figuresHeaderValue}
                unit={S.transactionsCalendar.figuresHeaderUnit}
                color={Palette.gray800}
                unitColor={Palette.gray600}
            />
        </LayoutContainer>
    );
};

export default TransactionsCalendarFiguresHeader;
