import {nullable} from "presentation/utils/types/optional";
import {cloneElement, MutableRefObject, ReactElement, useMemo} from "react";

const SVG = ({
                 ref,
                 className,
                 asset,
                 width = "auto",
                 height = "auto",
                 color,
                 fit = "contain",
             }: {
    ref?: MutableRefObject<nullable<HTMLImageElement>>;
    className?: string;
    asset: ReactElement;
    width?: string;
    height?: string;
    color?: string;
    fit?: string;
}) => {
    return useMemo(() => {
        const style = {
            width,
            height,
            minWidth: width,
            minHeight: height,
            color,
            objectFit: fit,
            transition:
                "color 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, min-width 0.3s ease-in-out, min-height 0.3s ease-in-out",
        };

        return cloneElement(asset, {
            ref,
            className,
            style,
        });
    }, [ref, className, asset, width, height, color, fit]);
};

export default SVG;
