import Fonts from "presentation/theme/fonts";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const TitleContainer = styled.h6.attrs<{ $color: string }>((props) => ({
    style: {
        color: props.$color,
    },
}))`
    ${Fonts.body1};
    transition: color 0.3s ease-in-out;
`;

const StatisticTitleLarge = ({
                                 title,
                                 color,
                             }: {
    title: string;
    color: string;
}) => {
    return (
        <LayoutContainer>
            <TitleContainer $color={color}>{title}</TitleContainer>
        </LayoutContainer>
    );
};

export default StatisticTitleLarge;
