import SVG from "presentation/components/common/svg";
import CardSalesStatusBoardSummaryItem
    from "presentation/pages/card_sales/view/status_board/components/card_sales_status_board_summary_item";
import cardSalesStatusBoardSelector from "presentation/states/card_sales/selector/card_sales_status_board_selector";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
`;

const CardSalesStatusBoardReceiveSummaryView = () => {
    const {data} = useRecoilValue(cardSalesStatusBoardSelector);

    const {receive, purchases, purchasesCount, transactionsFee} =
        useMemo(() => {
            let receive = 0;
            let purchases = 0;
            let purchasesCount = 0;
            let transactionsFee = 0;

            data.forEach((d) => {
                receive += d.receiveAmount;
                purchases += d.purchasesAmount;
                purchasesCount += d.purchasesCount;
                transactionsFee += d.transactionsFee;
            });

            return {
                receive: data.length ? receive : undefined,
                purchases: data.length ? purchases : undefined,
                purchasesCount: data.length ? purchasesCount : undefined,
                transactionsFee: data.length ? transactionsFee : undefined,
            };
        }, [data]);

    return (
        <LayoutContainer>
            <CardSalesStatusBoardSummaryItem
                color={Palette.gray700}
                title={S.cardSalesPage.statusBoard.receive.receiveLabel}
                hideCount={true}
                value={receive}
            />
            <CardSalesStatusBoardSummaryItem
                title={S.cardSalesPage.statusBoard.receive.purchasesLabel}
                hideCount={false}
                count={purchasesCount}
                value={purchases}
                icon={
                    <SVG
                        asset={SVGAssets.EqualCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
            />
            <CardSalesStatusBoardSummaryItem
                title={S.cardSalesPage.statusBoard.receive.transactionsFeeLabel}
                hideCount={true}
                value={transactionsFee}
                icon={
                    <SVG
                        asset={SVGAssets.MinusCircle}
                        width={"24px"}
                        height={"24px"}
                        color={Palette.gray500}
                    />
                }
            />
        </LayoutContainer>
    );
};

export default CardSalesStatusBoardReceiveSummaryView;
