import {animated, useTransition} from "@react-spring/web";
import FocusTrap from "focus-trap-react";
import Loading from "presentation/components/common/loading";
import uiLoadingStateSelector from "presentation/states/ui/selector/ui_loading_state_selector";
import Palette from "presentation/theme/palette";
import {useRecoilValue} from "recoil";
import styled from "styled-components";

const FocusTrapButtonContainer = styled.button`
    width: 0px;
    height: 0px;
    background-color: transparent;
    border: none;
    position: absolute;
`;

const LayoutContainer = styled(animated.div)`
    position: fixed;
    inset: 0;
    background-color: ${Palette.none};
`;

const LoadingProvider = () => {
    const loading = useRecoilValue(uiLoadingStateSelector);

    const transition = useTransition(loading, {
        from: {
            visibility: "hidden" as "hidden" | "visible",
            opacity: 0,
        },
        enter: {
            visibility: "visible",
            opacity: 1,
        },
        leave: {
            visibility: "hidden",
            opacity: 0,
        },
    });

    return (
        <FocusTrap
            active={loading}
            focusTrapOptions={{
                escapeDeactivates: false,
            }}
        >
            <div>
                <FocusTrapButtonContainer/>
                {transition((props, loading) =>
                    loading ? (
                        <LayoutContainer style={props}>
                            <Loading visible={false} showLabel={false}/>
                        </LayoutContainer>
                    ) : null
                )}
            </div>
        </FocusTrap>
    );
};

export default LoadingProvider;
