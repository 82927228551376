import S from "presentation/theme/s";

enum DonutChartType {
    Sales = "sales",
    Profit = "profit",
}

export const DonutChartTypeMap = {
    label: (type: DonutChartType): string => {
        switch (type) {
            case DonutChartType.Sales:
                return S.donutChartType.salesLabel;
            case DonutChartType.Profit:
                return S.donutChartType.profitLabel;
            default:
                throw new Error("Invalid DonutChartType");
        }
    },
    previous: (type: DonutChartType): DonutChartType => {
        switch (type) {
            case DonutChartType.Sales:
                return DonutChartType.Profit;
            case DonutChartType.Profit:
                return DonutChartType.Sales;
            default:
                throw new Error("Invalid DonutChartType");
        }
    },
    next: (type: DonutChartType): DonutChartType => {
        switch (type) {
            case DonutChartType.Sales:
                return DonutChartType.Profit;
            case DonutChartType.Profit:
                return DonutChartType.Sales;
            default:
                throw new Error("Invalid DonutChartType");
        }
    },
};

export default DonutChartType;
