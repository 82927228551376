import styled from "styled-components";

const LayoutContainer = styled.div<{ $color: string }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.$color};
`;

const Dot = ({className, color}: { className?: string; color: string }) => {
    return <LayoutContainer className={className} $color={color}/>;
};

export default Dot;
