import {TransactionsTypeMap} from "domain/model/tax/transactions/transactions_type";
import ContentSectionTitle from "presentation/components/content/content_section_title";
import {TransactionsPageContext} from "presentation/pages/transactions/transactions_page";
import S from "presentation/theme/s";
import {useContext} from "react";

const TransactionsCalendarListTitleView = () => {
    const {transactionsType} = useContext(TransactionsPageContext);

    return (
        <ContentSectionTitle
            title={S.transactionsPage.calendarList.title(
                TransactionsTypeMap.transactionsPageCalendarLabel(
                    transactionsType!
                )
            )}
        />
    );
};

export default TransactionsCalendarListTitleView;
