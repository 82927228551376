import {animated, useTransition} from "@react-spring/web";
import SVG from "presentation/components/common/svg";
import PaginationSelectionButton from "presentation/components/pagination/components/pagination_selection_button";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {useEffect, useMemo, useState} from "react";
import styled from "styled-components";

const pageGroupSize = 10;
const selectionButtonSizeInPx = 28;
const iconButtonSizeInPx = 24;
const gapInPx = 12;

const LayoutContainer = styled.div.attrs<{
    $widthInPx: number;
    $height: string;
    $gapInPx: number;
}>((props) => ({
    style: {
        width: `${props.$widthInPx}px`,
        height: props.$height,
        gap: `${props.$gapInPx}px`,
    },
}))`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
        gap 0.3s ease-in-out;
`;

const RelativeContainer = styled.div`
    flex-grow: 1;
    height: 100%;
    position: relative;
    overflow: hidden;
`;

const ButtonsContainer = styled(animated.div).attrs<{
    $height: string;
    $leftOffsetInPx: number;
}>((props) => ({
    style: {
        height: props.$height,
        left: `${props.$leftOffsetInPx}px`,
    },
}))`
    width: max-content;
    background-color: ${Palette.white100};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    transition: height 0.3s ease-in-out, left 0.3s ease-in-out;
`;

const IconButtonContainer = styled.button.attrs<{ $visible: boolean }>(
    (props) => ({
        style: {
            opacity: props.$visible ? 1 : 0,
            pointerEvents: props.$visible ? "auto" : "none",
        },
    })
)`
    border: none;
    background-color: ${Palette.none};
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
`;

const Pagination = ({
                        maxPage,
                        page,
                        onPageChange,
                    }: {
    maxPage: number;
    page: number;
    onPageChange: (page: number) => void;
}) => {
    const theme = useThemeContext();

    const {widthInPx, innerWidthInPx} = useMemo(() => {
        const count = Math.min(maxPage, pageGroupSize);
        const widthInPx =
            count * selectionButtonSizeInPx +
            gapInPx * (count + 1) +
            iconButtonSizeInPx * 2;
        const innerWidthInPx =
            count * selectionButtonSizeInPx + gapInPx * pageGroupSize;

        return {
            widthInPx,
            innerWidthInPx,
        };
    }, [maxPage]);

    const getPageGroup = (page: number) =>
        Math.floor(Math.max(page - 1, 0) / pageGroupSize);
    const getPages = (pageGroup: number) =>
        Array.from({length: pageGroupSize}, (_, i) => {
            const p = pageGroup * pageGroupSize + i + 1;
            return p > maxPage ? undefined : p;
        })
            .filter((p) => p !== undefined)
            .map((p) => p!);

    const [state, setState] = useState({
        pageGroup: getPageGroup(page),
        caller: "static",
    });

    useEffect(() => {
        onPageChange(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxPage]);

    useEffect(() => {
        setState((prev) => {
            const pageGroup = getPageGroup(page);
            const caller =
                prev.pageGroup === pageGroup
                    ? "static"
                    : prev.pageGroup < pageGroup
                        ? "next"
                        : "previous";

            return {
                pageGroup,
                caller,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const transition = useTransition(state.pageGroup, {
        from: {
            translateX:
                state.caller === "static"
                    ? "0px"
                    : state.caller === "previous"
                        ? `-${innerWidthInPx}px`
                        : `${innerWidthInPx}px`,
            zIndex: 10,
        },
        enter: {translateX: "0px", zIndex: 10},
        leave: {
            translateX:
                state.caller === "static"
                    ? "0px"
                    : state.caller === "previous"
                        ? `${innerWidthInPx}px`
                        : `-${innerWidthInPx}px`,
            zIndex: 9,
        },
    });

    const iconButtonSize = `${iconButtonSizeInPx}px`;
    const maxPageGroup = getPageGroup(maxPage);
    const selectionButtonHorizontalMarginInPx = gapInPx / 2;
    const buttonsContainerLeftOffsetInPx = -selectionButtonHorizontalMarginInPx;

    const previousButtonVisible = state.pageGroup > 0;
    const nextButtonVisible = state.pageGroup < maxPageGroup;

    const onPreviousButtonClick = () =>
        onPageChange(getPages(state.pageGroup).at(0)! - 1);
    const onNextButtonClick = () =>
        onPageChange(getPages(state.pageGroup).at(-1)! + 1);

    const onClick = (page: number) => () => onPageChange(page);

    return (
        <LayoutContainer
            $widthInPx={widthInPx}
            $height={theme.componentTheme.paginationHeight}
            $gapInPx={gapInPx}
        >
            <IconButtonContainer
                onClick={onPreviousButtonClick}
                $visible={previousButtonVisible}
            >
                <SVG
                    asset={SVGAssets.Back}
                    width={iconButtonSize}
                    height={iconButtonSize}
                    color={Palette.gray800}
                />
            </IconButtonContainer>
            <RelativeContainer>
                {transition((props, pageGroup) => (
                    <ButtonsContainer
                        style={props}
                        $height={theme.componentTheme.paginationHeight}
                        $leftOffsetInPx={buttonsContainerLeftOffsetInPx}
                    >
                        {getPages(pageGroup).map((p) => (
                            <PaginationSelectionButton
                                key={p}
                                selected={p === page}
                                sizeInPx={selectionButtonSizeInPx}
                                horizontalMarginInPx={
                                    selectionButtonHorizontalMarginInPx
                                }
                                page={p}
                                onClick={onClick(p)}
                            />
                        ))}
                    </ButtonsContainer>
                ))}
            </RelativeContainer>
            <IconButtonContainer
                onClick={onNextButtonClick}
                $visible={nextButtonVisible}
            >
                <SVG
                    asset={SVGAssets.Next}
                    width={iconButtonSize}
                    height={iconButtonSize}
                    color={Palette.gray800}
                />
            </IconButtonContainer>
        </LayoutContainer>
    );
};

export default Pagination;
