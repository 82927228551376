import ProfitReportRepository from "data/repository/profit_report_repository";
import UserRepository from "data/repository/user_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import ProfitReportDateFilterType, {
    ProfitReportDateFilterTypeMap,
} from "domain/model/tax/profit_report/profit_report_date_filter_type";
import Page from "presentation/components/common/page";
import SizedBox from "presentation/components/common/sized_box";
import ProfitReportProfitStatusBoardView
    from "presentation/pages/profit_report/view/profit/status_board/profit_report_profit_status_board_view";
import ProfitReportProfitYearlyChartView
    from "presentation/pages/profit_report/view/profit/yearly_chart/profit_report_profit_yearly_chart_view";
import ProfitReportProfitYearlyTableView
    from "presentation/pages/profit_report/view/profit/yearly_table/profit_report_profit_yearly_table_view";
import profitReportState from "presentation/states/profit_report/profit_report_state";
import DateTime from "presentation/utils/extension/date_extension";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import {useState} from "react";
import {useSetRecoilState} from "recoil";

const ProfitReportProfitView = () => {
    const userRepository = useRepository(UserRepository);
    const profitReportRepository = useRepository(ProfitReportRepository);

    const setState = useSetRecoilState(profitReportState);

    const [status, setStatus] = useState(ViewStatusType.Loading);

    useLifeCycle({
        onInit: async () => {
            const taxStartDate =
                (await userRepository({
                    handler: async (repository) => {
                        return await repository.getTaxStartDate();
                    },
                })) ?? DateTime.minNow();

            await profitReportRepository({
                handler: async (repository) => {
                    const now = DateTime.now();
                    const statusBoardDateFilter =
                        ProfitReportDateFilterType.RecentSixMonths;
                    const statusBoardStartDate =
                        ProfitReportDateFilterTypeMap.datePeriod(
                            statusBoardDateFilter
                        ).startDate;
                    const statusBoardEndDate =
                        ProfitReportDateFilterTypeMap.datePeriod(
                            statusBoardDateFilter
                        ).endDate;

                    const statusBoardData =
                        await repository.getProfitReportMonthlyFilteredFiguresInRange(
                            {
                                startDate: statusBoardStartDate,
                                endDate: statusBoardEndDate,
                            }
                        );

                    const yearlyTableYear = now.getFirstDayInYear();

                    const yearlyTableData =
                        await repository.getProfitReportMonthlyFilteredFiguresInRange(
                            {
                                startDate: yearlyTableYear,
                                endDate: yearlyTableYear.getLastDayInYear(),
                            }
                        );

                    const yearlyChartYear = now.getFirstDayInYear();

                    const yearlyChartData =
                        await repository.getProfitReportMonthlyFilteredFiguresInRange(
                            {
                                startDate: yearlyChartYear,
                                endDate: yearlyChartYear.getLastDayInYear(),
                            }
                        );

                    setState((prev) => ({
                        ...prev,
                        taxStartDateKey: taxStartDate.key,
                        profit: {
                            statusBoard: {
                                ...prev.profit.statusBoard,
                                selectedDateFilter: statusBoardDateFilter,
                                startDateKey: statusBoardStartDate.key,
                                endDateKey: statusBoardEndDate.key,
                                data: statusBoardData,
                            },
                            yearlyTable: {
                                ...prev.profit.yearlyTable,
                                selectedYearKey: yearlyTableYear.key,
                                data: yearlyTableData,
                            },
                            yearlyChart: {
                                ...prev.profit.yearlyChart,
                                selectedYearKey: yearlyChartYear.key,
                                data: yearlyChartData,
                            },
                        },
                    }));

                    setStatus(ViewStatusType.Loaded);
                },
            });
        },
    });

    return (
        <Page status={status}>
            <ProfitReportProfitStatusBoardView status={status}/>
            <SizedBox height={"24px"}/>
            <ProfitReportProfitYearlyTableView status={status}/>
            <SizedBox height={"24px"}/>
            <ProfitReportProfitYearlyChartView/>
        </Page>
    );
};

export default ProfitReportProfitView;
