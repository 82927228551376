import Palette from "presentation/theme/palette";
import {forwardRef, PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $maxWidth?: string;
    $contentHeight: string;
}>((props) => ({
    style: {
        width: "100%",
        maxWidth: props.$maxWidth !== undefined ? props.$maxWidth : "100%",
        height: `max(auto, calc(${props.$contentHeight} + 64px))`,
    },
}))`
    border-top: 32px solid ${Palette.white100};
    border-bottom: 32px solid ${Palette.white100};
    border-left: 36px solid ${Palette.white100};
    border-right: 36px solid ${Palette.white100};
    box-sizing: border-box;
    box-shadow: 0px 3px 15px 0px rgba(199, 199, 199, 0.25);
    border-radius: 16px;
    background-color: ${Palette.white100};
    position: relative;
    overflow: visible;
    transition: height 0.3s ease-in-out, max-height 0.3s ease-in-out,
        border 0.3s ease-in-out;

    @media (max-width: 768px) {
        border: 16px solid ${Palette.white100};
    }
`;

const ContentBox = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{
        maxWidth?: string;
        contentHeight: string;
    }>
>(({maxWidth, contentHeight, children}, ref) => {
    return (
        <LayoutContainer
            ref={ref}
            $maxWidth={maxWidth}
            $contentHeight={contentHeight}
        >
            {children}
        </LayoutContainer>
    );
});

export default ContentBox;
