import SortType from "domain/model/common/sort_type";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{ $width: string; $height: string }>(
    (props) => ({
        style: {
            width: props.$width,
            height: props.$height,
        },
    })
)`
    position: relative;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
`;

const PositionContainer = styled.div`
    position: absolute;
    inset: 0;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
`;

const SortIcon = ({
                      width,
                      height,
                      sortType,
                      enabledColor = Palette.gray400,
                      disabledColor = Palette.black8,
                  }: {
    width: string;
    height: string;
    sortType: SortType;
    enabledColor?: string;
    disabledColor?: string;
}) => {
    const upperColor =
        sortType === SortType.Descending ? disabledColor : enabledColor;
    const lowerColor =
        sortType === SortType.Ascending ? disabledColor : enabledColor;

    return (
        <LayoutContainer $width={width} $height={height}>
            <PositionContainer>
                <SVG
                    asset={SVGAssets.Ascending}
                    width={width}
                    height={height}
                    color={upperColor}
                />
            </PositionContainer>
            <PositionContainer>
                <SVG
                    asset={SVGAssets.Descending}
                    width={width}
                    height={height}
                    color={lowerColor}
                />
            </PositionContainer>
        </LayoutContainer>
    );
};

export default SortIcon;
