import NumberHelper from "config/helper/number_helper";
import {TransactionsFiguresTypeMap} from "domain/model/tax/transactions/transactions_figures_type";
import TransactionsType, {TransactionsTypeMap,} from "domain/model/tax/transactions/transactions_type";
import TransactionsCalendar from "presentation/components/calendar/transactions_calendar/transactions_calendar";
import {DashboardRowContext} from "presentation/pages/dashboard/components/dashboard_row";
import CalendarBox from "presentation/components/calendar/common/calendar_box";
import {
    DashboardTransactionsViewContext
} from "presentation/pages/dashboard/view/transactions/dashboard_transactions_view";
import {dashboardListenableState} from "presentation/states/dashboard/dashboard_state";
import {DashboardListScrollToTop} from "presentation/states/dashboard/dashboard_listenable_state";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";
import dashboardTransactionsSelector from "presentation/states/dashboard/selector/dashboard_transactions_selector";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {useSetListenableState} from "presentation/utils/hooks/use_set_listenable_state";
import {optional} from "presentation/utils/types/optional";
import {useContext, useMemo} from "react";
import {useRecoilValue} from "recoil";

const DashboardTransactionsCalendarView = () => {
    const {contentWidth, contentHeight} = useContext(DashboardRowContext);
    const {queryYearMonth, setQueryYearMonth, calendarConstants} = useContext(
        DashboardTransactionsViewContext
    );
    const setListenableState = useSetListenableState(dashboardListenableState);

    const {taxStartDate} = useRecoilValue(dashboardDateSelector);
    const {type, data} = useRecoilValue(dashboardTransactionsSelector);

    const startDate = taxStartDate;
    const endDate = DateTime.minNow();

    const {totalMap, suffixMap} = useMemo(() => {
        const types = Object.values(TransactionsType);
        const monthStartDate = DateTime.max(startDate, taxStartDate);
        const monthEndDate = DateTime.min(
            endDate,
            queryYearMonth.getLastDayInMonth()
        );

        const rangedData = data.filter((d) =>
            d.date.isInRange(monthStartDate, monthEndDate)
        );
        const totalData = rangedData.map((d) => {
            const figures: Record<string, number> = {};

            types.forEach((t) => {
                figures[t] = d.figures
                    .filter(
                        (f) =>
                            TransactionsFiguresTypeMap.transactionsType(
                                f.type
                            ) === t
                    )
                    .map((f) => f.value)
                    .reduce((acc, cur) => acc + cur, 0);
            });

            return {
                date: d.date,
                figures,
            };
        });

        const totalMap: Record<string, optional<number>> = {};
        types.forEach((t) => {
            const total = totalData
                .map((d) => d.figures[t] ?? 0)
                .reduce((acc, cur) => acc + cur, 0);

            totalMap[t] = total === 0 ? undefined : total;
        });

        const suffixMap: Record<string, Record<string, optional<string>>> = {};
        types.forEach((t) => {
            suffixMap[t] = {};
            totalData.forEach((d) => {
                suffixMap[t][d.date.key] =
                    d.figures[t] !== undefined
                        ? NumberHelper.toCalendarAbbreviatedString(d.figures[t])
                        : undefined;
            });
        });

        return {
            totalMap,
            suffixMap,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, startDate.key, endDate.key]);

    const onFiguresTodayButtonClick = () =>
        setListenableState(new DashboardListScrollToTop());

    return (
        <CalendarBox
            width={contentWidth!}
            height={contentHeight!}
            paddingInPx={calendarConstants.marginInPx}
        >
            <TransactionsCalendar
                constants={calendarConstants}
                figuresHeaderTitle={S.dashboardPage.transactions.figuresHeaderTitle(
                    TransactionsTypeMap.shortLabel(type)
                )}
                figuresHeaderValue={totalMap[type]}
                onFiguresTodayButtonClick={onFiguresTodayButtonClick}
                startDate={startDate}
                endDate={endDate}
                initialDate={queryYearMonth}
                suffixMap={suffixMap[type]}
                onYearMonthChange={setQueryYearMonth}
            />
        </CalendarBox>
    );
};

export default DashboardTransactionsCalendarView;
