import externalSyncsState from "presentation/states/external_syncs/external_syncs_state";
import {selector} from "recoil";

const externalSyncsCrefiaSelector = selector({
    key: "externalSyncsCrefiaSelector",
    get: ({get}) => {
        const state = get(externalSyncsState);

        return state.crefia;
    },
});

export default externalSyncsCrefiaSelector;
