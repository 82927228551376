import SortType from "domain/model/common/sort_type";
import BillingDateFilterType from "domain/model/tax/billing/billing_date_filter_type";
import BillingFigures from "domain/model/tax/billing/billing_figures";
import BillingFiguresStatusDropdownType from "domain/model/tax/billing/billing_figures_status_dropdown_type";
import BillingMonthlyFigures from "domain/model/tax/billing/billing_monthly_figures";
import {BillingReplayState} from "presentation/states/billing/billing_replay_state";
import DateTime from "presentation/utils/extension/date_extension";
import {optional} from "presentation/utils/types/optional";
import {atom} from "recoil";
import {BehaviorSubject} from "rxjs";

type BillingStatusBoard = {
    selectedDateFilter?: BillingDateFilterType;
    startDateKey: string;
    endDateKey: string;
    selectedDropdownType: BillingFiguresStatusDropdownType;
    dateSortType: SortType;
    billingAmountSortType: SortType;
    data: BillingFigures[];
};

type BillingYearlyTable = {
    selectedYearKey: string;
    data: BillingMonthlyFigures[];
};

type BillingState = {
    taxStartDateKey: string;
    statusBoard: BillingStatusBoard;
    yearlyTable: BillingYearlyTable;
};

export const billingReplayState = new BehaviorSubject<
    optional<BillingReplayState>
>(undefined);

const billingState = atom<BillingState>({
    key: "billingState",
    default: {
        taxStartDateKey: DateTime.minNow().key,
        statusBoard: {
            selectedDateFilter: BillingDateFilterType.ThisMonth,
            startDateKey: DateTime.minNow().getFirstDayInMonth().key,
            endDateKey: DateTime.minNow().key,
            selectedDropdownType: BillingFiguresStatusDropdownType.All,
            dateSortType: SortType.None,
            billingAmountSortType: SortType.None,
            data: [],
        },
        yearlyTable: {
            selectedYearKey: DateTime.minNow().getFirstDayInYear().key,
            data: [],
        },
    },
});

export default billingState;
