import ProfitReportType, {ProfitReportTypeMap,} from "domain/model/tax/profit_report/profit_report_type";
import TabBar from "presentation/components/tab_bar/tab_bar";
import TabBarItem from "presentation/components/tab_bar/tab_bar_item";
import ProfitReportDispensingView from "presentation/pages/profit_report/view/dispensing/profit_report_dispensing_view";
import ProfitReportProfitView from "presentation/pages/profit_report/view/profit/profit_report_profit_view";
import AppRoutes from "presentation/routes/model/app_routes";
import {ProfitReportScrollToElement} from "presentation/states/profit_report/profit_report_replay_state";
import {profitReportReplayState} from "presentation/states/profit_report/profit_report_state";
import {changePathWithoutRedirect} from "presentation/utils/functions/change_path_without_redirect";
import {useSearchParams} from "presentation/utils/hooks/use_search_params";
import {useSetReplayState} from "presentation/utils/hooks/use_set_replay_state";
import {optional} from "presentation/utils/types/optional";
import {useLayoutEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";

const ProfitReportPage = () => {
    const {pathname, search} = useLocation();
    const searchParams = useSearchParams();
    const setReplayState = useSetReplayState(profitReportReplayState);

    const [selectedType, setSelectedType] =
        useState<optional<ProfitReportType>>(undefined);
    const types = useMemo(() => Object.values(ProfitReportType), []);

    const onClick = (type: ProfitReportType) => () => {
        setSelectedType(type);
        changePathWithoutRedirect(AppRoutes.ProfitReport + "?type=" + type);
    };

    useLayoutEffect(() => {
        const searchSelectedType = searchParams("type");
        const type =
            types.find((t) => t === searchSelectedType) ??
            ProfitReportType.Profit;
        setSelectedType(type);

        const element = searchParams("scrollTo");
        if (element) {
            setReplayState(new ProfitReportScrollToElement({element}));
        }

        const path = `${AppRoutes.ProfitReport}?type=${type}`;
        changePathWithoutRedirect(path, {
            replace: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, search]);

    const view = useMemo(() => {
        switch (selectedType) {
            case ProfitReportType.Profit:
                return <ProfitReportProfitView/>;
            case ProfitReportType.Dispensing:
                return <ProfitReportDispensingView/>;
            default:
                return null;
        }
    }, [selectedType]);

    const label = (type: ProfitReportType) => ProfitReportTypeMap.label(type);

    return (
        <>
            <TabBar>
                {types.map((type) => (
                    <TabBarItem
                        key={type}
                        selected={type === selectedType}
                        label={label(type)}
                        onClick={onClick(type)}
                    />
                ))}
            </TabBar>
            {view}
        </>
    );
};

export default ProfitReportPage;
