import NumberHelper from "config/helper/number_helper";
import {ProfitReportFiguresDetailTypeMap} from "domain/model/tax/profit_report/profit_report_figures_detail_type";
import ProfitReportFiguresType, {
    ProfitReportFiguresTypeMap,
} from "domain/model/tax/profit_report/profit_report_figures_type";
import {ChartAxisData} from "presentation/components/charts/common/chart_model";
import ChartLegendLabel from "presentation/components/charts/common/chart_legend_label";
import LineChart from "presentation/components/charts/line_chart/line_chart";
import ProfitReportYearlyChartColumn
    from "presentation/pages/profit_report/components/profit/yearly_chart/profit_report_profit_yearly_chart_column";
import ProfitReportYearlyChartLegendsRow
    from "presentation/pages/profit_report/components/profit/yearly_chart/profit_report_profit_yearly_chart_legends_row";
import profitReportProfitYearlyChartSelector
    from "presentation/states/profit_report/selector/profit/yearly_chart/profit_report_yearly_chart_selector";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import {useMemo} from "react";
import {useRecoilValue} from "recoil";

const ProfitReportProfitYearlyChartOtherExpensesView = () => {
    const {data} = useRecoilValue(profitReportProfitYearlyChartSelector);

    const {chartData, xAxis, yAxis} = useMemo(() => {
        const chartData =
            ProfitReportFiguresTypeMap.profitReportFiguresDetailTypes(
                ProfitReportFiguresType.OtherExpenses
            ).map((t, index) => ({
                dots: data.map((d) => ({
                    x: d.date.month - 1,
                    y: d.otherExpenses[index + 1],
                })),
                label: ProfitReportFiguresDetailTypeMap.yearlyChartLabel(t),
                color: ProfitReportFiguresDetailTypeMap.color(t),
            }));

        const yValues = chartData
            .map((d) => d.dots.map((dot) => dot.y))
            .flatMap((y) => y);

        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);

        const yLabelCount = 4;
        const unit = NumberHelper.getUnitFromMinMaxCount(
            minY,
            maxY,
            yLabelCount
        );
        const [lowerBoundY, upperBoundY] = NumberHelper.getBoundsForCount(
            minY,
            maxY,
            yLabelCount
        );

        const yearNow = DateTime.now().copyWith({month: 1, day: 1}).min;
        const xAxis = data.length
            ? Array.from(
                {
                    length: DateTime.monthsInYear,
                },
                (_, index) => ({
                    value: index,
                    label: yearNow
                        .copyWith({
                            month: yearNow.month + index,
                        })
                        .format(
                            S.profitReportPage.profit.yearlyChart
                                .xAxisDateFormat
                        ),
                })
            )
            : [];

        const yAxis: ChartAxisData[] = [];

        let pointer = lowerBoundY;
        while (pointer <= upperBoundY) {
            yAxis.push({
                value: pointer,
                label: NumberHelper.toChartYAxisAbbreviatedString(pointer),
            });
            pointer += unit;
        }

        return {
            chartData,
            xAxis,
            yAxis,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <ProfitReportYearlyChartColumn
            title={
                S.profitReportPage.profit.yearlyChart.otherExpensesChartTitle
            }
        >
            <LineChart
                heightInPx={265}
                data={chartData}
                xAxis={xAxis}
                yAxis={yAxis}
            />
            {chartData.length && (
                <ProfitReportYearlyChartLegendsRow>
                    {chartData.map((d) => (
                        <ChartLegendLabel
                            key={d.label}
                            color={d.color}
                            label={d.label}
                        />
                    ))}
                </ProfitReportYearlyChartLegendsRow>
            )}
        </ProfitReportYearlyChartColumn>
    );
};

export default ProfitReportProfitYearlyChartOtherExpensesView;
