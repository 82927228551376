import SortType from "domain/model/common/sort_type";
import TransactionsDailyFilteredFigures from "domain/model/tax/transactions/transactions_daily_filtered_figures";
import TransactionsDateFilterType from "domain/model/tax/transactions/transactions_date_filter_type";
import {TransactionsListenableState} from "presentation/states/transactions/transactions_listenable_state";
import {TransactionsReplayState} from "presentation/states/transactions/transactions_replay_state";
import DateTime from "presentation/utils/extension/date_extension";
import {optional} from "presentation/utils/types/optional";
import {atom} from "recoil";
import {BehaviorSubject, Subject} from "rxjs";

type TransactionsTableState = {
    selectedDateFilter?: TransactionsDateFilterType;
    startDateKey: string;
    endDateKey: string;
    dateSortType: SortType;
    totalSortType: SortType;
    data: TransactionsDailyFilteredFigures[];
};

type TransactionsCalendarListState = {
    yearMonthKey: string;
    data: TransactionsDailyFilteredFigures[];
};

type TransactionsState = {
    taxStartDateKey: string;
    table: TransactionsTableState;
    calendarList: TransactionsCalendarListState;
};

export const transactionsListenableState =
    new Subject<TransactionsListenableState>();

export const transactionsReplayState = new BehaviorSubject<
    optional<TransactionsReplayState>
>(undefined);

export const transactionsState = atom<TransactionsState>({
    key: "transactionsState",
    default: {
        taxStartDateKey: DateTime.minNow().copyWith({
            month: DateTime.minNow().month - 30,
        }).key,
        table: {
            selectedDateFilter: TransactionsDateFilterType.ThisMonth,
            startDateKey: DateTime.minNow().key,
            endDateKey: DateTime.minNow().key,
            dateSortType: SortType.None,
            totalSortType: SortType.None,
            data: [],
        },
        calendarList: {
            yearMonthKey: DateTime.now().getFirstDayInMonth().key,
            data: [],
        },
    },
});
