import axios from "axios";

export type LocalClient = ReturnType<typeof createLocalClient>;

function createLocalClient() {
    return axios.create({
        baseURL: "https://127.0.0.1:16566/",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            accept: "application/json",
        },
    });
}

const localClientInstance = createLocalClient();

export default localClientInstance;
