import {animated, useSpring} from "@react-spring/web";
import {LineChartContext} from "presentation/components/charts/line_chart/line_chart";
import {useContext, useEffect, useMemo, useRef} from "react";
import styled from "styled-components";

const CanvasContainer = styled(animated.canvas)`
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: width 0.3s ease-in-out;
`;

const LineChartLines = () => {
    const context = useContext(LineChartContext);
    const ref = useRef<HTMLCanvasElement>(null);

    const data = useMemo(
        () =>
            context.data.map((d) => ({
                ...d,
                dots: d.dots.map((dot) => ({
                    xPos: context.functions.getXPos(dot.x),
                    yPos: context.functions.getYPos(dot.y),
                })),
            })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [context.data, context.constantsKey]
    );

    const [props, api] = useSpring(() => ({
        opacity: 0,
    }));

    useEffect(() => {
        const canvas = ref.current;
        const c = ref.current?.getContext("2d");
        if (!c) return;

        const drawLine = (
            color: string,
            data: { xPos: number; yPos: number },
            prev?: { xPos: number; yPos: number }
        ) => {
            if (!prev) return;

            c.strokeStyle = color;
            c.lineWidth = 3;
            c.beginPath();
            c.moveTo(prev.xPos, prev.yPos);
            c.lineTo(data.xPos, data.yPos);
            c.stroke();
        };

        data.forEach((d) =>
            d.dots.forEach((dot, index) =>
                drawLine(d.color, dot, d.dots[index - 1])
            )
        );

        api.start({
            opacity: 1,
        });

        return () => {
            c.clearRect(
                0,
                0,
                canvas?.width ?? context.constants.widthInPx,
                canvas?.height ?? context.constants.heightInPx
            );
            api.set({
                opacity: 0,
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, ref]);

    return (
        <CanvasContainer
            ref={ref}
            style={props}
            width={context.constants.widthInPx}
            height={context.constants.heightInPx}
        />
    );
};

export default LineChartLines;
