import uiState from "presentation/states/ui/ui_state";
import {selector} from "recoil";

const uiLoadingStateSelector = selector({
    key: "uiLoadingStateSelector",
    get: ({get}) => {
        const state = get(uiState);

        return state.loading;
    },
});

export default uiLoadingStateSelector;
