import BaseRepository from "data/repository/base_repository";
import TransactionsDailyFigures from "domain/model/tax/transactions/transactions_daily_figures";
import TransactionsDailyFilteredFigures from "domain/model/tax/transactions/transactions_daily_filtered_figures";
import TransactionsFiguresDetail from "domain/model/tax/transactions/transactions_figures_detail";
import TransactionsFiguresType, {
    TransactionsFiguresTypeMap,
} from "domain/model/tax/transactions/transactions_figures_type";
import TransactionsType from "domain/model/tax/transactions/transactions_type";
import DateTime, {DateDifferenceUnitType,} from "presentation/utils/extension/date_extension";

export default class TransactionsRepository extends BaseRepository {
    getFiguresInRange = async ({
                                   startDate,
                                   endDate,
                               }: {
        startDate: DateTime;
        endDate: DateTime;
    }): Promise<TransactionsDailyFigures[]> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                if (Math.random() < 0.1) return [];

                const types = Object.values(TransactionsFiguresType);
                const sales = types.filter(
                    (t) =>
                        TransactionsFiguresTypeMap.transactionsType(t) ===
                        TransactionsType.Sales
                );
                const purchases = types.filter(
                    (t) =>
                        TransactionsFiguresTypeMap.transactionsType(t) ===
                        TransactionsType.Purchases
                );
                const expectedReceipts = types.filter(
                    (t) =>
                        TransactionsFiguresTypeMap.transactionsType(t) ===
                        TransactionsType.ExpectedReceipts
                );

                const responseData = Array.from(
                    {
                        length:
                            endDate.subtract(
                                startDate,
                                DateDifferenceUnitType.Day
                            ) + 1,
                    },
                    (_, index) => {
                        return Math.random() < 0.3
                            ? undefined
                            : {
                                date: startDate.copyWith({
                                    day: startDate.day + index,
                                }),
                                dailySalesInfo: sales
                                    .map((f) =>
                                        Math.random() < 0.3
                                            ? undefined
                                            : {
                                                category: f,
                                                amount: Math.floor(
                                                    Math.random() *
                                                    100_000_000
                                                ),
                                            }
                                    )
                                    .filter((d) => d !== undefined)
                                    .map((d) => d!),
                                dailyPurchaseInfo: purchases
                                    .map((f) =>
                                        Math.random() < 0.3
                                            ? undefined
                                            : {
                                                category: f,
                                                amount: Math.floor(
                                                    Math.random() *
                                                    100_000_000
                                                ),
                                            }
                                    )
                                    .filter((d) => d !== undefined)
                                    .map((d) => d!),
                                dailyExpectedIncomeInfo: expectedReceipts
                                    .map((f) =>
                                        Math.random() < 0.3
                                            ? undefined
                                            : {
                                                category: f,
                                                amount: Math.floor(
                                                    Math.random() *
                                                    100_000_000
                                                ),
                                            }
                                    )
                                    .filter((d) => d !== undefined)
                                    .map((d) => d!),
                            };
                    }
                )
                    .filter((d) => d !== undefined)
                    .map((d) => d!);

                return responseData.map((d) => ({
                    date: d.date,
                    figures: [
                        ...d.dailySalesInfo,
                        ...d.dailyPurchaseInfo,
                        ...d.dailyExpectedIncomeInfo,
                    ]
                        .flat()
                        .map((f) => ({
                            type: f.category,
                            value: f.amount,
                        })),
                }));
            },
        });

    getFilteredFiguresInRange = async ({
                                           transactionsType,
                                           startDate,
                                           endDate,
                                       }: {
        transactionsType: TransactionsType;
        startDate: DateTime;
        endDate: DateTime;
    }): Promise<TransactionsDailyFilteredFigures[]> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                if (Math.random() < 0.1) return [];

                const types = Object.values(TransactionsFiguresType);
                const sales = types.filter(
                    (t) =>
                        TransactionsFiguresTypeMap.transactionsType(t) ===
                        TransactionsType.Sales
                );
                const purchases = types.filter(
                    (t) =>
                        TransactionsFiguresTypeMap.transactionsType(t) ===
                        TransactionsType.Purchases
                );
                const expectedReceipts = types.filter(
                    (t) =>
                        TransactionsFiguresTypeMap.transactionsType(t) ===
                        TransactionsType.ExpectedReceipts
                );

                const responseData = Array.from(
                    {
                        length:
                            endDate.subtract(
                                startDate,
                                DateDifferenceUnitType.Day
                            ) + 1,
                    },
                    (_, index) => {
                        const date = startDate.copyWith({
                            day: startDate.day + index,
                        });

                        return {
                            date,
                            dailySalesInfo: sales
                                .map((f) =>
                                    // date.isSameDay(new DateTime(2024, 6, 18)) ||
                                    Math.random() < 0.3
                                        ? undefined
                                        : {
                                            category: f,
                                            amount: Math.floor(
                                                Math.random() * 100_000_000
                                            ),
                                        }
                                )
                                .filter((d) => d !== undefined)
                                .map((d) => d!),
                            dailyPurchaseInfo: purchases
                                .map((f) =>
                                    Math.random() < 0.3
                                        ? undefined
                                        : {
                                            category: f,
                                            amount: Math.floor(
                                                Math.random() * 100_000_000
                                            ),
                                        }
                                )
                                .filter((d) => d !== undefined)
                                .map((d) => d!),
                            dailyExpectedIncomeInfo: expectedReceipts
                                .map((f) =>
                                    Math.random() < 0.3
                                        ? undefined
                                        : {
                                            category: f,
                                            amount: Math.floor(
                                                Math.random() * 100_000_000
                                            ),
                                        }
                                )
                                .filter((d) => d !== undefined)
                                .map((d) => d!),
                        };
                    }
                )
                    .filter((d) => d !== undefined)
                    .map((d) => d!);

                const data = responseData.map((d) => ({
                    date: d.date,
                    figures: [
                        ...d.dailySalesInfo,
                        ...d.dailyPurchaseInfo,
                        ...d.dailyExpectedIncomeInfo,
                    ]
                        .flat()
                        .map((f) => ({
                            type: f.category,
                            value: f.amount,
                        })),
                }));

                const appendMaxLength =
                    Object.values(TransactionsFiguresType).filter(
                        (t) =>
                            TransactionsFiguresTypeMap.transactionsType(t) ===
                            transactionsType
                    ).length + 1;
                return data.map((d) => {
                    const f = d.figures.filter(
                        (f) =>
                            TransactionsFiguresTypeMap.transactionsType(
                                f.type
                            ) === transactionsType
                    );
                    const total = f.reduce((acc, cur) => acc + cur.value, 0);
                    const values = [total, ...f.map((f) => f.value)];
                    const appended = values.concat(
                        Array.from(
                            {
                                length:
                                    values.length < appendMaxLength
                                        ? appendMaxLength - values.length
                                        : 0,
                            },
                            () => 0
                        )
                    );

                    return {
                        date: d.date,
                        values: appended,
                    };
                });
            },
        });

    getFiguresDetails = async ({
                                   date,
                                   transactionsFiguresType,
                               }: {
        date: DateTime;
        transactionsFiguresType: TransactionsFiguresType;
    }): Promise<TransactionsFiguresDetail[]> =>
        await this.handler({
            request: async () => {
                await this.delayed(300);

                if (Math.random() < 0.3) return [];

                const data = Array.from(
                    {
                        length: 1 + Math.floor(Math.random() * 50),
                    },
                    () => ({
                        label:
                            Math.random() < 0.3
                                ? "롯데카드"
                                : Math.random() < 0.5
                                    ? "현대카드"
                                    : "신한카드",
                        time: date.copyWith({
                            hour: Math.floor(Math.random() * 24),
                            minute: Math.floor(Math.random() * 60),
                            second: Math.floor(Math.random() * 60),
                        }),
                        amount: Math.floor(50_000 + Math.random() * 5_000_000),
                    })
                );

                return data
                    .map((d) => ({
                        label: d.label,
                        dateTime: d.time,
                        value: d.amount,
                    }))
                    .toSorted(
                        (a, b) =>
                            a.dateTime.millisecondsSinceEpoch -
                            b.dateTime.millisecondsSinceEpoch
                    );
            },
        });
}
