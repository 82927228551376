import {ChartInitialScrollPositionType} from "presentation/components/charts/common/chart_model";
import S from "presentation/theme/s";
import DateTime, {DateDifferenceUnitType,} from "presentation/utils/extension/date_extension";

enum DispensingDateFilterType {
    ThisMonth = "thisMonth",
    RecentSixMonths = "recentSixMonths",
    ThisYear = "thisYear",
}

export const DispensingDateFilterTypeMap = {
    queryDaily: (type: DispensingDateFilterType): boolean => {
        switch (type) {
            case DispensingDateFilterType.ThisMonth:
                return true;
            case DispensingDateFilterType.RecentSixMonths:
            case DispensingDateFilterType.ThisYear:
                return false;
            default:
                throw new Error("Invalid DispensingDateFilterType");
        }
    },
    queryMonthly: (type: DispensingDateFilterType): boolean => {
        switch (type) {
            case DispensingDateFilterType.ThisMonth:
                return false;
            case DispensingDateFilterType.RecentSixMonths:
            case DispensingDateFilterType.ThisYear:
                return true;
            default:
                throw new Error("Invalid DispensingDateFilterType");
        }
    },
    label: (type: DispensingDateFilterType): string => {
        switch (type) {
            case DispensingDateFilterType.ThisMonth:
                return S.dispensingDateFilterType.thisMonthLabel;
            case DispensingDateFilterType.RecentSixMonths:
                return S.dispensingDateFilterType.recentSixMonthsLabel;
            case DispensingDateFilterType.ThisYear:
                return S.dispensingDateFilterType.thisYearLabel;
            default:
                throw new Error("Invalid DispensingDateFilterType");
        }
    },
    datePeriod: (
        type: DispensingDateFilterType
    ): { startDate: DateTime; endDate: DateTime } => {
        const now = DateTime.now().min;
        const currentYearMonth = now.getFirstDayInMonth();
        switch (type) {
            case DispensingDateFilterType.ThisMonth:
                return {
                    startDate: now.getFirstDayInMonth(),
                    endDate: now,
                };
            case DispensingDateFilterType.RecentSixMonths:
                const sixMonthsBefore = currentYearMonth.copyWith({
                    month: currentYearMonth.month - 5,
                });

                return {
                    startDate: sixMonthsBefore,
                    endDate: currentYearMonth,
                };
            case DispensingDateFilterType.ThisYear:
                return {
                    startDate: now.copyWith({
                        month: 1,
                        day: 1,
                    }),
                    endDate: currentYearMonth,
                };
            default:
                throw new Error("Invalid DispensingDateFilterType");
        }
    },
    dateDifferenceUnitType: (
        type?: DispensingDateFilterType
    ): DateDifferenceUnitType => {
        if (!type) return DateDifferenceUnitType.Day;

        switch (type) {
            case DispensingDateFilterType.ThisMonth:
                return DateDifferenceUnitType.Day;
            case DispensingDateFilterType.RecentSixMonths:
            case DispensingDateFilterType.ThisYear:
                return DateDifferenceUnitType.Month;
            default:
                throw new Error("Invalid DispensingDateFilterType");
        }
    },
    initialScrollPosition: (
        type?: DispensingDateFilterType
    ): ChartInitialScrollPositionType => {
        if (!type) return ChartInitialScrollPositionType.Left;

        switch (type) {
            case DispensingDateFilterType.ThisMonth:
            case DispensingDateFilterType.RecentSixMonths:
                return ChartInitialScrollPositionType.Right;
            case DispensingDateFilterType.ThisYear:
                return ChartInitialScrollPositionType.Left;
            default:
                throw new Error("Invalid DispensingDateFilterType");
        }
    },
};

export default DispensingDateFilterType;
