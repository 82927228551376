import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";

enum CardSalesDateFilterType {
    ThisMonth = "thisMonth",
    ThisYear = "thisYear",
    LastYear = "lastYear",
}

export const CardSalesDateFilterTypeMap = {
    label: (type: CardSalesDateFilterType): string => {
        switch (type) {
            case CardSalesDateFilterType.ThisMonth:
                return S.cardSalesDateFilterType.thisMonthLabel;
            case CardSalesDateFilterType.ThisYear:
                return S.cardSalesDateFilterType.thisYearLabel;
            case CardSalesDateFilterType.LastYear:
                return S.cardSalesDateFilterType.lastYearLabel;
            default:
                throw new Error("Invalid CardSalesDateFilterType");
        }
    },
    datePeriod: (
        type: CardSalesDateFilterType
    ): { startDate: DateTime; endDate: DateTime } => {
        const now = DateTime.minNow();
        switch (type) {
            case CardSalesDateFilterType.ThisMonth:
                return {
                    startDate: now.getFirstDayInMonth(),
                    endDate: now,
                };
            case CardSalesDateFilterType.ThisYear:
                return {
                    startDate: now.getFirstDayInYear(),
                    endDate: now,
                };
            case CardSalesDateFilterType.LastYear:
                return {
                    startDate: now
                        .copyWith({
                            year: now.year - 1,
                        })
                        .getFirstDayInYear(),
                    endDate: now
                        .copyWith({
                            year: now.year - 1,
                        })
                        .getLastDayInYear(),
                };
            default:
                throw new Error("Invalid CardSalesDateFilterType");
        }
    },
};

export default CardSalesDateFilterType;
