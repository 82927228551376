import IconButton, {IconButtonHoverColorType,} from "presentation/components/button/icon_button";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import Debouncer from "presentation/utils/debouncer/debouncer";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";
import {ChangeEvent, useEffect, useRef} from "react";
import styled from "styled-components";

export const enum InputModeType {
    None = "none",
    Text = "text",
    Tel = "tel",
    Url = "url",
    Email = "email",
    Numeric = "numeric",
    Decimal = "decimal",
    Search = "search",
}

const LayoutContainer = styled.div`
    width: 100%;
    position: relative;
`;

const TextFieldContainer = styled.input.attrs<{
    $disabled: boolean;
    $error: boolean;
    $hasSuffix: boolean;
}>((props) => ({
    style: {
        padding: props.$hasSuffix ? "15.2px 56px 15.2px 16px" : "15.2px 16px",
    },
}))`
    width: 100%;
    border: 0px solid;
    box-shadow: ${(props) =>
    props.$disabled
        ? `0 0 0 1.5px ${Palette.gray100} inset`
        : props.$error
            ? `0 0 0 2px ${Palette.red500} inset`
            : `0 0 0 1.5px ${Palette.gray300} inset`};
    border-radius: 12px;
    outline: none;
    background-color: ${(props) =>
    props.$disabled
        ? Palette.gray100
        : props.$error
            ? Palette.red100
            : Palette.none};
    ${Fonts.detail3Medium};
    line-height: 1;
    color: ${(props) => (props.disabled ? Palette.gray400 : Palette.gray800)};
    caret-color: ${(props) =>
    props.$error ? Palette.red500 : Palette.primary500};
    cursor: ${(props) => (props.$disabled ? "not-allowed" : "text")};
    transition: all 0.2s ease-in-out;

    &:focus {
        box-shadow: ${(props) =>
    `0 0 0 2px ${
        props.$error ? Palette.red500 : Palette.primary500
    } inset`};
    }

    &::-webkit-input-placeholder {
        ${Fonts.detail3Medium};
        color: ${Palette.gray400};
    }
`;

const SuffixPositionContainer = styled.div.attrs<{ $visible: boolean }>(
    (props) => ({
        style: {
            pointerEvents: props.$visible ? "auto" : "none",
            opacity: props.$visible ? 1 : 0,
        },
    })
)`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: opacity 0.3s ease-in-out;
`;

export const TextField = ({
                              className,
                              secure = false,
                              enabled = true,
                              error = false,
                              value,
                              onValueChange,
                              focused,
                              onFocusChange,
                              placeholder,
                              inputMode = InputModeType.Text,
                              maxLength,
                              hasSuffix = false,
                              enterPressDebouncerDelayInMS = 50,
                              autoComplete = "off",
                              inputValidator,
                              onEnterPress,
                          }: {
    className?: string;
    secure?: boolean;
    enabled?: boolean;
    error?: boolean;
    value: string;
    onValueChange?: (value: string) => void;
    focused?: boolean;
    onFocusChange?: (focused: boolean) => void;
    placeholder?: string;
    inputMode?: InputModeType;
    maxLength?: number;
    hasSuffix?: boolean;
    enterPressDebouncerDelayInMS?: number;
    autoComplete?: string;
    inputValidator?: (value: string) => boolean;
    onEnterPress?: () => void;
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const suffixRef = useRef<HTMLButtonElement>(null);
    const debouncerRef = useRef(new Debouncer());

    useEffect(() => {
        if (focused) {
            inputRef.current?.focus();
            inputRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        } else if (!focused) {
            inputRef.current?.blur();
        }
    }, [focused]);

    useEffect(() => {
        const input = inputRef.current;

        if (!onEnterPress || !focused || !input) return;

        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                debouncerRef.current.run(
                    () => addPostFrameCallback(() => onEnterPress()),
                    enterPressDebouncerDelayInMS
                );
            }
        };

        input.addEventListener("keydown", onKeyDown);

        return () => input.removeEventListener("keydown", onKeyDown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused, onEnterPress, inputRef]);

    useEffect(() => {
        const suffix = suffixRef.current;
        if (!hasSuffix || !focused || !value.length || !suffix) return;

        const onClear = () => onValueChange?.("");

        suffix.addEventListener("mousedown", onClear);

        return () => suffix.removeEventListener("mousedown", onClear);
    }, [hasSuffix, focused, suffixRef, value.length, onValueChange]);

    const onBlur = () => {
        onFocusChange && onFocusChange(false);
        onValueChange?.(value.trim());
    };

    const onFocus = () => {
        onFocusChange && onFocusChange(true);
    };

    const onValidatedValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        if (
            (!maxLength || value.length <= maxLength) &&
            (!inputValidator || inputValidator(value))
        ) {
            onValueChange?.(value);
        }
    };

    const inputType = secure ? "password" : "text";

    const suffixVisible = !!value.length && (focused ?? false);

    return (
        <LayoutContainer>
            <TextFieldContainer
                className={className}
                ref={inputRef}
                disabled={!enabled}
                type={inputType}
                value={value}
                onChange={onValidatedValueChange}
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={placeholder ?? ""}
                inputMode={inputMode}
                autoComplete={autoComplete}
                $disabled={!enabled}
                $error={error}
                $hasSuffix={hasSuffix}
            />
            {hasSuffix && (
                <SuffixPositionContainer $visible={suffixVisible}>
                    <IconButton
                        ref={suffixRef}
                        asset={SVGAssets.DeleteCircle}
                        width={"20px"}
                        height={"20px"}
                        color={Palette.black20}
                        hoverColor={IconButtonHoverColorType.Light}
                        tabIndex={-1}
                    />
                </SuffixPositionContainer>
            )}
        </LayoutContainer>
    );
};
