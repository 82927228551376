import {animated, useSpring} from "@react-spring/web";
import NumberHelper from "config/helper/number_helper";
import ChartTooltipLabel from "presentation/components/charts/common/chart_tooltip_label";
import {DispensingChartContext} from "presentation/components/charts/dispensing_chart/dispensing_chart";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import Debouncer from "presentation/utils/debouncer/debouncer";
import {optional} from "presentation/utils/types/optional";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";

const LayoutContainer = styled(animated.ul)`
    width: max-content;
    padding: 8px;
    border-radius: 12px;
    background-color: ${Palette.black75};
    box-shadow: 0 0 0 1px ${Palette.white20} inset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    pointer-events: none;
    transform: translate(-50%, -100%);
    z-index: 5;
`;

const DispensingChartTooltip = () => {
    const context = useContext(DispensingChartContext);
    const defaultPos = "100px";
    const defaultTooltipWidthInPx = 300;
    const defaultTooltipHeightInPx = 85;

    const debouncerRef = useRef(new Debouncer());
    const [data, setData] = useState<Array<string>>([]);
    const ref = useRef<HTMLUListElement>(null);
    const lastXPosRef = useRef<string>(defaultPos);
    const lastYPosRef = useRef<string>(defaultPos);

    const getXPos = useCallback(
        (hoveredXIndex: optional<number>) => {
            if (hoveredXIndex === undefined) return defaultPos;

            const tooltipHalfWidth =
                (ref.current?.offsetWidth ?? defaultTooltipWidthInPx) / 2;
            const x = context.data.at(hoveredXIndex)?.x;

            if (x === undefined) return defaultPos;

            const xPos = context.functions.getXPos(x);
            const leftOverflow = xPos - tooltipHalfWidth;
            const rightOverflow =
                context.constants.innerWidthInPx +
                context.constants.xAxisHorizontalMarginInPx * 2 -
                (xPos + tooltipHalfWidth);

            if (leftOverflow < 0) {
                return `${xPos + Math.abs(leftOverflow)}px`;
            }

            if (rightOverflow < 0) {
                return `${xPos - Math.abs(rightOverflow)}px`;
            }

            return `${xPos}px`;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ref, context.data, context.constantsKey, data]
    );
    const getYPos = useCallback(
        (hoveredXIndex: optional<number>) => {
            if (hoveredXIndex === undefined) return defaultPos;

            const defaultOffset = -(context.constants.barRimPaddingInPx + 8);
            const height =
                ref.current?.offsetHeight ?? defaultTooltipHeightInPx;
            const y = context.data.at(hoveredXIndex)?.y;

            if (y === undefined) return defaultPos;

            const yPos = context.functions.getYPosFromTop(y) + defaultOffset;

            const topOverflow = yPos - height;

            if (topOverflow < 0) {
                return `${yPos + Math.abs(topOverflow)}px`;
            }

            return `${yPos}px`;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ref, context.data, context.constantsKey, data]
    );

    const [props, api] = useSpring(() => ({
        opacity: context.hoveredXIndex !== undefined ? 1 : 0,
        top: lastYPosRef.current,
        left: lastXPosRef.current,
    }));

    useEffect(() => {
        debouncerRef.current.runImmediately(() => {
            lastXPosRef.current = defaultPos;
            lastYPosRef.current = defaultPos;

            api.set({
                opacity: 0,
                top: lastYPosRef.current,
                left: lastXPosRef.current,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.data]);

    useEffect(() => {
        if (context.hoveredXIndex === undefined) {
            debouncerRef.current.run(
                () =>
                    api.start({
                        opacity: 0,
                        top: lastYPosRef.current,
                        left: lastXPosRef.current,
                    }),
                500
            );
            return;
        }

        debouncerRef.current.cancel();
        lastXPosRef.current = getXPos(context.hoveredXIndex);
        lastYPosRef.current = getYPos(context.hoveredXIndex);

        api.start({
            opacity: 1,
            top: lastYPosRef.current,
            left: lastXPosRef.current,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.data, context.constantsKey, context.hoveredXIndex, data]);

    useEffect(() => {
        if (context.hoveredXIndex === undefined) return;

        const dispensing = context.data.at(context.hoveredXIndex)?.y;
        const charge = context.data.at(context.hoveredXIndex)?.payload1;
        const average = context.data.at(context.hoveredXIndex)?.payload2;

        if (
            dispensing === undefined ||
            charge === undefined ||
            average === undefined
        )
            return;

        setData([
            S.dispensingChart.tooltip.dispensingLabel(
                NumberHelper.toChartAbbreviatedString(dispensing)
            ),
            S.dispensingChart.tooltip.chargeLabel(
                NumberHelper.toStringWithDividers(charge)
            ),
            S.dispensingChart.tooltip.averageLabel(
                NumberHelper.toChartAbbreviatedString(average)
            ),
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.data, context.constantsKey, context.hoveredXIndex]);

    return (
        <LayoutContainer ref={ref} style={props}>
            {data.map((d) => (
                <ChartTooltipLabel key={d} label={d}/>
            ))}
        </LayoutContainer>
    );
};

export default DispensingChartTooltip;
