import ArrowIconButton from "presentation/components/button/arrow_icon_button";
import SizedBox from "presentation/components/common/sized_box";
import dashboardState from "presentation/states/dashboard/dashboard_state";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/extension/date_extension";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import Debouncer from "presentation/utils/debouncer/debouncer";
import {useRef, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import styled from "styled-components";
import {useLifeCycle} from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import UserRepository from "data/repository/user_repository";
import ViewStatusType from "domain/model/common/view_status_type";
import dashboardDateSelector from "presentation/states/dashboard/selector/dashboard_date_selector";

const debouncerDelayInMS = 500;

const LayoutContainer = styled.div.attrs<{
    $height: string;
    $topOffset: string;
    $leftOffset: string;
}>((props) => ({
    style: {
        height: props.$height,
        top: props.$topOffset,
        left: props.$leftOffset,
    },
}))`
    padding: 0 36px;
    border-bottom: 1px solid ${Palette.gray100};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 0;
    z-index: 100;
    background-color: ${Palette.white100};
    transition: padding 0.3s ease-in-out;

    @media (max-width: 768px) {
        padding: 0 16px;
    }
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const HeaderTitleLayoutContainer = styled.div`
    width: 230px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

const HeaderTitleContainer = styled.h4`
    ${Fonts.title2};
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

const HeaderSubtitleContainer = styled.h6`
    ${Fonts.body1Medium};
    color: ${Palette.gray500};
    text-align: center;
`;

const DashboardHeaderView = () => {
    const repository = useRepository(UserRepository);
    const isMobile = useMobileQuery();
    const theme = useThemeContext();

    const {taxStartDate, selectedYearMonth} = useRecoilValue(
        dashboardDateSelector
    );
    const setState = useSetRecoilState(dashboardState);

    const debouncerRef = useRef(new Debouncer());
    const [status, setStatus] = useState(ViewStatusType.Loading);
    const [yearMonth, setYearMonth] = useState(selectedYearMonth);

    const loaded = status === ViewStatusType.Loaded;

    useLifeCycle({
        onInit: () =>
            repository({
                handler: async (repository) => {
                    const taxStartDate =
                        (await repository.getTaxStartDate()) ?? DateTime.now();

                    setState((prev) => ({
                        ...prev,
                        taxStartDateKey: taxStartDate.key,
                    }));

                    setStatus(ViewStatusType.Loaded);
                },
            }),
    });

    const leftOffset = isMobile
        ? theme.componentTheme.lnbMinWidth
        : theme.componentTheme.lnbMaxWidth;

    const title = yearMonth.format(S.dashboardPage.header.titleDateFormat);
    const subtitle = S.dashboardPage.header.subtitle(
        yearMonth
            .getFirstDayInMonth()
            .format(S.dashboardPage.header.subtitleDateFormat),
        DateTime.min(yearMonth.getLastDayInMonth(), DateTime.now().min).format(
            S.dashboardPage.header.subtitleDateFormat
        )
    );

    const hasPrevious = yearMonth.isAfter(taxStartDate.getFirstDayInMonth());
    const hasNext = yearMonth.isBefore(DateTime.now().getFirstDayInMonth());

    const onPreviousClick = () =>
        setYearMonth((prevYearMonth) => {
            debouncerRef.current.run(
                () =>
                    setState((prev) => ({
                        ...prev,
                        selectedYearMonthKey: prevYearMonth.copyWith({
                            month: prevYearMonth.month - 1,
                        }).key,
                    })),
                debouncerDelayInMS
            );

            return prevYearMonth.copyWith({month: prevYearMonth.month - 1});
        });

    const onNextClick = () =>
        setYearMonth((prevYearMonth) => {
            debouncerRef.current.run(
                () =>
                    setState((prev) => ({
                        ...prev,
                        selectedYearMonthKey: prevYearMonth.copyWith({
                            month: prevYearMonth.month + 1,
                        }).key,
                    })),
                debouncerDelayInMS
            );

            return prevYearMonth.copyWith({month: prevYearMonth.month + 1});
        });

    return (
        <>
            <SizedBox
                width={"100%"}
                height={theme.componentTheme.dashboardHeaderHeight}
            />
            <LayoutContainer
                $height={theme.componentTheme.dashboardHeaderHeight}
                $topOffset={theme.componentTheme.tnbHeight}
                $leftOffset={leftOffset}
            >
                <ColumnContainer>
                    <HeaderTitleLayoutContainer>
                        <ArrowIconButton
                            enabled={loaded && hasPrevious}
                            asset={SVGAssets.Back}
                            onClick={onPreviousClick}
                        />
                        <HeaderTitleContainer>{title}</HeaderTitleContainer>
                        <ArrowIconButton
                            enabled={loaded && hasNext}
                            asset={SVGAssets.Next}
                            onClick={onNextClick}
                        />
                    </HeaderTitleLayoutContainer>
                    <SizedBox height={"1px"}/>
                    <HeaderSubtitleContainer>
                        {subtitle}
                    </HeaderSubtitleContainer>
                </ColumnContainer>
            </LayoutContainer>
        </>
    );
};

export default DashboardHeaderView;
